// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table-list-head_root__3m7Dg {\n  display: flex;\n  color: #6d6971;\n  font-size: 13px;\n  line-height: 17px;\n  border-bottom: 1px solid #e7e9f0;\n  letter-spacing: -0.2px;\n}", ""]);
// Exports
exports.locals = {
	"root": "table-list-head_root__3m7Dg"
};
module.exports = exports;
