import { SnackbarOrigin } from '@material-ui/core/Snackbar';

export * from './people';
export * from './pagination';
export * from './user-profile';
export * from './typography';
export * from './auth';
export * from './error';
export * from './user';
export * from './account';
export * from './department';
export * from './division';
export * from './location';
export * from './project';
export * from './timesheet';
export * from './expense';
export * from './reimbursement';
export * from './contact';

export type Modules =
  | 'people'
  | 'dimensions'
  | 'time'
  | 'reimbursements'
  | 'contacts'
  | 'reports'
  | 'settings';

export type ModuleAccess =
  | 'people_access'
  | 'dimension_access'
  | 'time_access'
  | 'contact_access'
  | 'reimbursement_access'
  | 'settings_access';

export interface SnackBarConfig {
  anchorOrigin: SnackbarOrigin;
  autoHideDuration: number;
}

export interface SelectDropdownOption {
  label: string;
  value: string;
}

export interface NavItem {
  path: string;
  name: string;
  icon: JSX.Element;
}

export interface HeaderList {
  id: string;
  label: string;
  className: string;
}
