import {
  appRoute,
  BasicFilter,
  Company,
  COMPANY_TABLE_HEADERS,
  COMPONENT_STATE,
  useModuleAccess,
} from '@spovio/shared';
import {
  Button,
  DeleteIcon,
  DivisionListIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  EvStationIcon,
  NoDataContent,
  PlusIcon,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './contact-company-table-list.module.scss';

/* eslint-disable-next-line */
export interface ContactCompanyTableListProps {
  companies: Company[];
  filter?: BasicFilter;
  onFilter?: (filter: BasicFilter) => void;
  restrictEdit?: boolean;
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, company: Company) => void;
}

const { LOADING } = COMPONENT_STATE;
export const ContactCompanyTableList = ({
  companies,
  filter,
  onFilter,
  componentState,
  onListAction,
  restrictEdit = false,
}: ContactCompanyTableListProps) => {
  const { isAdmin } = useModuleAccess('contact_access');
  const { t } = useTranslation();
  const headerContent = COMPANY_TABLE_HEADERS();

  const getCompaniesCreateButton = () => {
    return (
      <Button
        size={'s'}
        component={NavLink}
        disabled={!isAdmin}
        to={appRoute.getRoute(`/contacts/companies/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('company.addCompany')}
        </>
      </Button>
    );
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <div className={clsx(styles.heading)}>{item.label}</div>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: Company[]) => {
    return results.map((item) => {
      const { id, name, city, state, country } = item;
      const dropdownItems: DropdownListItem[] = [
        {
          label: t('label.edit'),
          icon: <EditIcon />,
          id: 'edit',
        },
        {
          label: t('label.delete'),
          id: 'delete',
          icon: <DeleteIcon />,
        },
      ];
      const cells = [
        {
          classes: clsx(styles.col, styles.nameCol),
          content: <div className="ellipsis">{name}</div>,
        },
        {
          classes: clsx(styles.col, styles.cityCol),
          content: <div className="ellipsis">{city}</div>,
        },
        {
          classes: clsx(styles.col, styles.stateCol),
          content: <div className="ellipsis">{state}</div>,
        },
        {
          classes: clsx(styles.col, styles.countryCol),
          content: <div className="ellipsis">{country}</div>,
        },
      ];
      return (
        <TableListRow
          className={styles.trow}
          key={id}
          onClick={(e) => {
            e.stopPropagation();
            onListAction('view', item);
          }}
        >
          {cells.map((item, index) => {
            return (
              <TableListCell className={clsx(item.classes)} key={index}>
                <div className={clsx(styles.itemWrap, 'ellipsis')}>
                  {item.content}
                </div>
              </TableListCell>
            );
          })}
          <TableListCell
            className={clsx(styles.col, styles.actionCol)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isAdmin && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };
  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      if (!companies?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('company.noData.head')}
              desc={t('company.noData.desc')}
              icon={<EvStationIcon className={styles.noDataIcon} />}
              buttonComponent={getCompaniesCreateButton()}
            />
          </div>
        );
      }
      return getBodyRowMarkup(companies);
    }
  };

  return (
    <div className={styles.root}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
};

export default ContactCompanyTableList;
