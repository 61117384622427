import axios, { AxiosResponse } from 'axios';

import { appInfo } from '../constants';
import { AcceptInvite, Authentication, Credentials } from '../types';

export const login = async (
  payload: Authentication
): Promise<AxiosResponse<Credentials>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/auth/access/',
    data: payload,
  });
};

export const verifyEmail = async (
  email: string
): Promise<AxiosResponse<{ status: string }>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/signup/verify-email/',
    data: { email },
  });
};

export const createUser = async (payload: {
  password: string;
  token: string;
}): Promise<AxiosResponse<Credentials>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/signup/create-user/',
    data: payload,
  });
};

export const resetPwd = async (payload: {
  password: string;
  token: string;
}): Promise<AxiosResponse<Credentials>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/confirm-password/',
    data: payload,
  });
};

export const verifyToken = async (
  token: string
): Promise<AxiosResponse<{ status: string }>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/signup/confirm-email/',
    data: { token },
  });
};

export const verifyResetPwdToken = async (
  token: string
): Promise<AxiosResponse<{ status: string }>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/reset-password/',
    data: { token },
  });
};

export const acceptInvitation = async (
  token: string
): Promise<AxiosResponse<AcceptInvite>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/invite/accept/',
    data: { token },
  });
};

export const createUserFromInvite = async (payload: {
  password: string;
  token: string;
}): Promise<AxiosResponse<Credentials>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/invite/create-user/',
    data: payload,
  });
};

export const forgotPassword = async (
  email: string
): Promise<AxiosResponse<Credentials>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/forgot-password/',
    data: { email },
  });
};
