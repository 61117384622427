import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ReimbursementIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M.602 7.002a6.4 6.4 0 1 1 12.8 0 6.4 6.4 0 0 1-12.8 0Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M5.402 8.328c0 .737.597 1.333 1.334 1.333h.533c.737 0 1.333-.596 1.333-1.333 0-1.867-3.2-.8-3.2-2.667 0-.736.597-1.333 1.334-1.333h.533c.737 0 1.333.597 1.333 1.333"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M7.001 3.646v.585M7.001 9.72v.657"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
