import {
  createTime,
  getDurationFromText,
  getErrorMessages,
  getProjectBasicList,
  getTimeFromNumber,
  ProjectItem,
  TimeEntryInputType,
  TimeWeekGroupType,
  updateTime,
} from '@spovio/shared';
import {
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Select,
  Textarea,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeEntryInput from '../time-entry-input/time-entry-input';
import styles from './time-add.module.scss';

/* eslint-disable-next-line */
export interface TimeAddProps {
  open: boolean;
  isEdit?: boolean;
  timeItem?: Partial<TimeWeekGroupType>;
  onClose: () => void;
  onAdd: () => void;
  selectedDate: string;
  selectedUser?: number;
}

export function TimeAdd({
  open,
  isEdit,
  timeItem,
  onClose,
  onAdd,
  selectedDate,
  selectedUser,
}: TimeAddProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const [projectList, setProjectList] = useState<ProjectItem[]>(
    [] as ProjectItem[]
  );
  const [values, setValues] = useState(timeItem);
  const { showSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = async () => {
    setIsModalVisible(false);
    if (values) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload: any = values;
      if (!payload.date) {
        payload.date = moment().format('YYYY-MM-DD');
      } else {
        payload.date = moment(payload.date).format('YYYY-MM-DD');
      }
      if (values.project_id) {
        const project = projectList.find(
          (project) => project.id === Number(values.project_id)
        );
        payload.project = project;
      }
      if (selectedUser) {
        payload.user = selectedUser;
      }
      payload.date = isEdit ? timeItem?.date : selectedDate;
      if (isEdit && timeItem) {
        // patchTime
        try {
          await updateTime(payload);
          onAdd();
        } catch (error: any) {
          const msg = getErrorMessages(error);
          showSnackbar(true, { msg, severity: 'error' });
        }
      } else {
        // createTime
        try {
          await createTime(payload);
          onAdd();
        } catch (error: any) {
          const msg = getErrorMessages(error);
          showSnackbar(true, { msg, severity: 'error' });
        }
      }
    }
    onClose();
  };

  const getProjectLists = useCallback(async () => {
    const userId = selectedUser || currentUser.id;
    const res = await getProjectBasicList(userId);
    setProjectList(res.data);
  }, [setProjectList]);

  useEffect(() => {
    getProjectLists();
  }, [getProjectLists]);

  const projectOptions = projectList.map((project) => {
    return {
      label: project.name,
      value: `${project.id}`,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateValue = useCallback(
    (field: string, value: any) => {
      switch (field) {
        case 'project': {
          const project = projectList.find(
            (project) => project.id === Number(value)
          );
          setValues({
            ...values,
            project_name: project?.name,
            project_id: project?.id,
          });
          break;
        }
        case 'date': {
          setValues({ ...values, date: value });
          break;
        }
        case 'hour': {
          setValues({
            ...values,
            hours: getDurationFromText(getTimeFromNumber(value)),
          });
          break;
        }
        case 'notes': {
          setValues({ ...values, notes: value });
          break;
        }
      }
    },
    [values, projectList]
  );

  return (
    <div className={styles.root}>
      <Dialog
        title={isEdit ? t('timesheet.editTime') : t('timesheet.addTime')}
        action={isEdit ? t('label.save') : t('label.add')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
        isActionDisabled={
          !(
            values &&
            (values.project_name || values.project_id) &&
            values.hours
          )
        }
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={false} className={styles.leftCol}>
            <InputLabel
              label={t('label.project')}
              className={styles.label}
              required
            />
            <Select
              selectedOption={
                values?.project_id
                  ? {
                      label:
                        projectOptions.find(
                          (project) =>
                            project.value === values.project_id?.toString()
                        )?.label ?? '',
                      value: `${values.project_id}`,
                    }
                  : undefined
              }
              options={projectOptions}
              onSelect={(value) => onUpdateValue('project', value)}
              placeholder={t('label.selectProject')}
            />
          </FormControl>
          <div className={styles.row}>
            <FormControl gutter="xs" fullWidth={true}>
              <Input
                name="worked_on"
                labelClassName={styles.label}
                label={t('label.date')}
                value={isEdit ? timeItem?.date : selectedDate}
                disabled={true}
              />
            </FormControl>
            <FormControl
              gutter="xs"
              fullWidth={false}
              className={styles.leftCol}
            >
              <InputLabel
                label={t('label.hours')}
                className={styles.label}
                required
              />
              <TimeEntryInput
                value={values?.hours}
                size="m"
                className={styles.timeEntryInput}
                onTimeEntry={(value) => onUpdateValue('hour', value)}
                type={TimeEntryInputType.DEFAULT}
                textAlign="left"
              />
            </FormControl>
          </div>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel label={t('label.notes')} className={styles.label} />
            <Textarea
              name={'notes'}
              className={styles.textarea}
              size="s"
              value={values?.notes}
              minRows={3}
              onChange={(e) => onUpdateValue('notes', e.target.value)}
              disableResize
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default TimeAdd;
