import { SwitchTabItem } from '@spovio/shared';
import clsx from 'clsx';
import styles from './switch-nav-button.module.scss';

/* eslint-disable-next-line */
export interface SwitchNavButtonProps {
  className?: string;
  onChange: (type: SwitchTabItem) => void;
  buttonContents: SwitchTabItem[];
  currentBtnItem: SwitchTabItem
}

export function SwitchNavButton({
  className,
  onChange,
  buttonContents,
  currentBtnItem,
}: SwitchNavButtonProps) {
  return (
    <div className={clsx(styles.switch_wrap, className)}>
      <div id="switch" className={clsx(styles.switch)}>
        {buttonContents.map((item, index) => (
          <button
            type="button"
            key={item.name}
            className={clsx(styles.btn,
              item.name === currentBtnItem.name ? styles.active : '',)}
            onClick={() => onChange(item)}
          >
            {item.value}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SwitchNavButton;
