import { BasicFilter, LocationItem, LocationList, Pagination } from '../types';
import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

export const getLocations = async (
  filters?: BasicFilter
): Promise<AxiosResponse<LocationList>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/dimensions/location/?${queryParams}`);
};

export const getLocation = async (
  id: string
): Promise<AxiosResponse<LocationItem>> => {
  return await axios.get(`/dimensions/location/${id}/`);
};

export const createLocation = async (
  name: string
): Promise<AxiosResponse<LocationItem>> => {
  const payload = { name };
  return await axios.post(`/dimensions/location/`, payload);
};

export const updateLocation = async (
  id: number,
  name: string
): Promise<AxiosResponse<LocationItem>> => {
  const payload = { name };
  return await axios.patch(`/dimensions/location/${id}/`, payload);
};

export const deleteLocation = async (
  id: number
): Promise<AxiosResponse<LocationItem>> => {
  return await axios.delete(`/dimensions/location/${id}/`);
};
