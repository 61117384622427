import React from 'react';

type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const EmailIcon = (props: IconProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="10px"
      viewBox="0 0 12 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Email</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile"
          transform="translate(-270.000000, -298.000000)"
          fill="#808080"
        >
          <g id="Group-23" transform="translate(270.000000, 269.000000)">
            <g id="Group-75-Copy-3" transform="translate(0.000000, 25.000000)">
              <path
                d="M12,8 L12,12 C12,13.1045695 11.1045695,14 10,14 L2,14 C0.8954305,14 1.3527075e-16,13.1045695 0,12 L0,8 L5.10557281,10.5527864 C5.66862779,10.8343139 6.33137221,10.8343139 6.89442719,10.5527864 L12,8 Z M10,4 C11.1045695,4 12,4.8954305 12,6 L12,6.5 L6.89442719,9.0527864 C6.33137221,9.3343139 5.66862779,9.3343139 5.10557281,9.0527864 L0,6.5 L0,6 C-1.3527075e-16,4.8954305 0.8954305,4 2,4 L10,4 Z"
                id="Email"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
