import React, { useEffect, useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';

import styles from './popper.module.scss';

export interface PopperProps {
  open: boolean;
  rootPaperClassName?: string;
  paperClassName?: string;
  children?: React.ReactElement;
  anchorOrigin?: 'left' | 'right' | 'center';
  onClose: () => void;
  renderButton?: () => React.ReactElement;
}

export const Popper = ({
  anchorOrigin = 'right',
  rootPaperClassName,
  paperClassName,
  children,
  open,
  onClose,
  renderButton,
}: PopperProps) => {
  const [visible, setsVisible] = useState(open || false);
  useEffect(() => {
    setsVisible(open);
  }, [open]);
  const handleClose = () => {
    onClose();
    setsVisible(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        className={clsx(styles.root, rootPaperClassName, styles[anchorOrigin], {
          [styles.isOpen]: visible,
        })}
        property="popper"
      >
        {renderButton ? renderButton() : null}
        <div className={clsx(styles.paper, paperClassName)}>{children}</div>
      </div>
    </ClickAwayListener>
  );
};
