import {
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteDivision,
  DivisionItem,
  DivisionList,
  getDivisions,
  getErrorMessages,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  useSnackbar,
} from '@spovio/ui';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DivisionAdd from './division-add/division-add';
import { DivisionTableList } from './division-table-list/division-table-list';
import styles from './division.module.scss';

/* eslint-disable-next-line */
export interface DivisionProps {}
const { LOADED, LOADING } = COMPONENT_STATE;

export function Division(props: DivisionProps) {
  const { t } = useTranslation();
  const [divisions, setDivisions] = useState<DivisionList>({} as DivisionList);
  const [componentState, setComponentState] = useState(LOADING);
  const [isDivisionDialog, setIsDivisionDialog] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [editDivision, setEditDivision] = useState(false);
  const [divisionItem, setDivisionItem] = useState<DivisionItem | undefined>();
  const { showConfirmation } = useContext(ConfirmationContext);
  const { isAdmin } = useModuleAccess('dimension_access');
  const { showSnackbar } = useSnackbar();

  const BasicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };

  const [filter, setFilter] = useState<BasicFilter>(BasicFilter);

  const getDivisionList = useCallback(
    async (_filter = clone(filter)) => {
      setComponentState(LOADING);
      try {
        const res = await getDivisions(_filter);
        setDivisions(res.data);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      } finally {
        setComponentState(LOADED);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [mounted]
  );

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      setFilter(filter);
      getDivisionList(filter);
    },
    [getDivisionList]
  );

  useEffect(() => {
    mounted && getDivisionList();
    return () => setMounted(false);
  }, [getDivisionList, mounted]);

  const getDivisionCreateButton = () => {
    if (!isAdmin) return null;
    return (
      <Button size={'s'} onClick={() => setIsDivisionDialog(true)}>
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('dimensions.addDivision')}
        </>
      </Button>
    );
  };

  const onDeleteDivision = async (id: number) => {
    try {
      showSnackbar(false);
      const res = await deleteDivision(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().division.delete,
          severity: 'success',
        });
        getDivisionList();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (division: DivisionItem) => {
    showConfirmation(true, {
      header: t('dimensions.divisionDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteDivision(division.id),
    });
  };

  const onListAction = (action: string, division: DivisionItem) => {
    if (action === 'edit') {
      setEditDivision(true);
      setDivisionItem(division);
      setIsDivisionDialog(true);
    } else if (action === 'delete') {
      deleteConfirmation(division);
    }
  };

  const getDivisionTable = () => {
    return (
      <DivisionTableList
        divisions={divisions.results}
        onListAction={(action: string, division: DivisionItem) =>
          onListAction(action, division)
        }
        componentState={componentState}
      />
    );
  };

  const onCloseDialog = () => {
    setIsDivisionDialog(false);
    setEditDivision(false);
    setDivisionItem(undefined);
  };

  const onSubmitDialog = (updated?: boolean) => {
    if (updated) {
      getDivisionList(filter);
      showSnackbar(true, {
        msg: userMsgs().division.edit,
        severity: 'success',
      });
    } else {
      getDivisionList();
      showSnackbar(true, {
        msg: userMsgs().division.add,
        severity: 'success',
      });
    }
  };

  const getAddDivisionDialog = () => {
    if (isDivisionDialog) {
      return (
        <DivisionAdd
          open={isDivisionDialog}
          onClose={onCloseDialog}
          onAdd={onSubmitDialog}
          isEdit={editDivision}
          divisionItem={divisionItem}
        />
      );
    } else return null;
  };

  const getPaginationFooter = () => {
    if (!divisions) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      divisions;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.divisions')}</h4>}
        rightContent={getDivisionCreateButton()}
      />
      {getDivisionTable()}
      {getAddDivisionDialog()}
      {getPaginationFooter()}
    </>
  );
}

export default Division;
