import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TimeIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.705 1.458a5.998 5.998 0 1 1 4.59 11.084 5.998 5.998 0 0 1-4.59-11.084"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.813 4.322v3.1L9.25 8.91"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
