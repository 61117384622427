import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ClockIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Request Time Off</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Time-off"
          transform="translate(-1293.000000, -153.000000)"
          stroke="#4461CB"
          strokeWidth="1.2"
        >
          <g id="Group-65-Copy" transform="translate(541.000000, 146.000000)">
            <g id="Group-64" transform="translate(30.000000, 0.000000)">
              <g id="Group-13-Copy" transform="translate(721.000000, 0.000000)">
                <g
                  id="Alarm,-Clock,-Time,-Timer.1"
                  transform="translate(0.000000, 6.000000)"
                >
                  <g
                    id="Request-Time-Off"
                    transform="translate(2.000061, 1.999782)"
                  >
                    <path
                      d="M3.70460576,0.458218499 C6.76527243,-0.809781501 10.2739391,0.644218499 11.5419391,3.70488517 C12.8099391,6.76555183 11.3559391,10.2742185 8.29527243,11.5422185 C5.23460576,12.8102185 1.7259391,11.3562185 0.457939097,8.29555183 C-0.809394236,5.23488517 0.643939097,1.7262185 3.70460576,0.458218499"
                      id="Path"
                    ></path>
                    <polyline
                      id="Path"
                      points="5.8119391 3.32355183 5.8119391 6.4242185 8.24927243 7.9102185"
                    ></polyline>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
