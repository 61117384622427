/**
 * @author Sachin
 * @file MenuItem component
 */
import React from 'react';
import clsx from 'clsx';
import styles from './MenuItem.module.scss';

export interface MenuItemProps extends React.HTMLAttributes<HTMLElement> {
    className?: string;
    children?: any;
    value: any;
    icon?: any;
    label?: string | number;
    data?: any;
    disabled?: boolean;
    selected?: boolean;
    selectedClassName?: string;
}

export default class MenuItem extends React.Component<MenuItemProps> {
    public constructor(props: MenuItemProps) {
        super(props);
    }

    public render(): React.ReactElement {
        const className = clsx(
            styles.menuItem,
            this.props.className,
            this.props.disabled ? styles.disabled : '',
            this.props.selected ? styles.selected : ''
        );

        return (
            <li className={className} onClick={this.props.onClick}>
                {this.props.children}
            </li>
        );
    }
}
