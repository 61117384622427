import { Button, KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from '../../';
import clsx from 'clsx';
import styles from './arrow-dual-nav.module.scss';

/* eslint-disable-next-line */
export interface ArrowDualNavProps {
  className?: string;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  onClick: (type: string) => void;
}

export function ArrowDualNav({
  className,
  leftButtonDisabled = false,
  rightButtonDisabled = false,
  onClick,
}: ArrowDualNavProps) {
  return (
    <div className={clsx(styles.headerLeftInnerNav, className)}>
      <Button
        className={clsx(styles.headerLeftInnerNavLeft, leftButtonDisabled && styles.disabled)}
        disabled={leftButtonDisabled}
        onClick={() => onClick('left')}
      >
        <KeyboardArrowLeftIcon className={styles.headerLeftInnerNavIcon} />
      </Button>
      <Button
        className={clsx(styles.headerLeftInnerNavRight, rightButtonDisabled && styles.disabled)}
        disabled={rightButtonDisabled}
        onClick={() => onClick('right')}
      >
        <KeyboardArrowRightIcon className={styles.headerLeftInnerNavIcon} />
      </Button>
    </div>
  );
}

export default ArrowDualNav;
