import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const DirectoryIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13.753 2.5A3.747 3.747 0 0 1 17.5 6.247v7.507a3.747 3.747 0 0 1-3.747 3.746H6.247A3.747 3.747 0 0 1 2.5 13.753V6.25A3.75 3.75 0 0 1 6.25 2.5h7.503Z"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.043 11.955c1.82 1.82 4.125 2.98 5.495 1.615l.333-.333a1.001 1.001 0 0 0-.133-1.533c-.325-.226-.673-.468-1.058-.74a1.011 1.011 0 0 0-1.29.107l-.376.374a8.058 8.058 0 0 1-1.355-1.102l-.001-.002a8.027 8.027 0 0 1-1.102-1.355l.373-.375c.344-.345.386-.892.107-1.29-.272-.385-.514-.734-.74-1.058a1.002 1.002 0 0 0-1.533-.133l-.332.332c-1.364 1.371-.205 3.675 1.614 5.495"
        stroke="#A2A5A8"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
