import {
  createProject,
  getBasicUserList,
  getErrorMessages,
  ProjectItem,
  updateProject,
  User,
} from '@spovio/shared';
import {
  ChooseMember,
  Dialog,
  Divider,
  FormControl,
  Input,
  InputLabel,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectAutoCompleteUser, {
  AutoCompleteUserItem,
} from '../ProjectAutoCompleteUser/ProjectAutoCompleteUser';
import SimpleUserList from '../SimpleUserList/SimpleUserList';
import styles from './project-add.module.scss';

/* eslint-disable-next-line */
export interface ProjectAddProps {
  open: boolean;
  isEdit?: boolean;
  projectItem?: ProjectItem;
  onClose: () => void;
  onAdd: (project: ProjectItem, updated?: boolean) => void;
}

export function ProjectAdd({
  open,
  isEdit,
  projectItem,
  onClose,
  onAdd,
}: ProjectAddProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const [autoCompleteKey, setAutoCompleteKey] = useState(0);
  const [name, setName] = useState(
    isEdit && projectItem ? projectItem.name : ''
  );
  const [usersList, setUsersList] = useState<User[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const { showSnackbar } = useSnackbar();
  const getUsersList = async () => {
    try {
      const response = await getBasicUserList();
      setUsersList(response.data);
      setSelectedMembers(projectItem?.users.map((user) => user.id) || []);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const patchProject = async (name: string, id: number) => {
    try {
      const res = await updateProject(id, { name, users: selectedMembers });
      onAdd(res.data, true);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const postProject = async () => {
    try {
      const res = await createProject({ name, users: selectedMembers });
      if (res.status === 201) {
        onAdd(res.data);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onClose();
    if (isEdit && projectItem) {
      patchProject(name, projectItem.id);
    } else {
      postProject();
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    setName(e.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const onSelectUser = (user: User) => {
    if (!selectedMembers.includes(user.id)) {
      setSelectedMembers([...selectedMembers, user.id]);
      usersList.sort((a, b) => {
        if (
          [...selectedMembers, user.id].includes(a.id) &&
          ![...selectedMembers, user.id].includes(b.id)
        ) {
          return -1;
        }
        return 0;
      });
    } else {
      const updatedSelectedMembers = selectedMembers.filter(
        (id) => id !== user.id
      );
      setSelectedMembers(updatedSelectedMembers);
      usersList.sort((a, b) => {
        if (
          updatedSelectedMembers.includes(a.id) &&
          !updatedSelectedMembers.includes(b.id)
        ) {
          return -1;
        }
        return 0;
      });
    }
  };

  const onUserSelect = (users: AutoCompleteUserItem[]) => {
    if (!usersList) return;
    const [user] = users;
    const newUser = usersList.find((_user) => _user.id === user.data?.id);
    if (newUser) {
      setSelectedMembers([...selectedMembers, newUser.id]);
      setAutoCompleteKey(Math.random());
    }
  };

  const onUserRemove = (user: User) => {
    const updateUsers = selectedMembers.filter((id) => id !== user.id);
    setSelectedMembers(updateUsers);
  };

  useEffect(() => {
    getUsersList();
  }, []);
  const compactMode = !!selectedMembers.length;

  return (
    <div className={styles.root}>
      <Dialog
        title={
          isEdit ? t('dimensions.editProject') : t('dimensions.addProject')
        }
        action={isEdit ? t('label.save') : t('label.add')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
        isActionDisabled={!name}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              tabIndex={0} //this is to make the input focusable
              name="name"
              validationType={['required']}
              focusAuto
              labelClassName={styles.label}
              label={t('label.name')}
              value={name}
              required={true}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              className={styles.label}
              label={t('label.projectMembers')}
            />
            {/* {usersList && (
              <ChooseMember
                className={styles.chooseMember}
                userList={usersList}
                onUserSelect={onSelectUser}
                selectedMembers={selectedMembers}
                showAddRemoveButtons
              />
            )} */}
            <div className={clsx(compactMode && styles.userSelectorWrap)}>
              <ProjectAutoCompleteUser
                className={clsx(compactMode && styles.autoCompleteInput)}
                inputClassName={styles.input}
                key={autoCompleteKey}
                userList={usersList || []}
                selectedUsers={usersList.filter((user) =>
                  selectedMembers.includes(user.id)
                )}
                onChange={onUserSelect}
                noStyle={false}
              />
              {compactMode && <Divider className={styles.divider} />}
              <SimpleUserList
                className={clsx(compactMode && styles.simpleTaskListWrap)}
                onUserRemove={onUserRemove}
                users={usersList.filter((user) =>
                  selectedMembers.includes(user.id)
                )}
                variant={compactMode ? 'compact' : undefined}
              />
            </div>
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default ProjectAdd;
