import {
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteDepartment,
  DepartmentItem,
  DepartmentList,
  getDepartments,
  getErrorMessages,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  useSnackbar,
} from '@spovio/ui';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DepartmentAdd from './department-add/department-add';
import { DepartmentTableList } from './department-table-list/department-table-list';
import './department.module.scss';
import styles from './department.module.scss';

/* eslint-disable-next-line */
export interface DepartmentProps {}

const { LOADED, LOADING } = COMPONENT_STATE;

export function Department(props: DepartmentProps) {
  const { t } = useTranslation();
  const [departments, setDepartments] = useState<DepartmentList>(
    {} as DepartmentList
  );
  const [componentState, setComponentState] = useState(LOADING);
  const [isDepartmentDialog, setIsDepartmentDialog] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [editDepartment, setEditDepartment] = useState(false);
  const [departmentItem, setDepartmentItem] = useState<
    DepartmentItem | undefined
  >();
  const { showConfirmation } = useContext(ConfirmationContext);
  const { showSnackbar } = useSnackbar();
  const { isAdmin } = useModuleAccess('dimension_access');

  const BasicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };

  const [filter, setFilter] = useState<BasicFilter>(BasicFilter);

  const getDepartmentList = useCallback(
    async (_filter = clone(filter)) => {
      setComponentState(LOADING);
      try {
        const res = await getDepartments(_filter);
        setDepartments(res.data);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      } finally {
        setComponentState(LOADED);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [mounted]
  );

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      setFilter(filter);
      getDepartmentList(filter);
    },
    [getDepartmentList]
  );

  useEffect(() => {
    mounted && getDepartmentList();
    return () => setMounted(false);
  }, [getDepartmentList, mounted]);

  const getDepartmentCreateButton = () => {
    if (!isAdmin) {
      return null;
    }
    return (
      <Button size={'s'} onClick={() => setIsDepartmentDialog(true)}>
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('dimensions.addDepartment')}
        </>
      </Button>
    );
  };

  const onDeleteDepartment = async (id: number) => {
    try {
      showSnackbar(false);
      const res = await deleteDepartment(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().department.delete,
          severity: 'success',
        });
        getDepartmentList();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (department: DepartmentItem) => {
    showConfirmation(true, {
      header: t('dimensions.departmentDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteDepartment(department.id),
    });
  };

  const onListAction = (action: string, department: DepartmentItem) => {
    if (action === 'edit') {
      setEditDepartment(true);
      setDepartmentItem(department);
      setIsDepartmentDialog(true);
    } else if (action === 'delete') {
      deleteConfirmation(department);
    }
  };

  const getDepartmentTable = () => {
    return (
      <DepartmentTableList
        departments={departments.results}
        onListAction={(action: string, department: DepartmentItem) =>
          onListAction(action, department)
        }
        componentState={componentState}
      />
    );
  };

  const onCloseDialog = () => {
    setIsDepartmentDialog(false);
    setEditDepartment(false);
    setDepartmentItem(undefined);
  };

  const onSubmitDialog = (updated?: boolean) => {
    if (updated) {
      getDepartmentList(filter);
      showSnackbar(true, {
        msg: userMsgs().department.edit,
        severity: 'success',
      });
    } else {
      getDepartmentList();
      showSnackbar(true, {
        msg: userMsgs().department.add,
        severity: 'success',
      });
    }
  };

  const getAddDepartmentDialog = () => {
    if (isDepartmentDialog) {
      return (
        <DepartmentAdd
          open={isDepartmentDialog}
          onClose={onCloseDialog}
          onAdd={onSubmitDialog}
          isEdit={editDepartment}
          departmentItem={departmentItem}
        />
      );
    } else return null;
  };

  const getPaginationFooter = () => {
    if (!departments) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      departments;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };
  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.departments')}</h4>}
        rightContent={getDepartmentCreateButton()}
      />
      {getDepartmentTable()}
      {getAddDepartmentDialog()}
      {getPaginationFooter()}
    </>
  );
}

export default Department;
