// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".switch-nav-button_switch_wrap__pkJn4 {\n  height: 28px;\n  border-radius: 5px;\n  background-color: #efeff1;\n  padding: 2px;\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n}\n.switch-nav-button_switch_wrap__pkJn4 .switch-nav-button_btn__2cOX_ {\n  height: 24px;\n  width: fit-content;\n  width: 65px;\n  padding: 0 10px;\n  border-radius: 4px;\n  background-color: transparent;\n  color: #707379;\n  border: none;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n.switch-nav-button_switch_wrap__pkJn4 .switch-nav-button_btn__2cOX_.switch-nav-button_active__1w6Vs {\n  background-color: #ffffff;\n  box-shadow: 0 0 4px -1px rgba(37, 37, 38, 0.06), 0 2px 4px -2px rgba(37, 37, 38, 0.2);\n  color: #24252a;\n  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n}", ""]);
// Exports
exports.locals = {
	"switch_wrap": "switch-nav-button_switch_wrap__pkJn4",
	"btn": "switch-nav-button_btn__2cOX_",
	"active": "switch-nav-button_active__1w6Vs"
};
module.exports = exports;
