import clsx from 'clsx';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../index';
import { SearchResetIcon } from '../icons';
import SearchIcon from '../icons/search-icon';
import styles from './search-bar.module.scss';

export interface SearchBarProps {
  onSearch: (text: string) => void;
  placeholder?: string;
  containerClassName?: string;
  focusAuto?: boolean;
  trigger?: 'onEnter' | 'onChange';
  showSearchIcon?: boolean;
  showResetIcon?: boolean;
}

export function SearchBar({
  onSearch,
  placeholder,
  containerClassName,
  focusAuto,
  showSearchIcon = true,
  trigger = 'onChange',
  showResetIcon = true,
}: SearchBarProps) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const inputEl = React.useRef<HTMLInputElement>();

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (trigger === 'onChange') {
      onSearch(searchText);
    }
  };

  const onSearchReset = () => {
    setSearchText('');
    onSearch('');
    // inputEl?.current.focus();
  };

  const onkeypress = (e: { key: string }) => {
    if (trigger === 'onEnter' && e.key === 'Enter') {
      onSearch(searchText);
    }
  };

  const searchIconComponent = () => {
    if (showSearchIcon) {
      return (
        <Button
          color="default"
          variant="text"
          ghost
          className={styles.searchIcon}
        >
          <SearchIcon className={styles.searchIcon} />
        </Button>
      );
    } else return null;
  };
  return (
    <div className={clsx(containerClassName, styles.searchInputWrap)}>
      <Input
        inputRef={inputEl}
        focusAuto={focusAuto}
        inputWrapClass={styles.inputWrap}
        className={styles.input}
        placeholder={placeholder || t('label.search') + ' ...'}
        onChange={onChange}
        // onBlur={onBlur}
        // autoComplete={'off'}
        onFocus={(e) => {
          e.stopPropagation();
        }}
        inputProps={{
          onKeyDown: onkeypress,
          ref: inputEl,
        }}
        onKeyDown={onkeypress}
        icon={searchIconComponent()}
        noStyle
        value={searchText}
        append={
          searchText.trim() && showResetIcon ? (
            <Button
              ghost
              variant="text"
              color="default"
              onClick={onSearchReset}
            >
              <SearchResetIcon className={styles.searchResetIcon} />
            </Button>
          ) : null
        }
      />
    </div>
  );
}

export default SearchBar;
