import { getPaginationCounts } from '@spovio/shared';
import clsx from 'clsx';
import { parseUrl } from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDownIcon } from '../icons';
import { Select } from '../select/select';
import styles from './pagination.module.scss';
interface PaginationProps {
  className?: string;
  total: number;
  pageSize: number;
  page: number;
  onSelect: (pageSize: string) => void;
  onChange: (page: string) => void;
  next?: number;
  prev?: number;
}

const ShowPerPage = [
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
];

export const Pagination = ({
  className,
  total,
  pageSize,
  page,
  next,
  prev,
  onSelect,
  onChange,
}: PaginationProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState(pageSize);
  const getParsedQueryFromUrl = (url: string) => {
    const parsedUrl = parseUrl(url);
    const query: {
      page?: string;
    } = parsedUrl.query;
    return query;
  };
  const onPrevLink = () => {
    if (!prev) return;
    // const query = getParsedQueryFromUrl(prev);
    const page = prev?.toString() || '1';
    onChange(page);
  };

  const onNextLink = () => {
    if (!next) return;
    // const query = getParsedQueryFromUrl(next);
    const page = next.toString();
    onChange(page);
  };

  const { first, second } = getPaginationCounts(page, pageSize, total);
  return (
    <div className={clsx(styles.pagination, className)}>
      <div className={styles.results}>
        <div className={styles.text}>{t('label.show')}:</div>
        <Select
          rootPaperClassName={styles.popper}
          paperClassName={styles.paper}
          size="xs"
          options={ShowPerPage}
          selectedOption={{
            label:
              ShowPerPage.find((s) => s.value === size?.toString())?.label ??
              '',
            value: `${size}`,
          }}
          onSelect={(value) => {
            setSize(Number(value));
            onSelect(value);
          }}
          hideSearchHeader
        />
        <div className={styles.text}>{t('label.perPage')}</div>
      </div>
      <div className={styles.pages}>
        <div className={styles.text}>
          <span className={styles.strong}>{first}</span>-
          <span className={styles.strong}>{second}</span>
          {' of'}
          <span className={styles.strong}>{` ${total}`}</span>
        </div>
        <button
          className={clsx(
            styles.control,
            styles.prev,
            !prev && styles.disabled
          )}
          onClick={onPrevLink}
        >
          <KeyboardArrowDownIcon />
        </button>
        <button
          className={clsx(
            styles.control,
            styles.next,
            !next && styles.disabled
          )}
          onClick={onNextLink}
        >
          <KeyboardArrowDownIcon />
        </button>
      </div>
    </div>
  );
};
