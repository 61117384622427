import React from 'react';
type PlusIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const PlusIcon = (props: PlusIconProps) => {
  return (
    <svg
      {...props}
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Profile"
          transform="translate(-1310.000000, -155.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="Group-67" transform="translate(541.000000, 146.000000)">
            <g id="Group-54" transform="translate(30.000000, 0.000000)">
              <g id="Group-12" transform="translate(740.000000, 0.000000)">
                <path
                  d="M4,10 L4,18 M3.061617e-17,14 L8,14"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
