// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contact-tag-details_header__3szfm {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.contact-tag-details_header__3szfm .contact-tag-details_title__12X93 {\n  color: #252526;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: -0.25px;\n  line-height: 28px;\n}\n.contact-tag-details_header__3szfm .contact-tag-details_closeBtn__1iprp {\n  margin-right: 10px;\n}\n.contact-tag-details_header__3szfm .contact-tag-details_bottomContent__3qIda {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.contact-tag-details_header__3szfm .contact-tag-details_bottomContent__3qIda .contact-tag-details_filterButton__3nLPo {\n  padding: 6px 8px;\n}\n.contact-tag-details_header__3szfm .contact-tag-details_bottomContent__3qIda .contact-tag-details_filterButton__3nLPo .contact-tag-details_filterName__O0P8u {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.contact-tag-details_searchBar__1nJ04 {\n  border: 1px solid #d4d4d5;\n  border-radius: 6px;\n  background-color: #fff;\n  padding: 0 12px;\n  outline: 0;\n  width: auto;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  width: 300px;\n}\n\n.contact-tag-details_pagination__3QoVP {\n  padding: 25px 30px;\n}\n.contact-tag-details_pagination__3QoVP .contact-tag-details_paper__2vRZl {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}", ""]);
// Exports
exports.locals = {
	"header": "contact-tag-details_header__3szfm",
	"title": "contact-tag-details_title__12X93",
	"closeBtn": "contact-tag-details_closeBtn__1iprp",
	"bottomContent": "contact-tag-details_bottomContent__3qIda",
	"filterButton": "contact-tag-details_filterButton__3nLPo",
	"filterName": "contact-tag-details_filterName__O0P8u",
	"searchBar": "contact-tag-details_searchBar__1nJ04",
	"pagination": "contact-tag-details_pagination__3QoVP",
	"paper": "contact-tag-details_paper__2vRZl"
};
module.exports = exports;
