// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reimbursement-form_content__3WnLw {\n  padding: 0 25px;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_tabs__3vS6L {\n  padding: 20px 0;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_tabs__3vS6L :first-child {\n  margin-right: 20px;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_row__2Zbqo {\n  display: flex;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_row__2Zbqo .reimbursement-form_leftCol__A7p7k {\n  margin-right: 10px;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_row__2Zbqo .reimbursement-form_currencyInput__3jhAY {\n  padding-left: 40px !important;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_row__2Zbqo .reimbursement-form_inputIcon__3jSiJ {\n  font-size: 14px;\n  width: 40px;\n  color: #6d6b70;\n}\n.reimbursement-form_content__3WnLw .reimbursement-form_unit__h4uuO {\n  color: #707379;\n  margin-right: 10px;\n  font-size: 14px;\n}\n\n.reimbursement-form_selectOptions__1iAtZ {\n  display: flex;\n  align-items: center;\n}\n\n.reimbursement-form_userText__6SDLN {\n  line-height: 16px;\n  font-weight: 400;\n}\n\n.reimbursement-form_inputWrap__2gTTq .reimbursement-form_input__1hQu1 {\n  padding-right: 50px !important;\n}\n\n.reimbursement-form_currencyIcon__1YmFz {\n  font-size: 14px;\n  width: 40px;\n  color: #6d6b70;\n}", ""]);
// Exports
exports.locals = {
	"content": "reimbursement-form_content__3WnLw",
	"tabs": "reimbursement-form_tabs__3vS6L",
	"row": "reimbursement-form_row__2Zbqo",
	"leftCol": "reimbursement-form_leftCol__A7p7k",
	"currencyInput": "reimbursement-form_currencyInput__3jhAY",
	"inputIcon": "reimbursement-form_inputIcon__3jSiJ",
	"unit": "reimbursement-form_unit__h4uuO",
	"selectOptions": "reimbursement-form_selectOptions__1iAtZ",
	"userText": "reimbursement-form_userText__6SDLN",
	"inputWrap": "reimbursement-form_inputWrap__2gTTq",
	"input": "reimbursement-form_input__1hQu1",
	"currencyIcon": "reimbursement-form_currencyIcon__1YmFz"
};
module.exports = exports;
