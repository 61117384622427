// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onboard_root__3Mz0m {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  padding-top: 180px;\n}\n.onboard_root__3Mz0m .onboard_title__2XCJZ {\n  position: absolute;\n  top: 25px;\n  margin: 0;\n  width: 125px;\n  height: 38px;\n  left: 20px;\n}\n\n.onboard_innerContent__1b8YX {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 420px;\n  width: calc(100% - 16px);\n}\n.onboard_innerContent__1b8YX .onboard_heading__2geOh {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n.onboard_innerContent__1b8YX .onboard_helperText__2USHH {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin-bottom: 56px;\n}\n.onboard_innerContent__1b8YX .onboard_option__1E1sE {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 25px;\n  color: #6d6971;\n  font-size: 14px;\n  letter-spacing: -0.22px;\n  line-height: 17px;\n  text-align: center;\n}\n.onboard_innerContent__1b8YX .onboard_option__1E1sE .onboard_linkText__JiPXK {\n  margin-left: 3px;\n}", ""]);
// Exports
exports.locals = {
	"root": "onboard_root__3Mz0m",
	"title": "onboard_title__2XCJZ",
	"innerContent": "onboard_innerContent__1b8YX",
	"heading": "onboard_heading__2geOh",
	"helperText": "onboard_helperText__2USHH",
	"option": "onboard_option__1E1sE",
	"linkText": "onboard_linkText__JiPXK"
};
module.exports = exports;
