import { Popover } from '@material-ui/core';
import { toLowerCase } from '@spovio/shared';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '../divider/divider';
import { CloseIcon, KeyboardArrowDownIcon } from '../icons';
import { InputPlaceholder } from '../input-placeholder/input-placeholder';
import { PopperProps } from '../popper/popper';
import { SearchBar } from '../search-bar/search-bar';
import { TagItem } from '../tag-item/tag-item';
import styles from './select.module.scss';

export interface SelectMenuItem {
  label: string;
  value: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export interface SelectButtonProps {
  onClick: (e: any) => void;
  onKeyPress: () => void;
  isOpen: boolean;
}
interface SelectProps
  extends Pick<
    PopperProps,
    'anchorOrigin' | 'rootPaperClassName' | 'paperClassName'
  > {
  id?: string;
  open?: boolean;
  options: SelectMenuItem[];
  showOptionalAdd?: boolean;
  selectedOption?: SelectMenuItem;
  placeholder?: string;
  searchPlaceholder?: string;
  filterText?: string;
  size?: 'xs' | 's' | 'm' | 'l';
  listClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  showLabelAsTag?: boolean;
  showDropArrowIcon?: boolean;
  hideSearchHeader?: boolean;
  resetAfterSelect?: boolean;
  showResetIcon?: boolean;
  footer?: ReactNode | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (value: string, data?: any) => void;
  onSearch?: (text: string) => void;
  renderOption?: (options: SelectMenuItem) => React.ReactElement;
  filterOptions?: (
    options: SelectMenuItem[],
    inputValue: string
  ) => SelectMenuItem[];
  renderButton?: (props: SelectButtonProps) => React.ReactElement;
  onFooterClick?: (searchText: string) => void;
  onClose?: () => void;
  onClear?: () => void;
}

export const Select = ({
  id,
  open = false,
  options,
  showOptionalAdd,
  selectedOption,
  footer,
  placeholder,
  inputClassName,
  searchPlaceholder,
  rootPaperClassName,
  paperClassName,
  listClassName,
  disabled,
  size = 'm',
  anchorOrigin = 'left',
  hideSearchHeader = false,
  showLabelAsTag = false,
  showDropArrowIcon = true,
  showResetIcon = false,
  resetAfterSelect,
  filterText = '',
  onSelect,
  onSearch,
  renderOption,
  renderButton,
  onFooterClick,
  filterOptions,
  onClose,
  onClear,
}: SelectProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(open || false);
  const [cursor, setCursor] = useState(0);
  const [selected, setSelected] = useState(selectedOption);
  const [searchText, setSearchText] = useState(filterText);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popperStyles, setPopperStyles] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setSelected(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setSearchText(filterText);
  }, [filterText]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setPopperStyles({
      width: e.currentTarget.offsetWidth,
      height: e.currentTarget.offsetHeight,
    });
    setVisible(true);
  };

  const handleReset = (e: any) => {
    e.stopPropagation();
    setSelected({} as SelectMenuItem);
    setSearchText('');
    onClear && onClear();
  };

  const getFilteredList = (options: SelectMenuItem[]) => {
    let filteredOptions = options.filter(
      (item) => toLowerCase(item.label).indexOf(toLowerCase(searchText)) !== -1
    );
    if (showOptionalAdd) {
      const exactMatch = options.some(
        (option) => toLowerCase(option.label) === toLowerCase(searchText)
      );
      !exactMatch &&
        filteredOptions.push({
          label: searchText.trim(),
          value: '0',
          data: { type: 'add', searchText: searchText.trim() },
        });
    }
    if (filterOptions) {
      filteredOptions = filterOptions(filteredOptions, searchText);
    }
    return filteredOptions;
  };

  const menu = () => {
    const filteredOptions = getFilteredList(options);
    return (
      <ul
        className={clsx(styles.options, listClassName)}
        onKeyDown={handleKeyDown}
      >
        {getListMarkup(filteredOptions)}
      </ul>
    );
  };

  const getListMarkup = (filteredOption: SelectMenuItem[]) => {
    if (!filteredOption.length) {
      return getNoDataContent();
    }
    return filteredOption.map((item, i) => {
      const handleItemClick = (e: any) => {
        e.stopPropagation();
        onItemSelect(item, item.data);
      };
      const isItemInSelection = item.value === selected?.value;
      return (
        <li
          key={i}
          className={clsx(styles.option, {
            [styles.disabled]: item.disabled && !isItemInSelection,
            [styles.isActive]: cursor === i,
            [styles.selected]: isItemInSelection,
          })}
          onClick={handleItemClick}
          onKeyPress={handleItemClick}
        >
          {renderOption ? renderOption(item) : item.label}
        </li>
      );
    });
  };

  const getNoDataContent = () => {
    return <div className={styles.noData}>{t('extension.noDataFound')}</div>;
  };

  const onSearchText = (text: string) => {
    setSearchText(text);
    onSearch && onSearch(text);
  };

  const searchHeader = () => {
    if (hideSearchHeader) return null;
    return (
      <div
        className={styles.searchContainer}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <SearchBar
          onSearch={onSearchText}
          focusAuto
          placeholder={searchPlaceholder}
          showSearchIcon={false}
          showResetIcon={false}
          containerClassName={styles.selectSearchInput}
        />
        <Divider />
      </div>
    );
  };

  const getFooter = () => {
    if (!footer) return null;
    return (
      <>
        <Divider />
        <button
          className={styles.footer}
          onClick={() => {
            handleClose();
            if (onFooterClick) onFooterClick(searchText);
          }}
        >
          {footer}
        </button>
      </>
    );
  };

  const handleKeyDown = (e: { keyCode: number }) => {
    const filteredOptions = getFilteredList(options);
    if (e.keyCode === 38 && cursor > 0) {
      setCursor((prev) => prev - 1);
    } else if (e.keyCode === 40 && cursor < filteredOptions.length - 1) {
      setCursor((prev) => prev + 1);
    } else if (e.keyCode === 13) {
      const item = filteredOptions.find((i, index) => index === cursor);
      if (item && !item.disabled) {
        onItemSelect(item, item.data);
      }
    }
  };

  const getActionInputContent = () => {
    const hasSelection = !!selected?.value;
    return (
      <span className={styles.inputLabel}>
        {hasSelection && showLabelAsTag ? (
          <TagItem
            label={selected?.label || ''}
            onDelete={() => setSelected({} as SelectMenuItem)}
          />
        ) : hasSelection ? (
          selected?.label
        ) : (
          placeholder
        )}
      </span>
    );
  };

  const renderButtonInput = () => {
    const defaultProps = {
      onClick: handleClick,
      onKeyPress: () => setVisible(true),
      isOpen: visible,
    };
    if (renderButton) {
      return renderButton(defaultProps);
    }
    const { isOpen, ...restProps } = defaultProps;
    return (
      <InputPlaceholder
        id={id}
        className={clsx(styles.inputWrap, inputClassName)}
        disabled={disabled}
        hasValue={!!selected?.value}
        active={isOpen}
        append={
          showDropArrowIcon || showResetIcon ? (
            <>
              {showResetIcon && (
                <CloseIcon className={styles.resetIcon} onClick={handleReset} />
              )}
              {showDropArrowIcon && (
                <KeyboardArrowDownIcon className={styles.popupIcon} />
              )}
            </>
          ) : null
        }
        showLabelAsTag={showLabelAsTag}
        renderInputContent={getActionInputContent}
        size={size}
        {...restProps}
      />
    );
  };

  useEffect(() => {
    const index = options.findIndex((rank) => rank.value === selected?.value);
    setCursor(index);
  }, [options, selected, visible]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onItemSelect = (item: SelectMenuItem, data: any) => {
    setSelected(item);
    onSelect(item.value, data);
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
    setAnchorEl(null);
    setCursor(0);
    setSearchText('');
    onClose && onClose();
    if (resetAfterSelect) {
      setSelected({} as SelectMenuItem);
    }
  };
  return (
    <div className={clsx(styles.dropdown, visible && styles.isOpen)}>
      {renderButtonInput()}
      <Popover
        open={visible}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: popperStyles.width,
            maxWidth: popperStyles.width,
            marginTop: 10,
          },
        }}
      >
        <>
          {searchHeader()}
          {menu()}
          {getFooter()}
        </>
      </Popover>
    </div>
  );
};
