// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".time-approval_submit__3mUbx {\n  padding: 25px 30px;\n}\n.time-approval_submit__3mUbx button {\n  font-size: 14px;\n  height: 36px;\n  padding: 0 20px;\n  letter-spacing: -0.11px;\n  line-height: 17px;\n}\n\n.time-approval_filterWrap__2rCgD {\n  display: flex;\n  align-items: center;\n  margin-right: 26px;\n}", ""]);
// Exports
exports.locals = {
	"submit": "time-approval_submit__3mUbx",
	"filterWrap": "time-approval_filterWrap__2rCgD"
};
module.exports = exports;
