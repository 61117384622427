import React from 'react';

import clsx from 'clsx';

import styles from './form-control.module.scss';

export interface FormControlProps extends React.HTMLAttributes<HTMLElement> {
  fullWidth?: boolean;
  gutter?: 'xs' | 's' | 'm' | 'l' | 'xl';
  flex?: boolean;
  noStyle?: boolean;
}

export const FormControl = ({
  fullWidth,
  gutter = 'm',
  flex,
  className,
  noStyle,
  ...props
}: FormControlProps) => {
  const classes = clsx(styles.root, className, styles[gutter], {
    [styles.flex]: flex,
    [styles.fullWidth]: fullWidth,
    [styles.noStyle]: noStyle,
  });
  return (
    <div className={classes} {...props}>
      {props.children}
    </div>
  );
};
