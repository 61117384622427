// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input-label_root__2IP1C {\n  color: #000000;\n  margin-bottom: 8px;\n  letter-spacing: -0.25px;\n  display: block;\n}\n.input-label_root__2IP1C .input-label_required__37AfA {\n  color: #ff5858;\n}\n\n.input-label_s__AglT8 {\n  font-size: 13px;\n  line-height: 20px;\n}\n\n.input-label_m__15FLR {\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.input-label_l__TWyye {\n  font-size: 16px;\n  line-height: 22px;\n  margin-bottom: 16px;\n}\n\n.input-label_bold__3UAOk {\n  font-weight: 500;\n}", ""]);
// Exports
exports.locals = {
	"root": "input-label_root__2IP1C",
	"required": "input-label_required__37AfA",
	"s": "input-label_s__AglT8",
	"m": "input-label_m__15FLR",
	"l": "input-label_l__TWyye",
	"bold": "input-label_bold__3UAOk"
};
module.exports = exports;
