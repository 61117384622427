import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const FilesIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="14px"
      height="18px"
      viewBox="0 0 14 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-41"
          transform="translate(-12.000000, -7.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g
            id="Documents,-File,-Dots"
            transform="translate(8.000000, 5.000000)"
          >
            <g id="Group" transform="translate(4.760000, 3.000000)">
              <path
                d="M11.9235556,3.03466667 L9.40977778,0.520888889 C9.07644444,0.187555556 8.624,0 8.15288889,0 L1.77777778,0 C0.795555556,0 -7.89491929e-16,0.795555556 -7.89491929e-16,1.77777778 L-7.89491929e-16,14.2222222 C-7.89491929e-16,15.2044444 0.795555556,16 1.77777778,16 L10.6666667,16 C11.6488889,16 12.4444444,15.2044444 12.4444444,14.2222222 L12.4444444,4.29155556 C12.4444444,3.82044444 12.2568889,3.368 11.9235556,3.03466667 L11.9235556,3.03466667 Z"
                id="Path"
              ></path>
              <path
                d="M12.4444444,4.44444444 L8.88888889,4.44444444 C8.39822222,4.44444444 8,4.04622222 8,3.55555556 L8,0"
                id="Path"
              ></path>
              <line
                x1="2.66666667"
                y1="10.1066667"
                x2="7.24528018"
                y2="10.1066667"
                id="Path"
              ></line>
              <line
                x1="2.66666667"
                y1="12.7733333"
                x2="9.77777778"
                y2="12.7733333"
                id="Path"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
