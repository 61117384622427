import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const DeleteIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="14px"
      viewBox="0 0 12 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Delete</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="Departments" transform="translate(-1256.000000, -321.000000)">
          <g id="Group-4" transform="translate(1240.000000, 276.000000)">
            <g id="Group-111-Copy-7" transform="translate(6.000000, 37.000000)">
              <g id="Group-7" transform="translate(8.000000, 6.000000)">
                <g id="Group-57" transform="translate(0.000000, 1.000000)">
                  <g id="Delete" transform="translate(2.857143, 2.285714)">
                    <line
                      x1="0"
                      y1="2.00494397"
                      x2="10.2857143"
                      y2="2.00494397"
                      id="Path"
                      stroke="#828385"
                    ></line>
                    <path
                      d="M9.55102041,2.00494397 L9.55102041,9.84286489 C9.55102041,10.7186264 8.84107541,11.4285714 7.96531387,11.4285714 L2.32040042,11.4285714 C1.44463888,11.4285714 0.734693878,10.7186264 0.734693878,9.84286489 L0.734693878,2.00494397 L0.734693878,2.00494397"
                      id="Path"
                      stroke="#828385"
                    ></path>
                    <path
                      d="M7.34693878,1.58236582 L7.34693878,1.26856523 C7.34693878,0.567956001 6.77898277,-1.28699828e-16 6.07837354,0 L4.20734074,0 C3.50673151,1.28699828e-16 2.93877551,0.567956001 2.93877551,1.26856523 L2.93877551,1.58236582 L2.93877551,1.58236582"
                      id="Path"
                      stroke="#828385"
                    ></path>
                    <line
                      x1="3.67346939"
                      y1="4.90452165"
                      x2="3.67346939"
                      y2="8.52899375"
                      id="Path"
                      stroke="#828385"
                    ></line>
                    <line
                      x1="6.6122449"
                      y1="4.90452165"
                      x2="6.6122449"
                      y2="8.52899375"
                      id="Path"
                      stroke="#707379"
                    ></line>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
