import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const BurgerMenuIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 22 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 8V10H0V8H22ZM22 0V2H0V0H22Z" fill="#24252A" />
    </svg>
  );
};
