import {
  changePassword,
  getErrorMessages,
  userMsgs,
  validateForm,
} from '@spovio/shared';
import {
  Button,
  FormControl,
  FormText,
  Header,
  Input,
  useSnackbar,
} from '@spovio/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './change-password.module.scss';

/* eslint-disable-next-line */
export interface ChangePasswordProps {}

interface passwordType {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export function ChangePassword(props: ChangePasswordProps) {
  const { t } = useTranslation();
  const [formErrorMessage, setFormErrorMessage] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });
  const [values, setValues] = useState({} as passwordType);

  const errors = {
    oldPasswordRepeatation: 'New password should not be same as old password',
    passwordMismatch: 'New password and confirm password must be same',
  };

  const { showSnackbar } = useSnackbar();

  const handleChange = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    setValues(newValues);
    handleError(newValues);
  };

  const handleError = (values: passwordType) => {
    const formErrors: passwordType = {} as passwordType;

    if (values.new_password && values.new_password === values.old_password) {
      formErrors.new_password = errors.oldPasswordRepeatation;
    } else if (
      values.confirm_password &&
      values.new_password !== values.confirm_password
    ) {
      formErrors.confirm_password = errors.passwordMismatch;
    } else {
      formErrors.new_password = '';
      formErrors.confirm_password = '';
    }
    setFormErrorMessage(formErrors);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrorMessage({
      old_password: '',
      new_password: '',
      confirm_password: '',
    });
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      const res = await changePassword(payload);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().password.edit,
          severity: 'success',
        });
        setValues({
          old_password: '',
          new_password: '',
          confirm_password: '',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getSubmitButton = () => (
    <Button
      size={'s'}
      className={styles.actionBtn}
      disabled={!isValid()}
      onClick={handleSubmit}
    >
      {t('label.submit')}
    </Button>
  );

  const isValid = () => {
    return (
      validateForm.isRequired(values.old_password) &&
      validateForm.isRequired(values.new_password) &&
      validateForm.isRequired(values.confirm_password) &&
      values.new_password === values.confirm_password &&
      values.new_password !== values.old_password
    );
  };

  const getFormFields = () => (
    <div className={styles.root}>
      <div className={styles.innerContent}>
        <FormControl gutter="l" className={styles.form}>
          <Input
            placeholder={t('settings.oldPassword')}
            type="password"
            name="old_password"
            label={t('settings.oldPassword')}
            required
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={() => handleError(values)}
            value={values.old_password}
            size="l"
          />
          <FormText type="invalid">{formErrorMessage.old_password}</FormText>
        </FormControl>
        <FormControl gutter="l" className={styles.form}>
          <Input
            placeholder={t('settings.newPassword')}
            type="password"
            name="new_password"
            label={t('settings.newPassword')}
            required
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={() => handleError(values)}
            value={values.new_password}
            size="l"
            status={formErrorMessage.new_password ? 'invalid' : false}
          />
          <FormText type="invalid">{formErrorMessage.new_password}</FormText>
        </FormControl>
        <FormControl gutter="l" className={styles.form}>
          <Input
            placeholder={t('settings.confirmNewPassword')}
            type="password"
            name="confirm_password"
            label={t('settings.confirmNewPassword')}
            required
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={() => handleError(values)}
            value={values.confirm_password}
            size="l"
            status={formErrorMessage.confirm_password ? 'invalid' : false}
          />
          <FormText type="invalid">
            {formErrorMessage.confirm_password}
          </FormText>
        </FormControl>
      </div>
    </div>
  );

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('settings.changePassword')}</h4>}
        rightContent={getSubmitButton()}
      />
      {getFormFields()}
    </>
  );
}

export default ChangePassword;
