import {
  appMsgs,
  appRoute,
  archiveEmployee,
  clone,
  COMPONENT_STATE,
  deleteEmployee,
  Employee,
  EmployeeList,
  getErrorMessages,
  getPeopleList,
  getPeopleListExport,
  PeopleFilter,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  Select,
  useSnackbar,
} from '@spovio/ui';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import PeopleListTable from '../people-list-table/people-list-table';
import styles from './people-directory.module.scss';

/* eslint-disable-next-line */
export type PeopleDirectoryProps = RouteComponentProps;

const PeopleTypes = () => [
  {
    label: t('label.active'),
    value: 'active',
  },
  {
    label: t('label.inActive'),
    value: 'inactive',
  },
];

const { LOADED, LOADING } = COMPONENT_STATE;
export function PeopleDirectory(props: PeopleDirectoryProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [people, setPeople] = useState<EmployeeList>({} as EmployeeList);
  const [peopleType, setPeopleType] = useState(PeopleTypes()[0].value);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { isAdmin } = useModuleAccess('people_access');
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    getPeople();
  }, []);

  const PeopleFilter: PeopleFilter = {
    page: '1',
    page_size: '25',
    is_active: true,
  };
  const [filter, setFilter] = useState<PeopleFilter>(PeopleFilter);

  const getPeople = useCallback(
    async (_filter = clone(filter)) => {
      setComponentState(LOADING);
      try {
        const res = await getPeopleList(_filter);
        setPeople(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFilter = useCallback(
    (filter: PeopleFilter) => {
      setComponentState(LOADING);
      setFilter(filter);
      getPeople(filter);
    },
    [getPeople]
  );

  const handleSelect = (field: string, value: string) => {
    setPeopleType(value);
    onFilter({ ...filter, is_active: value === 'active' });
  };

  const editEmployee = (employee: Employee) => {
    props.history.push({
      pathname: appRoute.getRoute(`/people/${employee.id}/edit`),
    });
  };

  const onDeleteEmployee = async (id: number) => {
    try {
      const res = await deleteEmployee(id);
      if (res.status === 204) {
        setPeople((prev) => {
          const newPeople = clone(prev);
          newPeople.results = newPeople.results.filter((emp) => emp.id !== id);
          return newPeople;
        });
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().people.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const deleteConfirmation = (employee: Employee) => {
    showConfirmation(true, {
      header: t('people.employeeDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteEmployee(employee.id),
    });
  };

  const exportEmployee = async () => {
    try {
      const res = await getPeopleListExport(filter.is_active ? true : false);
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'spovio-employee_list.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const _archiveEmployee = async (employee: Employee) => {
    try {
      const res = await archiveEmployee(
        employee.id,
        filter.is_active ? false : true
      );
      if (res.status === 200) {
        getPeople(filter);
        showSnackbar(true, {
          msg: filter.is_active
            ? userMsgs().people.archive
            : userMsgs().people.unarchive,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onListAction = (dropId: string, employee: Employee) => {
    switch (dropId) {
      case 'edit':
        editEmployee(employee);
        break;
      case 'archive':
        _archiveEmployee(employee);
        break;
      case 'delete':
        deleteConfirmation(employee);
        break;
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const getEmployeeCreateButton = (buttonContent?: string, size?: 'm') => {
    if (!isAdmin) return null;
    return (
      <div>
        <Button
          size={size || 's'}
          style={{ marginRight: '10px' }}
          onClick={exportEmployee}
        >
          {t('label.export')}
        </Button>
        <Button
          size={size || 's'}
          component={NavLink}
          to={appRoute.getRoute(`/people/add`)}
        >
          {buttonContent || (
            <>
              <PlusIcon className={styles.plusIcon} />
              {t('people.addEmployee')}
            </>
          )}
        </Button>
      </div>
    );
  };

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
        <Select
          inputClassName={styles.statusSelector}
          options={PeopleTypes()}
          selectedOption={{
            label:
              PeopleTypes().find((type) => type.value === peopleType)?.label ??
              '',
            value: `${peopleType}`,
          }}
          onSelect={(value) => handleSelect('type', value)}
          paperClassName={styles.statusSelectorPaper}
          size="s"
          hideSearchHeader
        />
      </div>
    );
  };

  const getPeopleTable = () => {
    if (!people) return null;
    else
      return (
        <PeopleListTable
          people={people}
          filter={filter}
          onFilter={onFilter}
          componentState={componentState}
          onListAction={onListAction}
        />
      );
  };

  const getPaginationFooter = () => {
    // if (isLoading) return null;
    const { has_next, has_prev, total_count, page_size, page, links } = people;
    // if (!total) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.directory')}</h4>}
        rightContent={getEmployeeCreateButton()}
        bottomContent={getFilters()}
      />
      {getPeopleTable()}
      {getPaginationFooter()}
    </>
  );
}

export default PeopleDirectory;
