import { NavItem } from '@spovio/shared';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
};

export interface CurrentTagsConfigType {
  tagNavList: NavItem[];
  setTagNavList: (tagNavList: NavItem[]) => void;
}
export const CurrentTagsContext = createContext({} as CurrentTagsConfigType);

export const CurrentTagsProvider = ({ children }: Props) => {
  const [tags, setTags] = useState<NavItem[]>([]);
  const setTagNavList = useCallback((tagNavList: NavItem[]) => {
    setTags(tagNavList);
  }, []);
  return (
    <CurrentTagsContext.Provider value={{ tagNavList: tags, setTagNavList }}>
      {children}
    </CurrentTagsContext.Provider>
  );
};

export const useTagNavList = () => useContext(CurrentTagsContext);
