import {
  COMPONENT_STATE,
  createTimeOffType,
  getErrorMessages,
  userMsgs,
} from '@spovio/shared';
import { useSnackbar } from '@spovio/ui';
import { useState } from 'react';
import PeopleTimeOffTypeForm from '../people-time-off-type-form/people-time-off-type-form';
import './people-time-off-type-add-dialog.module.scss';

/* eslint-disable-next-line */
export interface PeopleTimeOffTypeAddDialogProps {
  onAddType: () => void;
}

interface typePayload {
  name: string;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export function PeopleTimeOffTypeAddDialog({
  onAddType,
}: PeopleTimeOffTypeAddDialogProps) {
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();

  const initialValues: typePayload = {
    name: '',
  };

  const handleSubmit = async (type: typePayload) => {
    setComponentState(LOADING);
    try {
      const res = await createTimeOffType(type);
      if (res.status === 201) {
        showSnackbar(true, {
          msg: userMsgs().timeOffType.add,
          severity: 'success',
        });
        onAddType();
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setComponentState(LOADED);
    }
  };

  return (
    <div>
      <PeopleTimeOffTypeForm
        open
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default PeopleTimeOffTypeAddDialog;
