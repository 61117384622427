import { Employee, getBirthdays } from '@spovio/shared';
import { Divider, Header } from '@spovio/ui';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './birthdays.module.scss';

/* eslint-disable-next-line */
interface BirthdaysProps {}

export const Birthdays = (props: BirthdaysProps) => {
  const [birthdays, setBirthdays] = useState<
    {
      month: number;
      employee: Employee[];
    }[]
  >();

  const init = async () => {
    try {
      const res = await getBirthdays();
      setBirthdays(res.data);
    } catch (error) {
      //todo
    }
  };
  useEffect(() => {
    init();
  }, []);

  const total_employee = birthdays?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.employee.length;
  }, 0);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={
          <div className={styles.title}>
            <h4>{t('label.birthdays')} </h4> &nbsp;
            {birthdays?.map((item) => item.employee.length) ? (
              <span>
                ({total_employee}) {t('label.people')}
              </span>
            ) : null}
          </div>
        }
      />

      {birthdays?.map((item) => {
        return (
          <div>
            <div className={styles.month_title}>
              {moment.months(item.month - 1)}
            </div>
            <Divider />
            <ul className={styles.list}>
              {item?.employee?.map((_per) => {
                return (
                  <li>
                    <span className={styles.date}>
                      {moment(_per.date_of_birth).format('dddd, d')}
                    </span>
                    {_per.name}
                    <span className={styles.age}>({_per.age})</span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
};
export default Birthdays;
