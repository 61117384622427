import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const NoAccessIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m12.1 5.833 1.237 1.238m-2.475 0L12.1 5.833l-1.238 1.238Zm2.475-2.475L12.1 5.833l1.237-1.237ZM12.1 5.833l-1.238-1.237L12.1 5.833ZM.583 11.667v-.584a4.083 4.083 0 0 1 8.167 0v.584"
        stroke="#E24545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.666 7a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667Z"
        stroke="#E24545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
