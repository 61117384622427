// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.settings-view-user_dialogTitle__XB5FL {\n  display: flex;\n  align-items: center;\n}\n\n.settings-view-user_content__2vqTT {\n  padding: 25px;\n}\n.settings-view-user_content__2vqTT .settings-view-user_heading__2Su9z {\n  font-size: 15px;\n  margin: 10px 0px;\n  margin-top: 20px;\n}\n.settings-view-user_content__2vqTT .settings-view-user_auditTrial__2tuaQ {\n  padding: 0;\n  margin: 0;\n}\n.settings-view-user_content__2vqTT .settings-view-user_auditTrial__2tuaQ .settings-view-user_listItem__3FnQq {\n  display: flex;\n}\n.settings-view-user_content__2vqTT .settings-view-user_auditTrial__2tuaQ .settings-view-user_listItem__3FnQq .settings-view-user_subHeading__TT9W- {\n  color: #6d6b70;\n  margin: 6px 0;\n  margin-right: 6px;\n}\n.settings-view-user_content__2vqTT .settings-view-user_auditTrial__2tuaQ .settings-view-user_listItem__3FnQq .settings-view-user_value__1Dl7m {\n  margin: 6px 0;\n}\n.settings-view-user_content__2vqTT .settings-view-user_auditTrial__2tuaQ li:before {\n  content: \"·\";\n  font-size: 60px;\n  vertical-align: middle;\n  line-height: 20px;\n  margin-right: 10px;\n  color: #c3c7d1;\n}\n\n.settings-view-user_row__2kUK3 {\n  display: flex;\n  align-items: center;\n}\n.settings-view-user_row__2kUK3 .settings-view-user_icon__24WRl {\n  width: 20px;\n  height: 20px;\n  color: #818385;\n}\n.settings-view-user_row__2kUK3 .settings-view-user_role__16-Bc {\n  display: flex;\n  align-items: center;\n  width: 160px;\n}\n.settings-view-user_row__2kUK3 .settings-view-user_roleTitle__1Dv4Y {\n  margin: 0;\n  margin-left: 10px;\n  color: #6d6b70;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 17px;\n  letter-spacing: -0.25px;\n  text-align: left;\n}\n.settings-view-user_row__2kUK3 .settings-view-user_roleDetail__32hFu {\n  color: #24252a;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 17px;\n  letter-spacing: -0.25px;\n  margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"dialogTitle": "settings-view-user_dialogTitle__XB5FL",
	"content": "settings-view-user_content__2vqTT",
	"heading": "settings-view-user_heading__2Su9z",
	"auditTrial": "settings-view-user_auditTrial__2tuaQ",
	"listItem": "settings-view-user_listItem__3FnQq",
	"subHeading": "settings-view-user_subHeading__TT9W-",
	"value": "settings-view-user_value__1Dl7m",
	"row": "settings-view-user_row__2kUK3",
	"icon": "settings-view-user_icon__24WRl",
	"role": "settings-view-user_role__16-Bc",
	"roleTitle": "settings-view-user_roleTitle__1Dv4Y",
	"roleDetail": "settings-view-user_roleDetail__32hFu"
};
module.exports = exports;
