// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".settings-add-edit-user_content__1Vsuf {\n  padding: 20px 25px 0 25px;\n}\n\n.settings-add-edit-user_row__3UWTl {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_left__3p8q7 {\n  margin-right: 5px;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_right__2wsUX {\n  margin-left: 5px;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_icon__1gkM4 {\n  width: 20px;\n  height: 20px;\n  color: #818385;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_icon__1gkM4.settings-add-edit-user_settings__1AmUv {\n  width: 24px;\n  height: 24px;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_role__3grb0 {\n  display: flex;\n  align-items: center;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_roleTitle__3iql8 {\n  margin: 0;\n  margin-left: 10px;\n}\n.settings-add-edit-user_row__3UWTl .settings-add-edit-user_select__1glMT {\n  width: 200px;\n}", ""]);
// Exports
exports.locals = {
	"content": "settings-add-edit-user_content__1Vsuf",
	"row": "settings-add-edit-user_row__3UWTl",
	"left": "settings-add-edit-user_left__3p8q7",
	"right": "settings-add-edit-user_right__2wsUX",
	"icon": "settings-add-edit-user_icon__1gkM4",
	"settings": "settings-add-edit-user_settings__1AmUv",
	"role": "settings-add-edit-user_role__3grb0",
	"roleTitle": "settings-add-edit-user_roleTitle__3iql8",
	"select": "settings-add-edit-user_select__1glMT"
};
module.exports = exports;
