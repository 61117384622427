import { User } from '@spovio/shared';
import clsx from 'clsx';
import { t } from 'i18next';
import React from 'react';
import { Avatar, AvatarSize, NoDataContent, SearchBar, TickIcon } from '../../';
import { RoundedAddIcon, RoundedRemoveIcon } from '../icons';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './choose-member.module.scss';

interface Props {
  className?: string;
  onUserSelect: (member: User) => void;
  userList: User[];
  showAddRemoveButtons?: boolean;
  // selectedMember?: number;
  selectedMembers?: number[];
}
interface State {
  searchText: string;
}
export class ChooseMember extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      searchText: '',
    } as State;
  }

  public render(): React.ReactElement {
    return (
      <div className={clsx(styles.root, this.props.className)}>
        <div className={styles.header}>
          <SearchBar placeholder={t('label.search')} onSearch={this.onSearch} />
        </div>
        <div className={styles.body}>{this.getContentMarkup()}</div>
      </div>
    );
  }

  private onSearch = (searchText: string) => {
    this.setState({
      searchText,
    });
  };

  private onUserSelect(userInfo: User) {
    this.props.onUserSelect(userInfo);
  }

  private filterMembers() {
    const userList: User[] = this.props.userList.filter((userList: User) => {
      return (
        userList.name
          .toLowerCase()
          .indexOf(this.state.searchText.toLowerCase()) !== -1 ||
        userList.email
          .toLowerCase()
          .indexOf(this.state.searchText.toLowerCase()) !== -1
      );
    });
    return userList;
  }

  private getUserListMarkup = (userList: User[]) => {
    return userList.map((member: User) => {
      const memberName = member.name || member.email;
      return (
        <div
          key={member.id}
          className={styles.item}
          onClick={() => this.onUserSelect(member)}
        >
          <Avatar
            className={styles.avatar}
            name={memberName}
            size={AvatarSize.avatar22}
            url={member.pic}
          />
          <div className={styles.memberName}>{memberName}</div>
          {this.props.selectedMembers?.includes(member.id) ? (
            this.props.showAddRemoveButtons ? (
              <RoundedRemoveIcon
                className={clsx(styles.itemIcon, styles.enlarge)}
              />
            ) : (
              <TickIcon className={styles.itemIcon} />
            )
          ) : this.props.showAddRemoveButtons ? (
            <RoundedAddIcon className={clsx(styles.itemIcon, styles.enlarge)} />
          ) : null}
        </div>
      );
    });
  };

  private getContentMarkup() {
    if (!this.props.userList) {
      return <LoadingSpinner />;
    }
    const userList = this.filterMembers();
    if (!userList.length) {
      return (
        <NoDataContent
          className={styles.noData}
          variant={'compact'}
          title={t('extension.noDataFound')}
        />
      );
    }
    const userListMarkup = this.getUserListMarkup(userList);
    return userListMarkup;
  }
}

export default ChooseMember;
