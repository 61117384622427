import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const PeopleIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="18px"
      height="16px"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-26-Copy"
          transform="translate(-1.000000, -2.000000)"
          stroke="currentColor"
          strokeWidth="1.2"
        >
          <g id="Group" transform="translate(2.000000, 3.000000)">
            <path
              d="M12.9286737,0.893740408 C14.1199143,2.08539429 14.1199143,4.01745306 12.9286737,5.20911673 C11.7374331,6.40077061 9.80604443,6.40077061 8.61479405,5.20911673 C7.42355346,4.01746286 7.42355346,2.08540408 8.61479405,0.893740408 C9.80603464,-0.297913469 11.7374233,-0.297913469 12.9286737,0.893740408"
              id="Path"
            ></path>
            <path
              d="M4.96331966,4.43120327 C5.73695918,5.20511118 5.73695918,6.45985959 4.96331966,7.23376653 C4.18968015,8.00767445 2.93536691,8.00767445 2.16172838,7.23376653 C1.38808887,6.45985861 1.38808887,5.2051102 2.16172838,4.43120327 C2.93536789,3.65729535 4.18968113,3.65729535 4.96331966,4.43120327"
              id="Path"
            ></path>
            <path
              d="M0,13.7142857 L0,12.6406531 C0,11.2878367 1.0957831,10.1916735 2.44813024,10.1916735 L3.23251117,10.1916735"
              id="Path"
            ></path>
            <path
              d="M16,13.7142857 L16,12.1459592 C16,10.2524082 14.465512,8.71738776 12.5726177,8.71738776 L8.9699492,8.71738776 C7.0770549,8.71738776 5.54256686,10.2524082 5.54256686,12.1459592 L5.54256686,13.7142857"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
