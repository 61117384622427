import { getDurationFromText, getTimeFromNumber, TimeEntryInputType } from '@spovio/shared';
import clsx from 'clsx';
import React from 'react';
import styles from './time-entry-input.module.scss';

/* eslint-disable-next-line */
interface Props {
  onTimeEntry?: (value: number) => void;
  size?: string;
  value?: number;
  type?: TimeEntryInputType;
  className?: string;
  isFocused?: boolean;
  updateOn?: 'blur' | 'change';
  defaultToZero?: boolean;
  disabled?: boolean;
  seconds?: boolean;
  autoWidth?: boolean;
  textAlign?: 'left' | 'right';
  autoInputFocus?: boolean;
}

interface State {
  value: string;
  prevValue: string;
}

export default class TimeEntryInput extends React.Component<Props, State> {

  public static defaultProps = {
        updateOn: 'blur',
    };

    private inputField = React.createRef<HTMLInputElement>();

    public constructor(props: Props) {
        super(props);
        const value = props.value
            ? getTimeFromNumber(props.value)
            : this.props.defaultToZero
            ? '0:00'
            : '';
        this.state = {
            value,
            prevValue: value,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.isFocused !== this.props.isFocused) {
            setTimeout(() => {
                this.inputField.current && this.inputField.current.focus();
            }, 100);
        }
        if (
            this.props.updateOn !== 'change' &&
            prevProps.value !== this.props.value
        ) {
            this.setState({
                prevValue: this.state.value,
                value: getTimeFromNumber(this.props.value),
            });
        }
    }

    public resetValue = (): void => {
        this.setState({ prevValue: '', value: '' });
    };

    public setValue = (value: string): void => {
        this.setState({ value });
    };

    public render(): React.ReactElement {
        const { type, size, autoInputFocus } = this.props;
        const typeClassName =
            type === 'frozen'
                ? styles.frozen
                : type === 'locked'
                ? styles.locked
                : '';
        const sizeClassName = `${size === 'm' ? styles.rootM : ''} ${
            size === 's' ? styles.rootS : ''
        } `;
        return (
            <input
                type="text"
                placeholder="0:00"
                autoFocus={autoInputFocus}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                style={
                    this.props.autoWidth
                        ? { width: this.state.value.length + 'ch' }
                        : {}
                }
                value={this.state.value}
                disabled={this.props.disabled}
                className={clsx(
                    styles.root,
                    this.props.textAlign === 'left' && styles.left,
                    this.props.className,
                    typeClassName,
                    sizeClassName,
                    {
                        [styles.autoWidth]: this.props.autoWidth,
                    }
                )}
                ref={this.inputField}
                onFocus={this.handleFocus}
                readOnly={this.props.type === 'locked' ? true : false}
            />
        );
    }

    private handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
        const value = evt.target.value;
        const duration = getDurationFromText(value);
        if (this.props.updateOn === 'blur') {
            this.triggerUpdate(duration);
        }

        this.setState({
            prevValue: this.state.value,
            value: duration
                ? getTimeFromNumber(duration)
                : this.props.defaultToZero
                ? '0:00'
                : '',
        });
    };

    private triggerUpdate(value: number) {
        const { prevValue } = this.state;
        const { onTimeEntry } = this.props;
        if (value !== getDurationFromText(prevValue)) {
            onTimeEntry && onTimeEntry(value);
        }
    }

    private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value;
        const duration = getDurationFromText(value);
        this.setState({
            value,
        });
        if (this.props.updateOn === 'change') {
            this.triggerUpdate(duration);
        }
    };

    private handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
        event.target.select();
}
