import { appRoute } from '@spovio/shared';
import { Button, NoDataContent, useCurrentUser } from '@spovio/ui';
import React, { Fragment } from 'react';
import { NavLink, Route } from 'react-router-dom';

/* eslint-disable-next-line */
export const AppRoute: React.FC<any> = ({
  component: Component,
  needsAuth = false,
  showComponent = false,
  layout: Layout = Fragment,
  componentProps = {},
  isAuthenticated = true,
  module,
  ...rest
}) => {
  const { currentUser } = useCurrentUser();
  if (!isAuthenticated) {
    return (
      <Layout>
        <div className="d-flex j-c-c a-i-c h-50">
          <NoDataContent
            icon={
              <img
                src={`/assets/icons/${module}_@2x.png`}
                alt="people"
                style={{ marginBottom: '10px' }}
              />
            }
            title="You do not have access to this page"
            desc="Please contact your administrator"
            buttonComponent={
              currentUser['settings_access'] === 'admin' && (
                <Button
                  size="s"
                  component={NavLink}
                  to={appRoute.getRoute('/settings/directory')}
                >
                  Go to settings
                </Button>
              )
            }
          />
        </div>
      </Layout>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...rest} {...props} {...componentProps} />
        </Layout>
      )}
    />
  );
};
