import React from 'react';
type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const DepartmentListIcon = (props: IconProps) => {
  return (
    <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path fill="currentColor" d="M14.75 5.75H1.25v-1.5l6.75-3 6.75 3z" />
        <path d="M2.375 10.625v-3M13.625 7.625v3M6.125 7.625v3M9.875 7.625v3" />
        <path fill="currentColor" d="M1.25 12.5h13.5v2.25H1.25z" />
      </g>
    </svg>
  );
};
