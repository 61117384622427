import { Button, PlusIcon } from '../../';
import clsx from 'clsx';
import styles from './no-data-content.module.scss';

/* eslint-disable-next-line */
export interface NoDataContentProps {
  icon?: any;
  buttonComponent?: any;
  title?: string;
  size?: 'md' | 'lg';
  desc?: string;
  variant?: 'compact' | 'regular';
  className?: string;
  containerClassName?: string;
  sub_desc?: string;
  buttonTitle?: string;
  btnClassName?: string;
  onBtnAction?: () => void;
}

export function NoDataContent({
  icon,
  title,
  desc,
  variant = 'regular',
  size = 'lg',
  className,
  containerClassName,
  sub_desc,
  buttonComponent,
  buttonTitle,
  btnClassName,
  onBtnAction,
}: NoDataContentProps) {
  return variant === 'regular' ? (
    <div className={clsx(styles.root, containerClassName)}>
      {icon && <div className={styles.iconCont}>{icon}</div>}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
        <div className={styles.desc}>{sub_desc}</div>
      </div>
      {(buttonComponent || buttonTitle) && (
        <div className={styles.btnWrap}>
          {!buttonComponent ? (
            <Button
              className={clsx(styles.btn, btnClassName)}
              onClick={onBtnAction}
            >
              <PlusIcon /> {buttonTitle}
            </Button>
          ) : (
            buttonComponent
          )}
        </div>
      )}
    </div>
  ) : (
    <div className={clsx(styles.placeholder, className, styles[size])}>
      {title}
    </div>
  );
}

export default NoDataContent;
