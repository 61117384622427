// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MuiMenu-list.MuiList-padding {\n  outline: 0;\n  padding: 6px;\n}\n\n.MenuItem_menuItem__2jF-J {\n  height: 30px;\n  border-radius: 4px;\n  padding: 6px 15px 6px 26px;\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n  color: #333333;\n  font-size: 13px;\n  letter-spacing: -0.24px;\n  line-height: 16px;\n  min-width: 168px;\n  position: relative;\n  cursor: pointer;\n  transition: background-color 0.2s;\n}\n.MenuItem_menuItem__2jF-J:hover {\n  background-color: #f5f6f7;\n}", ""]);
// Exports
exports.locals = {
	"menuItem": "MenuItem_menuItem__2jF-J"
};
module.exports = exports;
