import {
  appRoute,
  approveTimeSheets,
  COMPONENT_STATE,
  getApprovalListDetail,
  getDateFormat,
  getErrorMessages,
  getTimeFromNumber,
  TABS,
  TimeWeekGroupType,
  TIME_APPROVAL_DETAILS_TABLE_HEADERS,
  unlockTimeSheets,
  userMsgs,
} from '@spovio/shared';
import {
  ApprovalIcon,
  Button,
  CloseIcon,
  Header,
  NoDataContent,
  Skeleton,
  SummaryStatusCard,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, StaticContext } from 'react-router';
import { useLocation } from 'react-router-dom';
import styles from './time-approval-detail.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

interface MatchParams {
  id: string;
}

interface StateProps {
  name: string;
  tab: TABS;
}

/* eslint-disable-next-line */
export interface TimeApprovalDetailProps
  extends RouteComponentProps<MatchParams, StaticContext> {}

export function TimeApprovalDetail(props: TimeApprovalDetailProps) {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [componentState, setComponentState] = useState(LOADING);
  const [timeSummary, setTimeSummary] = useState<number>();
  const [timeList, setTimeList] = useState([] as TimeWeekGroupType[]);
  const [isApproved, setIsApproved] = useState(false);
  const location = useLocation();
  const state = location.state as StateProps;
  const headerContent = TIME_APPROVAL_DETAILS_TABLE_HEADERS();

  const getApprovalDetails = async () => {
    const { params } = props.match;
    const timeOffId = params.id;
    setComponentState(LOADING);
    try {
      const response = await getApprovalListDetail(Number(timeOffId));
      setIsApproved(response.data.times[0]?.status === 'approved');
      setTimeList(response.data.times);
      setTimeSummary(response.data.total_hours);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
    setComponentState(LOADED);
  };

  const handleNavBack = () => {
    props.history.push({
      pathname: appRoute.getRoute('/time/approval/'),
      state: {
        tab: state.tab,
      },
    });
  };

  const handleSubmit = async () => {
    showSnackbar(false);
    try {
      const payload = {
        ids: [Number(props.match.params.id)],
      };
      if (isApproved) {
        const res = await unlockTimeSheets(payload);
        showSnackbar(true, {
          msg: userMsgs().times.unlocked,
          severity: 'success',
        });
        props.history.push({
          pathname: appRoute.getRoute('/time/approval'),
          state: {
            tab: state.tab,
          },
        });
      } else {
        const res = await approveTimeSheets(payload);
        showSnackbar(true, {
          msg: userMsgs().times.approved,
          severity: 'success',
        });
        props.history.push({
          pathname: appRoute.getRoute('/time/approval'),
          state: {
            tab: state.tab,
          },
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getHeaders = () => {
    return (
      <div className={styles.navHeaderWrap}>
        {componentState === LOADING ? (
          <Skeleton
            variant="rect"
            width={70}
            height={26}
            className={styles.title}
          />
        ) : (
          <Text variant="h3" fontWeight="semi-bold" className={styles.title}>
            {t('extension.approveUserTimesheet', {
              name: state?.name && state?.name,
            })}
          </Text>
        )}
      </div>
    );
  };

  const getSummaryMarkup = () => {
    return (
      <div className={styles.summaryWrap}>
        <div className={styles.summary}>
          <SummaryStatusCard
            componentState={componentState}
            className={styles.summaryCard}
            value={getTimeFromNumber(timeSummary)}
            title={t('label.totalHours')}
            key={'total_hours'}
          />
        </div>
      </div>
    );
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
      </TableListRow>
    );
  };

  const getTableContentMarkup = () => {
    if (!timeList.length)
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('label.time')}
            desc={t('timesheet.approvalDetailNoData.desc')}
            icon={<ApprovalIcon className={styles.approvalIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    return timeList.map((item, index) => {
      return (
        <TableListRow className={styles.trow} key={index}>
          <TableListCell
            className={clsx(styles.dateCol, styles.cell)}
            key="date"
          >
            <Text variant="body1" fontWeight="medium">
              {getDateFormat(item.date, 'MMM DD YYYY')}
            </Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.projectCol, styles.cell)}
            key="title"
          >
            <Text variant="body1" className={styles.project}>
              {item.project_name}
            </Text>
            <Text variant="body1" className={styles.notes}>
              {item.notes}
            </Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.hourCol, styles.cell)}
            key="hours"
          >
            <Text variant="body1">{getTimeFromNumber(item.hours)}</Text>
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [90, 300, 90, 90];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
        />
      );
    } else return getTableContentMarkup();
  };

  const getTimeApprovalDetail = () => {
    return (
      <TableList className={styles.table}>
        <TableListHead>{getHeaderRowMarkup()}</TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    );
  };

  const getLeftHeaderContent = () => {
    return (
      <>
        <Button
          className={styles.closeBtn}
          ghost
          variant="text"
          color="default"
          onClick={handleNavBack}
        >
          <CloseIcon />
        </Button>
        <h4>{t('label.approval')}</h4>
      </>
    );
  };

  const getSubmitBtn = () => (
    <Button size={'m'} onClick={handleSubmit} className={styles.actionBtn}>
      {isApproved ? t('label.unlock') : t('label.approveTimesheets')}
    </Button>
  );

  useEffect(() => {
    getApprovalDetails();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={getLeftHeaderContent()}
        rightContent={getSubmitBtn()}
      />
      <div className={styles.content}>
        {getHeaders()}
        {getSummaryMarkup()}
        {getTimeApprovalDetail()}
        <div className={styles.submit}>{getSubmitBtn()}</div>
      </div>
    </>
  );
}

export default TimeApprovalDetail;
