// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_header__1RFha {\n  min-height: 70px;\n  box-sizing: border-box;\n  position: fixed;\n  top: 0;\n  display: flex;\n  align-items: center;\n  background-color: #fff;\n  box-shadow: inset 0 -1px 0 0 #e7e9f0;\n  z-index: 1;\n  width: inherit;\n}\n.header_header__1RFha .header_topContent__3KKuV {\n  display: flex;\n  padding: 0 30px;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1 1 0%;\n  display: flex;\n  flex-direction: row;\n  flex: auto 0 0;\n  box-sizing: border-box;\n  width: 100%;\n}\n@media (max-width: 767px) {\n  .header_header__1RFha .header_topContent__3KKuV {\n    padding-left: 62px;\n  }\n}\n.header_header__1RFha .header_topContent__3KKuV .header_title__1fx7F {\n  color: #252526;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: -0.25px;\n  line-height: 28px;\n}\n.header_header__1RFha .header_topContent__3KKuV .header_left__3GhnR,\n.header_header__1RFha .header_topContent__3KKuV .header_right__1h2K7 {\n  display: flex;\n  align-items: center;\n}\n.header_header__1RFha .header_topContent__3KKuV .header_left__3GhnR h4,\n.header_header__1RFha .header_topContent__3KKuV .header_right__1h2K7 h4 {\n  margin: 0px;\n  color: #24252a;\n  font-size: 20px;\n  font-weight: 500;\n  letter-spacing: -0.22px;\n  line-height: 24px;\n}\n.header_header__1RFha .header_bottomContent__3ppBu {\n  margin-top: 25px;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"header": "header_header__1RFha",
	"topContent": "header_topContent__3KKuV",
	"title": "header_title__1fx7F",
	"left": "header_left__3GhnR",
	"right": "header_right__1h2K7",
	"bottomContent": "header_bottomContent__3ppBu"
};
module.exports = exports;
