import {
  clone,
  COMPONENT_STATE,
  deleteUser,
  forgotPassword,
  getErrorMessages,
  getUserList,
  inviteUser,
  PeopleFilter,
  updateStatus,
  updateUser,
  useModuleAccess,
  User,
  UserAccess,
  UserList,
  userMsgs,
  UserPayload,
  UsersFilter,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  useSnackbar,
} from '@spovio/ui';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsAddEditUser from '../settings-add-edit-user/settings-add-edit-user';
import SettingsUserListTable from '../settings-user-list-table/settings-user-list-table';
import SettingsViewUser from '../settings-view-user/settings-view-user';
import styles from './settings-directory.module.scss';

/* eslint-disable-next-line */
export interface SettingsDirectoryProps {
  archived?: boolean;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function SettingsDirectory(props: SettingsDirectoryProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [people, setPeople] = useState<UserList>({} as UserList);
  const [isArchivedUsers, setIsArchivedUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isEditing, setIsEditing] = useState(false);
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [addUserDialog, setAddUserDialog] = useState(false);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { isAdmin } = useModuleAccess('people_access');
  const { showSnackbar } = useSnackbar();

  const usersFilter: UsersFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
    archived: false,
  };
  const [filter, setFilter] = useState<UsersFilter>(usersFilter);

  const getPeople = useCallback(
    async (_filter = clone(filter)) => {
      setComponentState(LOADING);
      try {
        _filter.archived = props.archived;
        const res = await getUserList(_filter);
        setPeople(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFilter = useCallback(
    (filter: PeopleFilter) => {
      setComponentState(LOADING);
      getPeople(filter);
    },
    [getPeople]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const handleUserSubmit = async (user: UserPayload) => {
    if (isEditing && selectedUser) {
      try {
        const response = await updateUser(selectedUser.id, {
          user: {
            email: user.email,
            name: user.name,
          },
          people_access: user.people_access.toUpperCase() as UserAccess,
          dimension_access: user.dimension_access.toUpperCase() as UserAccess,
          time_access: user.time_access.toUpperCase() as UserAccess,
          reimbursement_access:
            user.reimbursement_access.toUpperCase() as UserAccess,
          contact_access: user.contact_access.toUpperCase() as UserAccess,
          settings_access: user.settings_access.toUpperCase() as UserAccess,
        });
        if (response.status === 200) {
          showSnackbar(true, {
            msg: userMsgs().user.edit,
            severity: 'success',
          });
          getPeople();
          setIsEditing(false);
        }
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
        setAddUserDialog(false);
      }
    } else {
      try {
        const response = await inviteUser({
          ...user,
          people_access: user.people_access.toUpperCase() as UserAccess,
          dimension_access: user.dimension_access.toUpperCase() as UserAccess,
          time_access: user.time_access.toUpperCase() as UserAccess,
          reimbursement_access:
            user.reimbursement_access.toUpperCase() as UserAccess,
          contact_access: user.contact_access.toUpperCase() as UserAccess,
          settings_access: user.settings_access.toUpperCase() as UserAccess,
        });
        if (response.status === 201) {
          showSnackbar(true, {
            msg: userMsgs().user.add,
            severity: 'success',
          });
          getPeople();
          setAddUserDialog(false);
        }
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
        setAddUserDialog(false);
      }
    }
  };

  const onDeleteUser = async (id: number) => {
    try {
      const response = await deleteUser(id);
      if (response.status === 204) {
        setPeople((prev) => {
          const newPeople = clone(prev);
          newPeople.results = newPeople.results.filter(
            (user) => user.id !== id
          );
          return newPeople;
        });
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().user.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      showConfirmation(false);
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const deleteConfirmation = (user: User) => {
    showConfirmation(true, {
      header: t('settings.userDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteUser(user.id),
    });
  };

  const handleUpdateStatus = async (user: User, archive: boolean) => {
    try {
      const res = await updateStatus(user.id, archive);
      if (res.status === 200) {
        const updatedPeople = clone(people);
        updatedPeople.results = updatedPeople.results.filter(
          (p) => p.id !== user.id
        );
        showSnackbar(true, {
          msg: archive ? userMsgs().user.archived : userMsgs().user.restored,
          severity: 'success',
        });
        setPeople(updatedPeople);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const sendPasswordReset = async (email: string) => {
    try {
      const res = await forgotPassword(email);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: userMsgs().user.reset,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onListAction = (dropId: string, user: User) => {
    switch (dropId) {
      case 'view':
        setSelectedUser(user);
        setOpenUserDetails(true);
        break;
      case 'edit':
        setSelectedUser(user);
        setIsEditing(true);
        break;
      case 'archive':
        handleUpdateStatus(user, true);
        break;
      case 'restore':
        handleUpdateStatus(user, false);
        break;
      case 'send-password-reset':
        sendPasswordReset(user.email);
        break;
      case 'delete':
        deleteConfirmation(user);
        break;
    }
  };

  const handleDialogClose = () => {
    setAddUserDialog(false);
    setIsEditing(false);
    setOpenUserDetails(false);
  };

  const getAddUserButton = () => {
    if (isArchivedUsers || !isAdmin) {
      return null;
    }
    return (
      <Button
        size={'s'}
        onClick={() => {
          setSelectedUser(undefined);
          setAddUserDialog(true);
        }}
      >
        <PlusIcon className={styles.plusIcon} />
        {t('settings.addUser')}
      </Button>
    );
  };

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
        {/* <Button
          color="default"
          variant="text"
          ghost
          className={styles.filterButton}
        >
          <FilterIcon className={styles.filterIcon} />
          <Ellipsis className={styles.filterName}>Filter</Ellipsis>
        </Button> */}
      </div>
    );
  };

  const getPeopleTable = () => {
    return (
      <SettingsUserListTable
        people={people.results}
        componentState={componentState}
        onListAction={onListAction}
        isArchivedUsers={isArchivedUsers}
      />
    );
  };

  const getPaginationFooter = () => {
    const { has_next, has_prev, total_count, page_size, page, links } = people;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  const getAddUserDialog = () => {
    return (
      <SettingsAddEditUser
        open={addUserDialog || isEditing}
        action={isEditing ? t('label.save') : t('label.add')}
        user={
          selectedUser
            ? selectedUser
            : ({
                people_access: 'member',
                dimension_access: 'no_access',
                time_access: 'member',
                reimbursement_access: 'member',
                contact_access: 'member',
                settings_access: 'no_access',
              } as User)
        }
        disabledBasics={isEditing}
        onClose={handleDialogClose}
        onSubmit={handleUserSubmit}
      />
    );
  };

  const getViewUserDialog = () => {
    return (
      <SettingsViewUser
        open={openUserDetails}
        user={selectedUser ? selectedUser : ({} as User)}
        onClose={handleDialogClose}
      />
    );
  };

  useEffect(() => {
    if (props.archived) setIsArchivedUsers(true);
    getPeople();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={
          <h4>
            {isArchivedUsers ? t('label.archivedUsers') : t('label.directory')}
          </h4>
        }
        rightContent={getAddUserButton()}
        bottomContent={getFilters()}
      />
      {getPeopleTable()}
      {getPaginationFooter()}
      {getAddUserDialog()}
      {getViewUserDialog()}
    </>
  );
}

export default SettingsDirectory;
