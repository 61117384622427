import React from 'react';

import clsx from 'clsx';

import styles from './table-list-body.module.scss';

type TableListBodyProps = React.HTMLAttributes<HTMLElement>;

export const TableListBody = (props: TableListBodyProps) => {
  return (
    <div className={clsx(styles.root, props.className)}>{props.children}</div>
  );
};
