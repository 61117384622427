// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".location_plusIcon__2XgJK {\n  margin-right: 6px;\n}\n\n.location_pagination__3GgvC {\n  padding: 25px 30px;\n}\n.location_pagination__3GgvC .location_paper__2uUXS {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}", ""]);
// Exports
exports.locals = {
	"plusIcon": "location_plusIcon__2XgJK",
	"pagination": "location_pagination__3GgvC",
	"paper": "location_paper__2uUXS"
};
module.exports = exports;
