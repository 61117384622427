import React from 'react';

type KeyboardArrowDownIconProps = React.HTMLAttributes<HTMLOrSVGElement>;
export const KeyboardArrowDownIcon: React.FC<KeyboardArrowDownIconProps> = (
  props: KeyboardArrowDownIconProps
) => {
  return (
    <svg
      {...props}
      width="8"
      height="5"
      viewBox="0 0 8 5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1l3 3 3-3"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
