// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table-list-row_root__35pGg {\n  border-bottom: 1px solid #edeef2;\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  flex-shrink: 0;\n  justify-content: space-between;\n}", ""]);
// Exports
exports.locals = {
	"root": "table-list-row_root__35pGg"
};
module.exports = exports;
