// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-profile_header__3Bd-k .people-profile_plusIcon__3Ov94 {\n  margin-right: 6px;\n}\n\n@media (min-width: 1024px) {\n  .people-profile_content__3oqYX {\n    display: flex;\n    height: calc(109% - 112px);\n  }\n}\n.people-profile_content__3oqYX .people-profile_fullScreenDivider__3HE0h {\n  display: none;\n}\n@media (min-width: 1024px) {\n  .people-profile_content__3oqYX .people-profile_fullScreenDivider__3HE0h {\n    display: block;\n  }\n}\n@media (min-width: 1024px) {\n  .people-profile_content__3oqYX .people-profile_mobileDivider__1y-Uo {\n    display: none;\n  }\n}\n\n.people-profile_pagination__1f_ra {\n  display: flex;\n  align-items: center;\n}\n.people-profile_pagination__1f_ra svg {\n  width: 25px;\n  height: 25px;\n  margin: 0 4px;\n}", ""]);
// Exports
exports.locals = {
	"header": "people-profile_header__3Bd-k",
	"plusIcon": "people-profile_plusIcon__3Ov94",
	"content": "people-profile_content__3oqYX",
	"fullScreenDivider": "people-profile_fullScreenDivider__3HE0h",
	"mobileDivider": "people-profile_mobileDivider__1y-Uo",
	"pagination": "people-profile_pagination__1f_ra"
};
module.exports = exports;
