import { User, UserPayload, validateEmail, validateForm } from '@spovio/shared';
import {
  AccountSettingsIcon,
  ContactListIcon,
  Dialog,
  DimensionsIcon,
  FormControl,
  Input,
  PeopleIcon,
  ReimbursementIcon,
  Select,
  SettingsIcon,
  Text,
  TimeIcon,
} from '@spovio/ui';
import clsx from 'clsx';
import { t } from 'i18next';
import { upperCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './settings-add-edit-user.module.scss';

/* eslint-disable-next-line */
export interface SettingsAddEditUserProps {
  open: boolean;
  disabledBasics?: boolean;
  user: User;
  action?: string;
  onClose: () => void;
  onSubmit: (value: UserPayload) => void;
}

const roleOptions = () => [
  {
    label: t('label.admin'),
    value: 'ADMIN',
  },
  {
    label: t('label.member'),
    value: 'MEMBER',
  },
  {
    label: t('label.noAccess'),
    value: 'NO_ACCESS',
  },
];

export function SettingsAddEditUser({
  open,
  user,
  disabledBasics = false,
  action = 'Add',
  onClose,
  onSubmit,
}: SettingsAddEditUserProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState({} as UserPayload);
  const handleSelect = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit(values);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateValue = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validateValues = () => {
    const { email, name } = values;
    return validateForm.isEmail(email) && validateForm.isRequired(name);
  };

  useEffect(() => {
    setValues(user);
  }, [user]);

  useEffect(() => {
    setIsModalVisible(open);
    if (user && user.id) {
      setValues(user);
    } else if (!open) {
      setValues({
        people_access: roleOptions()[0].value,
        dimension_access: roleOptions()[0].value,
        reimbursement_access: roleOptions()[0].value,
        time_access: roleOptions()[0].value,
        contact_access: roleOptions()[0].value,
        settings_access: roleOptions()[0].value,
      } as UserPayload);
    }
  }, [open]);

  return (
    <div className={styles.root}>
      <Dialog
        title={user && user.id ? t('settings.editUser') : t('settings.addUser')}
        action={action}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        isActionDisabled={!validateValues()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.left}>
              <Input
                name="name"
                validationType={['required']}
                placeholder={t('label.name')}
                labelClassName={styles.label}
                label={t('label.name')}
                required={true}
                value={values.name}
                onChange={onUpdateValue}
              />
            </FormControl>
          </div>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="email"
              validationType={['required', 'email']}
              placeholder={t('label.email')}
              required={true}
              type="email"
              labelClassName={styles.label}
              label={t('label.email')}
              value={values.email}
              onChange={onUpdateValue}
            />
          </FormControl>
          <Text variant="h4" className={styles.heading}>
            {t('label.userRoles')}
          </Text>
          <FormControl gutter="l" className={styles.row}>
            <div className={styles.role}>
              <ContactListIcon className={styles.icon} />
              <Text variant="h5" className={styles.roleTitle}>
                {t('label.contacts')}:
              </Text>
            </div>
            <Select
              inputClassName={styles.select}
              options={roleOptions()}
              onSelect={(value) => handleSelect('contact_access', value)}
              selectedOption={{
                label:
                  roleOptions().find(
                    (option) =>
                      option.value ===
                      upperCase(values.contact_access).split(' ').join('_')
                  )?.label ?? '',
                value: upperCase(values.contact_access).split(' ').join('_'),
              }}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="l" className={styles.row}>
            <div className={styles.role}>
              <PeopleIcon className={styles.icon} />
              <Text variant="h5" className={styles.roleTitle}>
                {t('label.people')}:
              </Text>
            </div>
            <Select
              inputClassName={styles.select}
              options={roleOptions()}
              onSelect={(value) => handleSelect('people_access', value)}
              selectedOption={{
                label:
                  roleOptions().find(
                    (option) =>
                      option.value ===
                      upperCase(values.people_access).split(' ').join('_')
                  )?.label ?? '',
                value: upperCase(values.people_access).split(' ').join('_'),
              }}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="l" className={styles.row}>
            <div className={styles.role}>
              <ReimbursementIcon className={styles.icon} />
              <Text variant="h5" className={styles.roleTitle}>
                {t('label.reimbursements')}:
              </Text>
            </div>
            <Select
              inputClassName={styles.select}
              options={roleOptions()}
              onSelect={(value) => handleSelect('reimbursement_access', value)}
              selectedOption={{
                label:
                  roleOptions().find(
                    (option) =>
                      option.value ===
                      upperCase(values.reimbursement_access)
                        .split(' ')
                        .join('_')
                  )?.label ?? '',
                value: upperCase(values.reimbursement_access)
                  .split(' ')
                  .join('_'),
              }}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="l" className={styles.row}>
            <div className={styles.role}>
              <TimeIcon className={styles.icon} />
              <Text variant="h5" className={styles.roleTitle}>
                {t('label.time')}:
              </Text>
            </div>
            <Select
              inputClassName={styles.select}
              options={roleOptions()}
              onSelect={(value) => handleSelect('time_access', value)}
              selectedOption={{
                label:
                  roleOptions().find(
                    (option) =>
                      option.value ===
                      upperCase(values.time_access).split(' ').join('_')
                  )?.label ?? '',
                value: upperCase(values.time_access).split(' ').join('_'),
              }}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="l" className={styles.row}>
            <div className={styles.role}>
              <DimensionsIcon className={styles.icon} />
              <Text variant="h5" className={styles.roleTitle}>
                {t('label.dimensions')}:
              </Text>
            </div>
            <Select
              inputClassName={styles.select}
              options={roleOptions()}
              onSelect={(value) => handleSelect('dimension_access', value)}
              selectedOption={{
                label:
                  roleOptions().find(
                    (option) =>
                      option.value ===
                      upperCase(values.dimension_access).split(' ').join('_')
                  )?.label ?? '',
                value: upperCase(values.dimension_access).split(' ').join('_'),
              }}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="xs" className={styles.row}>
            <div className={styles.role}>
              <AccountSettingsIcon
                className={clsx(styles.icon, styles.settings)}
              />
              <Text variant="h5" className={styles.roleTitle}>
                {t('label.settings')}:
              </Text>
            </div>
            <Select
              inputClassName={styles.select}
              options={roleOptions().filter(
                (option) => option.value !== 'MEMBER'
              )}
              onSelect={(value) => handleSelect('settings_access', value)}
              selectedOption={{
                label:
                  roleOptions().find(
                    (option) =>
                      option.value ===
                      upperCase(values.settings_access).split(' ').join('_')
                  )?.label ?? '',
                value: upperCase(values.settings_access).split(' ').join('_'),
              }}
              hideSearchHeader
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default SettingsAddEditUser;
