// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".text-area_textarea__2nC9Y {\n  border: 1px solid #e0dee0;\n  border-radius: 6px;\n  background-color: #ffffff;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 4px 12px;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  color: #252526;\n  font-size: 14px;\n  letter-spacing: -0.25px;\n  line-height: 17px;\n}\n.text-area_textarea__2nC9Y:focus {\n  outline: none;\n  box-shadow: 0 0 0 1px #0075f3;\n  border-color: #0075f3;\n}\n.text-area_textarea__2nC9Y::placeholder {\n  color: #a6a6a6;\n  opacity: 0.8;\n}\n.text-area_textarea__2nC9Y.text-area_noStyle__2js5c {\n  border: none;\n  border-radius: 0;\n  padding: 0;\n}\n.text-area_textarea__2nC9Y.text-area_noStyle__2js5c:focus {\n  outline: none;\n  box-shadow: none;\n  border-color: none;\n}\n.text-area_textarea__2nC9Y.text-area_noResize__crq6G {\n  resize: none;\n}\n\n.text-area_s__3797y {\n  min-height: 28px;\n  font-size: 13px;\n  padding-left: 8px;\n}\n\n.text-area_m__i-f5a {\n  min-height: 36px;\n}\n\n.text-area_l__X9Twf {\n  min-height: 44px;\n}", ""]);
// Exports
exports.locals = {
	"textarea": "text-area_textarea__2nC9Y",
	"noStyle": "text-area_noStyle__2js5c",
	"noResize": "text-area_noResize__crq6G",
	"s": "text-area_s__3797y",
	"m": "text-area_m__i-f5a",
	"l": "text-area_l__X9Twf"
};
module.exports = exports;
