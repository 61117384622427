import React, { ReactNode } from 'react';

import MuiCheckbox, {
  CheckboxProps,
} from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import clsx from 'clsx';

import styles from './checkbox.module.scss';

export enum CheckboxVARIANT {
  CIRCLE = 'circle',
  SQUARED = 'squared',
}

interface Props extends Omit<CheckboxProps, 'size'> {
  label?: string | ReactNode;
  labelClassName?: string;
  size?: 's' | 'm' | 'l';
  indeterminate?: boolean;
  desc?: string;
  descClassName?: string;
}

export const Checkbox = ({
  label,
  labelClassName,
  id,
  className,
  value,
  checked,
  name,
  size = 'm',
  indeterminate,
  desc,
  descClassName,
  onChange,
  ...props
}: Props) => {
  return (
    <div className={styles.root}>
      <FormControlLabel
        className={clsx(
          styles.formLabel,
          className,
          props.disabled && styles.disabledWrap
        )}
        classes={{
          label: clsx(label ? styles.label : null, labelClassName),
        }}
        control={
          <MuiCheckbox
            id={id}
            checkedIcon={
              <span
                className={clsx(styles.icon, styles.checkedIcon, styles[size])}
              />
            }
            indeterminate={indeterminate}
            indeterminateIcon={
              <span
                className={clsx(
                  styles.icon,
                  styles.indeterminateIcon,
                  styles[size]
                )}
              />
            }
            icon={
              <span
                className={clsx(
                  styles.icon,
                  styles[size],
                  props.disabled && styles.disabled
                )}
              />
            }
            className={styles.checkbox}
            checked={checked}
            onChange={onChange}
            value={value}
            color={'primary'}
            disableRipple
            name={name}
            {...props}
          />
        }
        label={label}
      />
      {desc ? (
        <div className={clsx(styles.description, descClassName)}>{desc}</div>
      ) : null}
    </div>
  );
};
