import styles from './create-pwd.module.scss';
import {
  Button,
  FormControl,
  FormText,
  Input,
  KeyboardArrowLeftIcon,
  Text,
  useSnackbar,
} from '@spovio/ui';
import {
  COMPONENT_STATE,
  createUser,
  getErrorMessages,
  setAuthenticationDetails,
  userMsgs,
  verifyEmail,
  verifyToken,
} from '@spovio/shared';
import { ErrorMessage, Formik, FormikErrors } from 'formik';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

/* eslint-disable-next-line */
interface FormValues {
  password: string;
  confirmPassword: string;
}

interface MatchParams {
  token: string;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export type CreatePwdProps = RouteComponentProps<MatchParams>;

export function CreatePwd({ match, history }: CreatePwdProps) {
  const [serverErrorMsg, setServerErrorMsg] = useState<string>();
  const [componentState, setComponentState] = useState(LOADING);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (values: FormValues, _actions: any) => {
    try {
      const res = await createUser({
        password: values.password,
        token: match.params.token,
      });
      if (res.status === 200) {
        setAuthenticationDetails({ ...res.data });
        history.push(`/create-profile`);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  const handleFocus = () => {
    setServerErrorMsg(undefined);
  };

  const confirmToken = async () => {
    const inviteToken = match.params.token;
    if (inviteToken) {
      try {
        const res = await verifyToken(inviteToken);
        if (res.status === 200) {
          setComponentState(LOADED);
        }
      } catch (error) {
        showSnackbar(true, {
          msg: 'Token Expired',
          severity: 'error',
        });
        history.push('/register');
      }
    }
  };

  useEffect(() => {
    confirmToken();
  }, []);

  return (
    <div className={styles.root}>
      <Text variant="h1" className={styles.title}>
        SPOVIO
      </Text>
      <div className={styles.innerContent}>
        <Text variant="h1" className={styles.heading}>
          Create new password
        </Text>
        <Text variant="caption" className={styles.helperText}>
          Your new password must be different from previous used passwords.
        </Text>
        <Formik
          initialValues={{
            confirmPassword: '',
            password: '',
          }}
          validate={(values) => {
            const errors: FormikErrors<FormValues> = {};
            if (values.password && !values.confirmPassword) {
              errors.confirmPassword = 'Please re-enter your password';
            } else if (!values.password) {
              errors.password = 'Please enter a new password';
            } else if (values.confirmPassword !== values.password) {
              errors.confirmPassword =
                'Password confirmation does not match password';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl gutter="l" className={styles.formGroup}>
                <Input
                  name="password"
                  placeholder="New password"
                  type={'password'}
                  size="l"
                  status={
                    errors.password && touched.password ? 'invalid' : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  value={values.password}
                />
                <ErrorMessage name="password">
                  {(errorMessage) => (
                    <FormText type="invalid">{errorMessage}</FormText>
                  )}
                </ErrorMessage>
                {serverErrorMsg && (
                  <FormText type="invalid">{serverErrorMsg}</FormText>
                )}
              </FormControl>
              <FormControl gutter="xl">
                <Input
                  name="confirmPassword"
                  placeholder="Confirm password"
                  size="l"
                  type="password"
                  status={
                    errors.password && touched.password ? 'invalid' : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  value={values.confirmPassword}
                />

                <ErrorMessage name="confirmPassword">
                  {(errorMessage) => (
                    <FormText type="invalid">{errorMessage}</FormText>
                  )}
                </ErrorMessage>
              </FormControl>
              <Button
                fullWidth
                size="l"
                type="submit"
                className={styles.submitBtn}
                disabled={isSubmitting || !isValid}
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
        <div className={styles.option}>
          <NavLink className={styles.linkText} to={'/login'}>
            <KeyboardArrowLeftIcon />
            Back
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default CreatePwd;
