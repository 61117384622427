import {
  createAccount,
  CURRENCY_CHOICES,
  getErrorMessages,
  SelectDropdownOption,
  setDefaultOrgDetails,
  updateUserProfile,
} from '@spovio/shared';
import {
  Button,
  FormControl,
  FormText,
  Input,
  Select,
  SpovioIcon,
  SpovioLogoBlackIcon,
  Text,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './onboard.module.scss';

/* eslint-disable-next-line */
export interface OnboardProps {}

interface AccountForm {
  firstName: string;
  lastName: string;
  companyName: string;
  currency: string;
  timezone: string;
}

const timezoneList: SelectDropdownOption[] = [];
const timezoneNames = moment.tz.names();
timezoneNames.forEach((item: string) =>
  timezoneList.push({
    label: `(GMT ${moment.tz(item).format('Z')}) ${item}`,
    value: item,
  })
);

export function Onboard(props: OnboardProps) {
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [values, setValues] = useState({
    name: '',
    companyName: '',
    currency: '',
    timezone: '',
  });
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const currencyOptions = CURRENCY_CHOICES.map((item) => ({
    label: item.label,
    value: item.label,
  }));

  const handleFormSubmit = async (e: any) => {
    try {
      const res = await updateUserProfile({
        name: values.name,
        timezone: values.timezone,
      });
      if (res.status === 200) {
        const account = await createAccount({
          currency: values.currency,
          name: values.companyName,
        });
        if (account.status === 201) {
          setDefaultOrgDetails(res.data.id);
          history.push('/');
        }
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setLoginErrorMessage('');
  };

  const handleSelect = (field: string, value: string) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const isValid = () => {
    const { name, companyName, currency, timezone } = values;
    if (
      !name.length ||
      !companyName.length ||
      !currency.length ||
      !timezone.length
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className={styles.root}>
      <SpovioLogoBlackIcon className={styles.title} />
      <div className={styles.innerContent}>
        <Text variant="h1" className={styles.heading}>
          Let's get started
        </Text>
        <Text variant="caption" className={styles.helperText}>
          Tell us about you and your business
        </Text>
        <FormControl gutter="l" className={styles.form}>
          <Input
            placeholder="Name"
            type="text"
            name="name"
            onChange={handleChange}
            onFocus={handleFocus}
            value={values.name}
            size="l"
          />
          <FormText type="invalid">{loginErrorMessage}</FormText>
        </FormControl>
        <FormControl gutter="l" className={styles.form}>
          <Input
            placeholder="Company Name"
            type="text"
            name="companyName"
            onChange={handleChange}
            onFocus={handleFocus}
            value={values.companyName}
            size="l"
          />
          <FormText type="invalid">{loginErrorMessage}</FormText>
        </FormControl>
        <FormControl gutter="l" className={styles.form}>
          <Select
            placeholder="Currency"
            inputClassName={styles.currencySelector}
            size="l"
            options={currencyOptions}
            selectedOption={
              values.currency
                ? {
                    label:
                      currencyOptions.find(
                        (currency) =>
                          currency.value === values.currency?.toString()
                      )?.label ?? '',
                    value: `${values.currency}`,
                  }
                : undefined
            }
            onSelect={(value) => handleSelect('currency', value)}
          />
          <FormText type="invalid">{loginErrorMessage}</FormText>
        </FormControl>
        <FormControl gutter="l" className={styles.form}>
          <Select
            placeholder="Timezone"
            inputClassName={styles.currencySelector}
            size="l"
            options={timezoneList}
            selectedOption={
              values.timezone
                ? {
                    label:
                      timezoneList.find(
                        (timezone) =>
                          timezone.value === values.timezone?.toString()
                      )?.label ?? '',
                    value: `${values.timezone}`,
                  }
                : undefined
            }
            onSelect={(value) => handleSelect('timezone', value)}
          />
          <FormText type="invalid">{loginErrorMessage}</FormText>
        </FormControl>
        <Button
          className={styles.signIn}
          size={'l'}
          onClick={handleFormSubmit}
          fullWidth
          disabled={!isValid()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default Onboard;
