// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pagination_pagination__Zw2vo {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.pagination_results__2BDej,\n.pagination_pages__nRpY8 {\n  display: flex;\n  align-items: center;\n}\n\n.pagination_control__17cDj {\n  min-width: 0;\n  padding: 0;\n  line-height: 30px;\n  height: 28px;\n  width: 44px;\n  border: 1px solid #d0cfd1;\n  background-color: #ffffff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n.pagination_control__17cDj.pagination_disabled__1RGnC {\n  opacity: 0.3;\n  pointer-events: none;\n  cursor: default;\n}\n\n.pagination_next__3gGz3 {\n  border-radius: 0 5px 5px 0;\n}\n.pagination_next__3gGz3 svg {\n  transform: rotate(270deg);\n}\n\n.pagination_prev__2OijW {\n  margin-left: 10px;\n  border-radius: 5px 0 0 5px;\n  border-right-width: 0.5px;\n}\n.pagination_prev__2OijW svg {\n  transform: rotate(90deg);\n}\n.pagination_prev__2OijW.pagination_disabled__1RGnC {\n  border-right-width: 0;\n}\n\n.pagination_popper__2y30k {\n  min-width: 0;\n  width: 52px;\n  margin: 0 4px;\n}\n\n.pagination_text__nUCyH {\n  color: #4b494d;\n  font-size: 13px;\n  letter-spacing: -0.35px;\n  line-height: 16px;\n  margin: 0 8px;\n}\n.pagination_text__nUCyH:first-of-type {\n  margin-left: 0;\n}\n\n.pagination_strong__5XxLK {\n  color: #000000;\n  font-weight: 500;\n}", ""]);
// Exports
exports.locals = {
	"pagination": "pagination_pagination__Zw2vo",
	"results": "pagination_results__2BDej",
	"pages": "pagination_pages__nRpY8",
	"control": "pagination_control__17cDj",
	"disabled": "pagination_disabled__1RGnC",
	"next": "pagination_next__3gGz3",
	"prev": "pagination_prev__2OijW",
	"popper": "pagination_popper__2y30k",
	"text": "pagination_text__nUCyH",
	"strong": "pagination_strong__5XxLK"
};
module.exports = exports;
