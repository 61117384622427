import React, { HTMLAttributes, ReactNode } from 'react';

import {
  TypographyAlign,
  TypographyColor,
  TypographyFontFamily,
  TypographyTagVariant,
  variantsMapping,
} from '@spovio/shared';
import clsx from 'clsx';

import styles from './typography.module.scss';

interface TextProps extends HTMLAttributes<HTMLElement> {
  variant?: TypographyTagVariant;
  color?: TypographyColor;
  textAlign?: TypographyAlign;
  fontFamily?: TypographyFontFamily;
  textOverflow?: 'ellipsis';
  fontWeight?: 'light' | 'regular' | 'medium' | 'semi-bold' | 'bold';
  children: string | ReactNode;
  display?: 'block' | 'inline';
  caps?: boolean;
  textDecoration?: 'dashed' | 'underlined';
}

export const Text = ({
  variant,
  color,
  children,
  fontFamily,
  fontWeight,
  textOverflow,
  textAlign,
  display,
  caps,
  className,
  textDecoration,
  ...props
}: TextProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = variant ? variantsMapping[variant] : 'p';
  return (
    <Tag
      className={clsx(className, {
        [styles[`variant-${variant}`]]: variant,
        [styles[`color-${color}`]]: color,
        [styles[`font-${fontFamily}`]]: fontFamily,
        [styles[`weight-${fontWeight}`]]: fontWeight,
        [styles[`align-${textAlign}`]]: textAlign,
        [styles[`display-${display}`]]: display,
        [styles.caps]: caps,
        [styles.textOverflow]: textOverflow,
        [styles[`textDecoration-${textDecoration}`]]: textDecoration,
      })}
      {...props}
    >
      {children}
    </Tag>
  );
};