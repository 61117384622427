import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const BarGraphIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.667 10.834v3.333M10 5.833v8.334M13.333 8.334v5.833"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M13.753 2.5A3.747 3.747 0 0 1 17.5 6.247v7.507a3.747 3.747 0 0 1-3.747 3.746H6.247A3.747 3.747 0 0 1 2.5 13.753V6.25A3.75 3.75 0 0 1 6.25 2.5h7.503Z"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
