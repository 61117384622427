import React from 'react';

import clsx from 'clsx';

import { SpinIcon } from '../icons';
import styles from './spin.module.scss';

export interface SpinProps {
  text?: string;
  width?: number;
  height?: number;
  className?: string;
  size?: 'full' | '';
}

export const Spin = ({
  height,
  width,
  className,
  text,
  size = '',
}: SpinProps) => {
  return (
    <div
      className={clsx(styles.root, className, styles[size])}
      style={{ height: height + 'px', width: width + 'px' }}
    >
      <div className={styles.inner}>
        <SpinIcon />
        {text ? <p>{text}</p> : null}
      </div>
    </div>
  );
};
