// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gender-profile_header__22pNY {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.gender-profile_header__22pNY .gender-profile_plusIcon__c4kRJ {\n  margin-right: 6px;\n}\n.gender-profile_header__22pNY .gender-profile_bottomContent__2g7D7 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.gender-profile_header__22pNY .gender-profile_bottomContent__2g7D7 .gender-profile_filterButton__2kDhN {\n  padding: 6px 8px;\n}\n.gender-profile_header__22pNY .gender-profile_bottomContent__2g7D7 .gender-profile_filterButton__2kDhN .gender-profile_filterName__z-WoA {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.gender-profile_title__790eQ {\n  display: flex;\n  align-items: center;\n}\n.gender-profile_title__790eQ span {\n  color: #808080;\n  font-size: 14px;\n}\n\n.gender-profile_pagination__W5Q4k {\n  padding: 25px 30px;\n}\n.gender-profile_pagination__W5Q4k .gender-profile_paper__1dD9C {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}", ""]);
// Exports
exports.locals = {
	"header": "gender-profile_header__22pNY",
	"plusIcon": "gender-profile_plusIcon__c4kRJ",
	"bottomContent": "gender-profile_bottomContent__2g7D7",
	"filterButton": "gender-profile_filterButton__2kDhN",
	"filterName": "gender-profile_filterName__z-WoA",
	"title": "gender-profile_title__790eQ",
	"pagination": "gender-profile_pagination__W5Q4k",
	"paper": "gender-profile_paper__1dD9C"
};
module.exports = exports;
