import { Popover } from '@material-ui/core';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from '../button/button';
import { DatePicker } from '../date-picker/date-picker';
import { CalendarIcon, SearchResetIcon } from '../icons';
import { Input, InputProps } from '../input/input';
import { PopperProps } from '../popper/popper';
import styles from './date-picker-popover.module.scss';

interface DatePickerPopoverProps
  extends Pick<InputProps, 'size'>,
    Pick<PopperProps, 'anchorOrigin'> {
  open?: boolean;
  selected?: Date | string;
  id: string;
  mindate?: Date;
  maxdate?: Date;
  startDate?: Date;
  inputStatus?: 'invalid' | boolean;
  endDate?: Date;
  paperClassName?: string;
  placeholder?: string;
  buttonContent?: ReactNode | string;
  showQuickDays?: boolean;
  showResetIcon?: boolean;
  onChange: (date: string | Date) => void;
  onError?: (message: string) => void;
  onClickQuickDays?: (diff: number) => void;
  onReset?: () => void;
  onTextChange?: (text: string) => void;
}

export const DatePickerPopover = ({
  open = false,
  selected,
  paperClassName,
  placeholder,
  onChange,
  onClickQuickDays = () => {
    return null;
  },
  onTextChange,
  onError,
  inputStatus = false,
  showQuickDays,
  anchorOrigin,
  size = 's',
  buttonContent,
  mindate,
  maxdate,
  startDate,
  endDate,
  showResetIcon = false,
  onReset,
  ...props
}: DatePickerPopoverProps) => {
  const [date, setDate] = useState(selected);
  const [visible, setVisible] = useState(open);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggle = (event: any) => {
    setAnchorEl(event.currentTarget);
    setVisible((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setVisible(false);
  };

  useEffect(() => {
    setVisible(open);
  }, [open]);

  useEffect(() => {
    setDate(selected);
  }, [selected]);

  const onDateChange = (date: Date) => {
    const formattedDate = moment(date).format('MM/DD/YYYY');
    onChange(formattedDate);
    setDate(formattedDate);
    handleClose();
  };

  const onChangeQuickDays = (days: number) => {
    onClickQuickDays(days);
    handleClose();
  };

  const onDateReset = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    // setDate(null as any);
    onReset && onReset();
  };

  const renderButton = () => {
    const handleFormatDate = () => {
      if (selected) {
        setDate(moment(selected).format('MM/DD/YYYY'));
        if (moment(selected).format('MM/DD/YYYY') !== 'Invalid date')
          onTextChange && onTextChange(moment(selected).format('MM/DD/YYYY'));
        else {
          onTextChange && onTextChange('');
          onError && onError('Invalid Format');
        }
      }
      return '';
    };

    return (
      <Button
        variant="text"
        color="default"
        ghost
        fullWidth
        className={styles.button}
        isActive={visible}
      >
        {buttonContent ? (
          <div onClick={handleToggle}>{buttonContent}</div>
        ) : (
          <Input
            id={props.id}
            placeholder={placeholder}
            className={styles.input}
            status={inputStatus}
            append={
              <div className={styles.prefixIcon} onClick={handleToggle}>
                {showResetIcon && selected ? (
                  <Button
                    ghost
                    variant="text"
                    color="default"
                    onClick={onDateReset}
                  >
                    <SearchResetIcon className={styles.searchResetIcon} />
                  </Button>
                ) : null}
                <CalendarIcon />
              </div>
            }
            onChange={(e: any) => onTextChange?.(e.target.value)}
            onBlur={handleFormatDate}
            size={size}
            value={selected ? moment(selected).format('DD/MM/YYYY') : undefined}
          />
        )}
      </Button>
    );
  };
  return (
    <div className={styles.root}>
      {renderButton()}
      <Popover
        id={visible ? props.id : undefined}
        open={visible}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={0}
        classes={{ paper: styles.paper }}
      >
        <div className={styles.wrap}>
          <div className={styles.leftContent}>
            <DatePicker
              selected={selected ? moment(selected).toDate() : undefined}
              onChange={onDateChange}
              inline
              showDisabledMonthNavigation
              minDate={mindate}
              maxdate={maxdate}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};
