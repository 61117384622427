import React from 'react';
import { ReactNode } from 'react';

import clsx from 'clsx';

import { Button } from '../button/button';
import { CloseIcon } from '../icons';
import styles from './dialog-header.module.scss';

interface DialogHeaderProps {
  headerVariant?: 'default' | 'compact';
  desc?: string;
  title: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (e: any, reason: string) => void;
  children?: ReactNode;
}

export const DialogHeader = ({
  headerVariant,
  desc,
  title,
  children,
  onClose,
}: DialogHeaderProps) => {
  return (
    <header
      className={clsx(
        styles.header,
        headerVariant && styles[headerVariant],
        desc && styles.hasDescription
      )}
    >
      <div className={styles.title}>{title}</div>
      {desc ? <div className={styles.desc}>{desc}</div> : null}
      <Button
        variant="text"
        color="default"
        className={styles.closeButton}
        ghost
        onClick={(e) => onClose && onClose(e, 'backdropClick')}
      >
        <CloseIcon />
      </Button>
      {children}
    </header>
  );
};
