import { debounce } from '@material-ui/core';
import {
  appRoute,
  approveReimbursementRequest,
  clone,
  COMPONENT_STATE,
  getErrorMessages,
  getReimbursementRequestDetails,
  ReimbursementFilter,
  ReimbursementRequest,
  rejectReimbursementRequest,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Button,
  CloseIcon,
  Header,
  Pagination,
  SearchBar,
  Spin,
  Text,
  useSnackbar,
} from '@spovio/ui';
import { request } from 'http';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext, RouteComponentProps } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import MileagesListTable from '../../reimbursement/reimbursement-list-table/reimbursement-list-table';
import ReimbursementViewDialog from '../../reimbursement/reimbursement-view-dialog/reimbursement-view-dialog';
import styles from './reimbursement-request-details.module.scss';

interface MatchParams {
  id: string;
}

/* eslint-disable-next-line */
export interface ReimbursementRequestDetailsProps
  extends RouteComponentProps<MatchParams, StaticContext> {}

const { LOADED, LOADING } = COMPONENT_STATE;
export function ReimbursementRequestDetails(
  props: ReimbursementRequestDetailsProps
) {
  const { t } = useTranslation();
  const [reimbursements, setReimbursements] = useState<ReimbursementRequest>(
    {} as ReimbursementRequest
  );
  const [componentState, setComponentState] = useState(LOADING);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const handleNavBack = () => {
    history.goBack();
  };

  const getRequests = useCallback(
    async () => {
      setComponentState(LOADING);
      showSnackbar(false);
      try {
        const { params } = props.match;
        const res = await getReimbursementRequestDetails(params.id);
        setReimbursements(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const approveRequest = async () => {
    showSnackbar(false);
    try {
      const res = await approveReimbursementRequest(reimbursements.id);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: 'Request approved successfully',
          severity: 'success',
        });
        history.goBack();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const rejectRequest = async () => {
    showSnackbar(false);
    try {
      const res = await rejectReimbursementRequest(reimbursements.id);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: 'Request rejected successfully',
          severity: 'success',
        });
        history.goBack();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getLeftHeaderContent = () => {
    return (
      <>
        <Button
          className={styles.closeBtn}
          ghost
          variant="text"
          color="default"
          onClick={handleNavBack}
        >
          <CloseIcon />
        </Button>
        <h4>{t('label.details')}</h4>
      </>
    );
  };

  const getRightHeaderContent = () => {
    return (
      <div className={styles.avatarDateWrap}>
        <div className={styles.avatarWrap}>
          <Avatar
            name={reimbursements.created_by?.user?.name}
            textClassName={styles.avatarText}
            displayName
            url={reimbursements.created_by?.user?.pic}
            size={AvatarSize.avatar26}
            className={styles.avatar}
            circle
          />
        </div>
        <div className={styles.title}>
          {reimbursements.reimbursement && reimbursements.reimbursement[0].date}
        </div>
      </div>
    );
  };
  const getMileagesRoutes = () => {
    return (
      <Suspense fallback={<Spin size="full" />}>
        <Route
          exact
          path={appRoute.getPath(`/reimbursements/requests/:reqId/:id/view`)}
          render={(props) => (
            <ReimbursementViewDialog
              {...props}
              onUpdate={() => {}}
              onDelete={() => {}}
              hideDropDown
            />
          )}
        />
      </Suspense>
    );
  };

  const getMileagesTable = () => {
    return (
      <>
        <MileagesListTable
          restrictEdit={true}
          mileages={reimbursements.reimbursement}
          componentState={componentState}
          onListAction={(dropId, mileage) => {
            if (dropId === 'view') {
              history.push(
                appRoute.getRoute(
                  `/reimbursements/requests/${props.match.params.id}/${mileage.id}/view`
                )
              );
            }
          }}
        />
        {reimbursements.status === 'REQUESTED' ? (
          <div className={styles.submit}>
            <Button size={'m'} onClick={approveRequest}>
              {t('label.approve')}
            </Button>
            <Button size={'m'} onClick={rejectRequest} color="secondary">
              {t('label.reject')}
            </Button>
          </div>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={getLeftHeaderContent()}
        rightContent={getRightHeaderContent()}
      />
      {getMileagesTable()}
      {getMileagesRoutes()}
    </>
  );
}

export default ReimbursementRequestDetails;
