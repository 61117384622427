import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import {
  PeopleFilter,
  Reimbursement,
  ReimbursementListType,
  ReimbursementRequest,
  ReimbursementRequestListType,
} from '../types';

//Reimbursements
export const getReimbursementList = async (
  filters?: PeopleFilter
): Promise<AxiosResponse<ReimbursementListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/reimbursements/?${queryParams}`);
};

export const getReimbursement = async (
  id: number
): Promise<AxiosResponse<Reimbursement>> => {
  return await axios.get(`/reimbursements/${id}/`);
};

export const addReimbursement = async (
  data: FormData
): Promise<AxiosResponse<Reimbursement>> => {
  const payload = data;
  return await axios.post(`/reimbursements/`, payload);
};

export const deleteReimbursement = async (
  id: number
): Promise<AxiosResponse<null>> => {
  return await axios.delete(`/reimbursements/${id}/`);
};

export const updateReimbursement = async (
  id: number,
  payload: FormData
): Promise<AxiosResponse<Reimbursement>> => {
  return await axios.patch(`/reimbursements/${id}/`, payload);
};

export const requestReimbursement = async (
  ids: number[]
): Promise<AxiosResponse<ReimbursementRequestListType>> => {
  return await axios.post(`/reimbursements/request/`, { ids });
};

//Requests

export const getReimbursementRequestList = async (
  filters?: PeopleFilter
): Promise<AxiosResponse<ReimbursementRequestListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/reimbursements/request-list/?${queryParams}`);
};

export const getReimbursementRequestDetails = async (
  id: string
): Promise<AxiosResponse<ReimbursementRequest>> => {
  return await axios.get(`/reimbursements/request-item/${id}/`);
};

export const approveReimbursementRequest = async (
  id: number
): Promise<AxiosResponse<Reimbursement>> => {
  return await axios.get(`/reimbursements/request/${id}/approve/`);
};

export const rejectReimbursementRequest = async (
  id: number
): Promise<AxiosResponse<Reimbursement>> => {
  return await axios.get(`/reimbursements/request/${id}/reject/`);
};
