import React, { useEffect } from 'react';

import Modal, { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog';

import { DialogHeader } from '../dialog-header/dialog-header';
import styles from './dialog.module.scss';
import { Button } from '../../index';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  title?: string | React.ReactElement;
  headerVariant?: 'default' | 'compact';
  showHeader?: boolean;
  showFooter?: boolean;
  desc?: string;
  action?: string;
  isActionDisabled?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

export const Dialog = ({
  open,
  onClose,
  showHeader = true,
  showFooter = true,
  headerVariant = 'default',
  title,
  desc,
  action,
  isActionDisabled = false,
  onSubmit,
  ...props
}: DialogProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  useEffect(() => {
    setIsModalVisible(open);
  }, [open]);

  const handleClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: string
  ) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      setIsModalVisible(false);
      onClose();
    }
  };

  return (
    <Modal
      open={isModalVisible}
      className={clsx(styles.dialog)}
      onClose={handleClose}
      {...props}
    >
      <div className={clsx(styles.dialogInner)}>
        {showHeader && title ? (
          <DialogHeader
            title={title}
            headerVariant={headerVariant}
            desc={desc}
            onClose={handleClose}
          />
        ) : null}
        {props.children}
        {showFooter && action ? (
          <div className={styles.footerBlock}>
            <Button
              className={styles.closeBtn}
              variant="outlined"
              color="secondary"
              size={'m'}
              onClick={(e) => handleClose(e, 'backdropClick')}
            >
              {t('label.cancel')}
            </Button>
            <Button
              type="submit"
              className={styles.heroButton}
              size={'m'}
              disabled={isActionDisabled}
              onClick={onSubmit}
            >
              {action}
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
