import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const LocationIcon = (props: IconsProps) => {
  return (
    <svg viewBox="0 0 15 18" {...props} xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#A2A5A8"
        strokeWidth="1.2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.222 9.889h0a2.667 2.667 0 0 1-2.666-2.667h0a2.667 2.667 0 0 1 2.666-2.666h0A2.667 2.667 0 0 1 9.89 7.222h0A2.667 2.667 0 0 1 7.222 9.89Z" />
        <path d="M7.222 17S1 11.889 1 7.222a6.222 6.222 0 1 1 12.444 0C13.444 11.89 7.222 17 7.222 17Z" />
      </g>
    </svg>
  );
};
