import {
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteLocation,
  getErrorMessages,
  getLocations,
  LocationItem,
  LocationList,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  useSnackbar,
} from '@spovio/ui';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationAdd } from './location-add/location-add';
import LocationTableList from './location-table-list/location-table-list';
import styles from './location.module.scss';

/* eslint-disable-next-line */
export interface LocationProps {}
const { LOADED, LOADING } = COMPONENT_STATE;

export function Location(props: LocationProps) {
  const { t } = useTranslation();
  const [locations, setLocations] = useState<LocationList>({} as LocationList);
  const [componentState, setComponentState] = useState(LOADING);
  const [isLocationDialog, setIsLocationDialog] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [editLocation, setEditLocation] = useState(false);
  const [locationItem, setLocationItem] = useState<LocationItem | undefined>();
  const { showConfirmation } = useContext(ConfirmationContext);
  const { isAdmin } = useModuleAccess('dimension_access');
  const { showSnackbar } = useSnackbar();

  const BasicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };

  const [filter, setFilter] = useState<BasicFilter>(BasicFilter);

  const getLocationList = useCallback(
    async (_filter = clone(filter)) => {
      setComponentState(LOADING);
      try {
        const res = await getLocations(_filter);
        setLocations(res.data);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      } finally {
        setComponentState(LOADED);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [mounted]
  );

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      setFilter(filter);
      getLocationList(filter);
    },
    [getLocationList]
  );

  useEffect(() => {
    mounted && getLocationList();
    return () => setMounted(false);
  }, [getLocationList, mounted]);

  const getLocationCreateButton = () => {
    if (!isAdmin) {
      return null;
    }
    return (
      <Button size={'s'} onClick={() => setIsLocationDialog(true)}>
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('dimensions.addLocation')}
        </>
      </Button>
    );
  };

  const onDeleteLocation = async (id: number) => {
    try {
      showSnackbar(false);
      const res = await deleteLocation(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().location.delete,
          severity: 'success',
        });
        getLocationList();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (location: LocationItem) => {
    showConfirmation(true, {
      header: t('dimensions.locationDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteLocation(location.id),
    });
  };

  const onListAction = (action: string, location: LocationItem) => {
    if (action === 'edit') {
      setEditLocation(true);
      setLocationItem(location);
      setIsLocationDialog(true);
    } else if (action === 'delete') {
      deleteConfirmation(location);
    }
  };

  const getLocationTable = () => {
    return (
      <LocationTableList
        locations={locations.results}
        onListAction={(action: string, location: LocationItem) =>
          onListAction(action, location)
        }
        componentState={componentState}
      />
    );
  };

  const onCloseDialog = () => {
    setIsLocationDialog(false);
    setEditLocation(false);
    setLocationItem(undefined);
  };

  const onSubmitDialog = (updated?: boolean) => {
    if (updated) {
      getLocationList(filter);
      showSnackbar(true, {
        msg: userMsgs().location.edit,
        severity: 'success',
      });
    } else {
      getLocationList();
      showSnackbar(true, {
        msg: userMsgs().location.add,
        severity: 'success',
      });
    }
  };

  const getAddLocationDialog = () => {
    if (isLocationDialog) {
      return (
        <LocationAdd
          open={isLocationDialog}
          onClose={onCloseDialog}
          onAdd={onSubmitDialog}
          isEdit={editLocation}
          locationItem={locationItem}
        />
      );
    } else return null;
  };

  const getPaginationFooter = () => {
    if (!locations) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      locations;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.locations')}</h4>}
        rightContent={getLocationCreateButton()}
      />
      {getLocationTable()}
      {getAddLocationDialog()}
      {getPaginationFooter()}
    </>
  );
}

export default Location;
