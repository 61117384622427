import { COMPONENT_STATE } from '@spovio/shared';
import clsx from 'clsx';
import { Skeleton } from '../skeleton/skeleton';
import styles from './summary-status-card.module.scss';

/* eslint-disable-next-line */
export interface SummaryStatusCardProps {
  className?: string;
  title: string;
  value: number | string;
  componentState?: COMPONENT_STATE;
}

export function SummaryStatusCard({
  className,
  title,
  value,
  componentState = COMPONENT_STATE.LOADING,
}: SummaryStatusCardProps) {
  return (
    <div className={clsx(styles.cardWrap, className)}>
      <div className={styles.card}>
        <div className={styles.title}>{title}</div>
        {componentState === COMPONENT_STATE.LOADING ? (
          <Skeleton variant="rect" width={70} height={26} />
        ) : (
          <div className={styles.content}>{value}</div>
        )}
      </div>
    </div>
  );
}

export default SummaryStatusCard;
