// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".full-screen-layout_wrapper__3ptdW {\n  position: relative;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "full-screen-layout_wrapper__3ptdW"
};
module.exports = exports;
