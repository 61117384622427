import { AutocompleteGetTagProps } from '@material-ui/lab';
import {
  Company,
  ContactPayload,
  getBasicCompaniesList,
  getBasicTagsList,
  getErrorMessages,
  Tag,
  validateForm,
} from '@spovio/shared';
import {
  Autocomplete,
  AutoCompleteOptions,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Select,
  TagItem,
  Textarea,
  useSnackbar,
} from '@spovio/ui';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './contact-form.module.scss';

/* eslint-disable-next-line */
export interface ContactFormProps {
  open: boolean;
  title?: string;
  action?: string;
  isEditing?: boolean;
  initialValues: ContactPayload;
  onSubmit: (initialValues: ContactPayload) => void;
}

export const ContactForm = ({
  open,
  title = t('contact.addContact'),
  action = t('label.add'),
  isEditing,
  initialValues,
  onSubmit,
}: ContactFormProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState(initialValues);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelect = (field: string, value: any) => {
    if (field === 'tags') {
      setValues({
        ...values,
        [field]: value.map((item: any) => Number(item.data)),
      });
      return;
    }
    setValues({ ...values, [field]: value });
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    onSubmit(values);
    setIsModalVisible(false);
  };

  const getInitialValues = async () => {
    try {
      const companies = await getBasicCompaniesList();
      setCompanies(companies.data);
      const tags = await getBasicTagsList();
      setTags(tags.data);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const handleClearCompany = () => {
    setValues({ ...values, company: null });
  };

  const formattedSelectItems = (items: any[]) => {
    return items.map((item: any) => {
      return { label: item.name, value: `${item.id}` };
    });
  };

  const formattedTagItems = (items: any[]) => {
    return items.map((item: any) => {
      return { name: item.name, data: `${item.id}` };
    });
  };

  const renderTags = (
    values: AutoCompleteOptions[],
    getTagProps: AutocompleteGetTagProps
  ) => {
    const renderValues = values.filter(
      (option, index) => values.indexOf(option) === index
    );
    return renderValues.map((option, index) => {
      const tagProps = getTagProps({
        index,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { onDelete, key } = tagProps as any;
      const tagName = option.name;
      return (
        <TagItem
          key={key}
          onDelete={onDelete}
          containerClassName={styles.tagItem}
          label={tagName}
        />
      );
    });
  };

  const isValid = () => {
    return (
      validateForm.isRequired(values.name) &&
      (!validateForm.isRequired(values.phone) ||
        validateForm.isPhoneNumber(values.phone)) &&
      (!validateForm.isRequired(values.email) ||
        validateForm.isEmail(values.email))
    );
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  return (
    <div className={styles.root}>
      {!isModalVisible && history.goBack()}
      <Dialog
        title={title}
        action={action}
        open={isModalVisible}
        isActionDisabled={!isValid()}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="name"
              labelClassName={styles.label}
              required
              validationType={['required']}
              label={t('label.name')}
              placeholder={t('contact.contactName')}
              value={values.name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel label={t('label.company')} className={styles.label} />
            <Select
              options={formattedSelectItems(companies)}
              selectedOption={
                values.company
                  ? {
                      label:
                        formattedSelectItems(companies).find(
                          (company) =>
                            company.value === values.company?.toString()
                        )?.label ?? '',
                      value: `${values.company}`,
                    }
                  : undefined
              }
              showResetIcon
              onClear={handleClearCompany}
              onSelect={(value) => handleSelect('company', value)}
              placeholder={t('label.selectCompany')}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="title"
              labelClassName={styles.label}
              label={t('label.title')}
              placeholder={t('label.title')}
              value={values.title}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="email"
              labelClassName={styles.label}
              type="email"
              validationType={['email']}
              label={t('label.email')}
              placeholder={t('label.email')}
              value={values.email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="phone"
              labelClassName={styles.label}
              label={t('label.phone')}
              validationType={['phone']}
              placeholder={t('label.phone')}
              value={values.phone}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel className={styles.label} label={t('label.notes')} />
            <Textarea
              name={'notes'}
              className={styles.textarea}
              placeholder={t('label.notes')}
              size="s"
              minRows={3}
              maxRows={5}
              disableResize
              onChange={handleChange}
              value={values.notes}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel label={t('label.tags')} className={styles.label} />
            <Autocomplete
              multiple
              onChange={(event, tag) => handleSelect('tags', tag)}
              value={formattedTagItems(
                tags.filter((tag) => values.tags.includes(tag.id))
              )}
              options={formattedTagItems(tags)}
              inputClassName={styles.autoCompleteInput}
              renderTags={renderTags}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
};

export default ContactForm;
