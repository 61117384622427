// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  API_URL:
    process.env.NX_NODE_ENV === 'local'
      ? 'http://localhost:7200'
      : 'https://api.spovio.stead.global',
};
