// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".change-password_root__WZ5EU {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  padding-top: 100px;\n}\n.change-password_root__WZ5EU .change-password_title__BKnkK {\n  position: absolute;\n  top: 25px;\n  left: 30px;\n  margin: 0;\n}\n\n.change-password_innerContent__2Zgog {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 864px;\n  padding: 0 100px;\n  width: calc(100% - 16px);\n}\n.change-password_innerContent__2Zgog .change-password_heading__jB-vB {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n.change-password_innerContent__2Zgog .change-password_helperText__32xDb {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin-bottom: 56px;\n}\n.change-password_innerContent__2Zgog .change-password_option__3TTpa {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 25px;\n  color: #6d6971;\n  font-size: 14px;\n  letter-spacing: -0.22px;\n  line-height: 17px;\n  text-align: center;\n}\n.change-password_innerContent__2Zgog .change-password_option__3TTpa .change-password_linkText__2VuUG {\n  margin-left: 3px;\n}\n\n.change-password_actionBtn__3m00O {\n  font-size: 14px;\n  height: 36px;\n  padding: 0 20px;\n  letter-spacing: -0.11px;\n  line-height: 17px;\n}", ""]);
// Exports
exports.locals = {
	"root": "change-password_root__WZ5EU",
	"title": "change-password_title__BKnkK",
	"innerContent": "change-password_innerContent__2Zgog",
	"heading": "change-password_heading__jB-vB",
	"helperText": "change-password_helperText__32xDb",
	"option": "change-password_option__3TTpa",
	"linkText": "change-password_linkText__2VuUG",
	"actionBtn": "change-password_actionBtn__3m00O"
};
module.exports = exports;
