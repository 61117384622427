// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".no-data-content_root__3OpLf {\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  padding: 20px 0;\n}\n\n.no-data-content_content__2jS1x {\n  max-width: 500px;\n}\n\n.no-data-content_title__3-LGc {\n  color: #24252a;\n  font-size: 20px;\n  letter-spacing: -0.22px;\n  line-height: 30px;\n  margin-bottom: 4px;\n}\n\n.no-data-content_desc__1ATNt {\n  color: #828385;\n  font-size: 13px;\n  letter-spacing: 0.5px;\n  line-height: 20px;\n  margin-bottom: 4px;\n}\n\n.no-data-content_btnWrap__2rOLH {\n  padding-top: 15px;\n}\n\n.no-data-content_btn__1XaVj {\n  border-radius: 6px;\n  background-color: #4461cb;\n  height: 28px !important;\n  padding: 0 12px;\n  outline: none;\n  border: none;\n  box-sizing: border-box;\n  color: #ffffff;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 28px;\n  display: flex;\n  align-items: center;\n}\n.no-data-content_btn__1XaVj svg {\n  margin-right: 8px;\n}\n\n.no-data-content_placeholder__1NJ1j {\n  color: #c9cacd;\n  font-weight: 400;\n  letter-spacing: -0.25px;\n  line-height: 26px;\n  min-height: 120px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.no-data-content_placeholder__1NJ1j.no-data-content_md__1MzoU {\n  font-size: 14px;\n}\n.no-data-content_placeholder__1NJ1j.no-data-content_lg__2Hp8j {\n  font-size: 16px;\n}", ""]);
// Exports
exports.locals = {
	"root": "no-data-content_root__3OpLf",
	"content": "no-data-content_content__2jS1x",
	"title": "no-data-content_title__3-LGc",
	"desc": "no-data-content_desc__1ATNt",
	"btnWrap": "no-data-content_btnWrap__2rOLH",
	"btn": "no-data-content_btn__1XaVj",
	"placeholder": "no-data-content_placeholder__1NJ1j",
	"md": "no-data-content_md__1MzoU",
	"lg": "no-data-content_lg__2Hp8j"
};
module.exports = exports;
