import React from 'react';
import clsx from 'clsx';
import styles from './SimpleUserList.module.scss';
import { User } from '@spovio/shared';
import { Avatar, AvatarSize, Button, CloseIcon } from '@spovio/ui';

export interface SimpleUserListProps {
  users: User[];
  className?: string;
  onUserRemove: (user: User) => void;
  variant?: 'compact' | 'default';
}

const SimpleUserList: React.FC<SimpleUserListProps> = ({
  onUserRemove,
  className,
  users,
  variant = 'default',
}: SimpleUserListProps) => {
  const getListBodyMarkup = (users: User[]) => {
    const userListMarkup = users.map((user) => (
      <div
        key={user.id}
        className={clsx(styles.simpleTaskUserItemRoot, styles[variant])}
      >
        <div className={styles.userWrap}>
          <Avatar
            className={styles.avatar}
            url={user.pic}
            name={user.name}
            size={AvatarSize.avatar24}
          />
          <span className={styles.userName}>{user.name}</span>
          <span className={styles.email}>{user.email}</span>
        </div>
        <CloseIcon
          className={styles.closeButton}
          onClick={() => onUserRemove(user)}
        />
      </div>
    ));
    return userListMarkup;
  };

  return (
    <div className={clsx(styles.SimpleUserListWrap, className)}>
      <div className={styles.body}>{getListBodyMarkup(users)}</div>
    </div>
  );
};

export default SimpleUserList;
