import {
  appRoute,
  COMPONENT_STATE,
  currency,
  getDateFormat,
  Reimbursement,
  ReimbursementFilter,
  ReimbursementListType,
  REIMBURSEMENT_TABLE_HEADERS,
  useModuleAccess,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Button,
  Checkbox,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  EvStationIcon,
  EyeIcon,
  NoDataContent,
  PlusIcon,
  StatusBadge,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
} from '@spovio/ui';
import clsx from 'clsx';
import { capitalize, lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './reimbursement-list-table.module.scss';
import styles from './reimbursement-list-table.module.scss';

const { LOADING } = COMPONENT_STATE;

export interface MileagesListTableProps {
  mileages: Reimbursement[];
  filter?: ReimbursementFilter;
  onFilter?: (filter: ReimbursementFilter) => void;
  restrictEdit?: boolean;
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, people: Reimbursement) => void;
  selectedReimbursementList?: number[];
  onselect?: (id: number) => void;
}

export function MileagesListTable({
  mileages,
  filter,
  onFilter,
  selectedReimbursementList,
  componentState,
  onListAction,
  onselect,
  restrictEdit = false,
}: MileagesListTableProps) {
  const { t } = useTranslation();
  const isLoading = componentState === LOADING;
  const { isAdmin } = useModuleAccess('reimbursement_access');
  const headerContent = REIMBURSEMENT_TABLE_HEADERS();
  const getMileagesCreateButton = () => {
    if (!isAdmin) return null;
    return (
      <Button
        size={'s'}
        component={NavLink}
        to={appRoute.getRoute(`/reimbursements/list/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('reimbursement.addReimbursement')}
        </>
      </Button>
    );
  };

  const getHeaderRowMarkup = () => {
    let filteredHeaders = headerContent;
    if (restrictEdit)
      filteredHeaders = headerContent.filter(
        (header) => header.id !== 'person' && header.id !== 'date'
      );
    return (
      <TableListRow className={styles.row}>
        {filteredHeaders.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <div className={clsx(styles.heading)}>{item.label}</div>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: Reimbursement[]) => {
    return results.map((item) => {
      const {
        id,
        user,
        name,
        amount,
        date,
        notes,
        status,
        reimbursement_type,
      } = item;
      const cells = [
        {
          classes: clsx(styles.col, styles.dateCol),
          content: onselect ? (
            <Checkbox
              className={styles.checkbox}
              checked={selectedReimbursementList?.includes(item.id)}
              onChange={(e) => {
                onselect(item.id);
              }}
              disabled={['APPROVED', 'REQUESTED'].includes(status)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              label={<> {getDateFormat(date, 'MMM DD YYYY')}</>}
            />
          ) : (
            <div> {getDateFormat(date, 'MMM DD YYYY')}</div>
          ),
          name: 'checkbox',
        },
        {
          classes: clsx(styles.col, styles.nameCol),
          content: <div className="ellipsis">{name}</div>,
        },
        {
          classes: clsx(styles.col, styles.typeCol),
          content: (
            <div className="ellipsis">{capitalize(reimbursement_type)}</div>
          ),
        },
        {
          classes: clsx(styles.col, styles.notesCol),
          content: <div className="ellipsis">{notes}</div>,
        },
        {
          classes: clsx(styles.col, styles.amountCol),
          content: (
            <div className="ellipsis">
              {currency.getSymbol() + ' ' + amount.toFixed(2)}
            </div>
          ),
        },
        {
          classes: clsx(styles.col, styles.statusCol),
          content: (
            <StatusBadge
              status={lowerCase(status)}
              label={
                status === 'APPROVED'
                  ? t('label.approved')
                  : status === 'REJECTED'
                  ? t('label.rejected')
                  : t('label.pending').toLowerCase()
              }
            />
          ),
        },
      ];
      if (!restrictEdit) {
        cells.splice(2, 0, {
          classes: clsx(styles.col, styles.personCol),
          content: (
            <Avatar
              url={user.user.pic}
              name={user.user.name}
              size={AvatarSize.avatar30}
              className={styles.userIcon}
              displayName
              circle
            />
          ),
        });
      } else {
        cells.splice(0, 1);
      }

      const dropdownItems: DropdownListItem[] = [
        {
          label: t('label.view'),
          icon: <EyeIcon />,
          id: 'view',
        },
        {
          label: t('label.edit'),
          icon: <EditIcon />,
          id: 'edit',
        },
        {
          label: t('label.delete'),
          id: 'delete',
          icon: <DeleteIcon />,
        },
      ];
      return (
        <TableListRow
          className={styles.trow}
          key={id}
          onClick={(e) => {
            e.stopPropagation();
            onListAction('view', item);
          }}
        >
          {cells.map((item, index) => {
            return (
              <TableListCell
                className={clsx(item.classes, 'ellipsis')}
                key={index}
                onClick={(e) => {
                  if (item.name === 'checkbox') e.stopPropagation();
                }}
              >
                {item.content}
              </TableListCell>
            );
          })}

          <TableListCell
            className={clsx(styles.col, styles.actionCol)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!restrictEdit && item.status !== 'APPROVED' && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      if (!mileages?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('reimbursement.noData.head')}
              desc={t('reimbursement.noData.desc')}
              icon={<EvStationIcon className={styles.noDataIcon} />}
              buttonComponent={getMileagesCreateButton()}
            />
          </div>
        );
      }
      return getBodyRowMarkup(mileages);
    }
  };
  return (
    <div className={clsx(styles.root, restrictEdit && styles.freeze)}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default MileagesListTable;
