import {
  COMPONENT_STATE,
  ProjectItem,
  Tenant,
  useModuleAccess,
} from '@spovio/shared';
import {
  AvatarGroup,
  AvatarGroupAlign,
  AvatarSize,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  NoDataContent,
  ProjectIcon,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './project-table-list.module.scss';

const { LOADING } = COMPONENT_STATE;

/* eslint-disable-next-line */
export interface ProjectTableListProps {
  projects: ProjectItem[];
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, project: ProjectItem) => void;
}

export function ProjectTableList({
  projects,
  componentState,
  onListAction,
}: ProjectTableListProps) {
  const { isAdmin } = useModuleAccess('dimension_access');
  const { t } = useTranslation();

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.trow}>
        <TableListCell className={clsx(styles.nameCol, styles.col)}>
          <div className={styles.heading}>{t('label.name')}</div>
        </TableListCell>
        <TableListCell className={clsx(styles.usersCol, styles.col)}>
          <div className={styles.heading}>{t('label.assigned')}</div>
        </TableListCell>
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getProjectAvatarMarkup = (users: Tenant[]) => {
    const avatarList = users.map((user) => {
      return {
        size: AvatarSize.avatar24,
        url: user.user.pic,
        name: user.user.name,
        circle: true,
        key: user.id,
      };
    });

    return (
      <AvatarGroup
        align={AvatarGroupAlign.RIGHT}
        limit={3}
        size={24}
        avatarList={avatarList}
      />
    );
  };

  const getBodyRowMarkup = (results: ProjectItem[]) => {
    return results.map((item, index) => {
      const { id, name } = item;
      const dropdownItems: DropdownListItem[] = [
        {
          label: t('label.edit'),
          icon: <EditIcon />,
          id: 'edit',
        },
        {
          label: t('label.delete'),
          id: 'delete',
          icon: <DeleteIcon />,
        },
      ];
      return (
        <TableListRow className={styles.trow} key={id}>
          <TableListCell
            className={clsx(styles.nameCol, styles.listItem, styles.col)}
            // key={index}
          >
            <div className={styles.itemWrap}>
              <div className={styles.iconWrap}>
                <ProjectIcon className={styles.projectIcon} />
              </div>
              {name}
            </div>
          </TableListCell>
          <TableListCell
            className={clsx(styles.usersCol, styles.listItem, styles.col)}
            key={index}
          >
            {getProjectAvatarMarkup(item.users)}
          </TableListCell>

          <TableListCell className={clsx(styles.col, styles.actionCol)}>
            {isAdmin && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = [styles.nameCol, clsx(styles.usersCol, styles.pl0)];
      const widths = [200, 90];

      return (
        <TablePreloader
          rows={5}
          columns={2}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      if (!projects?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={'Projects'}
              desc={"You don't have any project"}
              icon={<ProjectIcon className={styles.noDataIcon} />}
            />
          </div>
        );
      }
      return getBodyRowMarkup(projects);
    }
  };

  return (
    <div className={styles.root}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default ProjectTableList;
