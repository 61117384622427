import { DragEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../..';
import { CloseIcon, FileIcon } from '../icons';
import styles from './drag-and-drop-field.module.scss';

/* eslint-disable-next-line */
export interface DragAndDropFieldProps {
  fileName?: string;
  onDrop: (file: File) => void;
  onRemove: () => void;
}

export function DragAndDropField({
  fileName,
  onDrop,
  onRemove,
}: DragAndDropFieldProps) {
  const { t } = useTranslation();
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) onDrop(files[0]);
  };

  return (
    <div>
      {!fileName ? (
        <div
          className={styles.upload}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <div className={styles.dragCaption}>
            <span className={styles['color-dove_grey']}>
              {t('extension.dragAndDrop')}
            </span>
            <label htmlFor="photo" className={styles.label}>
              <input
                type="file"
                className={styles.input}
                style={{ display: 'none' }}
                id="photo"
                name="photo"
                onChange={(e) => {
                  if (e.target?.files) onDrop(e.target.files[0]);
                }}
              />
              &nbsp; {t('extension.browse')}
            </label>
          </div>
        </div>
      ) : (
        <div className={styles.fileWrap}>
          <div className={styles.titleWrap}>
            <FileIcon className={styles.fileIcon} />
            <Text variant="body1" className={styles.fileName}>
              {fileName}
            </Text>
          </div>
          <CloseIcon className={styles.removeIcon} onClick={onRemove} />
        </div>
      )}
    </div>
  );
}

export default DragAndDropField;
