import { debounce } from '@material-ui/core';
import {
  appRoute,
  approveReimbursementRequest,
  clone,
  COMPONENT_STATE,
  getErrorMessages,
  getReimbursementRequestList,
  ReimbursementFilter,
  ReimbursementRequest,
  ReimbursementRequestListType,
  rejectReimbursementRequest,
} from '@spovio/shared';
import { Header, Pagination, SearchBar, useSnackbar } from '@spovio/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReimbursementRequestTable from '../reimbursement-request-table/reimbursement-request-table';
import styles from './reimbursement-request-list.module.scss';

/* eslint-disable-next-line */
export interface ReimbursementRequestListProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export function ReimbursementRequestList(props: ReimbursementRequestListProps) {
  const { t } = useTranslation();
  const [requests, setRequests] = useState<ReimbursementRequestListType>();
  const [componentState, setComponentState] = useState(LOADING);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const requestsFilter: ReimbursementFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };

  const [filter, setFilter] = useState<ReimbursementFilter>(requestsFilter);

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getReimbursementRequestList(_filter);
        setRequests(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFilter = useCallback(
    (filter: ReimbursementFilter) => {
      setComponentState(LOADING);
      getRequests(filter);
    },
    [getRequests]
  );
  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
      </div>
    );
  };

  const approveRequest = async (request: ReimbursementRequest) => {
    showSnackbar(false);
    try {
      const res = await approveReimbursementRequest(request.id);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: 'Request approved successfully',
          severity: 'success',
        });
        getRequests();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const rejectRequest = async (request: ReimbursementRequest) => {
    showSnackbar(false);
    try {
      const res = await rejectReimbursementRequest(request.id);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: 'Request rejected successfully',
          severity: 'success',
        });
        getRequests();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onListAction = (dropId: string, request: ReimbursementRequest) => {
    switch (dropId) {
      case 'view':
        history.push(
          appRoute.getRoute(`/reimbursements/requests/${request.id}`)
        );
        break;
      case 'approve':
        approveRequest(request);
        break;
      case 'reject':
        rejectRequest(request);
        break;
    }
    return;
  };

  const getRequestTable = () => {
    if (requests)
      return (
        <ReimbursementRequestTable
          requests={requests.results}
          filter={filter}
          onFilter={onFilter}
          componentState={componentState}
          onListAction={onListAction}
        />
      );
    return null;
  };

  const getPaginationFooter = () => {
    if (!requests) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      requests;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.requests')}</h4>}
        bottomContent={getFilters()}
      />
      {getRequestTable()}
      {getPaginationFooter()}
    </>
  );
}

export default ReimbursementRequestList;
