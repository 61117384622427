import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TimeOffIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-36"
          transform="translate(-9.000000, -7.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Group-34" transform="translate(8.000000, 5.000000)">
            <g
              id="alarm-clock-time-timer.5"
              transform="translate(0.000000, 1.000000)"
            >
              <g id="Group" transform="translate(2.500076, 2.499727)">
                <path
                  d="M4.6307572,0.572773124 C8.45659054,-1.01222688 12.8424239,0.805273124 14.4274239,4.63110646 C16.0124239,8.45693979 14.1949239,12.8427731 10.3690905,14.4277731 C6.5432572,16.0127731 2.15742387,14.1952731 0.572423871,10.3694398 C-1.0117428,6.54360646 0.804923871,2.15777312 4.6307572,0.572773124"
                  id="Path"
                ></path>
                <polyline
                  id="Path"
                  points="7.26492387 4.15443979 7.26492387 8.03027312 10.3115905 9.88777312"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
