import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ArchiveIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.083 3.5h5.833M4.083 5.25h5.833M5.25 9.917h3.5"
        stroke="#828385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 7h-.233a.35.35 0 0 0-.35.35v5.134c0 .193.157.35.35.35h10.967a.35.35 0 0 0 .35-.35V7.35a.35.35 0 0 0-.35-.35h-.234M1.75 7h10.5M1.75 7V1.517a.35.35 0 0 1 .35-.35h9.8a.35.35 0 0 1 .35.35V7"
        stroke="#828385"
        strokeWidth="1.2"
      />
    </svg>
  );
};
