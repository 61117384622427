import React from 'react';

type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const PhoneIcon = (props: IconProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Phone</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile"
          transform="translate(-270.000000, -272.000000)"
          fill="#808080"
        >
          <g id="Group-23" transform="translate(270.000000, 269.000000)">
            <g id="Phone" transform="translate(0.000000, 3.416028)">
              <path
                d="M7.76273491,7.87789253 C7.42140022,8.17295093 6.8294626,8.23292684 6.44055223,7.99409898 C6.44055223,7.99409898 6.57821373,8.11053203 5.91330759,7.64033754 C5.53590278,7.37345226 5.12678738,7.05225329 4.77155417,6.70106731 C4.53088605,6.46314115 4.30939038,6.20595504 4.11214346,5.95327598 C3.5053181,5.17591501 3.53408298,5.13323573 3.53408298,5.13323573 C3.30980948,4.75104149 3.35620786,4.16948063 3.65161226,3.81773747 L3.89224404,3.53121301 C4.32433404,3.01671593 4.28509044,2.21604874 3.81022036,1.74841635 L2.89913505,0.851217279 C2.42174462,0.381102957 1.62298256,0.367868397 1.12449569,0.8132183 L1.20713526,0.739387827 C0.540452863,1.33500419 0.0616610514,2.54251336 0.161522388,3.43580437 C0.161522388,3.43580437 0.139527427,3.83486247 0.402354699,4.75178569 C1.3633596,8.10443515 3.97280349,10.5672342 7.43741942,11.3332289 C8.18144789,11.4977267 8.16521329,11.4475995 8.16521329,11.4475995 C9.05701576,11.5229157 10.2627002,11.0420393 10.8590313,10.3725815 L10.764982,10.4781639 C11.2158442,9.97201355 11.1988575,9.17813886 10.7219803,8.69992028 L9.77114036,7.74640573 C9.29652892,7.27045926 8.5019336,7.23891036 7.98683445,7.68417518 L7.76273491,7.87789253 Z"
                id="Stroke-889"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
