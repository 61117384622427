import { t } from 'i18next';

export enum TimeEntryInputType {
  FROZEN = 'frozen',
  LOCKED = 'locked',
  DEFAULT = 'default',
}
export enum TABS {
  PENDING,
  APPROVED,
}
export const TIME_APPROVAL_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date'),
    className: 'dateCol',
  },
  {
    id: 'person',
    label: t('label.person'),
    className: 'personCol',
  },
  {
    id: 'hours',
    label: t('label.hours'),
    className: 'hoursCol',
  },
];

export const TIME_WEEKS_TABLE_HEADERS = () => [
  {
    id: 'week',
    label: t('label.week'),
    className: 'weekCol',
  },
  {
    id: 'person',
    label: t('label.person'),
    className: 'personCol',
  },
  {
    id: 'hours',
    label: t('label.hours'),
    className: 'hoursCol',
  },
  {
    id: 'status',
    label: t('label.status'),
    className: 'statusCol',
  },
];

export const TIME_APPROVAL_DETAILS_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date'),
    className: 'dateCol',
  },
  {
    id: 'project',
    label: t('label.projects'),
    className: 'projectCol',
  },
  {
    id: 'hour',
    label: t('label.hours'),
    className: 'hourCol',
  },
];

export const TIME_RANGE_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date'),
    className: 'dateCol',
  },
  {
    id: 'project',
    label: t('label.projectAndNote'),
    className: 'projectCol',
  },
  {
    id: 'person',
    label: t('label.person'),
    className: 'personCol',
  },
  {
    id: 'hours',
    label: t('label.hours'),
    className: 'hoursCol',
  },
];

export const TIME_REPORT_SUMMARY_HEADERS = () => [
  {
    id: 'person',
    label: t('label.person'),
    className: 'personCol',
  },

  {
    id: 'hours',
    label: t('label.hours'),
    className: 'hoursCol',
  },
];

export const TIME_RANGE_SELECT_OPTIONS = () => [
  {
    label: t('label.week'),
    value: 'week',
  },
  {
    label: t('label.month'),
    value: 'month',
  },
  {
    label: t('label.year'),
    value: 'year',
  },
  {
    label: t('label.allTime'),
    value: 'all_time',
  },
  {
    label: t('label.custom'),
    value: 'custom',
  },
];

export enum ReportColumns {
  NONE = 'NONE',
  DATE = 'DATE',
  PROJECT = 'PROJECT',
  PERSON = 'PERSON',
}

export enum TimeOffReportColumns {
  NONE = 'NONE',
  DATE = 'FROM_DATE',
  TYPE = 'TYPE',
  PERSON = 'PERSON',
}
