import axios, { AxiosResponse } from 'axios';
import { appInfo } from '../constants';
import { Account, User } from '../types';

import { UserProfile } from '../types/user-profile';

export const getUserProfile = async (): Promise<AxiosResponse<UserProfile>> => {
  return await axios.get(`/user-profile/`);
};

export const updateUserProfile = async (
  payload: Partial<User>
): Promise<AxiosResponse<User>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'patch',
    url: '/auth/user/',
    data: payload,
  });
};

export const createAccount = async (
  payload: Partial<Account>
): Promise<AxiosResponse<Account>> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'post',
    url: '/account/',
    data: payload,
  });
};

export const updateAccount = async (
  payload: Partial<Account>
): Promise<AxiosResponse<Account>> => {
  return await axios.patch(`/account/`, payload);
};
