import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { InputProps } from '../input/input';
import styles from './input-placeholder.module.scss';

interface InputPlaceholderProps extends Pick<InputProps, 'size' | 'disabled'> {
  id?: string;
  showLabelAsTag?: boolean;
  hasValue?: boolean;
  active?: boolean;
  append?: string | ReactNode;
  renderInputContent: () => React.ReactElement;
  className?: string;
  ref?: any;
}

export const InputPlaceholderBase = (
  {
    id,
    className,
    disabled,
    showLabelAsTag,
    hasValue,
    size = 'm',
    active,
    append,
    renderInputContent,
    ...restProps
  }: InputPlaceholderProps,
  ref: any
) => {
  return (
    <div
      id={id}
      ref={ref}
      role={'button'}
      className={clsx(className, styles.input, styles[size], {
        [styles.disabled]: disabled,
        [styles.hasTag]: showLabelAsTag,
        [styles.hasValue]: !!hasValue,
        [styles.active]: active,
      })}
      {...restProps}
    >
      {renderInputContent()}
      <div className={styles.append}>{append}</div>
    </div>
  );
};
export const InputPlaceholder = React.forwardRef(InputPlaceholderBase);
