import {
  appRoute,
  COMPONENT_STATE,
  Contact,
  getDateFormat,
  useModuleAccess,
} from '@spovio/shared';
import {
  Divider,
  Dropdown,
  DropdownListItem,
  EditIcon,
  EmailIcon,
  PhoneIcon,
  Skeleton,
  Text,
} from '@spovio/ui';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './contact-basic-info.module.scss';

/* eslint-disable-next-line */
export interface ContactBasicInfoProps {
  contact: Contact;
  componentState: COMPONENT_STATE;
}

const { LOADING } = COMPONENT_STATE;
export const ContactBasicInfo = ({
  contact,
  componentState,
}: ContactBasicInfoProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useModuleAccess('contact_access');
  const history = useHistory();
  const isLoading = componentState === LOADING;

  const scrollToTop = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.currentTarget.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
  };

  const dropdownItems: DropdownListItem[] = [
    {
      label: t('label.edit'),
      icon: <EditIcon />,
      id: 'edit',
      to: `/people/${'id'}/edit`,
    },
  ];

  const onListAction = (dropId: string, people: string) => {
    if (dropId === 'edit') {
      history.push({
        pathname: appRoute.getRoute(`/contacts/list/${contact.id}/view/edit`),
      });
    }
    return null;
  };

  const getBasicSection = () => (
    <div className={clsx(styles['basic-details'], isAdmin && styles['mt-0'])}>
      <div>
        <div className={styles['employee-name']}>
          {isLoading ? (
            <Skeleton variant={'rect'} width={140} height={15} />
          ) : (
            contact.name
          )}
        </div>
        <div className={styles['employee-title']}>{contact.title}</div>
      </div>
    </div>
  );

  const getContactSection = () => (
    <div className={styles.contacts}>
      <div className={styles.phone}>
        <PhoneIcon />
        {isLoading ? (
          <Skeleton width={80} height={15} />
        ) : (
          <div className={styles.textWrap}>
            <Text
              variant="body2"
              color="dove_grey"
              className={clsx(styles.text, 'no-wrap')}
              onMouseLeave={scrollToTop}
            >
              {contact.phone ? contact.phone : '—'}
            </Text>
          </div>
        )}
      </div>
      <div className={styles.email}>
        <EmailIcon />
        {isLoading ? (
          <Skeleton width={80} height={15} />
        ) : (
          <div className={styles.textWrap}>
            <Text
              variant="body2"
              color="dove_grey"
              className={clsx(styles.text, 'no-wrap')}
              onMouseLeave={scrollToTop}
            >
              {contact.email ? contact.email : '—'}
            </Text>
          </div>
        )}
      </div>
    </div>
  );

  const getDetailsSection = () => {
    return (
      <div className={styles.details}>
        <Text variant="h6" className={styles.heading}>
          {t('label.details')}
        </Text>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.title')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {contact.title ? contact.title : '—'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.company')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <NavLink
              to={appRoute.getRoute(
                `/contacts/companies/${contact.company?.id}/view`
              )}
            >
              <Text variant="body3" color="dark_grey" fontWeight="medium">
                {contact.company?.name ? contact.company?.name : '—'}
              </Text>
            </NavLink>
          )}
        </div>
      </div>
    );
  };

  const getAuditTrail = () => (
    <div className={styles.details}>
      <Text variant="h6" className={styles.heading}>
        {t('label.auditTrail')}
      </Text>
      <div className={styles.detail}>
        {/* <Text variant="body2" color="dove_grey">
          Created On {getDateFormat(contact.created_at, 'MMM DD YYYY')} by{' '}
          {contact.created_by?.name}
        </Text> */}
        {isLoading ? (
          <Skeleton width={80} height={15} />
        ) : (
          <Text variant="body2" color="dove_grey">
            {t('label.createdOn')}{' '}
            {getDateFormat(contact.created_at, 'MMM DD YYYY')} by{' '}
            {contact.created_by?.name}
          </Text>
        )}
      </div>
      {contact.updated_by?.name && (
        <div className={styles.detail}>
          {/* <Text variant="body2" color="dove_grey">
          Updated On
        </Text> */}
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body2" color="dove_grey">
              {contact.updated_at
                ? `Updated On ${getDateFormat(
                    contact.updated_at,
                    'MMM DD YYYY'
                  )} by ${contact.updated_by?.name}`
                : '—'}
            </Text>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.info}>
      <div className={styles.dropdown}>
        {isAdmin ? (
          <Dropdown
            buttonClassName={styles.actionContent}
            data={dropdownItems}
            onItemClick={(drpId) => onListAction(drpId, 'item')}
          />
        ) : (
          <div className={styles.actionContent}></div>
        )}
      </div>
      {getBasicSection()}
      {getContactSection()}
      <Divider />
      {getDetailsSection()}
      <Divider />
      {getAuditTrail()}
    </div>
  );
};

export default ContactBasicInfo;
