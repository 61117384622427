import {
  appRoute,
  BasicFilter,
  COMPONENT_STATE,
  getDateFormat,
  TIME_WEEKS_TABLE_HEADERS,
  useModuleAccess,
  Week,
} from '@spovio/shared';
import {
  ApprovedIcon,
  Avatar,
  AvatarSize,
  NoDataContent,
  StatusBadge,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  WarningIcon,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './time-incomplete-week-table-list.module.scss';

/* eslint-disable-next-line */
export interface TimeIncompleteWeekTableListProps {
  className?: string;
  weeks: Week[];
  filter?: BasicFilter;
  onFilter?: (filter: BasicFilter) => void;
  componentState: COMPONENT_STATE;
}

const { LOADING } = COMPONENT_STATE;
export const TimeIncompleteWeekTableList = ({
  className,
  weeks,
  filter,
  onFilter,
  componentState,
}: TimeIncompleteWeekTableListProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useModuleAccess('time_access');
  const history = useHistory();

  const headerContent = TIME_WEEKS_TABLE_HEADERS();

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <div className={clsx(styles.heading)}>{item.label}</div>
            </TableListCell>
          );
        })}
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: Week[]) => {
    return results.map((item, index) => {
      const {
        week_total,
        created_by__user__name,
        created_by__user__pic,
        week,
        start_date,
        end_date,
        status,
      } = item;
      const cells = [
        {
          classes: clsx(styles.col, styles.weekCol),
          content: (
            <div className="ellipsis">
              {getDateFormat(start_date, 'DD MMM') +
                ' - ' +
                getDateFormat(end_date, 'DD MMM YYYY')}
            </div>
          ),
        },
        {
          classes: clsx(styles.col, styles.personCol),
          content: (
            <div className={clsx(styles.avatarWrap, 'ellipsis')}>
              <Avatar
                url={created_by__user__pic}
                name={created_by__user__name}
                size={AvatarSize.avatar26}
                className={styles.userIcon}
                displayName
                textClassName={styles.userText}
                circle
              />
            </div>
          ),
        },
        {
          classes: clsx(styles.col, styles.hoursCol),
          content: <div className="ellipsis">{week_total.toFixed(2)}</div>,
        },
        {
          classes: clsx(styles.col, styles.statusCol),
          content: (
            <div className="ellipsis">
              <StatusBadge
                status={status}
                label={
                  status === 'complete'
                    ? t('label.complete')
                    : t('label.incomplete')
                }
              />
            </div>
          ),
        },
      ];

      return (
        <TableListRow className={styles.trow} key={index}>
          {cells.map((cell, index) => {
            return (
              <TableListCell className={clsx(cell.classes)} key={index}>
                <div className={clsx(styles.itemWrap, 'ellipsis')}>
                  {cell.content}
                </div>
              </TableListCell>
            );
          })}
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      if (!weeks?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('timesheet.incompleteWeeksNoData.head')}
              desc={t('timesheet.incompleteWeeksNoData.desc')}
              icon={<WarningIcon className={styles.noDataIcon} />}
            />
          </div>
        );
      }
      return getBodyRowMarkup(weeks);
    }
  };

  return (
    <div className={clsx([styles.root, className])}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
};

export default TimeIncompleteWeekTableList;
