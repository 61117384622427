import moment from 'moment';

export enum DefinedLabels {
  Last7Days = 'Last 7 days',
  Last4weeks = 'Last 4 weeks',
  Last3months = 'Last 3 months',
  Last12months = 'Last 12 months',
  MonthToDate = 'Month to date',
  QuarterToDate = 'Quarter to date',
  YearToDate = 'Year to date',
  AllTime = 'All time',
  Custom = 'Custom',
}

export type DateRangeType = {
  startDate: Date;
  endDate: Date;
  label?: string;
};

const prevDate = moment().subtract(1, 'd').toDate();
const startOfWeek = moment().startOf('isoWeek').toDate();
const startOfMonth = moment().startOf('month').toDate();
const startOfYear = moment().startOf('year').toDate();

export const definedRanges = {
  currentDate: moment().toDate(),
  prevDate,
  startOfMonth,
  startOfWeek,
  startOfYear,
  last7days: moment().subtract(7, 'd').toDate(),
  last4weeks: moment(startOfWeek).subtract(4, 'week').toDate(),
  startOfLast3mouth: moment(startOfMonth).subtract(3, 'month').toDate(),
  startOfLast12months: moment(startOfYear).subtract(12, 'month').toDate(),
  startOfQuarter: moment().startOf('quarter').toDate(),
  endOfWeek: moment().endOf('isoWeek').toDate(),
  startOfLastWeek: moment().subtract(1, 'w').startOf('isoWeek').toDate(),
  endOfLastWeek: moment().subtract(1, 'w').endOf('isoWeek').toDate(),
  endOfMonth: moment().endOf('month').toDate(),
  startOfLastMonth: moment().subtract(1, 'month').startOf('month').toDate(),
  endOfLastMonth: moment().subtract(1, 'month').endOf('month').toDate(),
  endOfYear: moment().endOf('year').toDate(),
  StartOfLastYear: moment().subtract(1, 'year').startOf('year').toDate(),
  endOfLastYear: moment().subtract(1, 'year').endOf('year').toDate(),
};

export const getLabel = (startDt: Date, endDt: Date) => {
  switch (true) {
    case moment(definedRanges.last7days).isSame(startDt, 'd') &&
      moment(definedRanges.currentDate).isSame(endDt, 'd'):
      return DefinedLabels.Last7Days;
    case moment(definedRanges.last4weeks).isSame(startDt, 'd') &&
      moment(definedRanges.endOfLastWeek).isSame(endDt, 'd'):
      return DefinedLabels.Last4weeks;
    case moment(definedRanges.startOfLast3mouth).isSame(startDt, 'd') &&
      moment(definedRanges.endOfLastMonth).isSame(endDt, 'd'):
      return DefinedLabels.Last3months;
    case moment(definedRanges.startOfLast12months).isSame(startDt, 'd') &&
      moment(definedRanges.endOfLastMonth).isSame(endDt, 'd'):
      return DefinedLabels.Last12months;
    case moment(definedRanges.startOfQuarter).isSame(startDt, 'd') &&
      moment(definedRanges.currentDate).isSame(endDt, 'd'):
      return DefinedLabels.QuarterToDate;
    default:
      return getDefaultDateRange(startDt, endDt, 'DD MMM');
  }
};

export const getDefaultDateRange = (
  startDt: Date | string,
  endDt: Date | string,
  format = 'DD MMM'
) => {
  const isSingleDay = moment(startDt).isSame(endDt, 'd');
  return isSingleDay
    ? moment(startDt).format(format)
    : `${moment(startDt).format(format)} - ${moment(endDt).format(format)}`;
};
