import React from 'react';

type Props = React.HTMLAttributes<HTMLOrSVGElement>;

export const SearchResetIcon = (props: Props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 16 16"
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx="8" cy="8" r="8" />
        <rect fill="#adb2bb" width="16" height="16" rx="8" />
        <path
          d="M5.784 4.381l.095.083L8 6.586l2.121-2.122a1 1 0 011.498 1.32l-.083.095L9.414 8l2.122 2.121a1 1 0 01-1.32 1.498l-.095-.083L8 9.414l-2.121 2.122a1 1 0 01-1.498-1.32l.083-.095L6.586 8 4.464 5.879a1 1 0 011.219-1.567l.101.07z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
