import { USERROLECHOICES } from '@spovio/shared';
import {
  AccountSettingsIcon,
  ApprovalIcon,
  ArchiveIcon,
  CalendarIcon,
  CompanyIcon,
  CompensationIcon,
  ContactListIcon,
  DepartmentListIcon,
  DirectoryIcon,
  DivisionIcon,
  EmergencyContactIcon,
  FilesIcon,
  LocationIcon,
  MyMileageIcon,
  PasswordIcon,
  PeopleIcon,
  Profile,
  ProjectIcon,
  ReportIcon,
  TagIcon,
  TimeOffIcon,
  TimesIcon,
  WarningIcon,
  WebSiteIcon,
} from '@spovio/ui';
import { t } from 'i18next';

const reimbursementsNavList = () => [
  {
    items: [
      {
        path: '/reimbursements/list',
        name: t('label.reimbursements'),
        icon: <MyMileageIcon />,
      },
      {
        path: '/reimbursements/requests',
        name: t('label.requests'),
        icon: <ApprovalIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
    ],
    divider: false,
  },
];

const peopleNavList = () => [
  {
    items: [
      {
        path: '/people/directory',
        name: t('label.people'),
        icon: <PeopleIcon />,
      },
      {
        path: '/people/approve',
        name: t('label.timeOffApproval'),
        icon: <ApprovalIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
      {
        path: '/people/time-off-type',
        name: t('label.timeOffType'),
        icon: <TimeOffIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
      {
        path: '/people/time-off-report',
        name: t('label.timeOffReport'),
        icon: <ReportIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
      {
        path: '/people/calendar',
        name: t('label.calendar'),
        icon: <CalendarIcon />,
      },
    ],
    divider: true,
  },
  {
    items: [
      {
        path: '/people/profile',
        name: 'Profile',
        icon: <Profile />,
      },
      {
        path: '/people/compensation',
        name: 'Compensation',
        icon: <CompensationIcon />,
      },
      {
        path: '/people/emergency',
        name: 'Emergency contact',
        icon: <EmergencyContactIcon />,
      },
      {
        path: '/people/time-off',
        name: 'Time off',
        icon: <TimeOffIcon />,
      },
      {
        path: '/people/files',
        name: 'Files',
        icon: <FilesIcon />,
      },
      {
        path: '/people/training',
        name: 'Training',
        icon: <TimeOffIcon />,
      },
    ],
    title: 'My info',
    divider: false,
    restricted: 'EMPLOYEE',
  },
];

const dimensionNavList = () => [
  {
    items: [
      {
        path: '/dimensions/department',
        name: t('label.department'),
        icon: <DepartmentListIcon />,
      },
      {
        path: '/dimensions/division',
        name: t('label.division'),
        icon: <DivisionIcon />,
      },
      {
        path: '/dimensions/location',
        name: t('label.location'),
        icon: <LocationIcon />,
      },
      {
        path: '/dimensions/project',
        name: t('label.project'),
        icon: <ProjectIcon />,
      },
    ],
    divider: false,
  },
];

const timeNavList = () => [
  {
    items: [
      {
        path: '/time/timesheet',
        name: t('label.time'),
        icon: <TimesIcon />,
      },
      {
        path: '/time/reports',
        name: t('label.reports'),
        icon: <ReportIcon />,
      },
      {
        path: '/time/reports-summary',
        name: t('label.weeklyReport'),
        icon: <ReportIcon />,
      },
      {
        path: '/time/approval',
        name: t('label.approve'),
        icon: <ApprovalIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
      {
        path: '/time/incomplete-weeks',
        name: t('label.incompleteWeeks'),
        icon: <WarningIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
    ],
    divider: false,
  },
];

const contactsNavList = () => [
  {
    items: [
      {
        path: '/contacts/list',
        name: t('label.contacts'),
        icon: <ContactListIcon />,
      },
      {
        path: '/contacts/companies',
        name: t('label.companies'),
        icon: <CompanyIcon />,
      },
      {
        path: '/contacts/tags',
        name: t('label.tags'),
        icon: <TagIcon />,
      },
    ],
    divider: true,
  },
];

const settingsNavList = () => [
  {
    items: [
      {
        path: '/settings/directory',
        name: t('label.directory'),
        icon: <DirectoryIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
      {
        path: '/settings/archived-users',
        name: t('label.archivedUsers'),
        icon: <ArchiveIcon />,
        requiredRole: USERROLECHOICES.ADMIN,
      },
    ],
    divider: true,
  },
  {
    items: [
      {
        path: '/settings/account',
        name: t('label.account'),
        icon: <AccountSettingsIcon />,
        requiredRole: USERROLECHOICES.OWNER,
      },
      {
        path: '/settings/change-password',
        name: t('label.password'),
        icon: <PasswordIcon />,
      },
      {
        path: '/settings/change-language',
        name: t('settings.language'),
        icon: <WebSiteIcon />,
      },
    ],
    title: t('label.profile'),
    divider: false,
  },
];

const reportNavList = () => {
  return [
    {
      items: [
        {
          path: '/reports/gender-profile',
          name: t('label.genderProfile'),
          icon: <DirectoryIcon />,
        },
        {
          path: '/reports/age-profile',
          name: t('label.ageProfile'),
          icon: <ArchiveIcon />,
        },
        {
          path: '/reports/salary-history',
          name: t('label.salaryHistory'),
          icon: <AccountSettingsIcon />,
        },
        {
          path: '/reports/birthdays',
          name: t('label.birthdays'),
          icon: <ApprovalIcon />,
        },
        {
          path: '/reports/headcount',
          name: t('label.headcount'),
          icon: <WebSiteIcon />,
        },
      ],
      divider: false,
    },
  ];
};

export const sideBarNavItems = () => {
  return {
    people: peopleNavList(),
    dimensions: dimensionNavList(),
    time: timeNavList(),
    reimbursements: reimbursementsNavList(),
    contacts: contactsNavList(),
    reports: reportNavList(),
    settings: settingsNavList(),
  };
};
