import React from 'react';
type iconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ApprovalIcon = (props: iconProps) => {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        strokeWidth="1.2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m6.075 9.121 1.998 1.998 4.224-4.224" />
        <path d="M9 1.5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15h0Z" />
      </g>
    </svg>
  );
};
