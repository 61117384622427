import {
  appRoute,
  capitalize,
  currency,
  getBasicUserList,
  getDateFormat,
  getErrorMessages,
  getProjectBasicList,
  getProjects,
  ProjectItem,
  ReimbursementPayload,
  REIMBURSEMENT_TABS,
  REIMBURSEMENT_TYPES,
  User,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  DatePickerPopover,
  Dialog,
  DragAndDropField,
  FormControl,
  Input,
  InputLabel,
  Radio,
  Select,
  Textarea,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styles from './reimbursement-form.module.scss';

export interface ReimbursementFormProps {
  open: boolean;
  title?: string;
  isEditing?: boolean;
  onClose: () => void;
  onSubmit: (initialValues: FormData) => void;
  initialValues: ReimbursementPayload;
  attachment?: string;
  action?: string;
}

export function ReimbursementForm({
  open,
  onClose,
  onSubmit,
  title = t('reimbursement.addReimbursement'),
  action = t('label.add'),
  isEditing = false,
  initialValues,
  attachment,
}: ReimbursementFormProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [fileName, setFileName] = useState(attachment);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [users, setUsers] = useState<User[]>([]);
  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const [activeTab, setActiveTab] = useState(REIMBURSEMENT_TABS.EXPENSE);
  const { showSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    setIsModalVisible(open);
  }, [open]);

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    const payload = formData;
    const formValues: any = values;
    Object.keys(formValues).map((key) => {
      if (key === 'amount' || key === 'rate')
        payload.append(key, Number(formValues[key]).toFixed(2));
      else if (key === 'date')
        payload.append(key, getDateFormat(formValues[key], 'YYYY-MM-DD'));
      else payload.append(key, formValues[key] ? formValues[key] : '');
      return null;
    });
    onSubmit(payload);
    setIsModalVisible(false);
  };

  const handleSelect = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'miles')
      setValues({
        ...values,
        [name]: value,
        amount: values.rate * value,
      });
    else if (name === 'rate')
      setValues({
        ...values,
        [name]: value,
        amount: Number(
          (values.miles * Number(Number(value).toFixed(2))).toFixed(2)
        ),
      });
    else if (name === 'amount')
      setValues({
        ...values,
        [name]: value,
      });
    else setValues({ ...values, [name]: value });
  };

  const handleOnBlur = (e: any) => {
    const { name, value } = e.target;
    if (name === 'amount' || name === 'rate') {
      setValues({
        ...values,
        [name]: Number(value).toFixed(2),
      });
    }
  };

  const handleDateChange = (date: any) => {
    const _date = date as string;
    setValues({ ...values, date: _date });
  };

  const onDropFile = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    setFormData(formData);
    setFileName(file.name);
    setValues({ ...values, file: file });
  };

  const userOptions = users.map((user) => {
    return {
      label: user.name,
      value: `${user.id}`,
      data: {
        url: user.pic,
        email: user.email,
      },
      disabled: false,
    };
  });

  const projectOptions = projects.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });

  const onTabChange = (tab: number) => {
    setActiveTab(tab);
  };

  const getExpenseForm = () => {
    return (
      <div className={styles.form}>
        <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
          <InputLabel label={t('label.user')} className={styles.label} />
          <Select
            options={userOptions}
            selectedOption={
              values.user
                ? {
                    label:
                      userOptions.find(
                        (user) => user.value === values.user?.toString()
                      )?.label ?? '',
                    value: `${values.user}`,
                  }
                : undefined
            }
            disabled
            onSelect={(value) => handleSelect('user', value)}
            placeholder={'Select user'}
            renderOption={(option) => (
              <div className={styles.selectOptions}>
                <Avatar
                  url={option.data.url}
                  email={option.data.email}
                  name={option.label}
                  size={AvatarSize.avatar32}
                  className={styles.userIcon}
                  textClassName={styles.userText}
                  displayName
                  circle
                />
              </div>
            )}
          />
        </FormControl>
        <div className={styles.row}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel
              className={styles.label}
              label={t('label.date')}
              required
            />
            <DatePickerPopover
              id={'date'}
              size={'m'}
              paperClassName={styles.paper}
              selected={values.date}
              onChange={handleDateChange}
              onTextChange={handleDateChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true}>
            <InputLabel label={t('label.project')} className={styles.label} />
            <Select
              options={projectOptions}
              onSelect={(value) => handleSelect('project', value)}
              selectedOption={
                values.project
                  ? {
                      label:
                        projectOptions.find(
                          (project) =>
                            project.value === values.project?.toString()
                        )?.label ?? '',
                      value: `${values.project}`,
                    }
                  : undefined
              }
              placeholder={t('reimbursement.addForm.selectProject')}
            />
          </FormControl>
        </div>
        <div className={styles.row}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="name"
              required
              validationType={['required']}
              labelClassName={styles.label}
              label={t('reimbursement.addForm.expenseName')}
              placeholder={t('reimbursement.addForm.expenseName')}
              value={values.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="amount"
              type="number"
              placeholder={t('label.amount')}
              icon={currency.getSymbol()}
              iconClassName={styles.currencyIcon}
              inputClassName={styles.currencyInput}
              labelClassName={styles.label}
              label={t('label.amount')}
              inputProps={{ min: 0 }}
              value={values.amount}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <FormControl gutter="l" fullWidth={true}>
          <InputLabel label={t('label.description')} className={styles.label} />
          <Textarea
            name={'notes'}
            className={styles.textarea}
            placeholder={t('label.description')}
            size="s"
            minRows={3}
            disableResize
            value={values.notes}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl gutter="xs" fullWidth={true}>
          <InputLabel label={t('label.attachments')} className={styles.label} />
          <DragAndDropField
            fileName={fileName}
            onDrop={onDropFile}
            onRemove={() => {
              setValues({ ...values, file: null });
              const formData = new FormData();
              formData.append('file', '');
              setFormData(formData);
              setFileName('');
            }}
          />
        </FormControl>
      </div>
    );
  };

  const getMileageForm = () => {
    return (
      <div className={styles.form}>
        <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
          <InputLabel label={t('label.user')} className={styles.label} />
          <Select
            options={userOptions}
            selectedOption={
              values.user
                ? {
                    label:
                      userOptions.find(
                        (user) => user.value === values.user?.toString()
                      )?.label ?? '',
                    value: `${values.user}`,
                  }
                : undefined
            }
            disabled
            onSelect={(value) => handleSelect('user', value)}
            placeholder={t('reimbursement.addForm.selectUser')}
            renderOption={(option) => (
              <div className={styles.selectOptions}>
                <Avatar
                  url={option.data.url}
                  email={option.data.email}
                  name={option.label}
                  size={AvatarSize.avatar32}
                  className={styles.userIcon}
                  textClassName={styles.userText}
                  displayName
                  circle
                />
              </div>
            )}
          />
        </FormControl>
        <div className={styles.row}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel
              className={styles.label}
              label={t('label.date')}
              required
            />
            <DatePickerPopover
              id={'date'}
              size={'m'}
              paperClassName={styles.paper}
              selected={values.date}
              onChange={handleDateChange}
              onTextChange={handleDateChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true}>
            <InputLabel label={t('label.project')} className={styles.label} />
            <Select
              options={projectOptions}
              onSelect={(value) => handleSelect('project', value)}
              selectedOption={
                values.project
                  ? {
                      label:
                        projectOptions.find(
                          (project) =>
                            project.value === values.project?.toString()
                        )?.label ?? '',
                      value: `${values.project}`,
                    }
                  : undefined
              }
              placeholder={t('reimbursement.addForm.selectProject')}
            />
          </FormControl>
        </div>
        <div className={styles.row}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="name"
              required
              labelClassName={styles.label}
              label={t('reimbursement.addForm.tripName')}
              validationType={['required']}
              placeholder={t('reimbursement.addForm.tripName')}
              value={values.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="miles"
              type="number"
              inputProps={{ min: 0 }}
              labelClassName={styles.label}
              label={t('reimbursement.addForm.miles')}
              value={values.miles}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className={styles.row}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="rate"
              type="number"
              onBlur={handleOnBlur}
              inputWrapClass={styles.inputWrap}
              labelClassName={styles.label}
              label={t('reimbursement.addForm.milageRate')}
              append={
                <div className={styles.unit}>
                  <span>/Mile</span>
                </div>
              }
              inputProps={{ min: 0 }}
              value={values.rate}
              icon={currency.getSymbol()}
              inputClassName={styles.currencyInput}
              iconClassName={styles.inputIcon}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="amount"
              type="number"
              onBlur={handleOnBlur}
              labelClassName={styles.label}
              label={t('label.amount')}
              inputProps={{ min: 0 }}
              icon={currency.getSymbol()}
              inputClassName={styles.currencyInput}
              iconClassName={styles.inputIcon}
              value={values.amount}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <FormControl gutter="l" fullWidth={true}>
          <InputLabel label={t('label.description')} className={styles.label} />
          <Textarea
            name={'notes'}
            className={styles.textarea}
            placeholder={t('label.description')}
            size="s"
            minRows={3}
            disableResize
            value={values.notes}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl gutter="s" fullWidth={true}>
          <InputLabel label={t('label.attachments')} className={styles.label} />
          <DragAndDropField
            fileName={fileName}
            onDrop={onDropFile}
            onRemove={() => {
              setValues({ ...values, file: null });
              const formData = new FormData();
              formData.append('file', '');
              setFormData(formData);
              setFileName('');
            }}
          />
        </FormControl>
      </div>
    );
  };

  const getPanes = () => {
    const { EXPENSE, MILEAGE } = REIMBURSEMENT_TABS;
    return [
      {
        title: 'Expense',
        content: getExpenseForm(),
        key: EXPENSE,
      },
      {
        title: 'Mileage',
        content: getMileageForm(),
        key: MILEAGE,
      },
    ];
  };

  useEffect(() => {
    getInitialLists();
  }, []);

  const getInitialLists = async () => {
    try {
      const users = await getBasicUserList();
      setUsers(users.data);
      const projects = await getProjectBasicList(currentUser.id);
      setProjects(projects.data);
      if (!(initialValues && initialValues.user)) {
        setValues({ ...values, user: currentUser.id });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  return (
    <div className={styles.root}>
      {!isModalVisible && (
        <Redirect to={appRoute.getRoute(`/reimbursements/list`)} />
      )}
      <Dialog
        title={title}
        action={action}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={styles.tabs}>
            {Object.values(REIMBURSEMENT_TYPES).map((type, index) => (
              <Radio
                key={type}
                id={type}
                disabled={isEditing}
                value={type}
                name={'reimbursement_type'}
                onChange={(e) => {
                  handleChange(e);
                }}
                className={styles.customLabel}
                checked={values.reimbursement_type === type}
                label={
                  type === 'EXPENSE' ? t('label.expense') : t('label.mileage')
                }
              />
            ))}
          </div>
          {values.reimbursement_type === 'EXPENSE'
            ? getExpenseForm()
            : getMileageForm()}
        </div>
      </Dialog>
    </div>
  );
}

export default ReimbursementForm;
