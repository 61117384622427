import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const SettingsIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="26"
      height="26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="24"
        height="24"
        rx="6"
        fill="url(#a)"
        stroke="url(#b)"
        strokeWidth=".5"
      />
      <path
        d="M17.933 15a1.1 1.1 0 0 0 .22 1.214l.04.04a1.332 1.332 0 0 1-.433 2.176 1.333 1.333 0 0 1-1.454-.29l-.04-.04a1.1 1.1 0 0 0-1.213-.22 1.1 1.1 0 0 0-.667 1.007V19a1.333 1.333 0 0 1-2.667 0v-.06a1.1 1.1 0 0 0-.72-1.006 1.099 1.099 0 0 0-1.213.22l-.04.04a1.335 1.335 0 1 1-1.887-1.887l.04-.04a1.1 1.1 0 0 0 .22-1.213 1.1 1.1 0 0 0-1.006-.667h-.114a1.334 1.334 0 0 1 0-2.667h.06A1.1 1.1 0 0 0 8.066 11a1.1 1.1 0 0 0-.22-1.213l-.04-.04A1.333 1.333 0 1 1 9.693 7.86l.04.04a1.1 1.1 0 0 0 1.213.22h.053a1.1 1.1 0 0 0 .667-1.006V7a1.333 1.333 0 0 1 2.667 0v.06a1.1 1.1 0 0 0 .666 1.007 1.1 1.1 0 0 0 1.214-.22l.04-.04a1.333 1.333 0 1 1 1.886 1.887l-.04.04a1.101 1.101 0 0 0-.22 1.213V11a1.1 1.1 0 0 0 1.007.667h.113a1.334 1.334 0 0 1 0 2.667h-.06a1.1 1.1 0 0 0-1.006.666Z"
        fill="url(#c)"
      />
      <path
        d="M13 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill="#717EEB"
        stroke="#717EEB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="a"
          x1="1"
          y1="1"
          x2="1"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7582ED" />
          <stop offset="1" stop-color="#5564DB" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="1.467"
          y1="1.074"
          x2="1.467"
          y2="24.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#838FEB" />
          <stop offset="1" stop-color="#6774D9" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="5.666"
          y1="5.667"
          x2="5.666"
          y2="20.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff" />
          <stop offset="1" stop-color="#B9C2FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
