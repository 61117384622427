import {
  EmergencyContact,
  EmergencyContactPayload,
  validateForm,
} from '@spovio/shared';
import { Dialog, FormControl, Input, InputLabel, Select } from '@spovio/ui';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './people-add-edit-emergency-contact.module.scss';

/* eslint-disable-next-line */
export interface PeopleAddEditEmergencyContactProps {
  open: boolean;
  contact: EmergencyContact;
  onClose: () => void;
  onSubmit: (value: EmergencyContactPayload) => void;
}

export function PeopleAddEditEmergencyContact({
  open,
  contact,
  onClose,
  onSubmit,
}: PeopleAddEditEmergencyContactProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState({} as EmergencyContactPayload);

  useEffect(() => {
    setIsModalVisible(open);
    if (contact && contact.id) {
      setValues({ ...contact, employee: contact.employee.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSelect = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit(values);
  };

  const isValid = () => {
    return (
      validateForm.isRequired(values.name) &&
      (!validateForm.isRequired(values.phone) ||
        validateForm.isPhoneNumber(values.phone)) &&
      (!validateForm.isRequired(values.email) ||
        validateForm.isEmail(values.email))
    );
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={
          contact && contact.id
            ? t('contact.editContact')
            : t('contact.addContact')
        }
        action={contact && contact.id ? t('label.save') : t('label.add')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        isActionDisabled={!isValid()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="name"
              validationType={['required']}
              required
              labelClassName={styles.label}
              label={t('label.name')}
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="relation"
              labelClassName={styles.label}
              label={t('label.relationship')}
              value={values.relation}
              onChange={(e) =>
                setValues({ ...values, relation: e.target.value })
              }
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="phone"
              validationType={['phone']}
              labelClassName={styles.label}
              label={t('label.phone')}
              value={values.phone}
              onChange={(e) => setValues({ ...values, phone: e.target.value })}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="email"
              validationType={['email']}
              labelClassName={styles.label}
              label={t('label.email')}
              placeholder={'customer@mail.com'}
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default PeopleAddEditEmergencyContact;
