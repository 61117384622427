import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const Unfold = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="7px"
      height="11px"
      viewBox="0 0 7 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="People-V1"
          transform="translate(-105.000000, -31.000000)"
          stroke="currentColor"
          strokeWidth="1.2"
        >
          <g id="Group-60" transform="translate(20.000000, 22.000000)">
            <g id="Group-35" transform="translate(0.000000, 1.000000)">
              <g id="Group-2" transform="translate(32.000000, 3.000000)">
                <g id="Group-22" transform="translate(54.000000, 6.000000)">
                  <polyline id="Path" points="0 6 2.5 8.5 5 6"></polyline>
                  <polyline
                    id="Path-Copy-2"
                    transform="translate(2.500000, 1.250000) scale(1, -1) translate(-2.500000, -1.250000) "
                    points="0 -1.17961196e-14 2.5 2.5 5 -1.18655086e-14"
                  ></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
