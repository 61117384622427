import {
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteProject,
  getErrorMessages,
  getProjects,
  ProjectItem,
  ProjectList,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  useSnackbar,
} from '@spovio/ui';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectAdd } from './project-add/project-add';
import ProjectTableList from './project-table-list/project-table-list';
import styles from './project.module.scss';

/* eslint-disable-next-line */
export interface ProjectProps {}

const { LOADED, LOADING } = COMPONENT_STATE;

export function Project(props: ProjectProps) {
  const { t } = useTranslation();
  const [projects, setProjects] = useState<ProjectList>({} as ProjectList);
  const [componentState, setComponentState] = useState(LOADING);
  const [isProjectDialog, setIsProjectDialog] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [editProject, setEditProject] = useState(false);
  const [projectItem, setProjectItem] = useState<ProjectItem | undefined>();
  const { showConfirmation } = useContext(ConfirmationContext);
  const { isAdmin } = useModuleAccess('dimension_access');
  const { showSnackbar } = useSnackbar();

  const BasicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };

  const [filter, setFilter] = useState<BasicFilter>(BasicFilter);

  const getProjectList = useCallback(
    async (_filter = clone(filter)) => {
      setComponentState(LOADING);
      try {
        const res = await getProjects(_filter);
        setProjects(res.data);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      } finally {
        setComponentState(LOADED);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [mounted]
  );

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      setFilter(filter);
      getProjectList(filter);
    },
    [getProjectList]
  );

  useEffect(() => {
    getProjectList();
    // return () => setMounted(false);
  }, []);

  const getProjectCreateButton = () => {
    if (!isAdmin) {
      return null;
    }
    return (
      <Button size={'s'} onClick={() => setIsProjectDialog(true)}>
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('dimensions.addProject')}
        </>
      </Button>
    );
  };

  const onDeleteProject = async (id: number) => {
    showSnackbar(false);
    try {
      const res = await deleteProject(id);
      if (res.status === 204 && projects) {
        getProjectList();
        showSnackbar(true, {
          msg: userMsgs().project.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (project: ProjectItem) => {
    showConfirmation(true, {
      header: t('dimensions.projectDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteProject(project.id),
    });
  };

  const onListAction = (action: string, project: ProjectItem) => {
    if (action === 'edit') {
      setEditProject(true);
      setProjectItem(project);
      setIsProjectDialog(true);
    } else if (action === 'delete') {
      deleteConfirmation(project);
    }
  };

  const getProjectTable = () => {
    return (
      <ProjectTableList
        projects={projects.results}
        onListAction={(action: string, project: ProjectItem) =>
          onListAction(action, project)
        }
        componentState={componentState}
      />
    );
  };

  const onCloseDialog = () => {
    setIsProjectDialog(false);
    setEditProject(false);
    setProjectItem(undefined);
  };

  const onSubmitDialog = (project: ProjectItem, update?: boolean) => {
    showSnackbar(false);
    if (update && projects) {
      getProjectList(filter);
      showSnackbar(true, {
        msg: userMsgs().project.edit,
        severity: 'success',
      });
    } else {
      getProjectList();
      showSnackbar(true, {
        msg: userMsgs().project.add,
        severity: 'success',
      });
    }
  };

  const getAddProjectDialog = () => {
    if (isProjectDialog) {
      return (
        <ProjectAdd
          open={isProjectDialog}
          onClose={onCloseDialog}
          onAdd={onSubmitDialog}
          isEdit={editProject}
          projectItem={projectItem}
        />
      );
    } else return null;
  };

  const getPaginationFooter = () => {
    if (!projects) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      projects;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.projects')}</h4>}
        rightContent={getProjectCreateButton()}
      />
      {getProjectTable()}
      {getAddProjectDialog()}
      {getPaginationFooter()}
    </>
  );
}

export default Project;
