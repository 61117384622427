import {
  addTimeOff,
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteTimeOff,
  getDateFormat,
  getErrorMessages,
  getTimeOffReport,
  getTimeOffs,
  getUpcomingTimeOffs,
  TimeOff,
  TimeOffList,
  TimeOffPayload,
  TIME_HISTORY_TABLE_HEADERS,
  updateTimeOff,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ClockIcon,
  ConfirmationContext,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  ExportIcon,
  NoDataContent,
  Pagination,
  StatusBadge,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
  TimeOffIcon,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PeopleAddEditTimeOff from '../people-add-edit-time-off/people-add-edit-time-off';
import styles from './people-time-off.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PeopleTimeOffProps {}

export function PeopleTimeOff(props: PeopleTimeOffProps) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [employeeID, setEmployeeID] = useState(currentUser.employee_detail.id);
  const [timeOffs, setTimeOffs] = useState({} as TimeOffList);
  const [upcomingTimeOffs, setUpcomingTimeOffs] = useState([{} as TimeOff]);
  const [selectedTimeOff, setSelectedTimeOff] = useState({} as TimeOff);
  const [isEditing, setIsEditing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [componentState, setComponentState] = useState(LOADING);
  const BasicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };
  const [filter, setFilter] = useState<BasicFilter>(BasicFilter);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();

  const { isAdmin } = useModuleAccess('people_access');

  const getTimeOffDetails = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      const id = Number(history.location.pathname.split('/').pop());
      let res;
      try {
        if (id) {
          setEmployeeID(id);
          res = await getTimeOffs(id, _filter);
        } else {
          res = await getTimeOffs(employeeID, _filter);
        }
        setTimeOffs(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    [employeeID, history.location.pathname]
  );

  const getUpcomingTimeOffDetails = useCallback(async () => {
    const id = Number(history.location.pathname.split('/').pop());
    let response;
    try {
      if (id) {
        setEmployeeID(id);
        response = await getUpcomingTimeOffs(id);
      } else {
        response = await getUpcomingTimeOffs(employeeID);
      }
      setComponentState(LOADING);
      setUpcomingTimeOffs(response.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [employeeID, history.location.pathname]);

  useEffect(() => {
    getTimeOffDetails();
    getUpcomingTimeOffDetails();
  }, [getTimeOffDetails, getUpcomingTimeOffDetails]);

  const onDeleteTimeOff = async (id: number) => {
    try {
      const res = await deleteTimeOff(id, employeeID);
      if (res.status === 200) {
        const newUpcomingTimeOffs = upcomingTimeOffs.filter(
          (timeOff: TimeOff) => timeOff.id !== id
        );
        setUpcomingTimeOffs(newUpcomingTimeOffs);

        getTimeOffDetails();
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().timeOff.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      getTimeOffDetails(filter);
    },
    [getTimeOffDetails]
  );

  const deleteConfirmation = (timeOff: TimeOff) => {
    showConfirmation(true, {
      header: t('people.timeOff.timeOffDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteTimeOff(timeOff.id),
    });
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsEditing(false);
    setSelectedTimeOff({} as TimeOff);
  };

  const handleTimeOffSubmit = async (timeOff: TimeOffPayload) => {
    try {
      if (isEditing) {
        const res = await updateTimeOff(
          selectedTimeOff.id,
          timeOff,
          employeeID
        );
        if (res.status === 200) {
          getTimeOffDetails();
          const newUpcomingTimeOffs = upcomingTimeOffs.map(
            (timeOff: TimeOff) => {
              if (timeOff.id === selectedTimeOff.id) {
                return {
                  ...timeOff,
                  ...res.data,
                };
              }
              return timeOff;
            }
          );
          // setTimeOffs(newTimeOffs);
          setUpcomingTimeOffs(newUpcomingTimeOffs);
          showSnackbar(true, {
            msg: userMsgs().timeOff.edit,
            severity: 'success',
          });
          setIsEditing(false);
          setIsDialogOpen(false);
        }
      } else {
        const res = await addTimeOff(timeOff, employeeID);
        if (res.status === 200) {
          getTimeOffDetails();
          getUpcomingTimeOffDetails();
          showSnackbar(true, {
            msg: userMsgs().timeOff.add,
            severity: 'success',
          });
          setIsDialogOpen(false);
        }
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setIsDialogOpen(false);
      setIsEditing(false);
    }
  };
  const headerContent = TIME_HISTORY_TABLE_HEADERS();

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getUpcomingContentMarkup = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
        to: `/people/${'id'}/edit`,
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, upcomingTimeOffs: TimeOff) => {
      if (dropId === 'edit') {
        setSelectedTimeOff(upcomingTimeOffs);
        setIsEditing(true);
      } else if (dropId === 'delete') {
        deleteConfirmation(upcomingTimeOffs);
      }
    };

    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          hasActionBtn={true}
          rowStyles={[styles.trow, styles.alignCenter]}
        />
      );
    } else if (!upcomingTimeOffs.length) {
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('label.upcomingTimeOff')}
            desc={t('extension.noUpcomingTimeOffsFound')}
            icon={<TimeOffIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    } else
      return upcomingTimeOffs.map((item, index) => {
        return (
          <TableListRow className={styles.trow} key={index}>
            <TableListCell
              className={clsx(styles.dateCol, styles.cell)}
              key="task"
            >
              <Text variant="body1">
                {getDateFormat(item.from_date, 'MM/DD/YYYY')}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.descriptionCol, styles.cell)}
              key="category"
            >
              <Text variant="body1" fontWeight="medium" className={styles.task}>
                {item.type?.name}
              </Text>
              <Text variant="body3" className={styles.description}>
                {item.note}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.usedCol, styles.cell)}
              key="date"
            >
              <Text variant="body1">{item.used?.toFixed(2)}</Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.statusCol, styles.cell)}
              key="avatar"
            >
              <StatusBadge
                label={
                  item.approved ? t('label.approved') : t('label.unapproved')
                }
                status={item.approved ? 'approved' : 'unapproved'}
              />
            </TableListCell>
            <TableListCell className={clsx(styles.cell, styles.actionCol)}>
              {item.approved ? (
                <div></div>
              ) : (
                <Dropdown
                  buttonClassName={styles.actionContent}
                  data={dropdownItems}
                  onItemClick={(drpId) => onListAction(drpId, item)}
                />
              )}
            </TableListCell>
          </TableListRow>
        );
      });
  };

  const getContentMarkup = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
        to: `/people/${'id'}/edit`,
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, timeOff: TimeOff) => {
      if (dropId === 'edit') {
        setSelectedTimeOff(timeOff);
        setIsEditing(true);
      } else if (dropId === 'delete') {
        deleteConfirmation(timeOff);
      }
    };

    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          hasActionBtn={true}
          rowStyles={[styles.trow, styles.alignCenter]}
        />
      );
    } else if (!timeOffs?.results?.length) {
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title="Time Off"
            desc={'No time offs found.'}
            icon={<TimeOffIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    } else
      return timeOffs.results.map((item, index) => {
        return (
          <TableListRow className={styles.trow} key={index}>
            <TableListCell
              className={clsx(styles.dateCol, styles.cell)}
              key="task"
            >
              <Text variant="body1">
                {getDateFormat(item.from_date, 'MM/DD/YYYY')}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.descriptionCol, styles.cell)}
              key="category"
            >
              <Text variant="body1" fontWeight="medium" className={styles.task}>
                {item.type?.name}
              </Text>
              <Text variant="body3" className={styles.description}>
                {item.note}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.usedCol, styles.cell)}
              key="date"
            >
              <Text variant="body1">{item.used?.toFixed(2)}</Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.statusCol, styles.cell)}
              key="avatar"
            >
              <StatusBadge
                label={
                  item.approved ? t('label.approved') : t('label.unapproved')
                }
                status={item.approved ? 'approved' : 'unapproved'}
              />
            </TableListCell>
            <TableListCell className={clsx(styles.cell, styles.actionCol)}>
              {item.approved ? (
                <div></div>
              ) : (
                <Dropdown
                  buttonClassName={styles.actionContent}
                  data={dropdownItems}
                  onItemClick={(drpId) => onListAction(drpId, item)}
                />
              )}
            </TableListCell>
          </TableListRow>
        );
      });
  };

  const handleExport = async () => {
    try {
      const res = await getTimeOffReport(employeeID);
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getHistorySection = () => {
    return (
      <div>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.history')}
          </Text>
        </div>
        <div className={styles.tableWrap}>
          <TableList className={clsx(styles.table, styles.historyTable)}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
        {getPaginationFooter()}
      </div>
    );
  };

  const getUpcomingTimeOff = () => {
    return (
      <div>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.upcomingTimeOff')}
          </Text>
          <div className={styles.sectionHeaderRight}>
            <Button
              className={styles.addItemBtn}
              variant="text"
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              <ClockIcon className={styles.plusIcon} />{' '}
              {t('label.requestTimeOff')}
            </Button>{' '}
            {isAdmin && (
              <Button
                size={'s'}
                onClick={handleExport}
                className={styles.exportBtn}
              >
                <ExportIcon className={styles.plusIcon} />
                {t('label.export')}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.tableWrap}>
          <TableList className={clsx(styles.table, styles.historyTable)}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getUpcomingContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
      </div>
    );
  };

  const getPaginationFooter = () => {
    // if (isLoading) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      timeOffs;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => {
          onFilter({ ...filter, page: page });
        }}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  const getRequestTimeOffDialog = () => {
    if (isDialogOpen || isEditing) {
      return (
        <PeopleAddEditTimeOff
          open={isDialogOpen || isEditing}
          timeOff={isEditing ? selectedTimeOff : ({} as TimeOff)}
          onClose={handleDialogClose}
          onSubmit={handleTimeOffSubmit}
        />
      );
    } else return null;
  };

  return (
    <>
      <div className={styles.timeOff}>
        {getUpcomingTimeOff()}
        {getHistorySection()}
      </div>
      {getRequestTimeOffDialog()}
    </>
  );
}

export default PeopleTimeOff;
