import React, { InputHTMLAttributes, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './radio.module.scss';
interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string | ReactNode;
  className?: string;
  value?: string;
}

export const Radio = ({
  id,
  label,
  value,
  className,
  ...restProps
}: RadioProps) => {
  return (
    <label htmlFor={id} className={clsx(styles.radioContainer, className)}>
      <input
        type={'radio'}
        id={id}
        className={styles.radioInput}
        value={value}
        {...restProps}
      />
      <span className={styles.circle} />
      <span className={styles.label}>{label}</span>
    </label>
  );
};
