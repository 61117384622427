// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".headcount_filter__3Urc_ {\n  display: flex;\n  justify-content: flex-end;\n  padding: 10px 30px;\n}\n.headcount_filter__3Urc_ .headcount_dateFilter__117-y {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: #252526;\n  font-size: 13px;\n  letter-spacing: -0.35px;\n  line-height: 16px;\n}\n.headcount_filter__3Urc_ .headcount_dateFilter__117-y button {\n  margin-top: 2px;\n}\n\n.headcount_title__39ev- {\n  display: flex;\n  align-items: center;\n}\n.headcount_title__39ev- span {\n  color: #808080;\n  font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"filter": "headcount_filter__3Urc_",
	"dateFilter": "headcount_dateFilter__117-y",
	"title": "headcount_title__39ev-"
};
module.exports = exports;
