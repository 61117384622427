// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".forgot-pwd_root__2NBz4 {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  padding-top: 180px;\n}\n.forgot-pwd_root__2NBz4 .forgot-pwd_title__162I7 {\n  position: absolute;\n  top: 25px;\n  margin: 0;\n  width: 125px;\n  height: 38px;\n  left: 20px;\n}\n\n.forgot-pwd_innerContent__yNPQE {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 420px;\n  width: calc(100% - 16px);\n}\n\n.forgot-pwd_logo__2Lr24 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #000;\n  font-size: 26px;\n  font-weight: bold;\n  margin-bottom: 67px;\n}\n\n.forgot-pwd_heading__29lbq {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n\n.forgot-pwd_helperText__2_4aB {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin: auto;\n  margin-bottom: 56px;\n  width: 300px;\n}\n\n.forgot-pwd_option__2T1y7 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #6d6971;\n  font-size: 13px;\n  margin-top: 40px;\n}\n.forgot-pwd_option__2T1y7 .forgot-pwd_linkText__3dCU4 {\n  display: flex;\n  color: #747578;\n  font-size: 16px;\n  font-weight: 600;\n  letter-spacing: -0.25px;\n  line-height: 20px;\n  text-align: center;\n}\n.forgot-pwd_option__2T1y7 .forgot-pwd_linkText__3dCU4 > svg {\n  width: 20px;\n  height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"root": "forgot-pwd_root__2NBz4",
	"title": "forgot-pwd_title__162I7",
	"innerContent": "forgot-pwd_innerContent__yNPQE",
	"logo": "forgot-pwd_logo__2Lr24",
	"heading": "forgot-pwd_heading__29lbq",
	"helperText": "forgot-pwd_helperText__2_4aB",
	"option": "forgot-pwd_option__2T1y7",
	"linkText": "forgot-pwd_linkText__3dCU4"
};
module.exports = exports;
