import {
  addDepartment,
  DepartmentItem,
  getErrorMessages,
  updateDepartment,
} from '@spovio/shared';
import { Dialog, FormControl, Input, useSnackbar } from '@spovio/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './department-add.module.scss';
import styles from './department-add.module.scss';

/* eslint-disable-next-line */
export interface DepartmentAddProps {
  open: boolean;
  isEdit?: boolean;
  departmentItem?: DepartmentItem;
  onClose: () => void;
  onAdd: () => void;
}

export function DepartmentAdd({
  open = false,
  onClose,
  onAdd,
  isEdit = false,
  departmentItem,
}: DepartmentAddProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const { showSnackbar } = useSnackbar();
  const [name, setName] = useState(
    isEdit && departmentItem ? departmentItem.name : ''
  );

  const patchDepartment = async (name: string, id: number) => {
    try {
      await updateDepartment(name, id);
      onAdd();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const postDepartment = async () => {
    try {
      await addDepartment(name);
      onAdd();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const handleSubmit = () => {
    onClose();
    setIsModalVisible(false);
    if (isEdit && departmentItem) {
      patchDepartment(name, departmentItem.id);
    } else {
      postDepartment();
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    setName(e.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={
          isEdit
            ? t('dimensions.editDepartment')
            : t('dimensions.addDepartment')
        }
        action={isEdit ? t('label.save') : t('label.add')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
        isActionDisabled={!name}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              tabIndex={0} //this is to make the input focusable
              name="name"
              validationType={['required']}
              focusAuto
              labelClassName={styles.label}
              label={t('label.name')}
              value={name}
              required={true}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default DepartmentAdd;
