import axios, { AxiosResponse } from 'axios';
import {
  BasicFilter,
  DepartmentItem,
  DepartmentList,
  Pagination,
} from '../types';
import { stringify } from 'query-string';

export const getDepartments = async (
  filters?: BasicFilter
): Promise<AxiosResponse<DepartmentList>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/dimensions/department/?${queryParams}`);
};

export const addDepartment = async (
  name: string
): Promise<AxiosResponse<DepartmentItem>> => {
  const payload = {
    name,
  };
  return await axios.post('/dimensions/department/', payload);
};

export const updateDepartment = async (
  name: string,
  id: number
): Promise<AxiosResponse<DepartmentItem>> => {
  const payload = {
    name,
  };
  return await axios.patch(`/dimensions/department/${id}/`, payload);
};

export const getDepartment = async (
  id: number
): Promise<AxiosResponse<DepartmentItem>> => {
  return await axios.get(`/dimensions/department/${id}/`);
};

export const deleteDepartment = async (
  id: number
): Promise<AxiosResponse<DepartmentItem>> => {
  return await axios.delete(`/dimensions/department/${id}/`);
};
