import { appRoute, TagPayload, validateForm } from '@spovio/shared';
import { Dialog, FormControl, Input } from '@spovio/ui';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styles from './contact-tag-form.module.scss';

/* eslint-disable-next-line */
export interface ContactTagFormProps {
  open: boolean;
  title?: string;
  action?: string;
  isEditing?: boolean;
  initialValues: TagPayload;
  onSubmit: (initialValues: TagPayload) => void;
}

export const ContactTagForm = ({
  open,
  title = t('tags.addTag'),
  action = t('label.add'),
  isEditing,
  initialValues,
  onSubmit,
}: ContactTagFormProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState(initialValues);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    onSubmit(values);
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(open);
  }, [open]);

  return (
    <div className={styles.root}>
      {!isModalVisible && <Redirect to={appRoute.getRoute(`/contacts/tags`)} />}
      <Dialog
        title={title}
        action={action}
        open={isModalVisible}
        isActionDisabled={!validateForm.isRequired(values.name)}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="name"
              required
              validationType={['required']}
              labelClassName={styles.label}
              label={t('label.name')}
              placeholder={t('tags.tagName')}
              value={values.name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
};

export default ContactTagForm;
