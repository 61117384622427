import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ContactListIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.265 6.229a3.139 3.139 0 0 1-4.394 0 3.032 3.032 0 0 1 0-4.332 3.139 3.139 0 0 1 4.394 0 3.032 3.032 0 0 1 0 4.332ZM1.5 13.456v.565c0 .979.856.979 1.282.979h8.434c.426 0 1.284 0 1.284-.979v-.566c0-2.336-2.382-4.452-5.508-4.452-3.127 0-5.492 2.116-5.492 4.453Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
