// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tag-item_root__1ztWG {\n  display: inline-flex;\n  height: 28px;\n  border: 1px solid #eae9eb;\n  border-radius: 5px;\n  padding: 0 5px;\n  align-items: center;\n}\n.tag-item_root__1ztWG:hover {\n  background-color: #f0eff1;\n}\n\n.tag-item_close__3B6Gf {\n  cursor: pointer;\n  margin-left: 5px;\n  display: flex;\n  align-items: center;\n}\n.tag-item_close__3B6Gf svg {\n  height: 12px;\n  width: 8px;\n  transition: 0.25s color;\n  color: #4b494d;\n}\n.tag-item_close__3B6Gf svg:hover {\n  color: #4b494d;\n}\n\n.tag-item_label__vIwbX {\n  font-size: 13px;\n  color: #4b494d;\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  max-width: 180px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.tag-item_prefix__38Xib {\n  margin-right: 7px;\n  display: flex;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"root": "tag-item_root__1ztWG",
	"close": "tag-item_close__3B6Gf",
	"label": "tag-item_label__vIwbX",
	"prefix": "tag-item_prefix__38Xib"
};
module.exports = exports;
