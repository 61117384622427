import {
  addEmployee,
  appMsgs,
  appRoute,
  COMPONENT_STATE,
  EmployeePayload,
  getDateFormat,
  getErrorMessages,
  userMsgs,
} from '@spovio/shared';
import { Spin, useSnackbar } from '@spovio/ui';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import PeopleAddForm from '../people-add-form/people-add-form';
import styles from './people-add.module.scss';

/* eslint-disable-next-line */
export type PeopleAddProps = RouteComponentProps;

const { LOADED, LOADING } = COMPONENT_STATE;

export function PeopleAdd(props: PeopleAddProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();

  const initialValues = {
    date_of_birth: '01/01/1998',
    national_id: '',
    country: '',
    address: '',
    zip_code: '',
    job_information: {
      effective_date: moment().format('MM/DD/YYYY'),
    },
  };

  const handleSubmit = async (values: EmployeePayload) => {
    try {
      if (values.date_of_birth)
        values = {
          ...values,
          date_of_birth: moment(values.date_of_birth).format('YYYY-MM-DD'),
        };
      if (values.job_information.effective_date)
        values = {
          ...values,
          effective_date: moment(values.effective_date).format('YYYY-MM-DD'),
          job_information: {
            ...values.job_information,
            effective_date: moment(values.effective_date).format('YYYY-MM-DD'),
          },
        };
      if (values.termination_date) {
        const termination_date = moment(values.termination_date).format(
          'YYYY-MM-DD'
        );
        values = {
          ...values,
          termination_date,
          job_information: {
            ...values.job_information,
            termination_date,
          },
        };
      }
      const res = await addEmployee(values);
      if (res.status === 201) {
        showSnackbar(true, {
          msg: userMsgs().people.add,
          severity: 'success',
        });
        props.history.push({
          pathname: appRoute.getRoute('/people/directory'),
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  return (
    <div className={styles.root}>
      {componentState === LOADED ? (
        <PeopleAddForm
          title={t('people.addEmployee')}
          actionBtnText={t('people.addEmployee')}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      ) : (
        <Spin height={800} />
      )}
    </div>
  );
}

export default PeopleAdd;
