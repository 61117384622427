import React from 'react';

type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const KeyboardArrowLeftIcon = (props: IconProps) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.37377 8.54044c.39053-.39052.39053-1.02369 0-1.41421-.39052-.39053-1.02369-.39053-1.41421 0l-4.16667 4.16666c-.39052.39053-.39052 1.0237 0 1.41422l4.16667 4.16666c.39052.39053 1.02369.39053 1.41421 0 .39053-.39052.39053-1.02369 0-1.41421L10.91421 12l3.45956-3.45956z"
        fill="#8C94A1"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default KeyboardArrowLeftIcon;