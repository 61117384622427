import React from 'react';
type iconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ReportIcon = (props: iconProps) => {
  return (
    <svg {...props} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M10.5 1.4A2.6 2.6 0 0 1 13.1 4v1.648h2.4a2.6 2.6 0 0 1 2.595 2.436l.005.165V15a2.6 2.6 0 0 1-2.6 2.6l-2.988-.001-.012.001h-8A2.6 2.6 0 0 1 1.9 15v-3.283a2.6 2.6 0 0 1 2.6-2.6l2.4-.001V4a2.6 2.6 0 0 1 2.436-2.595L9.5 1.4Zm-3.6 8.916H4.5a1.4 1.4 0 0 0-1.394 1.266l-.006.135V15a1.4 1.4 0 0 0 1.4 1.4h2.4v-6.084ZM11.9 4a1.4 1.4 0 0 0-1.265-1.394L10.5 2.6h-1A1.4 1.4 0 0 0 8.1 4v12.4h3.8Zm3.6 2.849-2.4-.001v9.551l2.4.001a1.4 1.4 0 0 0 1.4-1.4V8.249a1.4 1.4 0 0 0-1.4-1.4Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
