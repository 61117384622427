import React from 'react';

import clsx from 'clsx';

import styles from './ellipsis.module.scss';

interface EllipsisProps {
  children: React.ReactNode;
  component?: string;
  className?: string;
}

export const Ellipsis = ({
  children,
  className,
  component = 'div',
}: EllipsisProps) => {
  const Component: any = component || 'div';
  return (
    <Component className={clsx(className, styles.ellipsis)}>
      {children}
    </Component>
  );
};
