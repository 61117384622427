import React from 'react';

type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const KeyboardArrowRightIcon = (props: IconProps) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.2929 8.7071c-.39053-.39052-.39053-1.02368 0-1.4142.39052-.39053 1.02368-.39053 1.4142 0l4.16667 4.16666c.39053.39052.39053 1.02369 0 1.41421l-4.16666 4.16667c-.39053.39053-1.0237.39053-1.41422 0-.39052-.39052-.39052-1.02369 0-1.41421l3.45956-3.45956L9.2929 8.7071z"
        fill="#8C94A1"
        fillRule="nonzero"
      />
    </svg>
  );
};
