import styles from './salary-history.module.scss';

import {
  Compensation,
  COMPONENT_STATE,
  getCompensationList,
  PeopleFilter,
} from '@spovio/shared';
import {
  CompensationIcon,
  Header,
  NoDataContent,
  TableMarkup,
  TableMarkupColumns,
} from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const { LOADED, LOADING } = COMPONENT_STATE;

export const SalaryHistory = () => {
  const { t } = useTranslation();
  const [list, setList] = useState<Compensation[]>([]);
  const [componentState, setComponentState] = useState(LOADING);
  const PeopleFilter: PeopleFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
    is_active: true,
  };
  const [filter, setFilter] = useState<PeopleFilter>(PeopleFilter);

  const init = useCallback(async () => {
    setComponentState(LOADING);
    try {
      const res = await getCompensationList();
      setList(res.data);
      setComponentState(LOADED);
    } catch (error) {
      //todo
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const getColumns = (): TableMarkupColumns[] => {
    const columns = [
      {
        title: t('label.people'),
        dataIndex: 'employee',
        key: 'employee',
        className: styles.dateCol,
      },
      {
        title: t('label.effectiveDate'),
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: t('label.payRate'),
        dataIndex: 'payRate',
        key: 'payRate',
      },
      {
        title: t('label.frequency'),
        dataIndex: 'paySchedule',
        key: 'paySchedule',
        render: (value: string) => {
          return <div className={styles.paySchedule}>{value}</div>;
        },
      },
    ];

    return columns;
  };

  const getDataSource = () => {
    return list?.map((item) => {
      return {
        key: item.id,
        employee: item.employee?.name,
        date: moment(item.effective_date).format('DD/MM/YYYY'),
        payRate: item.rate + ' ' + item.currency,
        paySchedule: item.frequency.split('_').join(' ').toLowerCase(),
        data: item,
      };
    });
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.salaryHistory')}</h4>}
      />
      <TableMarkup
        columns={getColumns()}
        dataSource={getDataSource()}
        isLoading={componentState === LOADING}
        tableClassName={styles.table}
        noData={
          <NoDataContent
            title={t('label.salaryHistory')}
            desc={t('extension.noDataFound')}
            icon={<CompensationIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        }
      />
    </>
  );
};
export default SalaryHistory;
