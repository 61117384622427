import React, { useCallback, useMemo, useState } from 'react';
import { useContext } from 'react';

import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../confirmation-modal/confirmation-modal';

type Props = {
  children: React.ReactNode;
};

export type ConfirmationAttributes = Omit<ConfirmationModalProps, 'open'>;

const confirmationDefaultValue = {
  showConfirmation: (
    open: boolean,
    confirmationModalAttrs?: ConfirmationAttributes
  ) => null,
};

export const ConfirmationContext = React.createContext(
  confirmationDefaultValue
);

const ConfirmationModalProvider: React.FC<Props> = ({ children }: Props) => {
  const [confirmationState, setConfirmationState] = useState(
    {} as ConfirmationAttributes
  );
  const [open, setOpen] = useState<boolean>(false);
  const showConfirmation = useCallback(
    (open: boolean, confirmationModalAttrs?: ConfirmationAttributes) => {
      if (confirmationModalAttrs) {
        setConfirmationState({ ...confirmationModalAttrs });
      }
      setOpen(open);
    },
    []
  );

  const value = useMemo(() => ({ showConfirmation }), [showConfirmation]);
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <ConfirmationContext.Provider value={value as any}>
      {children}
      {open ? (
        <ConfirmationModal
          open={open}
          onClose={() => setOpen(false)}
          {...confirmationState}
        />
      ) : null}
    </ConfirmationContext.Provider>
  );
};
export const ConfirmationProvider = React.memo(ConfirmationModalProvider);

export const useConfirmation = () => useContext(ConfirmationContext);
