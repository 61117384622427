import es from 'date-fns/locale/es';
import { DateRangePicker as RcDateRangePicker } from 'react-date-range';

import { useTranslation } from 'react-i18next';
import {
  DateRangeType,
  getDefaultDateRange,
  getLabel,
} from './date-range-picker-utils';
import styles from './date-range-picker.module.scss';

interface RangeCalendarProps {
  onChange: (dateRange: DateRangeType, isClose?: boolean) => void;
  dateRange: DateRangeType;
}
export const DateRangePicker = ({
  onChange,
  dateRange,
}: RangeCalendarProps) => {
  const { i18n } = useTranslation();
  const handleSelect = (ranges: any) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    let label = '';
    if (startDate?.getFullYear() === endDate?.getFullYear()) {
      label = getLabel(startDate, endDate);
    } else {
      label = getDefaultDateRange(startDate, endDate, 'DD/MM/YYYY');
    }
    const dateRange = { label, startDate, endDate };
    onChange(dateRange, false);
  };

  return (
    <div className={styles.root}>
      <div className={styles.calendarCol}>
        <RcDateRangePicker
          onChange={handleSelect}
          // showSelectionPreview
          moveRangeOnFirstSelection={false}
          weekdayDisplayFormat={'eeeeee'}
          monthDisplayFormat={'MMMM yyyy'}
          direction={'horizontal'}
          months={2}
          ranges={[
            {
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              key: 'selection',
              color: 'transparent',
            },
          ]}
          locale={i18n.language === 'es' ? es : undefined}
        />
      </div>
    </div>
  );
};
