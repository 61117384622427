// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".compensation-chart_customTooltip__1-DG3 {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  padding: 15px 20px;\n  border-radius: 4px;\n  box-shadow: 0 0 0 1px rgba(90, 99, 112, 0.16), 0 8px 18px -2px rgba(51, 51, 51, 0.1);\n}\n.compensation-chart_customTooltip__1-DG3 p {\n  padding: 0;\n  margin: 0;\n  font-size: 13px;\n}\n.compensation-chart_customTooltip__1-DG3 p + p {\n  margin-top: 6px;\n}\n.compensation-chart_customTooltip__1-DG3 .compensation-chart_label__PZNyN {\n  color: #333333;\n  font-weight: bold;\n}\n.compensation-chart_customTooltip__1-DG3 .compensation-chart_rate__3wl8h {\n  color: #356aec;\n}\n.compensation-chart_customTooltip__1-DG3 .compensation-chart_number__2o398 {\n  font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"customTooltip": "compensation-chart_customTooltip__1-DG3",
	"label": "compensation-chart_label__PZNyN",
	"rate": "compensation-chart_rate__3wl8h",
	"number": "compensation-chart_number__2o398"
};
module.exports = exports;
