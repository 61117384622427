import {
  currency,
  CURRENCY_CHOICES,
  getErrorMessages,
  SelectDropdownOption,
  updateAccount,
  userMsgs,
  validateForm,
} from '@spovio/shared';
import {
  Button,
  FormControl,
  FormText,
  Header,
  Input,
  InputLabel,
  Select,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './account-settings.module.scss';

/* eslint-disable-next-line */
export interface AccountSettingsProps {}

const timezoneList: SelectDropdownOption[] = [];
const timezoneNames = moment.tz.names();
timezoneNames.forEach((item: string) =>
  timezoneList.push({
    label: `(GMT ${moment.tz(item).format('Z')}) ${item}`,
    value: item,
  })
);

export function AccountSettings(props: AccountSettingsProps) {
  const { t } = useTranslation();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [values, setValues] = useState({
    companyName: '',
    currency: currency.get(),
    timezone: '',
  });
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { showSnackbar } = useSnackbar();

  const currencyOptions = CURRENCY_CHOICES.map((item) => ({
    label: item.label,
    value: item.code,
  }));

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrorMessage('');
  };

  const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSelect = (field: string, value: string) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    showSnackbar(false);
    try {
      const accountRes = await updateAccount({
        currency: values.currency,
        name: values.companyName,
      });
      if (accountRes.status === 200) {
        setValues({
          ...values,
          companyName: accountRes.data.name,
          currency: accountRes.data.currency,
        });
        currency.set(accountRes.data.currency);
        setCurrentUser({
          ...currentUser,
          currentAccount: {
            ...currentUser.currentAccount,
            currency: accountRes.data.currency,
            name: accountRes.data.name,
          },
        });
        showSnackbar(true, {
          msg: userMsgs().account.edit,
          severity: 'success',
        });
      }
      // }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const isValid = () => {
    return validateForm.isRequired(values.companyName);
  };

  const getFormFields = () => (
    <div className={styles.root}>
      <div className={styles.innerContent}>
        <FormControl gutter="l" className={styles.form}>
          <Input
            placeholder="Company Name"
            validationType={['required']}
            type="text"
            name="companyName"
            label={t('settings.companyName')}
            required
            onChange={handleChange}
            onFocus={handleFocus}
            value={values.companyName}
            size="l"
          />
          <FormText type="invalid">{formErrorMessage}</FormText>
        </FormControl>
        <FormControl gutter="l" className={styles.form}>
          <InputLabel
            label={t('settings.currency')}
            className={styles.label}
            required
          />
          <Select
            placeholder="Currency"
            inputClassName={styles.currencySelector}
            size="l"
            options={currencyOptions}
            selectedOption={{
              label:
                currencyOptions.find(
                  (options) => options.value === values.currency
                )?.label ?? '',
              value: `${values.currency}`,
            }}
            onSelect={(value) => handleSelect('currency', value)}
          />
          <FormText type="invalid">{formErrorMessage}</FormText>
        </FormControl>
      </div>
    </div>
  );

  const getSubmitButton = () => (
    <Button
      size={'s'}
      className={styles.actionBtn}
      disabled={!isValid()}
      onClick={handleFormSubmit}
    >
      {t('label.submit')}
    </Button>
  );

  useEffect(() => {
    if (currentUser) {
      setValues({
        companyName: currentUser.currentAccount.name,
        currency: currentUser.currentAccount.currency,
        timezone: currentUser.timezone,
      });
    }
  }, [currentUser]);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('settings.accountSettings')}</h4>}
        rightContent={getSubmitButton()}
      />
      {getFormFields()}
    </>
  );
}

export default AccountSettings;
