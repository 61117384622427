import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const EvStationIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4.167V7.5"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.166 15.834V7.5c0-.92.746-1.667 1.667-1.667h7.5c.92 0 1.666.747 1.666 1.667v8.334c0 .92-.746 1.666-1.666 1.666h-7.5c-.92 0-1.667-.746-1.667-1.666Z"
        stroke="#A2A5A8"
        strokeWidth="1.2"
      />
      <path
        d="M4.166 8.333V4.167c0-.92.746-1.667 1.667-1.667h7.5c.92 0 1.666.746 1.666 1.667v4.166"
        stroke="#A2A5A8"
        strokeWidth="1.2"
      />
      <path
        d="m9.305 9.166-1.389 2.5h3.333l-1.389 2.5"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
