import clsx from 'clsx';
import styles from './table-preloader.module.scss';
import { Dropdown } from '../dropdown/dropdown';
import { TableListRow } from '../table/table-list-row/table-list-row';
import { TableListCell } from '../table/table-list-cell/table-list-cell';
import { Skeleton } from '../skeleton/skeleton';

/* eslint-disable-next-line */
export interface TablePreloaderProps {
  rows: number;
  columns: number;
  rowStyles?: string[];
  colStyles?: string[];
  widths?: number[];
  heights?: number[];
  hasActionBtn?: boolean;
  actionColClassName?: string;
}

export function TablePreloader({
  rows,
  columns,
  rowStyles,
  colStyles,
  widths,
  heights,
  hasActionBtn = false,
  actionColClassName
}: TablePreloaderProps) {
  return (
    <>
      {Array.from(Array(rows).keys()).map((key) => {
        return (
          <TableListRow
            className={clsx(styles.row, rowStyles )}
            key={key}
          >
            {Array.from(Array(columns).keys()).map((colKey) => (
              <TableListCell
                className={clsx(styles.col, colStyles ? colStyles[colKey] : '')}
                key={colKey}
              >
                <Skeleton
                  variant="rect"
                  width={widths ? widths[colKey] : 30}
                  height={heights ? heights[colKey] : 12}
                />
              </TableListCell>
            ))}
            {hasActionBtn && (
              <TableListCell className={clsx(styles.actionCol, actionColClassName)}>
                <Dropdown
                  buttonClassName={styles.actionContent}
                  data={[]}
                />
              </TableListCell>
            )}
          </TableListRow>
        );
      })}
    </>
  );
}

export default TablePreloader;
