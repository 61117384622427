import { COMPONENT_STATE, DivisionItem, useModuleAccess } from '@spovio/shared';
import {
  DeleteIcon,
  DivisionListIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  NoDataContent,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './division-table-list.module.scss';

const { LOADING } = COMPONENT_STATE;
/* eslint-disable-next-line */
export interface DivisionTableListProps {
  divisions: DivisionItem[];
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, division: DivisionItem) => void;
}

export function DivisionTableList({
  divisions,
  componentState,
  onListAction,
}: DivisionTableListProps) {
  const { t } = useTranslation();
  const { isAdmin } = useModuleAccess('dimension_access');

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        <TableListCell className={styles.col}>
          <div className={styles.heading}>{t('label.name')}</div>
        </TableListCell>
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: DivisionItem[]) => {
    return results.map((item, index) => {
      const { id, name } = item;
      const dropdownItems: DropdownListItem[] = [
        {
          label: t('label.edit'),
          icon: <EditIcon />,
          id: 'edit',
        },
        {
          label: t('label.delete'),
          id: 'delete',
          icon: <DeleteIcon />,
        },
      ];
      return (
        <TableListRow className={styles.trow} key={id}>
          <TableListCell className={styles.listItem} key={index}>
            <div className={styles.itemWrap}>
              <div className={styles.iconWrap}>
                <DivisionListIcon className={styles.departmentIcon} />
              </div>
              {name}
            </div>
          </TableListCell>

          <TableListCell className={clsx(styles.col, styles.actionCol)}>
            {isAdmin && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = [styles.listItem];
      const widths = [200];

      return (
        <TablePreloader
          rows={5}
          columns={1}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      if (!divisions?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={'Divisions'}
              desc={"You don't have any division"}
              icon={<DivisionListIcon className={styles.noDataIcon} />}
            />
          </div>
        );
      }
      return getBodyRowMarkup(divisions);
    }
  };

  return (
    <div className={styles.root}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default DivisionTableList;
