import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import styles from './contact-company-details.module.scss';
import {
  Button,
  CloseIcon,
  Divider,
  Header,
  Pagination,
  useSnackbar,
  Text,
  useConfirmation,
} from '@spovio/ui';
import {
  appRoute,
  BasicFilter,
  clone,
  Company,
  COMPONENT_STATE,
  Contact,
  ContactFilter,
  ContactListType,
  deleteContact,
  getCompanyDetails,
  getContacts,
  getErrorMessages,
  userMsgs,
} from '@spovio/shared';
import { ContactCompanyBasicInfo } from '../contact-company-basic-info/contact-company-basic-info';
import { debounce } from '@material-ui/core';
import { ContactTableList } from '../../contacts/contact-table-list/contact-table-list';
import { useTranslation } from 'react-i18next';

interface MatchParams {
  id?: string;
}
type RouteProps = RouteComponentProps<MatchParams, StaticContext>;
/* eslint-disable-next-line */
export interface ContactCompanyDetailsProps extends RouteProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactCompanyDetails = (props: ContactCompanyDetailsProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [tableComponentState, setTableComponentState] = useState(LOADING);
  const [company, setCompany] = useState({} as Company);
  const [contacts, setContacts] = useState<ContactListType>(
    {} as ContactListType
  );
  const { showConfirmation } = useConfirmation();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const basicFilter: ContactFilter = {
    page: '1',
    page_size: '25',
    sort_by: 'name',
    search_text: '',
  };
  const [filter, setFilter] = useState<ContactFilter>(basicFilter);

  const requestContacts = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setTableComponentState(LOADING);
      try {
        const res = await getContacts({
          ..._filter,
          company_id: props.match.params.id,
        });
        setContacts(res.data);
        setTableComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchCompanyDetails = useCallback(async (id: string) => {
    setComponentState(LOADING);
    try {
      if (id) {
        const res = await getCompanyDetails(id);
        setCompany(res.data);
        if (res.data) setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, []);

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      requestContacts(filter);
    },
    [requestContacts]
  );

  const deleteTagFromList = async (id: number) => {
    showSnackbar(false);
    try {
      const res = await deleteContact(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().contact.delete,
          severity: 'success',
        });
        requestContacts();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (contact: Contact) => {
    showConfirmation(true, {
      header: t('contact.deleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => deleteTagFromList(contact.id),
    });
  };

  const getLeftHeaderContent = () => {
    return (
      <>
        <Button
          className={styles.closeBtn}
          ghost
          variant="text"
          color="default"
          onClick={() => history.goBack()}
        >
          <CloseIcon />
        </Button>
        <h4>{t('label.CompanyDetails')}</h4>
      </>
    );
  };

  const getContactsTableList = () => (
    <ContactTableList
      className={styles.tableList}
      contacts={contacts.results}
      filter={filter}
      onFilter={onFilter}
      componentState={tableComponentState}
      onListAction={(dropId, contact) => {
        if (dropId === 'view') {
          history.push(appRoute.getRoute(`/contacts/${contact.id}/view`));
        } else if (dropId === 'edit') {
          history.push(appRoute.getRoute(`/contacts/list/${contact.id}/edit`));
        } else if (dropId === 'delete') {
          deleteConfirmation(contact);
        }
      }}
    />
  );

  const getPaginationFooter = () => {
    if (!contacts) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      contacts;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  const getDetails = () => (
    <div className={styles.contactDetails}>
      <div className={styles.sections}>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.contacts')}
          </Text>
        </div>
        {getContactsTableList()}
      </div>
      {getPaginationFooter()}
    </div>
  );

  useEffect(() => {
    if (props.match.params.id) {
      fetchCompanyDetails(props.match.params.id);
      requestContacts();
    }
  }, [props.match.params.id]);

  return (
    <>
      <Header className={styles.header} leftContent={getLeftHeaderContent()} />
      <div className={styles.basicInfoTemplate}>
        <ContactCompanyBasicInfo
          componentState={componentState}
          company={company}
        />
        <Divider type="vertical" className={styles.fullScreenDivider} />
        <Divider className={styles.mobileDivider} />
        {getDetails()}
      </div>
    </>
  );
};

export default ContactCompanyDetails;
