import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ExportIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12a1 1 0 011 1v2.001A1.994 1.994 0 0115.004 17H5.996A1.997 1.997 0 014 15.001V13a1 1 0 012 0v2h9v-2a1 1 0 011-1zm-5.5-9a1 1 0 011 1v5.585l1.293-1.292a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414l-3 3A1.006 1.006 0 0110.5 13l.085-.004a1.006 1.006 0 01-.052.003L10.5 13a1.018 1.018 0 01-.52-.146 1.035 1.035 0 01-.187-.147l.09.08a1.006 1.006 0 01-.09-.08l-3-3a1 1 0 011.414-1.414L9.5 9.585V4a1 1 0 01.883-.993z"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </svg>
  );
};
