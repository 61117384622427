import clsx from 'clsx';
import React, { Fragment, ReactNode } from 'react';
import { Skeleton } from '../skeleton/skeleton';
import { Table, TBody, TD, TFoot, TH, THead, TRow } from '../Table-new/Table';

import styles from './table-markup.module.scss';

interface TableMarkupProps {
  dataSource?: DataSource[];
  columns?: TableMarkupColumns[];
  renderFooter?: () => ReactNode;
  renderRow?: (data: any) => ReactNode;
  renderInfiniteLoading?: () => ReactNode;
  sortConfig?: any;
  onColumnSort?: any;
  isLoading?: boolean;
  tableClassName?: string;
  trowClassName?: string;
  noData?: any;
  pagination?: any;
  onRow?: (
    record: any,
    rowIndex: string
  ) => {
    onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
  };
}

interface DataSource {
  [key: string]: any;
}
export interface TableMarkupColumns {
  title: ReactNode | string;
  dataIndex: string;
  key: string;
  className?: string;
  hide_border_right?: boolean;
  text_align?: 'left' | 'center' | 'right';
  sortAlign?: 'left' | 'right';
  bold?: boolean;
  text_color?: 'red';
  text_muted?: boolean;
  showOnHover?: boolean;
  hideSort?: boolean;
  render?: (title: string, data: any) => ReactNode;
  preLoadingRender?: () => ReactNode;
}

export const TableMarkup = (props: TableMarkupProps) => {
  const getCellData = (record: DataSource, column: TableMarkupColumns) => {
    return Object.keys(record).map((key, cellIndex) => {
      if (column.dataIndex !== key) return null;
      const onClick = props?.onRow?.(record.data, column.dataIndex)?.onClick;
      return (
        <TD
          key={cellIndex}
          className={clsx(
            styles.col,
            column.className,
            styles[`align-${column.text_align}`],
            styles[`color-${column.text_color}`],
            {
              [styles.hide_border_right]: column.hide_border_right,
              [styles.bold]: column.bold,
              [styles.text_muted]: column.text_muted,
              [styles.showOnHover]: column.showOnHover,
            }
          )}
          onClick={onClick}
        >
          <div className={styles.inner}>
            {column.render
              ? column.render?.(record[key], record.data)
              : typeof record[key] === 'string'
              ? record[key]
              : null}
          </div>
        </TD>
      );
    });
  };

  const getBodyMarkup = () => {
    if (props.isLoading) {
      return Array.from(Array(6).keys()).map((record, index) => {
        return (
          <TRow key={index} className={styles.trow}>
            {props.columns?.map((column, index) => (
              <TD
                key={index}
                className={clsx(
                  styles.col,
                  column.className,
                  styles[`align-${column.text_align}`],
                  {
                    [styles.hide_border_right]: column.hide_border_right,
                    [styles.bold]: column.bold,
                    [styles.text_muted]: column.text_muted,
                  }
                )}
              >
                <div className={styles.inner}>
                  {column.preLoadingRender?.() || (
                    <Skeleton width={60} height={12} />
                  )}
                </div>
              </TD>
            ))}
          </TRow>
        );
      });
    } else if (!props.dataSource?.length) {
      return (
        <TRow className={styles.trow}>
          <TD colSpan={props.columns?.length} className={styles.noDataCol}>
            <div>{props.noData}</div>
          </TD>
        </TRow>
      );
    } else
      return props.dataSource?.map((record) => {
        return (
          <Fragment key={record.key}>
            <TRow className={clsx(styles.trow, record.className)}>
              {props.columns?.map((column) => getCellData(record, column))}
            </TRow>
            {props.renderRow?.(record.data)}
          </Fragment>
        );
      });
  };

  return (
    <Table className={clsx(styles.table, props.tableClassName)}>
      <THead className={styles.thead}>
        <TRow className={styles.trow}>
          {props.columns?.map((item) => {
            return (
              <TH
                key={item.key}
                className={clsx(
                  styles.col,
                  item.className,
                  styles[`align-${item.text_align}`],
                  {
                    [styles.hide_border_right]: item.hide_border_right,
                    [styles.actionCol]: item.dataIndex === 'action',
                  }
                )}
              >
                {/* <TableListHeaderCell
                  sortDirection={props.sortConfig?.direction}
                  showSortIcon={props.sortConfig?.key === item.dataIndex}
                  onClick={props.onColumnSort}
                  ColumnId={item.dataIndex}
                  label={item.title}
                  key={item.key}
                  align={item.sortAlign}
                  className={styles.headerCell}
                  hideSort={item.hideSort}
                /> */}
                <div>{item.title}</div>
              </TH>
            );
          })}
        </TRow>
      </THead>
      <TBody className={styles.tbody}>
        {getBodyMarkup()}
        {props.renderInfiniteLoading?.() ? (
          <TRow className={styles.loadSpinnerRow}>
            <TD colSpan={props.columns?.length}>
              {props.renderInfiniteLoading?.()}
            </TD>
          </TRow>
        ) : null}
      </TBody>

      <TFoot className={styles.tfoot}>{props.renderFooter?.()}</TFoot>
    </Table>
  );
};
export default TableMarkup;
