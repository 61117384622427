import React from 'react';
type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const LocationListIcon = (props: IconProps) => {
  return (
    <svg {...props} viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M6 14.75S.75 10.437.75 6.5a5.25 5.25 0 1 1 10.5 0c0 3.938-5.25 8.25-5.25 8.25Z"
          stroke="currentColor"
          strokeWidth="1.5"
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m6 3.5-.18.006A3.005 3.005 0 0 0 3 6.5l.006.18A3.005 3.005 0 0 0 6 9.5l.18-.006A3.005 3.005 0 0 0 9 6.5l-.006-.18A3.005 3.005 0 0 0 6 3.5Z"
          fill="#FBFCFE"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
