// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-time-off-type_header__2R81G {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.people-time-off-type_header__2R81G .people-time-off-type_plusIcon__1QXVC {\n  margin-right: 6px;\n}\n.people-time-off-type_header__2R81G .people-time-off-type_bottomContent__3zAcd {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.people-time-off-type_header__2R81G .people-time-off-type_bottomContent__3zAcd .people-time-off-type_filterButton__3HvMo {\n  padding: 6px 8px;\n}\n.people-time-off-type_header__2R81G .people-time-off-type_bottomContent__3zAcd .people-time-off-type_filterButton__3HvMo .people-time-off-type_filterName__3L2HK {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.people-time-off-type_pagination__3Fp2x {\n  padding: 25px 30px;\n}\n.people-time-off-type_pagination__3Fp2x .people-time-off-type_paper__3TGyH {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}", ""]);
// Exports
exports.locals = {
	"header": "people-time-off-type_header__2R81G",
	"plusIcon": "people-time-off-type_plusIcon__1QXVC",
	"bottomContent": "people-time-off-type_bottomContent__3zAcd",
	"filterButton": "people-time-off-type_filterButton__3HvMo",
	"filterName": "people-time-off-type_filterName__3L2HK",
	"pagination": "people-time-off-type_pagination__3Fp2x",
	"paper": "people-time-off-type_paper__3TGyH"
};
module.exports = exports;
