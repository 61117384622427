import { Compensation } from '@spovio/shared';
import moment from 'moment';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './compensation-chart.module.scss';
export interface InsightBarChartProps {
  graphData: Compensation[];
}

export function CompensationChart(props: InsightBarChartProps) {
  const customizedTick = (props: any) => {
    const { payload, height, width, index, textAnchor, x, y } = props;
    const attrs = {
      height,
      width,
      index,
      textAnchor,
      x,
      y,
      fill: '#ADB3BC',
      fontSize: 11,
      letterSpacing: -0.19,
    };
    const labels = payload.value ? payload.value.split('____') : payload.value;
    return (
      <text {...attrs}>
        <tspan x={x} dy="8">
          {labels[0]}
        </tspan>
        <tspan x={x} dy="15">
          {labels[1]}
        </tspan>
      </text>
    );
  };

  const customizedYTick = (props: any) => {
    const { payload, height, width, index, textAnchor, x, y } = props;
    const attrs = {
      height,
      width,
      index,
      textAnchor,
      x,
      y,
      fill: '#ADB3BC',
      fontSize: 12,
      letterSpacing: -0.19,
    };
    const labels = payload.value;
    const formattedLabel = labels / 1000;
    return (
      <text {...attrs}>
        <tspan y={y}>
          {labels > 1000 || labels < -1000 ? `${formattedLabel}K` : labels}
        </tspan>
      </text>
    );
  };

  const CustomTooltip = (chartItem: any) => {
    let markups;
    if (chartItem.active) {
      if (!chartItem.payload) return null;
      markups = chartItem.payload[0]?.payload;
      if (!markups) return null;
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>{markups.effective_date}</p>
          <p className={styles.rate}>
            comp :
            <span className={styles.number}>
              {' ' + markups.rate.toFixed(2)}
              {' ' + markups.currency}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const getAxisLabel = (label: string) => {
    const XAxisLabel = `${moment(label).format('MMM DD YYYY')}`;
    return XAxisLabel;
  };

  const getFormattedGraphData = (graph: Compensation[]) => {
    const parsedGraph: Compensation[] = JSON.parse(JSON.stringify(graph));
    const formattedGraph = parsedGraph.map((item) => {
      item.effective_date = getAxisLabel(item.effective_date);
      return item;
    });
    return formattedGraph;
  };

  const graphData = getFormattedGraphData(props.graphData);

  return (
    <ResponsiveContainer width="100%" className={styles.root} aspect={4.0 / 1}>
      <ComposedChart
        width={730}
        height={450}
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="effective_date"
          padding={{ left: 10, right: 10 }}
          tickMargin={10}
          height={43}
          tick={customizedTick}
          axisLine={false}
          tickLine={false}
        />
        <YAxis tick={customizedYTick} axisLine={false} tickLine={false} />
        <Tooltip cursor={{ fill: 'transparent' }} content={CustomTooltip} />

        <CartesianGrid stroke="#f5f5f5" />
        <Bar dataKey="rate" barSize={34} fill="#4461CB" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
export default CompensationChart;
