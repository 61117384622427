import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const EditIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 27</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Departments"
          transform="translate(-1256.000000, -291.000000)"
          stroke="#828385"
          strokeWidth="1.2"
        >
          <g id="Group-4" transform="translate(1240.000000, 276.000000)">
            <g id="Group-111-Copy" transform="translate(6.000000, 6.000000)">
              <g id="Group-7" transform="translate(8.000000, 6.000000)">
                <g id="Group-27" transform="translate(0.000000, 1.000000)">
                  <g transform="translate(2.857143, 2.857143)" id="Group">
                    <path
                      d="M0,10.2857143 L2.70005625,9.95227877 C2.88948877,9.92913543 3.06606388,9.8425622 3.20149527,9.70798796 L9.74248868,3.16699455 C10.4667895,2.44269375 10.4667895,1.26838357 9.74248868,0.543225603 L9.74248868,0.543225603 C9.01818788,-0.181075201 7.8438777,-0.181075201 7.11871974,0.543225603 L0.638584732,7.02336061 C0.507439143,7.1545062 0.422580232,7.32422402 0.39600825,7.50851357 L0,10.2857143 Z"
                      id="Path"
                    ></path>
                    <line
                      x1="5.95855471"
                      y1="1.80168218"
                      x2="8.47603684"
                      y2="4.28875976"
                      id="Path-2"
                    ></line>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
