import { Company, COMPONENT_STATE, getDateFormat } from '@spovio/shared';
import { Divider, Skeleton, Text } from '@spovio/ui';
import { useTranslation } from 'react-i18next';
import styles from './contact-company-basic-info.module.scss';

/* eslint-disable-next-line */
export interface ContactCompanyBasicInfoProps {
  company: Company;
  componentState: COMPONENT_STATE;
}
const { LOADING } = COMPONENT_STATE;
export function ContactCompanyBasicInfo({
  company,
  componentState,
}: ContactCompanyBasicInfoProps) {
  const { t } = useTranslation();
  const isLoading = componentState === LOADING;

  const getBasicSection = () => (
    <div className={styles['basic-details']}>
      <div>
        <div className={styles['employee-name']}>
          {isLoading ? (
            <Skeleton variant={'rect'} width={140} height={15} />
          ) : (
            company.name
          )}
        </div>
        <div className={styles['employee-title']}>{company.country}</div>
      </div>
    </div>
  );

  const getDetailsSection = () => {
    return (
      <div className={styles.details}>
        <Text variant="h6" className={styles.heading}>
          {t('label.address')}
        </Text>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.streetAddress')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {company.street1 ? company.street1 : '—'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('company.suiteFloorOther')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {company.street2 ? company.street2 : '—'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.city')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {company.city ? company.city : '—'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.state')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {company.state ? company.state : '—'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.zipCode')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {company.zipcode ? company.zipcode : '—'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.country')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {company.country ? company.country : '—'}
            </Text>
          )}
        </div>
      </div>
    );
  };

  const getNotes = () => (
    <div className={styles.details}>
      <Text variant="h6" className={styles.heading}>
        {t('label.notes')}
      </Text>
      <div className={styles.detail}>
        {isLoading ? (
          <Skeleton width={80} height={15} />
        ) : (
          <Text variant="body2" color="dark_grey" fontWeight="medium">
            {company.notes?.length ? company.notes : '—'}
          </Text>
        )}
      </div>
    </div>
  );

  const getAuditTrail = () => (
    <div className={styles.details}>
      <Text variant="h6" className={styles.heading}>
        {t('label.auditTrail')}
      </Text>
      <div className={styles.detail}>
        <Text variant="body2" color="dove_grey">
          {t('label.createdOn')}
        </Text>
        {isLoading ? (
          <Skeleton width={80} height={15} />
        ) : (
          <Text variant="body3" color="dark_grey" fontWeight="medium">
            {getDateFormat(company.created_at, 'MMM DD YYYY')}
          </Text>
        )}
      </div>
      <div className={styles.detail}>
        <Text variant="body2" color="dove_grey">
          {t('label.updatedOn')}
        </Text>
        {isLoading ? (
          <Skeleton width={80} height={15} />
        ) : (
          <Text variant="body3" color="dark_grey" fontWeight="medium">
            {company.updated_at
              ? getDateFormat(company.updated_at, 'MMM DD YYYY')
              : '—'}
          </Text>
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.info}>
      {getBasicSection()}
      {/* {getContactSection()} */}
      <Divider />
      {getDetailsSection()}
      <Divider />
      {getNotes()}
      <Divider />
      {getAuditTrail()}
    </div>
  );
}

export default ContactCompanyBasicInfo;
