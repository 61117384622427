import {
  COMPONENT_STATE,
  getTimeFromNumber,
  GroupedReportItem,
  HeaderList,
  ReportTimeItem,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Ellipsis,
  NoDataContent,
  ReportIcon,
  Spin,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  Text,
} from '@spovio/ui';
import clsx from 'clsx';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import styles from './time-reports-summary-table.module.scss';

/* eslint-disable-next-line */
export interface TimeReportsSummaryTableProps {
  componentState: COMPONENT_STATE;
  groupBy: string;
  reportList: GroupedReportItem[];
  totalHours: number;
  isSpinning: boolean;
  lastActivityElemRef: any;
  onLoadMore: () => void;
  headers: HeaderList[];
  noDataMessage?: string;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function TimeReportsSummaryTable({
  componentState,
  groupBy,
  reportList,
  totalHours,
  isSpinning,
  onLoadMore,
  lastActivityElemRef,
  headers,
  noDataMessage = t('reports.noData.desc'),
}: TimeReportsSummaryTableProps) {
  const { t } = useTranslation();
  const getTableMarkup = () => {
    return reportList.map((time, index) => {
      const total = time.times.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.hours;
      }, 0);
      return (
        <TableListRow
          className={styles.trow}
          key={index}
          ref={
            reportList[0].times.length === index + 1
              ? lastActivityElemRef
              : undefined
          }
        >
          <TableListCell
            className={clsx(styles.personCol, styles.col)}
            key={index}
          >
            <Avatar
              url={time.user_pic}
              name={time.name}
              size={AvatarSize.avatar26}
              className={styles.userIcon}
              displayName
              textClassName={styles.userText}
              circle
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.hoursCol, styles.col)}
            key={index}
          >
            <Text variant="body1">{getTimeFromNumber(total)}</Text>
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getTableContentMarkup = () => {
    if (!reportList.length || !reportList[0].times.length)
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('label.reports')}
            desc={noDataMessage}
            icon={<ReportIcon className={styles.reportIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    return getTableMarkup();
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      return null;
    } else return getTableContentMarkup();
  };

  const getTotalRowMarkup = () => {
    return (
      <div className={styles.totalWrap}>
        <Ellipsis className={styles.info}>Total:</Ellipsis>
        <Text variant="h4" fontWeight="semi-bold">
          {getTimeFromNumber(totalHours)}
        </Text>
      </div>
    );
  };

  return (
    <div className={styles.tableWrap}>
      <TableList className={styles.table}>
        <TableListHead>
          <TableListRow className={styles.headerRow}>
            {headers.map((item) => {
              return (
                <TableListCell className={styles[item.className]} key={item.id}>
                  <Text variant="tableHeader" className={styles.tableHeader}>
                    {item.label}
                  </Text>
                </TableListCell>
              );
            })}
          </TableListRow>
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
      {isSpinning ? <Spin className={styles.moreLoadingSpin} /> : null}
      {reportList[0]?.times.length ? getTotalRowMarkup() : null}
    </div>
  );
}

export default TimeReportsSummaryTable;
