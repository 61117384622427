import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const FileIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="14px"
      height="18px"
      viewBox="0 0 14 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="People-V1"
          transform="translate(-28.000000, -380.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Group-45" transform="translate(16.000000, 173.000000)">
            <g id="Group-41" transform="translate(0.000000, 200.000000)">
              <g id="Group-23" transform="translate(12.760000, 8.000000)">
                <path
                  d="M11.9235556,3.03466667 L9.40977778,0.520888889 C9.07644444,0.187555556 8.624,0 8.15288889,0 L1.77777778,0 C0.795555556,0 0,0.795555556 0,1.77777778 L0,14.2222222 C0,15.2044444 0.795555556,16 1.77777778,16 L10.6666667,16 C11.6488889,16 12.4444444,15.2044444 12.4444444,14.2222222 L12.4444444,4.29155556 C12.4444444,3.82044444 12.2568889,3.368 11.9235556,3.03466667 L11.9235556,3.03466667 Z"
                  id="Path"
                ></path>
                <path
                  d="M12.4444444,4.44444444 L8.88888889,4.44444444 C8.39822222,4.44444444 8,4.04622222 8,3.55555556 L8,0"
                  id="Path"
                ></path>
                <line
                  x1="2.66666667"
                  y1="10.1066667"
                  x2="7.24528018"
                  y2="10.1066667"
                  id="Path"
                ></line>
                <line
                  x1="2.66666667"
                  y1="12.7733333"
                  x2="9.77777778"
                  y2="12.7733333"
                  id="Path"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
