// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-calendar_calendarWrap__1BYHN {\n  padding: 3.5rem 2.5rem 1.5rem;\n  flex: 1;\n  background-color: #f7f9fc;\n}\n.people-calendar_calendarWrap__1BYHN .people-calendar_calendar__396fX {\n  padding: 2rem 1.25rem;\n  background-color: #fff;\n  border-radius: 8px;\n}\n.people-calendar_calendarWrap__1BYHN h2 {\n  margin: 0px;\n  color: #24252a;\n  font-size: 24px;\n  font-weight: 500;\n  letter-spacing: -0.22px;\n  line-height: 24px;\n}\n.people-calendar_calendarWrap__1BYHN table thead tr th,\n.people-calendar_calendarWrap__1BYHN table td a {\n  padding: 5px 0 !important;\n  color: #3a87ad;\n  font-size: 14px;\n}\n.people-calendar_calendarWrap__1BYHN table td a {\n  padding-right: 10px !important;\n  font-size: 12px;\n}\n.people-calendar_calendarWrap__1BYHN table tbody tr td a {\n  margin: 1px 5px 0 !important;\n}\n.people-calendar_calendarWrap__1BYHN table tbody tr td a div {\n  text-align: center;\n}\n.people-calendar_calendarWrap__1BYHN table tbody tr td a div span {\n  padding: 5px 0 !important;\n  font-size: 12px;\n  color: #fff;\n}\n\n.fc-button {\n  background-color: #4368dc !important;\n  border: none;\n}\n.fc-button:focus {\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;\n}\n.fc-button-active {\n  background-color: #507cff !important;\n}", ""]);
// Exports
exports.locals = {
	"calendarWrap": "people-calendar_calendarWrap__1BYHN",
	"calendar": "people-calendar_calendar__396fX"
};
module.exports = exports;
