// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".confirm-email_root__33kcd {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  padding-top: 180px;\n}\n.confirm-email_root__33kcd .confirm-email_title__2dsUv {\n  position: absolute;\n  top: 25px;\n  margin: 0;\n  width: 125px;\n  height: 38px;\n  left: 20px;\n}\n\n.confirm-email_innerContent__2qt9P {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 420px;\n  width: calc(100% - 16px);\n}\n.confirm-email_innerContent__2qt9P .confirm-email_heading__1nEdp {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n.confirm-email_innerContent__2qt9P .confirm-email_helperText__2p4C1 {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin-bottom: 56px;\n}\n.confirm-email_innerContent__2qt9P .confirm-email_option__1LpuZ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 25px;\n  color: #6d6971;\n  font-size: 14px;\n  letter-spacing: -0.22px;\n  line-height: 17px;\n  text-align: center;\n}\n.confirm-email_innerContent__2qt9P .confirm-email_option__1LpuZ .confirm-email_linkText__luW7G {\n  margin-left: 3px;\n}", ""]);
// Exports
exports.locals = {
	"root": "confirm-email_root__33kcd",
	"title": "confirm-email_title__2dsUv",
	"innerContent": "confirm-email_innerContent__2qt9P",
	"heading": "confirm-email_heading__1nEdp",
	"helperText": "confirm-email_helperText__2p4C1",
	"option": "confirm-email_option__1LpuZ",
	"linkText": "confirm-email_linkText__luW7G"
};
module.exports = exports;
