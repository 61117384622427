import { Avatar, AvatarProps } from '../../index';
import clsx from 'clsx';
import React from 'react';
import styles from './AvatarGroup.module.scss';

export enum AvatarGroupAlign {
  RIGHT,
  LEFT,
}

interface AvatarList extends AvatarProps {
  key: string | number;
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  avatarList: AvatarList[];
  limit: number;
  size: number;
  align?: AvatarGroupAlign;
}

function getAvatarList(avatarList: AvatarList[], size: number, limit: number) {
  return avatarList.slice(0, limit).map(({ key, ...restProps }, index) => {
    if (index < limit) {
      return (
        <span
          style={{
            marginLeft: index !== 0 ? `-${size / 2}px` : 0,
          }}
          key={key}
        >
          <Avatar
            url={restProps.url}
            size={restProps.size}
            className={styles.avatar}
            circle
            name={restProps.name}
          />
        </span>
      );
    } else {
      return null;
    }
  });
}

export const AvatarGroup = (props: Props) => {
  return (
    <div
      className={clsx(
        styles.root,
        props.align === AvatarGroupAlign.RIGHT ? styles.alignRight : ''
      )}
    >
      {getAvatarList(props.avatarList, props.size, props.limit)}
      {props.avatarList.length > props.limit ? (
        <span
          className={styles.remainingItem}
          style={{
            height: props.size,
            width: props.size,
            marginLeft: `-${props.size / 2}px`,
          }}
        >
          {'+'}
          {props.avatarList.length - props.limit}
        </span>
      ) : null}
    </div>
  );
};
