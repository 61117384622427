import { t } from 'i18next';

export const appNavItems = () => [
  {
    path: '/contacts/list',
    name: 'contacts',
    value: t('label.contacts'),
    icon: '/assets/icons/contacts_@2x.png',
    access: 'contact_access',
  },
  {
    path: '/people/directory',
    name: 'people',
    value: t('label.people'),
    icon: '/assets/icons/people_@2x.png',
    access: 'people_access',
  },
  {
    path: '/reimbursements/list',
    name: 'reimbursements',
    value: t('label.reimbursements'),
    icon: '/assets/icons/reimbursements_@2x.png',
    access: 'reimbursement_access',
  },
  {
    path: '/time/timesheet',
    name: 'time',
    value: t('label.time'),
    icon: '/assets/icons/time_@2x.png',
    access: 'time_access',
  },
  {
    path: '/dimensions/department',
    name: 'dimensions',
    value: t('label.dimensions'),
    icon: '/assets/icons/dimensions_@2x.png',
    access: 'dimension_access',
  },
  {
    path: '/reports/gender-profile',
    name: 'reports',
    value: 'Reports',
    icon: '/assets/icons/contacts_@2x.png',
    access: 'reports_access',
  },
  {
    path: '/settings/directory',
    name: 'settings',
    value: t('label.settings'),
    icon: '/assets/icons/settings_@2x.png',
    access: 'settings_access',
    defaultPath: '/settings/change-password',
  },
];
