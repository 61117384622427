// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reimbursement-list_header__1KrwS {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.reimbursement-list_header__1KrwS .reimbursement-list_plusIcon__3Xfjm {\n  margin-right: 6px;\n}\n.reimbursement-list_header__1KrwS .reimbursement-list_bottomContent__1i5qf {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.reimbursement-list_header__1KrwS .reimbursement-list_bottomContent__1i5qf .reimbursement-list_filterButton__25KFs {\n  padding: 6px 8px;\n}\n.reimbursement-list_header__1KrwS .reimbursement-list_bottomContent__1i5qf .reimbursement-list_filterButton__25KFs .reimbursement-list_filterName__F7oLe {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.reimbursement-list_searchBar__2BBkg {\n  border: 1px solid #d4d4d5;\n  border-radius: 6px;\n  background-color: #fff;\n  padding: 0 12px;\n  outline: 0;\n  width: auto;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  width: 300px;\n}\n\n.reimbursement-list_pagination__39Z4o {\n  padding: 25px 30px;\n}\n.reimbursement-list_pagination__39Z4o .reimbursement-list_paper__2Q0Bk {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}\n\n.reimbursement-list_submit__1zuxo {\n  padding: 25px 30px;\n  padding-bottom: 0;\n}\n.reimbursement-list_submit__1zuxo button {\n  font-size: 14px;\n  height: 36px;\n  padding: 0 20px;\n  letter-spacing: -0.11px;\n  line-height: 17px;\n}", ""]);
// Exports
exports.locals = {
	"header": "reimbursement-list_header__1KrwS",
	"plusIcon": "reimbursement-list_plusIcon__3Xfjm",
	"bottomContent": "reimbursement-list_bottomContent__1i5qf",
	"filterButton": "reimbursement-list_filterButton__25KFs",
	"filterName": "reimbursement-list_filterName__F7oLe",
	"searchBar": "reimbursement-list_searchBar__2BBkg",
	"pagination": "reimbursement-list_pagination__39Z4o",
	"paper": "reimbursement-list_paper__2Q0Bk",
	"submit": "reimbursement-list_submit__1zuxo"
};
module.exports = exports;
