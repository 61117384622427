import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ProjectIcon = (props: IconsProps) => {
  return (
    <svg {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h14v14H0z" />
        <path
          d="M5.33 1.4c.564 0 1.287.306 1.692.71l.29.29h3.42c1.296 0 2.344 1.05 2.425 2.365l.006.166v5.138c0 1.388-1.086 2.531-2.43 2.531H3.392c-1.349 0-2.428-1.137-2.428-2.528V3.928c0-1.387 1.085-2.528 2.43-2.528Zm6.633 5.088h-9.8v3.584c0 .695.489 1.254 1.104 1.321l.124.007h7.343c.666 0 1.229-.592 1.229-1.33V6.487ZM5.33 2.6H3.393c-.669 0-1.23.59-1.23 1.328v1.36h9.8v-.357c0-.697-.49-1.257-1.107-1.324l-.125-.007H7.063a.6.6 0 0 1-.425-.176l-.465-.465c-.182-.182-.6-.359-.843-.359Z"
          fill="currentColor"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
