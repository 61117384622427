// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table-preloader_actionCol__1d2Du {\n  flex: 0 0 10%;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"actionCol": "table-preloader_actionCol__1d2Du"
};
module.exports = exports;
