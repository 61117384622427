import { debounce } from '@material-ui/core';
import {
  BasicFilter,
  clone,
  COMPONENT_STATE,
  getBasicUserList,
  getDateFormat,
  getErrorMessages,
  getIncompleteWeeks,
  getThisWeekRange,
  Week,
  WeekDateRange,
  WeekListType,
} from '@spovio/shared';
import {
  Button,
  CalendarIcon,
  DatePickerPopover,
  Header,
  Pagination,
  SearchBar,
  SearchResetIcon,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectUser, {
  MultiSelectUserItem,
} from '../../multi-select-user/multi-select-user';
import TimeIncompleteWeekTableList from '../time-incomplete-week-table-list/time-incomplete-week-table-list';
import styles from './time-incomplete-week.module.scss';

/* eslint-disable-next-line */
export interface TimeIncompleteWeekProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export const TimeIncompleteWeek = (props: TimeIncompleteWeekProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const basicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };
  const [filter, setFilter] = useState<BasicFilter>(basicFilter);
  const [weeks, setWeeks] = useState<WeekListType>({} as WeekListType);
  const [weekFilter, setWeekFilter] = useState<boolean>(false);
  const [peopleFilters, setPeopleFilters] = useState<number[]>([]);
  const [peopleList, setPeopleList] = useState<MultiSelectUserItem[]>(
    [] as MultiSelectUserItem[]
  );
  const [dateRange, setDateRange] = useState<WeekDateRange>(getThisWeekRange());
  const { showSnackbar } = useSnackbar();

  const requestWeeks = useCallback(
    async (
      dateRange?: WeekDateRange,
      peopleFilters?: number[],
      _filter = clone(filter)
    ) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getIncompleteWeeks(dateRange, peopleFilters, _filter);
        setWeeks(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getFilterList = async () => {
    const res = await getBasicUserList();
    setPeopleList(res.data);
  };

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      requestWeeks(weekFilter ? dateRange : undefined, peopleFilters, filter);
    },
    [requestWeeks]
  );
  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const setNewDateRange = (newDate: string) => {
    if (!(dateRange.start_date <= newDate && newDate <= dateRange.end_date)) {
      const newRange = getThisWeekRange(newDate);
      setDateRange(newRange);
      requestWeeks(newRange, peopleFilters, filter);
    }
  };

  const onDatePickerChange = (date: string | Date) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    setWeekFilter(true);
    setNewDateRange(newDate);
  };

  const onDateReset = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setWeekFilter(false);
    requestWeeks(undefined, peopleFilters, filter);
    setDateRange(getThisWeekRange());
  };

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
        <div className={styles.filters}>
          <DatePickerPopover
            id={'week-date-picker-popover'}
            onChange={onDatePickerChange}
            selected={new Date(dateRange.start_date)}
            startDate={new Date(dateRange.start_date)}
            endDate={new Date(dateRange.end_date)}
            buttonContent={
              !weekFilter ? (
                <CalendarIcon
                  className={styles.headerRightInnerCalendarBtnIcon}
                />
              ) : (
                <div className={styles.headerRightInnerCalendarBtn}>
                  <div className="ellipsis">
                    {getDateFormat(dateRange.start_date, 'DD MMM') +
                      ' - ' +
                      getDateFormat(dateRange.end_date, 'DD MMM')}
                  </div>
                  <Button
                    ghost
                    variant="text"
                    color="default"
                    onClick={onDateReset}
                  >
                    <SearchResetIcon className={styles.searchResetIcon} />
                  </Button>
                </div>
              )
            }
          />
          <MultiSelectUser
            onSelect={(value: any, type?: string) => {
              setPeopleFilters(value);
              setFilter((prev) => {
                return {
                  ...prev,
                  page: '1',
                };
              });
              requestWeeks(weekFilter ? dateRange : undefined, value, filter);
            }}
            isLoading={LOADED}
            userList={peopleList}
          />
        </div>
      </div>
    );
  };

  const getWeekssTable = () => (
    <TimeIncompleteWeekTableList
      weeks={weeks.results}
      filter={filter}
      onFilter={onFilter}
      componentState={componentState}
    />
  );

  const getPaginationFooter = () => {
    if (!weeks) return null;
    const { has_next, has_prev, total_count, page_size, page, links } = weeks;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  useEffect(() => {
    getFilterList();
    requestWeeks();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.incompleteWeeks')}</h4>}
        bottomContent={getFilters()}
      />
      {getWeekssTable()}
      {getPaginationFooter()}
    </>
  );
};

export default TimeIncompleteWeek;
