import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { Button, ButtonProps } from '../button/button';
import { DialogContent } from '../dialog-content/dialog-content';
import { Dialog } from '../dialog/dialog';
import styles from './confirmation-modal.module.scss';
import { t } from 'i18next';

export interface ConfirmationModalProps extends Pick<ButtonProps, 'color'> {
  onClose?: () => void;
  onConfirmation?: () => void;
  open?: boolean;
  header: string;
  content?: string | React.ReactNode;
  className?: string;
  cancelButtonLabel?: string;
  buttonContent?: string;
  isPrimaryButtonDisabled?: boolean;
  actions?: React.ReactElement[];
}

export const ConfirmationModal = ({
  onConfirmation,
  onClose,
  open = false,
  header = '',
  content = '',
  className,
  buttonContent = t('label.delete'),
  cancelButtonLabel = t('label.cancel'),
  isPrimaryButtonDisabled,
  color = 'danger',
  actions,
}: ConfirmationModalProps) => {
  const [disableConfirmationBtn, setDisableConfirmationBtn] = useState(false);
  const onClickConfirmation = () => {
    setDisableConfirmationBtn(true);
    onConfirmation && onConfirmation();
  };
  useEffect(() => {
    setTimeout(() => {
      setDisableConfirmationBtn(false);
    }, 200);
  }, [open]);

  const getDefaultActions = () => {
    if (actions) return actions;
    return [
      <Button
        className={styles.cancelButton}
        size={'m'}
        variant="outlined"
        color="secondary"
        onClick={onClose}
      >
        {cancelButtonLabel}
      </Button>,
      <Button
        disabled={isPrimaryButtonDisabled || disableConfirmationBtn}
        size={'m'}
        onClick={onClickConfirmation}
        color={color}
      >
        {buttonContent}
      </Button>,
    ];
  };

  const onDialogClose = () => {
    onClose && onClose();
  };

  return (
    <div className={styles.confirmationDialog}>
      <Dialog
        open={open}
        aria-labelledby="confirmation dialog"
        onClose={onDialogClose}
        maxWidth={'xs'}
        showHeader={false}
      >
        <DialogContent className={styles.dialog}>
          <div className={clsx(styles.root, className)}>
            <div className={styles.wrapper}>
              <div className={styles.header}>{header}</div>
              <div className={styles.subText}> {content} </div>
            </div>
            <div className={styles.footerBlock}>
              {getDefaultActions().map((item, index) => (
                <div key={index} className={styles.actionItem}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
