// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".add-workspace_content__14AHs {\n  padding: 20px 25px 0 25px;\n}", ""]);
// Exports
exports.locals = {
	"content": "add-workspace_content__14AHs"
};
module.exports = exports;
