import { environment } from '../environments';
import { ModuleAccess, Modules, SnackBarConfig } from '../types';

export * from './user';
export * from './people';
export * from './messages';
export * from './reimbursements';
export * from './times';
export * from './contact';

export const appInfo = {
  baseURL: environment.API_URL,
  defaultDateFormat: 'YYYY-MM-DD',
};

export enum COMPONENT_STATE {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export const MODULES: Modules[] = [
  'people',
  'dimensions',
  'time',
  'reimbursements',
  'contacts',
  'settings',
  'reports',
];

export const MODULE_ACCESS: ModuleAccess[] = [
  'people_access',
  'dimension_access',
  'time_access',
  'reimbursement_access',
  'settings_access',
];

export const MAPPED_MODULE_ACCESS = {
  people: 'people_access',
  dimensions: 'dimension_access',
  time: 'time_access',
  reimbursements: 'reimbursement_access',
  contacts: 'contact_access',
  reports: 'reports_access',
  settings: 'settings_access',
};

export const snackBarConfig: SnackBarConfig = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 4000,
};

export const COMPENSATION_FREQUENCY = [
  'HOUR',
  'WEEK',
  'BI_WEEK',
  'SEMI_MONTH',
  'MONTH',
  'YEAR',
];

export const USERROLECHOICES = {
  ADMIN: 'admin',
  MEMBER: 'member',
  NO_ACCESS: 'no_access',
  OWNER: 'owner',
};

export const COUNTRY_CHOICES = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Deps',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Rep',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo {Democratic Rep}',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea North',
  'Korea South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'St Kitts & Nevis',
  'St Lucia',
  'Saint Vincent & the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome & Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const CURRENCY_CHOICES = [
  { label: 'US Dollar', code: 'USD', symbol: '$' },
  { label: 'Canadian Dollar', code: 'CAD', symbol: 'CA$' },
  { label: 'Euro', code: 'EUR', symbol: '€' },
  { label: 'United Arab Emirates Dirham', code: 'AED', symbol: 'AED' },
  { label: 'Afghan Afghani', code: 'AFN', symbol: 'Af' },
  { label: 'Albanian Lek', code: 'ALL', symbol: 'ALL' },
  { label: 'Armenian Dram', code: 'AMD', symbol: 'AMD' },
  { label: 'Argentine Peso', code: 'ARS', symbol: 'AR$' },
  { label: 'Australian Dollar', code: 'AUD', symbol: 'AU$' },
  { label: 'Azerbaijani Manat', code: 'AZN', symbol: 'man.' },
  { label: 'Bosnia-Herzegovina Convertible Mark', code: 'BAM', symbol: 'KM' },
  { label: 'Bangladeshi Taka', code: 'BDT', symbol: 'Tk' },
  { label: 'Bulgarian Lev', code: 'BGN', symbol: 'BGN' },
  { label: 'Bahraini Dinar', code: 'BHD', symbol: 'BD' },
  { label: 'Burundian Franc', code: 'BIF', symbol: 'FBu' },
  { label: 'Brunei Dollar', code: 'BND', symbol: 'BN$' },
  { label: 'Bolivian Boliviano', code: 'BOB', symbol: 'Bs' },
  { label: 'Brazilian Real', code: 'BRL', symbol: 'R$' },
  { label: 'Botswanan Pula', code: 'BWP', symbol: 'BWP' },
  { label: 'Belarusian Ruble', code: 'BYN', symbol: 'Br' },
  { label: 'Belize Dollar', code: 'BZD', symbol: 'BZ$' },
  { label: 'Congolese Franc', code: 'CDF', symbol: 'CDF' },
  { label: 'Swiss Franc', code: 'CHF', symbol: 'CHF' },
  { label: 'Chilean Peso', code: 'CLP', symbol: 'CL$' },
  { label: 'Chinese Yuan', code: 'CNY', symbol: 'CN¥' },
  { label: 'Colombian Peso', code: 'COP', symbol: 'CO$' },
  { label: 'Costa Rican Colón', code: 'CRC', symbol: '₡' },
  { label: 'Cape Verdean Escudo', code: 'CVE', symbol: 'CV$' },
  { label: 'Czech Republic Koruna', code: 'CZK', symbol: 'Kč' },
  { label: 'Djiboutian Franc', code: 'DJF', symbol: 'Fdj' },
  { label: 'Danish Krone', code: 'DKK', symbol: 'Dkr' },
  { label: 'Dominican Peso', code: 'DOP', symbol: 'RD$' },
  { label: 'Algerian Dinar', code: 'DZD', symbol: 'DA' },
  { label: 'Estonian Kroon', code: 'EEK', symbol: 'Ekr' },
  { label: 'Egyptian Pound', code: 'EGP', symbol: 'EGP' },
  { label: 'Eritrean Nakfa', code: 'ERN', symbol: 'Nfk' },
  { label: 'Ethiopian Birr', code: 'ETB', symbol: 'Br' },
  { label: 'British Pound Sterling', code: 'GBP', symbol: '£' },
  { label: 'Georgian Lari', code: 'GEL', symbol: 'GEL' },
  { label: 'Ghanaian Cedi', code: 'GHS', symbol: 'GH₵' },
  { label: 'Guinean Franc', code: 'GNF', symbol: 'FG' },
  { label: 'Guatemalan Quetzal', code: 'GTQ', symbol: 'GTQ' },
  { label: 'Hong Kong Dollar', code: 'HKD', symbol: 'HK$' },
  { label: 'Honduran Lempira', code: 'HNL', symbol: 'HNL' },
  { label: 'Croatian Kuna', code: 'HRK', symbol: 'kn' },
  { label: 'Hungarian Forint', code: 'HUF', symbol: 'Ft' },
  { label: 'Indonesian Rupiah', code: 'IDR', symbol: 'Rp' },
  { label: 'Israeli New Sheqel', code: 'ILS', symbol: '₪' },
  { label: 'Indian Rupee', code: 'INR', symbol: 'Rs' },
  { label: 'Iraqi Dinar', code: 'IQD', symbol: 'IQD' },
  { label: 'Iranian Rial', code: 'IRR', symbol: 'IRR' },
  { label: 'Icelandic Króna', code: 'ISK', symbol: 'Ikr' },
  { label: 'Jamaican Dollar', code: 'JMD', symbol: 'J$' },
  { label: 'Jordanian Dinar', code: 'JOD', symbol: 'JD' },
  { label: 'Japanese Yen', code: 'JPY', symbol: '¥' },
  { label: 'Kenyan Shilling', code: 'KES', symbol: 'Ksh' },
  { label: 'Cambodian Riel', code: 'KHR', symbol: 'KHR' },
  { label: 'Comorian Franc', code: 'KMF', symbol: 'CF' },
  { label: 'South Korean Won', code: 'KRW', symbol: '₩' },
  { label: 'Kuwaiti Dinar', code: 'KWD', symbol: 'KD' },
  { label: 'Kazakhstani Tenge', code: 'KZT', symbol: 'KZT' },
  { label: 'Lebanese Pound', code: 'LBP', symbol: 'L.L.' },
  { label: 'Sri Lankan Rupee', code: 'LKR', symbol: 'SLRs' },
  { label: 'Lithuanian Litas', code: 'LTL', symbol: 'Lt' },
  { label: 'Latvian Lats', code: 'LVL', symbol: 'Ls' },
  { label: 'Libyan Dinar', code: 'LYD', symbol: 'LD' },
  { label: 'Moroccan Dirham', code: 'MAD', symbol: 'MAD' },
  { label: 'Moldovan Leu', code: 'MDL', symbol: 'MDL' },
  { label: 'Malagasy Ariary', code: 'MGA', symbol: 'MGA' },
  { label: 'Macedonian Denar', code: 'MKD', symbol: 'MKD' },
  { label: 'Myanma Kyat', code: 'MMK', symbol: 'MMK' },
  { label: 'Macanese Pataca', code: 'MOP', symbol: 'MOP$' },
  { label: 'Mauritian Rupee', code: 'MUR', symbol: 'MURs' },
  { label: 'Mexican Peso', code: 'MXN', symbol: 'MX$' },
  { label: 'Malaysian Ringgit', code: 'MYR', symbol: 'RM' },
  { label: 'Mozambican Metical', code: 'MZN', symbol: 'MTn' },
  { label: 'Namibian Dollar', code: 'NAD', symbol: 'N$' },
  { label: 'Nigerian Naira', code: 'NGN', symbol: '₦' },
  { label: 'Nicaraguan Córdoba', code: 'NIO', symbol: 'C$' },
  { label: 'Norwegian Krone', code: 'NOK', symbol: 'Nkr' },
  { label: 'Nepalese Rupee', code: 'NPR', symbol: 'NPRs' },
  { label: 'New Zealand Dollar', code: 'NZD', symbol: 'NZ$' },
  { label: 'Omani Rial', code: 'OMR', symbol: 'OMR' },
  { label: 'Panamanian Balboa', code: 'PAB', symbol: 'B/.' },
  { label: 'Peruvian Nuevo Sol', code: 'PEN', symbol: 'S/.' },
  { label: 'Philippine Peso', code: 'PHP', symbol: '₱' },
  { label: 'Pakistani Rupee', code: 'PKR', symbol: 'PKRs' },
  { label: 'Polish Zloty', code: 'PLN', symbol: 'zł' },
  { label: 'Paraguayan Guarani', code: 'PYG', symbol: '₲' },
  { label: 'Qatari Rial', code: 'QAR', symbol: 'QR' },
  { label: 'Romanian Leu', code: 'RON', symbol: 'RON' },
  { label: 'Serbian Dinar', code: 'RSD', symbol: 'din.' },
  { label: 'Russian Ruble', code: 'RUB', symbol: 'RUB' },
  { label: 'Rwandan Franc', code: 'RWF', symbol: 'RWF' },
  { label: 'Saudi Riyal', code: 'SAR', symbol: 'SR' },
  { label: 'Sudanese Pound', code: 'SDG', symbol: 'SDG' },
  { label: 'Swedish Krona', code: 'SEK', symbol: 'Skr' },
  { label: 'Singapore Dollar', code: 'SGD', symbol: 'S$' },
  { label: 'Somali Shilling', code: 'SOS', symbol: 'Ssh' },
  { label: 'Syrian Pound', code: 'SYP', symbol: 'SY£' },
  { label: 'Thai Baht', code: 'THB', symbol: '฿' },
  { label: 'Tunisian Dinar', code: 'TND', symbol: 'DT' },
  { label: 'Tongan Paʻanga', code: 'TOP', symbol: 'T$' },
  { label: 'Turkish Lira', code: 'TRY', symbol: 'TL' },
  { label: 'Trinidad and Tobago Dollar', code: 'TTD', symbol: 'TT$' },
  { label: 'New Taiwan Dollar', code: 'TWD', symbol: 'NT$' },
  { label: 'Tanzanian Shilling', code: 'TZS', symbol: 'TSh' },
  { label: 'Ukrainian Hryvnia', code: 'UAH', symbol: '₴' },
  { label: 'Ugandan Shilling', code: 'UGX', symbol: 'USh' },
  { label: 'Uruguayan Peso', code: 'UYU', symbol: '$U' },
  { label: 'Uzbekistan Som', code: 'UZS', symbol: 'UZS' },
  { label: 'Venezuelan Bolívar', code: 'VEF', symbol: 'Bs.F.' },
  { label: 'Vietnamese Dong', code: 'VND', symbol: '₫' },
  { label: 'CFA Franc BEAC', code: 'XAF', symbol: 'FCFA' },
  { label: 'CFA Franc BCEAO', code: 'XOF', symbol: 'CFA' },
  { label: 'Yemeni Rial', code: 'YER', symbol: 'YR' },
  { label: 'South African Rand', code: 'ZAR', symbol: 'R' },
  { label: 'Zambian Kwacha', code: 'ZMK', symbol: 'ZK' },
  { label: 'Zimbabwean Dollar', code: 'ZWL', symbol: 'ZWL$' },
];
