// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contact-form_content__3wEOl {\n  padding: 20px 25px 0 25px;\n}\n\n.contact-form_tagItem__er0Ks {\n  padding: 0 10px;\n}\n\n.contact-form_autoCompleteInput__3Zvxx {\n  padding: 10px !important;\n}", ""]);
// Exports
exports.locals = {
	"content": "contact-form_content__3wEOl",
	"tagItem": "contact-form_tagItem__er0Ks",
	"autoCompleteInput": "contact-form_autoCompleteInput__3Zvxx"
};
module.exports = exports;
