// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table-list-body_root__IMjgV {\n  color: #4b494d;\n  font-size: 13px;\n  letter-spacing: -0.35px;\n  line-height: 16px;\n}", ""]);
// Exports
exports.locals = {
	"root": "table-list-body_root__IMjgV"
};
module.exports = exports;
