import {
  addCompensation,
  Compensation,
  CompensationPayload,
  COMPENSATION_TABLE_HEADERS,
  COMPONENT_STATE,
  CURRENCY_CHOICES,
  deleteCompensation,
  getCompensations,
  getCompensationsReport,
  getDateFormat,
  getErrorMessages,
  updateCompensation,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  CompensationIcon,
  ConfirmationContext,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  ExportIcon,
  NoDataContent,
  PlusIcon,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CompensationChart from '../../compensation-chart/compensation-chart';
import PeopleAddEditCompensation from '../people-add-edit-compensation/people-add-edit-compensation';
import styles from './people-compensation.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PeopleCompensationProps {}

export function PeopleCompensation(props: PeopleCompensationProps) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [employeeID, setEmployeeID] = useState(currentUser.employee_detail.id);
  const [addCompensationDialog, setAddCompensationDialog] = useState(false);
  const [compensationList, setCompensationList] = useState([
    {} as Compensation,
  ]);
  const [selectedCompensation, setSelectedCompensation] = useState(
    {} as Compensation
  );
  const [componentState, setComponentState] = useState(LOADING);
  const [isEditing, setIsEditing] = useState(false);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const { isAdmin } = useModuleAccess('people_access');

  const headerContent = COMPENSATION_TABLE_HEADERS();

  const getInitialCompensations = useCallback(async () => {
    const id = Number(history.location.pathname.split('/').pop());
    let res;
    try {
      if (id) {
        setEmployeeID(id);
        res = await getCompensations(id);
      } else {
        res = await getCompensations(employeeID);
      }
      setCompensationList(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [employeeID, history.location.pathname]);

  useEffect(() => {
    getInitialCompensations();
  }, [getInitialCompensations]);

  const onDeleteCompensation = async (id: number) => {
    try {
      const res = await deleteCompensation(employeeID, id);
      if (res.status === 200) {
        const newCompensationList = compensationList.filter(
          (compensation) => compensation.id !== id
        );
        setCompensationList(newCompensationList);
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().compensation.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const deleteConfirmation = (compensation: Compensation) => {
    showConfirmation(true, {
      header: t('people.compensationDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteCompensation(compensation.id),
    });
  };

  const handleCompensationSubmit = async (
    compensation: CompensationPayload
  ) => {
    try {
      if (isEditing) {
        const CompensationPayload = {
          rate: compensation.rate,
          effective_date: compensation.effective_date,
          currency: compensation.currency,
          frequency: compensation.frequency,
        };
        const res = await updateCompensation(
          employeeID,
          selectedCompensation.id,
          CompensationPayload
        );
        if (res.status === 200) {
          const updatedCompensationList = compensationList.map((item) => {
            if (item.id === res.data.id) {
              return res.data;
            }
            return item;
          });
          updatedCompensationList.sort((a, b) => {
            return moment(b.effective_date).diff(moment(a.effective_date));
          });
          showSnackbar(true, {
            msg: userMsgs().compensation.edit,
            severity: 'success',
          });
          setCompensationList(updatedCompensationList);
          setIsEditing(false);
        }
      } else {
        const res = await addCompensation(employeeID, compensation);
        if (res.status === 200) {
          const newCompensationList = [...compensationList, res.data].sort(
            (a, b) => {
              return moment
                .utc(b.effective_date)
                .diff(moment.utc(a.effective_date));
            }
          );
          setCompensationList(newCompensationList);
          showSnackbar(true, {
            msg: userMsgs().compensation.add,
            severity: 'success',
          });
          setAddCompensationDialog(false);
        }
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setAddCompensationDialog(false);
      setIsEditing(false);
    }
  };
  const handleDialogClose = () => {
    setAddCompensationDialog(false);
    setIsEditing(false);
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getCurrencySymbol = (code: string) => {
    return CURRENCY_CHOICES.find((item) => item.code === code)?.symbol;
  };

  const getContentMarkup = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
        to: `/people/${'id'}/edit`,
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, compensation: Compensation) => {
      if (dropId === 'edit') {
        setSelectedCompensation({
          ...compensation,
          effective_date: moment(compensation.effective_date).format(
            'MM/DD/YYYY'
          ),
        });
        setIsEditing(true);
      } else if (dropId === 'delete') {
        deleteConfirmation(compensation);
      }
    };
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = headerContent.map((item) => 90);
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          hasActionBtn={true}
          rowStyles={[styles.trow]}
        />
      );
    } else if (!compensationList.length) {
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title="Compensation"
            desc={'No compensation found'}
            icon={<CompensationIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    } else
      return compensationList.map((item, index) => {
        return (
          <TableListRow className={styles.trow} key={index}>
            <TableListCell
              className={clsx(styles.dateCol, styles.cell)}
              key="date"
            >
              <Text variant="body1">
                {getDateFormat(item.effective_date, 'MM/DD/YYYY')}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.compensationCol, styles.cell)}
              key="amount"
            >
              <Text variant="body1">
                <span className={styles.currencyTag}>
                  {getCurrencySymbol(item.currency)}
                </span>
                {item.rate.toFixed(2)}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.frequencyCol, styles.cell)}
              key="frequency"
            >
              <Text variant="body1">
                {item.frequency.split('_').join(' ').toLowerCase()}
              </Text>
            </TableListCell>

            <TableListCell className={clsx(styles.cell, styles.actionCol)}>
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            </TableListCell>
          </TableListRow>
        );
      });
  };

  const handleExport = async () => {
    try {
      const res = await getCompensationsReport(employeeID);
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getCompensation = () => {
    return (
      <div>
        <div className={styles.tableWrap}>
          <TableList className={styles.table}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
      </div>
    );
  };

  const getAddCompensationDialog = () => {
    if (addCompensationDialog || isEditing) {
      return (
        <PeopleAddEditCompensation
          open={addCompensationDialog || isEditing}
          compensation={isEditing ? selectedCompensation : ({} as Compensation)}
          onSubmit={handleCompensationSubmit}
          onClose={handleDialogClose}
        />
      );
    } else return null;
  };
  const getCompensationChart = () => {
    return (
      <>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.compensationHistory')}
          </Text>
          <div className={styles.sectionHeaderRight}>
            <Button
              className={styles.addItemBtn}
              variant="text"
              onClick={() => setAddCompensationDialog(true)}
            >
              <PlusIcon className={styles.plusIcon} />{' '}
              {t('label.addCompensation')}
            </Button>
            {isAdmin && (
              <Button
                size={'s'}
                onClick={handleExport}
                className={styles.exportBtn}
              >
                <ExportIcon className={styles.plusIcon} />
                {t('label.export')}
              </Button>
            )}
          </div>
        </div>
        <CompensationChart graphData={compensationList} />
      </>
    );
  };

  return (
    <div className={styles.compensationWrap}>
      <div className={styles.compensationChart}>{getCompensationChart()}</div>
      <div className={styles.compensation}>{getCompensation()}</div>
      {getAddCompensationDialog()}
    </div>
  );
}

export default PeopleCompensation;
