import React, { ReactNode, Suspense } from 'react';

import { Spin } from '@spovio/ui';

import { SideBar } from '../../sidebar/sidebar';
import styles from './app-layout.module.scss';

interface LayoutProps {
  children?: ReactNode;
}

export const AppLayout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.space}>
      <main className={styles.content}>
        <div className={styles.contentBody}>
          <div className={styles.contentBodyWrapper}>
            <Suspense fallback={<Spin size="full" />}>{children}</Suspense>
          </div>
        </div>
      </main>
    </div>
  );
};
