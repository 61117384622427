import {
  capitalize,
  COUNTRY_CHOICES,
  DepartmentItem,
  DivisionItem,
  EmployeePayload,
  getBasicUserList,
  getDepartments,
  getDivisions,
  getErrorMessages,
  getLocations,
  getPeoplesBasicList,
  LocationItem,
  MultiSelectEmployeeItem,
  User,
  validateForm,
} from '@spovio/shared';
import {
  Button,
  CloseIcon,
  DatePickerPopover,
  Divider,
  FormControl,
  Header,
  Input,
  InputLabel,
  Select,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './people-add-form.module.scss';

const CountryList = COUNTRY_CHOICES.map((item) => {
  return { label: item, value: item };
});

export interface PeopleAddFormProps {
  title: string;
  actionBtnText: string;
  onSubmit: (values: EmployeePayload) => void;
  initialValues: Partial<EmployeePayload>;
}

export function PeopleAddForm({
  title,
  actionBtnText,
  initialValues,
  onSubmit,
}: PeopleAddFormProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues as EmployeePayload);
  const [users, setUsers] = useState<User[]>([]);
  const [employees, setEmployees] = useState<MultiSelectEmployeeItem[]>([]);
  const [errors, setErrors] = useState<Partial<EmployeePayload>>(
    {} as Partial<EmployeePayload>
  );
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [divisions, setDivisions] = useState<DivisionItem[]>([]);
  const [locations, setLocations] = useState<LocationItem[]>([]);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    getInitialLists();
  }, []);

  const getInitialLists = async () => {
    try {
      const users = await getBasicUserList();
      setUsers(users.data);
      const res = await getPeoplesBasicList();
      setEmployees(res.data);
      const departments = await getDepartments();
      setDepartments(departments.data.results);
      const divisions = await getDivisions();
      setDivisions(divisions.data.results);
      const locations = await getLocations();
      setLocations(locations.data.results);
      if (!(initialValues && initialValues.job_information?.department)) {
        setValues(values);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const userOptions = users.map((user) => {
    return {
      label: user.name,
      value: `${user.id}`,
      disabled: false,
    };
  });
  const employeeOptions = employees.map((employee) => {
    return {
      label: employee.name,
      value: `${employee.id}`,
      disabled: false,
    };
  });
  const departmentOptions = departments.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });
  const divisionOptions = divisions.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });
  const locationOptions = locations.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateValue = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDateChange = (field: string, value: any) => {
    switch (field) {
      case 'dob': {
        setValues({ ...values, date_of_birth: value });
        break;
      }
      case 'hire': {
        setValues({ ...values, effective_date: value });
        break;
      }
      case 'termination': {
        setValues({ ...values, termination_date: value ? value : null });
        break;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (field: string, value: any) => {
    switch (field) {
      case 'gender': {
        setValues({ ...values, gender: value });
        break;
      }
      case 'user': {
        setValues({
          ...values,
          user: value,
          name: users.find((item) => item.id === Number(value))?.name ?? '',
          job_information: {
            ...values.job_information,
            manager:
              values.job_information?.manager === value
                ? undefined
                : values.job_information?.manager,
          },
        });
        break;
      }
      case 'country': {
        setValues({ ...values, country: value });
        break;
      }
      case 'manager': {
        setValues({
          ...values,
          job_information: {
            ...values.job_information,
            manager: value,
          },
        });
        break;
      }
      case 'department': {
        setValues({
          ...values,
          job_information: {
            ...values.job_information,
            department: value,
          },
        });
        break;
      }
      case 'division': {
        setValues({
          ...values,
          job_information: {
            ...values.job_information,
            division: value,
          },
        });
        break;
      }
      case 'location': {
        setValues({
          ...values,
          job_information: {
            ...values.job_information,
            location: value,
          },
        });
        break;
      }
    }
  };

  const handleClearUser = () => {
    setValues({ ...values, user: undefined, name: '' });
  };

  const handleClearCountry = () => {
    setValues({ ...values, country: null });
  };

  const handleClearSelect = (field: string) => {
    setValues({
      ...values,
      job_information: { ...values.job_information, [field]: null },
    });
  };

  const handleSubmit = () => {
    onSubmit(values);
  };

  const handleCancel = () => {
    history.goBack();
  };

  // functional components
  const getLeftComponent = () => {
    return (
      <>
        <Button
          className={styles.closeBtn}
          ghost
          variant="text"
          color="default"
          onClick={handleCancel}
        >
          <CloseIcon />
        </Button>
        <Divider type="vertical" className={styles.divider} />
        <h4 className={styles.title}>{title}</h4>
      </>
    );
  };

  const getEmployeeCreateButton = () => {
    return (
      <div>
        <Button
          variant="outlined"
          color="secondary"
          className={styles.cancelBtn}
          onClick={handleCancel}
        >
          {t('label.cancel')}
        </Button>
        <Button size={'m'} onClick={handleSubmit} disabled={!isValid()}>
          {actionBtnText}
        </Button>
      </div>
    );
  };

  const getEmbeddedDivider = (title: string) => {
    return (
      <div className={styles.embeddedDivider}>
        <div className={styles.title}>{title}</div>
        <Divider />
      </div>
    );
  };

  const isValid = () => {
    return (
      validateForm.isRequired(values.name) &&
      validateForm.isRequired(values.effective_date) &&
      validateForm.isRequired(values.job_information?.title) &&
      (!validateForm.isRequired(values.phone) ||
        validateForm.isPhoneNumber(values.phone)) &&
      (!validateForm.isRequired(values.personal_email) ||
        validateForm.isEmail(values.personal_email)) &&
      (!values?.termination_date ||
        !moment(values?.termination_date).isBefore(values?.effective_date))
    );
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={getLeftComponent()}
        rightContent={getEmployeeCreateButton()}
      />
      <div className={styles.form}>
        {getEmbeddedDivider(t('label.employeeDetails'))}
        <div className={styles.detailSection}>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
              <InputLabel label={t('label.user')} className={styles.label} />
              <Select
                options={userOptions}
                selectedOption={
                  values.user
                    ? {
                        label:
                          userOptions.find(
                            (user) => Number(user.value) === Number(values.user)
                          )?.label ?? '',
                        value: `${values.user}`,
                      }
                    : undefined
                }
                showResetIcon
                onClear={handleClearUser}
                onSelect={(value) => handleSelect('user', value)}
                placeholder={t('label.selectUser')}
              />
            </FormControl>
            <FormControl gutter="xs" fullWidth={true}>
              <Input
                name="name"
                validationType={['required']}
                disabled={!!values.user}
                required
                labelClassName={styles.label}
                label={t('label.name')}
                value={values.name}
                onChange={onUpdateValue}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
              <InputLabel className={styles.label} label={t('label.dob')} />
              <DatePickerPopover
                id={'dob'}
                size={'m'}
                paperClassName={styles.paper}
                selected={values.date_of_birth}
                placeholder={t('label.dobPlaceholder')}
                maxdate={moment()
                  .set({ year: moment().year() - 16 })
                  .toDate()}
                onTextChange={(value) => onDateChange('dob', value)}
                onChange={(value) => onDateChange('dob', value)}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              <InputLabel label={'Gender'} className={styles.label} />
              <Select
                options={[
                  {
                    label: 'Male',
                    value: 'male',
                  },
                  {
                    label: 'Female',
                    value: 'female',
                  },
                ]}
                onSelect={(value) => handleSelect('gender', value)}
                selectedOption={
                  values.gender
                    ? {
                        label: capitalize(values.gender),
                        value: values.gender,
                      }
                    : undefined
                }
                showResetIcon
                hideSearchHeader
              />
            </FormControl>
          </div>

          <div className={styles.row}>
            <FormControl
              gutter="xs"
              fullWidth={true}
              className={styles.leftCol}
            >
              <Input
                name="address1"
                labelClassName={styles.label}
                label={t('company.streetAddress', { number: 1 })}
                placeholder={t('company.streetAddress', { number: 1 })}
                value={values.address1}
                onChange={onUpdateValue}
              />
            </FormControl>
            <FormControl gutter="xs" fullWidth={true}>
              <Input
                name="address2"
                labelClassName={styles.label}
                label={t('company.streetAddress', { number: 2 })}
                placeholder={t('company.streetAddress', { number: 2 })}
                value={values.address2}
                onChange={onUpdateValue}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl
              gutter="xs"
              fullWidth={true}
              className={styles.leftCol}
            >
              <Input
                name="city"
                labelClassName={styles.label}
                label={t('label.city')}
                placeholder={t('label.city')}
                value={values.city}
                onChange={onUpdateValue}
              />
            </FormControl>
            <FormControl gutter="l" fullWidth={true}>
              <Input
                name="state"
                labelClassName={styles.label}
                label={t('label.state')}
                value={values.state}
                onChange={onUpdateValue}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
              <InputLabel label={t('label.country')} className={styles.label} />
              <Select
                options={CountryList}
                selectedOption={
                  values.country
                    ? {
                        label:
                          CountryList.find(
                            (country) => country.value === values.country
                          )?.label ?? '',
                        value: `${values.country}`,
                      }
                    : undefined
                }
                onSelect={(value) => handleSelect('country', value)}
                placeholder={t('label.selectCountry')}
                showResetIcon
                onClear={handleClearCountry}
              />
            </FormControl>
            <FormControl gutter="l" fullWidth={true}>
              <Input
                name="zip_code"
                labelClassName={styles.label}
                label={t('label.zipCode')}
                value={values.zip_code}
                onChange={onUpdateValue}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl
              gutter="l"
              fullWidth={true}
              className={clsx(styles.leftCol)}
            >
              <Input
                name="phone"
                validationType={['phone']}
                labelClassName={styles.label}
                label={t('label.phone')}
                value={values.phone}
                onChange={onUpdateValue}
              />
            </FormControl>
            <FormControl gutter="xs" fullWidth={true}>
              <Input
                name="work_email"
                validationType={['email']}
                labelClassName={styles.label}
                label={t('label.workEmail')}
                value={values.work_email}
                onChange={onUpdateValue}
                status={errors['work_email'] ? 'invalid' : false}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl
              gutter="l"
              fullWidth={true}
              className={clsx(styles.leftCol)}
            >
              <Input
                name="personal_email"
                validationType={['email']}
                labelClassName={styles.label}
                label={t('label.personalEmail')}
                value={values.personal_email}
                onChange={onUpdateValue}
              />
            </FormControl>
            <div className={styles.row}>
              <FormControl gutter="xs" fullWidth={true}>
                <Input
                  name="national_id"
                  labelClassName={styles.label}
                  label={t('label.nationalID')}
                  value={values.national_id}
                  onChange={onUpdateValue}
                />
              </FormControl>
            </div>
          </div>
        </div>
        {getEmbeddedDivider(t('label.jobDetails'))}
        <div className={styles.detailSection}>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
              <Input
                name="title"
                required
                validationType={['required']}
                labelClassName={styles.label}
                label={t('label.title')}
                value={values.job_information?.title}
                onChange={(e) =>
                  setValues({
                    ...values,
                    job_information: {
                      ...values.job_information,
                      title: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
            <FormControl gutter="xs" fullWidth={true}>
              <InputLabel label={t('label.manager')} className={styles.label} />
              <Select
                options={employeeOptions.map((user) => {
                  user.disabled = Number(user.value) === Number(values.user);
                  return user;
                })}
                selectedOption={
                  values.job_information?.manager
                    ? {
                        label:
                          employeeOptions.find(
                            (user) =>
                              Number(user.value) ===
                              Number(values.job_information?.manager)
                          )?.label ?? '',
                        value: `${values.job_information?.manager}`,
                      }
                    : undefined
                }
                onSelect={(value) => handleSelect('manager', value)}
                showResetIcon
                onClear={() => handleClearSelect('manager')}
                placeholder={t('label.selectManager')}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
              <InputLabel
                className={styles.label}
                required
                label={t('label.hireDate')}
              />
              <DatePickerPopover
                id={'effective_date'}
                size={'m'}
                paperClassName={styles.paper}
                placeholder={t('label.dobPlaceholder')}
                selected={values?.effective_date}
                onTextChange={(value) => onDateChange('hire', value)}
                onChange={(value) => onDateChange('hire', value)}
              />
            </FormControl>
            <FormControl gutter="xs" fullWidth={true}>
              <InputLabel
                className={styles.label}
                label={t('label.terminationDate')}
              />
              <DatePickerPopover
                id={'termination_data'}
                size={'m'}
                paperClassName={styles.paper}
                selected={values?.termination_date}
                placeholder={t('label.dobPlaceholder')}
                onTextChange={(value) => onDateChange('termination', value)}
                onChange={(value) => onDateChange('termination', value)}
                inputStatus={
                  values?.effective_date &&
                  values?.termination_date &&
                  moment(values.termination_date).isBefore(
                    values.effective_date
                  )
                    ? 'invalid'
                    : false
                }
                mindate={new Date(values.effective_date)}
                showResetIcon
                onReset={() => onDateChange('termination', null)}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
              <InputLabel
                label={t('label.department')}
                className={styles.label}
              />
              <Select
                options={departmentOptions}
                selectedOption={
                  values.job_information?.department
                    ? {
                        label:
                          departmentOptions.find(
                            (department) =>
                              Number(department.value) ===
                              Number(values.job_information?.department)
                          )?.label ?? '',
                        value: `${values.job_information?.department}`,
                      }
                    : undefined
                }
                showResetIcon
                onClear={() => handleClearSelect('department')}
                onSelect={(value) => handleSelect('department', value)}
                placeholder={t('dimensions.selectDepartment')}
              />
            </FormControl>
            <FormControl gutter="xs" fullWidth={true}>
              <InputLabel
                label={t('label.division')}
                className={styles.label}
              />
              <Select
                options={divisionOptions}
                selectedOption={
                  values.job_information?.division
                    ? {
                        label:
                          divisionOptions.find(
                            (division) =>
                              Number(division.value) ===
                              Number(values.job_information?.division)
                          )?.label ?? '',
                        value: `${values.job_information?.division}`,
                      }
                    : undefined
                }
                showResetIcon
                onClear={() => handleClearSelect('division')}
                onSelect={(value) => handleSelect('division', value)}
                placeholder={t('dimensions.selectDivision')}
              />
            </FormControl>
          </div>
          <div className={styles.row}>
            <FormControl
              gutter="l"
              fullWidth={true}
              className={clsx(styles.leftCol, styles.halfCol)}
            >
              <InputLabel
                label={t('label.location')}
                className={styles.label}
              />
              <Select
                options={locationOptions}
                selectedOption={
                  values.job_information?.location
                    ? {
                        label:
                          locationOptions.find(
                            (location) =>
                              Number(location.value) ===
                              Number(values.job_information?.location)
                          )?.label ?? '',
                        value: `${values.job_information?.location}`,
                      }
                    : undefined
                }
                showResetIcon
                onClear={() => handleClearSelect('location')}
                onSelect={(value) => handleSelect('location', value)}
                placeholder={t('dimensions.selectLocation')}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleAddForm;
