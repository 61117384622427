import React, { ReactNode, Suspense } from 'react';

import { Spin } from '@spovio/ui';

import styles from './full-screen-layout.module.scss';

interface FullScreenLayoutProps {
  children?: ReactNode;
}

export const FullScreenLayout = ({ children }: FullScreenLayoutProps) => {
  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<Spin size="full" />}>{children}</Suspense>
    </div>
  );
};
