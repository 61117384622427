// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-add-edit-compensation_content__p3LuI {\n  padding: 20px 25px 0 25px;\n}\n.people-add-edit-compensation_content__p3LuI .people-add-edit-compensation_inputSelector__3-ric {\n  height: 22px;\n}\n.people-add-edit-compensation_content__p3LuI .people-add-edit-compensation_currencySelector__3-lPz {\n  border: 0;\n  width: 71px;\n  padding-left: 10px;\n}\n\n.people-add-edit-compensation_payRateInput__t3LMd {\n  padding-left: 40px !important;\n}\n\n.people-add-edit-compensation_payRateIcon__28By- {\n  width: 40px;\n  font-size: 14px;\n}\n\n.people-add-edit-compensation_selectList__1fbnU > li {\n  text-transform: capitalize;\n}", ""]);
// Exports
exports.locals = {
	"content": "people-add-edit-compensation_content__p3LuI",
	"inputSelector": "people-add-edit-compensation_inputSelector__3-ric",
	"currencySelector": "people-add-edit-compensation_currencySelector__3-lPz",
	"payRateInput": "people-add-edit-compensation_payRateInput__t3LMd",
	"payRateIcon": "people-add-edit-compensation_payRateIcon__28By-",
	"selectList": "people-add-edit-compensation_selectList__1fbnU"
};
module.exports = exports;
