import styles from './forgot-pwd.module.scss';
import {
  Button,
  FormControl,
  FormText,
  Input,
  KeyboardArrowLeftIcon,
  SpovioIcon,
  SpovioLogoBlackIcon,
  Text,
} from '@spovio/ui';
import { forgotPassword, userMsgs, validateEmail } from '@spovio/shared';
import { ErrorMessage, Formik, FormikErrors } from 'formik';
import { NavLink, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface ForgotPwdProps {}
interface FormValues {
  email: string;
}

export function ForgotPwd(props: ForgotPwdProps) {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState('');
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await forgotPassword(values.email);
      if (response.status === 200) {
        history.push({
          pathname: '/email-confirm',
          state: { email: values.email },
        });
      }
    } catch (error: any) {
      setServerError(error?.message);
    }
  };

  const handleFocus = () => {
    setServerError('');
  };

  return (
    <div className={styles.root}>
      <SpovioLogoBlackIcon className={styles.title} />
      <div className={styles.innerContent}>
        <Text variant="h1" className={styles.heading}>
          {t('login.forgotPassword')}
        </Text>
        <Text variant="caption" className={styles.helperText}>
          {t('login.forgotPasswordSubHead')}
        </Text>
        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors: FormikErrors<FormValues> = {};
            if (!values.email) {
              errors.email = userMsgs().emailNotEntered;
            }
            if (validateEmail(values.email)) {
              errors.email = userMsgs().emailNotValid;
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl gutter="xl" className={styles.formGroup}>
                <Input
                  name="email"
                  placeholder={t('label.email')}
                  type="email"
                  size="l"
                  status={
                    (errors.email && touched.email) || serverError
                      ? 'invalid'
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  value={values.email}
                />
                <ErrorMessage name="email">
                  {(errorMessage) => (
                    <FormText type="invalid">{errorMessage}</FormText>
                  )}
                </ErrorMessage>
                {serverError && (
                  <FormText type="invalid">{serverError}</FormText>
                )}
              </FormControl>
              <Button
                fullWidth
                size="l"
                type="submit"
                className={styles.btn}
                disabled={isSubmitting || !isValid}
              >
                {t('login.resetPassword')}
              </Button>
            </form>
          )}
        </Formik>
        <div className={styles.option}>
          <NavLink className={styles.linkText} to={'/login'}>
            <KeyboardArrowLeftIcon />
            {t('label.back')}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default ForgotPwd;
