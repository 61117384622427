import {
  approveTimeOff,
  BasicFilter,
  COMPONENT_STATE,
  getApprovalTimeOffs,
  getApprovedTimeOffs,
  getDateFormat,
  getErrorMessages,
  getPeoplesBasicList,
  getThisMonthRange,
  PEOPLE_TIME_APPROVAL_TABLE_HEADERS,
  TABS,
  TimeOffList,
  TimeOffTypePayload,
  unlockTimeOff,
  userMsgs,
  WeekDateRange,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Button,
  DateRangePickerPopover,
  Header,
  NoDataContent,
  Pagination,
  StatusBadge,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Tabs,
  Text,
  TimeOffIcon,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectUser, {
  MultiSelectUserItem,
} from '../../multi-select-user/multi-select-user';
import styles from './people-time-off-approve.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

interface FilterOptions extends BasicFilter {
  dateRange?: WeekDateRange;
}

/* eslint-disable-next-line */
export interface PeopleTimeOffApproveProps {}

export function PeopleTimeOffApprove(props: PeopleTimeOffApproveProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [activeTab, setActiveTab] = useState(TABS.PENDING);
  const [timeOffs, setTimeOffs] = useState({} as TimeOffList);
  const [selectedTimeList, setSelectedTimeList] = useState([] as number[]);
  const [peopleFilters, setPeopleFilters] = useState<number[]>();
  const { showSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<FilterOptions>({
    page: '1',
    page_size: '25',
    search_text: '',
  });

  const [peopleList, setPeopleList] = useState<MultiSelectUserItem[]>(
    [] as MultiSelectUserItem[]
  );

  const headerContent = PEOPLE_TIME_APPROVAL_TABLE_HEADERS();

  useEffect(() => {
    getFilterList();
  }, []);

  const getFilterList = async () => {
    setComponentState(LOADING);
    const res = await getPeoplesBasicList();
    setPeopleList(res.data);
    setComponentState(LOADED);
  };

  const getTimeOffList = async (
    activeTab: TABS,
    peopleFilters?: number[],
    filter?: FilterOptions
  ) => {
    setComponentState(LOADING);
    const payload: TimeOffTypePayload = {
      employees: peopleFilters?.length ? peopleFilters : undefined,
      start_date: filter?.dateRange?.start_date,
      end_date: filter?.dateRange?.end_date,
    };
    const basicFilter = filter;
    if (basicFilter) {
      delete basicFilter.dateRange;
    }
    if (activeTab === TABS.PENDING) {
      const res = await getApprovalTimeOffs(payload, basicFilter);
      setTimeOffs(res.data.data);
      if (!filter?.dateRange) {
        const { start_date, end_date } = res.data;
        setFilter((prev) => {
          return { ...prev, dateRange: { start_date, end_date } };
        });
      }
      setPeopleFilters(peopleFilters);
      setComponentState(LOADED);
    } else {
      const res = await getApprovedTimeOffs(payload, basicFilter);
      setTimeOffs(res.data.data);
      if (!filter) {
        const { start_date, end_date } = res.data;
        setFilter((prev) => {
          return { ...prev, dateRange: { start_date, end_date } };
        });
      }
      setPeopleFilters(peopleFilters);
      setComponentState(LOADED);
    }
  };

  const onFilter = (filters: FilterOptions) => {
    setComponentState(LOADING);
    setFilter({ ...filter, ...filters });
    getTimeOffList(activeTab, peopleFilters, filters);
  };

  const onselect = (id: number) => {
    if (selectedTimeList.includes(id)) {
      setSelectedTimeList(selectedTimeList.filter((item) => item !== id));
    } else {
      setSelectedTimeList([...selectedTimeList, id]);
    }
  };

  const onTabChange = (tab: number) => {
    setSelectedTimeList([]);
    setActiveTab(tab);
    getTimeOffList(tab, peopleFilters);
  };

  const handleSubmit = async (id: number) => {
    showSnackbar(false);
    try {
      if (activeTab === TABS.PENDING) {
        const res = await approveTimeOff(id);
        if (res.status === 200) {
          getTimeOffList(activeTab, peopleFilters);
          showSnackbar(true, {
            msg: userMsgs().timeOff.approved,
            severity: 'success',
          });
        }
      } else if (activeTab === TABS.APPROVED) {
        const res = await unlockTimeOff(id);
        if (res.status === 200) {
          getTimeOffList(activeTab, peopleFilters);
          showSnackbar(true, {
            msg: userMsgs().timeOff.unlocked,
            severity: 'success',
          });
        }
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onChangeDateFilter = (date?: { startDate: Date; endDate: Date }) => {
    if (date) {
      const { startDate, endDate } = date;
      const start_date = getDateFormat(startDate);
      const end_date = getDateFormat(endDate);
      setFilter((prev) => {
        return { ...prev, dateRange: { start_date, end_date } };
      });
      getTimeOffList(activeTab, peopleFilters, {
        ...filter,
        dateRange: { start_date, end_date },
      });
    }
  };

  const onResetDateFilter = () => {
    setFilter((prev) => {
      return {
        ...prev,
        dateRange: getThisMonthRange(String(new Date())),
      };
    });
  };

  const getFilterSection = () => {
    return (
      <div className={styles.filterWrap}>
        <MultiSelectUser
          onSelect={(value: any, type?: string) => {
            setPeopleFilters(value);
            getTimeOffList(activeTab, value, filter);
          }}
          isLoading={LOADED}
          userList={peopleList}
        />

        <div className={styles.headerRightInnerCalendar}>
          <DateRangePickerPopover
            className={styles.filter}
            onChange={onChangeDateFilter}
            onReset={onResetDateFilter}
            dateRange={{
              startDate: filter.dateRange?.start_date
                ? moment(filter.dateRange?.start_date).toDate()
                : new Date(),
              endDate: filter.dateRange?.end_date
                ? moment(filter.dateRange?.end_date).toDate()
                : new Date(),
            }}
          />
        </div>
      </div>
    );
  };

  const getHeaderRowMarkup = () => {
    let headerRow = headerContent;
    if (activeTab === TABS.PENDING) {
      headerRow = headerContent.filter((item) => item.id !== 'approved');
    }
    return (
      <TableListRow className={styles.headerRow}>
        {headerRow.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
      </TableListRow>
    );
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      let headerRow = headerContent;
      if (activeTab === TABS.PENDING) {
        headerRow = headerContent.filter((item) => item.id !== 'approved');
      }
      const colStyles = headerRow.map((item) => styles[item.className]);
      const widths = [...headerRow.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerRow.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
        />
      );
    } else if (!timeOffs.results?.length) {
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('people.timeOff.timeOffRequestNoData.head')}
            desc={t('people.timeOff.timeOffRequestNoData.desc', {
              status:
                activeTab === TABS.PENDING
                  ? t('label.pending').toLowerCase()
                  : t('label.approved').toLowerCase(),
            })}
            icon={<TimeOffIcon className={styles.noDataIcon} />}
          />
        </div>
      );
    }
    return timeOffs.results.map((item, index) => {
      return (
        <TableListRow className={styles.trow} key={index}>
          <TableListCell
            className={clsx(styles.dateCol, styles.cell)}
            key="date"
          >
            <Text variant="body1" fontWeight="medium" className={styles.task}>
              {getDateFormat(item.from_date, 'MMM DD YYYY')}
            </Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.userCol, styles.cell, 'no-wrap')}
            key="user"
          >
            <Avatar
              name={item.employee?.name}
              url={item.employee?.user?.user?.pic}
              size={AvatarSize.avatar30}
              className={styles.avatar}
              displayName
              circle
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.descriptionCol, styles.cell)}
            key="description"
          >
            <Text variant="body1" fontWeight="medium" className={styles.task}>
              {item.type?.name}
            </Text>
            <Text variant="body3" className={styles.description}>
              {item.note}
            </Text>
          </TableListCell>
          <TableListCell
            className={clsx(
              styles.approvedCol,
              styles.cell,
              activeTab === TABS.PENDING && styles.displayNone
            )}
            key="approvedDate"
          >
            <Text variant="body1" fontWeight="medium" className={styles.task}>
              {getDateFormat(item.approved_at, 'MMM DD YYYY')}
            </Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.usedCol, styles.cell)}
            key="used"
          >
            <Text variant="body1">{item.used?.toFixed(2)}</Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.actionCol, styles.cell)}
            key="action"
          >
            <Button ghost onClick={() => handleSubmit(item.id)}>
              <StatusBadge
                status={item.approved ? 'unlock' : 'approve'}
                label={item.approved ? t('label.unlock') : t('label.approve')}
              />
            </Button>
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getTimeOffTable = () => {
    return (
      <>
        <div className={styles.tableWrap}>
          <TableList className={clsx(styles.table, styles.historyTable)}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
        {getPaginationFooter()}
      </>
    );
  };

  const getPanes = () => {
    const { PENDING, APPROVED } = TABS;
    return [
      {
        title: t('label.pendingApproval'),
        content: getTimeOffTable(),
        key: PENDING,
      },
      {
        title: t('label.approved'),
        content: getTimeOffTable(),
        key: APPROVED,
      },
    ];
  };

  const getPaginationFooter = () => {
    // if (isLoading) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      timeOffs;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => {
          onFilter({ ...filter, page: page });
        }}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  useEffect(() => {
    getTimeOffList(activeTab);
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('people.timeOff.timeOffRequests')}</h4>}
      />
      <Tabs
        panes={getPanes()}
        contentClassName={styles.content}
        activeTab={activeTab}
        onChange={(tab) => onTabChange(tab)}
        tabBarClassName={styles.tabBar}
        extraContent={getFilterSection()}
      />
    </>
  );
}

export default PeopleTimeOffApprove;
