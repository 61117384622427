import axios, { AxiosResponse } from 'axios';
import { BasicFilter, DivisionItem, DivisionList, Pagination } from '../types';
import { stringify } from 'query-string';

export const getDivisions = async (
  filters?: BasicFilter
): Promise<AxiosResponse<DivisionList>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/dimensions/division/?${queryParams}`);
};

export const getDivision = async (
  id: string
): Promise<AxiosResponse<DivisionItem>> => {
  return await axios.get(`/dimensions/division/${id}/`);
};

export const createDivision = async (
  name: string
): Promise<AxiosResponse<DivisionItem>> => {
  const payload = { name };
  return await axios.post(`/dimensions/division/`, payload);
};

export const updateDivision = async (
  id: number,
  name: string
): Promise<AxiosResponse<DivisionItem>> => {
  const payload = { name };
  return await axios.patch(`/dimensions/division/${id}/`, payload);
};

export const deleteDivision = async (
  id: number
): Promise<AxiosResponse<DivisionItem>> => {
  return await axios.delete(`/dimensions/division/${id}/`);
};
