import {
  clone,
  COMPONENT_STATE,
  getPeoplesBasicList,
  getDateFormat,
  getErrorMessages,
  getGroupedTimeOffReportsByWeek,
  getTimeOffReportsByWeek,
  getThisMonthRange,
  getThisWeekRange,
  getThisYearRange,
  GroupedReportItem,
  LazyLoadPagination,
  TimeOffReportColumns,
  ReportSummaryItem,
  TIME_OFF_RANGE_TABLE_HEADERS,
  TIME_RANGE_SELECT_OPTIONS,
  TIME_RANGE_TABLE_HEADERS,
  toLowerCase,
  WeekDateRange,
  getTimeOffTypes,
  TimeOffType,
  exportTimeOffReport,
} from '@spovio/shared';
import {
  ArrowDualNav,
  Text,
  Header,
  useSnackbar,
  DateRangePickerPopover,
  Popper,
  Ellipsis,
  KeyboardArrowDownIcon,
  Button,
  TickIcon,
  TimeIcon,
  TimeOffIcon,
  ExportIcon,
} from '@spovio/ui';
import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectProjects from '../../multi-select-projects/multi-select-projects';
import MultiSelectUser, {
  MultiSelectUserItem,
} from '../../multi-select-user/multi-select-user';
import TimeReportsTable from '../../time/time-reports-table/time-reports-table';
import styles from './people-time-off-report.module.scss';

/* eslint-disable-next-line */
export interface PeopleTimeOffReportProps {}

interface FilterOptions extends LazyLoadPagination {
  dateRange: WeekDateRange;
  selectedGroupByOption: string;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function PeopleTimeOffReport(props: PeopleTimeOffReportProps) {
  const { t } = useTranslation();
  const [selectedRangeOption, setSelectedRangeOption] = useState(
    TIME_RANGE_SELECT_OPTIONS()[0]
  );
  const [reportList, setReportList] = useState<GroupedReportItem[]>(
    [] as GroupedReportItem[]
  );
  const [timeSummary, setTimeSummary] = useState<ReportSummaryItem>(
    {} as ReportSummaryItem
  );
  const [peopleList, setPeopleList] = useState<MultiSelectUserItem[]>(
    [] as MultiSelectUserItem[]
  );
  const [timeOffList, setTimeOffList] = useState<TimeOffType[]>(
    [] as TimeOffType[]
  );
  const [isRangeSelectOpen, setRangeSelectOpen] = useState(false);
  const [isGroupByFilterOpen, setGroupByFilterOpen] = useState(false);
  const [typeFilters, setTypeFilters] = useState<number[]>([]);
  const [peopleFilters, setPeopleFilters] = useState<number[]>([]);
  const [componentState, setComponentState] = useState(LOADING);
  const observer = useRef<any>();
  const [hasMore, setHasMore] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<FilterOptions>({
    page: 1,
    page_size: 25,
    has_next: true,
    dateRange: getThisWeekRange(),
    selectedGroupByOption: TimeOffReportColumns.NONE,
  });

  const handleToggle = () => {
    setRangeSelectOpen(!isRangeSelectOpen);
  };

  const onRangeSelectClose = () => {
    setRangeSelectOpen(false);
  };

  const handleGroupByFilterToggle = () => {
    setGroupByFilterOpen(!isGroupByFilterOpen);
  };

  const onGroupByFilterClose = () => {
    setGroupByFilterOpen(false);
  };

  const loadMoreReports = async (page: number) => {
    try {
      if (filter.selectedGroupByOption === TimeOffReportColumns.NONE) {
        const res = await getTimeOffReportsByWeek(
          filter.dateRange.start_date,
          filter.dateRange.end_date,
          peopleFilters.length ? peopleFilters : undefined,
          page,
          typeFilters.length ? typeFilters : undefined
        );
        const reportsByWeek = [res.data.results];
        setReportList((prevState) => {
          const newState = clone(prevState);
          newState[0] = {
            ...newState[0],
            times: prevState[0]
              ? [...prevState[0].times, ...reportsByWeek[0].times]
              : [...reportsByWeek[0].times],
          };
          return newState;
        });
        setFilter((prev) => {
          return { ...prev, has_next: res.data.has_next };
        });
        setHasMore(res.data.has_next);
      } else {
        const res = await getGroupedTimeOffReportsByWeek(
          filter.dateRange.start_date,
          filter.dateRange.end_date,
          toLowerCase(filter.selectedGroupByOption),
          peopleFilters.length ? peopleFilters : undefined,
          page,
          typeFilters.length ? typeFilters : undefined
        );
        const reportsByWeek = res.data.results.times;
        setReportList((prevState) => {
          const previousState = clone(prevState);
          const newReports = [];
          const prop = reportsByWeek[0].project_id
            ? 'project_id'
            : reportsByWeek[0].user_id
            ? 'user_id'
            : 'date';
          for (let i = 0; i < reportsByWeek.length; i++) {
            const report = reportsByWeek[i];
            const index = previousState.findIndex(
              (prevItem) => prevItem[prop] === report[prop]
            );
            if (index === -1) {
              newReports.push(report);
            } else {
              previousState[index].times = [
                ...previousState[index].times,
                ...report.times,
              ];
            }
          }
          return [...previousState, ...newReports];
        });
        setFilter((prev) => {
          return { ...prev, has_next: res.data.has_next };
        });
        setHasMore(res.data.has_next);
      }
      setIsSpinning(false);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onLoadMore = async () => {
    setIsSpinning(true);
    setFilter((prev: FilterOptions) => {
      loadMoreReports(prev.page + 1);
      return { ...prev, page: prev.page + 1 };
    });
  };

  const getFilterList = async () => {
    setComponentState(LOADING);
    const res = await getPeoplesBasicList();
    setPeopleList(res.data);
    const typeList = await getTimeOffTypes();
    setTimeOffList(typeList.data);
    setComponentState(LOADED);
  };

  const lastActivityElemRef = useCallback(
    (node) => {
      observer.current && observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          onLoadMore();
        }
      });
      node && observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, filter.selectedGroupByOption]
  );

  const handleExport = async () => {
    try {
      const res = await exportTimeOffReport(
        filter.dateRange.start_date,
        filter.dateRange.end_date,
        peopleFilters.length ? peopleFilters : undefined,
        typeFilters.length ? typeFilters : undefined
      );
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getTimeCreateButton = (buttonContent?: string, size?: 'm') => {
    return (
      <Button size={size || 's'} onClick={handleExport}>
        {buttonContent || (
          <>
            <ExportIcon className={styles.plusIcon} />
            {t('label.export')}
          </>
        )}
      </Button>
    );
  };

  const getReportsByWeekData = async (
    page: number,
    selectedGroupByOption: string,
    typeFilters: number[],
    peopleFilters: number[],
    dateRange: WeekDateRange
  ) => {
    setComponentState(COMPONENT_STATE.LOADING);
    if (page === 1)
      try {
        if (selectedGroupByOption === TimeOffReportColumns.NONE) {
          const res = await getTimeOffReportsByWeek(
            dateRange.start_date,
            dateRange.end_date,
            peopleFilters.length ? peopleFilters : undefined,
            page,
            typeFilters.length ? typeFilters : undefined
          );
          const reportsByWeek = [res.data.results];
          setTimeSummary(res.data.results);
          setReportList(reportsByWeek);
          if (!dateRange.start_date)
            setFilter((prev) => {
              return {
                ...prev,
                has_next: res.data.has_next,
                dateRange: {
                  start_date: res.data.results.start_date,
                  end_date: res.data.results.end_date,
                },
              };
            });
          else
            setFilter((prev) => {
              return {
                ...prev,
                has_next: res.data.has_next,
              };
            });
          setHasMore(res.data.has_next);
        } else {
          const res = await getGroupedTimeOffReportsByWeek(
            dateRange.start_date,
            dateRange.end_date,
            toLowerCase(selectedGroupByOption),
            peopleFilters.length ? peopleFilters : undefined,
            page,
            typeFilters.length ? typeFilters : undefined
          );
          const reportsByWeek = res.data.results.times;
          setReportList(reportsByWeek);
          setTimeSummary(res.data.results);
          if (!dateRange.start_date)
            setFilter((prev) => {
              return {
                ...prev,
                has_next: res.data.has_next,
                dateRange: {
                  start_date: res.data.results.start_date,
                  end_date: res.data.results.end_date,
                },
              };
            });
          else
            setFilter((prev) => {
              return {
                ...prev,
                has_next: res.data.has_next,
              };
            });
          setHasMore(res.data.has_next);
        }
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    setComponentState(COMPONENT_STATE.LOADED);
  };

  const setNewDateRange = (
    startDate: string,
    endDate?: string,
    rangeOption?: string
  ) => {
    const range = rangeOption || selectedRangeOption.value;
    switch (range) {
      case 'year':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisYearRange(startDate), page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          typeFilters,
          peopleFilters,
          getThisYearRange(startDate)
        );
        break;
      case 'month':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisMonthRange(startDate), page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          typeFilters,
          peopleFilters,
          getThisMonthRange(startDate)
        );

        break;
      case 'week':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisWeekRange(startDate), page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          typeFilters,
          peopleFilters,
          getThisWeekRange(startDate)
        );
        break;
      case 'all_time':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: {} as WeekDateRange, page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          typeFilters,
          peopleFilters,
          {} as WeekDateRange
        );
        break;
      default: {
        if (endDate) {
          setFilter((prev) => {
            return {
              ...prev,
              dateRange: { start_date: startDate, end_date: endDate },
              page: 1,
            };
          });
          getReportsByWeekData(
            1,
            filter.selectedGroupByOption,
            typeFilters,
            peopleFilters,
            { start_date: startDate, end_date: endDate }
          );
        }
      }
    }
  };

  const getDayViewDateHeader = () => {
    const isCurrentWeekMonthYear = (date: string) => {
      const currentDate = moment(date);
      const currentWeek = moment(new Date());
      const currentMonth = moment(new Date()).startOf('month');
      const currentYear = moment(new Date()).startOf('year');
      if (
        currentDate.isSame(currentWeek, 'week') &&
        selectedRangeOption.value === 'week'
      )
        return t('label.thisWeek') + ':';
      if (
        currentDate.isSame(currentMonth, 'month') &&
        selectedRangeOption.value === 'month'
      )
        return t('label.thisMonth') + ':';
      if (
        currentDate.isSame(currentYear, 'year') &&
        selectedRangeOption.value === 'year'
      )
        return t('label.thisYear') + ':';
      return null;
    };

    const isCurrentRange = isCurrentWeekMonthYear(filter.dateRange.start_date);
    const start_date = moment(filter.dateRange.start_date).format('DD MMM');
    const end_date = moment(filter.dateRange.end_date).format('DD MMM YYYY');

    return (
      <div className={styles.headerLeftInnerTitle}>
        {isCurrentRange ? (
          <Text
            variant="h3"
            fontWeight="semi-bold"
            className={styles.currentPeriod}
          >
            {isCurrentRange}
          </Text>
        ) : (
          ''
        )}
        <Text variant="h3">
          {start_date} - {end_date}
        </Text>
      </div>
    );
  };

  const onChangeDateFilter = (date?: { startDate: Date; endDate: Date }) => {
    if (date) {
      const { startDate, endDate } = date;
      const start_date = getDateFormat(startDate);
      const end_date = getDateFormat(endDate);
      setNewDateRange(start_date, end_date, 'custom');
      setSelectedRangeOption(TIME_RANGE_SELECT_OPTIONS()[4]);
    }
  };

  const onResetDateFilter = () => {
    setSelectedRangeOption(TIME_RANGE_SELECT_OPTIONS()[0]);
    setNewDateRange(getDateFormat(new Date()), undefined, 'week');
  };

  const onDualArrowChange = (type: string) => {
    let newDate: string, diff: number;
    switch (selectedRangeOption.value) {
      case 'week':
        diff = type === 'left' ? -7 : 7;
        newDate = moment(filter.dateRange.start_date)
          .add(diff, 'days')
          .format('YYYY-MM-DD');
        setNewDateRange(newDate);
        break;
      case 'month':
        diff = type === 'left' ? -1 : 1;
        newDate = moment(filter.dateRange.start_date)
          .add(diff, 'months')
          .format('YYYY-MM-DD');
        setNewDateRange(newDate);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisMonthRange(newDate) };
        });
        break;
      case 'year':
        diff = type === 'left' ? -1 : 1;
        newDate = moment(filter.dateRange.start_date)
          .add(diff, 'years')
          .format('YYYY-MM-DD');
        setNewDateRange(newDate);
        break;
      default:
        break;
    }
  };

  const handleSelectRangeOption = (option: {
    label: string;
    value: string;
  }) => {
    setSelectedRangeOption(option);
    setNewDateRange(getDateFormat(new Date()), undefined, option.value);
    setRangeSelectOpen(false);
  };

  const handleSelectedGroupByOption = (option: string) => {
    setComponentState(COMPONENT_STATE.LOADING);
    setFilter((prev) => {
      return { ...prev, page: 1, selectedGroupByOption: option };
    });
    getReportsByWeekData(
      1,
      option,
      typeFilters,
      peopleFilters,
      filter.dateRange
    );
    setGroupByFilterOpen(false);
  };

  const getNavItemMarkup = () => {
    return TIME_RANGE_SELECT_OPTIONS().map((item, index) => (
      <Button
        // to={appRoute.getRoute(item.path)}
        className={clsx(
          styles.navLink,
          item.value === selectedRangeOption.value && styles.active
        )}
        // activeClassName={styles.active}
        onClick={() => handleSelectRangeOption(item)}
        key={index}
      >
        <li className={styles.navItem} key={index}>
          <div className={styles.navLinkLeft}>{item.label}</div>
          {item.value === selectedRangeOption.value && (
            <TickIcon className={styles.checkIcon} />
          )}
        </li>
      </Button>
    ));
  };

  const getTimeTableNavHeader = (): JSX.Element => {
    return (
      <div className={styles.reportTableNavHeaderWrap}>
        <div className={styles.reportTableNavLeftHeaderWrap}>
          <ArrowDualNav
            onClick={onDualArrowChange}
            leftButtonDisabled={['all_time', 'custom'].includes(
              selectedRangeOption.value
            )}
            rightButtonDisabled={['all_time', 'custom'].includes(
              selectedRangeOption.value
            )}
          />
          {getDayViewDateHeader()}
        </div>
        <div className={styles.reportTableNavRightHeaderWrap}>
          <MultiSelectProjects
            name={t('people.timeOff.timeOffTypes')}
            searchPlaceholder={t('people.timeOff.findTimeOffType')}
            icon={TimeOffIcon}
            onSelect={(value: any, type?: string) => {
              setTypeFilters(value);
              setFilter((prev) => {
                return {
                  ...prev,
                  page: 1,
                };
              });
              getReportsByWeekData(
                1,
                filter.selectedGroupByOption,
                value,
                peopleFilters,
                filter.dateRange
              );
            }}
            isLoading={LOADED}
            projectList={timeOffList}
          />
          <MultiSelectUser
            onSelect={(value: any, type?: string) => {
              setPeopleFilters(value);
              setFilter((prev) => {
                return {
                  ...prev,
                  page: 1,
                };
              });
              getReportsByWeekData(
                1,
                filter.selectedGroupByOption,
                typeFilters,
                value,
                filter.dateRange
              );
            }}
            isLoading={LOADED}
            userList={peopleList}
          />

          <div className={styles.headerRightInnerCalendar}>
            <DateRangePickerPopover
              className={styles.filter}
              onChange={onChangeDateFilter}
              onReset={onResetDateFilter}
              dateRange={{
                startDate: filter.dateRange?.start_date
                  ? moment(filter.dateRange?.start_date).toDate()
                  : new Date(),
                endDate: filter.dateRange?.end_date
                  ? moment(filter.dateRange?.end_date).toDate()
                  : new Date(),
              }}
            />
          </div>
          <div className={styles.headerRightInnerPeriod}>
            <Popper
              open={isRangeSelectOpen}
              anchorOrigin="right"
              paperClassName={styles.paper}
              renderButton={() => {
                return (
                  <button
                    className={clsx(styles.headerRightInnerPeriodBtn)}
                    onClick={handleToggle}
                  >
                    <Ellipsis className={styles.selectedRange}>
                      {selectedRangeOption.label}
                    </Ellipsis>
                    <KeyboardArrowDownIcon />
                  </button>
                );
              }}
              onClose={onRangeSelectClose}
            >
              <ul className={styles.navMenu}>{getNavItemMarkup()}</ul>
            </Popper>
          </div>
        </div>
      </div>
    );
  };

  const getGroupByFilterOption = (item: any) => {
    return item === 'NONE'
      ? t('label.none')
      : item === 'DATE'
      ? t('label.date')
      : item === 'TYPE'
      ? t('label.type')
      : item === 'PERSON'
      ? t('label.person')
      : null;
  };

  const getGroupFilterNav = () => {
    return Object.keys(TimeOffReportColumns).map((item: string, index) => (
      <Button
        className={clsx(
          styles.navLink,
          item === filter.selectedGroupByOption && styles.active
        )}
        onClick={() => handleSelectedGroupByOption(item)}
        key={index}
      >
        <li className={styles.navItem} key={index}>
          <div className={styles.navLinkLeft}>
            {getGroupByFilterOption(item)}
          </div>
          {item === filter.selectedGroupByOption && (
            <TickIcon className={styles.checkIcon} />
          )}
        </li>
      </Button>
    ));
  };

  const getGroupByFilter = () => {
    return (
      <div
        className={clsx(styles.headerRightInnerPeriod, styles.groupByContainer)}
      >
        <Popper
          open={isGroupByFilterOpen}
          anchorOrigin="left"
          paperClassName={styles.paper}
          renderButton={() => {
            return (
              <button
                className={clsx(
                  styles.headerRightInnerPeriodBtn,
                  styles.ghostBtn,
                  styles.mr_auto
                )}
                onClick={handleGroupByFilterToggle}
              >
                <div className={styles.d_flex}>
                  <Ellipsis className={styles.title}>
                    {t('label.groupBy')}:
                  </Ellipsis>
                  <Ellipsis className={styles.selectedTxt}>
                    {getGroupByFilterOption(filter.selectedGroupByOption)}
                  </Ellipsis>
                </div>
                <KeyboardArrowDownIcon />
              </button>
            );
          }}
          onClose={onGroupByFilterClose}
        >
          <ul className={styles.navMenu}>{getGroupFilterNav()}</ul>
        </Popper>
      </div>
    );
  };

  useEffect(() => {
    getFilterList();
    getReportsByWeekData(
      filter.page,
      filter.selectedGroupByOption,
      typeFilters,
      peopleFilters,
      filter.dateRange
    );
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.reports')}</h4>}
        rightContent={getTimeCreateButton()}
      />
      <div className={styles.content}>
        {getTimeTableNavHeader()}
        {getGroupByFilter()}
        <TimeReportsTable
          componentState={componentState}
          groupBy={filter.selectedGroupByOption}
          reportList={reportList}
          totalHours={timeSummary.total_hours}
          isSpinning={isSpinning}
          lastActivityElemRef={lastActivityElemRef}
          onLoadMore={onLoadMore}
          headers={TIME_OFF_RANGE_TABLE_HEADERS()}
          noDataMessage={'No time offs in this period'}
        />
      </div>
    </>
  );
}

export default PeopleTimeOffReport;
