import { Suspense, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './app/app';
import { AppRoute } from './routes';
import {
  Spin,
  CurrentUserProvider,
  ConfirmationProvider,
  SnackbarProvider,
  CurrentTagsProvider,
} from '@spovio/ui';
import { publicAppRoutes } from './routes/public-routes';
import { interceptor } from '@spovio/shared';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

export const history = createBrowserHistory();

const Spovio = () => {
  useEffect(() => {
    interceptor();
  }, []);
  return (
    <CurrentUserProvider>
      <CurrentTagsProvider>
        <ConfirmationProvider>
          <SnackbarProvider>
            <Suspense fallback={<Spin size="full" />}>
              <Switch>
                {publicAppRoutes().map((route) => (
                  <AppRoute
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    showComponent
                  />
                ))}
                <Route
                  path="/:orgId"
                  render={(props) => {
                    return <App {...props} />;
                  }}
                />
                <Redirect to={'/login'} />
              </Switch>
            </Suspense>
          </SnackbarProvider>
        </ConfirmationProvider>
      </CurrentTagsProvider>
    </CurrentUserProvider>
  );
};

ReactDOM.render(
  <Router history={history}>
    <I18nextProvider i18n={i18n}>
      <Spovio />
    </I18nextProvider>
  </Router>,
  document.getElementById('root')
);
