import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const HomeIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group-48"
          transform="translate(-2.000000, -3.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Home,-House,-Line" transform="translate(0.000000, 1.000000)">
            <g id="Group" transform="translate(3.000000, 3.000000)">
              <path
                d="M13.0993333,4.12163889 L8.43266667,0.49175 C7.59033333,-0.163916667 6.41044444,-0.163916667 5.56733333,0.49175 L0.900666667,4.12163889 C0.332111111,4.56341667 -3.45402719e-16,5.24319444 -3.45402719e-16,5.96341667 L-3.45402719e-16,11.6668611 C-3.45402719e-16,12.9556389 1.04455556,14.0001944 2.33333333,14.0001944 L11.6666667,14.0001944 C12.9554444,14.0001944 14,12.9556389 14,11.6668611 L14,5.96341667 C14,5.24319444 13.6678889,4.56341667 13.0993333,4.12163889 Z"
                id="Path"
              ></path>
              <line
                x1="4.66666667"
                y1="10.8890833"
                x2="9.33333333"
                y2="10.8890833"
                id="Path"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
