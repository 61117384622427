import {
  approveTimeSheets,
  COMPONENT_STATE,
  getApprovedTimeSheets,
  getBasicUserList,
  getDateFormat,
  getErrorMessages,
  getPendingTimeSheets,
  getThisMonthRange,
  TABS,
  TimeOffItem,
  unlockTimeSheets,
  userMsgs,
  WeekDateRange,
} from '@spovio/shared';
import {
  Button,
  DateRangePickerPopover,
  Header,
  Tabs,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MultiSelectUser, {
  MultiSelectUserItem,
} from '../../multi-select-user/multi-select-user';
import TimeApprovalTable from '../time-approval-table/time-approval-table';
import styles from './time-approval.module.scss';

/* eslint-disable-next-line */
export interface TimeApprovalProps {}

interface StateProps {
  tab: TABS;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function TimeApproval(props: TimeApprovalProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as StateProps;
  const [timeList, setTimeList] = useState([] as TimeOffItem[]);
  const [selectedTimeList, setSelectedTimeList] = useState([] as number[]);
  const [peopleList, setPeopleList] = useState<MultiSelectUserItem[]>(
    [] as MultiSelectUserItem[]
  );
  const [filters, setFilters] = useState<{
    peopleFilters: number[];
    dateFilter: WeekDateRange;
    activeTab: TABS;
  }>({
    peopleFilters: [],
    dateFilter: getThisMonthRange(),
    activeTab: state?.tab ? TABS.APPROVED : TABS.PENDING,
  });
  const [componentState, setComponentState] = useState(LOADING);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    getFilterList();
  }, []);

  useEffect(() => {
    setComponentState(LOADING);
    updateApprovalList();
  }, [filters]);

  const getFilterList = async () => {
    setComponentState(LOADING);
    const res = await getBasicUserList();
    setPeopleList(res.data);
    setComponentState(LOADED);
  };

  const updateApprovalList = async () => {
    if (filters.activeTab === TABS.PENDING) {
      const res = await getPendingTimeSheets({
        ...filters.dateFilter,
        employees: filters.peopleFilters,
      });
      setTimeList(res.data);
      setComponentState(LOADED);
    } else {
      const res = await getApprovedTimeSheets({
        ...filters.dateFilter,
        employees: filters.peopleFilters,
      });
      setTimeList(res.data);
      setComponentState(LOADED);
    }
  };

  const onChangeDateFilter = (date?: { startDate: Date; endDate: Date }) => {
    if (date) {
      const { startDate, endDate } = date;
      const start_date = getDateFormat(startDate);
      const end_date = getDateFormat(endDate);
      setFilters((prev) => ({
        ...prev,
        dateFilter: { start_date, end_date },
      }));
    }
  };

  const onResetDateFilter = () => {
    setFilters((prev) => ({
      ...prev,
      dateFilter: getThisMonthRange(),
    }));
  };

  const onselect = (id: number) => {
    if (selectedTimeList.includes(id)) {
      setSelectedTimeList(selectedTimeList.filter((item) => item !== id));
    } else {
      setSelectedTimeList([...selectedTimeList, id]);
    }
  };

  const handleSubmit = async () => {
    showSnackbar(false);
    try {
      if (filters.activeTab === TABS.PENDING) {
        const payload = {
          ids: selectedTimeList,
        };
        const res = await approveTimeSheets(payload);
        setTimeList(timeList.filter((item) => !payload.ids.includes(item.id)));
        setSelectedTimeList([]);
        showSnackbar(true, {
          msg: userMsgs().times.approved,
          severity: 'success',
        });
      } else if (filters.activeTab === TABS.APPROVED) {
        const payload = {
          ids: selectedTimeList,
        };
        const res = await unlockTimeSheets(payload);
        setTimeList(timeList.filter((item) => !payload.ids.includes(item.id)));
        setSelectedTimeList([]);
        showSnackbar(true, {
          msg: userMsgs().times.unlocked,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getFilterSection = () => {
    return (
      <div className={styles.filterWrap}>
        <MultiSelectUser
          onSelect={(value: any, type?: string) => {
            setFilters((prev) => ({
              ...prev,
              peopleFilters: value,
            }));
          }}
          isLoading={LOADED}
          userList={peopleList}
        />

        <div className={styles.headerRightInnerCalendar}>
          <DateRangePickerPopover
            className={styles.filter}
            onChange={onChangeDateFilter}
            onReset={onResetDateFilter}
            dateRange={{
              startDate: filters.dateFilter?.start_date
                ? moment(filters.dateFilter?.start_date).toDate()
                : new Date(),
              endDate: filters.dateFilter?.end_date
                ? moment(filters.dateFilter?.end_date).toDate()
                : new Date(),
            }}
          />
        </div>
      </div>
    );
  };

  const getTimeApprovalTable = (submitText: string) => {
    return (
      <>
        <TimeApprovalTable
          timeList={timeList}
          componentState={componentState}
          selectedTimeList={selectedTimeList}
          onselect={onselect}
          activeTab={filters.activeTab}
        />
        {timeList.length ? (
          <div className={styles.submit}>
            <Button
              size={'m'}
              onClick={handleSubmit}
              disabled={!selectedTimeList.length}
            >
              {submitText}
            </Button>
          </div>
        ) : null}
      </>
    );
  };

  const getPanes = () => {
    const { PENDING, APPROVED } = TABS;
    return [
      {
        title: t('label.pendingApproval'),
        content: getTimeApprovalTable(t('label.approveTimesheets')),
        key: PENDING,
      },
      {
        title: t('label.approved'),
        content: getTimeApprovalTable(t('label.unlock')),
        key: APPROVED,
      },
    ];
  };

  const onTabChange = (tab: number) => {
    setSelectedTimeList([]);
    setFilters((prev) => ({
      ...prev,
      activeTab: tab,
      dateFilter: getThisMonthRange(),
    }));
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.approval')}</h4>}
      />
      <Tabs
        panes={getPanes()}
        contentClassName={styles.content}
        activeTab={filters.activeTab}
        onChange={(tab) => onTabChange(tab)}
        tabBarClassName={styles.tabBar}
        extraContent={getFilterSection()}
      />
    </>
  );
}

export default TimeApproval;
