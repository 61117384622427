import {
  appRoute,
  COMPONENT_STATE,
  getDateFormat,
  getTimeFromNumber,
  TABS,
  TimeOffItem,
  TIME_APPROVAL_TABLE_HEADERS,
} from '@spovio/shared';
import {
  ApprovalIcon,
  Avatar,
  AvatarSize,
  Checkbox,
  NoDataContent,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './time-approval-table.module.scss';

/* eslint-disable-next-line */
export interface TimeApprovalTableProps {
  timeList: TimeOffItem[];
  componentState: COMPONENT_STATE;
  selectedTimeList: number[];
  activeTab: TABS;
  onselect: (id: number) => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function TimeApprovalTable({
  timeList,
  componentState,
  selectedTimeList,
  onselect,
  activeTab,
}: TimeApprovalTableProps) {
  const { t } = useTranslation();
  const headerContent = TIME_APPROVAL_TABLE_HEADERS();

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
      </TableListRow>
    );
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [90, 300, 90, 90];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
        />
      );
    } else if (!timeList.length)
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('label.time')}
            desc={t('timesheet.timeApproveNoData.desc', {
              status:
                activeTab === TABS.PENDING
                  ? t('label.pending')
                  : t('label.approved'),
            })}
            icon={<ApprovalIcon className={styles.approvalIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    else
      return timeList.map((item, index) => {
        return (
          <NavLink
            to={{
              pathname: appRoute.getRoute('/time/approval/' + item.id),
              state: {
                name: item.created_by.user.name,
                tab: activeTab,
              },
            }}
            className={styles.navLink}
            activeClassName={styles.active}
            key={index}
          >
            <TableListRow className={styles.trow} key={index}>
              <TableListCell
                className={clsx(styles.dateCol, styles.cell)}
                key="date"
              >
                <Checkbox
                  className={styles.checkbox}
                  checked={selectedTimeList.includes(item.id)}
                  onChange={(e) => {
                    onselect(item.id);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  label={
                    <>
                      {' '}
                      {getDateFormat(item.start_date, 'MMM DD YYYY')} -{' '}
                      {getDateFormat(item.end_date, 'MMM DD YYYY')}
                    </>
                  }
                />
              </TableListCell>
              <TableListCell
                className={clsx(styles.personCol, styles.cell)}
                key="person"
              >
                <Avatar
                  url={item.created_by.user.pic}
                  name={item.created_by.user.name}
                  size={AvatarSize.avatar26}
                  className={styles.userIcon}
                  displayName
                  textClassName={styles.userText}
                  circle
                />
              </TableListCell>
              <TableListCell
                className={clsx(styles.hoursCol, styles.cell)}
                key="hours"
              >
                <Text variant="body1">
                  {getTimeFromNumber(item.total_hours)}
                </Text>
              </TableListCell>
            </TableListRow>
          </NavLink>
        );
      });
  };

  return (
    <div className={styles.tableWrap}>
      <TableList className={styles.table}>
        <TableListHead>{getHeaderRowMarkup()}</TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default TimeApprovalTable;
