import React, { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './header.module.scss';

/* eslint-disable-next-line */
interface HeaderProps {
  className?: string;
  leftContent?: ReactNode | string;
  rightContent?: ReactNode | string;
  bottomContent?: ReactNode | string;
}

export const Header = ({
  leftContent,
  rightContent,
  bottomContent,
  className,
}: HeaderProps) => {
  return (
    <header className={clsx(styles.header, className)}>
      <div className={styles.topContent}>
        <div className={styles.left}>{leftContent}</div>
        <div className={styles.right}>{rightContent}</div>
      </div>
      {bottomContent && (
        <div className={styles.bottomContent}>{bottomContent}</div>
      )}
    </header>
  );
};
