import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const Profile = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-29"
          transform="translate(-9.000000, -7.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Group-25" transform="translate(8.000000, 5.000000)">
            <g id="users-06" transform="translate(0.000000, 1.000000)">
              <g id="Group-24" transform="translate(2.000000, 2.000000)">
                <path
                  d="M2.66666667,13.8524444 C4.06133333,12.4968889 5.94844444,11.6631111 8.02044444,11.6631111 C10.072,11.6631111 11.9386667,12.48 13.3333333,13.8106667 C11.9386667,15.1662222 10.0515556,16 7.97955556,16 C5.928,16 4.06133333,15.1831111 2.66666667,13.8524444 Z"
                  id="Path"
                ></path>
                <path
                  d="M0.866666667,11.5777778 C0.324444444,10.4986667 0,9.29066667 0,8 C0,3.57866667 3.57866667,0 8,0 C12.4213333,0 16,3.57866667 16,8 C16,9.28888889 15.6773333,10.4977778 15.1315556,11.576"
                  id="Path"
                ></path>
                <path
                  d="M9.8856,4.33660444 C10.9269956,5.378 10.9269956,7.06644444 9.8856,8.10784 C8.84420444,9.14923556 7.15576,9.14923556 6.11436444,8.10784 C5.07296889,7.06644444 5.07296889,5.378 6.11436444,4.33660444 C7.15576,3.29520889 8.84420444,3.29520889 9.8856,4.33660444"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
