// import './icons.module.scss';

/* eslint-disable-next-line */
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TempIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.5a3 3 0 013 3v9a3 3 0 01-3 3H4a3 3 0 01-3-3v-9a3 3 0 013-3zm.621 3H3.379a1.2 1.2 0 00-1.2 1.2v7.81a1.8 1.8 0 001.8 1.8H14.02a1.8 1.8 0 001.8-1.8V5.7a1.2 1.2 0 00-1.2-1.2zM5.9 5.7a.6.6 0 01.6.6v7.2a.6.6 0 01-.6.6H4.1a.6.6 0 01-.6-.6V6.3a.6.6 0 01.6-.6h1.8z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const LogoutIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="17"
      height="15"
      viewBox="0 0 17 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M12.739 11.553a.6.6 0 01.004.848 7.17 7.17 0 01-5.111 2.128c-3.953 0-7.16-3.178-7.16-7.1 0-3.923 3.207-7.1 7.16-7.1a7.17 7.17 0 015.042 2.058.6.6 0 11-.841.856 5.97 5.97 0 00-4.201-1.714c-3.293 0-5.96 2.642-5.96 5.9 0 3.257 2.667 5.9 5.96 5.9a5.97 5.97 0 004.258-1.772.6.6 0 01.849-.004z"
          fill="#6F6973"
        />
        <path
          d="M14.536 6.679a.75.75 0 01.101 1.493l-.101.007H7.107a.75.75 0 01-.102-1.494l.102-.006h7.429z"
          fill="#6D6B70"
        />
        <path
          d="M12.643 5.107V9.75a.5.5 0 00.82.384l2.786-2.326a.5.5 0 000-.768l-2.786-2.317a.5.5 0 00-.82.384z"
          fill="#6D6B70"
        />
      </g>
    </svg>
  );
};

export const SuccussSnackbarIcon = (props: IconsProps) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Times Approved!</title>
      <g
        id="Time"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Approved-Status" transform="translate(-773.000000, -51.000000)">
          <g id="Group-89" transform="translate(761.000000, 40.000000)">
            <g id="Times-Approved!" transform="translate(12.000000, 11.000000)">
              <rect
                id="Rectangle-Copy-13"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="16"
                height="16"
                rx="8"
              ></rect>
              <polyline
                id="Path-18"
                stroke="#26CB6C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="5.3863736 8.25590962 6.92307692 10 10.8 6"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ErrorSnackbarIcon = (props: IconsProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <g
          transform="translate(1 1)"
          stroke="#252526"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        >
          <rect width="14" height="14" rx="7" />
          <path d="M3.032 1.677l7.627 10.629" />
        </g>
      </g>
    </svg>
  );
};

export const InfoSnackbarIcon = (props: IconsProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <g
          transform="translate(1 1)"
          stroke="#252526"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        >
          <rect width="14" height="14" rx="7" />
          <path d="M7 10V7M6.999 4.25a.25.25 0 10.002.5.25.25 0 00-.002-.5" />
        </g>
      </g>
    </svg>
  );
};

export const WarningSnackbarIcon = (props: IconsProps) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1.385 1h16v16h-16z" />
        <g
          stroke="#252526"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        >
          <path d="M10.76 3.27l5.268 9.782A2 2 0 0114.267 16H3.733a2 2 0 01-1.76-2.948L7.238 3.27a2 2 0 013.522 0zM9 7v3" />
          <path d="M8.999 12.75a.25.25 0 11.002-.5.25.25 0 01-.002.5" />
        </g>
      </g>
    </svg>
  );
};
