import {
  appRoute,
  CompanyPayload,
  COUNTRY_CHOICES,
  validateForm,
} from '@spovio/shared';
import {
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Select,
  Textarea,
} from '@spovio/ui';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styles from './contact-company-form.module.scss';

/* eslint-disable-next-line */
export interface ContactCompanyFormProps {
  open: boolean;
  title?: string;
  action?: string;
  isEditing?: boolean;
  initialValues: CompanyPayload;
  onSubmit: (initialValues: CompanyPayload) => void;
}

export const ContactCompanyForm = ({
  open,
  title = t('company.addCompany'),
  action = t('label.add'),
  isEditing,
  initialValues,
  onSubmit,
}: ContactCompanyFormProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState(initialValues);

  const CountryList = COUNTRY_CHOICES.map((item) => {
    return { label: item, value: item };
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelect = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    onSubmit(values);
    setIsModalVisible(false);
  };

  const isValidForm = () => {
    return (
      validateForm.isRequired(values.name) &&
      validateForm.isRequired(values.country)
    );
  };

  return (
    <div className={styles.root}>
      {!isModalVisible && (
        <Redirect to={appRoute.getRoute(`/contacts/companies`)} />
      )}
      <Dialog
        title={title}
        action={action}
        open={isModalVisible}
        isActionDisabled={!isValidForm()}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="name"
              labelClassName={styles.label}
              validationType={['required']}
              required
              label={t('label.name')}
              placeholder={t('company.companyName')}
              value={values.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="street1"
              labelClassName={styles.label}
              label={t('company.streetAddress', { number: 1 })}
              placeholder={t('company.streetAddress', { number: 1 })}
              value={values.street1}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="street2"
              labelClassName={styles.label}
              label={t('company.suiteFloorOther')}
              placeholder={t('company.suiteFloorOther')}
              value={values.street2}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="city"
              labelClassName={styles.label}
              label={t('label.city')}
              placeholder={t('label.city')}
              value={values.city}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="state"
              labelClassName={styles.label}
              label={t('label.state')}
              placeholder={t('label.state')}
              value={values.state}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <Input
              name="zipcode"
              labelClassName={styles.label}
              label={t('label.zipCode')}
              placeholder={t('label.zipCode')}
              value={values.zipcode}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel
              label={t('label.country')}
              className={styles.label}
              required
            />
            <Select
              options={CountryList}
              selectedOption={{
                label:
                  CountryList.find(
                    (country) => country.value === values.country
                  )?.label ?? '',
                value: `${values.country}`,
              }}
              onSelect={(value) => handleSelect('country', value)}
              placeholder={t('label.selectCountry')}
            />
          </FormControl>

          <FormControl gutter="l" fullWidth={true} className={styles.leftCol}>
            <InputLabel className={styles.label} label={t('label.notes')} />
            <Textarea
              name={'notes'}
              className={styles.textarea}
              placeholder={t('label.notes')}
              size="s"
              minRows={3}
              maxRows={5}
              disableResize
              onChange={handleChange}
              value={values.notes}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
};

export default ContactCompanyForm;
