import React from 'react';

import RcDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import styles from './date-picker.module.scss';
import es from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';
interface DatePickerProps extends ReactDatePickerProps {
  mindate?: Date;
  maxdate?: Date;
}

export const DatePicker = ({ mindate, maxdate, ...props }: DatePickerProps) => {
  const { i18n } = useTranslation();
  return (
    <div className={styles.datePicker}>
      <RcDatePicker
        {...props}
        inline
        showDisabledMonthNavigation
        minDate={mindate}
        showYearDropdown
        dateFormatCalendar="MMMM"
        dateFormat="yyyy/MM/dd"
        yearDropdownItemNumber={20}
        scrollableYearDropdown
        maxDate={maxdate}
        // showYearPicker
        // dateFormat="yyyy"

        locale={i18n.language === 'es' ? es : undefined}
      />
    </div>
  );
};
