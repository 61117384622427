import React, { HTMLProps, ReactNode, useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { Button, ButtonProps } from '../button/button';
import { Divider } from '../divider/divider';
import { EllipsisOutlinedIcon } from '../icons';
import styles from './dropdown.module.scss';

export interface DropdownListItem {
  label: React.ReactNode | string;
  icon?: React.ReactNode;
  id?: string;
  to?: any;
  data?: any;
  needDivider?: boolean;
  component?: 'link' | 'div' | 'a';
  component_props?: Pick<
    HTMLProps<HTMLAnchorElement>,
    'href' | 'rel' | 'target'
  >;
  action?: any;
}

export interface DropdownProps {
  data: DropdownListItem[];
  buttonClassName?: string;
  buttonActiveClassName?: string;
  menuClassName?: string;
  onItemClick?: (id: string) => void;
  placement?: 'right' | 'left';
  buttonContent?: ReactNode | string;
  buttonProps?: Omit<ButtonProps, 'children'>;
}

export const Dropdown = ({
  onItemClick,
  data,
  buttonClassName,
  buttonActiveClassName,
  placement = 'right',
  buttonContent,
  menuClassName,
  buttonProps = {
    size: 'm',
    ghost: true,
  },
  ...resProps
}: DropdownProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleItemClick = (id: string) => {
    onItemClick?.(id);
    setVisible(false);
  };

  const renderOption = (option: DropdownListItem) => {
    if (option.needDivider) return <Divider className={styles.divider} />;
    return (
      <>
        {option.icon ? (
          <span className={styles.itemIcon}>{option.icon}</span>
        ) : null}
        {option.label}
      </>
    );
  };
  const menu = (
    <ul className={clsx(styles.menu, styles[placement], menuClassName)}>
      {data.map((option, index) => {
        const Component =
          option.component === 'link' ? NavLink : option.component || 'div';
        return (
          <Component
            key={index}
            to={option.to}
            className={clsx(
              styles.menuItem,
              option.needDivider && styles.divider
            )}
            onClick={() => handleItemClick(option.id + '')}
            {...option.component_props}
          >
            {renderOption(option)}
          </Component>
        );
      })}
    </ul>
  );
  const handleOpen = () => {
    setVisible((prev) => !prev);
  };
  function getItemsMarkup(items: DropdownListItem[]) {
    if (!items) return null;
    return (
      <ul className={clsx(styles.menu, styles[placement], menuClassName)}>
        {items.map((item, index) => {
          const Component: any = item.component || 'div';
          return (
            <Component
              key={index}
              to={item.to}
              className={clsx(
                styles.menuItem,
                item.needDivider && styles.divider
              )}
              onClick={() => handleItemClick(item.id + '')}
              {...item.component_props}
            >
              {renderOption(item)}
            </Component>
          );
        })}
      </ul>
    );
  }
  return (
    <div className={clsx(styles.dropdown, visible && styles.isOpen)}>
      <Button
        className={clsx(
          styles.dropdownBtn,
          buttonClassName,
          !buttonContent && styles.iconButton,
          visible && styles.active,
          visible && buttonActiveClassName
        )}
        ref={anchorRef}
        isActive={visible}
        variant="text"
        color="secondary"
        aria-label={'dropdown button'}
        onClick={handleOpen}
        {...buttonProps}
      >
        {buttonContent || <EllipsisOutlinedIcon />}
      </Button>
      <Popper
        open={visible}
        anchorEl={anchorRef.current}
        className={styles.dropdownWrap}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'top right' : 'right bottom',
            }}
          >
            <Paper className={styles.container} id="menu-list-grow">
              <ClickAwayListener onClickAway={() => setVisible(false)}>
                <div className={styles.list}>{getItemsMarkup(data)}</div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
