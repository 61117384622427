import moment from 'moment';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from 'recharts';
import styles from './headcount-chart.module.scss';

interface Data {
  month: number;
  year: number;
  headcount: number;
}

/* eslint-disable-next-line */
interface HeadcountChartProps {
  graphData: Data[];
}

export const HeadcountChart = (props: HeadcountChartProps) => {
  const customizedTick = (props: any) => {
    const { payload, height, width, index, textAnchor, x, y } = props;
    const attrs = {
      height,
      width,
      index,
      textAnchor,
      x,
      y,
      fill: '#ADB3BC',
      fontSize: 11,
      letterSpacing: -0.19,
    };
    const labels = payload.value ? payload.value.split('____') : payload.value;
    return (
      <text {...attrs}>
        <tspan x={x} dy="8">
          {labels[0]}
        </tspan>
        <tspan x={x} dy="15">
          {labels[1]}
        </tspan>
      </text>
    );
  };

  const customizedYTick = (props: any) => {
    const { payload, height, width, index, textAnchor, x, y } = props;
    const attrs = {
      height,
      width,
      index,
      textAnchor,
      x,
      y,
      fill: '#ADB3BC',
      fontSize: 12,
      letterSpacing: -0.19,
    };
    const labels = payload.value;
    const formattedLabel = labels / 1000;
    return (
      <text {...attrs}>
        <tspan y={y}>
          {labels > 1000 || labels < -1000 ? `${formattedLabel}K` : labels}
        </tspan>
      </text>
    );
  };

  const CustomTooltip = (chartItem: any) => {
    let markups;
    if (chartItem.active) {
      if (!chartItem.payload) return null;
      markups = chartItem.payload[0]?.payload;
      if (!markups) return null;
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>{markups.year}</p>
        </div>
      );
    }
    return null;
  };

  const getAxisLabel = (data: Data) => {
    const XAxisLabel = `${moment()
      .set({ year: data.year, month: data.month })
      .format('MMM')}`;
    return XAxisLabel as any;
  };

  const getFormattedGraphData = (graph: Data[]) => {
    const parsedGraph: Data[] = JSON.parse(JSON.stringify(graph));
    const formattedGraph = parsedGraph.map((item) => {
      item.month = getAxisLabel(item);
      return item;
    });
    return formattedGraph;
  };

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    if (!value) return null;

    return (
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#4461CB"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontSize: 13,
        }}
      >
        {value}
      </text>
    );
  };

  const graphData = getFormattedGraphData(props.graphData);
  const years = Array.from(new Set(graphData.map((d) => d.year)));
  const dividerPosition = 50 + (years.length - 1) * 25 + 25 / 2;

  return (
    <ResponsiveContainer width="90%" height={250}>
      <BarChart
        // width={200}
        data={graphData}
        barCategoryGap={'20%'}
        margin={{
          top: 40,
          right: 40,
          left: 0,
          bottom: 5,
        }}
      >
        {/* <YAxis tick={customizedYTick} axisLine={false} tickLine={false} /> */}
        {/* <Tooltip cursor={{ fill: 'transparent' }} content={CustomTooltip} /> */}
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <Bar
          isAnimationActive={false}
          dataKey="headcount"
          barSize={34}
          fill="#4461CB"
          radius={[6, 6, 0, 0]}
        >
          <LabelList dataKey="headcount" content={renderCustomizedLabel} />
        </Bar>

        <XAxis
          dataKey="month"
          padding={{ left: 15, right: 100 }}
          tickMargin={10}
          height={43}
          tick={customizedTick}
          axisLine={false}
          tickLine={false}
        />
        {/* {years.map((year) => {
          const aa = graphData.filter((d) => d.year === year);

          return (
            <Bar
              key={year}
              dataKey="headcount"
              barSize={34}
              radius={[6, 6, 0, 0]}
              style={{ stroke: '#fff', strokeWidth: 0 }}
              data={graphData.filter((d) => d.year === year) as any}
            />
          );
        })} */}

        {/* <Customized
          position={dividerPosition}
          component={() => (
            <div style={{ height: '100%', borderLeft: '1px solid #ccc' }} />
          )}
        /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default HeadcountChart;
