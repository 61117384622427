import React from 'react';

type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const FilterIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="People-V1"
          transform="translate(-1355.000000, -83.000000)"
          stroke="#818385"
          strokeWidth="1.2"
        >
          <g id="Group-5" transform="translate(265.000000, 75.000000)">
            <g
              id="Group-15-Copy-4"
              transform="translate(1076.000000, 0.000000)"
            >
              <g id="Group-11" transform="translate(0.000000, 6.000000)">
                <g id="Group" transform="translate(14.750001, 3.333335)">
                  <path
                    d="M2.98743521,0.512561875 C3.67085104,1.19597771 3.67085104,2.30401937 2.98743521,2.98743521 C2.30401937,3.67085104 1.19597771,3.67085104 0.512561875,2.98743521 C-0.170853958,2.30401937 -0.170853958,1.19597771 0.512561875,0.512561875 C1.19597771,-0.170853958 2.30401937,-0.170853958 2.98743521,0.512561875"
                    id="Path"
                  ></path>
                  <line
                    x1="9.91666521"
                    y1="1.74999854"
                    x2="3.49999854"
                    y2="1.74999854"
                    id="Path"
                  ></line>
                  <path
                    d="M9.98742354,6.34590687 C10.6708394,7.02932271 10.6708394,8.13736437 9.98742354,8.82078021 C9.30400771,9.50419604 8.19596604,9.50419604 7.51255021,8.82078021 C6.82913437,8.13736437 6.82913437,7.02932271 7.51255021,6.34590687 C8.19596604,5.66249104 9.30400771,5.66249104 9.98742354,6.34590687"
                    id="Path"
                  ></path>
                  <line
                    x1="0.583331875"
                    y1="7.58333187"
                    x2="6.99999854"
                    y2="7.58333187"
                    id="Path"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};