import {
  addFolder,
  COMPONENT_STATE,
  deleteFile,
  deleteFolder,
  FileType,
  FILE_TABLE_HEADERS,
  Folder,
  FolderPayload,
  FOLDER_TABLE_HEADERS,
  getDateFormat,
  getErrorMessages,
  getFilesFromFolder,
  getFolders,
  humanFileSize,
  updateFolder,
  uploadFile,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  FileIcon,
  FolderIcon,
  KeyboardArrowRightIcon,
  NoDataContent,
  PlusIcon,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
  UploadIcon,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PeopleAddEditFolder from '../people-add-edit-folder/people-add-edit-folder';
import styles from './people-files.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PeopleFilesProps {}

export function PeopleFiles(props: PeopleFilesProps) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [employeeID, setEmployeeID] = useState(currentUser.employee_detail.id);
  const [folderList, setFolderList] = useState<Folder[]>([{} as Folder]);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [componentState, setComponentState] = useState(LOADING);
  const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
  const { showConfirmation } = useContext(ConfirmationContext);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const headerContent =
    selectedFolder && !isEditing
      ? FILE_TABLE_HEADERS()
      : FOLDER_TABLE_HEADERS();

  const getInitialFolders = useCallback(async () => {
    const id = Number(history.location.pathname.split('/').pop());
    let res;
    try {
      if (id) {
        setEmployeeID(id);
        res = await getFolders(id);
      } else {
        res = await getFolders(employeeID);
      }
      setFolderList(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [employeeID, history.location.pathname]);

  useEffect(() => {
    getInitialFolders();
  }, [getInitialFolders]);

  const openFolder = async (folder: Folder) => {
    setComponentState(LOADING);
    setSelectedFolder(folder);
    try {
      const response = await getFilesFromFolder(folder.id, employeeID);
      setFileList(response.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
    // }
  };

  const handleFolderSubmit = async (folder: FolderPayload) => {
    try {
      if (isEditing && selectedFolder) {
        const FolderPayload = { id: selectedFolder.id, name: folder.name };
        const response = await updateFolder(
          selectedFolder.id,
          FolderPayload,
          employeeID
        );
        if (response.status === 200) {
          const updateFolderList = folderList.map((f) => {
            if (f.id === response.data.id) {
              return response.data;
            }
            return f;
          });
          setFolderList(updateFolderList);
          showSnackbar(true, {
            msg: userMsgs().files.edit,
            severity: 'success',
          });
          setSelectedFolder(null);
          setIsEditing(false);
        }
      } else {
        const response = await addFolder(folder, employeeID);
        if (response.status === 200) {
          setFolderList([...folderList, response.data]);
          showSnackbar(true, {
            msg: userMsgs().files.add,
            severity: 'success',
          });
          setAddFolderDialogOpen(false);
        }
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setAddFolderDialogOpen(false);
      setIsEditing(false);
    }
  };

  const handleUploadFile = async (files: FileList) => {
    if (selectedFolder) {
      showSnackbar(false);
      try {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFile(
          selectedFolder.id,
          formData,
          employeeID
        );
        if (response.status === 200) {
          setFileList([...fileList, response.data]);
          showSnackbar(true, {
            msg: userMsgs().files.add,
            severity: 'success',
          });
        }
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    }
  };

  const handleNavClick = () => {
    setSelectedFolder(null);
    setFileList([]);
  };

  const handleDialogClose = () => {
    setAddFolderDialogOpen(false);
    setSelectedFolder(null);
    setIsEditing(false);
  };

  const onDeleteFolder = async (folder: Folder) => {
    try {
      const response = await deleteFolder(folder.id, employeeID);
      if (response.status === 200) {
        const updateFolderList = folderList.filter((f) => f.id !== folder.id);
        setFolderList(updateFolderList);
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().files.folderDelete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onDeleteFile = async (file: FileType) => {
    try {
      const response = await deleteFile(file.id, employeeID);
      if (response.status === 200) {
        const updateFileList = fileList.filter((f) => f.id !== file.id);
        setFileList(updateFileList);
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().files.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const folderDeleteConfirmation = (folder: Folder) => {
    showConfirmation(true, {
      header: t('people.folder.folderDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteFolder(folder),
    });
  };

  const fileDeleteConfirmation = (file: FileType) => {
    showConfirmation(true, {
      header: t('people.file.fileDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteFile(file),
    });
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getFolderList = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, folder: Folder) => {
      if (dropId === 'edit') {
        setSelectedFolder(folder);
        setIsEditing(true);
      } else if (dropId === 'delete') {
        folderDeleteConfirmation(folder);
      }
    };
    return folderList.map((item, index) => {
      return (
        <TableListRow
          className={styles.trow}
          key={index}
          onClick={() => openFolder(item)}
        >
          <TableListCell
            className={clsx(styles.nameCol, styles.cell)}
            key="name"
          >
            <Button
              className={styles.folderBtn}
              ghost
              variant="text"
              color="default"
            >
              <FolderIcon className={styles.folderIcon} />
              <Text variant="body1">{item.name}</Text>
            </Button>
          </TableListCell>
          <TableListCell
            className={clsx(styles.modifiedCol, styles.cell)}
            key="modified"
          >
            <Text variant="body1">
              {getDateFormat(item.updated_at, 'MM/DD/YYYY')}
            </Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.cell, styles.actionCol)}
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown
              buttonClassName={styles.actionContent}
              data={dropdownItems}
              onItemClick={(drpId) => onListAction(drpId, item)}
            />
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getFileList = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, file: FileType) => {
      if (dropId === 'delete') {
        fileDeleteConfirmation(file);
      }
    };
    return fileList.map((item, index) => {
      return (
        <TableListRow
          className={styles.trow}
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            window.open(item.path_url, '_blank');
          }}
        >
          <TableListCell
            className={clsx(styles.fileNameCol, styles.cell)}
            key="name"
          >
            <Button
              className={styles.nameBtn}
              ghost
              variant="text"
              color="default"
            >
              <FileIcon className={styles.fileIcon} />
              <Text variant="body1">{item.name}</Text>
            </Button>
          </TableListCell>
          <TableListCell
            className={clsx(styles.sizeCol, styles.cell)}
            key="owner"
          >
            <Text variant="body1">{humanFileSize(Number(item.file_size))}</Text>
          </TableListCell>
          <TableListCell
            className={clsx(styles.cell, styles.actionCol)}
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown
              buttonClassName={styles.actionContent}
              data={dropdownItems}
              onItemClick={(drpId) => onListAction(drpId, item)}
            />
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = headerContent.map((item) => 90);
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          hasActionBtn={true}
          rowStyles={[styles.trow]}
        />
      );
    } else {
      if (selectedFolder && !isEditing) {
        if (!fileList.length) {
          return (
            <div className={styles.blankListWrap}>
              <NoDataContent
                title={t('label.files')}
                desc={t('people.file.fileNoData.desc')}
                icon={<FileIcon className={styles.noDataIcon} />}
                containerClassName={styles.blankList}
              />
            </div>
          );
        }
        return getFileList();
      } else {
        if (!folderList.length) {
          return (
            <div className={styles.blankListWrap}>
              <NoDataContent
                title={t('label.folder')}
                desc={t('people.folder.folderNoData.desc')}
                icon={<FolderIcon className={styles.noDataIcon} />}
                containerClassName={styles.blankList}
              />
            </div>
          );
        }
        return getFolderList();
      }
    }
  };

  const getFolderSection = () => {
    return (
      <div>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.documents')}
          </Text>
          <div className={styles.rightCol}>
            {selectedFolder && !isEditing ? (
              <Button
                className={styles.uploadBtn}
                variant="text"
                onClick={(e) => e.stopPropagation()}
              >
                <label htmlFor="photo" className={styles.label}>
                  <input
                    // accept="image/*"
                    style={{ display: 'none' }}
                    id="photo"
                    name="photo"
                    type="file"
                    multiple={false}
                    onChange={(e) => {
                      if (e.target.files) handleUploadFile(e.target.files);
                    }}
                  />
                  <UploadIcon className={styles.plusIcon} /> {t('label.upload')}
                </label>
              </Button>
            ) : (
              <Button
                className={styles.addItemBtn}
                variant="text"
                onClick={() => setAddFolderDialogOpen(true)}
              >
                <PlusIcon className={styles.plusIcon} /> {t('label.addFolder')}
              </Button>
            )}
          </div>
        </div>
        {selectedFolder && !isEditing && (
          <div className={styles.folderNav} onClick={handleNavClick}>
            {selectedFolder.name}
            <KeyboardArrowRightIcon className={styles.navIcon} />
          </div>
        )}
        <div className={styles.tableWrap}>
          <TableList className={styles.table}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
      </div>
    );
  };

  const getAddFolderDialog = () => {
    if (addFolderDialogOpen || isEditing) {
      return (
        <PeopleAddEditFolder
          open={addFolderDialogOpen || isEditing}
          onClose={handleDialogClose}
          onSubmit={handleFolderSubmit}
          folder={isEditing && selectedFolder ? selectedFolder : ({} as Folder)}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className={styles.files}>{getFolderSection()}</div>
      {getAddFolderDialog()}
    </>
  );
}

export default PeopleFiles;
