import { toUpperCase } from './../utils/common-utils';
import { t } from 'i18next';
import { PeopleNavList } from '../types';

export const PEOPLE_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.person').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'title',
    label: t('label.jobTitle').toUpperCase(),
    className: 'titleCol',
  },
  {
    id: 'phone',
    label: t('label.phone').toUpperCase(),
    className: 'phoneCol',
  },
  {
    id: 'email',
    label: t('label.workEmail').toUpperCase(),
    className: 'emailCol',
  },
  {
    id: 'department',
    label: t('label.department').toUpperCase(),
    className: 'departmentCol',
  },
  {
    id: 'division',
    label: t('label.division').toUpperCase(),
    className: 'divisionCol',
  },
  {
    id: 'location',
    label: t('label.location').toUpperCase(),
    className: 'locationCol',
  },
];

export const PEOPLE_ACCESS_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.person').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'contact',
    label: t('label.contact').toUpperCase(),
    className: 'contactCol',
  },
  {
    id: 'people',
    label: t('label.people').toUpperCase(),
    className: 'peopleCol',
  },
  {
    id: 'reimbursement',
    label: t('label.reimbursements').toUpperCase(),
    className: 'reimbursementCol',
  },
  {
    id: 'time',
    label: t('label.time').toUpperCase(),
    className: 'timeCol',
  },
  {
    id: 'dimension',
    label: t('label.dimensions').toUpperCase(),
    className: 'dimensionCol',
  },
];

export const PEOPLE_NAV_LIST: { path: string; name: PeopleNavList }[] = [
  {
    path: '/profile',
    name: 'Overview',
  },
  {
    path: '/compensations',
    name: 'Compensation',
  },
  {
    path: '/emergency-contact',
    name: 'Emergency',
  },
  {
    path: '/time-off',
    name: 'Time off',
  },
  // {
  //   path: '/performance',
  //   name: 'Performance',
  // },
  {
    path: '/files',
    name: 'Files',
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
  },
];

export const JOB_INFO_TABLE_HEADERS = () => [
  {
    id: 'title',
    label: t('label.jobTitle').toUpperCase(),
    className: 'titleCol',
  },
  {
    id: 'department',
    label: t('label.department').toUpperCase(),
    className: 'departmentCol',
  },
  {
    id: 'division',
    label: t('label.division').toUpperCase(),
    className: 'divisionCol',
  },
  {
    id: 'location',
    label: t('label.location').toUpperCase(),
    className: 'locationCol',
  },
  {
    id: 'effective_date',
    label: t('label.hireDate').toUpperCase(),
    className: 'dateCol',
  },
];

export const DIRECT_REPORT_TABLE_HEADERS = [
  {
    id: 'name',
    label: 'NAME',
    className: 'nameCol',
  },
  {
    id: 'title',
    label: 'JOB TITLE',
    className: 'titleCol',
  },
  {
    id: 'department',
    label: 'DEPARTMENT',
    className: 'departmentCol',
  },
];

export const COMPENSATION_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.effectiveDate').toUpperCase(),
    className: 'dateCol',
  },
  {
    id: 'amount',
    label: t('label.compensation').toUpperCase(),
    className: 'compensationCol',
  },
  {
    id: 'frequency',
    label: t('label.frequency').toUpperCase(),
    className: 'frequencyCol',
  },
];

export const EMERGENCY_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.name').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'relationship',
    label: t('label.relationship').toUpperCase(),
    className: 'relationshipCol',
  },
  {
    id: 'phone',
    label: t('label.phone').toUpperCase(),
    className: 'phoneCol',
  },
  {
    id: 'email',
    label: t('label.email').toUpperCase(),
    className: 'emailCol',
  },
];

export const FOLDER_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.name').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'modified',
    label: t('label.modified').toUpperCase(),
    className: 'modifiedCol',
  },
];
export const FILE_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.name'),
    className: 'fileNameCol',
  },
  {
    id: 'size',
    label: t('label.size'),
    className: 'sizeCol',
  },
];

export const ONBOARDING_TABLE_HEADERS = [
  {
    id: 'task',
    label: 'Task',
    className: 'taskCol',
  },
  {
    id: 'category',
    label: 'CATEGORY',
    className: 'categoryCol',
  },
  {
    id: 'date',
    label: 'HIRE DATE',
    className: 'dateCol',
  },
  {
    id: 'assignee',
    label: 'ASSIGNEE',
    className: 'assigneeCol',
  },
];

export const TIME_HISTORY_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date').toUpperCase(),
    className: 'dateCol',
  },
  {
    id: 'description',
    label: t('label.description').toUpperCase(),
    className: 'descriptionCol',
  },
  {
    id: 'used',
    label: t('label.used').toUpperCase(),
    className: 'usedCol',
  },
  {
    id: 'status',
    label: t('label.status').toUpperCase(),
    className: 'statusCol',
  },
];

export const PEOPLE_TIME_APPROVAL_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date').toUpperCase(),
    className: 'dateCol',
  },
  {
    id: 'user',
    label: t('label.user').toUpperCase(),
    className: 'userCol',
  },
  {
    id: 'description',
    label: t('label.description').toUpperCase(),
    className: 'descriptionCol',
  },
  {
    id: 'approved',
    label: t('label.approvedOn').toUpperCase(),
    className: 'approvedCol',
  },
  {
    id: 'used',
    label: t('label.used').toUpperCase(),
    className: 'usedCol',
  },
  {
    id: 'action',
    label: t('label.action').toUpperCase(),
    className: 'actionCol',
  },
];

export const EXPENSE_HEADERS = [
  {
    id: 'date',
    label: 'DATE',
    className: 'dateCol',
  },
  {
    id: 'person',
    label: 'PERSON',
    className: 'personCol',
  },
  {
    id: 'name',
    label: 'NAME',
    className: 'nameCol',
  },
  {
    id: 'notes',
    label: 'DESCRIPTION',
    className: 'notesCol',
  },
  {
    id: 'amount',
    label: 'AMOUNT',
    className: 'amountCol',
  },
  {
    id: 'status',
    label: 'STATUS',
    className: 'statusCol',
  },
];

export const TIME_OFF_RANGE_TABLE_HEADERS = () => [
  {
    id: 'from_date',
    label: t('label.date'),
    className: 'dateCol',
  },
  {
    id: 'type',
    label: t('label.typeAndDescription'),
    className: 'projectCol',
  },
  {
    id: 'person',
    label: t('label.person'),
    className: 'personCol',
  },
  {
    id: 'hours',
    label: t('label.hours'),
    className: 'hoursCol',
  },
];

export const TRAINING_TABLE_HEADERS = [
  {
    id: 'training_on',
    label: 'Date',
    className: 'dateCol',
  },
  {
    id: 'name',
    label: 'Name',
    className: 'phoneCol',
  },
  {
    id: 'hours',
    label: 'Hours',
    className: 'hoursCol',
  },
];
