import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const CompanyIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3a2 2 0 0 1 2-2h3l5.2 3.9a2 2 0 0 1 .8 1.6V15h-9a2 2 0 0 1-2-2V3ZM12.5 15h2a2 2 0 0 0 2-2v-2.882a1 1 0 0 0-.553-.894L12.5 7.5M6 11.5h2M6 8.5h2M6 5.5h2"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
