import {
  appRoute,
  Authentication,
  getAccessToken,
  getErrorMessages,
  login,
  setAuthenticationDetails,
  userMsgs,
  validateEmail,
} from '@spovio/shared';
import {
  Button,
  Divider,
  FormControl,
  FormText,
  Input,
  SpovioLogoBlackIcon,
  Text,
} from '@spovio/ui';
import { ErrorMessage, Formik, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useTranslation } from 'react-i18next';

import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './login.module.scss';

type LoginFormValues = Authentication;

type LoginProps = RouteComponentProps;

export function Login(props: LoginProps) {
  const { t } = useTranslation();
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  const responseGoogleSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    //todo
  };

  const responseGoogleFailure = (error: { details: string }) => {
    //todo
  };

  const handleSubmit = async (values: LoginFormValues) => {
    try {
      const res = await login(values);
      setAuthenticationDetails({ ...res.data });
      const { default_account } = res.data;
      if (!default_account) {
        props.history.push(`/create-profile`);
      } else {
        props.history.push(`/${default_account}/`);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { email_or_password_incorrect } = userMsgs();
      const msg = getErrorMessages(err, {
        no_active_account: email_or_password_incorrect,
      });
      setLoginErrorMessage(msg);
    }
  };

  const handleFocus = () => {
    setLoginErrorMessage('');
  };

  useEffect(() => {
    const token = getAccessToken();
    if (token && localStorage.getItem('defaultAccount')) {
      props.history.push(appRoute.getRoute('/'));
    }
  }, []);

  return (
    <div className={styles.root}>
      <SpovioLogoBlackIcon className={styles.title} />
      <div className={styles.innerContent}>
        <Text variant="h1" className={styles.heading}>
          {t('login.welcomeToSpovio')}
        </Text>
        <Text variant="caption" className={styles.helperText}>
          {t('login.signInToYourAccountToContinue')}
        </Text>
        <div className={styles.loginForm}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={({ email, password }) => {
              const errors: FormikErrors<LoginFormValues> = {};
              const { emailNotEntered, emailNotValid, passwordNotEntered } =
                userMsgs();
              if (!email) {
                errors.email = emailNotEntered;
              } else if (validateEmail(email)) {
                errors.email = emailNotValid;
              }
              if (!password) {
                errors.password = passwordNotEntered;
              }
              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              isValid,
              touched,
              errors,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl gutter="l" className={styles.form}>
                  <Input
                    placeholder={t('label.email')}
                    type="email"
                    name="email"
                    status={
                      (errors.email && touched.email) || loginErrorMessage
                        ? 'invalid'
                        : undefined
                    }
                    autoComplete="on"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    value={values.email}
                    size="l"
                  />
                  <ErrorMessage name="email">
                    {(errorMessage) => (
                      <FormText type="invalid">{errorMessage}</FormText>
                    )}
                  </ErrorMessage>
                  <FormText type="invalid">{loginErrorMessage}</FormText>
                </FormControl>
                <FormControl gutter="l">
                  <Input
                    name="password"
                    placeholder={t('label.password')}
                    type="password"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    size="l"
                    status={
                      errors.password && touched.password
                        ? 'invalid'
                        : undefined
                    }
                    value={values.password}
                    append={
                      <div className={styles.suffixWrap}>
                        <Divider
                          className={styles.restDivider}
                          type="vertical"
                        />
                        <NavLink
                          to={'/password/reset'}
                          className={styles.suffixInfo}
                        >
                          {t('label.forgot')} ?
                        </NavLink>
                      </div>
                    }
                  />
                  <ErrorMessage name="password">
                    {(errorMessage) => (
                      <FormText type="invalid">{errorMessage}</FormText>
                    )}
                  </ErrorMessage>
                </FormControl>
                <Button
                  className={styles.signIn}
                  size={'l'}
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  fullWidth
                >
                  {t('login.signIn')}
                </Button>
              </form>
            )}
          </Formik>
        </div>
        {/* <div className={styles.option}>
          Don’t have an account?
          <NavLink to="/register" className={styles.linkText}>
            Sign up
          </NavLink>
        </div> */}
      </div>
    </div>
  );
}

export default withRouter(Login);
