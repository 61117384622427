// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".headcount-chart_customTooltip__3NTyz {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  padding: 15px 20px;\n  border-radius: 4px;\n}\n.headcount-chart_customTooltip__3NTyz p {\n  padding: 0;\n  margin: 0;\n  font-size: 13px;\n}\n.headcount-chart_customTooltip__3NTyz p + p {\n  margin-top: 6px;\n}\n.headcount-chart_customTooltip__3NTyz .headcount-chart_label__jJAZ8 {\n  color: #333333;\n  font-weight: bold;\n}\n.headcount-chart_customTooltip__3NTyz .headcount-chart_rate__1Bxpr {\n  color: #356aec;\n}\n.headcount-chart_customTooltip__3NTyz .headcount-chart_number__2_jWf {\n  font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"customTooltip": "headcount-chart_customTooltip__3NTyz",
	"label": "headcount-chart_label__jJAZ8",
	"rate": "headcount-chart_rate__1Bxpr",
	"number": "headcount-chart_number__2_jWf"
};
module.exports = exports;
