import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import {
  BasicFilter,
  Company,
  CompanyListType,
  CompanyPayload,
  Contact,
  ContactListType,
  ContactPayload,
  ContactsFilter,
  Tag,
  TagListType,
  TagPayload,
} from '../types';

//Tags
export const getTags = (
  filters?: BasicFilter
): Promise<AxiosResponse<TagListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return axios.get(`/contacts/tag/?${queryParams}`);
};

export const getBasicTagsList = (): Promise<AxiosResponse<Tag[]>> => {
  return axios.get(`/contacts/tag/basic-list/`);
};

export const getTagDetails = (tagId: string): Promise<AxiosResponse<Tag>> => {
  return axios.get(`/contacts/tag/${tagId}/`);
};

export const createTag = (tag: TagPayload): Promise<AxiosResponse<Tag>> => {
  return axios.post(`/contacts/tag/`, tag);
};

export const updateTag = (
  id: string,
  tag: TagPayload
): Promise<AxiosResponse<Tag>> => {
  return axios.patch(`/contacts/tag/${id}/`, tag);
};

export const deleteTag = (id: number): Promise<AxiosResponse<null>> => {
  return axios.delete(`/contacts/tag/${id}/`);
};

//Companies
export const getCompanies = (
  filters?: BasicFilter
): Promise<AxiosResponse<CompanyListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return axios.get(`/contacts/company/?${queryParams}`);
};

export const getBasicCompaniesList = (): Promise<AxiosResponse<Company[]>> => {
  return axios.get(`/contacts/company/basic-list/`);
};

export const getCompanyDetails = (
  companyId: string
): Promise<AxiosResponse<Company>> => {
  return axios.get(`/contacts/company/${companyId}`);
};

export const createCompany = (
  company: CompanyPayload
): Promise<AxiosResponse<Company>> => {
  return axios.post(`/contacts/company/`, company);
};

export const updateCompany = (
  id: string,
  company: CompanyPayload
): Promise<AxiosResponse<Company>> => {
  return axios.patch(`/contacts/company/${id}/`, company);
};

export const deleteCompany = (id: number): Promise<AxiosResponse<null>> => {
  return axios.delete(`/contacts/company/${id}/`);
};

//contacts
export const getContacts = (
  filters?: ContactsFilter
): Promise<AxiosResponse<ContactListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return axios.get(`/contacts/contact/?${queryParams}`);
};

export const getContactDetails = (
  contactId: string
): Promise<AxiosResponse<Contact>> => {
  return axios.get(`/contacts/contact/${contactId}/`);
};

export const createContact = (
  contact: ContactPayload
): Promise<AxiosResponse<Contact>> => {
  return axios.post(`/contacts/contact/`, contact);
};

export const updateContact = (
  id: string,
  contact: ContactPayload
): Promise<AxiosResponse<Contact>> => {
  return axios.patch(`/contacts/contact/${id}/`, contact);
};

export const deleteContact = (id: number): Promise<AxiosResponse<null>> => {
  return axios.delete(`/contacts/contact/${id}/`);
};
