import {
  account,
  appRoute,
  currency,
  getDefaultAccount,
  getDefaultOrgDetails,
  getErrorMessages,
  getUserProfile,
  initializeAxiosConfig,
  MAPPED_MODULE_ACCESS,
  ModuleAccess,
  MODULES,
  Modules,
  MODULE_ACCESS,
} from '@spovio/shared';
import { Spin, useCurrentUser, useSnackbar } from '@spovio/ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import { SideBar } from '../components/sidebar/sidebar';
import i18n from '../i18n';
import { AppRoute } from '../routes';
import { privateAppRoutes } from '../routes/private-routes';

interface MatchParams {
  orgId?: string;
}

export type AppProps = RouteComponentProps<MatchParams>;

export const App = (props: AppProps) => {
  const { setCurrentUser, currentUser } = useCurrentUser();
  const [isAppReadyToRender, setIsAppReadyToRender] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const { params } = props.match;
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const orgId = params.orgId
    ? parseInt(params.orgId, 10)
    : parseInt(getDefaultAccount() || '', 10);

  useEffect(() => {
    if (isMounted) {
      const default_account = getDefaultOrgDetails();
      if (!default_account) {
        history.push('/create-profile');
      } else {
        getInitialApiCalls();
      }
    }
    return () => setIsMounted(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    try {
      const res = await getUserProfile();
      const currentUser = res.data.user;
      account.set(res.data.accounts);
      i18n.changeLanguage(currentUser.language);
      if (res.data.user?.currentAccount) {
        currency.set(res.data.user.currentAccount.currency);
      }
      moment.locale(currentUser.language);
      setCurrentUser(currentUser);
      setIsAppReadyToRender(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const status = error?.response?.status;
      if (status === 401) {
        props.history.push('/login');
        setIsAppReadyToRender(false);
      }
    }
  };

  const getInitialApiCalls = async () => {
    initializeAxiosConfig(orgId);
    try {
      await getProfile();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  if (!orgId) {
    return <Redirect to={'/login'} />;
  } else if (!isAppReadyToRender) {
    return <Spin size="full" />;
  }

  const isSidebarActive = () => {
    const path = history.location.pathname;
    const isMatching =
      !!matchPath(
        history.location.pathname,
        appRoute.getRoute('/people/add')
      ) ||
      !!matchPath(
        history.location.pathname,
        appRoute.getRoute('/people/:id/edit')
      );
    return !isMatching;
  };

  const isAuthenticated = (module: Modules, role?: string) => {
    if (currentUser.is_owner && role === 'owner') {
      return true;
    }
    if (module) {
      const requiredPermission: ModuleAccess = MAPPED_MODULE_ACCESS[
        module
      ] as ModuleAccess;
      if (
        currentUser[requiredPermission] === 'no_access' &&
        module !== 'settings'
      ) {
        return false;
      } else if (
        role &&
        currentUser[requiredPermission] !== 'admin' &&
        ['admin', 'owner'].includes(role)
      ) {
        return false;
      }
    } else if (module === 'settings' && role === 'owner') {
      return !!currentUser.is_owner;
    }
    return true;
  };

  const firstPermittedModule = (): string => {
    const modules: Modules[] = MODULES;
    const access = MODULE_ACCESS;
    const permittedModule = access.find(
      (access) => currentUser[access] !== 'no_access'
    );
    if (permittedModule)
      // return modules[access.indexOf(permittedModule)];
      switch (modules[access.indexOf(permittedModule)]) {
        case 'people':
          return '/people/directory';
        case 'dimensions':
          return '/dimensions/department';
        case 'time':
          return '/time/timesheet/day';
        case 'reimbursements':
          return '/reimbursements/list';
        case 'contacts':
          return '/contacts/list';
      }
    return '/settings/directory';
  };

  // const filterPermittedRoutes = () => {}

  return (
    <>
      {isSidebarActive() && <SideBar />}
      <Switch>
        {privateAppRoutes.map((route) => (
          <AppRoute
            key={route.path}
            path={appRoute.getPath(route.path)}
            component={route.component}
            needsAuth
            showComponent={isAppReadyToRender}
            exact={route.exact}
            isAuthenticated={isAuthenticated(
              route.module as Modules,
              route.role
            )}
            module={route.module}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            layout={route?.layout as any}
            componentProps={route.props}
          />
        ))}
        <Route
          exact
          path={appRoute.getPath('/')}
          render={() => (
            <Redirect to={appRoute.getRoute(firstPermittedModule())} />
          )}
        />
      </Switch>
    </>
  );
};

export default App;
