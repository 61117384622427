import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const DollarOutlinedIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
        stroke="#A2A5A8"
        strokeWidth="1.2"
      />
      <path
        d="M8 11.662v0c0 .92.746 1.666 1.667 1.666h.666c.921 0 1.667-.746 1.667-1.666 0-2.334-4-1-4-3.334 0-.92.746-1.666 1.667-1.666h.666c.921 0 1.667.746 1.667 1.666"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M10 5.808v.731M10 13.401v.82"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
