import React, { ReactNode } from 'react';

import MuiAutocomplete, {
  createFilterOptions,
  AutocompleteGetTagProps,
  AutocompleteCloseReason,
} from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import clsx from 'clsx';

import { Input, InputProps } from '../input/input';
import { Spin } from '../spin/spin';
import styles from './autocomplete.module.scss';
import { COMPONENT_STATE } from '@spovio/shared';
import { KeyboardArrowDownIcon } from '../icons';
import { useTranslation } from 'react-i18next';
export interface AutoCompleteOptions {
  name: string;
  data?: any;
}
export interface AutocompleteProps
  extends Pick<InputProps, 'size' | 'noStyle' | 'placeholder'> {
  id?: string;
  limitTags?: number;
  label?: string;
  className?: string;
  inputClassName?: string;
  icon?: ReactNode;
  multiple?: boolean;
  value?: AutoCompleteOptions[] | AutoCompleteOptions;
  defaultValue?: AutoCompleteOptions[] | AutoCompleteOptions;
  inputValue?: string;
  popupIcon?: boolean;
  open?: boolean;
  loading?: COMPONENT_STATE;
  freeSolo?: boolean;
  disabled?: boolean;
  autoInputFocus?: boolean;
  showOptionalAdd?: boolean;
  options: AutoCompleteOptions[];
  disableCloseOnSelect?: boolean;
  disablePortal?: boolean;

  onChange: (e: any, value: any) => void;
  renderOption?: (options: AutoCompleteOptions) => React.ReactElement;
  groupBy?: (option: AutoCompleteOptions) => string;
  onOpen?: () => void;
  getOptionSelected?: (
    options: AutoCompleteOptions,
    value: AutoCompleteOptions
  ) => boolean;
  filterOptions?: (
    options: AutoCompleteOptions[],
    params: FilterOptionsState<AutoCompleteOptions>
  ) => AutoCompleteOptions[];
  onInputChange?: (e: any, value: any, reason: string) => void;
  onClose?: (e: any, reason: AutocompleteCloseReason) => void;
  renderTags?: (
    value: AutoCompleteOptions[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const { t } = useTranslation();
  const {
    value,
    inputValue,
    open,
    defaultValue,
    options,
    freeSolo,
    multiple,
    popupIcon,
    limitTags,
    loading,
    disabled,
    noStyle,
    id,
    label,
    className,
    inputClassName,
    icon,
    placeholder,
    size,
    disableCloseOnSelect,
    disablePortal,
    renderOption,
    filterOptions,
    getOptionSelected,
    onInputChange,
    onChange,
    onOpen,
    onClose,
    groupBy,
    renderTags,
  } = props;
  const getFilterOptions = (options: AutoCompleteOptions[], params: any) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    return filtered as AutoCompleteOptions[];
  };
  const getDefaultRenderOption = (option: AutoCompleteOptions) => {
    const { name } = option;
    return <div className={styles.autoCompleteItem}>{name}</div>;
  };

  const getNoData = () => (
    <div className={styles.noData}>{t('extension.noDataFound')}</div>
  );
  const getPopupIcon = () => {
    return popupIcon ? (
      <KeyboardArrowDownIcon className={styles.popupIcon} />
    ) : null;
  };
  return (
    <MuiAutocomplete
      value={value}
      inputValue={inputValue}
      autoHighlight
      openOnFocus
      id={id}
      debug
      open={open}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      disablePortal={disablePortal}
      options={options}
      multiple={multiple}
      disableClearable
      freeSolo={freeSolo}
      closeIcon={false}
      limitTags={limitTags}
      defaultValue={defaultValue}
      loadingText={<Spin />}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      groupBy={groupBy}
      renderTags={renderTags}
      popupIcon={getPopupIcon()}
      noOptionsText={getNoData()}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.name}
      loading={loading === COMPONENT_STATE.LOADING}
      getOptionDisabled={(option) => option.data && option.data.disabled}
      filterOptions={filterOptions || getFilterOptions}
      renderOption={renderOption || getDefaultRenderOption}
      getOptionSelected={
        getOptionSelected
          ? getOptionSelected
          : (option, value) => option.name === value.name
      }
      classes={{
        root: clsx(styles.autoComplete, className, {
          [styles.multiple]: multiple,
          [styles.noStyle]: noStyle,
        }),
        popperDisablePortal: styles.popperDisablePortal,
        option: styles.autocompleteOptions,
      }}
      renderInput={(params: any) => {
        return (
          <Input
            {...params}
            placeholder={placeholder}
            className={clsx(inputClassName, styles.autoCompleteInput)}
            inputClassName={inputClassName}
            label={label}
            icon={icon}
            size={size}
            noStyle={props.multiple ?? noStyle}
            inputProps={{
              ...params.inputProps,
              id: id || 'autocompleteInput',
              autoComplete: 'off',
            }}
          />
        );
      }}
    />
  );
};
