// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reimbursement-request-details_header__1n67C .reimbursement-request-details_title__fnc3J {\n  color: #252526;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: -0.25px;\n  line-height: 28px;\n}\n.reimbursement-request-details_header__1n67C .reimbursement-request-details_closeBtn__1aRiV {\n  margin-right: 10px;\n}\n\n.reimbursement-request-details_blankListWrap__3aZSO {\n  min-height: 230px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.reimbursement-request-details_blankListWrap__3aZSO .reimbursement-request-details_blankList__O9KRm {\n  text-align: left;\n}\n.reimbursement-request-details_blankListWrap__3aZSO .reimbursement-request-details_approvalIcon__2cX3s {\n  height: 32px;\n  width: 32px;\n  color: #090a11;\n  margin-bottom: 8px;\n}\n\n.reimbursement-request-details_submit__1LyU5 {\n  padding: 30px;\n}\n.reimbursement-request-details_submit__1LyU5 :first-child {\n  margin-right: 20px;\n}\n\n.reimbursement-request-details_avatarText__2SZfl {\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.2228571475px;\n  text-align: left;\n  text-transform: capitalize;\n  margin-left: 5px;\n}\n\n.reimbursement-request-details_avatarWrap__2j3Hv {\n  display: flex;\n  align-items: center;\n}\n\n.reimbursement-request-details_avatarDateWrap__1kebF {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  padding: 10px 0;\n}", ""]);
// Exports
exports.locals = {
	"header": "reimbursement-request-details_header__1n67C",
	"title": "reimbursement-request-details_title__fnc3J",
	"closeBtn": "reimbursement-request-details_closeBtn__1aRiV",
	"blankListWrap": "reimbursement-request-details_blankListWrap__3aZSO",
	"blankList": "reimbursement-request-details_blankList__O9KRm",
	"approvalIcon": "reimbursement-request-details_approvalIcon__2cX3s",
	"submit": "reimbursement-request-details_submit__1LyU5",
	"avatarText": "reimbursement-request-details_avatarText__2SZfl",
	"avatarWrap": "reimbursement-request-details_avatarWrap__2j3Hv",
	"avatarDateWrap": "reimbursement-request-details_avatarDateWrap__1kebF"
};
module.exports = exports;
