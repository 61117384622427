// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".age-breakdown_title__NE1Vr {\n  color: #24252a;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: -0.22px;\n  line-height: 24px;\n  padding: 20px 40px 0;\n}\n\n.age-breakdown_pie__2lZZ1 {\n  padding-left: 20px;\n}", ""]);
// Exports
exports.locals = {
	"title": "age-breakdown_title__NE1Vr",
	"pie": "age-breakdown_pie__2lZZ1"
};
module.exports = exports;
