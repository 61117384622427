import axios from 'axios';

import { appRoute } from '../app-config';
import { appInfo } from '../constants';
import { Credentials, ModuleAccess } from '../types';

export const setAuthenticationDetails = (data: Credentials) => {
  setAccessToken(data.access);
  localStorage.setItem('refreshtoken', data.refresh);
  setDefaultOrgDetails(data.default_account);
};

export const setAccessToken = (access: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;
  localStorage.setItem('accesstoken', access);
};

export const setDefaultOrgDetails = (defaultAccount: number | null) => {
  axios.defaults.baseURL = `${appInfo.baseURL}${`/${defaultAccount}`}`;
  if (defaultAccount) {
    localStorage.setItem('defaultAccount', `${defaultAccount}`);
    appRoute.setOrgId(defaultAccount);
  }
};

export const getDefaultOrgDetails = () => {
  return localStorage.getItem('defaultAccount');
};

export const setLoggedUserEmail = (email: string) => {
  localStorage.setItem('email', email);
};

//retrieve auth details

export const getAccessToken = () => {
  return localStorage.getItem('accesstoken');
};

export const getDefaultAccount = () => {
  return localStorage.getItem('defaultAccount');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshtoken');
};

// remove auth details

export const removeAuthenticationDetails = () => {
  removeAccessToken();
  localStorage.removeItem('refreshtoken');
  removeDefaultOrgDetails();
  window.location.href = '/';
};
export const removeAccessToken = () => {
  localStorage.removeItem('accesstoken');
};
export const removeDefaultOrgDetails = () => {
  axios.defaults.baseURL = '';
  localStorage.removeItem('defaultAccount');
  appRoute.setOrgId('');
};
