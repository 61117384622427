import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const EyeIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
        stroke="#828385"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8c-1.26 1.994-3.521 4-6 4S3.259 9.994 2 8c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"
        stroke="#828385"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
