import { account, appRoute, useModuleAccess } from '@spovio/shared';
import {
  AccountSettingsIcon,
  Avatar,
  AvatarSize,
  Divider,
  KeyboardArrowDownIcon,
  LogoutIcon,
  PlusIcon,
  Popper,
  Profile,
  useCurrentUser,
} from '@spovio/ui';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './user-profile-dropdown.module.scss';

interface UserProfileDropdownProps {
  onLogout: () => void;
  toggleCreateWorkspace: () => void;
  handleSwitchWorkspace: (id: number) => void;
}
export const UserProfileDropdown = ({
  onLogout,
  toggleCreateWorkspace,
  handleSwitchWorkspace,
}: UserProfileDropdownProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const hasPeopleAccess = useModuleAccess('people_access').isAdmin;

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => {
    setOpen(false);
  };
  const { isRestricted } = useModuleAccess('people_access');

  const getHeaderSection = () => {
    const fullName = currentUser.name;
    return (
      // <UserInfoCard user={currentUser} className={styles.userCard} />
      <div className={clsx(styles.userWrapper, styles.userCard)}>
        <div className={styles.avatarWrap}>
          <Avatar
            url={currentUser.pic}
            name={fullName}
            size={AvatarSize.avatar24}
            className={styles.userIcon}
            circle
          />
        </div>
        <div className={styles.userDetails}>
          <div className={styles.userName}>{fullName}</div>
          <div className={styles.userEmail}>{currentUser.email}</div>
        </div>
      </div>
    );
  };

  const getWorkspaceMarkup = () => {
    const accounts = account.get();
    return (
      <div className={styles.navPills}>
        {accounts.map((account, index) => (
          <div
            className={clsx(
              styles.workspace,
              styles.navPills,
              account.id === appRoute.getOrgId() && styles.active
            )}
            key={index}
            onClick={() => handleSwitchWorkspace(account.id)}
          >
            <Avatar
              className={styles.userMenuItemImage}
              textWrapClassName={styles.userMenuItemTextWrap}
              size={AvatarSize.avatar22}
              name={account.name}
              displayName
              url={account.logo}
            />
          </div>
        ))}
      </div>
    );
  };

  const getNavPillsSection = () => (
    <div className={styles.navPills}>
      {currentUser.is_account_creatable && (
        <span className={styles.navPillsItem} onClick={toggleCreateWorkspace}>
          <PlusIcon />
          Create an account
        </span>
      )}
      {/* {!isRestricted && (
        <NavLink
          activeClassName={styles.active}
          to={appRoute.getRoute(
            '/people/profile/' + currentUser.employee_detail.id
          )}
          onClick={() => setOpen(false)}
        >
          <span className={styles.navPillsItem}>
            <Profile />
            Profile
          </span>
        </NavLink>
      )} */}
      <NavLink
        activeClassName={styles.active}
        to={appRoute.getRoute('/settings/account')}
        onClick={() => setOpen(false)}
      >
        <span className={styles.navPillsItem}>
          <AccountSettingsIcon />
          {t('settings.accountSettings')}
        </span>
      </NavLink>
    </div>
  );

  const renderButton = () => {
    const fullName = currentUser.name;
    return (
      <button
        className={clsx(styles.userProfileBtn, open && styles.active)}
        onClick={handleToggle}
      >
        {open ? (
          <KeyboardArrowDownIcon className={styles.arrowDownIcon} />
        ) : null}
        <div className={styles.avatarWrap}>
          <Avatar
            name={fullName}
            circle
            size={AvatarSize.avatar24}
            url={currentUser.pic}
          />
        </div>
      </button>
    );
  };

  return (
    <div className={styles.root}>
      <Popper
        paperClassName={styles.paper}
        open={open}
        renderButton={renderButton}
        anchorOrigin="right"
        onClose={handleClose}
      >
        <div className={styles.container}>
          {getHeaderSection()}
          {/* <Divider /> */}
          {getWorkspaceMarkup()}
          <Divider />
          {getNavPillsSection()}
          <Divider />
          <div className={clsx(styles.navPills, styles.logout)}>
            <button className={styles.navPillsItem} onClick={onLogout}>
              <LogoutIcon /> {t('extension.logOut')}
            </button>
          </div>
        </div>
      </Popper>
    </div>
  );
};
