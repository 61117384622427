import React, { ForwardedRef, forwardRef } from 'react';

import clsx from 'clsx';

import styles from './table-list-row.module.scss';

type TableListRowProps = React.HTMLAttributes<HTMLElement>;

const Row = ({ className, children, ...props }: TableListRowProps, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
  return (
    <div ref={ref} className={clsx(styles.root, className)} {...props}>
      {children}
    </div>
  );
};

export const TableListRow = forwardRef(Row);
