import { USERROLECHOICES } from '@spovio/shared';
import ContactCompanyDetails from '../components/contacts/companies/contact-company-details/contact-company-details';
import ContactCompanyList from '../components/contacts/companies/contact-company-list/contact-company-list';
import ContactDetails from '../components/contacts/contacts/contact-details/contact-details';
import ContactList from '../components/contacts/contacts/contact-list/contact-list';
import ContactTagDetails from '../components/contacts/tags/contact-tag-details/contact-tag-details';
import ContactTagList from '../components/contacts/tags/contact-tag-list/contact-tag-list';
import Department from '../components/dimensions/department/department';
import Division from '../components/dimensions/division/division';
import Location from '../components/dimensions/location/location';
import Project from '../components/dimensions/project/project';
import { AppLayout } from '../components/layouts/app-layout/app-layout';
import { FullScreenLayout } from '../components/layouts/full-screen-layout/full-screen-layout';
import PeopleAdd from '../components/people/people-add/people-add';
import PeopleCalendar from '../components/people/people-calendar/people-calendar';
import PeopleDirectory from '../components/people/people-directory/people-directory';
import PeopleEdit from '../components/people/people-edit/people-edit';
import PeopleFiles from '../components/people/people-files/people-files';
import PeopleProfile from '../components/people/people-profile/people-profile';
import PeopleTimeOffApprove from '../components/people/people-time-off-approve/people-time-off-approve';
import PeopleTimeOffReport from '../components/people/people-time-off-report/people-time-off-report';
import PeopleTimeOffType from '../components/people/people-time-off-type/people-time-off-type';
import SalaryHistory from '../components/reports/salary-history/salary-history';
import MileagesList from '../components/reimbursement/reimbursement/reimbursement-list/reimbursement-list';
import ReimbursementRequestDetails from '../components/reimbursement/requests/reimbursement-request-details/reimbursement-request-details';
import ReimbursementRequestList from '../components/reimbursement/requests/reimbursement-request-list/reimbursement-request-list';
import AccountSettings from '../components/settings/account-settings/account-settings';
import ChangePassword from '../components/settings/change-password/change-password';
import languageSettings from '../components/settings/language-settings/language-settings';
import SettingsDirectory from '../components/settings/settings-directory/settings-directory';
import TimeApprovalDetail from '../components/time/time-approval-detail/time-approval-detail';
import TimeApproval from '../components/time/time-approval/time-approval';
import TimeIncompleteWeek from '../components/time/time-incomplete-week/time-incomplete-week';
import TimeReportSummary from '../components/time/time-report-summary/time-report-summary';
import TimeReports from '../components/time/time-reports/time-reports';
import { Timesheet } from '../components/time/timesheet/timesheet';
import GenderProfile from '../components/reports/gender-profile/gender-profile';
import Birthdays from '../components/reports/birthdays/birthdays';
import Headcount from '../components/reports/headcount/headcount';

export const privateAppRoutes = [
  //home
  // {
  //   path: '/home',
  //   component: Home,
  //   layout: AppLayout,
  //   exact: true,
  // },
  {
    path: '/people/directory',
    component: PeopleDirectory,
    layout: AppLayout,
    exact: true,
    module: 'people',
  },
  {
    path: '/people/add',
    component: PeopleAdd,
    layout: FullScreenLayout,
    exact: true,
    module: 'people',
  },
  {
    path: '/people/:id/edit',
    component: PeopleEdit,
    layout: FullScreenLayout,
    exact: true,
    module: 'people',
  },
  {
    path: '/people/approve',
    component: PeopleTimeOffApprove,
    layout: AppLayout,
    exact: true,
    module: 'people',
    role: USERROLECHOICES.ADMIN,
  },
  {
    path: '/people/time-off-type',
    component: PeopleTimeOffType,
    layout: AppLayout,
    module: 'people',
    role: USERROLECHOICES.ADMIN,
  },
  {
    path: '/people/time-off-report',
    component: PeopleTimeOffReport,
    layout: AppLayout,
    module: 'people',
    role: USERROLECHOICES.ADMIN,
  },
  {
    path: '/people/calendar',
    component: PeopleCalendar,
    layout: AppLayout,
    exact: true,
    module: 'people',
  },
  {
    path: '/people',
    component: PeopleProfile,
    layout: AppLayout,
    module: 'people',
  },
  {
    path: '/files',
    component: PeopleFiles,
    layout: AppLayout,
    exact: true,
    module: 'people',
  },

  //Dimensions
  {
    path: '/dimensions/department',
    component: Department,
    layout: AppLayout,
    exact: true,
    module: 'dimensions',
  },
  {
    path: '/dimensions/division',
    component: Division,
    layout: AppLayout,
    exact: true,
    module: 'dimensions',
  },
  {
    path: '/dimensions/location',
    component: Location,
    layout: AppLayout,
    exact: true,
    module: 'dimensions',
  },
  {
    path: '/dimensions/project',
    component: Project,
    layout: AppLayout,
    exact: true,
    module: 'dimensions',
  },

  //Times
  {
    path: '/time/timesheet',
    component: Timesheet,
    layout: AppLayout,
    module: 'time',
  },
  {
    path: '/time/reports',
    component: TimeReports,
    layout: AppLayout,
    exact: true,
    module: 'time',
  },
  {
    path: '/time/reports-summary',
    component: TimeReportSummary,
    layout: AppLayout,
    exact: true,
    module: 'time',
  },
  {
    path: '/time/approval',
    component: TimeApproval,
    layout: AppLayout,
    exact: true,
    module: 'time',
  },
  {
    path: '/time/approval/:id',
    component: TimeApprovalDetail,
    layout: AppLayout,
    exact: true,
    module: 'time',
  },
  {
    path: '/time/incomplete-weeks/',
    component: TimeIncompleteWeek,
    layout: AppLayout,
    exact: true,
    module: 'time',
  },

  //Settings
  {
    path: '/settings/directory',
    component: SettingsDirectory,
    layout: AppLayout,
    exact: true,
    module: 'settings',
    role: USERROLECHOICES.ADMIN,
  },
  {
    path: '/settings/archived-users',
    component: SettingsDirectory,
    layout: AppLayout,
    exact: true,
    props: {
      archived: true,
    },
    module: 'settings',
    role: USERROLECHOICES.ADMIN,
  },
  {
    path: '/settings/account',
    component: AccountSettings,
    layout: AppLayout,
    exact: true,
    module: 'settings',
    role: USERROLECHOICES.OWNER,
  },
  {
    path: '/settings/change-password',
    component: ChangePassword,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/settings/change-language',
    component: languageSettings,
    layout: AppLayout,
    exact: true,
  },

  //Reimbursements
  {
    path: '/reimbursements/list',
    component: MileagesList,
    layout: AppLayout,
    module: 'reimbursements',
    // exact: true,
  },
  {
    path: '/reimbursements/requests',
    component: ReimbursementRequestList,
    layout: AppLayout,
    module: 'reimbursements',
    exact: true,
  },
  {
    path: '/reimbursements/requests/:id',
    component: ReimbursementRequestDetails,
    layout: AppLayout,
    module: 'reimbursements',
  },
  //Contacts
  {
    path: '/contacts/list/:id/view',
    component: ContactDetails,
    layout: AppLayout,
    module: 'contacts',
  },
  {
    path: '/contacts/list',
    component: ContactList,
    layout: AppLayout,
    module: 'contacts',
  },
  {
    path: '/contacts/companies/:id/view',
    component: ContactCompanyDetails,
    layout: AppLayout,
    module: 'contacts',
    exact: true,
  },
  {
    path: '/contacts/companies',
    component: ContactCompanyList,
    layout: AppLayout,
    module: 'contacts',
  },
  {
    path: '/contacts/tag/:id',
    component: ContactTagDetails,
    layout: AppLayout,
    module: 'contacts',
    exact: true,
  },
  {
    path: '/contacts/tags',
    component: ContactTagList,
    layout: AppLayout,
    module: 'contacts',
  },

  {
    path: '/reports/gender-profile',
    component: GenderProfile,
    layout: AppLayout,
    exact: true,
    module: 'reports',
    // role: USERROLECHOICES.OWNER,
  },
  {
    path: '/reports/salary-history',
    component: SalaryHistory,
    layout: AppLayout,
    exact: true,
    module: 'reports',
    // role: USERROLECHOICES.OWNER,
  },
  {
    path: '/reports/age-profile',
    component: GenderProfile,
    layout: AppLayout,
    exact: true,
    module: 'reports',
    // role: USERROLECHOICES.OWNER,
  },

  {
    path: '/reports/birthdays',
    component: Birthdays,
    layout: AppLayout,
    exact: true,
    module: 'reports',
    // role: USERROLECHOICES.OWNER,
  },
  {
    path: '/reports/headcount',
    component: Headcount,
    layout: AppLayout,
    exact: true,
    module: 'reports',
    // role: USERROLECHOICES.OWNER,
  },
];
