import {
  addReimbursement,
  COMPONENT_STATE,
  getDateFormat,
  getErrorMessages,
  ReimbursementPayload,
  REIMBURSEMENT_TYPES,
  userMsgs,
} from '@spovio/shared';
import { useSnackbar } from '@spovio/ui';
import { useState } from 'react';
import MileageForm, {
  ReimbursementForm,
} from '../reimbursement-form/reimbursement-form';
import styles from './reimbursement-add-dialog.module.scss';

export interface ReimbursementAddDialogProps {
  onAddMileage: () => void;
}
const { LOADED, LOADING } = COMPONENT_STATE;
export function ReimbursementAddDialog({
  onAddMileage,
}: ReimbursementAddDialogProps) {
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();

  const initialValues: ReimbursementPayload = {
    name: '',
    miles: 0,
    rate: 0,
    date: getDateFormat(new Date(), 'MM/DD/YYYY'),
    amount: 0,
    reimbursement_type: REIMBURSEMENT_TYPES[0],
  } as ReimbursementPayload;

  const handleSubmit = async (values: FormData) => {
    try {
      const res = await addReimbursement(values);
      if (res.status === 201) {
        const reimbursement_type = values.get('reimbursement_type');
        showSnackbar(true, {
          msg:
            reimbursement_type === 'EXPENSE'
              ? userMsgs().expense.add
              : userMsgs().mileage.add,
          severity: 'success',
        });
      }
      onAddMileage();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  return (
    <div>
      <ReimbursementForm
        open
        onClose={() => {
          return null;
        }}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default ReimbursementAddDialog;
