import {
  DepartmentItem,
  DivisionItem,
  getDateFormat,
  getDepartments,
  getDivisions,
  getErrorMessages,
  getLocations,
  JobInfo,
  JobInfoPayload,
  LocationItem,
  validateForm,
} from '@spovio/shared';
import {
  DatePickerPopover,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Select,
  useSnackbar,
} from '@spovio/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './people-add-job-info.module.scss';

export interface PeopleAddJobInfoProps {
  open: boolean;
  jobInfo: JobInfo;
  onClose: () => void;
  onSubmit: (value: JobInfoPayload) => void;
}

export function PeopleAddJobInfo({
  open,
  jobInfo,
  onClose,
  onSubmit,
}: PeopleAddJobInfoProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState({} as JobInfoPayload);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [divisions, setDivisions] = useState<DivisionItem[]>([]);
  const [locations, setLocations] = useState<LocationItem[]>([]);
  const [dateString, setDateString] = useState<string>(
    getDateFormat(new Date())
  );
  const [date, setDate] = useState<string>(
    getDateFormat(new Date(), 'MM/DD/YYYY')
  );
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setIsModalVisible(open);
    getInitialLists();
    fillForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getInitialLists = async () => {
    try {
      const departments = await getDepartments();
      setDepartments(departments.data.results);
      const divisions = await getDivisions();
      setDivisions(divisions.data.results);
      const locations = await getLocations();
      setLocations(locations.data.results);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const fillForms = async () => {
    if (jobInfo && jobInfo.department) {
      setValues({
        ...jobInfo,
        department: jobInfo.department.id,
        division: jobInfo.division.id,
        location: jobInfo.location.id,
        manager: jobInfo.manager.id,
      });
      setDate(getDateFormat(jobInfo.effective_date, 'MM/DD/YYYY'));
      setDateString(getDateFormat(jobInfo.effective_date));
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit({ ...values, effective_date: dateString });
  };

  const handleSelect = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const handleChange = (date: any) => {
    const _date = getDateFormat(date);
    setDateString(_date);
    setDate(date);
  };

  const handleClearSelect = (field: string) => {
    setValues({ ...values, [field]: '' });
  };

  const departmentOptions = departments.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });
  const divisionOptions = divisions.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });
  const locationOptions = locations.map((item) => {
    return { label: item.name, value: `${item.id}` };
  });

  const isValid = () => {
    return validateForm.isRequired(values.title);
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={
          jobInfo.department
            ? t('people.editJobInformation')
            : t('people.addJobInformation')
        }
        action={t('label.save')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        isActionDisabled={!isValid()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true} className={styles.label}>
            <Input
              name="title"
              required
              validationType={['required']}
              labelClassName={styles.label}
              label={t('label.title')}
              placeholder={t('label.title')}
              value={values.title}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              label={t('label.department')}
              className={styles.label}
            />
            <Select
              options={departmentOptions}
              onSelect={(value) => handleSelect('department', value)}
              selectedOption={
                values.department
                  ? {
                      label:
                        departmentOptions.find(
                          (department) =>
                            department.value === values.department?.toString()
                        )?.label ?? '',
                      value: `${values.department}`,
                    }
                  : undefined
              }
              showResetIcon
              onClear={() => handleClearSelect('department')}
              placeholder={t('dimensions.selectDepartment')}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel label={t('label.division')} className={styles.label} />
            <Select
              options={divisionOptions}
              onSelect={(value) => handleSelect('division', value)}
              selectedOption={
                values.division
                  ? {
                      label:
                        divisionOptions.find(
                          (division) =>
                            division.value === values.division?.toString()
                        )?.label ?? '',
                      value: `${values.division}`,
                    }
                  : undefined
              }
              placeholder={t('dimensions.selectDivision')}
              showResetIcon
              onClear={() => handleClearSelect('division')}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel label={t('label.location')} className={styles.label} />
            <Select
              options={locationOptions}
              onSelect={(value) => handleSelect('location', value)}
              selectedOption={
                values.location
                  ? {
                      label:
                        locationOptions.find(
                          (location) =>
                            location.value === values.location?.toString()
                        )?.label ?? '',
                      value: `${values.location}`,
                    }
                  : undefined
              }
              placeholder={t('dimensions.selectLocation')}
              showResetIcon
              onClear={() => handleClearSelect('location')}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              className={styles.label}
              label={t('label.effectiveDate')}
              required
            />
            <DatePickerPopover
              id={'date'}
              size={'m'}
              paperClassName={styles.paper}
              selected={date}
              onChange={handleChange}
              onTextChange={handleChange}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default PeopleAddJobInfo;
