// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".multi-select-user_userMenuItem__lP_F5 {\n  display: flex;\n  color: #333333;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.24px;\n  line-height: 16px;\n  align-items: center;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.multi-select-user_calendarBtn__1U0tj {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n}", ""]);
// Exports
exports.locals = {
	"userMenuItem": "multi-select-user_userMenuItem__lP_F5",
	"calendarBtn": "multi-select-user_calendarBtn__1U0tj"
};
module.exports = exports;
