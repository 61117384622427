import {
  BasicFilter,
  Pagination,
  ProjectItem,
  ProjectItemPayload,
  ProjectList,
} from '../types';
import { stringify } from 'query-string';
import axios, { AxiosResponse } from 'axios';

export const getProjects = async (
  filters?: BasicFilter
): Promise<AxiosResponse<ProjectList>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/dimensions/project/?${queryParams}`);
};

export const createProject = async (
  data: ProjectItemPayload
): Promise<AxiosResponse<ProjectItem>> => {
  const payload = data;
  return await axios.post(`/dimensions/project/`, payload);
};

export const deleteProject = async (
  id: number
): Promise<AxiosResponse<ProjectItem>> => {
  return await axios.delete(`/dimensions/project/${id}/`);
};

export const updateProject = async (
  id: number,
  data: Partial<ProjectItemPayload>
): Promise<AxiosResponse<ProjectItem>> => {
  const payload = data;
  return await axios.patch(`/dimensions/project/${id}/`, payload);
};

export const getProject = async (
  id: string
): Promise<AxiosResponse<ProjectItem>> => {
  return await axios.get(`/dimensions/project/${id}/`);
};

export const getProjectBasicList = async (
  id?: number
): Promise<AxiosResponse<ProjectItem[]>> => {
  let url = `/dimensions/project/basic-list/?`;
  if (id) {
    url += `user_id=${id}`;
  }
  return await axios.get(url);
};
