import {
  appMsgs,
  COMPONENT_STATE,
  getDateFormat,
  getErrorMessages,
  getReimbursement,
  Reimbursement,
  ReimbursementPayload,
  updateReimbursement,
  userMsgs,
} from '@spovio/shared';
import { Spin, useSnackbar } from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, StaticContext } from 'react-router';
import ReimbursementForm from '../reimbursement-form/reimbursement-form';

export interface ReimbursementEditDialogProps {
  onEdit: (data: Reimbursement) => void;
}
const { LOADED, LOADING } = COMPONENT_STATE;

interface MatchParams {
  id?: string;
}

type RouteProps = RouteComponentProps<MatchParams, StaticContext>;

export interface ReimbursementEditDialogProps extends RouteProps {
  onEdit: (data: Reimbursement) => void;
}

export function ReimbursementEditDialog({
  onEdit,
  ...props
}: ReimbursementEditDialogProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADED);
  const [initialValues, setInitialValues] = useState<ReimbursementPayload>(
    {} as ReimbursementPayload
  );
  const [attachment, setAttachment] = useState<string>();
  const { showSnackbar } = useSnackbar();
  const expense_id = Number(props.match.params.id);
  const handleSubmit = async (values: FormData) => {
    try {
      values.append('status', 'PENDING');
      const res = await updateReimbursement(expense_id, values);
      if (res.status === 200) {
        const reimbursement_type = values.get('reimbursement_type');
        showSnackbar(true, {
          msg:
            reimbursement_type === 'EXPENSE'
              ? userMsgs().expense.edit
              : userMsgs().mileage.edit,
          severity: 'success',
        });
      }
      onEdit(res.data);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const fetchData = useCallback(async () => {
    setComponentState(LOADING);
    try {
      const res = await getReimbursement(expense_id);
      const initialValue: ReimbursementPayload = {
        name: res.data.name,
        user: res.data.user.id,
        amount: res.data.amount,
        project: res.data.project.id,
        date: getDateFormat(res.data.date, 'MM/DD/YYYY'),
        notes: res.data.notes,
        miles: res.data.miles,
        rate: res.data.rate,
        reimbursement_type: res.data.reimbursement_type,
      };
      setInitialValues(initialValue);
      setAttachment(res.data.file?.split('?')[0].split('/').splice(-1)[0]);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [expense_id, showSnackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData, props.match.params.id]);
  return componentState === LOADED ? (
    <ReimbursementForm
      open
      action={t('label.save')}
      onClose={() => null}
      title={t('reimbursement.editReimbursement')}
      initialValues={initialValues}
      isEditing={true}
      attachment={attachment}
      onSubmit={handleSubmit}
    />
  ) : (
    <Spin />
  );
}

export default ReimbursementEditDialog;
