import { t } from 'i18next';

export const userMsgs = () => {
  return {
    //passwords
    passwordNotEntered: 'Enter your password.',

    //emails
    emailNotValid: 'Enter a valid email address.',
    emailNotEntered: 'Enter your email.',

    //server error
    email_or_password_incorrect: 'You entered an incorrect email or password.',

    //No default active account
    no_default_account: 'You do not have any active account',

    //No Data
    matchesNotFound: 'No matches found.',

    //people
    people: {
      add: t('messages.people.add'),
      edit: t('messages.people.edit'),
      archive: t('messages.people.archive'),
      unarchive: t('messages.people.unarchive'),
      delete: t('messages.people.delete'),
    },
    expense: {
      add: t('messages.expense.add'),
      edit: t('messages.expense.edit'),
      delete: t('messages.expense.delete'),
    },
    mileage: {
      add: t('messages.mileage.add'),
      edit: t('messages.mileage.edit'),
      delete: t('messages.mileage.delete'),
    },
    jobInfo: {
      add: t('messages.jobInfo.add'),
      edit: t('messages.jobInfo.edit'),
      delete: t('messages.jobInfo.delete'),
    },
    compensation: {
      add: t('messages.compensation.add'),
      edit: t('messages.compensation.edit'),
      delete: t('messages.compensation.delete'),
    },
    contact: {
      add: t('messages.contact.add'),
      edit: t('messages.contact.edit'),
      delete: t('messages.contact.delete'),
    },
    training: {
      add: t('messages.training.add'),
      edit: t('messages.training.edit'),
      delete: t('messages.training.delete'),
    },
    timeOff: {
      add: t('messages.timeOff.add'),
      edit: t('messages.timeOff.edit'),
      delete: t('messages.timeOff.delete'),
      approved: t('messages.timeOff.approved'),
      unlocked: t('messages.timeOff.unlocked'),
    },
    timeOffType: {
      add: t('messages.timeOffType.add'),
      edit: t('messages.timeOffType.edit'),
      delete: t('messages.timeOffType.delete'),
      archive: t('messages.timeOffType.archive'),
      unarchive: t('messages.timeOffType.unarchive'),
    },
    files: {
      add: t('messages.files.add'),
      edit: t('messages.files.edit'),
      delete: t('messages.files.delete'),
      folderAdd: t('messages.files.folderAdd'),
      folderEdit: t('messages.files.folderEdit'),
      folderDelete: t('messages.files.folderDelete'),
    },
    //dimensions
    department: {
      add: t('messages.department.add'),
      edit: t('messages.department.edit'),
      delete: t('messages.department.delete'),
    },
    division: {
      add: t('messages.division.add'),
      edit: t('messages.division.edit'),
      delete: t('messages.division.delete'),
    },
    location: {
      add: t('messages.location.add'),
      edit: t('messages.location.edit'),
      delete: t('messages.location.delete'),
    },
    project: {
      add: t('messages.project.add'),
      edit: t('messages.project.edit'),
      delete: t('messages.project.delete'),
    },

    //contacts
    tags: {
      add: t('messages.tags.add'),
      edit: t('messages.tags.edit'),
      delete: t('messages.tags.delete'),
    },
    company: {
      add: t('messages.company.add'),
      edit: t('messages.company.edit'),
      delete: t('messages.company.delete'),
    },

    // time
    times: {
      unlocked: t('messages.times.unlocked'),
      approved: t('messages.times.approved'),
    },

    //settings
    user: {
      add: t('messages.user.add'),
      edit: t('messages.user.edit'),
      delete: t('messages.user.delete'),
      archived: t('messages.user.archived'),
      restored: t('messages.user.restored'),
      reset: t('messages.user.reset'),
    },
    account: {
      edit: t('messages.account.edit'),
    },
    password: {
      edit: t('messages.password.edit'),
    },
  };
};

export const appMsgs = () => {
  return {
    catch: {
      error: t('messages.catch.error'),
    },
  };
  //CatchBlock
};
