import React from 'react';
import styles from './multi-select-projects.module.scss';
import { COMPONENT_STATE, ProjectItem } from '@spovio/shared';
import { FolderIcon, Text, MenuItemProps } from '@spovio/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import MultiSelect from 'libs/ui/src/lib/MultiSelect/MultiSelect';
import { useTranslation } from 'react-i18next';

export type MultiSelectProjectItems = Pick<ProjectItem, 'id' | 'name'>;

export interface MultiSelectProjectsProps {
  projectList: MultiSelectProjectItems[];
  initialValues?: MenuItemProps['value'][];
  onSelect: (value: any, type?: string) => void;
  isLoading?: COMPONENT_STATE;
  name?: string;
  searchPlaceholder?: string;
  icon?: (props: any) => JSX.Element;
}

function userDropdownList(
  projectList: MultiSelectProjectItems[],
  icon?: (props: any) => JSX.Element
) {
  const updatedProjectList = projectList.map(
    (project: MultiSelectProjectItems) => {
      const Icon = icon || FolderIcon;
      return {
        label: project.name,
        value: project.id,
        component: () => {
          return (
            <div className={styles.userMenuItem}>
              <Icon className={styles.userMenuItemImage} />
              <Text variant="caption" className={styles.userMenuItemText}>
                {project.name}
              </Text>
            </div>
          );
        },
      };
    }
  );
  return updatedProjectList;
}

export default React.forwardRef<MultiSelect, MultiSelectProjectsProps>(
  (props: MultiSelectProjectsProps, ref) => {
    const { t } = useTranslation();
    return (
      <MultiSelect
        icon={props.icon || FolderIcon}
        buttonClassName={styles.calendarBtn}
        isLoading={props.isLoading}
        onSelect={props.onSelect}
        label={props.name || t('label.projects')}
        searchPlaceholder={props.searchPlaceholder || t('label.findProject')}
        items={userDropdownList(props.projectList, props.icon)}
        initialValues={props.initialValues}
        ref={ref}
      />
    );
  }
);
