// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SimpleUserList_simpleTaskUserItemRoot__3GrBR {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: relative;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR.SimpleUserList_default__17WuQ {\n  padding: 0 10px;\n  height: 40px;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR.SimpleUserList_compact__1JkA6 {\n  padding: 0 7px;\n  height: 34px;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR:hover {\n  background-color: #f5f6f7;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_userWrap__2NNmd {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_userWrap__2NNmd .SimpleUserList_avatar__6IcD0 {\n  margin-right: 8px;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_userName__1wzE0 {\n  color: #333333;\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: -0.19px;\n  margin-right: 7px;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_email__1sY8d {\n  color: #8a94a2;\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_email__1sY8d:before {\n  height: 2px;\n  width: 2px;\n  opacity: 0.7;\n  margin-right: 7px;\n  background-color: #8a94a2;\n  content: \"\";\n  position: relative;\n  left: 0;\n  display: flex;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_closeButton__1g7lh {\n  position: absolute;\n  right: 10px;\n  padding: 5px;\n  cursor: pointer;\n  border-radius: 5px;\n  width: 10px;\n  height: 10px;\n}\n.SimpleUserList_simpleTaskUserItemRoot__3GrBR .SimpleUserList_closeButton__1g7lh:hover {\n  background-color: #ffffff;\n}", ""]);
// Exports
exports.locals = {
	"simpleTaskUserItemRoot": "SimpleUserList_simpleTaskUserItemRoot__3GrBR",
	"default": "SimpleUserList_default__17WuQ",
	"compact": "SimpleUserList_compact__1JkA6",
	"userWrap": "SimpleUserList_userWrap__2NNmd",
	"avatar": "SimpleUserList_avatar__6IcD0",
	"userName": "SimpleUserList_userName__1wzE0",
	"email": "SimpleUserList_email__1sY8d",
	"closeButton": "SimpleUserList_closeButton__1g7lh"
};
module.exports = exports;
