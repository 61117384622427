import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import {
  Expenses,
  ExpensesListType,
  ExpensesPayload,
  PeopleFilter,
} from '../types';

//Expense
export const getExpensesList = async (
  filters?: PeopleFilter
): Promise<AxiosResponse<ExpensesListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/reimbursements/expense/?${queryParams}`);
};

export const getExpenses = async (
  id: number
): Promise<AxiosResponse<Expenses>> => {
  return await axios.get(`/reimbursements/expense/${id}/`);
};

export const getMyExpenseList = async (
  filters?: PeopleFilter
): Promise<AxiosResponse<ExpensesListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/reimbursements/expense/my-list/?${queryParams}`);
};

export const addExpenses = async (
  data: FormData
): Promise<AxiosResponse<Expenses>> => {
  const payload = data;
  return await axios.post(`/reimbursements/expense/`, payload);
};

export const deleteExpenses = async (
  id: number
): Promise<AxiosResponse<null>> => {
  return await axios.delete(`/reimbursements/expense/${id}/`);
};

export const updateExpenses = async (
  id: number,
  payload: FormData
): Promise<AxiosResponse<Expenses>> => {
  return await axios.patch(`/reimbursements/expense/${id}/`, payload);
};

export const approveExpenses = async (
  id: number
): Promise<AxiosResponse<Expenses>> => {
  return await axios.post(`/reimbursements/expense/${id}/approve/`);
};

export const rejectExpenses = async (
  id: number
): Promise<AxiosResponse<Expenses>> => {
  return await axios.post(`/reimbursements/expense/${id}/reject/`);
};

export const unlockExpenses = async (
  id: number
): Promise<AxiosResponse<Expenses>> => {
  return await axios.post(`/reimbursements/expense/${id}/unlock/`);
};
