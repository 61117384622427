/* eslint-disable react/display-name */
import { COMPONENT_STATE, User } from '@spovio/shared';
import { Avatar, AvatarSize, MenuItemProps, PeopleIcon } from '@spovio/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import MultiSelectSelect from 'libs/ui/src/lib/MultiSelect/MultiSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './multi-select-user.module.scss';

export type MultiSelectUserItem = Pick<User, 'id' | 'name' | 'pic'>;
export interface MultiSelectUserProps {
  userList: MultiSelectUserItem[];
  initialValues?: MenuItemProps['value'][];
  onSelect: (value: any, type?: string) => void;
  isLoading?: COMPONENT_STATE;
}

function userDropdownList(userList: MultiSelectUserItem[]) {
  const updatedUserList = userList.map((user: MultiSelectUserItem) => {
    return {
      label: user.name,
      value: user.id,
      component: () => {
        return (
          <div className={styles.userMenuItem}>
            <Avatar
              className={styles.userMenuItemImage}
              size={AvatarSize.avatar26}
              name={user.name}
              displayName
              url={user.pic}
              circle
            />
          </div>
        );
      },
    };
  });
  return updatedUserList;
}
export default React.forwardRef<MultiSelectSelect, MultiSelectUserProps>(
  (props: MultiSelectUserProps, ref) => {
    const { t } = useTranslation();
    return (
      <MultiSelectSelect
        icon={PeopleIcon}
        buttonClassName={styles.calendarBtn}
        isLoading={props.isLoading}
        onSelect={props.onSelect}
        label={t('label.person')}
        searchPlaceholder={t('label.findPerson')}
        items={userDropdownList(props.userList)}
        initialValues={props.initialValues}
        ref={ref}
      />
    );
  }
);
