import React from 'react';

type SearchIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

const SearchIcon: React.FC<SearchIconProps> = (props: SearchIconProps) => {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <g
        transform="translate(3 3)"
        stroke="#6D6971"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeWidth="1.2" cx="5.07" cy="5.07" r="5.07" />
        <path strokeWidth="2.2" strokeLinecap="round" d="M9.041 9.046L12 12" />
      </g>
    </svg>
  );
};
export default SearchIcon;
