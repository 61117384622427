import { CURRENCY_CHOICES } from '../constants';
import { Account, NavItem } from '../types';

interface AppRoute {
  orgId: number | '';
  setOrgId: (id: number | '') => void;
  getOrgId: () => number | '';
  getRoute: (url: string, id?: number) => string;
  getPath: (url: string) => string;
}

export const appRoute: AppRoute = {
  orgId: 0,
  setOrgId(id: number | '') {
    this.orgId = id;
  },
  getOrgId() {
    return this.orgId;
  },
  getRoute(url: string, id?: number) {
    const orgId = id || this.orgId || localStorage.getItem('defaultAccount');
    if (orgId) {
      return `/${orgId}${url}`;
    } else {
      return url;
    }
  },
  getPath(url: string) {
    return `/:orgId${url}`;
  },
};

interface AccountConfigType {
  // eslint-disable-next-line @typescript-eslint/ban-types
  accounts: Account[];
  set(accounts: Account[]): void;
  get(): Account[];
  getCurrent(): Account | undefined;
}

export const account: AccountConfigType = {
  accounts: [],
  set(accounts: Account[]) {
    this.accounts = accounts;
  },
  get() {
    return this.accounts;
  },
  getCurrent() {
    const defaultAccount = localStorage.getItem('defaultAccount');
    const account = this.accounts.find(
      (account) => account.id === Number(defaultAccount)
    );
    return account;
  },
};

interface CurrencyConfigType {
  // eslint-disable-next-line @typescript-eslint/ban-types
  currency: string;
  set(currencies: string): void;
  get(): string;
  getSymbol(): string | undefined;
}

export const currency: CurrencyConfigType = {
  currency: '',
  set(currency: string) {
    this.currency = currency;
  },
  get() {
    return this.currency;
  },
  getSymbol() {
    return CURRENCY_CHOICES.find((item) => item.code === this.currency)?.symbol;
  },
};

interface TagConfigType {
  tagNavList: NavItem[];
  set(tagNavList: NavItem[]): void;
  get(): NavItem[];
}

export const TagNavList: TagConfigType = {
  tagNavList: [],
  set(tagNavList: NavItem[]) {
    this.tagNavList = tagNavList;
  },
  get() {
    return this.tagNavList;
  },
};
