// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contact-details_header__1j4TM .contact-details_title__1z9LD {\n  color: #252526;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: -0.25px;\n  line-height: 28px;\n}\n.contact-details_header__1j4TM .contact-details_closeBtn__1knRd {\n  margin-right: 10px;\n}\n\n@media (min-width: 1024px) {\n  .contact-details_basicInfoTemplate__3UF8U {\n    display: flex;\n  }\n}\n.contact-details_basicInfoTemplate__3UF8U .contact-details_fullScreenDivider__3eFWf {\n  display: none;\n  height: calc(100vh - 70px);\n}\n@media (min-width: 1024px) {\n  .contact-details_basicInfoTemplate__3UF8U .contact-details_fullScreenDivider__3eFWf {\n    display: block;\n  }\n}\n@media (min-width: 1024px) {\n  .contact-details_basicInfoTemplate__3UF8U .contact-details_mobileDivider__bFANS {\n    display: none;\n  }\n}\n.contact-details_basicInfoTemplate__3UF8U .contact-details_contactDetails__13dGZ {\n  width: 100%;\n  padding: 30px 30px 15px 30px;\n  box-sizing: border-box;\n}\n@media (min-width: 1024px) {\n  .contact-details_basicInfoTemplate__3UF8U .contact-details_contactDetails__13dGZ {\n    width: calc(100vw - 540px);\n  }\n}\n.contact-details_basicInfoTemplate__3UF8U .contact-details_contactDetails__13dGZ .contact-details_sections__2apEc {\n  margin-bottom: 50px;\n}\n.contact-details_basicInfoTemplate__3UF8U .contact-details_contactDetails__13dGZ .contact-details_sectionHeader__3fZcv {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n.contact-details_basicInfoTemplate__3UF8U .contact-details_contactDetails__13dGZ .contact-details_sectionHeader__3fZcv .contact-details_heading__3lS1V {\n  margin: 0px;\n}\n.contact-details_basicInfoTemplate__3UF8U .contact-details_contactDetails__13dGZ .contact-details_noData__MNzlM {\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"header": "contact-details_header__1j4TM",
	"title": "contact-details_title__1z9LD",
	"closeBtn": "contact-details_closeBtn__1knRd",
	"basicInfoTemplate": "contact-details_basicInfoTemplate__3UF8U",
	"fullScreenDivider": "contact-details_fullScreenDivider__3eFWf",
	"mobileDivider": "contact-details_mobileDivider__bFANS",
	"contactDetails": "contact-details_contactDetails__13dGZ",
	"sections": "contact-details_sections__2apEc",
	"sectionHeader": "contact-details_sectionHeader__3fZcv",
	"heading": "contact-details_heading__3lS1V",
	"noData": "contact-details_noData__MNzlM"
};
module.exports = exports;
