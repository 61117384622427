import {
  CheckApprovalType,
  COMPONENT_STATE,
  createTime,
  getDurationFromText,
  getErrorMessages,
  getFlattenTimeData,
  getProjectBasicList,
  getTimeFromNumber,
  getTimesByProject,
  ProjectItem,
  submitForApproval,
  TimeEntryInputType,
  TimesDateGroupType,
  TimesItem,
  TimeWeekGroupType,
  unlockTimeSheetsByWeek,
  updateTime,
  WeekDateRange,
  weeklyTimeDelete,
} from '@spovio/shared';
import {
  Button,
  CloseIcon,
  ConfirmationContext,
  NoDataContent,
  PlusIcon,
  Skeleton,
  TimesIcon,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { AddRowProject } from '../add-row-project/add-row-project';
import TimeEntryInput from '../time-entry-input/time-entry-input';
import {
  getTableBodyData,
  getTableFooterData,
  getTimeGroupItem,
  TableBodyData,
} from '../timesheet.utils';
import styles from './time-week-view.module.scss';

/* eslint-disable-next-line */
export interface TimeWeekViewProps extends RouteComponentProps {
  dateRangeProp: WeekDateRange;
  onUpdateTimeEntry: () => void;
  onApproval: (type?: string) => void;
  isAdmin?: boolean;
  weekStatus: CheckApprovalType;
  selectedUser?: number;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function TimeWeekView({
  dateRangeProp,
  onUpdateTimeEntry,
  onApproval,
  isAdmin,
  weekStatus,
  selectedUser,
}: TimeWeekViewProps) {
  const { t } = useTranslation();
  const { showConfirmation } = useContext(ConfirmationContext);
  const { showSnackbar } = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [flattenTimeData, setFlattenTimeData] = useState<any>();
  const [tableBodyData, setTableBodyData] = useState<TableBodyData[]>();
  const [isAddRow, setIsAddRow] = useState<boolean>(false);
  const [approvalStatus, setApprovalStatus] =
    useState<CheckApprovalType>(weekStatus);
  const [componentState, setComponentState] = useState(LOADING);
  const [isApprovalBtn, setIsApprovalBtn] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<ProjectItem[]>(
    [] as ProjectItem[]
  );

  const { currentUser } = useCurrentUser();

  const getTimesByProjectData = async (range?: WeekDateRange) => {
    setComponentState(COMPONENT_STATE.LOADING);
    isApprovalBtn && setIsApprovalBtn(false);
    const rangeData = range || dateRangeProp;
    try {
      const res = await getTimesByProject(
        rangeData.start_date,
        rangeData.end_date,
        selectedUser
      );
      const timesByProject: TimesDateGroupType[] = res.data.time_list;
      const currentProjects: number[] = [];
      timesByProject.forEach((item) => {
        item.times.forEach((time) => {
          currentProjects.push(time.project_id);
        });
      });
      getProjectLists(currentProjects);
      setApprovalStatus({
        is_approved: res.data.is_approved,
        is_submitted: res.data.is_submitted,
      });
      const _flattenTimeData = getFlattenTimeData(timesByProject);
      const _tableBodyData = getTableBodyData(_flattenTimeData);
      setFlattenTimeData(_flattenTimeData);
      setTableBodyData(_tableBodyData);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      setComponentState(COMPONENT_STATE.LOADED);
    }
  };

  const onApprovalClick = async (type?: string) => {
    try {
      const _approvalStatus = approvalStatus as CheckApprovalType;

      if (type === 'unlock') {
        const res = await unlockTimeSheetsByWeek({
          date: dateRangeProp.start_date,
        });
        _approvalStatus.is_approved = false;
      } else {
        const res = await submitForApproval(
          dateRangeProp.start_date,
          dateRangeProp.end_date
        );
        _approvalStatus.is_submitted = true;
      }
      setApprovalStatus(_approvalStatus);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      setIsApprovalBtn(false);
    }
  };

  const getProjectLists = useCallback(
    async (currentProjects: number[]) => {
      const userId = selectedUser || currentUser.id;
      const res = await getProjectBasicList(userId);
      const filteredList = res.data.filter(
        (item: ProjectItem) => !currentProjects.includes(item.id)
      );
      setProjectList(filteredList);
    },
    [currentUser.id, selectedUser]
  );

  useEffect(() => {
    getTimesByProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeProp, selectedUser]);

  const getWeekHeader = () => {
    const weekHeader = [];
    for (let i = 0; i < 7; i++) {
      const date = moment(dateRangeProp.start_date).add(i, 'days');
      weekHeader.push(
        <div className={clsx(styles.weeklyCol, styles.weekDayCol)} key={i}>
          <div className={styles.weekDay}>
            <span>{moment(date).format('ddd')}</span>
            <span>{moment(date).format('MMM DD')}</span>
          </div>
        </div>
      );
    }
    return weekHeader;
  };

  const getWeekHeaderPreloader = () => {
    const weekHeader = [];
    for (let i = 0; i < 7; i++) {
      weekHeader.push(
        <div className={clsx(styles.weeklyCol, styles.weekDayCol)} key={i}>
          <div className={styles.weekDay}>
            <Skeleton variant="rect" width={35} height={15} />
            <Skeleton variant="rect" width={50} height={20} />
          </div>
        </div>
      );
    }
    return weekHeader;
  };

  const updateTimeEntry = async (payload: Partial<TimesItem>) => {
    try {
      const res = await updateTime(payload);
      onUpdateTimeEntry();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const saveTimeEntry = async (payload: Partial<TimesItem>) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _payload: any = payload;
      if (selectedUser) {
        _payload.user = selectedUser;
      }
      const res = await createTime(_payload);
      const time = res.data;
      onUpdateTimeEntry();
      const _flattenTimeData = flattenTimeData;
      _flattenTimeData.push(getTimeGroupItem(time));
      const tableBodyData = getTableBodyData(_flattenTimeData);
      setTableBodyData(tableBodyData);
      setFlattenTimeData(_flattenTimeData);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onTimeEntry = (
    newTotal: number,
    prevTotal: number,
    hasMultipleEntries: boolean,
    id: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveTimePayload: any
  ) => {
    if (hasMultipleEntries) {
      const timeEntry = flattenTimeData.find(
        (time: TimeWeekGroupType) => time.id === id
      );

      let duration = newTotal - prevTotal + timeEntry.hours;
      duration = getDurationFromText(getTimeFromNumber(duration));
      timeEntry.hours = duration;
      const payload = {
        date: timeEntry.date,
        hours: duration,
        id: id,
      };

      updateTimeEntry(payload);
      const tableBodyData = getTableBodyData(flattenTimeData);
      setTableBodyData(tableBodyData);
      setFlattenTimeData(flattenTimeData);
    } else {
      saveTimePayload.hours = getDurationFromText(getTimeFromNumber(newTotal));
      saveTimeEntry(saveTimePayload);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getWeekCols = (row: any) => {
    return Object.keys(row.week).map((key, index) => {
      const day = row.week[key];
      const totalTime = day.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, item: any) => acc + item.hours,
        0
      );
      const date = moment(dateRangeProp.start_date).day(index + 1);

      const saveTimePayLoad = {
        date: moment(date).format('YYYY-MM-DD'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      if (row.project.project_id) {
        saveTimePayLoad.project = {
          id: row.project.project_id,
          name: row.project.name,
        };
      }
      return (
        <div
          className={clsx(styles.weeklyCol, styles.weekDayCol, styles.timeCol)}
          key={index}
        >
          <TimeEntryInput
            type={
              approvalStatus?.is_approved
                ? TimeEntryInputType.LOCKED
                : TimeEntryInputType.DEFAULT
            }
            value={totalTime}
            disabled={row.project?.is_time_off}
            defaultToZero={!!totalTime}
            className={clsx(styles.timeEntryInput, {
              [styles.disabled]: row.project?.is_time_off,
            })}
            onTimeEntry={(value) => {
              const lastTimeEntry = day[day.length - 1];
              const id = lastTimeEntry && lastTimeEntry.id;
              onTimeEntry(
                value,
                totalTime,
                day.length > 0,
                id,
                saveTimePayLoad
              );
            }}
            size={'s'}
          />
        </div>
      );
    });
  };

  const getWeekColsPreloader = () => {
    return Array(7)
      .fill(0)
      .map((key, index) => {
        return (
          <div
            className={clsx(
              styles.preloaderCol,
              styles.weeklyCol,
              styles.weekDayCol,
              styles.timeCol
            )}
            key={index}
          >
            <Skeleton variant="rect" width={50} height={25} />
          </div>
        );
      });
  };

  const onConfirmationDelete = async (item: TableBodyData) => {
    try {
      await weeklyTimeDelete(
        dateRangeProp.start_date,
        item.project.project_id,
        selectedUser || currentUser.id
      );
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      getTimesByProjectData(dateRangeProp);
      showConfirmation(false);
    }
  };

  const onDeleteClick = (item: TableBodyData) => {
    showConfirmation(true, {
      header: t('timesheet.timeEntryDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onConfirmationDelete(item),
    });
  };

  const getListMarkUp = (row: TableBodyData[]) => {
    if (row.length === 0) {
      return (
        <div className={styles.blankListWrap}>
          {!approvalStatus?.is_approved ? (
            <NoDataContent
              title={t('label.time')}
              desc={t('timesheet.noData.desc')}
              icon={<TimesIcon className={styles.timesIcon} />}
              containerClassName={styles.blankList}
              buttonTitle={t('label.addRow')}
              onBtnAction={() => setIsAddRow(true)}
            />
          ) : (
            <NoDataContent
              title="This timesheet has been approved."
              variant="compact"
            />
          )}
        </div>
      );
    }

    return row?.map((item) => {
      const project = item.project;

      return (
        <div
          className={clsx(styles.weeklyheaderInnerWrap, styles.itemListWrap)}
          key={project.project_id}
        >
          <div
            className={clsx(styles.weeklyCol, styles.desc, styles.projectWrap)}
          >
            {project.project_name}
          </div>
          {tableBodyData && getWeekCols(item)}
          <div
            className={clsx(styles.weeklyCol, styles.weekDayCol)}
            key={'blank'}
          >
            <div className={styles.itemTimeTotal}>
              {getTimeFromNumber(item.total)}
            </div>
          </div>
          {!approvalStatus?.is_approved && !project.is_time_off && (
            <div
              className={styles.deleteRowWrap}
              onClick={() => onDeleteClick(item)}
            >
              <CloseIcon />
            </div>
          )}
        </div>
      );
    });
  };

  const getListMarkUpPreloader = () => {
    return Array(3)
      .fill(0)
      .map((item, index) => {
        return (
          <div
            className={clsx(styles.weeklyheaderInnerWrap, styles.itemListWrap)}
            key={index}
          >
            <div
              className={clsx(
                styles.weeklyCol,
                styles.desc,
                styles.projectWrap
              )}
            >
              <Skeleton variant="rect" width={100} height={25} />
            </div>
            {getWeekColsPreloader()}
            <div
              className={clsx(styles.weeklyCol, styles.weekDayCol)}
              key={'blank'}
            >
              <div className={styles.itemTimeTotal}>
                <Skeleton variant="rect" width={50} height={25} />
              </div>
            </div>
          </div>
        );
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTotalTimeListMarkUp = (tableFooterData: any) => {
    const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    return days.map((day, index) => {
      return (
        <div
          className={clsx(
            styles.weeklyCol,
            styles.weekDayCol,
            styles.timeCol,
            styles.timeTotalCol,
            (!tableFooterData[day] || tableFooterData[day] === 0) &&
              styles.timeTotalColZero
          )}
          key={index}
        >
          {componentState === LOADED ? (
            getTimeFromNumber(tableFooterData[day]) || '0:00'
          ) : (
            <Skeleton variant="rect" width={50} height={25} />
          )}
        </div>
      );
    });
  };

  const getWeekFooter = (_tableBodyData: TableBodyData[]) => {
    const tableFooterData = getTableFooterData(tableBodyData);
    return (
      <div className={clsx(styles.weeklyheaderInnerWrap, styles.totalWrap)}>
        <div
          className={clsx(styles.weeklyCol, styles.desc, styles.projectWrap)}
        >
          {!approvalStatus?.is_approved && (
            <Button className={styles.addRow} onClick={() => setIsAddRow(true)}>
              <PlusIcon className={styles.plusIcon} />
              {t('label.addRow')}
            </Button>
          )}
        </div>
        {getTotalTimeListMarkUp(tableFooterData)}
        <div
          className={clsx(
            styles.weeklyCol,
            styles.weekDayCol,
            styles.finalTotal
          )}
          key={'blank'}
        >
          <div className={styles.itemTimeTotal}>
            {componentState === LOADED ? (
              getTimeFromNumber(tableFooterData?.total)
            ) : (
              <Skeleton variant="rect" width={50} height={25} />
            )}
          </div>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onProjectSelect = (value: ProjectItem) => {
    const project = {
      project_id: value.id,
      project_name: value.name,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isSameTask = (element: any) =>
      element.project.project_id === project.project_id;
    if (tableBodyData?.some(isSameTask)) return;

    const _tableBodyData: TableBodyData[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableWeekData: any = {};
    tableWeekData.week = {
      Mo: [],
      Tu: [],
      We: [],
      Th: [],
      Fr: [],
      Sa: [],
      Su: [],
    };
    const totalTime = 0;
    tableWeekData.project = {
      project_id: project.project_id,
      project_name: project.project_name,
    };
    tableWeekData.total = totalTime;
    _tableBodyData.push(tableWeekData);
    const newTableData = tableBodyData && [...tableBodyData, ..._tableBodyData];
    setTableBodyData(newTableData);
    const currentProjects: number[] = [];
    newTableData?.forEach((item) => {
      currentProjects.push(item.project.project_id);
    });
    getProjectLists(currentProjects);
  };

  const getAddRowDialog = () => {
    if (isAddRow) {
      return (
        <AddRowProject
          open={isAddRow}
          onClose={() => setIsAddRow(false)}
          onAdd={onProjectSelect}
          projectList={projectList}
        />
      );
    } else return null;
  };

  // const onApprovalClick = () => {
  //   setIsApprovalBtn(false);
  //   if (approvalStatus) {
  //     const _approvalStatus = approvalStatus;
  //     _approvalStatus.is_submitted = true;
  //     setApprovalStatus(_approvalStatus);
  //     onApproval(approvalStatus.is_approved ? 'unlock': '');
  //   }
  // };

  const getApprovalButton = () => {
    if (componentState === LOADING) {
      return (
        <Button className={styles.approvalBtn}>
          <Skeleton variant="rect" width={150} height={26} />
        </Button>
      );
    }

    const submited = !(
      approvalStatus?.is_submitted && !approvalStatus?.is_approved
    );
    const approved = approvalStatus?.is_approved;

    if (approved && !isAdmin) {
      return null;
    }

    if (isApprovalBtn) {
      return (
        <div className={styles.approvalConfirmWrap}>
          {approved
            ? t('timesheet.thisWillUnlockEntireTimesheet')
            : submited
            ? t('timesheet.submitThisTimesheet')
            : t('timesheet.resubmitThisTimesheet')}
          <Button
            className={clsx(approved && styles.danger, styles.submitBtn)}
            size={'s'}
            onClick={() =>
              onApprovalClick(approvalStatus.is_approved ? 'unlock' : '')
            }
          >
            {t('label.yes') + ', '}
            {approved
              ? t('label.unlock')
              : submited
              ? t('label.submit')
              : t('label.resubmit')}{' '}
            {t('label.timesheet').toLowerCase()}
          </Button>
          <Button
            className={styles.cancelSubmitBtn}
            size={'s'}
            onClick={() => setIsApprovalBtn(false)}
          >
            {t('label.cancel')}
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          className={clsx(approved && styles.withdraw, styles.approvalBtn)}
          onClick={() => setIsApprovalBtn(true)}
        >
          {approved
            ? t('timesheet.withdrawApproval')
            : submited
            ? t('timesheet.submitWeekForApproval')
            : t('timesheet.resubmitWeekForApproval')}
        </Button>
      );
    }
  };

  const getApprovedWrap = () => {
    return <div className={styles.approvedWrap}>Approved week</div>;
  };

  return (
    <div className={styles.weeklyViewWrap}>
      {approvalStatus?.is_approved && getApprovedWrap()}
      <div className={styles.viewWrap}>
        <div className={styles.viewInnerWrap}>
          <div className={styles.weeklyheaderWrap}>
            <div className={styles.weeklyheaderInnerWrap}>
              <div
                className={clsx(
                  styles.weeklyCol,
                  styles.desc,
                  styles.projectWrap
                )}
              ></div>
              {componentState === LOADING
                ? getWeekHeaderPreloader()
                : getWeekHeader()}
              <div
                className={clsx(styles.weeklyCol, styles.weekDayCol)}
                key={'blank'}
              >
                <div className={styles.weekDay}></div>
              </div>
            </div>
          </div>
          <div className={styles.weeklybodyWrap}>
            <div className={styles.weeklybodyInnerWrap}>
              {componentState === LOADING
                ? getListMarkUpPreloader()
                : tableBodyData && getListMarkUp(tableBodyData)}
              {tableBodyData && getWeekFooter(tableBodyData)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.approvalWrap}>
        <div className={styles.approvalBtnWrap}>{getApprovalButton()}</div>
      </div>
      {getAddRowDialog()}
    </div>
  );
}

export default TimeWeekView;
