import { validateForm } from '@spovio/shared';
import { Dialog, FormControl, Input } from '@spovio/ui';
import { useState } from 'react';
import styles from './add-workspace.module.scss';

/* eslint-disable-next-line */
export interface AddWorkspaceProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (name: string) => void;
}

export function AddWorkspace({ open, onClose, onSubmit }: AddWorkspaceProps) {
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [name, setName] = useState('');

  const handleClose = () => {
    setIsModalVisible(false);
    onClose && onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit(name);
  };

  const isValid = () => {
    return validateForm.isRequired(name);
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={'Add Account'}
        action={'Add'}
        open={isModalVisible}
        maxWidth="sm"
        isActionDisabled={!isValid()}
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="name"
              label={'Name'}
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default AddWorkspace;
