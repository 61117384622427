import {
  appRoute,
  CheckApprovalType,
  COMPONENT_STATE,
  deleteTime,
  getErrorMessages,
  getThisWeekRange,
  getTimeFromNumber,
  getTimesByWeek,
  getTimeSummary,
  OverallSummaryItem,
  submitForApproval,
  SwitchTabItem,
  TimeListByDate,
  TimesByWeekItem,
  TimeWeekGroupType,
  unlockTimeSheetsByWeek,
  User,
  USERROLECHOICES,
  WeekDateRange,
} from '@spovio/shared';
import {
  ArrowDualNav,
  Button,
  CalendarIcon,
  ConfirmationContext,
  DatePickerPopover,
  Header,
  PlusIcon,
  SelectPeoplePopover,
  Skeleton,
  SummaryStatusCard,
  SwitchNavButton,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import { t } from 'i18next';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import TimeAdd from './time-add/time-add';
import { TimeDayView } from './time-day-view/time-day-view';
import { TimeWeekView } from './time-week-view/time-week-view';
import styles from './timesheet.module.scss';

/* eslint-disable-next-line */
export type TimesheetProps = RouteComponentProps;

const today = moment().format('YYYY-MM-DD');
const { LOADED, LOADING } = COMPONENT_STATE;

export function Timesheet(props: TimesheetProps) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const isAdmin = currentUser?.time_access === USERROLECHOICES.ADMIN;

  const [isWeekView, setIsWeekView] = useState(
    props.location.pathname.includes('week')
  );
  const [timeSummary, setTimeSummary] = useState<OverallSummaryItem>(
    {} as OverallSummaryItem
  );
  const [timesByWeek, setTimesByWeek] = useState<TimesByWeekItem>(
    {} as TimesByWeekItem
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  );
  const [dateRange, setDateRange] = useState<WeekDateRange>(getThisWeekRange());
  const [selectedTimeList, setSelectedTimeList] = useState<TimeWeekGroupType[]>(
    [] as TimeWeekGroupType[]
  );
  const [currentTotalHours, setCurrentTotalHours] = useState<number>(0);
  const [isTimeAdd, setIsTimeAdd] = useState<boolean>(false);
  const [isTimeEdit, setIsTimeEdit] = useState<boolean>(false);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState<
    TimeWeekGroupType | undefined
  >();
  const [approvalStatus, setApprovalStatus] = useState<CheckApprovalType>();
  const [isApprovalBtn, setIsApprovalBtn] = useState<boolean>(false);
  const [summaryComponentState, setSummaryComponentState] = useState(LOADING);
  const [componentState, setComponentState] = useState(LOADING);
  const [selectedUser, setSelectedUser] = useState<User>();

  const { showConfirmation } = useContext(ConfirmationContext);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const getSummary = useCallback(
    async (userId?: number) => {
      setSummaryComponentState(COMPONENT_STATE.LOADING);
      try {
        const res = await getTimeSummary(userId || currentUser?.id);
        setTimeSummary(res.data);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      } finally {
        setSummaryComponentState(COMPONENT_STATE.LOADED);
      }
    },
    [currentUser?.id, showSnackbar]
  );
  const toggletabs = () => [
    {
      name: 'day',
      value: t('label.day'),
    },
    {
      name: 'week',
      value: t('label.week'),
    },
  ];

  const deleteConfirmation = (time: TimeWeekGroupType) => {
    showConfirmation(true, {
      header: t('timesheet.timeEntryDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteTimeEntry(time.id),
    });
  };

  const setSelectedDateByWeek = (data: TimesByWeekItem, date?: string) => {
    data.times_list.forEach((item: TimeListByDate) => {
      const day = date || selectedDate;
      if (item.day === day) {
        setCurrentTotalHours(item.per_day_total_hours);
        setSelectedTimeList(item.times);
      }
    });
  };

  const getTimesByWeekData = async (
    range?: WeekDateRange,
    date?: string,
    user_id?: number
  ) => {
    setComponentState(COMPONENT_STATE.LOADING);
    isApprovalBtn && setIsApprovalBtn(false);
    const rangeData = range || dateRange;
    const _date = date || moment().format('YYYY-MM-DD');
    const _user = user_id;
    try {
      const res = await getTimesByWeek(
        rangeData.start_date,
        rangeData.end_date,
        _user
      );
      const timesByWeek = res.data.data;
      setApprovalStatus({
        is_approved: res.data.is_approved,
        is_submitted: res.data.is_submitted,
      });
      setTimesByWeek(timesByWeek);
      setSelectedDateByWeek(timesByWeek, _date);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      setComponentState(COMPONENT_STATE.LOADED);
    }
  };

  useEffect(() => {
    getSummary(currentUser.id);
    if (!isWeekView) {
      getTimesByWeekData(dateRange, selectedDate, selectedUser?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUserChange = (user?: User) => {
    setSelectedUser(user);
    getSummary(user?.id);
    !isWeekView && getTimesByWeekData(undefined, selectedDate, user?.id);
  };

  const getTimeCreateButton = (buttonContent?: string, size?: 'm') => {
    return (
      <div className={styles.headerBtnWrap}>
        {currentUser.time_access === USERROLECHOICES.ADMIN && (
          <SelectPeoplePopover
            selectedMember={selectedUser}
            onUserSelect={onUserChange}
          />
        )}
        {!isWeekView && !approvalStatus?.is_approved && (
          <Button
            className={styles.headerAddBtn}
            size={size || 's'}
            onClick={() => {
              setIsTimeAdd(true);
            }}
          >
            {buttonContent || (
              <>
                <PlusIcon className={styles.plusIcon} />
                {t('timesheet.addTime')}
              </>
            )}
          </Button>
        )}
      </div>
    );
  };

  const getSummaryMarkup = () => {
    return (
      <div className={styles.summaryWrap}>
        <div className={styles.summary}>
          <SummaryStatusCard
            className={styles.summaryCard}
            value={getTimeFromNumber(timeSummary.today_total)}
            title={t('timesheet.hoursToday')}
            key={'hours_today'}
            componentState={summaryComponentState}
          />
          <SummaryStatusCard
            className={styles.summaryCard}
            value={getTimeFromNumber(timeSummary.this_week_total)}
            title={t('timesheet.hoursThisWeek')}
            key={'hours_week'}
            componentState={summaryComponentState}
          />
          <SummaryStatusCard
            className={styles.summaryCard}
            value={getTimeFromNumber(timeSummary.this_month_total)}
            title={t('timesheet.hoursThisMonth')}
            key={'hours_month'}
            componentState={summaryComponentState}
          />
        </div>
      </div>
    );
  };

  const getDayViewDateHeader = (isThisWeek?: boolean) => {
    const isToday = selectedDate === moment().format('YYYY-MM-DD');
    const currentYear = moment().format('YYYY');
    const selectedYear = moment(selectedDate).format('YYYY');
    const date = moment(selectedDate).format('dddd, DD MMM');

    if (componentState === LOADING && !isWeekView) {
      return (
        <div className={styles.headerLeftInnerTitle}>
          <Skeleton variant="rect" width={200} height={25} />
        </div>
      );
    }

    if (!isWeekView) {
      return (
        <div className={styles.headerLeftInnerTitle}>
          {isToday ? <span>{t('label.today')}:</span> : ''}
          {date} {selectedYear !== currentYear ? selectedYear : ''}
        </div>
      );
    } else {
      const start_date = moment(dateRange.start_date).format('DD MMM');
      const end_date = moment(dateRange.end_date).format('DD MMM');
      return (
        <div className={styles.headerLeftInnerTitle}>
          {isThisWeek && <span>{t('label.thisWeek') + ': '}</span>}
          {`${start_date} - ${end_date}`}{' '}
          {selectedYear !== currentYear ? selectedYear : ''}
        </div>
      );
    }
  };

  const setNewDateRange = (newDate: string) => {
    if (!(dateRange.start_date <= newDate && newDate <= dateRange.end_date)) {
      const newRange = getThisWeekRange(newDate);
      setDateRange(newRange);
      !isWeekView && getTimesByWeekData(newRange, newDate, selectedUser?.id);
    }
  };

  const onDualArrowChange = (type: string) => {
    let newDate: string;
    if (type === 'left') {
      newDate = moment(selectedDate)
        .subtract(isWeekView ? 7 : 1, 'days')
        .format('YYYY-MM-DD');
    } else {
      newDate = moment(selectedDate)
        .add(isWeekView ? 7 : 1, 'days')
        .format('YYYY-MM-DD');
    }
    setSelectedDate(newDate);
    !isWeekView && setSelectedDateByWeek(timesByWeek, newDate);
    setNewDateRange(newDate);
  };

  const onClickReturnToToday = () => {
    const today = moment().format('YYYY-MM-DD');
    setSelectedDate(today);
    setSelectedDateByWeek(timesByWeek, today);
    setNewDateRange(today);
  };

  const onDatePickerChange = (date: string | Date) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    setSelectedDate(newDate);
    setNewDateRange(newDate);
  };

  const onSwitchTab = (type: SwitchTabItem) => {
    if (type.name === 'week') {
      history.push(appRoute.getRoute('/time/timesheet/week'));
      setIsWeekView(true);
    } else if (type.name === 'day') {
      history.push(appRoute.getRoute('/time/timesheet/day'));
      setIsWeekView(false);
      getTimesByWeekData(dateRange, selectedDate, selectedUser?.id);
    }
  };

  const getTimeTableNavHeader = () => {
    const isToday = selectedDate === today;
    const isThisWeek =
      dateRange.start_date <= today && today <= dateRange.end_date;
    return (
      <div className={styles.timeTableNavHeaderWrap}>
        <div className={styles.timeTableNavHeader}>
          <div className={styles.NavHeaderWrap}>
            <div className={styles.headerLeft}>
              <ArrowDualNav onClick={onDualArrowChange} />
              {getDayViewDateHeader(isThisWeek)}
              {!isWeekView
                ? !isToday && (
                    <div className={styles.returnToWrap}>
                      <Button
                        className={styles.returnToBtn}
                        onClick={onClickReturnToToday}
                      >
                        {t('timesheet.returnToToday')}
                      </Button>
                    </div>
                  )
                : !isThisWeek && (
                    <div className={styles.returnToWrap}>
                      <Button
                        className={styles.returnToBtn}
                        onClick={onClickReturnToToday}
                      >
                        {t('timesheet.returnToThisWeek')}
                      </Button>
                    </div>
                  )}
            </div>
            <div className={styles.headerRight}>
              <div className={styles.headerRightInnerCalendar}>
                <DatePickerPopover
                  id={'timesheet-date-picker-popover'}
                  onChange={onDatePickerChange}
                  selected={!isWeekView ? selectedDate : undefined}
                  startDate={
                    isWeekView ? new Date(dateRange.start_date) : undefined
                  }
                  endDate={
                    isWeekView ? new Date(dateRange.end_date) : undefined
                  }
                  buttonContent={
                    <CalendarIcon
                      className={styles.headerRightInnerCalendarBtnIcon}
                    />
                  }
                />
              </div>
              <div className={styles.headerRightInnerNav}>
                <SwitchNavButton
                  currentBtnItem={
                    isWeekView ? toggletabs()[1] : toggletabs()[0]
                  }
                  onChange={onSwitchTab}
                  buttonContents={toggletabs()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeSelectedDate = (date: string) => {
    setSelectedDate(date);
    setSelectedDateByWeek(timesByWeek, date);
  };

  const onDeleteTimeEntry = async (id: number) => {
    try {
      await deleteTime(id);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      getSummary(selectedUser?.id);
      getTimesByWeekData(dateRange, selectedDate, selectedUser?.id);
      showConfirmation(false);
    }
  };

  const onDropdownClick = (id: string, item: TimeWeekGroupType) => {
    if (id === 'edit') {
      setIsTimeAdd(true);
      setIsTimeEdit(true);
      setSelectedTimeEntry(item);
    } else if (id === 'delete') {
      deleteConfirmation(item);
    }
  };

  const onCloseDialog = () => {
    setIsTimeAdd(false);
    setIsTimeEdit(false);
    setSelectedTimeEntry({} as TimeWeekGroupType);
  };

  const onSubmitDialog = () => {
    getSummary(selectedUser?.id);
    getTimesByWeekData(dateRange, selectedDate, selectedUser?.id);
  };

  const getAddTimeDialog = () => {
    if (isTimeAdd) {
      return (
        <TimeAdd
          open={isTimeAdd}
          onClose={onCloseDialog}
          onAdd={onSubmitDialog}
          isEdit={isTimeEdit}
          timeItem={selectedTimeEntry}
          selectedDate={selectedDate}
          selectedUser={selectedUser?.id}
        />
      );
    } else return null;
  };

  const onApprovalClick = async (type?: string) => {
    try {
      const _approvalStatus = approvalStatus as CheckApprovalType;

      if (type === 'unlock') {
        const res = await unlockTimeSheetsByWeek({
          date: dateRange.start_date,
        });
        _approvalStatus.is_approved = false;
      } else {
        const res = await submitForApproval(
          dateRange.start_date,
          dateRange.end_date
        );
        _approvalStatus.is_submitted = true;
      }
      setApprovalStatus(_approvalStatus);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      setIsApprovalBtn(false);
    }
  };

  const onApprovalBtnAction = (value: boolean) => {
    setIsApprovalBtn(value);
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.time')}</h4>}
        rightContent={getTimeCreateButton()}
      />
      <div className={styles.content}>
        {getSummaryMarkup()}
        {getTimeTableNavHeader()}
        <Switch>
          <Route
            exact
            path={appRoute.getPath('/time/timesheet/day')}
            render={(props) => (
              <TimeDayView
                timesByWeek={timesByWeek}
                selectedDate={selectedDate}
                onChangeSelectedDate={onChangeSelectedDate}
                onDropdownClick={onDropdownClick}
                selectedTimeList={selectedTimeList}
                currentTotalHours={currentTotalHours}
                onApproval={onApprovalClick}
                approvalStatus={approvalStatus}
                isApprovalBtn={isApprovalBtn}
                onApprovalBtnAction={onApprovalBtnAction}
                componentState={componentState}
                onAddTime={() => {
                  setIsTimeAdd(true);
                }}
                isAdmin={isAdmin}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={appRoute.getPath('/time/timesheet/week')}
            render={(props) => (
              <TimeWeekView
                dateRangeProp={dateRange}
                onUpdateTimeEntry={() => getSummary(selectedUser?.id)}
                onApproval={onApprovalClick}
                isAdmin={isAdmin}
                weekStatus={
                  approvalStatus ? approvalStatus : ({} as CheckApprovalType)
                }
                selectedUser={selectedUser ? selectedUser.id : currentUser.id}
                {...props}
              />
            )}
          />
          <Redirect
            to={appRoute.getRoute('/time/timesheet/day')}
            from={appRoute.getRoute('/time/')}
          />
        </Switch>
        {getAddTimeDialog()}
      </div>
    </>
  );
}

export default Timesheet;
