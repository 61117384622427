import {
  appMsgs,
  appRoute,
  COMPONENT_STATE,
  EmployeePayload,
  getEmployee,
  getErrorMessages,
  updateEmployee,
  userMsgs,
} from '@spovio/shared';
import { Spin, useSnackbar } from '@spovio/ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import PeopleAddForm from '../people-add-form/people-add-form';
import styles from './people-edit.module.scss';

interface MatchParams {
  id?: string;
}

/* eslint-disable-next-line */
export interface PeopleEditProps extends RouteComponentProps<MatchParams> {}

const { LOADED, LOADING } = COMPONENT_STATE;

export function PeopleEdit(props: PeopleEditProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADED);
  const [initialValues, setInitialValues] = useState<EmployeePayload>(
    {} as EmployeePayload
  );
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (values: EmployeePayload) => {
    try {
      if (values.date_of_birth)
        values = {
          ...values,
          date_of_birth: moment(values.date_of_birth).format('YYYY-MM-DD'),
        };
      if (values?.effective_date) {
        const effective_date = moment(values?.effective_date).format(
          'YYYY-MM-DD'
        );
        values = {
          ...values,
          effective_date,
          job_information: {
            ...values.job_information,
            effective_date,
          },
        };
      }
      if (values?.termination_date) {
        const termination_date = moment(values?.termination_date).format(
          'YYYY-MM-DD'
        );
        values = {
          ...values,
          termination_date,
          job_information: {
            ...values.job_information,
            termination_date,
          },
        };
      }
      const res = await updateEmployee(Number(props.match.params.id), values);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: userMsgs().people.edit,
          severity: 'success',
        });
        props.history.goBack();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const fetchData = async () => {
    setComponentState(LOADING);
    try {
      const res = await getEmployee(Number(props.match.params.id));
      const employeePayload = {
        ...res.data,
        date_of_birth: moment(res.data.date_of_birth).format('MM/DD/YYYY'),
        job_information: {
          ...res.data.job_information,
          department: res.data.job_information?.department?.id,
          division: res.data.job_information?.division?.id,
          location: res.data.job_information?.location?.id,
          manager: res.data.job_information?.manager?.id,
        },
        effective_date: moment(res.data.effective_date || undefined).format(
          'MM/DD/YYYY'
        ),
        termination_date: res.data.termination_date
          ? moment(res.data?.termination_date).format('MM/DD/YYYY')
          : undefined,
        user: res.data.user?.id,
      };
      setInitialValues(employeePayload);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.match.params.id]);

  return (
    <div className={styles.root}>
      {componentState === LOADED ? (
        <PeopleAddForm
          title={t('people.editEmployee')}
          actionBtnText={t('label.save')}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      ) : (
        <Spin height={800} />
      )}
    </div>
  );
}

export default PeopleEdit;
