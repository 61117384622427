import {
  clone,
  COMPONENT_STATE,
  exportReport,
  getBasicUserList,
  getErrorMessages,
  getGroupedReportsByWeek,
  getProjectBasicList,
  getReportsByWeek,
  getThisMonthRange,
  getThisWeekRange,
  getThisYearRange,
  getTimeFromNumber,
  GroupedReportItem,
  LazyLoadPagination,
  ReportColumns,
  ReportSummaryItem,
  TIME_RANGE_SELECT_OPTIONS,
  TIME_REPORT_SUMMARY_HEADERS,
  toLowerCase,
  WeekDateRange,
} from '@spovio/shared';
import {
  ArrowDualNav,
  Button,
  ExportIcon,
  Header,
  SummaryStatusCard,
  Text,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelectProjectItems } from '../../multi-select-projects/multi-select-projects';
import MultiSelectUser, {
  MultiSelectUserItem,
} from '../../multi-select-user/multi-select-user';
import TimeReportsSummaryTable from '../time-reports-summary-table/time-reports-summary-table';

import styles from './time-report-summary.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

/* eslint-disable-next-line */
export interface TimeReportsProps {}
interface FilterOptions extends LazyLoadPagination {
  dateRange: WeekDateRange;
  selectedGroupByOption: string;
}

/* eslint-disable-next-line */
interface TimeReportSummaryProps {}

export const TimeReportSummary = (props: TimeReportSummaryProps) => {
  const { t } = useTranslation();
  const [selectedRangeOption, setSelectedRangeOption] = useState(
    TIME_RANGE_SELECT_OPTIONS()[0]
  );
  const [timeSummary, setTimeSummary] = useState<ReportSummaryItem>(
    {} as ReportSummaryItem
  );
  const [reportList, setReportList] = useState<GroupedReportItem[]>(
    [] as GroupedReportItem[]
  );
  const [peopleList, setPeopleList] = useState<MultiSelectUserItem[]>(
    [] as MultiSelectUserItem[]
  );
  const [projectsList, setProjectsList] = useState<MultiSelectProjectItems[]>(
    [] as MultiSelectProjectItems[]
  );
  const [projectFilters, setProjectFilters] = useState<number[]>([]);
  const [peopleFilters, setPeopleFilters] = useState<number[]>([]);
  const [componentState, setComponentState] = useState(LOADING);
  const observer = useRef<any>();
  const [hasMore, setHasMore] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<FilterOptions>({
    page: 1,
    page_size: 25,
    has_next: true,
    dateRange: getThisWeekRange(),
    selectedGroupByOption: ReportColumns.PERSON,
  });

  const lastActivityElemRef = useCallback(
    (node) => {
      observer.current && observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          onLoadMore();
        }
      });
      node && observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, filter.selectedGroupByOption]
  );

  const getFilterList = async () => {
    setComponentState(LOADING);
    const res = await getBasicUserList();
    setPeopleList(res.data);
    const projectList = await getProjectBasicList();
    setProjectsList(projectList.data);
    setComponentState(LOADED);
  };

  const onLoadMore = async () => {
    setIsSpinning(true);
    setFilter((prev: FilterOptions) => {
      loadMoreReports(prev.page + 1);
      return { ...prev, page: prev.page + 1 };
    });
  };

  const setNewDateRange = (
    startDate: string,
    endDate?: string,
    rangeOption?: string
  ) => {
    const range = rangeOption || selectedRangeOption.value;
    switch (range) {
      case 'year':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisYearRange(startDate), page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          projectFilters,
          peopleFilters,
          getThisYearRange(startDate)
        );
        break;
      case 'month':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisMonthRange(startDate), page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          projectFilters,
          peopleFilters,
          getThisMonthRange(startDate)
        );

        break;
      case 'week':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisWeekRange(startDate), page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          projectFilters,
          peopleFilters,
          getThisWeekRange(startDate)
        );
        break;
      case 'all_time':
        setComponentState(COMPONENT_STATE.LOADING);
        setFilter((prev) => {
          return { ...prev, dateRange: {} as WeekDateRange, page: 1 };
        });
        getReportsByWeekData(
          1,
          filter.selectedGroupByOption,
          projectFilters,
          peopleFilters,
          {} as WeekDateRange
        );
        break;
      default: {
        if (endDate) {
          setFilter((prev) => {
            return {
              ...prev,
              dateRange: { start_date: startDate, end_date: endDate },
              page: 1,
            };
          });
          getReportsByWeekData(
            1,
            filter.selectedGroupByOption,
            projectFilters,
            peopleFilters,
            { start_date: startDate, end_date: endDate }
          );
        }
      }
    }
  };

  const onDualArrowChange = (type: string) => {
    let newDate: string, diff: number;
    switch (selectedRangeOption.value) {
      case 'week':
        diff = type === 'left' ? -7 : 7;
        newDate = moment(filter.dateRange.start_date)
          .add(diff, 'days')
          .format('YYYY-MM-DD');
        setNewDateRange(newDate);
        break;
      case 'month':
        diff = type === 'left' ? -1 : 1;
        newDate = moment(filter.dateRange.start_date)
          .add(diff, 'months')
          .format('YYYY-MM-DD');
        setNewDateRange(newDate);
        setFilter((prev) => {
          return { ...prev, dateRange: getThisMonthRange(newDate) };
        });
        break;
      case 'year':
        diff = type === 'left' ? -1 : 1;
        newDate = moment(filter.dateRange.start_date)
          .add(diff, 'years')
          .format('YYYY-MM-DD');
        setNewDateRange(newDate);
        break;
      default:
        break;
    }
  };

  const getDayViewDateHeader = () => {
    const isCurrentWeekMonthYear = (date: string) => {
      const currentDate = moment(date);
      const currentWeek = moment(new Date());
      const currentMonth = moment(new Date()).startOf('month');
      const currentYear = moment(new Date()).startOf('year');
      if (
        currentDate.isSame(currentWeek, 'week') &&
        selectedRangeOption.value === 'week'
      )
        return t('label.thisWeek') + ':';
      if (
        currentDate.isSame(currentMonth, 'month') &&
        selectedRangeOption.value === 'month'
      )
        return t('label.thisMonth') + ':';
      if (
        currentDate.isSame(currentYear, 'year') &&
        selectedRangeOption.value === 'year'
      )
        return t('label.thisYear') + ':';
      return null;
    };

    const isCurrentRange = isCurrentWeekMonthYear(filter.dateRange.start_date);
    const start_date = moment(filter.dateRange.start_date).format('DD MMM');
    const end_date = moment(filter.dateRange.end_date).format('DD MMM YYYY');

    return (
      <div className={styles.headerLeftInnerTitle}>
        {isCurrentRange ? (
          <Text
            variant="h3"
            fontWeight="semi-bold"
            className={styles.currentPeriod}
          >
            {isCurrentRange}
          </Text>
        ) : (
          ''
        )}
        <Text variant="h3">
          {start_date} - {end_date}
        </Text>
      </div>
    );
  };

  const loadMoreReports = async (page: number) => {
    try {
      if (filter.selectedGroupByOption === ReportColumns.NONE) {
        const res = await getReportsByWeek(
          filter.dateRange.start_date,
          filter.dateRange.end_date,
          peopleFilters.length ? peopleFilters : undefined,
          projectFilters.length ? projectFilters : undefined,
          page
        );
        const reportsByWeek = [res.data.results];
        setReportList((prevState) => {
          const newState = clone(prevState);
          newState[0] = {
            ...newState[0],
            times: prevState[0]
              ? [...prevState[0].times, ...reportsByWeek[0].times]
              : [...reportsByWeek[0].times],
          };
          return newState;
        });
        setFilter((prev) => {
          return { ...prev, has_next: res.data.has_next };
        });
        setHasMore(res.data.has_next);
      } else {
        const res = await getGroupedReportsByWeek(
          filter.dateRange.start_date,
          filter.dateRange.end_date,
          toLowerCase(filter.selectedGroupByOption),
          peopleFilters.length ? peopleFilters : undefined,
          projectFilters.length ? projectFilters : undefined,
          page
        );
        const reportsByWeek = res.data.results.times;
        setReportList((prevState) => {
          const previousState = clone(prevState);
          const newReports = [];
          const prop = reportsByWeek[0].project_id
            ? 'project_id'
            : reportsByWeek[0].user_id
            ? 'user_id'
            : 'date';
          for (let i = 0; i < reportsByWeek.length; i++) {
            const report = reportsByWeek[i];
            const index = previousState.findIndex(
              (prevItem) => prevItem[prop] === report[prop]
            );
            if (index === -1) {
              newReports.push(report);
            } else {
              previousState[index].times = [
                ...previousState[index].times,
                ...report.times,
              ];
            }
          }
          return [...previousState, ...newReports];
        });
        setFilter((prev) => {
          return { ...prev, has_next: res.data.has_next };
        });
        setHasMore(res.data.has_next);
      }
      setIsSpinning(false);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getReportsByWeekData = async (
    page: number,
    selectedGroupByOption: string,
    projectFilters: number[],
    peopleFilters: number[],
    dateRange: WeekDateRange
  ) => {
    setComponentState(COMPONENT_STATE.LOADING);
    if (page === 1)
      try {
        const res = await getGroupedReportsByWeek(
          dateRange.start_date,
          dateRange.end_date,
          toLowerCase(selectedGroupByOption),
          peopleFilters.length ? peopleFilters : undefined,
          projectFilters.length ? projectFilters : undefined,
          page
        );
        const reportsByWeek = res.data.results.times;
        setReportList(reportsByWeek);
        setTimeSummary(res.data.results);
        if (!dateRange.start_date)
          setFilter((prev) => {
            return {
              ...prev,
              has_next: res.data.has_next,
              dateRange: {
                start_date: res.data.results.start_date,
                end_date: res.data.results.end_date,
              },
            };
          });
        else
          setFilter((prev) => {
            return {
              ...prev,
              has_next: res.data.has_next,
            };
          });
        setHasMore(res.data.has_next);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    setComponentState(COMPONENT_STATE.LOADED);
  };

  const handleExport = async () => {
    try {
      const res = await exportReport(
        filter.dateRange.start_date,
        filter.dateRange.end_date,
        peopleFilters.length ? peopleFilters : undefined,
        projectFilters.length ? projectFilters : undefined
      );
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getTimeCreateButton = (buttonContent?: string, size?: 'm') => {
    return (
      <Button size={size || 's'} onClick={handleExport}>
        {buttonContent || (
          <>
            <ExportIcon className={styles.plusIcon} />
            {t('label.export')}
          </>
        )}
      </Button>
    );
  };

  const getTimeTableNavHeader = (): JSX.Element => {
    return (
      <div className={styles.reportTableNavHeaderWrap}>
        <div className={styles.reportTableNavLeftHeaderWrap}>
          <ArrowDualNav
            onClick={onDualArrowChange}
            leftButtonDisabled={['all_time', 'custom'].includes(
              selectedRangeOption.value
            )}
            rightButtonDisabled={['all_time', 'custom'].includes(
              selectedRangeOption.value
            )}
          />
          {getDayViewDateHeader()}
        </div>
        <div className={styles.reportTableNavRightHeaderWrap}>
          <MultiSelectUser
            onSelect={(value: any, type?: string) => {
              setPeopleFilters(value);
              setFilter((prev) => {
                return {
                  ...prev,
                  page: 1,
                };
              });
              getReportsByWeekData(
                1,
                filter.selectedGroupByOption,
                projectFilters,
                value,
                filter.dateRange
              );
            }}
            isLoading={LOADED}
            userList={peopleList}
          />
        </div>
      </div>
    );
  };

  const getSummaryMarkup = () => {
    return (
      <div className={styles.summaryWrap}>
        <div className={styles.summary}>
          <SummaryStatusCard
            componentState={componentState}
            className={styles.summaryCard}
            value={getTimeFromNumber(timeSummary.total_hours)}
            title={t('label.totalHours')}
            key={'total_hours'}
          />
          <SummaryStatusCard
            componentState={componentState}
            className={styles.summaryCard}
            value={timeSummary.total_users}
            title={t('label.people')}
            key={'people'}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getFilterList();
    getReportsByWeekData(
      filter.page,
      filter.selectedGroupByOption,
      projectFilters,
      peopleFilters,
      filter.dateRange
    );
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.weeklyReport')}</h4>}
        rightContent={getTimeCreateButton()}
      />
      <div className={styles.content}>
        {getTimeTableNavHeader()}
        {getSummaryMarkup()}
        <TimeReportsSummaryTable
          componentState={componentState}
          groupBy={filter.selectedGroupByOption}
          reportList={reportList}
          totalHours={timeSummary.total_hours}
          isSpinning={isSpinning}
          lastActivityElemRef={lastActivityElemRef}
          onLoadMore={onLoadMore}
          headers={TIME_REPORT_SUMMARY_HEADERS()}
        />
      </div>
    </>
  );
};
export default TimeReportSummary;
