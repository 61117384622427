import {
  CompanyPayload,
  COMPONENT_STATE,
  createCompany,
  getErrorMessages,
  userMsgs,
} from '@spovio/shared';
import { useSnackbar } from '@spovio/ui';
import { useState } from 'react';
import { ContactCompanyForm } from '../contact-company-form/contact-company-form';
import './contact-company-add-dialog.module.scss';

/* eslint-disable-next-line */
export interface ContactCompanyAddDialogProps {
  onAddCompany: () => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactCompanyAddDialog = ({
  onAddCompany,
}: ContactCompanyAddDialogProps) => {
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();

  const initialValues: CompanyPayload = {
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    notes: '',
  };

  const handleSubmit = async (company: CompanyPayload) => {
    setComponentState(LOADING);
    try {
      const res = await createCompany(company);
      if (res.status === 201) {
        showSnackbar(true, {
          msg: userMsgs().company.add,
          severity: 'success',
        });
        onAddCompany();
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setComponentState(LOADED);
    }
  };
  return (
    <div>
      <ContactCompanyForm
        open
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ContactCompanyAddDialog;
