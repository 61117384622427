// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".create-pwd_root__3AZaj {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  padding-top: 180px;\n}\n.create-pwd_root__3AZaj .create-pwd_title__1ByxJ {\n  position: absolute;\n  top: 25px;\n  left: 30px;\n  margin: 0;\n}\n\n.create-pwd_innerContent__1bJCn {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 420px;\n  width: calc(100% - 16px);\n}\n\n.create-pwd_heading__376fV {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n\n.create-pwd_helperText__tVtTC {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin: auto;\n  margin-bottom: 56px;\n  width: 300px;\n}\n\n.create-pwd_submitBtn__2uLPW {\n  font-size: 15px;\n  font-weight: 600;\n  letter-spacing: -0.12px;\n  line-height: 19px;\n  text-align: center;\n}\n\n.create-pwd_option__DggKr {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #6d6971;\n  font-size: 13px;\n  margin-top: 40px;\n}\n.create-pwd_option__DggKr .create-pwd_linkText__2ytQr {\n  display: flex;\n  color: #747578;\n  font-size: 16px;\n  font-weight: 600;\n  letter-spacing: -0.25px;\n  line-height: 20px;\n  text-align: center;\n}\n.create-pwd_option__DggKr .create-pwd_linkText__2ytQr > svg {\n  width: 20px;\n  height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"root": "create-pwd_root__3AZaj",
	"title": "create-pwd_title__1ByxJ",
	"innerContent": "create-pwd_innerContent__1bJCn",
	"heading": "create-pwd_heading__376fV",
	"helperText": "create-pwd_helperText__tVtTC",
	"submitBtn": "create-pwd_submitBtn__2uLPW",
	"option": "create-pwd_option__DggKr",
	"linkText": "create-pwd_linkText__2ytQr"
};
module.exports = exports;
