import {
  appRoute,
  BasicFilter,
  COMPONENT_STATE,
  Contact,
  ContactFilter,
  CONTACT_TABLE_HEADERS,
  useModuleAccess,
} from '@spovio/shared';
import {
  Button,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  EvStationIcon,
  KeyboardArrowDownIcon,
  NoDataContent,
  PlusIcon,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
} from '@spovio/ui';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './contact-table-list.module.scss';

/* eslint-disable-next-line */
export interface ContactTableListProps {
  className?: string;
  contacts: Contact[];
  filter: ContactFilter;
  onFilter: (filter: ContactFilter) => void;
  restrictEdit?: boolean;
  componentState: COMPONENT_STATE;
  onListAction?: (dropId: string, contact: Contact) => void;
}

const { LOADING } = COMPONENT_STATE;
export function ContactTableList({
  className,
  contacts,
  filter,
  onFilter,
  componentState,
  onListAction,
  restrictEdit = false,
}: ContactTableListProps) {
  const [sortBy, setSortBy] = useState<string>('name');
  const { isAdmin } = useModuleAccess('contact_access');
  const history = useHistory();
  const { t } = useTranslation();

  const headerContent = CONTACT_TABLE_HEADERS();

  const getContactCreateButton = () => {
    return (
      <Button
        size={'s'}
        component={NavLink}
        disabled={!isAdmin}
        to={appRoute.getRoute(`/contacts/list/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('contact.addContact')}
        </>
      </Button>
    );
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        {headerContent.map((item) => {
          return (
            <TableListCell
              className={clsx(
                styles[item.className],
                ['name', 'company'].includes(item.id) && styles.cursorPointer
              )}
              key={item.id}
              onClick={() => {
                if (contacts.length)
                  if (item.id === 'name' && sortBy !== 'name') {
                    setSortBy('name');
                    onFilter({ ...filter, sort_by: 'name' });
                  } else if (item.id === 'company' && sortBy !== 'company') {
                    setSortBy('company');
                    onFilter({ ...filter, sort_by: 'company' });
                  }
              }}
            >
              <div className={clsx(styles.heading)}>{item.label}</div>
              {['name', 'company'].includes(item.id) && (
                <KeyboardArrowDownIcon
                  className={clsx(
                    styles.sortIcon,
                    item.id === sortBy && styles.selected
                  )}
                />
              )}
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: Contact[]) => {
    return results.map((item) => {
      const { id, name, company, title, phone, email } = item;
      const dropdownItems: DropdownListItem[] = [
        {
          label: t('label.edit'),
          icon: <EditIcon />,
          id: 'edit',
        },
        {
          label: t('label.delete'),
          id: 'delete',
          icon: <DeleteIcon />,
        },
      ];
      const cells = [
        {
          classes: clsx(styles.col, styles.nameCol),
          content: <div className="ellipsis">{name}</div>,
        },
        {
          classes: clsx(styles.col, styles.companyCol),
          content: <div className="ellipsis">{company?.name}</div>,
        },
        {
          classes: clsx(styles.col, styles.titleCol),
          content: <div className="ellipsis">{title}</div>,
        },
        {
          classes: clsx(styles.col, styles.phoneCol),
          content: <div className="ellipsis">{phone}</div>,
        },
        {
          classes: clsx(styles.col, styles.emailCol),
          content: <div className="ellipsis">{email}</div>,
        },
      ];
      return (
        <TableListRow
          className={styles.trow}
          key={id}
          onClick={(e) => {
            e.stopPropagation();
            history.push(appRoute.getRoute(`/contacts/list/${item.id}/view`));
          }}
        >
          {cells.map((cell, index) => {
            return (
              <TableListCell className={clsx(cell.classes)} key={index}>
                <div className={clsx(styles.itemWrap, 'ellipsis')}>
                  {cell.content}
                </div>
              </TableListCell>
            );
          })}
          <TableListCell
            className={clsx(styles.col, styles.actionCol)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isAdmin && onListAction && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) =>
                  onListAction && onListAction(drpId, item)
                }
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      if (!contacts?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('contact.noData.head')}
              desc={t('contact.noData.desc')}
              icon={<EvStationIcon className={styles.noDataIcon} />}
              buttonComponent={getContactCreateButton()}
            />
          </div>
        );
      }
      return getBodyRowMarkup(contacts);
    }
  };

  return (
    <div className={clsx([styles.root, className])}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default ContactTableList;
