import axios, { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';

import { appRoute } from '../app-config';
import { appInfo } from '../constants';
import {
  getAccessToken,
  getDefaultAccount,
  getRefreshToken,
  removeAuthenticationDetails,
  setAccessToken,
  setDefaultOrgDetails,
} from './local-storage.utils';

export const interceptor = () => {
  const source = axios.CancelToken.source();
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (config) {
        const token = getAccessToken();
        config.headers = {
          ...config.headers,
          Authorization: token ? `Bearer ${token}` : '',
        };
        const refreshTokenUrl = '/auth/access/refresh/';
        const isRefreshTokenCall = config.url?.includes(refreshTokenUrl);
        config.headers['Accept-Language'] = i18next.language;
        if (!isRefreshTokenCall) {
          config.cancelToken = source.token;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  let numberOfRefreshCall = 0;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const loginUrl = '/auth/access/';
      const isLoginCall = originalRequest.url.includes(loginUrl);
      const errRes = error.response;
      if (
        errRes &&
        errRes.status === 401 &&
        !originalRequest._retry &&
        !isLoginCall
      ) {
        const refreshTokenUrl = '/auth/access/refresh/';
        const isRefreshTokenCall =
          originalRequest.url.includes(refreshTokenUrl);
        const refreshToken = getRefreshToken();
        if (refreshToken && !isRefreshTokenCall && numberOfRefreshCall < 5) {
          originalRequest._retry = true;
          numberOfRefreshCall++;
          return axios({
            baseURL: appInfo.baseURL,
            method: 'post',
            url: refreshTokenUrl,
            data: { refresh: refreshToken },
          })
            .then((res) => {
              const { access } = res.data;
              setAccessToken(access);
              originalRequest.headers.Authorization = `Bearer ${access}`;
              return axios(originalRequest);
            })
            .catch(() => {
              removeAuthenticationDetails();
            });
        } else {
          removeAuthenticationDetails();
        }
        if (!isRefreshTokenCall) {
          source.cancel();
        }
      }
      return Promise.reject(error);
    }
  );
};

export const initializeAxiosConfig = (orgId?: number) => {
  orgId = orgId || parseInt(getDefaultAccount() || '', 10);
  const token = getAccessToken();
  if (orgId && token) {
    setDefaultOrgDetails(orgId);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.baseURL = `${appInfo.baseURL}${'/' + orgId}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    appRoute.setOrgId(Number(orgId));
  } else {
    removeAuthenticationDetails();
  }
};
