// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".add-row-project_content__1-JGH {\n  padding: 20px 25px 0 25px;\n}\n.add-row-project_content__1-JGH .add-row-project_label__1u2AA {\n  color: #535659;\n  font-size: 14px;\n  letter-spacing: -0.1px;\n  line-height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"content": "add-row-project_content__1-JGH",
	"label": "add-row-project_label__1u2AA"
};
module.exports = exports;
