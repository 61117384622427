import {
  COMPONENT_STATE,
  getErrorMessages,
  getTimeOffType,
  TimeOffType,
  updateTimeOffType,
  userMsgs,
} from '@spovio/shared';
import { Spin, useSnackbar } from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import PeopleTimeOffTypeForm from '../people-time-off-type-form/people-time-off-type-form';
import './people-time-off-type-edit-dialog.module.scss';

/* eslint-disable-next-line */
interface MatchParams {
  id?: string;
}
type RouteProps = RouteComponentProps<MatchParams, StaticContext>;

export interface PeopleTimeOffTypeEditDialogProps extends RouteProps {
  onEdit: (data: TimeOffType) => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export function PeopleTimeOffTypeEditDialog({
  onEdit,
  ...props
}: PeopleTimeOffTypeEditDialogProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [initialValues, setInitialValues] = useState<TimeOffType>(
    {} as TimeOffType
  );
  const { showSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    try {
      if (props.match.params.id) {
        const res = await getTimeOffType(props.match.params.id);
        const initialValues = res.data;
        setInitialValues(initialValues);
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [props.match.params.id, showSnackbar]);

  const handleSubmit = async (contact: { name: string }) => {
    try {
      if (props.match.params.id) {
        // contact.tags = contact.tags.map((tag: any) => tag.data);
        const res = await updateTimeOffType(props.match.params.id, contact);
        if (res.status === 200) {
          showSnackbar(true, {
            msg: userMsgs().timeOffType.edit,
            severity: 'success',
          });
        }
        onEdit(res.data);
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, props.match.params.id]);
  return componentState === LOADED ? (
    <div>
      <PeopleTimeOffTypeForm
        open
        title={t('people.timeOff.editType')}
        action={t('label.save')}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  ) : (
    <Spin />
  );
}

export default PeopleTimeOffTypeEditDialog;
