// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-directory_header__bFRGn {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.people-directory_header__bFRGn .people-directory_plusIcon__v4XE4 {\n  margin-right: 6px;\n}\n.people-directory_header__bFRGn .people-directory_bottomContent__2OOJC {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.people-directory_header__bFRGn .people-directory_bottomContent__2OOJC .people-directory_filterButton__39iDx {\n  padding: 6px 8px;\n}\n.people-directory_header__bFRGn .people-directory_bottomContent__2OOJC .people-directory_filterButton__39iDx .people-directory_filterName__kVOYg {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.people-directory_searchBar__32AAd {\n  border: 1px solid #d4d4d5;\n  border-radius: 6px;\n  background-color: #fff;\n  padding: 0 12px;\n  outline: 0;\n  width: auto;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  width: 300px;\n}\n\n.people-directory_pagination__2Ua7E {\n  padding: 25px 30px;\n}\n.people-directory_pagination__2Ua7E .people-directory_paper__1uj1r {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}\n\n.people-directory_statusSelector__KHLOK {\n  padding-left: 15px;\n  font-size: 13px;\n  color: #707379;\n  letter-spacing: -0.18px;\n}\n\n.people-directory_statusSelectorPaper__Vmc43 {\n  min-width: 200px !important;\n}\n\n.people-directory_statusSelectorDropdown__ZtUBh {\n  top: 6px !important;\n}", ""]);
// Exports
exports.locals = {
	"header": "people-directory_header__bFRGn",
	"plusIcon": "people-directory_plusIcon__v4XE4",
	"bottomContent": "people-directory_bottomContent__2OOJC",
	"filterButton": "people-directory_filterButton__39iDx",
	"filterName": "people-directory_filterName__kVOYg",
	"searchBar": "people-directory_searchBar__32AAd",
	"pagination": "people-directory_pagination__2Ua7E",
	"paper": "people-directory_paper__1uj1r",
	"statusSelector": "people-directory_statusSelector__KHLOK",
	"statusSelectorPaper": "people-directory_statusSelectorPaper__Vmc43",
	"statusSelectorDropdown": "people-directory_statusSelectorDropdown__ZtUBh"
};
module.exports = exports;
