// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AvatarGroup_root__3NRh7 {\n  display: flex;\n  flex-direction: row;\n}\n.AvatarGroup_root__3NRh7.AvatarGroup_alignRight__XqPtw {\n  justify-content: flex-end;\n}\n\n.AvatarGroup_avatar__3SXi4 {\n  border: 2px solid #fcfcfd;\n}\n\n.AvatarGroup_remainingItem__ZANCz {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 16px;\n  position: relative;\n  background-color: #edeef2;\n  border: 2px solid #fcfcfd;\n  color: #8c94a1;\n  font-size: 11px;\n  font-weight: bold;\n  letter-spacing: -0.15px;\n  text-align: center;\n  user-select: none;\n}\n\n.AvatarGroup_avatarGroupLoadingItem__1XnsO {\n  border: 2px solid #ffffff;\n  display: inline-flex;\n  justify-content: center;\n  border-radius: 50%;\n}\n.AvatarGroup_avatarGroupLoadingItem__1XnsO > * {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"root": "AvatarGroup_root__3NRh7",
	"alignRight": "AvatarGroup_alignRight__XqPtw",
	"avatar": "AvatarGroup_avatar__3SXi4",
	"remainingItem": "AvatarGroup_remainingItem__ZANCz",
	"avatarGroupLoadingItem": "AvatarGroup_avatarGroupLoadingItem__1XnsO"
};
module.exports = exports;
