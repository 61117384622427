// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app-layout_space__3KuIb {\n  display: flex;\n  padding-top: 70px;\n}\n@media (min-width: 768px) {\n  .app-layout_space__3KuIb {\n    margin-left: 240px;\n  }\n}\n\n.app-layout_content__1g48V {\n  display: flex;\n  flex: 2 0 0%;\n  background: #fff;\n  overflow: auto;\n  height: calc(100vh - 70px);\n}\n\n.app-layout_contentBody__63VaP {\n  flex: 1 1 0%;\n  display: flex;\n  flex-direction: row;\n  min-height: 0;\n}\n\n.app-layout_contentBodyWrapper__2PZXr {\n  width: 100vw;\n  height: 100%;\n  max-width: 100%;\n  margin: 0 auto;\n  position: relative;\n}\n@media (min-width: 768px) {\n  .app-layout_contentBodyWrapper__2PZXr {\n    width: calc(100vw - 240px);\n  }\n}", ""]);
// Exports
exports.locals = {
	"space": "app-layout_space__3KuIb",
	"content": "app-layout_content__1g48V",
	"contentBody": "app-layout_contentBody__63VaP",
	"contentBodyWrapper": "app-layout_contentBodyWrapper__2PZXr"
};
module.exports = exports;
