// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".summary-status-card_cardWrap__1OFq3 {\n  margin-right: 30px;\n}\n.summary-status-card_cardWrap__1OFq3 .summary-status-card_card__cd81- {\n  min-width: 100px;\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n}\n@media screen and (min-width: 750px) {\n  .summary-status-card_cardWrap__1OFq3 .summary-status-card_card__cd81- {\n    min-width: 180px;\n  }\n}\n.summary-status-card_cardWrap__1OFq3 .summary-status-card_title__2AFbR {\n  color: #828385;\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  line-height: 16px;\n  margin-bottom: 2px;\n}\n@media screen and (min-width: 750px) {\n  .summary-status-card_cardWrap__1OFq3 .summary-status-card_title__2AFbR {\n    font-size: 14px;\n    letter-spacing: -0.22px;\n    line-height: 22px;\n  }\n}\n.summary-status-card_cardWrap__1OFq3 .summary-status-card_content__2HhOr {\n  color: #252526;\n  font-size: 24px;\n  letter-spacing: -0.3px;\n  line-height: 30px;\n  font-weight: 500;\n}\n@media screen and (min-width: 750px) {\n  .summary-status-card_cardWrap__1OFq3 .summary-status-card_content__2HhOr {\n    font-size: 28px;\n    letter-spacing: -0.35px;\n    line-height: 32px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"cardWrap": "summary-status-card_cardWrap__1OFq3",
	"card": "summary-status-card_card__cd81-",
	"title": "summary-status-card_title__2AFbR",
	"content": "summary-status-card_content__2HhOr"
};
module.exports = exports;
