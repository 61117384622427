// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".date-range-picker-popover_buttonIcon__195iQ {\n  width: 16px;\n  height: 16px;\n}\n\n.date-range-picker-popover_filterButton__3-CyB {\n  height: 28px;\n  color: #6d6971;\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  line-height: 16px;\n  padding: 0 8px;\n  text-transform: capitalize;\n  border-radius: 5px;\n}\n.date-range-picker-popover_filterButton__3-CyB:hover {\n  background-color: #f0eff1;\n}\n.date-range-picker-popover_filterButton__3-CyB.date-range-picker-popover_isOpen__1kaYr {\n  color: #4b494d;\n  background-color: #eae9eb;\n}\n.date-range-picker-popover_filterButton__3-CyB .date-range-picker-popover_ecgIcon__3Gc-s {\n  margin-right: 5px;\n}\n.date-range-picker-popover_filterButton__3-CyB.date-range-picker-popover_hasTag__2jo5Y {\n  padding: 0;\n  border: none;\n}", ""]);
// Exports
exports.locals = {
	"buttonIcon": "date-range-picker-popover_buttonIcon__195iQ",
	"filterButton": "date-range-picker-popover_filterButton__3-CyB",
	"isOpen": "date-range-picker-popover_isOpen__1kaYr",
	"ecgIcon": "date-range-picker-popover_ecgIcon__3Gc-s",
	"hasTag": "date-range-picker-popover_hasTag__2jo5Y"
};
module.exports = exports;
