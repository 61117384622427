import { Divider } from '@material-ui/core';
import {
  appRoute,
  COMPONENT_STATE,
  getDateFormat,
  Profile,
  useModuleAccess,
} from '@spovio/shared';
import {
  ArchiveIcon,
  Avatar,
  AvatarSize,
  Dropdown,
  DropdownListItem,
  EditIcon,
  EmailIcon,
  PhoneIcon,
  Skeleton,
  Text,
  useCurrentUser,
  WorkEmailIcon,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './employee-basic-info.module.scss';

/* eslint-disable-next-line */
export interface EmployeeBasicInfoProps {
  profile: Profile;
  componentState: COMPONENT_STATE;
  onListAction?: (dropId: string, people: string) => void;
}
const { LOADING } = COMPONENT_STATE;

export function EmployeeBasicInfo({
  profile,
  componentState,
  onListAction,
}: EmployeeBasicInfoProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAdmin } = useModuleAccess('people_access');
  const { currentUser } = useCurrentUser();
  const isLoading = componentState === LOADING;

  const hasEmployeeAccess = () => {
    const id =
      Number(history.location.pathname.split('/').pop()) ||
      currentUser.employee_detail.id;
    return (
      currentUser.people_access === 'admin' ||
      id === currentUser.employee_detail.id
    );
  };

  const dropdownItems = (): DropdownListItem[] => {
    if (!profile?.employee_detail) return [];
    const { is_active, id } = profile?.employee_detail;
    const items: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
      },
    ];
    if (currentUser.employee_detail.id !== id) {
      items.push({
        label: is_active ? t('label.archive') : t('label.unarchive'),
        id: 'archive',
        icon: <ArchiveIcon />,
      });
    }
    return items;
  };

  const scrollToTop = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.currentTarget.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleManagerClick = (id: number) => {
    history.push(appRoute.getRoute(`/people/profile/${id}`));
  };

  const getBasicSection = () => {
    return (
      <div className={styles['basic-details']}>
        {isLoading ? (
          <Skeleton
            variant={'rect'}
            width={74}
            height={74}
            className={styles.avatar}
          />
        ) : (
          <Avatar
            name={profile.employee_detail?.name}
            url={profile.user_detail?.user?.pic}
            size={AvatarSize.avatar74}
            className={styles.avatar}
          />
        )}
        <div>
          <div className={styles['employee-name']}>
            {isLoading ? (
              <Skeleton variant={'rect'} width={140} height={15} />
            ) : (
              profile.employee_detail?.name
            )}
          </div>
          <div className={styles['employee-title']}>
            {profile.employee_detail?.job_information?.title}
          </div>
        </div>
      </div>
    );
  };

  const getContactSection = () => {
    return (
      <div className={styles.contacts}>
        <div className={clsx(styles.phone, styles['mb-7'])}>
          <PhoneIcon />
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <div className={styles.textWrap}>
              <Text
                variant="body2"
                color="dove_grey"
                className={clsx(styles.text, 'no-wrap')}
                onMouseLeave={scrollToTop}
              >
                {profile.employee_detail?.phone
                  ? profile.employee_detail?.phone
                  : '—'}
              </Text>
            </div>
          )}
        </div>
        <div className={clsx(styles.email, styles['mb-7'])}>
          <WorkEmailIcon />
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <div className={styles.textWrap}>
              <Text
                variant="body2"
                color="dove_grey"
                className={clsx(styles.text, 'no-wrap')}
                onMouseLeave={scrollToTop}
              >
                {profile.employee_detail?.work_email
                  ? profile.employee_detail?.work_email
                  : '—'}
              </Text>
            </div>
          )}
        </div>
        <div className={styles.email}>
          <EmailIcon />
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <div className={styles.textWrap}>
              <Text
                variant="body2"
                color="dove_grey"
                className={clsx(styles.text, 'no-wrap')}
                onMouseLeave={scrollToTop}
              >
                {profile.employee_detail?.personal_email
                  ? profile.employee_detail?.personal_email
                  : '—'}
              </Text>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getAddressSection = () => {
    return (
      <div className={styles.address}>
        <Text variant="h6" className={styles.heading}>
          {t('label.address')}
        </Text>
        {profile.employee_detail?.address1 ||
        profile.employee_detail?.address2 ? (
          <div className={styles.detail}>
            <Text variant="body2" color="dove_grey">
              {t('label.address')}
            </Text>
            {isLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text
                variant="body3"
                color="dark_grey"
                fontWeight="medium"
                textAlign="right"
              >
                {profile.employee_detail?.address1}
                <br />
                {profile.employee_detail?.address2}
              </Text>
            )}
          </div>
        ) : null}
        {profile.employee_detail?.city && (
          <div className={styles.detail}>
            <Text variant="body2" color="dove_grey">
              {t('label.city')}
            </Text>
            {isLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text variant="body3" color="dark_grey" fontWeight="medium">
                {profile.employee_detail?.city}
              </Text>
            )}
          </div>
        )}
        {profile.employee_detail?.state && (
          <div className={styles.detail}>
            <Text variant="body2" color="dove_grey">
              {t('label.state')}
            </Text>
            {isLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text variant="body3" color="dark_grey" fontWeight="medium">
                {profile.employee_detail?.state}
              </Text>
            )}
          </div>
        )}
        {profile.employee_detail?.country && (
          <div className={styles.detail}>
            <Text variant="body2" color="dove_grey">
              {t('label.country')}
            </Text>
            {isLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text variant="body3" color="dark_grey" fontWeight="medium">
                {profile.employee_detail?.country}
              </Text>
            )}
          </div>
        )}
        {profile.employee_detail?.zip_code && (
          <div className={styles.detail}>
            <Text variant="body2" color="dove_grey">
              {t('label.zipCode')}
            </Text>
            {isLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text variant="body3" color="dark_grey" fontWeight="medium">
                {profile.employee_detail?.zip_code}
              </Text>
            )}
          </div>
        )}
      </div>
    );
  };

  const getDetailSection = () => {
    const { id, user, name } =
      profile.employee_detail?.job_information?.manager || {};

    return (
      <div className={styles.details}>
        <Text variant="h6" className={styles.heading}>
          {t('label.employeeDetails')}
        </Text>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.dob')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {getDateFormat(
                profile.employee_detail?.date_of_birth,
                'MMM DD YYYY'
              )}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.nationalID')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text
              variant="body3"
              color="dark_grey"
              fontWeight="medium"
              className={clsx(!hasEmployeeAccess() && styles.nationalId)}
            >
              {hasEmployeeAccess()
                ? profile.employee_detail?.national_id
                : '*********'}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.title')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {profile.employee_detail?.job_information?.title}
            </Text>
          )}
        </div>
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.hireDate')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text variant="body3" color="dark_grey" fontWeight="medium">
              {getDateFormat(
                profile.employee_detail?.effective_date,
                'MMM DD YYYY'
              )}
            </Text>
          )}
        </div>
        {profile.employee_detail?.termination_date ? (
          <div className={styles.detail}>
            <Text variant="body2" color="dove_grey">
              {t('label.terminationDate')}
            </Text>
            {isLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text variant="body3" color="dark_grey" fontWeight="medium">
                {getDateFormat(
                  profile.employee_detail?.termination_date,
                  'MMM DD YYYY'
                )}
              </Text>
            )}
          </div>
        ) : null}
        <div className={styles.detail}>
          <Text variant="body2" color="dove_grey">
            {t('label.manager')}
          </Text>
          {isLoading ? (
            <Skeleton width={80} height={15} />
          ) : (
            <Text
              variant="body3"
              color="dark_grey"
              fontWeight="medium"
              className={clsx(id && styles.cursorPointer)}
              onClick={() => id && handleManagerClick(id)}
            >
              {name || '—'}
            </Text>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.info}>
      <div className={styles.dropdown}>
        {isAdmin ? (
          <Dropdown
            buttonClassName={styles.actionContent}
            data={dropdownItems()}
            onItemClick={(drpId) => {
              if (!profile?.employee_detail) return;
              const { id } = profile?.employee_detail;
              if (drpId === 'edit') {
                history.push(appRoute.getRoute(`/people/${id}/edit`));
              } else {
                onListAction?.(drpId, 'item');
              }
            }}
          />
        ) : (
          <div className={styles.actionContent}></div>
        )}
      </div>
      {getBasicSection()}
      {getContactSection()}
      <Divider />
      {getAddressSection()}
      <Divider />
      {getDetailSection()}
    </div>
  );
}

export default EmployeeBasicInfo;
