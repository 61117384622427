import {
  COMPONENT_STATE,
  Contact,
  ContactPayload,
  getContactDetails,
  getErrorMessages,
  updateContact,
  userMsgs,
} from '@spovio/shared';
import { Spin, useSnackbar } from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ContactForm } from '../contact-form/contact-form';
import './contact-edit-dialog.module.scss';

interface MatchParams {
  id?: string;
}

type RouteProps = RouteComponentProps<MatchParams, StaticContext>;

/* eslint-disable-next-line */
export interface ContactEditDialogProps extends RouteProps {
  onEdit: (data: Contact) => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export function ContactEditDialog({
  onEdit,
  ...props
}: ContactEditDialogProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [initialValues, setInitialValues] = useState<Contact>({} as Contact);
  const { showSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    try {
      if (props.match.params.id) {
        const res = await getContactDetails(props.match.params.id);
        const initialValues = res.data;
        delete initialValues.created_by;
        delete initialValues.updated_by;
        setInitialValues(initialValues);
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [props.match.params.id, showSnackbar]);

  const handleSubmit = async (contact: ContactPayload) => {
    try {
      if (props.match.params.id) {
        // contact.tags = contact.tags.map((tag: any) => tag.data);
        const res = await updateContact(props.match.params.id, contact);
        if (res.status === 200) {
          showSnackbar(true, {
            msg: userMsgs().contact.edit,
            severity: 'success',
          });
        }
        onEdit(res.data);
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, props.match.params.id]);
  return componentState === LOADED ? (
    <div>
      <ContactForm
        open
        title={t('contact.editContact')}
        action={t('label.save')}
        initialValues={{
          ...initialValues,
          company: initialValues.company?.id,
          tags: initialValues.tags.map((tag) => tag.id),
        }}
        onSubmit={handleSubmit}
      />
    </div>
  ) : (
    <Spin />
  );
}

export default ContactEditDialog;
