import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { CurrentUser } from '@spovio/shared';

type Props = {
  children: ReactNode;
};

export type CurrentUserContextType = {
  currentUser: CurrentUser;
  setCurrentUser: (currentUser: CurrentUser) => void;
};
export const CurrentUserContext = createContext({} as CurrentUserContextType);

export const CurrentUserProvider = ({ children }: Props) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<CurrentUser>(
    {} as CurrentUser
  );
  const setCurrentUser = useCallback((currentUser: CurrentUser) => {
    setAuthenticatedUser(currentUser);
  }, []);
  return (
    <CurrentUserContext.Provider
      value={{ currentUser: authenticatedUser, setCurrentUser }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);
