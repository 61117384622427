import {
  capitalize,
  COMPONENT_STATE,
  Employee,
  EmployeeList,
  getAgeProfile,
  getAgeProfileGraph,
  PeopleFilter,
} from '@spovio/shared';
import {
  CompensationIcon,
  Divider,
  Header,
  NoDataContent,
  Pagination,
  TableMarkup,
  TableMarkupColumns,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AgeBreakdown from '../age-breakdown/age-breakdown';
import styles from './gender-profile.module.scss';

/* eslint-disable-next-line */
interface GenderProfileProps {}

const { LOADED, LOADING } = COMPONENT_STATE;

export const GenderProfile = (props: GenderProfileProps) => {
  const { t } = useTranslation();
  const [people, setPeople] = useState<EmployeeList>();
  const [componentState, setComponentState] = useState(LOADING);
  const [graph, setGraph] = useState<{ name: string; value: number }[]>();
  const PeopleFilter: PeopleFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
    is_active: true,
  };
  const [filter, setFilter] = useState<PeopleFilter>(PeopleFilter);
  const location = useLocation();
  const isAgeProfile = location.pathname.includes('age-');

  const init = useCallback(async () => {
    setComponentState(LOADING);
    try {
      const res = await getAgeProfile(filter);

      setPeople({ ...res.data });
      setComponentState(LOADED);
    } catch (error) {
      //todo
    }
  }, [filter]);

  const fetchGraphData = async () => {
    const type = isAgeProfile ? 'age' : 'gender';
    try {
      const res = await getAgeProfileGraph(type);
      setGraph(res.data);
    } catch (error) {
      //todo
    }
  };
  useEffect(() => {
    fetchGraphData();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const getColumns = (): TableMarkupColumns[] => {
    const columns = [
      {
        title: t('label.name'),
        dataIndex: 'name',
        key: 'name',
        className: styles.dateCol,
      },
      {
        title: t('label.gender'),
        dataIndex: 'gender',
        key: 'gender',
      },

      {
        title: t('label.jobTitle'),
        dataIndex: 'jobTitle',
        key: 'jobTitle',
      },
      {
        title: t('label.department'),
        dataIndex: 'department',
        key: 'department',
      },
      {
        title: t('label.division'),
        dataIndex: 'division',
        key: 'division',
      },
      {
        title: t('label.location'),
        dataIndex: 'location',
        key: 'location',
      },
    ];

    if (isAgeProfile) {
      columns[1] = {
        title: t('label.age'),
        dataIndex: 'age',
        key: 'age',
      };
    }
    return columns;
  };

  const getDataSource = (data: Employee[]) => {
    return data.map((item) => {
      const age = moment().diff(item.date_of_birth, 'years', true);
      return {
        key: item,
        name: item.name,
        gender: capitalize(item.gender),
        age: age.toFixed(0),
        jobTitle: item.job_information.title,
        department: item.job_information.department.name,
        division: item.job_information.division.name,
        location: item.job_information.location.name,
        data: item,
      };
    });
  };

  const getPaginationFooter = () => {
    if (!people) return null;
    const { has_next, has_prev, total_count, page_size, links } = people;
    const page = Number(filter.page);
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => setFilter({ ...filter, page: page })}
        onSelect={(value) => {
          setFilter({ ...filter, page_size: value });
        }}
      />
    );
  };
  return (
    <>
      <Header
        className={styles.header}
        leftContent={
          <div className={styles.title}>
            <h4>
              {isAgeProfile ? t('label.ageProfile') : t('label.genderProfile')}{' '}
            </h4>{' '}
            &nbsp;
            {people ? (
              <span>
                ({people?.total_count}) {t('label.people')}
              </span>
            ) : null}
          </div>
        }
      />
      <div className={styles.table}>
        {graph ? (
          <AgeBreakdown
            data={graph}
            title={
              isAgeProfile
                ? t('label.ageBreakdown')
                : t('label.genderBreakdown')
            }
          />
        ) : null}
        <Divider />
        <TableMarkup
          columns={getColumns()}
          dataSource={getDataSource(people?.results || [])}
          noData={
            <NoDataContent
              title={
                isAgeProfile ? t('label.ageProfile') : t('label.genderProfile')
              }
              desc={t('extension.noDataFound')}
              icon={<CompensationIcon className={styles.noDataIcon} />}
              containerClassName={styles.blankList}
            />
          }
        />
        {getPaginationFooter()}
      </div>
    </>
  );
};
export default GenderProfile;
