import { useCurrentUser } from '@spovio/ui';
import { useState, useEffect } from 'react';
import { ModuleAccess } from '../types';

export function useModuleAccess(access: ModuleAccess) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser[access] === 'admin');
      setIsRestricted(currentUser[access] === 'no_access');
    }
  }, [access, currentUser]);

  return { isAdmin, isRestricted };
}
