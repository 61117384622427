// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contact-tag-form_content__2npP1 {\n  padding: 20px 25px 0 25px;\n}", ""]);
// Exports
exports.locals = {
	"content": "contact-tag-form_content__2npP1"
};
module.exports = exports;
