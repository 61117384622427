import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import {
  ApprovalTimeOffResponse,
  BasicFilter,
  Compensation,
  CompensationPayload,
  DirectReportType,
  EmergencyContact,
  EmergencyContactPayload,
  Employee,
  EmployeeList,
  EmployeePayload,
  FileType,
  Folder,
  FolderPayload,
  GroupedReports,
  JobInfo,
  JobInfoPayload,
  MultiSelectEmployeeItem,
  Pagination,
  PeopleFilter,
  Profile,
  ReportItem,
  TimeOff,
  TimeOffList,
  TimeOffPayload,
  TimeOffType,
  TimeOffTypePayload,
  User,
} from '../types';

export const getPeopleList = async (
  filters?: PeopleFilter
): Promise<AxiosResponse<EmployeeList>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/peoples/?${queryParams}`);
};
export const getPeopleListExport = async (isActive: boolean) => {
  return await axios.get(`/peoples/export`, {
    params: {
      export_format: 'csv',
      is_active: isActive,
    },
  });
};

export const getPeoplesBasicList = async (
  filters?: PeopleFilter
): Promise<AxiosResponse<MultiSelectEmployeeItem[]>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/peoples/basic-list/?${queryParams}`);
};

export const addEmployee = async (
  data: EmployeePayload
): Promise<AxiosResponse<Employee>> => {
  const payload = data;
  return await axios.post(`/peoples/`, payload);
};

export const getEmployee = async (
  id: number
): Promise<AxiosResponse<Employee>> => {
  return await axios.get(`/peoples/${id}/`);
};

export const updateEmployee = async (
  id: number,
  data: EmployeePayload
): Promise<AxiosResponse<Employee>> => {
  const payload = data;
  return await axios.patch(`/peoples/${id}/`, payload);
};

export const archiveEmployee = async (
  id: number,
  is_active: boolean
): Promise<AxiosResponse<Employee>> => {
  return await axios.get(
    `/peoples/${id}/update-status/?is_active=${is_active}`
  );
};

export const deleteEmployee = async (
  id: number
): Promise<AxiosResponse<Employee>> => {
  return await axios.delete(`/peoples/${id}`);
};

export const getMyProfile = async (): Promise<AxiosResponse<Profile>> => {
  return await axios.get(`/peoples/my-profile/`);
};

export const getProfile = async (
  id: number
): Promise<AxiosResponse<Profile>> => {
  return await axios.get(`/peoples/${id}/profile/`);
};

// JOB INFO
export const getJobInfos = async (
  employeeID: number
): Promise<AxiosResponse<Pagination<JobInfo[]>>> => {
  return await axios.get(`/job-information/?employee_id=${employeeID}`);
};

export const addJobInfo = async (
  data: JobInfoPayload,
  employeeID: number
): Promise<AxiosResponse<JobInfo>> => {
  const payload = data;
  return await axios.post(
    `/job-information/?employee_id=${employeeID}`,
    payload
  );
};

export const updateJobInfo = async (
  id: number,
  data: JobInfoPayload,
  employeeID: number
): Promise<AxiosResponse<JobInfo>> => {
  const payload = data;
  return await axios.patch(
    `/job-information/${id}/?employee_id=${employeeID}`,
    payload
  );
};

export const deleteJobInfo = async (
  id: number,
  employeeID: number
): Promise<AxiosResponse<JobInfo>> => {
  return await axios.delete(
    `/job-information/${id}/?employee_id=${employeeID}`
  );
};

//COMPENSATION
export const getCompensations = async (
  employeeId: number
): Promise<AxiosResponse<Compensation[]>> => {
  return await axios.get(`/peoples/${employeeId}/compensation/`);
};

export const getLatestCompensation = async (
  employeeId: number
): Promise<AxiosResponse<Compensation>> => {
  return await axios.get(`/peoples/${employeeId}/latest-compensation/`);
};
export const getCompensationsReport = async (
  employee?: number,
  name?: string,
  start_date?: string,
  end_date?: string
) => {
  const payload = {
    employee,
    name,
    start_date,
    end_date,
  };
  return await axios.post(`/peoples/compensation/export/`, payload);
};

export const addCompensation = async (
  employeeId: number,
  data: CompensationPayload
): Promise<AxiosResponse<Compensation>> => {
  const payload = data;
  return await axios.post(
    `/peoples/${employeeId}/create-compensation/`,
    payload
  );
};

export const updateCompensation = async (
  employeeId: number,
  id: number,
  data: CompensationPayload
): Promise<AxiosResponse<Compensation>> => {
  const payload = data;
  return await axios.patch(
    `/peoples/${employeeId}/edit-compensation/${id}/`,
    payload
  );
};

export const deleteCompensation = async (
  employeeId: number,
  id: number
): Promise<AxiosResponse<Compensation>> => {
  return await axios.delete(
    `/peoples/${employeeId}/delete-compensation/${id}/`
  );
};

//EMERGENCY CONTACT
export const getEmergencyContacts = async (
  employeeId: number
): Promise<AxiosResponse<EmergencyContact[]>> => {
  return await axios.get(`/peoples/${employeeId}/emergency-contact/`);
};

export const addEmergencyContact = async (
  employeeId: number,
  data: EmergencyContactPayload
): Promise<AxiosResponse<EmergencyContact>> => {
  const payload = data;
  return await axios.post(
    `/peoples/${employeeId}/create-emergency-contact/`,
    payload
  );
};

export const updateEmergencyContact = async (
  employeeId: number,
  id: number,
  data: EmergencyContactPayload
): Promise<AxiosResponse<EmergencyContact>> => {
  const payload = data;
  return await axios.patch(
    `/peoples/${employeeId}/edit-emergency-contact/${id}/`,
    payload
  );
};

export const deleteEmergencyContact = async (
  employeeId: number,
  id: number
): Promise<AxiosResponse<EmergencyContact>> => {
  return await axios.delete(
    `/peoples/${employeeId}/delete-emergency-contact/${id}/`
  );
};

//TIME_OFF
export const getTimeOffs = async (
  employeeID: number,
  filters: BasicFilter
): Promise<AxiosResponse<TimeOffList>> => {
  const queryParams = stringify(
    { ...filters, employee_id: employeeID },
    { skipNull: true }
  );
  return await axios.get(`/time-off/?${queryParams}`);
};
export const getUpcomingTimeOffs = async (
  employeeID: number
): Promise<AxiosResponse<TimeOff[]>> => {
  return await axios.get(
    `/time-off/upcoming-time-off/?employee_id=${employeeID}`
  );
};

export const getTimeOffReport = async (
  employee?: number,
  name?: string,
  start_date?: string,
  end_date?: string
) => {
  const payload = { employee, name, start_date, end_date };
  return await axios.post(`/time-off/export/`, payload);
};

export const addTimeOff = async (
  data: TimeOffPayload,
  employeeID: number
): Promise<AxiosResponse<TimeOff>> => {
  return await axios.post(`/time-off/?employee_id=${employeeID}`, data);
};

export const updateTimeOff = async (
  id: number,
  data: TimeOffPayload,
  employeeID: number
): Promise<AxiosResponse<TimeOff>> => {
  return await axios.patch(`/time-off/${id}/?employee_id=${employeeID}`, data);
};

export const approveTimeOff = async (
  id: number
): Promise<AxiosResponse<TimeOff>> => {
  return await axios.get(`/time-off/${id}/approve/`);
};

export const unlockTimeOff = async (
  id: number
): Promise<AxiosResponse<TimeOff>> => {
  return await axios.get(`/time-off/${id}/unlock/`);
};

export const deleteTimeOff = async (
  id: number,
  employeeID: number
): Promise<AxiosResponse<TimeOff>> => {
  return await axios.delete(`/time-off/${id}/?employee_id=${employeeID}`);
};

export const exportTimeOffReport = async (
  start: string,
  end: string,
  users?: number[],
  time_off_types?: number[]
  // groupedBy: string,
): Promise<AxiosResponse<any>> => {
  const payload = {
    start,
    end,
    users,
    time_off_types,
  };
  const url = `/report/time-offs/export/`;
  return await axios.post(url, payload, {
    responseType: 'blob',
  });
};

export const getTimeOffTypes = async (
  is_active?: boolean
): Promise<AxiosResponse<TimeOffType[]>> => {
  let url = `/time-off-type/?`;
  if (is_active || is_active === false) url += `is_active=${is_active}`;
  return await axios.get(url);
};

export const getTimeOffType = async (
  id: string
): Promise<AxiosResponse<TimeOffType>> => {
  return await axios.get(`/time-off-type/${id}/`);
};

export const createTimeOffType = async (data: {
  name: string;
}): Promise<AxiosResponse<TimeOffType>> => {
  return await axios.post(`/time-off-type/`, data);
};

export const updateTimeOffType = async (
  id: string,
  data: { name: string }
): Promise<AxiosResponse<TimeOffType>> => {
  return await axios.patch(`/time-off-type/${id}/`, data);
};

export const updateTimeOffTypeStatus = async (
  id: number,
  is_active: boolean
): Promise<AxiosResponse<TimeOffType>> => {
  return await axios.get(
    `/time-off-type/${id}/update-status/?is_active=${is_active}`
  );
};

export const deleteTimeOffType = async (
  id: number
): Promise<AxiosResponse<TimeOffType>> => {
  return await axios.delete(`/time-off-type/${id}/`);
};

export const getApprovalTimeOffs = async (
  payload: TimeOffTypePayload,
  filters?: BasicFilter
): Promise<AxiosResponse<ApprovalTimeOffResponse>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.post(`/time-off/approval-list/?${queryParams}`, payload);
};

export const getApprovedTimeOffs = async (
  payload?: TimeOffTypePayload,
  filters?: BasicFilter
): Promise<AxiosResponse<ApprovalTimeOffResponse>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.post(`/time-off/approved-list/?${queryParams}`, payload);
};

export const getCalendarTimeOffs = async (
  payload?: TimeOffTypePayload
): Promise<AxiosResponse<TimeOff[]>> => {
  return await axios.post(`/time-off/calendar/`, payload);
};

export const getTimeOffReportsByWeek = async (
  start: string,
  end: string,
  users?: number[],
  page?: number,
  time_off_types?: number[]
): Promise<AxiosResponse<Pagination<ReportItem>>> => {
  const payload = {
    start,
    end,
    users,
    time_off_types,
  };
  let url = '/report/time-offs/?';
  if (page) {
    url += `page=${page}&`;
  }
  return await axios.post(url, payload);
};

export const getGroupedTimeOffReportsByWeek = async (
  start: string,
  end: string,
  groupedBy: string,
  users?: number[],
  page?: number,
  time_off_types?: number[]
): Promise<AxiosResponse<Pagination<GroupedReports>>> => {
  const payload = {
    start,
    end,
    users,
    time_off_types,
  };
  let url = `/report/time-offs/?group_by=${groupedBy}&`;
  if (page) {
    url += `page=${page}&`;
  }
  return await axios.post(url, payload);
};

//FOLDER
export const getFolders = async (
  employeeID: number
): Promise<AxiosResponse<Folder[]>> => {
  return await axios.get(`/peoples/uploads/folder/?employee_id=${employeeID}`);
};

export const addFolder = async (
  data: FolderPayload,
  employeeID: number
): Promise<AxiosResponse<Folder>> => {
  const payload = data;
  return await axios.post(
    `/peoples/uploads/folder/?employee_id=${employeeID}`,
    payload
  );
};

export const updateFolder = async (
  id: number,
  data: FolderPayload,
  employeeID: number
): Promise<AxiosResponse<Folder>> => {
  const payload = data;
  return await axios.patch(
    `/peoples/uploads/folder/${id}/?employee_id=${employeeID}`,
    payload
  );
};

export const deleteFolder = async (
  id: number,
  employeeID: number
): Promise<AxiosResponse<Folder>> => {
  return await axios.delete(
    `/peoples/uploads/folder/${id}/?employee_id=${employeeID}`
  );
};

export const getFilesFromFolder = async (
  folderId: number,
  employeeID: number
): Promise<AxiosResponse<FileType[]>> => {
  return await axios.post(`/peoples/uploads/file/?employee_id=${employeeID}`, {
    folder: folderId,
  });
};

export const uploadFile = async (
  folderId: number,
  data: FormData,
  employeeID: number
): Promise<AxiosResponse<FileType>> => {
  return await axios.post(
    `/peoples/uploads/${folderId}/upload-file/?employee_id=${employeeID}`,
    data
  );
};

export const deleteFile = async (
  fileId: number,
  employeeID: number
): Promise<AxiosResponse<FileType>> => {
  return await axios.delete(
    `/peoples/uploads/file/${fileId}/?employee_id=${employeeID}`
  );
};

export const getDirectReports = async (
  employeeID: number
): Promise<AxiosResponse<DirectReportType[]>> => {
  return await axios.get(`/peoples/${employeeID}/direct-reports/`);
};

//Age profile

export const getAgeProfile = async (
  filter: PeopleFilter
): Promise<AxiosResponse<EmployeeList>> => {
  return await axios.get('/peoples/profile-list/', { params: filter });
};

export const getAgeProfileGraph = async (
  type: string
): Promise<AxiosResponse<{ name: string; value: number }[]>> => {
  return await axios.get('/peoples/age-graph/', {
    params: {
      type,
    },
  });
};

export const getCompensationList = async () => {
  return await axios.get('/peoples/salary-history/');
};

export const getBirthdays = async () => {
  return await axios.get('/peoples/birthdays/');
};

export interface HeadcountData {
  month: number;
  year: number;
  headcount: number;
  employees: Employee[];
}

export const getHeadcount = async (
  filter: any
): Promise<AxiosResponse<HeadcountData[]>> => {
  return await axios.get('/peoples/headcount/', { params: filter });
};

export const getGragh = async (): Promise<AxiosResponse<HeadcountData[]>> => {
  return await axios.get('/peoples/gragh/');
};
