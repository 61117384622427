export const WebSiteIcon = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      fillRule="nonzero"
    >
      <path d="M10 1.25a8.75 8.75 0 110 17.5 8.75 8.75 0 010-17.5zm.366 13.756l-.124.103-.25.217c-.634.54-1.176.763-1.964.63-1.107-.189-1.77-.156-2.111.035A7.214 7.214 0 0010 17.25c1.72 0 3.3-.6 4.544-1.6-.566-.465-1.437-.809-2.632-1.014-.71-.122-1.062-.02-1.546.37zM10 2.75c-.306 0-.607.019-.902.056-.669 1.26-.937 2.916-.788 4.98l.032.386c.29 3.065-1.772 4.02-5.5 2.98a7.22 7.22 0 001.892 3.83c.684-.637 1.91-.783 3.545-.506.24.041.379 0 .638-.207l.346-.296c.863-.744 1.64-1.033 2.903-.816 1.495.258 2.651.728 3.449 1.43a7.223 7.223 0 001.487-3.124c-.976-.366-2.055-.605-3.236-.716-1.752-.166-2.025-1.92-1.119-3.542.581-1.04 1.5-1.832 2.557-2.148A7.231 7.231 0 0010 2.75zm-2.73.531l-.196.084a7.254 7.254 0 00-4.311 6.2l-.012-.003c3.08 1.002 4.212.592 4.108-1.11l-.01-.138c-.185-1.942-.047-3.621.422-5.033zm9.03 3.13l-.137.005c-.827.07-1.624.658-2.107 1.521-.462.828-.391 1.284-.048 1.316a14.42 14.42 0 013.243.669 7.227 7.227 0 00-.95-3.512z" />
    </svg>
  );
};
export default WebSiteIcon;
