import React from 'react';

type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const WorkEmailIcon = (props: IconProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="10px"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v.5L6.894 5.053a2 2 0 0 1-1.788 0L0 2.5V2Zm0 2 5.106 2.553a2 2 0 0 0 1.788 0L12 4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm6.459-1.941a.6.6 0 0 0 0-.877.675.675 0 0 0-.921 0 .6.6 0 0 0 0 .877.675.675 0 0 0 .92 0Zm.79 1.359.001.119c0 .164-.113.31-.278.356A3.681 3.681 0 0 1 6 4c-.393 0-.716-.036-.972-.107a.372.372 0 0 1-.278-.356v-.12a.623.623 0 0 1 .414-.574A2.36 2.36 0 0 1 6 2.697c.3 0 .578.049.836.146a.623.623 0 0 1 .413.575Z"
        fill="gray"
      />
    </svg>
  );
};
