import { appRoute, Modules } from '@spovio/shared';
import { Ellipsis, Popper, TickIcon, Unfold, useCurrentUser } from '@spovio/ui';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { appNavItems } from './app-dropdown-utils';
import styles from './app-dropdown.module.scss';

/* eslint-disable-next-line */
export interface AppDropdownProps {
  currentModule: Modules;
}

export function AppDropdown({ currentModule }: AppDropdownProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { currentUser } = useCurrentUser();

  const [value, setValue] = useState('');

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => {
    setOpen(false);
  };

  const getNavItemMarkup = () => {
    const {
      people_access,
      reimbursement_access,
      dimension_access,
      time_access,
      contact_access,
      settings_access,
    } = currentUser;
    const permittedNavItems = appNavItems().filter((item) => {
      if (item.access === 'people_access') {
        return people_access !== 'no_access';
      }
      if (item.access === 'reimbursement_access') {
        return reimbursement_access !== 'no_access';
      }
      if (item.access === 'dimension_access') {
        return dimension_access !== 'no_access';
      }
      if (item.access === 'time_access') {
        return time_access !== 'no_access';
      }
      if (item.access === 'contact_access') {
        return contact_access !== 'no_access';
      }

      return true;
    });
    return permittedNavItems.map((item, index) => (
      <NavLink
        to={
          item.name === 'settings' &&
          settings_access === 'no_access' &&
          item.defaultPath
            ? appRoute.getRoute(item.defaultPath)
            : appRoute.getRoute(item.path)
        }
        className={styles.navLink}
        activeClassName={styles.active}
        onClick={() => {
          setValue(item.value);
          handleClose();
        }}
        key={index}
      >
        <li
          className={clsx(
            styles.navItem,
            item.name === currentModule && styles.active
          )}
          key={index}
        >
          <div className={styles.navLinkLeft}>
            <div className={styles.navLinkIcon}>
              <img src={item.icon} alt="people" />
            </div>

            {item.value}
          </div>
          {item.name === currentModule && (
            <TickIcon className={styles.checkIcon} />
          )}
        </li>
      </NavLink>
    ));
  };

  return (
    <div className={styles.root}>
      <Popper
        open={open}
        anchorOrigin="left"
        paperClassName={styles.paper}
        renderButton={() => {
          return (
            <button
              className={clsx(
                styles.workspaceProfileBtn,
                open && styles.active
              )}
              onClick={handleToggle}
            >
              <div className={styles.navLinkIcon}>
                <img
                  src={
                    appNavItems().find((item) => item.name === currentModule)
                      ?.icon
                  }
                  alt="people"
                />
              </div>
              <Ellipsis className={styles.accountName}>
                {
                  appNavItems().find((item) => item.name === currentModule)
                    ?.value
                }
              </Ellipsis>
              <Unfold className={styles.unfold} />
            </button>
          );
        }}
        onClose={handleClose}
      >
        <>
          <div className={styles.heading}>{t('extension.switchToApps')}</div>
          <ul className={styles.navMenu}>{getNavItemMarkup()}</ul>
        </>
      </Popper>
    </div>
  );
}
