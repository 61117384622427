// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".salary-history_header__3owER {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.salary-history_header__3owER .salary-history_plusIcon__3G79G {\n  margin-right: 6px;\n}\n.salary-history_header__3owER .salary-history_bottomContent__yAHGP {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.salary-history_header__3owER .salary-history_bottomContent__yAHGP .salary-history_filterButton___bJzP {\n  padding: 6px 8px;\n}\n.salary-history_header__3owER .salary-history_bottomContent__yAHGP .salary-history_filterButton___bJzP .salary-history_filterName__BBXEM {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.salary-history_paySchedule__3nvcj {\n  text-transform: capitalize;\n}", ""]);
// Exports
exports.locals = {
	"header": "salary-history_header__3owER",
	"plusIcon": "salary-history_plusIcon__3G79G",
	"bottomContent": "salary-history_bottomContent__yAHGP",
	"filterButton": "salary-history_filterButton___bJzP",
	"filterName": "salary-history_filterName__BBXEM",
	"paySchedule": "salary-history_paySchedule__3nvcj"
};
module.exports = exports;
