import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TickIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="14px"
      height="10px"
      viewBox="0 0 14 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Switch-to-apps"
          transform="translate(-190.000000, -112.000000)"
          stroke="currentColor"
          strokeWidth="1.2"
        >
          <g id="Group-80-Copy" transform="translate(16.000000, 55.000000)">
            <g id="Group-29-Copy" transform="translate(8.000000, 46.000000)">
              <g id="Group-25" transform="translate(8.000000, 5.000000)">
                <polyline
                  id="Tik"
                  points="159 11.416538 162.658696 14.833757 170.789474 7"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
