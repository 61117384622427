import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TimesIcon = (props: IconsProps) => {
  return (
    <svg {...props} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M6.706 16.915a.6.6 0 0 1 .097 1.192l-.097.008h-.941a.6.6 0 0 1-.098-1.192l.098-.008h.94ZM10.47 1.4c4.493 0 8.129 3.746 8.129 8.358 0 4.611-3.636 8.357-8.13 8.357a.6.6 0 1 1 0-1.2c3.823 0 6.93-3.2 6.93-7.157S14.293 2.6 10.47 2.6c-3.17 0-5.91 2.225-6.694 5.364a.6.6 0 0 1-1.165-.291C3.527 4.009 6.74 1.4 10.471 1.4ZM6.706 14.006a.6.6 0 0 1 .097 1.192l-.097.008H3.882a.6.6 0 0 1-.097-1.192l.097-.008h2.824Zm3.765-8.1a.6.6 0 0 1 .592.502l.008.098-.001 2.941 3.068 2.18a.6.6 0 0 1 .192.752l-.05.084a.6.6 0 0 1-.753.192l-.084-.05-3.32-2.358a.6.6 0 0 1-.245-.394l-.007-.095V6.506a.6.6 0 0 1 .6-.6Zm-3.765 5.191a.6.6 0 0 1 .097 1.192l-.097.008H2a.6.6 0 0 1-.097-1.192L2 11.097h4.706Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
