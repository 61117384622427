import {
  COMPONENT_STATE,
  ContactPayload,
  COUNTRY_CHOICES,
  createContact,
  getErrorMessages,
  userMsgs,
} from '@spovio/shared';
import { useSnackbar } from '@spovio/ui';
import { useState } from 'react';
import { ContactForm } from '../contact-form/contact-form';
import './contact-add-dialog.module.scss';

/* eslint-disable-next-line */
export interface ContactAddDialogProps {
  onAddContact: () => void;
}

const CountryList = COUNTRY_CHOICES.map((item) => {
  return { label: item, value: item };
});

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactAddDialog = ({ onAddContact }: ContactAddDialogProps) => {
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();

  const initialValues: ContactPayload = {
    name: '',
    title: '',
    email: '',
    phone: '',
    notes: '',
    tags: [],
  };

  const handleSubmit = async (contact: ContactPayload) => {
    setComponentState(LOADING);
    try {
      const res = await createContact(contact);
      if (res.status === 201) {
        showSnackbar(true, {
          msg: userMsgs().contact.add,
          severity: 'success',
        });
        onAddContact();
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setComponentState(LOADED);
    }
  };
  return (
    <div>
      <ContactForm open initialValues={initialValues} onSubmit={handleSubmit} />
    </div>
  );
};

export default ContactAddDialog;
