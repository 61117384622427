import {
  getDateFormat,
  getErrorMessages,
  getTimeOffTypes,
  TimeOff,
  TimeOffPayload,
  TimeOffType,
  validateForm,
} from '@spovio/shared';
import {
  DatePickerPopover,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Select,
  Textarea,
  useSnackbar,
} from '@spovio/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './people-add-edit-time-off.module.scss';

/* eslint-disable-next-line */
export interface PeopleAddEditTimeOffProps {
  open: boolean;
  timeOff: TimeOff;
  onClose: () => void;
  onSubmit: (value: TimeOffPayload) => void;
}

export function PeopleAddEditTimeOff({
  open,
  timeOff,
  onClose,
  onSubmit,
}: PeopleAddEditTimeOffProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState({} as TimeOffPayload);
  const [timeOffTypes, setTimeOffTypes] = useState([] as TimeOffType[]);
  const [date, setDate] = useState<string | undefined>(
    getDateFormat(new Date(), 'MM/DD/YYYY')
  );
  const { showSnackbar } = useSnackbar();

  const _getTimeOffTypes = async () => {
    try {
      const res = await getTimeOffTypes();
      setTimeOffTypes(res.data);
      if (!(timeOff && timeOff.id) && res.data[0]) {
        setValues({
          ...values,
          type: res.data[0].id,
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const handleSelect = (field: string, value: string) => {
    if (field === 'type') {
      setValues({ ...values, [field]: Number(value) });
      return;
    }
    setValues({ ...values, [field]: value });
  };

  const handleDateChange = (date?: any) => {
    setDate(date);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit({
      ...values,
      from_date: getDateFormat(date),
      to_date: getDateFormat(date),
    });
  };

  const isValid = () => {
    return validateForm.isPositiveNumber(values.used);
  };

  useEffect(() => {
    _getTimeOffTypes();
  }, []);

  useEffect(() => {
    setIsModalVisible(open);
    if (timeOff && timeOff.id) {
      setValues({
        ...timeOff,
        type: timeOff.type.id,
        employee: timeOff.employee.id,
        from_date: getDateFormat(timeOff.from_date),
      });
      setDate(getDateFormat(timeOff.from_date, 'MM/DD/YYYY'));
    }
  }, [open]);

  return (
    <div className={styles.root}>
      <Dialog
        title={
          timeOff && timeOff.id
            ? t('people.timeOff.editTimeOff')
            : t('people.timeOff.addTimeOff')
        }
        action={t('label.save')}
        open={isModalVisible}
        maxWidth="sm"
        isActionDisabled={!isValid()}
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              label={t('label.type')}
              className={styles.label}
              required
            />
            <Select
              options={timeOffTypes.map((type) => ({
                label: type.name,
                value: `${type.id}`,
              }))}
              selectedOption={{
                label:
                  (timeOff && timeOff.type?.name) ||
                  timeOffTypes.find((type) => type.id === values.type)?.name ||
                  '',
                value: `${values.type}`,
              }}
              onSelect={(value) => handleSelect('type', value)}
              placeholder={t('label.selectType')}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              className={styles.label}
              label={t('label.date')}
              required
            />
            <DatePickerPopover
              id={'date'}
              size={'m'}
              paperClassName={styles.paper}
              selected={date}
              onChange={handleDateChange}
              onTextChange={handleDateChange}
              placeholder={t('label.dobPlaceholder')}
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="used"
              type="number"
              validationType={['required']}
              labelClassName={styles.label}
              label={t('label.used')}
              inputProps={{ min: 0 }}
              value={values.used}
              required
              onChange={(e) =>
                setValues({
                  ...values,
                  used: e.target.value as unknown as number,
                })
              }
            />
          </FormControl>
          <FormControl className={styles.lastFormControl} fullWidth={true}>
            <InputLabel className={styles.label} label={t('label.note')} />
            <Textarea
              name={'note'}
              className={styles.textarea}
              size="s"
              minRows={5}
              maxRows={5}
              disableResize
              onChange={(e) => setValues({ ...values, note: e.target.value })}
              value={values.note}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default PeopleAddEditTimeOff;
