import { MenuItem, Select as MuiSelect } from '@material-ui/core';
import {
  appRoute,
  COMPONENT_STATE,
  Employee,
  EmployeeList,
  PeopleFilter,
  PEOPLE_TABLE_HEADERS,
  useModuleAccess,
} from '@spovio/shared';
import {
  ArchiveIcon,
  Avatar,
  AvatarSize,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  NoDataContent,
  PeopleIcon,
  Select,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  useCurrentUser,
} from '@spovio/ui';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styles from './people-list-table.module.scss';

const { LOADING } = COMPONENT_STATE;

export interface PeopleListTableProps {
  people: EmployeeList;
  filter: PeopleFilter;
  onFilter: (filter: PeopleFilter) => void;
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, people: Employee) => void;
}

export function PeopleListTable({
  people,
  filter,
  onFilter,
  componentState,
  onListAction,
}: PeopleListTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAdmin } = useModuleAccess('people_access');
  const isLoading = componentState === LOADING;
  const headerContent = PEOPLE_TABLE_HEADERS();
  const { currentUser } = useCurrentUser();

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        {headerContent.map((item) => {
          return (
            <TableListCell
              className={clsx(styles.col, styles[item.className])}
              key={item.id}
            >
              <div className={clsx(styles.heading)}>{item.label}</div>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: Employee[]) => {
    return results.map((item) => {
      const { id, user, name, phone, work_email, job_information } = item;
      const cells = [
        {
          classes: clsx(styles.col, styles.userAvatar, 'no-wrap'),
          content: (
            <Avatar
              url={user?.user.pic}
              name={user?.user.name || name}
              size={AvatarSize.avatar30}
              className={styles.userIcon}
              displayName
              circle
            />
          ),
        },
        {
          classes: clsx(styles.col),
          content: <div className="no-wrap">{job_information?.title}</div>,
        },
        {
          classes: clsx(styles.col),
          content: <div className="no-wrap">{phone}</div>,
        },
        {
          classes: clsx(styles.col),
          content: <div className="no-wrap">{work_email}</div>,
        },
        {
          classes: clsx(styles.col),
          content: (
            <div className="no-wrap">{job_information?.department?.name}</div>
          ),
        },
        {
          classes: clsx(styles.col, styles.divisionCol),
          content: (
            <div className="no-wrap">{job_information?.division?.name}</div>
          ),
        },
        {
          classes: clsx(styles.col, styles.locationCol),
          content: (
            <div className="no-wrap">{job_information?.location?.name}</div>
          ),
        },
      ];
      const dropdownItems = (): DropdownListItem[] => {
        const items = [
          {
            label: t('label.edit'),
            icon: <EditIcon />,
            id: 'edit',
            to: `/people/${id}/edit`,
            // component: 'link',
          },
          {
            label: t('label.delete'),
            id: 'delete',
            icon: <DeleteIcon />,
          },
        ];
        if (currentUser.employee_detail.id !== id) {
          items.splice(1, 0, {
            label: filter.is_active ? 'Archive' : 'Unarchive',
            icon: <ArchiveIcon />,
            id: 'archive',
          });
        }
        return items;
      };
      return (
        <TableListRow
          className={styles.trow}
          key={id}
          onClick={(e) => {
            history.push(appRoute.getRoute(`/people/profile/${id}`));
            e.stopPropagation();
          }}
        >
          {cells.map((item, index) => {
            return (
              <TableListCell className={item.classes} key={index}>
                {item.content}
              </TableListCell>
            );
          })}

          <TableListCell
            className={clsx(styles.col, styles.actionCol)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isAdmin && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems()}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      const { results } = people;
      if (!results?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('settings.noDataUser.head')}
              desc={t('settings.noDataUser.desc')}
              icon={<PeopleIcon className={styles.noDataIcon} />}
            />
          </div>
        );
      }
      return getBodyRowMarkup(results);
    }
  };
  return (
    <div className={styles.root}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default PeopleListTable;
