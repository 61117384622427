import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const MyMileageIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.5 6.302c0-1.179.955-2.135 2.136-2.135h1.197V2.5H10v1.667h1.668c3.22 0 5.832 2.61 5.832 5.831v5.369a2.135 2.135 0 0 1-2.136 2.134H4.636A2.137 2.137 0 0 1 2.5 15.367V6.302Z"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M10 7.5s2.5 2.495 2.5 4.072c0 1.38-1.12 2.5-2.5 2.5s-2.49-1.12-2.5-2.5C7.508 9.994 10 7.5 10 7.5Z"
        stroke="#A2A5A8"
        strokeWidth="1.2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
