import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const OnboardingIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="17px"
      height="18px"
      viewBox="0 0 17 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-43"
          transform="translate(-10.000000, -7.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Group-42" transform="translate(8.000000, 5.000000)">
            <g id="Folder,-Group.11" transform="translate(0.000000, 1.000000)">
              <g id="Group" transform="translate(3.000000, 2.000000)">
                <line
                  x1="4"
                  y1="11.4166667"
                  x2="10.65"
                  y2="11.4166667"
                  id="Path"
                ></line>
                <line
                  x1="4"
                  y1="8.41666667"
                  x2="10.65"
                  y2="8.41666667"
                  id="Path"
                ></line>
                <path
                  d="M13.3333333,5.5 L7.94583333,5.5 C7.6675,5.5 7.40666667,5.36083333 7.2525,5.12916667 L6.08083333,3.37166667 C5.92583333,3.13916667 5.66583333,3 5.3875,3 L1.66666667,3 C0.745833333,3 0,3.74583333 0,4.66666667 L0,13.8333333 C0,14.7541667 0.745833333,15.5 1.66666667,15.5 L13.3333333,15.5 C14.2541667,15.5 15,14.7541667 15,13.8333333 L15,7.16666667 C15,6.24583333 14.2541667,5.5 13.3333333,5.5 Z"
                  id="Path"
                ></path>
                <path
                  d="M2,2.5 L2,1.66666667 C2,0.745833333 2.74583333,0 3.66666667,0 L12,0 C12.9208333,0 13.6666667,0.745833333 13.6666667,1.66666667 L13.6666667,5"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
