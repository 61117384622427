import {
  Compensation,
  CompensationPayload,
  COMPENSATION_FREQUENCY,
  currency,
  CURRENCY_CHOICES,
  getDateFormat,
  validateForm,
} from '@spovio/shared';
import {
  DatePickerPopover,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Select,
  Divider,
  DollarIcon,
} from '@spovio/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './people-add-edit-compensation.module.scss';

/* eslint-disable-next-line */
export interface PeopleAddEditCompensationProps {
  open: boolean;
  compensation: Compensation;
  onClose: () => void;
  onSubmit: (value: CompensationPayload) => void;
}

export function PeopleAddEditCompensation({
  open,
  compensation,
  onClose,
  onSubmit,
}: PeopleAddEditCompensationProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState({} as CompensationPayload);

  const currencyOptions = CURRENCY_CHOICES.map((item) => ({
    label: item.code,
    value: item.code,
  }));
  const frequencyOptions = COMPENSATION_FREQUENCY.map((item) => ({
    label: item.split('_').join(' ').toLowerCase(),
    value: item,
  }));

  useEffect(() => {
    setIsModalVisible(open);
    if (compensation && compensation.id) {
      setValues(compensation);
    } else {
      setValues({
        ...values,
        effective_date: getDateFormat(new Date(), 'MM/DD/YYYY'),
        frequency: COMPENSATION_FREQUENCY[0],
        currency: CURRENCY_CHOICES[0].code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit({
      ...values,
      effective_date: getDateFormat(values.effective_date),
    });
  };

  const handleSelect = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const handleDateChange = (date: any) => {
    setValues({ ...values, effective_date: date });
  };

  const isValid = () => {
    return (
      validateForm.isNumber(values.rate) &&
      validateForm.isDateFormat(values.effective_date)
    );
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={
          compensation && compensation.id
            ? t('people.editCompensation')
            : t('people.addCompensation')
        }
        action={t('label.save')}
        open={isModalVisible}
        maxWidth="sm"
        isActionDisabled={!isValid()}
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="rate"
              required
              validationType={['required', 'number']}
              labelClassName={styles.label}
              inputClassName={styles.payRateInput}
              iconClassName={styles.payRateIcon}
              label={t('label.payRate')}
              type="number"
              // icon={<DollarIcon />}
              icon={
                CURRENCY_CHOICES.find((item) => item.code === values.currency)
                  ?.symbol
              }
              append={
                <>
                  <div className={styles.inputSelector}>
                    <Divider type="vertical" className={styles.divider} />
                  </div>
                  <Select
                    inputClassName={styles.currencySelector}
                    options={currencyOptions}
                    selectedOption={{
                      label: values.currency,
                      value: values.currency,
                    }}
                    onSelect={(value) => handleSelect('currency', value)}
                  />
                </>
              }
              value={values.rate}
              onChange={(e) =>
                setValues({
                  ...values,
                  rate: e.target.value as unknown as number,
                })
              }
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              label={t('label.frequency')}
              className={styles.label}
              required
            />
            <Select
              options={frequencyOptions}
              onSelect={(value) => handleSelect('frequency', value)}
              placeholder={t('label.selectFrequency')}
              listClassName={styles.selectList}
              selectedOption={{
                label:
                  frequencyOptions.find(
                    (item) =>
                      item.value === compensation?.frequency ||
                      item.value === values.frequency
                  )?.label ?? '',
                value: `${values.frequency}`,
              }}
              hideSearchHeader
            />
          </FormControl>
          <FormControl gutter="l" fullWidth={true}>
            <InputLabel
              className={styles.label}
              label={t('label.effectiveDate')}
              required
            />
            <DatePickerPopover
              id={'date'}
              size={'m'}
              paperClassName={styles.paper}
              selected={values.effective_date}
              onTextChange={handleDateChange}
              onChange={handleDateChange}
              placeholder={t('label.dobPlaceholder')}
              showResetIcon
              onReset={() => handleDateChange(undefined)}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default PeopleAddEditCompensation;
