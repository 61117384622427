import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './status-badge.module.scss';

/* eslint-disable-next-line */
export interface StatusBadgeProps {
  className?: string;
  size?: 's' | 'm' | 'l';
  status: string;
  type?: 'filled' | 'outlined';
  color?: string;
  icon?: ReactNode;
  label?: string;
}

export function StatusBadge({
  className,
  size = 'm',
  status,
  type = 'filled',
  color,
  icon,
  label,
}: StatusBadgeProps) {
  return (
    <div
      className={clsx(
        className,
        styles.root,
        styles[status],
        styles[size],
        styles[type],
        color && styles[color]
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {label || status}
    </div>
  );
}

export default StatusBadge;
