import {
  COMPONENT_STATE,
  createTag,
  getErrorMessages,
  TagPayload,
  userMsgs,
} from '@spovio/shared';
import { useSnackbar } from '@spovio/ui';
import { useState } from 'react';
import ContactTagForm from '../contact-tag-form/contact-tag-form';
import './contact-tag-add-dialog.module.scss';

/* eslint-disable-next-line */
export interface ContactTagAddDialogProps {
  onAddTag: () => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactTagAddDialog = ({ onAddTag }: ContactTagAddDialogProps) => {
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();

  const initialValues: TagPayload = {
    name: '',
  };

  const handleSubmit = async (tag: TagPayload) => {
    setComponentState(LOADING);
    try {
      const res = await createTag(tag);
      if (res.status === 201) {
        showSnackbar(true, {
          msg: userMsgs().tags.add,
          severity: 'success',
        });
        onAddTag();
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setComponentState(LOADED);
    }
  };

  return (
    <div>
      <ContactTagForm
        open
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ContactTagAddDialog;
