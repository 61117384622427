// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".arrow-dual-nav_headerLeftInnerNav__MeuGD {\n  flex-shrink: 0;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavLeft__gMloR,\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavRight__N7N-7 {\n  height: 28px;\n  width: 32px;\n  border: 1px solid #eae9eb;\n  background-color: #ffffff;\n  padding: 0;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavLeft__gMloR.arrow-dual-nav_disabled__3hFFa,\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavRight__N7N-7.arrow-dual-nav_disabled__3hFFa {\n  background-color: #efeff1;\n  pointer-events: none;\n  border: 1px solid #eae9eb;\n  opacity: 0.8;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavLeft__gMloR.arrow-dual-nav_disabled__3hFFa .arrow-dual-nav_headerLeftInnerNavIcon__DPdLW,\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavRight__N7N-7.arrow-dual-nav_disabled__3hFFa .arrow-dual-nav_headerLeftInnerNavIcon__DPdLW {\n  color: #747578;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavLeft__gMloR:hover,\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavRight__N7N-7:hover {\n  background-color: #efeff1;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavIcon__DPdLW {\n  width: 22px;\n  height: 22px;\n  color: #8d8f94;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavLeft__gMloR {\n  border-radius: 5px 0 0 5px;\n}\n.arrow-dual-nav_headerLeftInnerNav__MeuGD .arrow-dual-nav_headerLeftInnerNavRight__N7N-7 {\n  border-left: none;\n  border-radius: 0 5px 5px 0;\n}", ""]);
// Exports
exports.locals = {
	"headerLeftInnerNav": "arrow-dual-nav_headerLeftInnerNav__MeuGD",
	"headerLeftInnerNavLeft": "arrow-dual-nav_headerLeftInnerNavLeft__gMloR",
	"headerLeftInnerNavRight": "arrow-dual-nav_headerLeftInnerNavRight__N7N-7",
	"disabled": "arrow-dual-nav_disabled__3hFFa",
	"headerLeftInnerNavIcon": "arrow-dual-nav_headerLeftInnerNavIcon__DPdLW"
};
module.exports = exports;
