import { FullNameUserPayload, User, WeekDateRange } from '../types';
import moment from 'moment-timezone';
import { projectColors } from '../constants';

export const toUpperCase = (str = '') => str.toUpperCase();
export const toLowerCase = (str = '') => str.toLowerCase();
export const capitalize = (str = '') => {
  if (!str) return '';
  str = toLowerCase(str);
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getHashForString = (str: string): number => {
  let hash = 0;
  if (!str) return hash;
  str.split('').forEach((_letter, index) => {
    const charCode = str.charCodeAt(index);
    hash += charCode;
  });
  return hash;
};

export const getColorFromString = (name: string): string => {
  let random = getHashForString(name);
  random = random % projectColors.length;
  return projectColors[random];
};

export const validateForm = {
  isEmail: (email: string | undefined) => {
    if (!email) return false;
    return !validateEmail(email);
  },
  isRequired: (value: string | undefined) => {
    return value ? value.trim() !== '' : false;
  },
  isPhoneNumber: (value: any) => {
    return (
      value !== '' &&
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value)
    );
  },
  isNumber: (value: any) => {
    return value !== '' && !isNaN(value);
  },
  isPositiveNumber: (value: any) => {
    return value !== '' && !isNaN(value) && value > 0;
  },
  isDateFormat: (value: any) => {
    return value !== '' && moment(value, 'MM/DD/YYYY').isValid();
  },
};

export const getInitialsFromString = (
  name = '',
  firstLetter = true
): string => {
  if (!name) return '';
  if (firstLetter) {
    return name.trim()[0];
  }
  const names = name.split(' ');
  if (names.length > 1) {
    const initial =
      names[0].substring(0, 1) + names[names.length - 1].substring(0, 1);
    return initial;
  } else {
    return names[0].substring(0, 1);
  }
};

export const clone = <T>(data: T): T => {
  return JSON.parse(JSON.stringify(data));
};

export const getPaginationCounts = (
  page: number,
  pageSize: number,
  total: number
) => {
  const c = (page - 1) * pageSize;
  const a = total - c;
  const first = c + 1;
  let second;
  if (a > pageSize) {
    second = c + pageSize;
  } else {
    second = c + a;
  }
  return { first, second };
};

//Validation
export const validateEmail = (email: string) => {
  return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const isCurrentDate = (date: string | Date) =>
  moment(date).isSame(new Date(), 'day');

export const getDateFormat = (
  date: Date | string = moment().toDate(),
  format = 'YYYY-MM-DD',
  needTodaysLabel = false
) => {
  const isCurrentDay = isCurrentDate(date);
  if (needTodaysLabel && isCurrentDay) {
    return 'Today';
  }
  const formattedDate = moment(date).format(format);
  return formattedDate;
};

export const getFullName = (user: FullNameUserPayload): string => {
  return `${user.first_name} ${user.last_name}`;
};

export function getTimeFromNumber(value: number | undefined): string {
  if (!value) return '0:00';
  value = Math.round(value * 100) / 100;
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);

  const formattedHours = hours;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  const time = `${formattedHours}:${formattedMinutes}`;
  return time;
}

export function getDurationFromText(value: string): number {
  try {
    const values = value.split(':');
    const hours = values[0]
      ? isNaN(parseFloat(values[0]))
        ? 0
        : parseFloat(values[0])
      : 0;
    const minutes = values[1]
      ? isNaN(parseFloat(values[1]))
        ? 0
        : parseFloat(values[1]) / 60
      : 0;
    const time = hours + minutes;
    return parseFloat(Math.min(time, 999).toFixed(2));
  } catch (error) {
    return 0;
  }
}

export const getThisWeekRange = (date?: string): WeekDateRange => {
  const today = date ? moment(date, 'YYYY-MM-DD') : moment();
  const startOfWeek = today.clone().startOf('isoWeek');
  const endOfWeek = today.clone().endOf('isoWeek');
  return {
    start_date: startOfWeek.format('YYYY-MM-DD'),
    end_date: endOfWeek.format('YYYY-MM-DD'),
  };
};

export const getThisMonthRange = (date?: string): WeekDateRange => {
  const today = date ? moment(date, 'YYYY-MM-DD') : moment();
  const startOfMonth = today.clone().startOf('month');
  const endOfMonth = today.clone().endOf('month');
  return {
    start_date: startOfMonth.format('YYYY-MM-DD'),
    end_date: endOfMonth.format('YYYY-MM-DD'),
  };
};

export const getThisYearRange = (date?: string): WeekDateRange => {
  const today = date ? moment(date, 'YYYY-MM-DD') : moment();
  const startOfYear = today.clone().startOf('year');
  const endOfYear = today.clone().endOf('year');
  return {
    start_date: startOfYear.format('YYYY-MM-DD'),
    end_date: endOfYear.format('YYYY-MM-DD'),
  };
};

export function getFlattenTimeData(days: any) {
  return days.reduce((acc: any, day: any) => [...acc, ...day.times], []);
}

export function groupBy(
  objectArray: any,
  property: string | string[],
  itemsKey?: string
) {
  return objectArray.reduce((acc: any, obj: any) => {
    let key = '';
    if (typeof property === 'string') {
      key = obj[property];
    } else {
      const _key = [] as string[];
      property.forEach((prop) => _key.push(obj[prop]));
      key = _key.join('___');
    }
    if (!acc[key]) {
      acc[key] = {
        [itemsKey || 'items']: [],
      };
    }
    acc[key][itemsKey || 'items'].push(obj);
    return acc;
  }, {});
}

export function humanFileSize(size: number) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const readableSize = size / Math.pow(1024, i);
  return (
    Number(readableSize.toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}
