import {
  COMPONENT_STATE,
  currency,
  getDateFormat,
  ReimbursementFilter,
  ReimbursementRequest,
  ReimbursementRequestListType,
  REIMBURSEMENT_REQUEST_TABLE_HEADERS,
} from '@spovio/shared';
import {
  ApprovalActionIcon,
  ApprovalIcon,
  Avatar,
  AvatarSize,
  Dropdown,
  DropdownListItem,
  EyeIcon,
  NoDataContent,
  RejectIcon,
  StatusBadge,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
} from '@spovio/ui';
import clsx from 'clsx';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import styles from './reimbursement-request-table.module.scss';

const { LOADING } = COMPONENT_STATE;

/* eslint-disable-next-line */
export interface ReimbursementRequestTableProps {
  requests: ReimbursementRequest[];
  filter: ReimbursementFilter;
  onFilter: (filter: ReimbursementFilter) => void;
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, request: ReimbursementRequest) => void;
}

export function ReimbursementRequestTable({
  requests,
  filter,
  onFilter,
  componentState,
  onListAction,
}: ReimbursementRequestTableProps) {
  const { t } = useTranslation();
  const headerContent = REIMBURSEMENT_REQUEST_TABLE_HEADERS();
  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <div className={clsx(styles.heading)}>{item.label}</div>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (results: ReimbursementRequest[]) => {
    return results.map((item) => {
      const { id, requested_on, status, total_amount, created_by } = item;
      const cells = [
        {
          classes: clsx(styles.col, styles.dateCol),
          content: <div>{getDateFormat(requested_on, 'MMM DD YYYY')}</div>,
        },
        {
          classes: clsx(styles.col, styles.personCol),
          content: (
            <Avatar
              url={created_by.user.pic}
              name={created_by.user.name}
              size={AvatarSize.avatar30}
              className={styles.userIcon}
              displayName
              circle
            />
          ),
        },
        {
          classes: clsx(styles.col, styles.amountCol),
          content: (
            <div>{currency.getSymbol() + ' ' + total_amount?.toFixed(2)}</div>
          ),
        },
        {
          classes: clsx(styles.col, styles.statusCol),
          content: <StatusBadge status={lowerCase(status)} />,
        },
      ];
      const dropdownItems: DropdownListItem[] = [
        {
          label: t('label.view'),
          icon: <EyeIcon />,
          id: 'view',
        },
        {
          label: t('label.approve'),
          icon: <ApprovalActionIcon />,
          id: 'approve',
        },
        {
          label: t('label.reject'),
          icon: <RejectIcon />,
          id: 'reject',
        },
      ];
      return (
        <TableListRow
          className={styles.trow}
          key={id}
          onClick={(e) => {
            e.stopPropagation();
            onListAction('view', item);
          }}
        >
          {cells.map((item, index) => {
            return (
              <TableListCell className={item.classes} key={index}>
                {item.content}
              </TableListCell>
            );
          })}

          <TableListCell
            className={clsx(styles.col, styles.actionCol)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {status === 'REQUESTED' && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={dropdownItems}
                onItemClick={(drpId) => onListAction(drpId, item)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      const results = requests;
      if (!results?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('reimbursement.noDataRequest.head')}
              desc={t('reimbursement.noDataRequest.desc')}
              icon={<ApprovalIcon className={styles.noDataIcon} />}
            />
          </div>
        );
      }
      return getBodyRowMarkup(results);
    }
  };

  return (
    <div className={styles.root}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default ReimbursementRequestTable;
