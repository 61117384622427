import { t } from 'i18next';
export const COMPANY_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.name').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'city',
    label: t('label.city').toUpperCase(),
    className: 'cityCol',
  },
  {
    id: 'state',
    label: t('label.state').toUpperCase(),
    className: 'stateCol',
  },
  {
    id: 'country',
    label: t('label.country').toUpperCase(),
    className: 'countryCol',
  },
];

export const CONTACT_TABLE_HEADERS = () => [
  {
    id: 'name',
    label: t('label.name').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'company',
    label: t('label.company').toUpperCase(),
    className: 'companyCol',
  },
  {
    id: 'title',
    label: t('label.title').toUpperCase(),
    className: 'titleCol',
  },
  {
    id: 'phone',
    label: t('label.phone').toUpperCase(),
    className: 'phoneCol',
  },
  {
    id: 'email',
    label: t('label.email').toUpperCase(),
    className: 'emailCol',
  },
];
