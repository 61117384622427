// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Table_table__lhoR6 {\n  width: 100%;\n  border-collapse: separate;\n  table-layout: fixed;\n}\n\n.Table_th__2uQlg {\n  color: #828385;\n  font-size: 11px;\n  font-weight: 600;\n  letter-spacing: 0.5px;\n  line-height: 14px;\n  padding: 12px 20px;\n  white-space: nowrap;\n}\n\n.Table_td__1dcJF {\n  padding: 10px 20px;\n  color: #252526;\n  font-size: 13px;\n  letter-spacing: -0.35px;\n  line-height: 16px;\n}\n\n.Table_th__2uQlg {\n  border-bottom: 1px solid #edeef2;\n  text-transform: uppercase;\n}", ""]);
// Exports
exports.locals = {
	"table": "Table_table__lhoR6",
	"th": "Table_th__2uQlg",
	"td": "Table_td__1dcJF"
};
module.exports = exports;
