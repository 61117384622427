import React, { forwardRef, ReactNode, useEffect } from 'react';

import { InputBase, InputBaseProps } from '@material-ui/core';
import clsx from 'clsx';

import { InputLabel } from '../input-label/input-label';
import styles from './input.module.scss';
import { validateForm } from '@spovio/shared';

export interface InputProps extends Omit<InputBaseProps, 'autoFocus'> {
  rootClassName?: string;
  inputClassName?: string;
  iconClassName?: string;
  helperText?: string;
  InputProps?: string;
  inputWrapClass?: string;
  inline?: boolean;
  for?: string;
  noStyle?: boolean;
  label?: ReactNode | string;
  labelClassName?: string;
  validationType?: string[];
  append?: ReactNode | string;
  icon?: ReactNode | string;
  desc?: ReactNode | string;
  status?: 'invalid' | boolean;
  size?: 'xs' | 's' | 'm' | 'l';
  textAlign?: 'left' | 'right';
  focusAuto?: boolean;
}

const TextField = (
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement | null>
) => {
  const {
    inputRef = ref,
    autoComplete = 'off',
    type = 'text',
    size = 'm',
    textAlign = 'left',
    inline = false,
    disabled = false,
    fullWidth = true,
    focusAuto = false,
    multiline = false,
    id,
    inputProps,
    InputProps,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    rows,
    maxRows,
    value = '',
    desc,
    icon,
    noStyle,
    status,
    validationType,
    inputWrapClass,
    className,
    iconClassName,
    inputClassName,
    rootClassName,
    append,
    defaultValue,
    helperText,
  } = props;
  const [isValid, setIsValid] = React.useState(true);
  const [valueState, setValueState] = React.useState(value);
  const InputMore = {};

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const inputGroupClassName = clsx(inputWrapClass, {
    [styles.invalid]: status === 'invalid' || !isValid,
    [styles.hasIcon]: icon,
    [styles.hasAppend]: append,
  });

  const validate = (fieldValue: any) => {
    let valid = true;
    if (validationType) {
      if (!validationType.includes('required') && fieldValue === '') {
        setIsValid(valid);
        return;
      }
      if (validationType.includes('required') && !fieldValue) {
        valid = false;
      }
      if (
        validationType.includes('email') &&
        !validateForm.isEmail(fieldValue)
      ) {
        valid = false;
      }
      if (
        validationType.includes('phone') &&
        !validateForm.isPhoneNumber(fieldValue)
      ) {
        valid = false;
      }
      setIsValid(valid);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    validate(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    validate(e.target.value);
  };

  useEffect(() => {
    if (value !== valueState) {
      setValueState(value);
      validate(value);
    }
  }, [value]);

  return (
    <div
      className={clsx(
        styles.root,
        styles[size],
        styles[textAlign],
        rootClassName
      )}
    >
      {props.label ? (
        <InputLabel
          label={props.label}
          className={props.labelClassName}
          required={props.required}
          for={props.for}
        />
      ) : null}
      <div className={inputGroupClassName}>
        {icon ? (
          <div className={clsx(styles.iconCont, iconClassName)}>{icon}</div>
        ) : null}
        <InputBase
          id={id}
          type={type}
          name={name}
          rows={rows}
          value={value}
          inputRef={ref}
          maxRows={maxRows}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focusAuto}
          multiline={multiline}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          fullWidth={fullWidth}
          disabled={disabled}
          placeholder={placeholder}
          aria-describedby={helperTextId}
          classes={{
            input: clsx(styles.input, styles.inputBase, inputClassName, {
              [styles.noStyle]: noStyle,
              [styles.fullWidth]: fullWidth,
              [styles.inline]: inline,
            }),
          }}
          inputProps={Object.assign({}, inputProps, {
            className,
          })}
          {...InputMore}
          {...InputProps}
        />
        {append ? <div className={styles.appendCont}>{append}</div> : null}
      </div>
      {desc ? <div className={styles.textMuted}>{desc}</div> : null}
    </div>
  );
};

export const Input = forwardRef(TextField);
