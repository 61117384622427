import { debounce } from '@material-ui/core';
import {
  BasicFilter,
  clone,
  COMPONENT_STATE,
  ContactListType,
  getContacts,
  getErrorMessages,
  TagFilter,
} from '@spovio/shared';
import {
  Button,
  CloseIcon,
  Header,
  Pagination,
  SearchBar,
  useSnackbar,
} from '@spovio/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ContactTableList from '../../contacts/contact-table-list/contact-table-list';
import styles from './contact-tag-details.module.scss';

interface MatchParams {
  id: string;
}

/* eslint-disable-next-line */
export type ContactTagDetailsProps = RouteComponentProps<MatchParams>;

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactTagDetails = (props: ContactTagDetailsProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const tagFilter: TagFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
    sort_by: 'name',
    tag: props.match.params.id,
  };
  const [filter, setFilter] = useState<TagFilter>(tagFilter);
  const [contacts, setContacts] = useState<ContactListType>(
    {} as ContactListType
  );
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const requestContacts = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getContacts({
          ..._filter,
          tag: props.match.params.id,
        });
        setContacts(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.match.params.id]
  );

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      requestContacts(filter);
    },
    [requestContacts]
  );

  const getLeftHeaderContent = () => {
    return (
      <>
        {/* <Button
          className={styles.closeBtn}
          ghost
          variant="text"
          color="default"
          onClick={() => history.goBack()}
        >
          <CloseIcon />
        </Button> */}
        <h4>Contacts</h4>
      </>
    );
  };

  const getContactsTable = () => (
    <ContactTableList
      contacts={contacts.results}
      filter={filter}
      onFilter={onFilter}
      componentState={componentState}
    />
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
      </div>
    );
  };

  const getPaginationFooter = () => {
    if (!contacts) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      contacts;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  useEffect(() => {
    if (props.match.params.id) requestContacts();
  }, [requestContacts]);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={getLeftHeaderContent()}
        bottomContent={getFilters()}
      />
      {getContactsTable()}
      {getPaginationFooter()}
    </>
  );
};

export default ContactTagDetails;
