// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".skeleton_root__mxjd2 {\n  background-color: #f0eff1;\n}\n\n.skeleton_rect__3rPX- {\n  border-radius: 1px;\n}", ""]);
// Exports
exports.locals = {
	"root": "skeleton_root__mxjd2",
	"rect": "skeleton_rect__3rPX-"
};
module.exports = exports;
