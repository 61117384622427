import {
  appRoute,
  archiveEmployee,
  COMPONENT_STATE,
  getErrorMessages,
  getProfile,
  Profile,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  Header,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  PlusIcon,
  Tabs,
  Text,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useHistory } from 'react-router-dom';
import EmployeeBasicInfo from '../employee-basic-info/employee-basic-info';
import PeopleCompensation from '../people-compensation/people-compensation';
import PeopleEmergencyContact from '../people-emergency-contact/people-emergency-contact';
import PeopleFiles from '../people-files/people-files';
import PeopleOverview from '../people-overview/people-overview';
import PeopleTimeOff from '../people-time-off/people-time-off';
import PeopleTraining from '../people-training/people-training';
import styles from './people-profile.module.scss';

/* eslint-disable-next-line */
export interface PeopleProfileProps {}

enum TABS {
  OVERVIEW,
  COMPENSATION,
  EMERGENCY,
  TIME_OFF,
  FILES,
  TRAINING,
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function PeopleProfile(props: PeopleProfileProps) {
  const { t } = useTranslation();
  const [profile, setProfile] = useState({} as Profile);
  const [activeTab, setActiveTab] = useState(TABS.OVERVIEW);
  const [componentState, setComponentState] = useState(LOADING);
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { showSnackbar } = useSnackbar();
  const [employeeID, setEmployeeID] = useState(currentUser.employee_detail.id);

  const getProfileDetails = useCallback(async () => {
    const id = Number(history.location.pathname.split('/').pop());
    let res;
    try {
      if (id) {
        res = await getProfile(id);
        setEmployeeID(id);
      } else res = await getProfile(employeeID);
      setProfile(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [employeeID, history.location.pathname, showSnackbar]);

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  useEffect(() => {
    getCurrentTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const getCurrentTab = () => {
    const { pathname } = history.location;
    if (pathname.includes('/people/compensation')) {
      setActiveTab(TABS.COMPENSATION);
    } else if (pathname.includes('/people/emergency')) {
      setActiveTab(TABS.EMERGENCY);
    } else if (pathname.includes('/people/time-off')) {
      setActiveTab(TABS.TIME_OFF);
    } else if (pathname.includes('/people/files')) {
      setActiveTab(TABS.FILES);
    } else if (pathname.includes('/people/profile')) {
      setActiveTab(TABS.OVERVIEW);
    } else if (pathname.includes('/people/training')) {
      setActiveTab(TABS.TRAINING);
    }
  };

  const getPanes = () => {
    const { OVERVIEW, COMPENSATION, EMERGENCY, TIME_OFF, FILES, TRAINING } =
      TABS;
    const isPermitted =
      currentUser.people_access === 'admin' ||
      employeeID === currentUser.employee_detail.id;
    const defaultPanes = [
      {
        title: <span className={styles.label}>{t('label.overview')}</span>,
        content: (
          <Route
            exact
            path={appRoute.getPath('/people/profile/:id')}
            render={(props) => (
              <PeopleOverview
                {...props}
                isPermitted={isPermitted}
                onUpdateJobInfo={() => getProfileDetails()}
              />
            )}
          />
        ),
        key: OVERVIEW,
      },
      {
        title: <span className={styles.label}>{t('label.emergency')}</span>,
        content: (
          <Route
            exact
            path={appRoute.getPath('/people/emergency/:id')}
            render={(props) => <PeopleEmergencyContact {...props} />}
          />
        ),
        key: EMERGENCY,
      },
    ];
    const panes = [
      {
        title: <span className={styles.label}>{t('label.compensation')}</span>,
        content: (
          <Route
            exact
            path={appRoute.getPath(`/people/compensation/:id`)}
            render={(props) => <PeopleCompensation {...props} />}
          />
        ),
        key: COMPENSATION,
      },
      {
        title: <span className={styles.label}>{t('label.timeOff')}</span>,
        content: (
          <Route
            exact
            path={appRoute.getPath('/people/time-off/:id')}
            render={(props) => <PeopleTimeOff {...props} />}
          />
        ),
        key: TIME_OFF,
      },
      {
        title: <span className={styles.label}>{t('label.files')}</span>,
        content: (
          <Route
            exact
            path={appRoute.getPath('/people/files/:id')}
            render={(props) => <PeopleFiles {...props} />}
          />
        ),
        key: FILES,
      },
    ];

    const trainingTab = {
      title: <span className={styles.label}>{t('label.training')}</span>,
      content: (
        <Route
          exact
          path={appRoute.getPath('/people/training/:id')}
          render={(props) => (
            <PeopleTraining {...props} employeeId={employeeID} />
          )}
        />
      ),
      key: TRAINING,
    };

    if (isPermitted) {
      const newPanes = defaultPanes;
      newPanes.splice(1, 0, panes[0]);
      newPanes.splice(3, 0, panes[1]);
      newPanes.splice(5, 0, panes[2]);
      newPanes.push(trainingTab);
      return newPanes;
    } else return defaultPanes;
  };

  const onTabChange = (tab: number) => {
    switch (tab) {
      case TABS.OVERVIEW:
        history.push(appRoute.getRoute('/people/profile/' + employeeID));
        break;
      case TABS.COMPENSATION:
        history.push(appRoute.getRoute('/people/compensation/' + employeeID));
        break;
      case TABS.EMERGENCY:
        history.push(appRoute.getRoute('/people/emergency/' + employeeID));
        break;
      case TABS.TIME_OFF:
        history.push(appRoute.getRoute('/people/time-off/' + employeeID));
        break;
      case TABS.FILES:
        history.push(appRoute.getRoute('/people/files/' + employeeID));
        break;
      case TABS.TRAINING:
        history.push(appRoute.getRoute('/people/training/' + employeeID));
        break;
    }
    setActiveTab(tab);
  };
  const _archiveEmployee = async () => {
    showSnackbar(false);
    try {
      const res = await archiveEmployee(
        profile.employee_detail.id,
        !profile.employee_detail.is_active
      );
      if (res.status === 200) {
        showSnackbar(true, {
          msg: profile.employee_detail.is_active
            ? userMsgs().people.archive
            : userMsgs().people.unarchive,
          severity: 'success',
        });
        profile.employee_detail.is_active = !profile.employee_detail.is_active;
        setProfile({ ...profile });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onListAction = (dropId: string) => {
    if (dropId === 'archive') {
      _archiveEmployee();
    }
  };
  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.profile')}</h4>}
      />
      <Tabs
        panes={getPanes()}
        contentClassName={styles.content}
        activeTab={activeTab}
        onChange={(tab) => onTabChange(tab)}
        tabBarClassName={styles.tabBar}
        extraContent={
          profile.prev_employee !== profile.next_employee ? (
            <div className={styles.pagination}>
              <NavLink
                to={appRoute.getRoute(
                  '/people/profile/' + profile.prev_employee
                )}
              >
                <KeyboardArrowLeftIcon />
              </NavLink>
              <NavLink
                to={appRoute.getRoute(
                  '/people/profile/' + profile.next_employee
                )}
              >
                <KeyboardArrowRightIcon />
              </NavLink>
            </div>
          ) : undefined
        }
        leftSection={
          <EmployeeBasicInfo
            profile={profile}
            componentState={componentState}
            onListAction={onListAction}
          />
        }
      />
    </>
  );
}

export default PeopleProfile;
