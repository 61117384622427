import { validateForm } from '@spovio/shared';
import { Dialog, FormControl, Input, useSnackbar } from '@spovio/ui';
import { t } from 'i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './people-time-off-type-form.module.scss';

interface typePayload {
  name: string;
}

export interface PeopleTimeOffTypeFormProps {
  open: boolean;
  title?: string;
  action?: string;
  initialValues: typePayload;
  onSubmit: (initialValues: typePayload) => void;
}

export function PeopleTimeOffTypeForm({
  open,
  initialValues,
  title = t('people.timeOff.addType'),
  action = t('label.add'),
  onSubmit,
}: PeopleTimeOffTypeFormProps) {
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState(initialValues);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(values);
    setIsModalVisible(false);
  };

  const isValid = () => {
    return validateForm.isRequired(values.name);
  };

  return (
    <div className={styles.root}>
      {!isModalVisible && history.goBack()}
      <Dialog
        title={title}
        action={action}
        open={isModalVisible}
        isActionDisabled={!isValid()}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              name="name"
              labelClassName={styles.label}
              required
              validationType={['required']}
              label={t('label.name')}
              placeholder={t('people.timeOff.typeName')}
              value={values.name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default PeopleTimeOffTypeForm;
