import AcceptInvite from '../pages/accept-invite/accept-invite';
import ConfirmEmail from '../pages/confirm-email/confirm-email';
import CreatePwd from '../pages/create-pwd/create-pwd';
import ForgotPwd from '../pages/forgot-pwd/forgot-pwd';
import Login from '../pages/login/login';
import Onboard from '../pages/onboard/onboard';
import Register from '../pages/register/register';
import ResetPassword from '../pages/reset-password/reset-password';

export const publicAppRoutes = () => [
  {
    path: '/login',
    component: Login,
  },
  // {
  //   path: '/register',
  //   component: Register,
  // },
  {
    path: '/password/reset',
    component: ForgotPwd,
  },
  {
    path: '/verify-email/:token',
    component: CreatePwd,
  },
  {
    path: '/email-confirm',
    component: ConfirmEmail,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
  },
  {
    path: '/create-profile',
    component: Onboard,
  },
  {
    path: '/accept-invite/:token',
    component: AcceptInvite,
  },
];
