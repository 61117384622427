// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reimbursement-request-list_header__amMgm {\n  flex-direction: column;\n  padding-top: 20px;\n  width: inherit;\n}\n.reimbursement-request-list_header__amMgm .reimbursement-request-list_bottomContent__2QSr5 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 30px 10px 30px;\n}\n.reimbursement-request-list_header__amMgm .reimbursement-request-list_bottomContent__2QSr5 .reimbursement-request-list_filterButton__3Q_xj {\n  padding: 6px 8px;\n}\n.reimbursement-request-list_header__amMgm .reimbursement-request-list_bottomContent__2QSr5 .reimbursement-request-list_filterButton__3Q_xj .reimbursement-request-list_filterName__cpw4Q {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n  margin-left: 6px;\n}\n\n.reimbursement-request-list_searchBar__abpVC {\n  border: 1px solid #d4d4d5;\n  border-radius: 6px;\n  background-color: #fff;\n  padding: 0 12px;\n  outline: 0;\n  width: auto;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  width: 300px;\n}\n\n.reimbursement-request-list_pagination__cHdSN {\n  padding: 25px 30px;\n}\n.reimbursement-request-list_pagination__cHdSN .reimbursement-request-list_paper__2qAVF {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}", ""]);
// Exports
exports.locals = {
	"header": "reimbursement-request-list_header__amMgm",
	"bottomContent": "reimbursement-request-list_bottomContent__2QSr5",
	"filterButton": "reimbursement-request-list_filterButton__3Q_xj",
	"filterName": "reimbursement-request-list_filterName__cpw4Q",
	"searchBar": "reimbursement-request-list_searchBar__abpVC",
	"pagination": "reimbursement-request-list_pagination__cHdSN",
	"paper": "reimbursement-request-list_paper__2qAVF"
};
module.exports = exports;
