import {
  addJobInfo,
  appRoute,
  Compensation,
  COMPONENT_STATE,
  deleteJobInfo,
  DirectReportType,
  DIRECT_REPORT_TABLE_HEADERS,
  Employee,
  getCompensations,
  getDateFormat,
  getDirectReports,
  getErrorMessages,
  getJobInfos,
  getLatestCompensation,
  JobInfo,
  JobInfoPayload,
  JOB_INFO_TABLE_HEADERS,
  updateJobInfo,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Button,
  ConfirmationContext,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  NoDataContent,
  PeopleIcon,
  PlusIcon,
  Skeleton,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import PeopleAddJobInfo from '../people-add-job-info/people-add-job-info';
import styles from './people-overview.module.scss';

export interface PeopleOverviewProps {
  isPermitted?: boolean;
  onUpdateJobInfo: () => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function PeopleOverview({
  isPermitted,
  onUpdateJobInfo,
}: PeopleOverviewProps) {
  const { t } = useTranslation();
  const [addJobInfoDialog, setAddJobInfoDialog] = useState(false);
  const [selectedJobInfo, setSelectedJobInfo] = useState({} as JobInfo);
  const [isEditing, setIsEditing] = useState(false);
  const [jobInfos, setJobInfos] = useState([] as JobInfo[]);
  const [directReports, setDirectReports] = useState([] as DirectReportType[]);
  const [compensation, setCompensation] = useState({} as Compensation);
  const [componentState, setComponentState] = useState(LOADING);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { currentUser } = useCurrentUser();
  const [employeeID, setEmployeeID] = useState(currentUser.employee_detail.id);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const { isAdmin } = useModuleAccess('people_access');

  const headerContent = JOB_INFO_TABLE_HEADERS();
  const directReportHeaderContent = DIRECT_REPORT_TABLE_HEADERS;

  const hasEmployeeAccess = () => {
    const id = Number(history.location.pathname.split('/').pop()) || employeeID;
    return (
      currentUser.people_access === 'admin' ||
      id === currentUser.employee_detail.id
    );
  };

  const getInitialValues = useCallback(async () => {
    const id = Number(history.location.pathname.split('/').pop());
    let res;
    let jobInfoRes, directReportRes;
    try {
      if (id) {
        jobInfoRes = await getJobInfos(id);
        if (hasEmployeeAccess()) res = await getLatestCompensation(id);
        directReportRes = await getDirectReports(id);
        setEmployeeID(id);
      } else {
        jobInfoRes = await getJobInfos(employeeID);
        if (hasEmployeeAccess()) res = await getLatestCompensation(employeeID);
        directReportRes = await getDirectReports(employeeID);
      }
      setJobInfos(jobInfoRes.data.results);
      hasEmployeeAccess() && res?.data && setCompensation(res.data);
      setDirectReports(directReportRes.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [employeeID, history.location.pathname, showSnackbar]);

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues, isPermitted]);

  const handleDialogClose = () => {
    setAddJobInfoDialog(false);
    setIsEditing(false);
  };

  const returnCurrentId = () => {
    const id = Number(history.location.pathname.split('/').pop());
    if (id) return id;
    else return employeeID;
  };

  const onDeleteJobInfo = async (id: number) => {
    showSnackbar(false);
    try {
      const res = await deleteJobInfo(id, employeeID);
      if (res.status === 204) {
        setJobInfos(jobInfos.filter((jobInfo) => jobInfo.id !== id));
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().jobInfo.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const deleteConfirmation = (jobInfo: JobInfo) => {
    showConfirmation(true, {
      header: t('people.jobInformationDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteJobInfo(jobInfo.id),
    });
  };

  const handleJobInfoSubmit = async (values: JobInfoPayload) => {
    showSnackbar(false);
    try {
      if (isEditing) {
        // update existing job info record
        const jobInfoPayload = {
          title: values.title,
          department: values.department,
          division: values.division,
          location: values.location,
          manager: values.manager,
          effective_date: values.effective_date,
        };
        const res = await updateJobInfo(
          selectedJobInfo.id,
          jobInfoPayload,
          employeeID
        );
        const updatedJobInfos = jobInfos.map((jobInfo) => {
          if (jobInfo.id === res.data.id) {
            return res.data;
          }
          return jobInfo;
        });
        setJobInfos(updatedJobInfos);
        setIsEditing(false);
        showSnackbar(true, {
          msg: userMsgs().jobInfo.edit,
          severity: 'success',
        });
      } else {
        // add new job info record
        const res = await addJobInfo(values, employeeID);
        setJobInfos([res.data, ...jobInfos]);
        setAddJobInfoDialog(false);
        showSnackbar(true, {
          msg: userMsgs().jobInfo.add,
          severity: 'success',
        });
      }
      onUpdateJobInfo();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setIsEditing(false);
      setAddJobInfoDialog(false);
    }
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getContentMarkup = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
        to: `/people/${'id'}/edit`,
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, jobInfo: JobInfo) => {
      if (dropId === 'edit') {
        setSelectedJobInfo(jobInfo);
        setIsEditing(true);
      } else if (dropId === 'delete') {
        deleteConfirmation(jobInfo);
      }
    };
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = headerContent.map((item) => 90);
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          hasActionBtn={true}
        />
      );
    } else if (!jobInfos.length) {
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title="Job Information"
            desc={'No job information found.'}
            icon={<PeopleIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    } else
      return jobInfos.map((item, index) => {
        return (
          <TableListRow className={styles.trow} key={index}>
            <TableListCell
              className={clsx(styles.titleCol, styles.cell)}
              key="title"
            >
              <Text variant="body1" className="ellipsis">
                {item.title}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.departmentCol, styles.cell)}
              key="department"
            >
              <Text variant="body1" className="ellipsis">
                {item.department?.name}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.divisionCol, styles.cell)}
              key="division"
            >
              <Text variant="body1" className="ellipsis">
                {item.division?.name}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.locationCol, styles.cell)}
              key="location"
            >
              <Text variant="body1" className="ellipsis">
                {item.location?.name}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.dateCol, styles.cell)}
              key="date"
            >
              <Text variant="body1">
                {getDateFormat(item.effective_date, 'MM/DD/YYYY')}
              </Text>
            </TableListCell>
            <TableListCell className={clsx(styles.cell, styles.actionCol)}>
              {isAdmin && (
                <Dropdown
                  buttonClassName={styles.actionContent}
                  data={dropdownItems}
                  onItemClick={(drpId) => onListAction(drpId, item)}
                />
              )}
            </TableListCell>
          </TableListRow>
        );
      });
  };

  const getJobInfo = () => {
    return (
      <div>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.jobInformation')}
          </Text>
          {isPermitted && (
            <Button
              className={styles.addItemBtn}
              variant="text"
              onClick={() => setAddJobInfoDialog(true)}
            >
              <PlusIcon className={styles.plusIcon} />{' '}
              {t('label.addInformation')}
            </Button>
          )}
        </div>
        <div className={styles.tableWrap}>
          <TableList className={styles.table}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
      </div>
    );
  };

  const getCompensation = () => {
    const firstCompensation = compensation;
    const getCompensationSkeleton = () => {
      return Array.from(Array(3).keys()).map((key) => (
        <div className={styles.details} key={key}>
          <Skeleton width={240} height={15} className={styles.skeleton} />
          <Skeleton width={140} height={15} className={styles.skeleton} />
        </div>
      ));
    };

    if (firstCompensation?.id)
      return (
        <div className={styles.compensation}>
          <div className={styles.sectionHeader}>
            <Text variant="h4" className={styles.heading}>
              {t('label.compensation')}
            </Text>
            {hasEmployeeAccess() && (
              <Button
                className={styles.addItemBtn}
                variant="text"
                component={NavLink}
                to={appRoute.getRoute(
                  `/people/compensation/${returnCurrentId()}`
                )}
              >
                {t('label.viewAll')}
              </Button>
            )}
          </div>
          {componentState === LOADING ? (
            getCompensationSkeleton()
          ) : (
            <div className={styles.details}>
              <Text variant="body1" color="dove_grey">
                {firstCompensation.rate?.toFixed(2) +
                  ' ' +
                  firstCompensation.currency +
                  ' per ' +
                  firstCompensation.frequency?.toLowerCase()}
              </Text>
              <Text variant="body1" color="dark_grey">
                {t('label.effectiveDateOn')}{' '}
                {getDateFormat(firstCompensation.effective_date, 'MMM DD YYYY')}
              </Text>
            </div>
          )}
        </div>
      );
    else return null;
  };

  const getDirectReportUsers = () => {
    if (!directReports.length) return;
    const getReportHeaderRowMarkup = () => {
      return (
        <TableListRow className={styles.headerRow}>
          {directReportHeaderContent.map((item) => {
            return (
              <TableListCell className={styles[item.className]} key={item.id}>
                <Text variant="tableHeader" className={styles.tableHeader}>
                  {item.label}
                </Text>
              </TableListCell>
            );
          })}
        </TableListRow>
      );
    };

    const getReportContentMarkup = () => {
      if (componentState === LOADING) {
        const colStyles = headerContent.map((item) => styles[item.className]);
        const widths = headerContent.map((item) => 90);
        return (
          <TablePreloader
            rows={5}
            columns={headerContent.length}
            colStyles={colStyles}
            widths={widths}
            hasActionBtn={true}
          />
        );
      } else
        return directReports.map((item, index) => {
          return (
            <TableListRow
              className={clsx(styles.trow, styles.cursorPointer)}
              key={index}
              onClick={(e) => {
                history.push(appRoute.getRoute(`/people/profile/${item.id}`));
                e.stopPropagation();
              }}
            >
              <TableListCell
                className={clsx(styles.nameCol, styles.cell)}
                key="title"
              >
                <Text variant="body1" className="ellipsis">
                  {item.name}
                </Text>
              </TableListCell>
              <TableListCell
                className={clsx(styles.titleCol, styles.cell)}
                key="title"
              >
                <Text variant="body1" className="ellipsis">
                  {item.title}
                </Text>
              </TableListCell>
              <TableListCell
                className={clsx(styles.departmentCol, styles.cell)}
                key="title"
              >
                <Text variant="body1" className="ellipsis">
                  {item.department?.name}
                </Text>
              </TableListCell>
            </TableListRow>
          );
        });
    };

    return (
      <div>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            Direct reports
          </Text>
        </div>
        <div className={clsx(styles.tableWrap, styles.directReport)}>
          <TableList className={styles.table}>
            <TableListHead>{getReportHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getReportContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
      </div>
    );
  };

  const getAddJobInfoDialog = () => {
    if (addJobInfoDialog || isEditing) {
      return (
        <PeopleAddJobInfo
          open={addJobInfoDialog || isEditing}
          jobInfo={
            isEditing
              ? selectedJobInfo
              : ({ effective_date: getDateFormat(new Date()) } as JobInfo)
          }
          onClose={handleDialogClose}
          onSubmit={handleJobInfoSubmit}
        />
      );
    } else return null;
  };

  return (
    <>
      <div className={styles.overview}>
        {getJobInfo()}
        {getDirectReportUsers()}
        {hasEmployeeAccess() && getCompensation()}
      </div>
      {getAddJobInfoDialog()}
    </>
  );
}

export default PeopleOverview;
