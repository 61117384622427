import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const EllipsisOutlinedIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="12"
      height="4"
      viewBox="0 0 12 4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM6 .5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm4.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
        fill="#6D6971"
        fillRule="evenodd"
      />
    </svg>
  );
};
