// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".snackbar_root__273ih {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 22px;\n  box-shadow: 0 6px 16px -4px rgba(36, 37, 42, 0.25);\n  height: 38px;\n  min-width: 158px;\n}\n\n.snackbar_success__1Z7T- {\n  background-color: #26cb6c;\n}\n\n.snackbar_error__3UW9u {\n  background-color: #e24545;\n}\n\n.snackbar_warning__2YpO9 {\n  background-color: #ffae42;\n}\n\n.snackbar_msg__1PETU {\n  color: #ffffff;\n  font-size: 13px;\n  font-weight: 600;\n  letter-spacing: -0.1px;\n  line-height: 16px;\n}\n\n.snackbar_wrap__2eVwd {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 11px 18px 11px 12px;\n}\n\n.snackbar_iconWrap__K23zP {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 6px;\n}\n.snackbar_iconWrap__K23zP svg {\n  width: 16px;\n  height: 16px;\n}\n\n.snackbar_closeIcon__2bJzx {\n  width: 28px;\n  padding: 0;\n  margin-right: -6px;\n  margin-left: 20px;\n}\n.snackbar_closeIcon__2bJzx svg {\n  color: rgba(255, 255, 255, 0.45);\n}\n.snackbar_closeIcon__2bJzx:hover {\n  background-color: rgba(255, 255, 255, 0.07);\n}\n.snackbar_closeIcon__2bJzx:hover svg {\n  color: #fff;\n}", ""]);
// Exports
exports.locals = {
	"root": "snackbar_root__273ih",
	"success": "snackbar_success__1Z7T-",
	"error": "snackbar_error__3UW9u",
	"warning": "snackbar_warning__2YpO9",
	"msg": "snackbar_msg__1PETU",
	"wrap": "snackbar_wrap__2eVwd",
	"iconWrap": "snackbar_iconWrap__K23zP",
	"closeIcon": "snackbar_closeIcon__2bJzx"
};
module.exports = exports;
