import { getProjectBasicList, getProjects, ProjectItem } from '@spovio/shared';
import {
  Dialog,
  FormControl,
  InputLabel,
  Select,
  useCurrentUser,
} from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-row-project.module.scss';

/* eslint-disable-next-line */
export interface AddRowProjectProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAdd: (value: ProjectItem) => void;
  projectList: ProjectItem[];
}

export function AddRowProject({
  open,
  onClose,
  onAdd,
  projectList,
}: AddRowProjectProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const [selectedProject, setSelectedProject] = useState<ProjectItem>();

  const projectOptions = projectList.map((project) => {
    return {
      label: project.name,
      value: `${project.id}`,
    };
  });

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    selectedProject && onAdd(selectedProject);
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectProject = (value: any) => {
    const project = projectList.find((project) => project.id === Number(value));
    project && setSelectedProject(project);
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={t('label.addRow')}
        action={t('label.add')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={handleClose}
        onSubmit={handleSubmit}
        isActionDisabled={!selectedProject}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={false} className={styles.leftCol}>
            <InputLabel
              label={t('label.project')}
              className={styles.label}
              required
            />
            <Select
              options={projectOptions}
              onSelect={onSelectProject}
              placeholder={t('label.selectProject')}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default AddRowProject;
