// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".time-entry-input_root__19vVa {\n  border: 1px solid #e0dee0;\n  border-radius: 6px;\n  background-color: #FFFFFF;\n  outline: none;\n  color: #24252A;\n  font-size: 14px;\n  letter-spacing: 0;\n  line-height: 16px;\n  transition: background-color 0.2s, border-color 0.2s;\n  text-align: right;\n  height: 36px;\n  width: 100%;\n  box-sizing: border-box;\n}\n.time-entry-input_root__19vVa.time-entry-input_autoWidth___E75Y {\n  min-width: 45px;\n  max-width: 200px;\n}\n.time-entry-input_root__19vVa.time-entry-input_left__3dTvF {\n  text-align: left;\n  padding: 2px 10px 2px 10px;\n}\n.time-entry-input_root__19vVa.time-entry-input_frozen__2Lnt- {\n  padding-left: 0;\n  border-left: 0;\n  border-radius: 0;\n  padding-right: 0;\n  margin: 0 18px 0 10px;\n  border: none;\n}\n.time-entry-input_root__19vVa.time-entry-input_frozen__2Lnt-:hover {\n  background-color: transparent;\n}\n.time-entry-input_root__19vVa.time-entry-input_locked__Dw9MX {\n  cursor: default;\n  border: transparent;\n}\n.time-entry-input_root__19vVa.time-entry-input_locked__Dw9MX:hover {\n  background-color: transparent;\n}\n.time-entry-input_root__19vVa input::placeholder {\n  color: #A9ABAF;\n}\n.time-entry-input_root__19vVa:focus:not(.time-entry-input_frozen__2Lnt-):not(.time-entry-input_locked__Dw9MX) {\n  border-color: #0075f3;\n  box-shadow: 0 0 0 1px #0075f3;\n}\n.time-entry-input_root__19vVa[contenteditable=true]:empty:before {\n  content: attr(placeholder);\n}\n.time-entry-input_root__19vVa[disabled] {\n  pointer-events: none;\n}\n\n.time-entry-input_rootM__35YP8 {\n  padding: 9px 12px;\n  height: 36px;\n}\n.time-entry-input_rootS__2ov2W {\n  border: 1px solid #C7C9CF;\n  border-radius: 6px;\n  background-color: #FFFFFF;\n  color: #24252A;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.23px;\n  line-height: 18px;\n  text-align: right;\n  padding: 5px 10px;\n  height: 28px;\n  box-sizing: border-box;\n}", ""]);
// Exports
exports.locals = {
	"root": "time-entry-input_root__19vVa",
	"autoWidth": "time-entry-input_autoWidth___E75Y",
	"left": "time-entry-input_left__3dTvF",
	"frozen": "time-entry-input_frozen__2Lnt-",
	"locked": "time-entry-input_locked__Dw9MX",
	"rootM": "time-entry-input_rootM__35YP8",
	"rootS": "time-entry-input_rootS__2ov2W"
};
module.exports = exports;
