import {
  COMPONENT_STATE,
  PEOPLE_ACCESS_TABLE_HEADERS,
  useModuleAccess,
  User,
} from '@spovio/shared';
import {
  ApprovedIcon,
  ArchiveIcon,
  Avatar,
  AvatarSize,
  DeleteIcon,
  Dropdown,
  EditIcon,
  EyeIcon,
  NoAccessIcon,
  NoDataContent,
  PasswordIcon,
  PendingIcon,
  PeopleIcon,
  StatusBadge,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  useCurrentUser,
} from '@spovio/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './settings-user-list-table.module.scss';

const { LOADING } = COMPONENT_STATE;

/* eslint-disable-next-line */
export interface SettingsUserListTableProps {
  people: User[];
  componentState: COMPONENT_STATE;
  onListAction: (dropId: string, people: User) => void;
  isArchivedUsers: boolean;
}

export function SettingsUserListTable({
  people,
  componentState,
  onListAction,
  isArchivedUsers,
}: SettingsUserListTableProps) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const headerContent = PEOPLE_ACCESS_TABLE_HEADERS();

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'admin':
        return t('label.admin');
      case 'member':
        return t('label.member');
      default:
        return t('label.noAccess');
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'admin':
        return <ApprovedIcon />;
      case 'member':
        return <PendingIcon />;
      default:
        return <NoAccessIcon />;
    }
  };

  const getColorOfStatus = (status: string) => {
    switch (status) {
      case 'admin':
        return 'green';
      case 'member':
        return 'yellow';
      default:
        return 'red';
    }
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.row}>
        {headerContent.map((item) => {
          return (
            <TableListCell
              className={clsx(styles.col, styles[item.className])}
              key={item.id}
            >
              <div className={clsx(styles.heading)}>{item.label}</div>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getBodyRowMarkup = (people: User[]) => {
    const getDropdownItems = (user: User) => {
      if (isArchivedUsers) {
        return [
          {
            label: t('label.restore'),
            icon: <ArchiveIcon />,
            id: 'restore',
          },
        ];
      }
      return [
        {
          label: t('label.view'),
          icon: <EyeIcon />,
          id: 'view',
        },
        {
          label: t('label.edit'),
          icon: <EditIcon />,
          id: 'edit',
        },
        {
          label: t('label.archive'),
          icon: <ArchiveIcon />,
          id: 'archive',
        },
        {
          label: t('label.resetPassword'),
          icon: <PasswordIcon />,
          id: 'send-password-reset',
        },
        {
          label: t('label.delete'),
          id: 'delete',
          icon: <DeleteIcon />,
        },
      ];
    };

    return people.map((user) => {
      return (
        <TableListRow
          key={user.id}
          className={styles.trow}
          onClick={(e) => {
            e.stopPropagation();
            onListAction('view', user);
          }}
        >
          <TableListCell
            className={clsx(styles.col, styles.nameCol)}
            key="name"
          >
            <Avatar
              url={user.pic}
              name={user.name}
              email={user.email}
              size={AvatarSize.avatar32}
              className={styles.userIcon}
              badge={
                user.is_pending ? (
                  <StatusBadge
                    className={styles.invitedStatusBadge}
                    size="s"
                    status={t('label.pending')}
                    color={'pending'}
                  />
                ) : null
              }
              displayName
              circle
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.col, styles.contactCol)}
            key="contact"
          >
            <StatusBadge
              status={getStatusLabel(user.contact_access)}
              type={'outlined'}
              icon={getStatusIcon(user.contact_access)}
              color={getColorOfStatus(user.contact_access)}
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.col, styles.peopleCol)}
            key="people"
          >
            <StatusBadge
              status={getStatusLabel(user.people_access)}
              type={'outlined'}
              icon={getStatusIcon(user.people_access)}
              color={getColorOfStatus(user.people_access)}
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.col, styles.reimbursementCol)}
            key="reimbursement"
          >
            <StatusBadge
              status={getStatusLabel(user.reimbursement_access)}
              type={'outlined'}
              icon={getStatusIcon(user.reimbursement_access)}
              color={getColorOfStatus(user.reimbursement_access)}
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.col, styles.timeCol)}
            key="time"
          >
            <StatusBadge
              status={getStatusLabel(user.time_access)}
              type={'outlined'}
              icon={getStatusIcon(user.time_access)}
              color={getColorOfStatus(user.time_access)}
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.col, styles.dimensionCol)}
            key="dimension"
          >
            <StatusBadge
              status={getStatusLabel(user.dimension_access)}
              type={'outlined'}
              icon={getStatusIcon(user.dimension_access)}
              color={getColorOfStatus(user.dimension_access)}
            />
          </TableListCell>
          <TableListCell
            className={clsx(styles.col, styles.actionCol)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {currentUser.is_owner && (
              <Dropdown
                buttonClassName={styles.actionContent}
                data={getDropdownItems(user)}
                onItemClick={(drpId) => onListAction(drpId, user)}
              />
            )}
          </TableListCell>
        </TableListRow>
      );
    });
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = [...headerContent.map((item) => 90)];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
          hasActionBtn
          actionColClassName={styles.actionColPreloader}
        />
      );
    } else {
      // const { results } = people;
      if (!people?.length) {
        return (
          <div className={styles.blankListWrap}>
            <NoDataContent
              title={t('settings.noDataUser.head')}
              desc={t('settings.noDataUser.desc')}
              icon={<PeopleIcon className={styles.noDataIcon} />}
            />
          </div>
        );
      }
      return getBodyRowMarkup(people);
    }
  };

  return (
    <div className={styles.root}>
      <TableList className={styles.table}>
        <TableListHead className={styles.head}>
          {getHeaderRowMarkup()}
        </TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
    </div>
  );
}

export default SettingsUserListTable;
