import {
  COMPONENT_STATE,
  getDateFormat,
  getTimeFromNumber,
  GroupedReportItem,
  HeaderList,
  ReportTimeItem,
  toLowerCase,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Ellipsis,
  NoDataContent,
  ReportIcon,
  Spin,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
} from '@spovio/ui';
import clsx from 'clsx';
import { t } from 'i18next';
import styles from './time-reports-table.module.scss';

/* eslint-disable-next-line */
export interface TimeReportsTableProps {
  componentState: COMPONENT_STATE;
  groupBy: string;
  reportList: GroupedReportItem[];
  totalHours: number;
  isSpinning: boolean;
  lastActivityElemRef: any;
  onLoadMore: () => void;
  headers: HeaderList[];
  noDataMessage?: string;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function TimeReportsTable({
  componentState,
  groupBy,
  reportList,
  totalHours,
  isSpinning,
  onLoadMore,
  lastActivityElemRef,
  headers,
  noDataMessage = t('reports.noData.desc'),
}: TimeReportsTableProps) {
  const headerContent = headers;

  const getSortedHeaderContent = () => {
    if (groupBy === 'NONE') {
      return headerContent;
    }
    const sortedHeaderContent = [...headers];
    return sortedHeaderContent.sort((a, b) => {
      if (a.id === toLowerCase(groupBy)) {
        return -1;
      }
      return 0;
    });
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {getSortedHeaderContent().map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
      </TableListRow>
    );
  };

  const getColByProperty = (property: string, time: ReportTimeItem) => {
    switch (property) {
      case 'date':
      case 'from_date':
        return (
          <Text variant="body1" fontWeight="medium">
            {getDateFormat(time.date, 'MMM DD YYYY')}
          </Text>
        );
      case 'project':
      case 'type': {
        return (
          <div className={styles.projectWrap}>
            <Text variant="body1" className={styles.project}>
              {time.type_name ? time.type_name : time.project_name}
            </Text>
            {groupBy !== 'PROJECT' && groupBy !== 'TYPE' && (
              <Text variant="body1" className={styles.notes}>
                {time.notes || time.note}
              </Text>
            )}
          </div>
        );
      }
      case 'person': {
        return (
          <Avatar
            url={time.user_pic}
            name={time.name}
            size={AvatarSize.avatar26}
            className={styles.userIcon}
            displayName
            textClassName={styles.userText}
            circle
          />
        );
      }
      case 'hours': {
        return <Text variant="body1">{getTimeFromNumber(time.hours)}</Text>;
      }
      default:
        return null;
    }
  };

  const getTableContentMarkup = () => {
    if (!reportList.length || !reportList[0].times.length)
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('label.reports')}
            desc={noDataMessage}
            icon={<ReportIcon className={styles.reportIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    if (groupBy === 'NONE') {
      return reportList[0].times.map((time, index) => {
        return (
          <TableListRow
            className={styles.trow}
            key={index}
            ref={
              reportList[0].times.length === index + 1
                ? lastActivityElemRef
                : undefined
            }
          >
            {headerContent.map((item) => {
              return (
                <TableListCell
                  className={clsx(styles[item.className], styles.col)}
                  key={item.id}
                >
                  {getColByProperty(item.id, time)}
                </TableListCell>
              );
            })}
          </TableListRow>
        );
      });
    } else {
      let counter = 0;
      const flatReportList = reportList.reduce((acc, item) => {
        return [...acc, ...item.times];
      }, [] as ReportTimeItem[]);
      return reportList.map((report, reportIndex) => (
        <div className={styles.tContentWrap} key={reportIndex}>
          {report.times.map((time, index) => {
            counter++;
            return (
              <TableListRow
                className={clsx(styles.trow)}
                key={counter}
                ref={
                  flatReportList.length === counter
                    ? lastActivityElemRef
                    : undefined
                }
              >
                {getSortedHeaderContent().map((item) => {
                  return (
                    <TableListCell
                      className={clsx(styles[item.className], styles.col)}
                      key={item.id}
                    >
                      {getColByProperty(item.id, time)}
                    </TableListCell>
                  );
                })}
              </TableListRow>
            );
          })}
        </div>
      ));
    }
  };

  const getContentMarkup = () => {
    if (componentState === LOADING) {
      const colStyles = getSortedHeaderContent().map(
        (item) => styles[item.className]
      );
      const widths = [90, 300, 90, 90];
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          rowStyles={[styles.trow, styles.alignCenter]}
        />
      );
    } else return getTableContentMarkup();
  };

  const getTotalRowMarkup = () => {
    return (
      <div className={styles.totalWrap}>
        <Ellipsis className={styles.info}>{t('label.total')}:</Ellipsis>
        <Text variant="h4" fontWeight="semi-bold">
          {getTimeFromNumber(totalHours)}
        </Text>
      </div>
    );
  };

  return (
    <div className={styles.tableWrap}>
      <TableList className={styles.table}>
        <TableListHead>{getHeaderRowMarkup()}</TableListHead>
        <TableListBody className={styles.body}>
          {getContentMarkup()}
        </TableListBody>
      </TableList>
      {isSpinning ? <Spin className={styles.moreLoadingSpin} /> : null}
      {reportList[0]?.times.length ? getTotalRowMarkup() : null}
    </div>
  );
}

export default TimeReportsTable;
