import React from 'react';

import MuiSkeleton from '@material-ui/lab/Skeleton';

import styles from './skeleton.module.scss';

interface SkeletonProps {
  animation?: 'pulse' | 'wave' | false;
  children?: React.ReactNode;
  height?: number | string;
  variant?: 'text' | 'rect' | 'circle';
  width?: number | string;
  className?: string;
}

export const Skeleton = ({
  animation = false,
  variant = 'text',

  ...props
}: SkeletonProps) => {
  return (
    <MuiSkeleton
      animation={animation}
      classes={{ root: styles.root, rect: styles.rect }}
      variant={variant}
      {...props}
    />
  );
};
