import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const PasswordIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1 8.376v4.14a2.484 2.484 0 0 0 2.484 2.485h6.625a2.484 2.484 0 0 0 2.485-2.484V8.376a2.484 2.484 0 0 0-2.485-2.484H3.484A2.484 2.484 0 0 0 1 8.376Z"
        stroke="#A2A5A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39 5.89V3.746A2.746 2.746 0 0 0 6.645 1h-.18a2.566 2.566 0 0 0-2.566 2.566v0"
        stroke="#A2A5A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.264 10.678a1.019 1.019 0 1 0-.887-.03l-.339 1.221a.2.2 0 0 0 .193.254h1.171a.2.2 0 0 0 .193-.254l-.331-1.191Z"
        fill="#A2A5A8"
      />
    </svg>
  );
};
