import {
  appInfo,
  COMPONENT_STATE,
  Employee,
  EmployeeList,
  getErrorMessages,
  getHeadcount,
  getPeopleList,
  HeadcountData,
} from '@spovio/shared';
import {
  CalendarIcon,
  CompensationIcon,
  DatePickerPopover,
  Divider,
  Header,
  NoDataContent,
  TableMarkup,
  TableMarkupColumns,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeadcountChart from '../../headcount-chart/headcount-chart';
import styles from './headcount.module.scss';

/* eslint-disable-next-line */
interface HeadcountProps {}
const { LOADED, LOADING } = COMPONENT_STATE;
export const Headcount = (props: HeadcountProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [people, setPeople] = useState<Employee[]>();
  const [graphData, setGraphData] = useState<HeadcountData[]>();
  const { showSnackbar } = useSnackbar();

  const filter_info = {
    date: moment().startOf('month').format(appInfo.defaultDateFormat),
  };
  const [filter, setFilter] = useState(filter_info);

  const init = useCallback(async () => {
    setComponentState(LOADING);
    try {
      const res = await getHeadcount(filter);
      const people: Employee[] = [];
      res.data.forEach((item) => {
        item.employees.forEach((emp) => {
          people.push(emp);
        });
      });
      setPeople(removeDuplicates(people) as Employee[]);
      setGraphData(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [filter]);

  const removeDuplicates = (arr: Employee[]) => {
    return [...new Set(arr.map((item) => item.id))].map((id) => {
      return arr.find((item) => item.id === id);
    });
  };

  useEffect(() => {
    init();
  }, [init]);

  const getColumns = (): TableMarkupColumns[] => {
    const columns = [
      {
        title: t('label.people'),
        dataIndex: 'employee',
        key: 'employee',
        className: styles.dateCol,
      },
      {
        title: t('label.effectiveDate'),
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: t('label.jobTitle'),
        dataIndex: 'job_title',
        key: 'job_title',
      },
      {
        title: t('label.department'),
        dataIndex: 'department',
        key: 'department',
      },
      {
        title: t('label.location'),
        dataIndex: 'location',
        key: 'location',
      },
    ];

    return columns;
  };

  const getDataSource = () => {
    return people?.map((item) => {
      return {
        key: item.id,
        employee: item.name,
        date: moment(item.job_information?.effective_date).format('DD/MM/YYYY'),
        job_title: item.job_information.title,
        department: item.job_information?.department.name,
        location: item.job_information?.location.name,
        data: item,
      };
    });
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={
          <div className={styles.title}>
            <h4>{t('label.headcount')} </h4> &nbsp;
            {people ? (
              <span>
                ({people?.length}) {t('label.people')}
              </span>
            ) : null}
          </div>
        }
      />
      <div className={styles.filter}>
        <div className={styles.dateFilter}>
          {moment(filter.date).format('DD/MM/YYYY')}
          <DatePickerPopover
            id={'timesheet-date-picker-popover'}
            onChange={(date) => {
              setFilter({
                ...filter,
                date: moment(date).format(appInfo.defaultDateFormat),
              });
            }}
            selected={new Date(filter.date)}
            buttonContent={
              <CalendarIcon
                className={styles.headerRightInnerCalendarBtnIcon}
              />
            }
          />
        </div>
      </div>
      <Divider />
      {graphData ? <HeadcountChart graphData={graphData} /> : null}
      <Divider />
      <TableMarkup
        columns={getColumns()}
        dataSource={getDataSource()}
        isLoading={componentState === LOADING}
        tableClassName={styles.table}
        noData={
          <NoDataContent
            title={t('label.headcount')}
            desc={t('extension.noDataFound')}
            icon={<CompensationIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        }
      />
    </>
  );
};
export default Headcount;
