// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table-list-cell_root__3BY6y {\n  display: flex;\n  align-items: center;\n  padding-top: 12px;\n  box-sizing: border-box;\n  position: relative;\n  width: 13%;\n}\n.table-list-cell_root__3BY6y:first-of-type {\n  padding-left: 30px;\n  width: 15%;\n}\n.table-list-cell_root__3BY6y:last-child {\n  border-right: none;\n}\n\n.table-list-cell_ellipsis__FOxox:after {\n  display: block;\n  width: 60px;\n  height: 90%;\n  position: absolute;\n  content: \"\";\n  right: 0;\n  top: 2px;\n  background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));\n}", ""]);
// Exports
exports.locals = {
	"root": "table-list-cell_root__3BY6y",
	"ellipsis": "table-list-cell_ellipsis__FOxox"
};
module.exports = exports;
