// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".project-add_content__1P_Rc {\n  padding: 20px 25px 0 25px;\n}\n\n.project-add_chooseMember__74WYI {\n  border: 1px solid #c7c9cf66;\n  width: 100% !important;\n}\n\n.project-add_userSelectorWrap__35FrD {\n  box-sizing: border-box;\n  border: 1px solid #e0dee0;\n  border-radius: 6px;\n  background-color: #ffffff;\n  padding: 0 15px 8px;\n}\n\n.project-add_divider__2F4g8 {\n  height: 1px;\n  margin-bottom: 3px;\n}\n\n.project-add_simpleTaskListWrap__1VX6B {\n  margin: 0 -7px;\n}\n\n.project-add_input__TMfhE {\n  width: inherit !important;\n}\n\n.project-add_autoCompleteInput__2PYiu {\n  border: none;\n  box-shadow: none !important;\n}", ""]);
// Exports
exports.locals = {
	"content": "project-add_content__1P_Rc",
	"chooseMember": "project-add_chooseMember__74WYI",
	"userSelectorWrap": "project-add_userSelectorWrap__35FrD",
	"divider": "project-add_divider__2F4g8",
	"simpleTaskListWrap": "project-add_simpleTaskListWrap__1VX6B",
	"input": "project-add_input__TMfhE",
	"autoCompleteInput": "project-add_autoCompleteInput__2PYiu"
};
module.exports = exports;
