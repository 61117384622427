import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ApprovedIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 14"
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.167 11.667v-.584A4.083 4.083 0 0 1 5.25 7"
        stroke="#30CA6C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.218 7.182a.944.944 0 0 1 1.396 0c.19.208.461.32.742.308a.944.944 0 0 1 .987.986c-.014.281.1.553.307.742a.944.944 0 0 1 0 1.396.944.944 0 0 0-.307.742.944.944 0 0 1-.987.987.944.944 0 0 0-.742.307.944.944 0 0 1-1.396 0 .944.944 0 0 0-.742-.307.944.944 0 0 1-.986-.987.944.944 0 0 0-.308-.742.944.944 0 0 1 0-1.396.944.944 0 0 0 .308-.742.944.944 0 0 1 .986-.986c.281.013.553-.1.742-.308Z"
        stroke="#30CA6C"
      />
      <path
        d="m8.962 9.917.636.636 1.273-1.273M5.25 7a2.333 2.333 0 1 0 0-4.667A2.333 2.333 0 0 0 5.25 7Z"
        stroke="#30CA6C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
