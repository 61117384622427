import { debounce } from '@material-ui/core';
import {
  appRoute,
  BasicFilter,
  clone,
  COMPONENT_STATE,
  Contact,
  ContactFilter,
  ContactListType,
  deleteContact,
  getContacts,
  getErrorMessages,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  Spin,
  useConfirmation,
  useSnackbar,
} from '@spovio/ui';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useHistory } from 'react-router-dom';
import ContactAddDialog from '../contact-add-dialog/contact-add-dialog';
import ContactDetails from '../contact-details/contact-details';
import ContactEditDialog from '../contact-edit-dialog/contact-edit-dialog';
import { ContactTableList } from '../contact-table-list/contact-table-list';
import styles from './contact-list.module.scss';

/* eslint-disable-next-line */
export interface ContactListProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export function ContactList(props: ContactListProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const basicFilter: ContactFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
    sort_by: 'name',
  };
  const [filter, setFilter] = useState<ContactFilter>(basicFilter);
  const [contacts, setContacts] = useState<ContactListType>(
    {} as ContactListType
  );
  const { showSnackbar } = useSnackbar();
  const { showConfirmation } = useConfirmation();
  const { isAdmin } = useModuleAccess('contact_access');

  const history = useHistory();

  const requestContacts = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getContacts(_filter);
        setContacts(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getContactCreateButton = () => {
    if (!isAdmin) return null;
    return (
      <Button
        size={'s'}
        component={NavLink}
        to={appRoute.getRoute(`/contacts/list/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('contact.addContact')}
        </>
      </Button>
    );
  };

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      requestContacts(filter);
    },
    [requestContacts]
  );
  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
      </div>
    );
  };

  const deleteTagFromList = async (id: number) => {
    showSnackbar(false);
    try {
      const res = await deleteContact(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().contact.delete,
          severity: 'success',
        });
        requestContacts();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (contact: Contact) => {
    showConfirmation(true, {
      header: t('contact.deleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => deleteTagFromList(contact.id),
    });
  };

  const getContactsTable = () => (
    <ContactTableList
      contacts={contacts.results}
      filter={filter}
      onFilter={onFilter}
      componentState={componentState}
      onListAction={(dropId, contact) => {
        if (dropId === 'view') {
          history.push(appRoute.getRoute(`/contacts/list/${contact.id}/view`));
        } else if (dropId === 'edit') {
          history.push(appRoute.getRoute(`/contacts/list/${contact.id}/edit`));
        } else if (dropId === 'delete') {
          deleteConfirmation(contact);
        }
      }}
    />
  );

  const getPaginationFooter = () => {
    if (!contacts) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      contacts;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  const getContactRoutes = () => (
    <Suspense fallback={<Spin size="full" />}>
      <Route
        exact
        path={appRoute.getPath(`/contacts/list/add`)}
        render={() => (
          <ContactAddDialog
            onAddContact={() => {
              requestContacts();
            }}
          />
        )}
      />
      <Route
        exact
        path={appRoute.getPath(`/contacts/list/:id/edit`)}
        render={(props) => (
          <ContactEditDialog
            {...props}
            onEdit={(data: Contact) => {
              const _contact = contacts.results.find(
                (contact) => contact.id === Number(props.match.params.id)
              );
              _contact && Object.assign(_contact, data);
              setContacts({ ...contacts });
            }}
          />
        )}
      />
    </Suspense>
  );

  useEffect(() => {
    requestContacts();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.contacts')}</h4>}
        rightContent={getContactCreateButton()}
        bottomContent={getFilters()}
      />
      {getContactsTable()}
      {getPaginationFooter()}
      {getContactRoutes()}
    </>
  );
}

export default ContactList;
