import {
  COMPONENT_STATE,
  getCalendarTimeOffs,
  TimeOffTypePayload,
} from '@spovio/shared';
import { Header, Spin } from '@spovio/ui';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './people-calendar.module.scss';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// eslint-disable-next-line @typescript-eslint/no-var-requires

const { LOADED, LOADING } = COMPONENT_STATE;

/* eslint-disable-next-line */
export interface PeopleCalendarProps {}

interface eventType {
  title: string;
  start: string;
  end: string;
}

export function PeopleCalendar(props: PeopleCalendarProps) {
  const [componentState, setComponentState] = useState(LOADED);
  const { t, i18n } = useTranslation();

  const getTimeOffList = async (filter: any) => {
    try {
      const res = await getCalendarTimeOffs(filter);
      const events = res.data.map((item) => {
        return {
          title:
            item.employee.name +
            ' - ' +
            item.used +
            ' ' +
            t('label.hours').toLowerCase(),
          start: item.from_date,
          end: item.to_date,
        };
      });
      return Promise.resolve(events);
    } catch {
      return Promise.reject(undefined);
    }
  };

  if (componentState === COMPONENT_STATE.LOADING) {
    return <Spin size="full" />;
  } else {
    return (
      <>
        <Header
          className={styles.header}
          leftContent={<h4>{t('calender.timeOffCalendar')}</h4>}
        />
        <div className={styles.calendarWrap}>
          <div className={styles.calendar}>
            <FullCalendar
              allDayText={t('label.allDay')}
              buttonText={{
                today: t('label.today').toLowerCase(),
                month: t('label.month').toLowerCase(),
                week: t('label.week').toLowerCase(),
              }}
              views={{
                week: { timeGrid: false },
                agenda: {
                  scrollGrid: false,
                },
              }}
              locale={i18n.language}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              // allDaySlot={false}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek',
              }}
              navLinks={true}
              editable={false}
              dayMaxEvents={true}
              events={(a: any, callback: any) => {
                const filterBody = {
                  start_date: moment(a.start).format('YYYY-MM-DD'),
                  end_date: moment(a.end).format('YYYY-MM-DD'),
                };
                getTimeOffList(filterBody).then((res) => {
                  callback(res);
                });
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PeopleCalendar;
