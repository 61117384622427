import { User } from '@spovio/shared';
import {
  AccountSettingsIcon,
  Avatar,
  AvatarSize,
  ContactListIcon,
  Dialog,
  DimensionsIcon,
  Divider,
  FormControl,
  PeopleIcon,
  ReimbursementIcon,
  Text,
  TimeIcon,
} from '@spovio/ui';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './settings-view-user.module.scss';

/* eslint-disable-next-line */
export interface SettingsViewUserProps {
  open: boolean;
  user: User;
  onClose: () => void;
}

const roleOptions = () => [
  {
    label: t('label.admin'),
    value: 'admin',
  },
  {
    label: t('label.member'),
    value: 'member',
  },
  {
    label: t('label.noAccess'),
    value: 'no_access',
  },
];

export function SettingsViewUser({
  open = false,
  user,
  onClose,
}: SettingsViewUserProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open);

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const getDialogTitle = () => {
    return (
      <div className={styles.dialogTitle}>
        <Avatar
          url={user.pic}
          name={user.name}
          email={user.email}
          size={AvatarSize.avatar48}
          className={styles.userIcon}
          displayName
          circle
        />
      </div>
    );
  };
  useEffect(() => {
    setIsModalVisible(open);
  }, [open]);

  return (
    <div className={styles.root}>
      <Dialog
        title={getDialogTitle()}
        // action={'Save'}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        // onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={styles.access}>
            <FormControl gutter="s" className={styles.row}>
              <div className={styles.role}>
                <ContactListIcon className={styles.icon} />
                <Text variant="h5" className={styles.roleTitle}>
                  {t('label.contacts')}:
                </Text>
              </div>
              <Text variant="h5" className={styles.roleDetail}>
                {
                  roleOptions().find(
                    (option) => option.value === user.contact_access
                  )?.label
                }
              </Text>
            </FormControl>
            <FormControl gutter="s" className={styles.row}>
              <div className={styles.role}>
                <PeopleIcon className={styles.icon} />
                <Text variant="h5" className={styles.roleTitle}>
                  {t('label.people')}:
                </Text>
              </div>
              <Text variant="h5" className={styles.roleDetail}>
                {
                  roleOptions().find(
                    (option) => option.value === user.people_access
                  )?.label
                }
              </Text>
            </FormControl>
            <FormControl gutter="s" className={styles.row}>
              <div className={styles.role}>
                <ReimbursementIcon className={styles.icon} />
                <Text variant="h5" className={styles.roleTitle}>
                  {t('label.reimbursements')}:
                </Text>
              </div>
              <Text variant="h5" className={styles.roleDetail}>
                {
                  roleOptions().find(
                    (option) => option.value === user.reimbursement_access
                  )?.label
                }
              </Text>
            </FormControl>
            <FormControl gutter="s" className={styles.row}>
              <div className={styles.role}>
                <TimeIcon className={styles.icon} />
                <Text variant="h5" className={styles.roleTitle}>
                  {t('label.time')}:
                </Text>
              </div>
              <Text variant="h5" className={styles.roleDetail}>
                {
                  roleOptions().find(
                    (option) => option.value === user.time_access
                  )?.label
                }
              </Text>
            </FormControl>
            <FormControl gutter="s" className={styles.row}>
              <div className={styles.role}>
                <DimensionsIcon className={styles.icon} />
                <Text variant="h5" className={styles.roleTitle}>
                  {t('label.dimensions')}:
                </Text>
              </div>
              <Text variant="h5" className={styles.roleDetail}>
                {
                  roleOptions().find(
                    (option) => option.value === user.dimension_access
                  )?.label
                }
              </Text>
            </FormControl>
            <FormControl gutter="s" className={styles.row}>
              <div className={styles.role}>
                <AccountSettingsIcon className={styles.icon} />
                <Text variant="h5" className={styles.roleTitle}>
                  {t('label.settings')}:
                </Text>
              </div>
              <Text variant="h5" className={styles.roleDetail}>
                {
                  roleOptions().find(
                    (option) => option.value === user.settings_access
                  )?.label
                }
              </Text>
            </FormControl>
          </div>
          <Divider />
          <Text variant="h6" className={styles.heading}>
            {t('label.auditTrail')}
          </Text>
          <ul className={styles.auditTrial}>
            {user.last_login && (
              <li className={styles.listItem}>
                <Text variant="body1" className={styles.subHeading}>
                  {t('label.lastLogin')}
                </Text>
                <Text variant="body1" className={styles.value}>
                  {moment(user.last_login)
                    .utc()
                    .format('MMM DD, YYYY hh:mm A z')}
                </Text>
              </li>
            )}
            <li className={styles.listItem}>
              <Text variant="body1" className={styles.subHeading}>
                {t('label.createdOn')}
              </Text>
              <Text variant="body1" className={styles.value}>
                {moment(user.updated_at).utc().format('MMM DD, YYYY hh:mm A z')}
              </Text>
            </li>
            <li className={styles.listItem}>
              <Text variant="body1" className={styles.subHeading}>
                {t('label.updatedOn')}
              </Text>
              <Text variant="body1" className={styles.value}>
                {moment(user.updated_at).utc().format('MMM DD, YYYY hh:mm A z')}
              </Text>
            </li>
          </ul>
        </div>
      </Dialog>
    </div>
  );
}

export default SettingsViewUser;
