// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tabs_tabButton__3dqxs {\n  background-color: transparent;\n  border: none;\n  font-weight: 400;\n}\n.tabs_tabButton__3dqxs.tabs_gutter-m__2hbRT {\n  margin-right: 10px;\n}\n.tabs_tabButton__3dqxs.tabs_gutter-s__3zobO {\n  margin-right: 8px;\n  padding: 0 8px;\n}\n.tabs_tabButton__3dqxs:last-child {\n  margin-right: 0;\n}\n.tabs_tabButton__3dqxs:hover {\n  color: #24252a;\n}\n\n.tabs_tabBar__1AdmX {\n  height: 44px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.tabs_tabBar__1AdmX .tabs_tabs__16kap {\n  display: flex;\n  align-items: center;\n  overflow-x: auto;\n  padding: 8px 30px;\n}\n\n.tabs_extraContent__2Nb6N {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.tabs_content__3_MI- {\n  height: 100%;\n}\n\n.tabs_fullScreenDivider__3IGtw {\n  display: none;\n}\n@media (min-width: 1024px) {\n  .tabs_fullScreenDivider__3IGtw {\n    display: block;\n  }\n}\n\n@media (min-width: 1024px) {\n  .tabs_mobileDivider__1CWZP {\n    display: none;\n  }\n}", ""]);
// Exports
exports.locals = {
	"tabButton": "tabs_tabButton__3dqxs",
	"gutter-m": "tabs_gutter-m__2hbRT",
	"gutter-s": "tabs_gutter-s__3zobO",
	"tabBar": "tabs_tabBar__1AdmX",
	"tabs": "tabs_tabs__16kap",
	"extraContent": "tabs_extraContent__2Nb6N",
	"content": "tabs_content__3_MI-",
	"fullScreenDivider": "tabs_fullScreenDivider__3IGtw",
	"mobileDivider": "tabs_mobileDivider__1CWZP"
};
module.exports = exports;
