import React, { forwardRef } from 'react';

import TextareaAutosize, {
  TextareaAutosizeProps,
} from '@material-ui/core/TextareaAutosize';
import clsx from 'clsx';

import styles from './text-area.module.scss';

interface TextAreaProps extends TextareaAutosizeProps {
  size?: 's' | 'm' | 'l';
  noStyle?: boolean;
  focusAuto?: boolean;
  disableResize?: boolean;
}

const TextAreaInput = (
  {
    className,
    size = 'm',
    noStyle,
    focusAuto = false,
    disableResize,
    ...restProps
  }: TextAreaProps,
  ref: React.Ref<HTMLTextAreaElement> | undefined
) => {
  return (
    <TextareaAutosize
      ref={ref}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={focusAuto}
      className={clsx(
        styles.textarea,
        className,
        styles[size],
        noStyle && styles.noStyle,
        disableResize && styles.noResize
      )}
      {...restProps}
    />
  );
};

export const Textarea = forwardRef(TextAreaInput);
