import axios, { AxiosResponse } from 'axios';
import { BasicFilter, Pagination } from '../types';
import { stringify } from 'query-string';
import {
  GroupedReports,
  OverallSummaryItem,
  ReportItem,
  TimeOffItem,
  TimesByProjectItem,
  TimesByWeekItemExtended,
  TimesItem,
  TimeWeekApprovalType,
  WeekListType,
} from '../types/timesheet';

export const getTimeSummary = async (
  userID: number
): Promise<AxiosResponse<OverallSummaryItem>> => {
  let url = `/times/overall-summary/`;
  if (userID) {
    url += `?user=${userID}`;
  }
  return await axios.get(url);
};

export const getTimesByWeek = async (
  start: string,
  end: string,
  user_id?: number
): Promise<AxiosResponse<TimesByWeekItemExtended>> => {
  const payload = {
    start,
    end,
    user_id,
  };
  return await axios.post(`/times/times-by-week/`, payload);
};

export const getReportsByWeek = async (
  start: string,
  end: string,
  users?: number[],
  projects?: number[],
  page?: number
): Promise<AxiosResponse<Pagination<ReportItem>>> => {
  const payload = {
    start,
    end,
    users,
    projects,
  };
  let url = '/report/times/?';
  if (page) {
    url += `page=${page}&`;
  }
  return await axios.post(url, payload);
};

export const weeklyTimeDelete = async (
  date: string,
  project_id: number,
  user: number
): Promise<AxiosResponse<TimesItem>> => {
  const payload = {
    date,
    project_id,
    user,
  };
  return await axios.post(`/times/weekly-delete/`, payload);
};

export const getGroupedReportsByWeek = async (
  start: string,
  end: string,
  groupedBy: string,
  users?: number[],
  projects?: number[],
  page?: number
): Promise<AxiosResponse<Pagination<GroupedReports>>> => {
  const payload = {
    start,
    end,
    users,
    projects,
  };
  let url = `/report/times/?group_by=${groupedBy}&`;
  if (page) {
    url += `page=${page}&`;
  }
  return await axios.post(url, payload);
};

export const exportReport = async (
  start: string,
  end: string,
  users?: number[],
  projects?: number[]
  // groupedBy: string,
): Promise<AxiosResponse<any>> => {
  const payload = {
    start,
    end,
    users,
    projects,
  };
  const url = `/report/times/export/`;
  return await axios.post(url, payload, {
    responseType: 'blob',
  });
};

export const getPendingTimeSheets = async (filter: {
  start_date?: string;
  end_date?: string;
  employees: number[];
}): Promise<AxiosResponse<TimeOffItem[]>> => {
  return await axios.post(`/times/approval-list/`, filter);
};

export const getApprovedTimeSheets = async (filter: {
  start_date?: string;
  end_date?: string;
  employees: number[];
}): Promise<AxiosResponse<TimeOffItem[]>> => {
  return await axios.post(`/times/approved-list/`, filter);
};

export const getApprovalListDetail = async (
  id: number
): Promise<AxiosResponse<TimeWeekApprovalType>> => {
  return await axios.get(`/times/${id}/approval-list/`);
};

export const approveTimeSheets = async (times: {
  ids: number[];
}): Promise<AxiosResponse<TimesItem[]>> => {
  return await axios.post(`/times/approve/`, times);
};

export const unlockTimeSheets = async (times: {
  ids: number[];
}): Promise<AxiosResponse<TimesItem[]>> => {
  return await axios.post(`/times/unlock/`, times);
};

export const unlockTimeSheetsByWeek = async (payload: {
  date: string;
  user_id?: number;
}): Promise<AxiosResponse<TimesItem[]>> => {
  return await axios.post(`/times/unlock-by-week/`, payload);
};

export const createTime = async (
  data: Partial<TimesItem>
): Promise<AxiosResponse<TimesItem>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload: any = data;
  payload.project = payload.project.id;
  return await axios.post(`/times/`, payload);
};

export const updateTime = async (
  data: Partial<TimesItem>
): Promise<AxiosResponse<TimesItem>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload: any = data;
  if (payload.project) {
    payload.project = payload.project.id;
  }
  return await axios.patch(`/times/${data.id}/`, payload);
};

export const deleteTime = async (
  id: number
): Promise<AxiosResponse<TimesItem>> => {
  return await axios.delete(`/times/${id}/`);
};

export const submitForApproval = async (
  start: string,
  end: string,
  userId?: number
): Promise<AxiosResponse<TimesItem[]>> => {
  const payload = {
    start,
    end,
    user_id: userId,
  };
  return await axios.post(`/times/submit-for-approval/`, payload);
};

export const getTimesByProject = async (
  start: string,
  end: string,
  user_id?: number
): Promise<AxiosResponse<TimesByProjectItem>> => {
  const payload = {
    start,
    end,
    user_id,
  };
  return await axios.post(`/times/times-by-project/`, payload);
};

export const getIncompleteWeeks = async (
  dateRange?: {
    start_date: string;
    end_date: string;
  },
  user_ids?: number[],
  filters?: BasicFilter
): Promise<AxiosResponse<WeekListType>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return axios.post(`/times/weekly-status/?${queryParams}`, {
    start: dateRange?.start_date,
    end: dateRange?.end_date,
    user_ids,
  });
};
