import {
  addEmergencyContact,
  COMPONENT_STATE,
  deleteEmergencyContact,
  EmergencyContact,
  EmergencyContactPayload,
  EMERGENCY_TABLE_HEADERS,
  getEmergencyContacts,
  getErrorMessages,
  updateEmergencyContact,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  EmergencyContactIcon,
  NoDataContent,
  PlusIcon,
  TableList,
  TableListBody,
  TableListCell,
  TableListHead,
  TableListRow,
  TablePreloader,
  Text,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PeopleAddEditEmergencyContact from '../people-add-edit-emergency-contact/people-add-edit-emergency-contact';
import styles from './people-emergency-contact.module.scss';

const { LOADED, LOADING } = COMPONENT_STATE;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PeopleEmergencyContactProps {}

export function PeopleEmergencyContact(props: PeopleEmergencyContactProps) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [employeeID, setEmployeeID] = useState(currentUser.employee_detail.id);
  const [addContactDialog, setAddContactDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [contacts, setContacts] = useState<EmergencyContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<EmergencyContact>(
    {} as EmergencyContact
  );
  const [componentState, setComponentState] = useState(LOADING);
  const { showConfirmation } = useContext(ConfirmationContext);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();

  const headerContent = EMERGENCY_TABLE_HEADERS();

  const getInitialContacts = useCallback(async () => {
    const id = Number(history.location.pathname.split('/').pop());
    let res;
    try {
      if (id) {
        setEmployeeID(id);
        res = await getEmergencyContacts(id);
      } else {
        res = await getEmergencyContacts(employeeID);
      }
      setContacts(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [employeeID, history.location.pathname]);

  useEffect(() => {
    getInitialContacts();
  }, [getInitialContacts]);

  const hasEmployeeAccess = () => {
    const id = Number(history.location.pathname.split('/').pop()) || employeeID;
    return (
      currentUser.people_access === 'admin' ||
      id === currentUser.employee_detail.id
    );
  };

  const handleContactSubmit = async (contact: EmergencyContactPayload) => {
    try {
      if (isEditing) {
        const contactPayload = {
          email: contact.email,
          name: contact.name,
          phone: contact.phone,
          relation: contact.relation,
        };
        const response = await updateEmergencyContact(
          employeeID,
          selectedContact.id,
          contactPayload
        );
        if (response.status === 200) {
          const updatedContacts = contacts.map((c) => {
            if (c.id === response.data.id) {
              return response.data;
            }
            return c;
          });
          setContacts(updatedContacts);
          showSnackbar(true, {
            msg: userMsgs().contact.edit,
            severity: 'success',
          });
          setIsEditing(false);
        }
      } else {
        const response = await addEmergencyContact(employeeID, contact);
        if (response.status === 200) {
          setContacts([...contacts, response.data]);
          showSnackbar(true, {
            msg: userMsgs().contact.add,
            severity: 'success',
          });
          setAddContactDialog(false);
        }
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      setAddContactDialog(false);
      setIsEditing(false);
    }
  };

  const onDeleteContact = async (id: number) => {
    try {
      const res = await deleteEmergencyContact(employeeID, id);
      if (res.status === 200) {
        const updatedContacts = contacts.filter((c) => c.id !== id);
        setContacts(updatedContacts);
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().contact.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const deleteConfirmation = (contact: EmergencyContact) => {
    showConfirmation(true, {
      header: t('contact.deleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteContact(contact.id),
    });
  };

  const handleDialogClose = () => {
    setAddContactDialog(false);
    setIsEditing(false);
  };

  const getHeaderRowMarkup = () => {
    return (
      <TableListRow className={styles.headerRow}>
        {headerContent.map((item) => {
          return (
            <TableListCell className={styles[item.className]} key={item.id}>
              <Text variant="tableHeader" className={styles.tableHeader}>
                {item.label}
              </Text>
            </TableListCell>
          );
        })}
        <TableListCell className={styles.actionCol}></TableListCell>
      </TableListRow>
    );
  };

  const getContentMarkup = () => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
        to: `/people/${'id'}/edit`,
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];
    const onListAction = (dropId: string, contact: EmergencyContact) => {
      if (dropId === 'edit') {
        setSelectedContact(contact);
        setIsEditing(true);
      } else if (dropId === 'delete') {
        deleteConfirmation(contact);
      }
    };
    if (componentState === LOADING) {
      const colStyles = headerContent.map((item) => styles[item.className]);
      const widths = headerContent.map((item) => 90);
      return (
        <TablePreloader
          rows={5}
          columns={headerContent.length}
          colStyles={colStyles}
          widths={widths}
          hasActionBtn={true}
          rowStyles={[styles.trow]}
        />
      );
    } else if (!contacts.length) {
      return (
        <div className={styles.blankListWrap}>
          <NoDataContent
            title={t('label.contacts')}
            desc={t('contact.noContactsAddedYet')}
            icon={<EmergencyContactIcon className={styles.noDataIcon} />}
            containerClassName={styles.blankList}
          />
        </div>
      );
    } else
      return contacts.map((item, index) => {
        return (
          <TableListRow className={styles.trow} key={index}>
            <TableListCell
              className={clsx(styles.nameCol, styles.cell)}
              key="name"
            >
              <Text variant="body1" className="ellipsis">
                {item.name}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.relationshipCol, styles.cell)}
              key="relationship"
            >
              <Text variant="body1" className="ellipsis">
                {item.relation}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.phoneCol, styles.cell)}
              key="phone"
            >
              <Text variant="body1" className="ellipsis">
                {item.phone}
              </Text>
            </TableListCell>
            <TableListCell
              className={clsx(styles.emailCol, styles.cell)}
              key="email"
            >
              <Text variant="body1" className="ellipsis">
                {item.email}
              </Text>
            </TableListCell>

            <TableListCell className={clsx(styles.cell, styles.actionCol)}>
              {hasEmployeeAccess() && (
                <Dropdown
                  buttonClassName={styles.actionContent}
                  data={dropdownItems}
                  onItemClick={(drpId) => onListAction(drpId, item)}
                />
              )}
            </TableListCell>
          </TableListRow>
        );
      });
  };

  const getEmergencySection = () => {
    return (
      <div>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.contactDetails')}
          </Text>
          <Button
            className={styles.addItemBtn}
            variant="text"
            onClick={() => setAddContactDialog(true)}
          >
            <PlusIcon className={styles.plusIcon} /> {t('contact.addContact')}
          </Button>
        </div>
        <div className={styles.tableWrap}>
          <TableList className={styles.table}>
            <TableListHead>{getHeaderRowMarkup()}</TableListHead>
            <TableListBody className={styles.body}>
              {getContentMarkup()}
            </TableListBody>
          </TableList>
        </div>
      </div>
    );
  };

  const getAddContactDialog = () => {
    if (addContactDialog || isEditing) {
      return (
        <PeopleAddEditEmergencyContact
          open={addContactDialog || isEditing}
          contact={isEditing ? selectedContact : ({} as EmergencyContact)}
          onClose={handleDialogClose}
          onSubmit={handleContactSubmit}
        />
      );
    } else return null;
  };

  return (
    <>
      <div className={styles.emergency}>{getEmergencySection()}</div>
      {getAddContactDialog()}
    </>
  );
}

export default PeopleEmergencyContact;
