import React, { memo, ReactNode, useState } from 'react';

import { snackBarConfig } from '@spovio/shared';
import Slide from '@material-ui/core/Slide';
import MuiSnackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import { TransitionProps } from '@material-ui/core/transitions';

import { Button } from '../button/button';
import {
  CloseIcon,
  DeleteIcon,
  EditIcon,
  ErrorSnackbarIcon,
  FileCopyIcon,
  InfoSnackbarIcon,
  SuccussSnackbarIcon,
  WarningSnackbarIcon,
} from '../icons';
import styles from './snackbar.module.scss';
import clsx from 'clsx';

export interface SnackBarProps {
  open: boolean;
  onClose: () => void;
  msg: string | ReactNode;
  severity?:
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'delete'
    | 'duplicate'
    | 'edit';
}

const severityMapping = {
  error: ErrorSnackbarIcon,
  warning: WarningSnackbarIcon,
  info: InfoSnackbarIcon,
  success: SuccussSnackbarIcon,
  delete: DeleteIcon,
  duplicate: FileCopyIcon,
  edit: EditIcon,
};

export const Snackbar = memo(
  ({ open, msg, severity = 'success', onClose }: SnackBarProps) => {
    const SlideTransition = (props: TransitionProps) => (
      <Slide {...props} direction="down" />
    );
    const [state] = useState({ Transition: SlideTransition });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClose = (_e: any, reason: SnackbarCloseReason) => {
      reason !== 'clickaway' && onClose();
    };
    const Icon = severityMapping[severity];
    return (
      <MuiSnackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        {...snackBarConfig}
      >
        <div className={clsx(styles.root, styles[severity])}>
          <div className={styles.wrap}>
            {severity ? (
              <div className={styles.iconWrap}>
                <Icon />
              </div>
            ) : null}
            <div className={styles.msg}>{msg}</div>
          </div>
        </div>
      </MuiSnackbar>
    );
  }
);
