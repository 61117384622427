// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reset-password_root__3b24B {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  padding-top: 180px;\n}\n.reset-password_root__3b24B .reset-password_title__D4o6V {\n  position: absolute;\n  top: 25px;\n  left: 30px;\n  margin: 0;\n}\n\n.reset-password_innerContent__1lW9R {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 420px;\n  width: calc(100% - 16px);\n}\n\n.reset-password_heading__1XFWA {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n\n.reset-password_helperText__3ED6P {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin: auto;\n  margin-bottom: 56px;\n  width: 300px;\n}\n\n.reset-password_submitBtn__3sLZi {\n  font-size: 15px;\n  font-weight: 600;\n  letter-spacing: -0.12px;\n  line-height: 19px;\n  text-align: center;\n}\n\n.reset-password_option__3fS7U {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #6d6971;\n  font-size: 13px;\n  margin-top: 40px;\n}\n.reset-password_option__3fS7U .reset-password_linkText__2LPEe {\n  display: flex;\n  color: #747578;\n  font-size: 16px;\n  font-weight: 600;\n  letter-spacing: -0.25px;\n  line-height: 20px;\n  text-align: center;\n}\n.reset-password_option__3fS7U .reset-password_linkText__2LPEe > svg {\n  width: 20px;\n  height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"root": "reset-password_root__3b24B",
	"title": "reset-password_title__D4o6V",
	"innerContent": "reset-password_innerContent__1lW9R",
	"heading": "reset-password_heading__1XFWA",
	"helperText": "reset-password_helperText__3ED6P",
	"submitBtn": "reset-password_submitBtn__3sLZi",
	"option": "reset-password_option__3fS7U",
	"linkText": "reset-password_linkText__2LPEe"
};
module.exports = exports;
