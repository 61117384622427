import React from 'react';

type CloseIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const CalendarIcon = (props: CloseIconProps) => {
  return (
    <svg
      {...props}
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>calender</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M9.67722222,0.5 C10.0380738,0.5 10.3362955,0.768150689 10.3834932,1.11605806 L10.39,1.21277778 L10.39,1.54087346 L11,1.54087346 C12.3254834,1.54087346 13.4100387,2.5724094 13.4946823,3.87649777 L13.5,4.04087346 L13.5,11 C13.5,12.3254834 12.4684641,13.4100387 11.1643757,13.4946823 L11,13.5 L3,13.5 C1.6745166,13.5 0.589961328,12.4684641 0.50531768,11.1643757 L0.5,11 L0.5,4.04087346 C0.5,2.71539006 1.53153594,1.63083479 2.83562431,1.54619114 L3,1.54087346 L3.650556,1.54087346 L3.650556,1.21296274 C3.650556,0.819204292 3.96976029,0.5 4.36351874,0.5 C4.72446399,0.5 5.02276303,0.768220273 5.06997297,1.11621792 L5.07648148,1.21296274 L5.07648148,1.54087346 L8.96444444,1.54087346 L8.96444444,1.21277778 C8.96444444,0.819121481 9.28356593,0.5 9.67722222,0.5 Z M11.8,4.5 L2.2,4.5 C1.95454011,4.5 1.75039163,4.67687516 1.70805567,4.91012437 L1.7,5 L1.7,11.1 C1.7,11.7213203 2.17219968,12.2323525 2.77730697,12.2938045 L2.9,12.3 L11.1,12.3 C11.7213203,12.3 12.2323525,11.8278003 12.2938045,11.222693 L12.3,11.1 L12.3,5 C12.3,4.72385763 12.0761424,4.5 11.8,4.5 Z M4.78,5.91 C5.05614237,5.91 5.28,6.13385763 5.28,6.41 L5.28,7.41 C5.28,7.68614237 5.05614237,7.91 4.78,7.91 L3.78,7.91 C3.50385763,7.91 3.28,7.68614237 3.28,7.41 L3.28,6.41 C3.28,6.13385763 3.50385763,5.91 3.78,5.91 L4.78,5.91 Z"
          id="calender"
          fill="#818385"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
