import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const EmergencyContactIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Compensation</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-33"
          transform="translate(-9.000000, -7.000000)"
          stroke="#A2A5A8"
        >
          <g id="Group-37" transform="translate(8.000000, 5.000000)">
            <g
              id="Phone,-Call,-Square"
              transform="translate(0.000000, 1.000000)"
            >
              <g id="Compensation" transform="translate(2.500000, 2.500000)">
                <path
                  d="M11.2533333,0 C13.3225,0 15,1.6775 15,3.74666667 L15,11.2541667 C15,13.3225 13.3225,15 11.2533333,15 L3.74666667,15 C1.6775,15 0,13.3225 0,11.2533333 L0,3.75 C0,1.67916667 1.67916667,0 3.75,0 L11.2533333,0 Z"
                  id="Path"
                  strokeWidth="1.2"
                ></path>
                <path
                  d="M5.5425,9.455 C7.36166667,11.275 9.66666667,12.435 11.0375,11.07 L11.37,10.7375 C11.8133333,10.295 11.7516667,9.56166667 11.2366667,9.20416667 C10.9125,8.97833333 10.5641667,8.73583333 10.1791667,8.465 C9.78083333,8.185 9.235,8.22916667 8.88916667,8.57166667 L8.51333333,8.945 C8.0475,8.65 7.58666667,8.27083333 7.15833333,7.84333333 L7.15666667,7.84166667 C6.72916667,7.41416667 6.35,6.9525 6.055,6.48666667 L6.42833333,6.11083333 C6.77166667,5.76583333 6.81416667,5.21916667 6.535,4.82083333 C6.26333333,4.43583333 6.02083333,4.0875 5.79583333,3.76333333 C5.43833333,3.24916667 4.705,3.1875 4.2625,3.63 L3.93,3.9625 C2.56583333,5.33333333 3.725,7.63666667 5.54416667,9.4575"
                  id="Path"
                  strokeWidth="1.1"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
