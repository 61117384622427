// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown_dropdown__1QIts {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown_menu__21w6J {\n  margin: 0;\n  padding: 6px 0;\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px rgba(128, 117, 137, 0.07), 0 10px 22px 0 rgba(51, 38, 61, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.08);\n  list-style: none;\n  box-sizing: border-box;\n  text-align: left;\n  display: block;\n  position: absolute;\n  top: calc(110% + 2px);\n  z-index: 1000;\n  min-width: 150px;\n  overflow: hidden;\n}\n\n.dropdown_isOpen__d691p .dropdown_menu__21w6J {\n  visibility: visible;\n  height: auto;\n  animation: dropdown_panel-slide-down__1ExJL 0.1s ease-in-out forwards;\n}\n\n.dropdown_right__4yTMp {\n  right: 0;\n}\n\n.dropdown_menuItem__1s91s {\n  display: flex;\n  align-items: center;\n  padding: 8px 6px;\n  width: 100%;\n  cursor: pointer;\n  transition: all 0.6s;\n  border-radius: 5px;\n  color: #4b494d;\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  line-height: 16px;\n  width: calc(100% - 24px);\n  margin: 0 auto;\n}\n.dropdown_menuItem__1s91s .dropdown_itemIcon__2pP9B {\n  width: 16px;\n  height: 13px;\n  margin-right: 5px;\n}\n.dropdown_menuItem__1s91s .dropdown_itemIcon__2pP9B svg {\n  color: #6d6971;\n  width: 16px;\n  height: 13px;\n}\n.dropdown_menuItem__1s91s:hover {\n  background-color: #f6f5f7;\n  color: #252526;\n}\n.dropdown_menuItem__1s91s:hover svg {\n  color: #4b494d;\n}\n\n.dropdown_divider__2SyZZ {\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  overflow: hidden;\n  margin: 2px 0;\n  pointer-events: none;\n  cursor: default;\n}\n\n.dropdown_dropdownBtn__3BE2i.dropdown_iconButton__2bzAr {\n  height: 28px;\n  width: 28px;\n  border-radius: 4.8px;\n  transition: all 0.4s;\n}\n.dropdown_dropdownBtn__3BE2i:hover, .dropdown_dropdownBtn__3BE2i:active, .dropdown_dropdownBtn__3BE2i.dropdown_active__3bfEJ {\n  opacity: 1 !important;\n}\n\n.dropdown_dropdownWrap__3KkQ3 {\n  z-index: 1400;\n}", ""]);
// Exports
exports.locals = {
	"dropdown": "dropdown_dropdown__1QIts",
	"menu": "dropdown_menu__21w6J",
	"isOpen": "dropdown_isOpen__d691p",
	"panel-slide-down": "dropdown_panel-slide-down__1ExJL",
	"right": "dropdown_right__4yTMp",
	"menuItem": "dropdown_menuItem__1s91s",
	"itemIcon": "dropdown_itemIcon__2pP9B",
	"divider": "dropdown_divider__2SyZZ",
	"dropdownBtn": "dropdown_dropdownBtn__3BE2i",
	"iconButton": "dropdown_iconButton__2bzAr",
	"active": "dropdown_active__3bfEJ",
	"dropdownWrap": "dropdown_dropdownWrap__3KkQ3"
};
module.exports = exports;
