import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import clsx from 'clsx';

import { Button, ButtonProps } from '../button/button';
import { Divider } from '../divider/divider';
import styles from './tabs.module.scss';

interface Pane {
  title: ReactNode | string;
  icon?: ReactNode;
  content: ReactNode | string;
  key: number;
  disabled?: boolean;
}

interface TabProps {
  panes: Pane[];
  contentClassName?: string;
  tabBarClassName?: string;
  onChange?: (key: number) => void;
  activeTab?: number;
  extraContent?: ReactElement;
  buttonProps?: Omit<ButtonProps, 'children'>;
  showTopLine?: boolean;
  showBottomLine?: boolean;
  inBetweenTabAndContent?: ReactElement;
  tabsButtonGutter?: 'gutter-s' | 'gutter-m';
  hasContent?: boolean;
  leftSection?: ReactNode | string;
}

export const Tabs = ({
  panes,
  contentClassName,
  tabBarClassName,
  onChange,
  activeTab = 0,
  extraContent,
  showTopLine = true,
  showBottomLine = true,
  buttonProps = {
    variant: 'text',
    color: 'secondary',
    size: 's',
  },
  inBetweenTabAndContent,
  tabsButtonGutter = 'gutter-m',
  hasContent = true,
  leftSection,
}: TabProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    if (value !== newValue) {
      onChange && onChange(newValue);
    }
  };

  return (
    <>
      {showTopLine ? <Divider /> : null}
      <div className={clsx(styles.tabBar, tabBarClassName)}>
        <div className={styles.tabs}>
          {panes.map((item) => {
            const activeTab = value === item.key;
            return (
              <Button
                key={item.key}
                isActive={activeTab}
                disabled={item.disabled}
                className={clsx(styles.tabButton, styles[tabsButtonGutter])}
                onClick={() => handleChange(item.key)}
                {...buttonProps}
              >
                {item?.icon}
                {item.title}
              </Button>
            );
          })}
        </div>
        {extraContent ? (
          <div className={styles.extraContent}>{extraContent}</div>
        ) : null}
      </div>
      {showBottomLine ? <Divider /> : null}
      {inBetweenTabAndContent}
      {hasContent ? (
        <div className={clsx(styles.content, contentClassName)}>
          {leftSection && (
            <>
              {leftSection}
              <Divider type="vertical" className={styles.fullScreenDivider} />
              <Divider className={styles.mobileDivider} />
            </>
          )}
          {panes.map((item) => (
            <Fragment key={item.key}>
              {value === item.key ? item.content : null}
            </Fragment>
          ))}
        </div>
      ) : null}
    </>
  );
};
