import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const CompensationIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-31" transform="translate(-8.000000, -6.000000)">
          <g id="Group-30" transform="translate(8.000000, 5.000000)">
            <g id="Group-26" transform="translate(0.000000, 1.000000)">
              <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
              <g
                id="Group-12"
                transform="translate(2.000000, 2.000000)"
                stroke="#A2A5A8"
                strokeWidth="1.2"
              >
                <path
                  d="M0,8 C0,3.58181818 3.58181818,0 8,0 C12.4181818,0 16,3.58181818 16,8 C16,12.4181818 12.4181818,16 8,16 C3.58181818,16 0,12.4181818 0,8 L0,8 Z"
                  id="Stroke-848"
                ></path>
                <g
                  id="Group-44"
                  transform="translate(6.000000, 3.650000)"
                  strokeLinecap="round"
                >
                  <g id="Group-19">
                    <path
                      d="M0,6.0116654 L0,6.0116654 C0,6.93233207 0.746,7.67833207 1.66666667,7.67833207 L2.33333333,7.67833207 C3.254,7.67833207 4,6.93233207 4,6.0116654 C4,3.67833207 0,5.0116654 0,2.67833207 C0,1.7576654 0.746,1.0116654 1.66666667,1.0116654 L2.33333333,1.0116654 C3.254,1.0116654 4,1.7576654 4,2.67833207"
                      id="Stroke-852"
                    ></path>
                    <path
                      d="M2,0.158212527 L2,0.889406521 M2,7.75121531 L2,8.57191654"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
