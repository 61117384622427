import { debounce } from '@material-ui/core';
import {
  appRoute,
  BasicFilter,
  clone,
  Company,
  CompanyListType,
  COMPONENT_STATE,
  deleteCompany,
  getCompanies,
  getErrorMessages,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  Spin,
  useConfirmation,
  useSnackbar,
} from '@spovio/ui';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useHistory } from 'react-router-dom';
import ContactCompanyAddDialog from '../contact-company-add-dialog/contact-company-add-dialog';
import ContactCompanyEditDialog from '../contact-company-edit-dialog/contact-company-edit-dialog';
import ContactCompanyTableList from '../contact-company-table-list/contact-company-table-list';
import styles from './contact-company-list.module.scss';

/* eslint-disable-next-line */
export interface ContactCompanyListProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactCompanyList = (props: ContactCompanyListProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [companies, setCompanies] = useState<CompanyListType>(
    {} as CompanyListType
  );
  const basicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };
  const [filter, setFilter] = useState<BasicFilter>(basicFilter);
  const { showSnackbar } = useSnackbar();
  const { showConfirmation } = useConfirmation();
  const history = useHistory();
  const { isAdmin } = useModuleAccess('contact_access');

  const requestCompanies = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getCompanies(_filter);
        setCompanies(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getCompanyCreateButton = () => {
    if (!isAdmin) return null;
    return (
      <Button
        size={'s'}
        component={NavLink}
        to={appRoute.getRoute(`/contacts/companies/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('company.addCompany')}
        </>
      </Button>
    );
  };

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      requestCompanies(filter);
    },
    [requestCompanies]
  );
  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
      </div>
    );
  };

  const deleteCompanyFromList = async (id: number) => {
    showSnackbar(false);
    try {
      const res = await deleteCompany(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().company.delete,
          severity: 'success',
        });
        requestCompanies();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (company: Company) => {
    showConfirmation(true, {
      header: t('company.companyDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => deleteCompanyFromList(company.id),
    });
  };

  const getCompaniesTable = () => (
    <ContactCompanyTableList
      companies={companies.results}
      filter={filter}
      onFilter={onFilter}
      componentState={componentState}
      onListAction={(dropId, company) => {
        if (dropId === 'view') {
          history.push(
            appRoute.getRoute(`/contacts/companies/${company.id}/view`)
          );
        } else if (dropId === 'edit') {
          history.push(
            appRoute.getRoute(`/contacts/companies/${company.id}/edit`)
          );
        } else if (dropId === 'delete') {
          deleteConfirmation(company);
        }
      }}
    />
  );

  const getPaginationFooter = () => {
    if (!companies) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      companies;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  const getCompanyRoutes = () => (
    <Suspense fallback={<Spin size="full" />}>
      <Route
        exact
        path={appRoute.getPath(`/contacts/companies/add`)}
        render={() => (
          <ContactCompanyAddDialog
            onAddCompany={() => {
              requestCompanies();
            }}
          />
        )}
      />
      <Route
        exact
        path={appRoute.getPath(`/contacts/companies/:id/edit`)}
        render={(props) => (
          <ContactCompanyEditDialog
            {...props}
            onEdit={(data: Company) => {
              const _company = companies.results.find(
                (company) => company.id === Number(props.match.params.id)
              );
              _company && Object.assign(_company, data);
              setCompanies({ ...companies });
            }}
          />
        )}
      />
    </Suspense>
  );

  useEffect(() => {
    requestCompanies();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.companies')}</h4>}
        rightContent={getCompanyCreateButton()}
        bottomContent={getFilters()}
      />
      {getCompaniesTable()}
      {getPaginationFooter()}
      {getCompanyRoutes()}
    </>
  );
};

export default ContactCompanyList;
