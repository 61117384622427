// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table-markup_table__22d3V {\n  border-spacing: 0;\n  border-collapse: collapse;\n  background-color: #ffffff;\n}\n\n.table-markup_col__1x5C- {\n  width: 100%;\n  text-align: left;\n}\n.table-markup_col__1x5C-:first-child {\n  padding-left: 30px;\n}\n.table-markup_col__1x5C-:last-child {\n  padding-right: 30px;\n}\n.table-markup_col__1x5C-.table-markup_align-right__IPDis {\n  text-align: right;\n}\n.table-markup_col__1x5C-.table-markup_actionCol__PqRbs {\n  width: 26px;\n}\n\n.table-markup_tbody__2ETdj .table-markup_trow__qKWfk {\n  border-bottom: 1px solid #edeef2;\n}\n.table-markup_tbody__2ETdj .table-markup_trow__qKWfk .table-markup_noDataCol__19cuB > div {\n  min-height: 230px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.table-markup_tbody__2ETdj .table-markup_trow__qKWfk .table-markup_noDataCol__19cuB > div svg {\n  height: 32px;\n  width: 32px;\n  color: #24252a;\n  margin-bottom: 8px;\n}\n.table-markup_tbody__2ETdj .table-markup_trow__qKWfk .table-markup_showOnHover__NST8L .dropdown-button {\n  opacity: 0;\n}\n.table-markup_tbody__2ETdj .table-markup_trow__qKWfk:hover .table-markup_showOnHover__NST8L .dropdown-button {\n  opacity: 1;\n}\n.table-markup_tbody__2ETdj .table-markup_col__1x5C- {\n  vertical-align: middle;\n}\n.table-markup_tbody__2ETdj .table-markup_col__1x5C-.table-markup_bold__3b_-I {\n  font-weight: 500;\n}\n.table-markup_tbody__2ETdj .table-markup_col__1x5C-.table-markup_color-red__3wZvU {\n  color: #eb5a5b;\n}\n.table-markup_tbody__2ETdj .table-markup_col__1x5C-.table-markup_text_muted__3GYBH {\n  color: #808080;\n  font-size: 13px;\n  letter-spacing: -0.35px;\n  line-height: 16px;\n  text-align: right;\n  text-decoration: none;\n}\n\n.table-markup_headerCell__1APhp {\n  padding: 0;\n}", ""]);
// Exports
exports.locals = {
	"table": "table-markup_table__22d3V",
	"col": "table-markup_col__1x5C-",
	"align-right": "table-markup_align-right__IPDis",
	"actionCol": "table-markup_actionCol__PqRbs",
	"tbody": "table-markup_tbody__2ETdj",
	"trow": "table-markup_trow__qKWfk",
	"noDataCol": "table-markup_noDataCol__19cuB",
	"showOnHover": "table-markup_showOnHover__NST8L",
	"bold": "table-markup_bold__3b_-I",
	"color-red": "table-markup_color-red__3wZvU",
	"text_muted": "table-markup_text_muted__3GYBH",
	"headerCell": "table-markup_headerCell__1APhp"
};
module.exports = exports;
