import {
  COMPONENT_STATE,
  getErrorMessages,
  getTagDetails,
  Tag,
  TagPayload,
  updateTag,
  userMsgs,
} from '@spovio/shared';
import { Spin, useSnackbar } from '@spovio/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import ContactTagForm from '../contact-tag-form/contact-tag-form';
import './contact-tag-edit-dialog.module.scss';

interface MatchParams {
  id?: string;
}

type RouteProps = RouteComponentProps<MatchParams, StaticContext>;

/* eslint-disable-next-line */
export interface ContactTagEditDialogProps extends RouteProps {
  onEdit: (data: Tag) => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;
export function ContactTagEditDialog({
  onEdit,
  ...props
}: ContactTagEditDialogProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [initialValues, setInitialValues] = useState<Tag>({} as Tag);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (tag: TagPayload) => {
    try {
      if (props.match.params.id) {
        const res = await updateTag(props.match.params.id, tag);
        if (res.status === 200) {
          showSnackbar(true, {
            msg: userMsgs().tags.edit,
            severity: 'success',
          });
        }
        onEdit(res.data);
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const fetchData = useCallback(async () => {
    try {
      if (props.match.params.id) {
        const res = await getTagDetails(props.match.params.id);
        const initialValues = res.data;
        setInitialValues(initialValues);
        setComponentState(LOADED);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, [props.match.params.id, showSnackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData, props.match.params.id]);

  return componentState === LOADED ? (
    <div>
      <ContactTagForm
        open
        title={t('tags.editTag')}
        action={t('label.save')}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  ) : (
    <Spin />
  );
}

export default ContactTagEditDialog;
