import { debounce } from '@material-ui/core';
import {
  appRoute,
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteTimeOffType,
  getErrorMessages,
  getTimeOffTypes,
  TimeOffType,
  updateTimeOffTypeStatus,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  ConfirmationContext,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  Spin,
  Tabs,
  useSnackbar,
} from '@spovio/ui';
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useHistory, useLocation } from 'react-router-dom';
import PeopleTimeOffListTable from '../people-time-off-list-table/people-time-off-list-table';
import PeopleTimeOffTypeAddDialog from '../people-time-off-type-add-dialog/people-time-off-type-add-dialog';
import PeopleTimeOffTypeEditDialog from '../people-time-off-type-edit-dialog/people-time-off-type-edit-dialog';
import styles from './people-time-off-type.module.scss';

/* eslint-disable-next-line */
export interface PeopleTimeOffTypeProps {}
const { LOADED, LOADING } = COMPONENT_STATE;

enum TABS {
  ACTIVE,
  ARCHIVED,
}

interface StateProps {
  tab: TABS;
}

export function PeopleTimeOffType(props: PeopleTimeOffTypeProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [timeOffTypes, setTimeOffTypes] = useState<TimeOffType[]>([]);
  const { showSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<BasicFilter>({
    page: '1',
    page_size: '25',
  });
  const location = useLocation();
  const state = location.state as StateProps;
  const [activeTab, setActiveTab] = useState(TABS.ACTIVE);
  const [selectedType, setSelectedType] = useState<TimeOffType>(
    {} as TimeOffType
  );
  const [isEditing, setIsEditing] = useState(false);
  const { isAdmin } = useModuleAccess('people_access');
  const history = useHistory();
  const { showConfirmation } = useContext(ConfirmationContext);

  useEffect(() => {
    setComponentState(LOADING);
    getTimeOff(activeTab);
  }, [activeTab]);

  const getTimeOff = useCallback(
    async (activeTab) => {
      setComponentState(LOADING);
      try {
        const res = await getTimeOffTypes(activeTab === TABS.ACTIVE);
        setTimeOffTypes(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const _archiveType = async (type: TimeOffType) => {
    try {
      const res = await updateTimeOffTypeStatus(
        type.id,
        TABS.ACTIVE === activeTab ? false : true
      );
      if (res.status === 200) {
        getTimeOff(activeTab);
        showSnackbar(true, {
          msg:
            TABS.ACTIVE === activeTab
              ? userMsgs().timeOffType.archive
              : userMsgs().timeOffType.unarchive,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onDeleteType = async (id: number) => {
    try {
      showSnackbar(false);
      const res = await deleteTimeOffType(id);
      if (res.status === 200) {
        getTimeOff(activeTab);
        showConfirmation(false);
        showSnackbar(true, {
          msg: userMsgs().timeOffType.delete,
          severity: 'success',
        });
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (type: TimeOffType) => {
    showConfirmation(true, {
      header: t('people.timeOff.timeOffTypeDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => onDeleteType(type.id),
    });
  };

  const onListAction = (dropId: string, type: TimeOffType) => {
    switch (dropId) {
      case 'edit':
        history.push(
          appRoute.getRoute(`/people/time-off-type/${type.id}/edit`)
        );
        break;
      case 'archive':
        _archiveType(type);
        break;
      case 'delete':
        deleteConfirmation(type);
        break;
    }
  };

  const getTypeCreateButton = (buttonContent?: string, size?: 'm') => {
    if (!isAdmin) return null;
    return (
      <Button
        size={size || 's'}
        component={NavLink}
        to={appRoute.getRoute(`/people/time-off-type/add`)}
      >
        {buttonContent || (
          <>
            <PlusIcon className={styles.plusIcon} />
            {t('people.timeOff.addType')}
          </>
        )}
      </Button>
    );
  };

  const getTimeOffTypeTable = () => {
    if (!timeOffTypes) return null;
    else
      return (
        <PeopleTimeOffListTable
          activeTab={activeTab}
          types={timeOffTypes}
          componentState={componentState}
          onListAction={onListAction}
        />
      );
  };

  const getTypeRoutes = () => (
    <Suspense fallback={<Spin size="full" />}>
      <Route
        exact
        path={appRoute.getPath(`/people/time-off-type/add`)}
        render={() => (
          <PeopleTimeOffTypeAddDialog
            onAddType={() => {
              getTimeOff(activeTab);
            }}
          />
        )}
      />
      <Route
        exact
        path={appRoute.getPath(`/people/time-off-type/:id/edit`)}
        render={(props) => (
          <PeopleTimeOffTypeEditDialog
            {...props}
            onEdit={(data: TimeOffType) => {
              const newTimeOffTypes = timeOffTypes.map((type) => {
                if (type.id === data.id) {
                  return data;
                }
                return type;
              });
              setTimeOffTypes(newTimeOffTypes);
            }}
          />
        )}
      />
    </Suspense>
  );

  const getPanes = () => {
    const { ACTIVE, ARCHIVED } = TABS;
    return [
      {
        title: t('label.activeTypes'),
        content: getTimeOffTypeTable(),
        key: ACTIVE,
      },
      {
        title: t('label.archivedTypes'),
        content: getTimeOffTypeTable(),
        key: ARCHIVED,
      },
    ];
  };

  const onTabChange = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('people.timeOff.timeOffTypes')}</h4>}
        rightContent={getTypeCreateButton()}
        // bottomContent={getFilters()}
      />
      <Tabs
        panes={getPanes()}
        contentClassName={styles.content}
        activeTab={activeTab}
        onChange={(tab) => onTabChange(tab)}
        tabBarClassName={styles.tabBar}
      />
      {getTypeRoutes()}
    </>
  );
}

export default PeopleTimeOffType;
