// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".drag-and-drop-field_upload__HVpR0 {\n  height: 64px;\n  width: 410px;\n  background: rgba(199, 201, 207, 0.05);\n  border: 1.2px dashed #c7c9cf;\n  box-sizing: border-box;\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #4461cb;\n  font-size: 14px;\n  letter-spacing: 0;\n  line-height: 17px;\n  position: relative;\n  width: 100%;\n}\n.drag-and-drop-field_upload__HVpR0 .drag-and-drop-field_dragCaption__278_t {\n  position: absolute;\n  display: flex;\n}\n.drag-and-drop-field_upload__HVpR0 .drag-and-drop-field_dragCaption__278_t span {\n  color: #8a8b8d;\n}\n\n.drag-and-drop-field_input__W5Kbt {\n  opacity: 0;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  width: 100%;\n}\n\n.drag-and-drop-field_label__3KOqM {\n  cursor: pointer;\n}\n\n.drag-and-drop-field_fileWrap__2jYFh {\n  display: flex;\n  align-items: center;\n}\n.drag-and-drop-field_fileWrap__2jYFh .drag-and-drop-field_titleWrap__1LZW6 {\n  display: flex;\n  align-items: center;\n}\n.drag-and-drop-field_fileWrap__2jYFh .drag-and-drop-field_titleWrap__1LZW6 .drag-and-drop-field_fileIcon__3ClBZ {\n  margin-right: 10px;\n}\n.drag-and-drop-field_fileWrap__2jYFh .drag-and-drop-field_titleWrap__1LZW6 .drag-and-drop-field_fileName__10L0k {\n  width: 320px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.drag-and-drop-field_fileWrap__2jYFh .drag-and-drop-field_removeIcon__lC9No {\n  width: 10px;\n  margin-left: 50px;\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"upload": "drag-and-drop-field_upload__HVpR0",
	"dragCaption": "drag-and-drop-field_dragCaption__278_t",
	"input": "drag-and-drop-field_input__W5Kbt",
	"label": "drag-and-drop-field_label__3KOqM",
	"fileWrap": "drag-and-drop-field_fileWrap__2jYFh",
	"titleWrap": "drag-and-drop-field_titleWrap__1LZW6",
	"fileIcon": "drag-and-drop-field_fileIcon__3ClBZ",
	"fileName": "drag-and-drop-field_fileName__10L0k",
	"removeIcon": "drag-and-drop-field_removeIcon__lC9No"
};
module.exports = exports;
