import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const FolderIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Files" transform="translate(-570.000000, -237.000000)">
          <g id="Group-66" transform="translate(541.000000, 189.000000)">
            <g id="Group-9" transform="translate(0.000000, 39.000000)">
              <g
                id="Group-75-Copy-2"
                transform="translate(30.000000, 10.000000)"
              >
                <g
                  id="Group-11-Copy-3"
                  fill="#FBFCFE"
                  stroke="#DBDDE3"
                  strokeWidth="0.5"
                >
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="28"
                    height="28"
                    rx="4"
                  ></rect>
                </g>
                <g id="folder-blank" transform="translate(5.000000, 5.000000)">
                  <path
                    d="M14.25,5.205 L9.39675,5.205 C9.1485,5.205 8.916,5.082 8.7765,4.8765 L7.72275,3.32775 C7.58325,3.123 7.3515,3 7.10325,3 L3.75,3 C2.92125,3 2.25,3.67125 2.25,4.5 L2.25,13.5 C2.25,14.32875 2.92125,15 3.75,15 L14.25,15 C15.07875,15 15.75,14.32875 15.75,13.5 L15.75,6.705 C15.75,5.877 15.07875,5.205 14.25,5.205 Z"
                    id="Path"
                    fill="#C3C7D1"
                  ></path>
                  <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
