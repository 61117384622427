// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search-bar_searchInputWrap__2yrQd .search-bar_searchInput__1_axN {\n  max-width: 280px;\n}\n.search-bar_searchInputWrap__2yrQd .search-bar_searchIcon__3pWh9 {\n  width: 18px;\n  color: #808080;\n}\n.search-bar_searchInputWrap__2yrQd .search-bar_searchResetIcon__1oCwk {\n  width: 14px;\n}\n\n.search-bar_inputWrap__3ymZa .search-bar_input__2sxAg {\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  line-height: 16px;\n}\n.search-bar_inputWrap__3ymZa .search-bar_input__2sxAg::placeholder {\n  color: #908e91;\n}", ""]);
// Exports
exports.locals = {
	"searchInputWrap": "search-bar_searchInputWrap__2yrQd",
	"searchInput": "search-bar_searchInput__1_axN",
	"searchIcon": "search-bar_searchIcon__3pWh9",
	"searchResetIcon": "search-bar_searchResetIcon__1oCwk",
	"inputWrap": "search-bar_inputWrap__3ymZa",
	"input": "search-bar_input__2sxAg"
};
module.exports = exports;
