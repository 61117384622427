import { Popover } from '@material-ui/core';
import { COMPONENT_STATE, toLowerCase, userMsgs } from '@spovio/shared';
import clsx from 'clsx';
import React, { Component } from 'react';
import { Button } from '../button/button';
import { Checkbox } from '../checkbox/checkbox';
// import Button, { Variant } from '../Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import MenuItem, { MenuItemProps } from '../MenuItem/MenuItem';
import { NoDataContent } from '../no-data-content/no-data-content';
import { SearchBar } from '../search-bar/search-bar';
import styles from './MultiSelect.module.scss';

interface MultiSelectSelectItem {
  value: any;
  component?: any;
  label: string;
}

interface State {
  selection: any;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  searchText: string;
}
interface Props {
  onSelect: (value: any, type?: string) => void;
  icon?: any;
  buttonClassName?: string;
  items: MultiSelectSelectItem[];
  label: string;
  searchPlaceholder?: string;
  headerLabel?: string;
  initialValues?: MenuItemProps['value'][];
  isLoading?: COMPONENT_STATE;
  menuItemClassName?: string;
}

export const MULTI_SELECT_ALL = 'MULTI_SELECT_ALL';
export const MULTI_CLEAR_ALL = 'MULTI_CLEAR_ALL';

type MouseButtonEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
export default class MultiSelect extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      selection: this.props.initialValues || [],
    } as State;
  }

  public componentDidMount(): void {
    const { initialValues, onSelect } = this.props;
    initialValues && onSelect(initialValues);
  }

  public resetValue = (): void => {
    this.props.onSelect([]);
    this.setState({ selection: [] });
  };

  public render(): JSX.Element {
    const { anchorEl } = this.state;
    const open = !!anchorEl;
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        {this.getButtonComponent()}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          className={styles.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={styles.root}>
            <SearchBar
              containerClassName={styles.searchBar}
              focusAuto
              // size={'md'}
              placeholder={this.props.searchPlaceholder}
              onSearch={this.onSearch}
            />
            <div className={styles.body}>{this.getContentMarkup()}</div>
          </div>
        </Popover>
      </>
    );
  }

  private onSearch = (searchText: string) => {
    this.setState({
      searchText,
    });
  };

  private getFilteredList() {
    const { items } = this.props;
    const { searchText } = this.state;
    const filteredList = items.filter((item) => {
      return toLowerCase(item.label).indexOf(toLowerCase(searchText)) !== -1;
    });
    return filteredList;
  }

  private getContentMarkup() {
    const isLoading = this.props.isLoading === COMPONENT_STATE.LOADING;
    const filteredList = this.getFilteredList();
    if (isLoading) {
      return <LoadingSpinner className={styles.loading} />;
    } else if (!filteredList.length) {
      return (
        <NoDataContent
          className={styles.noData}
          variant={'compact'}
          title={userMsgs().matchesNotFound}
        />
      );
    }
    return this.getMenuList(filteredList);
  }

  private getMenuList(items: MultiSelectSelectItem[]) {
    const { selection } = this.state;
    const indeterminate = !!(
      selection.length && selection.length < items.length
    );
    const headerValue = indeterminate ? MULTI_CLEAR_ALL : MULTI_SELECT_ALL;
    return <ul className={styles.menuList}>{this.getItemsMarkup(items)}</ul>;
  }

  private getItemsMarkup(items: MultiSelectSelectItem[]) {
    const { menuItemClassName } = this.props;
    return items.map((item) => {
      return (
        <MenuItem
          value={item.value}
          className={clsx(styles.menuItem, menuItemClassName)}
          key={item.value}
          onClick={() => this.onMenuItemClick(item)}
        >
          <Checkbox
            className={clsx(styles.checkbox, styles.multiSelectCheckbox)}
            checked={this.isItemInSelection(item)}
          />
          {item.component && item.component()}
        </MenuItem>
      );
    });
  }

  private onHeaderClick(value: string, items: MultiSelectSelectItem[]) {
    const { selection } = this.state;
    let selectedItems = [];
    if (value === MULTI_SELECT_ALL) {
      if (selection.length === items.length) {
        selectedItems = [];
      } else {
        selectedItems = items.map((item) => item.value);
      }
    } else if (value === MULTI_CLEAR_ALL) {
      selectedItems = [];
    }
    this.props.onSelect(selectedItems, 'option');
    this.setState({ selection: selectedItems });
  }

  private onMenuItemClick(item: MultiSelectSelectItem) {
    const { selection } = this.state;
    let selectedItems = [];
    if (!selection.some((current: number) => current === item.value)) {
      selectedItems = [...selection, item.value];
    } else {
      selectedItems = selection.filter(
        (current: number) => current !== item.value
      );
    }
    this.setState({ selection: selectedItems });
    this.props.onSelect(selectedItems, 'option');
  }

  private isItemInSelection(item: MultiSelectSelectItem) {
    const { selection } = this.state;
    const isItemSelected = selection.some(
      (current: number) => current === item.value
    );
    return isItemSelected ? true : false;
  }

  private handleClick = (event: MouseButtonEvent) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null, searchText: '' });
  };

  private getButtonComponent() {
    const { selection, anchorEl } = this.state;
    const { label, icon } = this.props;
    const open = !!anchorEl;
    const Icon = icon;
    return (
      <Button
        isActive={open}
        onClick={this.handleClick}
        className={clsx(
          styles.button,
          open && styles.buttonOpen,
          this.props.buttonClassName,
          selection.length && styles.selectedBtn
        )}
      >
        <span className={styles.iconWrap}>
          <Icon />
        </span>
        {label}
      </Button>
    );
  }
}
