import {
  appRoute,
  COMPONENT_STATE,
  Contact,
  getContactDetails,
  getErrorMessages,
} from '@spovio/shared';
import {
  Button,
  CloseIcon,
  Divider,
  Header,
  Spin,
  Text,
  useSnackbar,
} from '@spovio/ui';
import { Fragment, Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import ContactBasicInfo from '../contact-basic-info/contact-basic-info';
import ContactEditDialog from '../contact-edit-dialog/contact-edit-dialog';
import styles from './contact-details.module.scss';

interface MatchParams {
  id?: string;
}

type RouteProps = RouteComponentProps<MatchParams, StaticContext>;

/* eslint-disable-next-line */
export interface ContactDetailsProps extends RouteProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactDetails = (props: ContactDetailsProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [contact, setContact] = useState({} as Contact);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const fetchContactDetails = useCallback(async (id: string) => {
    setComponentState(LOADING);
    try {
      const res = await getContactDetails(id);
      setContact(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  }, []);

  const getLeftHeaderContent = () => {
    return (
      <>
        <Button
          className={styles.closeBtn}
          ghost
          variant="text"
          color="default"
          onClick={() => history.goBack()}
        >
          <CloseIcon />
        </Button>
        <h4>{t('label.contactDetails')}</h4>
      </>
    );
  };

  const getContactsList = () => {
    if (componentState === LOADING) return;
    return (
      <div className={styles.contactDetails}>
        <div className={styles.sections}>
          <div className={styles.sectionHeader}>
            <Text variant="h4" className={styles.heading}>
              {t('label.notes')}
            </Text>
          </div>
          <Divider />
          <div className={styles.details}>
            <Text variant="body1" color="dove_grey">
              {contact.notes ? contact.notes : '—'}
            </Text>
          </div>
        </div>
        <div className={styles.sections}>
          <div className={styles.sectionHeader}>
            <Text variant="h4" className={styles.heading}>
              {t('label.tags')}
            </Text>
          </div>
          <Divider />
          <div className={styles.details}>
            <Text variant="body1" color="dove_grey">
              {contact.tags.length
                ? contact.tags.map((tag) => tag.name).join(', ')
                : '—'}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const getContactRoutes = () => (
    <Suspense fallback={<Spin size="full" />}>
      <Route
        exact
        path={appRoute.getPath(`/contacts/list/:id/view/edit`)}
        render={(props) => (
          <ContactEditDialog
            {...props}
            onEdit={(data: Contact) => {
              // const _contact = contacts.results.find(
              //   (contact) => contact.id === Number(props.match.params.id)
              // );
              setComponentState(LOADING);
              contact && Object.assign(contact, data);
              // setContactState(contact);
              setContact(contact);
              setComponentState(LOADED);
            }}
          />
        )}
      />
    </Suspense>
  );

  useEffect(() => {
    if (props.match.params.id) fetchContactDetails(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <>
      <Header className={styles.header} leftContent={getLeftHeaderContent()} />
      <div className={styles.basicInfoTemplate}>
        <ContactBasicInfo componentState={componentState} contact={contact} />
        <Divider type="vertical" className={styles.fullScreenDivider} />
        <Divider className={styles.mobileDivider} />
        {getContactsList()}
      </div>
      {getContactRoutes()}
    </>
  );
};

export default ContactDetails;
