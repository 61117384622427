import React from 'react';

import clsx from 'clsx';

import { CloseIconSm } from '../icons';
import styles from './tag-item.module.scss';

interface TagItemProps {
  prefix?: React.ReactNode;
  containerClassName?: string;
  component?: keyof JSX.IntrinsicElements;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
}

export const TagItem = ({
  prefix,
  containerClassName,
  label,
  component,
  onDelete,
  ...restProps
}: TagItemProps) => {
  const Component = component || 'div';
  return (
    <Component className={clsx(styles.root, containerClassName)} {...restProps}>
      {prefix && <div className={styles.prefix}>{prefix}</div>}
      <div className={styles.label}>{label} </div>
      <span
        role="button"
        className={styles.close}
        tabIndex={0}
        onKeyPress={onDelete}
        onClick={onDelete}
      >
        <CloseIconSm />
      </span>
    </Component>
  );
};
