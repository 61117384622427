// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog_dialog__rgAcf {\n  overflow: visible;\n}\n.dialog_dialog__rgAcf .MuiBackdrop-root {\n  background-color: rgba(0, 0, 0, 0.2);\n}\n.dialog_dialog__rgAcf .MuiDialog-paperWidthXs {\n  min-width: 448px;\n  max-width: 500px;\n}\n.dialog_dialog__rgAcf .MuiDialog-paperWidthSm {\n  width: 460px;\n  max-width: 460px;\n}\n.dialog_dialog__rgAcf .MuiDialog-paperWidthLg {\n  width: 638px;\n  max-width: 638px;\n}\n.dialog_dialog__rgAcf .MuiDialog-paper {\n  margin: 0;\n}\n.dialog_dialog__rgAcf .MuiPaper-rounded {\n  border-radius: 12px;\n}\n.dialog_dialog__rgAcf .MuiPaper-elevation24 {\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px rgba(128, 117, 137, 0.07), 0 10px 22px 0 rgba(51, 38, 61, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.08);\n}\n\n.dialog_dialogInner__1sM2i {\n  width: 100%;\n  display: flex;\n  flex-flow: column;\n  border-radius: 12px;\n  overflow: auto;\n  position: relative;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n}\n.dialog_dialogInner__1sM2i .dialog_footerBlock__279Mb {\n  display: flex;\n  justify-content: flex-end;\n  padding: 25px;\n}\n.dialog_dialogInner__1sM2i .dialog_footerBlock__279Mb .dialog_closeBtn__3EEXF {\n  margin-right: 10px;\n}\n\n.dialog_heroButton__2INsi {\n  font-size: 14px;\n  letter-spacing: -0.11px;\n  line-height: 17px;\n  text-align: center;\n}", ""]);
// Exports
exports.locals = {
	"dialog": "dialog_dialog__rgAcf",
	"dialogInner": "dialog_dialogInner__1sM2i",
	"footerBlock": "dialog_footerBlock__279Mb",
	"closeBtn": "dialog_closeBtn__3EEXF",
	"heroButton": "dialog_heroButton__2INsi"
};
module.exports = exports;
