import React from 'react';
import clsx from 'clsx';
import styles from './LoadingSpinner.module.scss';
import LoadingSpinnerIcon from '../icons/LoadingSpinnerIcon';

export interface LoadingSpinnerProps {
  text?: string;
  width?: number;
  height?: number;
  className?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (
  props: LoadingSpinnerProps
) => {
  return (
    <div
      className={clsx(styles.root, props.className)}
      style={{ height: props.height + 'px', width: props.width + 'px' }}
    >
      <div className={styles.inner}>
        <LoadingSpinnerIcon />
        {props.text ? <p>{props.text}</p> : null}
      </div>
    </div>
  );
};

export default LoadingSpinner;
