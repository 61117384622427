import { updateUserProfile } from '@spovio/shared';
import {
  Button,
  FormControl,
  Header,
  InputLabel,
  Select,
  useCurrentUser,
} from '@spovio/ui';

import i18next from 'i18next';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './language-settings.module.scss';

const LanguageSettings = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [values, setValues] = useState({ language: currentUser.language });

  const changeLocale = (l: string) => {
    i18next.changeLanguage(l).then((t) => {
      moment.locale(l);
    });
  };

  const handleSubmit = async () => {
    try {
      const res = await updateUserProfile({
        language: values.language,
      });
      setValues({ language: res.data.language });
      changeLocale(values.language);
    } catch {
      // todo
    }
  };

  const langs = [
    {
      label: 'Espanol',
      value: 'es',
    },
    {
      label: 'English',
      value: 'en',
    },
  ];

  const handleSelect = (field: string, value: any) => {
    setValues({ ...value, [field]: value });
  };

  const getSubmitButton = () => (
    <Button size={'s'} className={styles.actionBtn} onClick={handleSubmit}>
      {t('label.submit')}
    </Button>
  );

  const getFormFields = () => (
    <div className={styles.root}>
      <div className={styles.innerContent}>
        <FormControl gutter="l" className={styles.form}>
          <InputLabel label={t('settings.language')} className={styles.label} />
          <Select
            size="l"
            options={langs}
            onSelect={(value) => handleSelect('language', value)}
            selectedOption={{
              label:
                langs.find((item) => item.value === values.language)?.label ||
                '',
              value:
                langs.find((item) => item.value === values.language)?.value ||
                '',
            }}
          ></Select>
        </FormControl>
      </div>
    </div>
  );

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('settings.changeLanguage')}</h4>}
        rightContent={getSubmitButton()}
      />
      {getFormFields()}
    </>
  );
};

export default LanguageSettings;
