import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { Button } from '../button/button';
import {
  DateRangeType,
  getDefaultDateRange,
} from '../date-range-picker/date-range-picker-utils';
import { DateRangePicker } from '../date-range-picker/date-range-picker';
import { CalendarIcon } from '../icons';
import { Popper } from '../popper/popper';
import { TagItem } from '../tag-item/tag-item';
import styles from './date-range-picker-popover.module.scss';

interface DateRangePickerPopoverProps {
  onChange: (dateRange: DateRangeType) => void;
  onReset: () => void;
  dateRange: DateRangeType;
  className?: string;
}

export const DateRangePickerPopover = ({
  className,
  ...props
}: DateRangePickerPopoverProps) => {
  const [open, setOpen] = useState(false);
  // const [label, setLabel] = useState<string | null>(null);
  const handleToggle = () => setOpen((prev) => !prev);
  const handleClose = () => setOpen(false);
  const [dateRange, setDateRange] = React.useState(props.dateRange);

  useEffect(() => {
    // const { startDate, endDate } = dateRange;
    // const isDiffInYears = startDate?.getFullYear() !== endDate?.getFullYear();
    // const label = getDefaultDateRange(
    //   startDate,
    //   endDate,
    //   isDiffInYears ? 'DD/MM/YYYY' : undefined
    // );
    setDateRange(props.dateRange);
    // setLabel(label);
  }, [props.dateRange]);

  const onChangeDate = (dateRange: DateRangeType) => {
    props.onChange(dateRange);
    handleClose();
  };

  const onChangeDateRangeRange = (dateRange: DateRangeType, isClose = true) => {
    const { startDate, endDate } = dateRange;
    const isDiffInYears = startDate?.getFullYear() !== endDate?.getFullYear();
    // const label = getDefaultDateRange(
    //   startDate,
    //   endDate,
    //   isDiffInYears ? 'DD/MM/YYYY' : undefined
    // );
    setDateRange({ ...dateRange });
    // setLabel(label);
    if (isClose) {
      onChangeDate(dateRange);
    }
  };

  const onClose = () => {
    //STATE
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const label = dateRange.label;
    //PROPS
    const startDt = props.dateRange.startDate;
    const endDt = props.dateRange.endDate;
    //CHECK ANY CHANGE IN DATE RANGE
    const isSameDateRange =
      moment(startDt).isSame(startDate, 'd') &&
      moment(endDt).isSame(endDate, 'd');
    if (!isSameDateRange) {
      const dateRange = { label, startDate, endDate };
      onChangeDate(dateRange);
    }
    setOpen(false);
  };

  const onRemoveTag = (e: any) => {
    e.stopPropagation();
    // setLabel('');
    props.onReset();
  };

  const renderButton = () => {
    return (
      <Button
        className={clsx(styles.filterButton, {
          // [styles.hasTag]: !!label,
          [styles.isOpen]: open,
        })}
        onClick={handleToggle}
        size="s"
        variant="text"
        color="secondary"
      >
        <CalendarIcon className={styles.buttonIcon} />
      </Button>
    );
  };
  return (
    <Popper
      open={open}
      onClose={() => onClose()}
      renderButton={renderButton}
      rootPaperClassName={className}
    >
      <DateRangePicker
        onChange={onChangeDateRangeRange}
        dateRange={dateRange}
      />
    </Popper>
  );
};
