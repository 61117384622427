import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const AccountSettingsIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M10.405 2c.913 0 1.71.592 1.99 1.47l.043.157.03.16.067.466a.954.954 0 0 0 .3.57l.08.063.101.062.321.177c.18.099.387.136.572.113l.107-.02.117-.038.673-.258a2.217 2.217 0 0 1 2.466.623l.112.138.1.15.213.347c.5.816.37 1.857-.307 2.542l-.125.117-.135.105-.468.33a.959.959 0 0 0-.359.489l-.026.11-.013.129-.006.122a.953.953 0 0 0 .226.666l.08.08.092.072.384.272a2.062 2.062 0 0 1 .716 2.451l-.07.156-.081.149-.216.351a2.217 2.217 0 0 1-2.334 1.01l-.173-.042-.171-.057-.705-.27a.967.967 0 0 0-.524-.047l-.117.033-.121.052-.2.1a.955.955 0 0 0-.459.489l-.033.101-.024.122-.06.414a2.074 2.074 0 0 1-1.728 1.747l-.162.02-.161.007h-.811c-.907 0-1.7-.588-1.978-1.459l-.043-.156-.03-.159-.061-.422a.954.954 0 0 0-.318-.585l-.085-.063-.106-.061-.2-.102a.966.966 0 0 0-.522-.102l-.12.021-.128.04-.701.269a2.217 2.217 0 0 1-2.466-.623l-.112-.138-.1-.15-.25-.407a2.002 2.002 0 0 1 .275-2.438l.126-.121.137-.108.332-.24c.2-.144.336-.36.378-.58l.014-.11v-.127l-.012-.242a.958.958 0 0 0-.215-.559l-.079-.08-.099-.08-.415-.3a2.001 2.001 0 0 1-.69-2.354l.068-.16.084-.153.25-.407a2.217 2.217 0 0 1 2.333-1.009l.173.041.172.057.6.23a.96.96 0 0 0 .63.02l.11-.044.116-.066.389-.252a.958.958 0 0 0 .363-.434l.034-.1.024-.118.072-.466A2.01 2.01 0 0 1 9.21 2.03l.164-.023L9.54 2h.865Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M10 7.3a2.7 2.7 0 1 1 0 5.4 2.7 2.7 0 0 1 0-5.4Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
