import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const DivisionIcon = (props: IconsProps) => {
  return (
    <svg viewBox="0 0 18 18" {...props} xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#A2A5A8"
        strokeWidth="1.2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 14.333V3.667A2.667 2.667 0 0 1 3.667 1h10.666A2.667 2.667 0 0 1 17 3.667v10.666A2.667 2.667 0 0 1 14.333 17H3.667A2.667 2.667 0 0 1 1 14.333Z" />
        <path d="M7.518 6.037h0c0-.817.663-1.481 1.482-1.481h0c.818 0 1.482.663 1.482 1.481h0c0 .818-.663 1.482-1.482 1.482h0a1.483 1.483 0 0 1-1.482-1.482ZM3.927 11.963h0c0-.818.663-1.482 1.482-1.482h0c.818 0 1.482.663 1.482 1.482h0c-.001.817-.663 1.481-1.482 1.481h0a1.481 1.481 0 0 1-1.482-1.481h0ZM11.11 11.963h0c0-.818.662-1.482 1.481-1.482h0c.818 0 1.482.663 1.482 1.482h0c0 .817-.663 1.481-1.482 1.481h0a1.481 1.481 0 0 1-1.482-1.481ZM7.124 8.956l-1.075 1.68M9.8 7.284l1.076 1.672M11.107 11.96H9" />
      </g>
    </svg>
  );
};
