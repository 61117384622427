import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: any;
  href?: string;
  component?: any;
  ghost?: boolean;
  isActive?: boolean;
  fullWidth?: boolean;
  children?: ReactNode | string;
  size?: 'xs' | 's' | 'm' | 'l';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'danger';
  target?: string;
  rel?: string;
}

const ButtonBase = (
  {
    size = 'm',
    ghost,
    className,
    component,
    type = 'button',
    children,
    fullWidth,
    disabled,
    variant = 'contained',
    color = 'primary',
    isActive,
    ...restProps
  }: ButtonProps,
  ref: ForwardedRef<unknown>
) => {
  const Component = component || 'button';
  return (
    <Component
      ref={ref}
      type={type}
      className={clsx(
        className,
        styles.button,
        styles[variant],
        [styles[`${variant}-${color}`]],
        styles[size],
        {
          [styles.ghost]: ghost,
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled,
          [styles.isActive]: isActive,
        }
      )}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export const Button = forwardRef(ButtonBase);
