// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".status-badge_root__3wLVV {\n  padding: 4px 6px;\n  font-size: 11px;\n  font-weight: 600;\n  line-height: 14px;\n  text-transform: uppercase;\n  display: flex;\n}\n.status-badge_root__3wLVV.status-badge_s__1pziQ {\n  font-size: 9px;\n  font-weight: 600;\n  line-height: 8px;\n}\n.status-badge_root__3wLVV.status-badge_approved__3DiRe, .status-badge_root__3wLVV.status-badge_approve__1nUU1, .status-badge_root__3wLVV.status-badge_complete__kZWC6 {\n  color: #2ec35a;\n  border-radius: 5px;\n  background-color: #e5faed;\n  border: 1px solid #caf3d7;\n}\n.status-badge_root__3wLVV.status-badge_unapproved__1Yo3i, .status-badge_root__3wLVV.status-badge_rejected__3TbqR, .status-badge_root__3wLVV.status-badge_unlock__1EftV, .status-badge_root__3wLVV.status-badge_incomplete__OsRGA {\n  color: #da2d36;\n  border-radius: 5px;\n  border: 1px solid #f8d2d3;\n  background-color: #fdebec;\n}\n.status-badge_root__3wLVV.status-badge_pending__3XkSK, .status-badge_root__3wLVV.status-badge_requested__1fAuA {\n  color: #ffae42;\n  border-radius: 5px;\n  background: rgba(255, 174, 66, 0.12);\n}\n.status-badge_root__3wLVV.status-badge_outlined__1FTgw {\n  background-color: #ffffff;\n  border-radius: 5px;\n  text-transform: capitalize;\n  border: 1px solid #f0f1f4;\n}\n.status-badge_root__3wLVV.status-badge_filled__ECsAa {\n  border-radius: 5px;\n  text-transform: capitalize;\n}\n.status-badge_root__3wLVV .status-badge_icon__3n6EQ {\n  display: flex;\n  margin-right: 10px;\n}", ""]);
// Exports
exports.locals = {
	"root": "status-badge_root__3wLVV",
	"s": "status-badge_s__1pziQ",
	"approved": "status-badge_approved__3DiRe",
	"approve": "status-badge_approve__1nUU1",
	"complete": "status-badge_complete__kZWC6",
	"unapproved": "status-badge_unapproved__1Yo3i",
	"rejected": "status-badge_rejected__3TbqR",
	"unlock": "status-badge_unlock__1EftV",
	"incomplete": "status-badge_incomplete__OsRGA",
	"pending": "status-badge_pending__3XkSK",
	"requested": "status-badge_requested__1fAuA",
	"outlined": "status-badge_outlined__1FTgw",
	"filled": "status-badge_filled__ECsAa",
	"icon": "status-badge_icon__3n6EQ"
};
module.exports = exports;
