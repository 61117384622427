import { IconProps, useMediaQuery } from '@material-ui/core';
import {
  account,
  appRoute,
  createAccount,
  getBasicTagsList,
  getErrorMessages,
  MAPPED_MODULE_ACCESS,
  ModuleAccess,
  MODULES,
  Modules,
  removeAuthenticationDetails,
  setDefaultAccount,
  setDefaultOrgDetails,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  BurgerMenuIcon,
  Divider,
  FilledKeyboardArrowDownIcon,
  TagIcon,
  useCurrentUser,
  useSnackbar,
  useTagNavList,
} from '@spovio/ui';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import AddWorkspace from '../add-workspace/add-workspace';
import { AppDropdown } from '../app-dropdown/app-dropdown';
import { UserProfileDropdown } from '../user-profile-dropdown/user-profile-dropdown';
import { sideBarNavItems } from './sidebar-utils';
import styles from './sidebar.module.scss';

/* eslint-disable-next-line */
export interface SideBarProps {}

interface NavItem {
  path: string;
  name: string;
  icon: IconProps;
  requiredRole?: string;
}

export const SideBar = (props: SideBarProps) => {
  const { t } = useTranslation();
  const [isInfoOpen, setInfoOpen] = React.useState(true);
  const { currentUser } = useCurrentUser();
  const [currentModule, setCurrentModule] = React.useState<Modules>(
    '' as Modules
  );
  const { tagNavList, setTagNavList } = useTagNavList();
  const [isAddingWorkspace, setIsAddingWorkspace] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 768px)', { noSsr: true });
  const [isCollapsed, setIsCollapsed] = React.useState(!isDesktop);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (isDesktop) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    getCurrentModule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (currentUser['contact_access'] !== 'no_access') getTagList();
  }, []);

  const getCurrentModule = () => {
    const { pathname } = history.location;
    const modules: Modules[] = MODULES;
    const _currentModule: Modules =
      modules.find((item: Modules) => pathname.includes(item)) || 'people';
    setCurrentModule(_currentModule);
    // }
  };

  const getTagList = async () => {
    const res = await getBasicTagsList();
    if (res.status === 200) {
      const { data } = res;
      const tagNavs = data.map((tag) => ({
        path: `/contacts/tag/${tag.id}`,
        name: tag.name,
        icon: <TagIcon />,
      }));
      setTagNavList(tagNavs);
    }
  };

  const onLogout = () => {
    removeAuthenticationDetails();
    history.push('/login');
  };

  const getIfInProfile = (path: string) => {
    const res = sideBarNavItems().people[1].items.filter((item) =>
      item.path.includes(path)
    );
    return Boolean(res.length > 0);
  };

  const toggleCreateWorkspace = () => {
    setIsAddingWorkspace(true);
  };

  const handleSwitchWorkspace = async (id: number) => {
    const res = await setDefaultAccount(id);
    if (res.status === 200) {
      setDefaultOrgDetails(id);
      // const path = history.location.pathname;
      // const x = path.split('/');
      // x.splice(0, 2);
      // const newPath = '/' + x.join('/');
      history.push(appRoute.getRoute('/'));
      window.location.reload();
    }
  };

  const handleAddWorkspace = async (name: string) => {
    try {
      const res = await createAccount({ name });
      if (res.status === 201) {
        const account = res.data;
        handleSwitchWorkspace(account.id);
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getNavItemMarkup = (items: NavItem[], show: boolean) => {
    const filteredItems = items.filter((item: NavItem) => {
      // if (currentModule !== 'settings') {
      const requiredPermission: ModuleAccess = MAPPED_MODULE_ACCESS[
        currentModule
      ] as ModuleAccess;
      if (
        currentUser[requiredPermission] !== 'admin' &&
        item.requiredRole === 'admin'
      ) {
        return false;
      }
      // } else
      if (!currentUser.is_owner && item.requiredRole === 'owner') {
        return false;
      }
      return true;
    });
    if (show)
      return filteredItems.map((item, index) => (
        <li className={styles.navItem} key={index}>
          <NavLink
            to={
              getIfInProfile(item.path)
                ? appRoute.getRoute(item.path) +
                  '/' +
                  currentUser.employee_detail.id
                : appRoute.getRoute(item.path)
            }
            className={styles.navLink}
            activeClassName={styles.active}
            key={index}
            onClick={() => (!isDesktop ? setIsCollapsed(true) : null)}
          >
            {item.icon ? (
              <div className={styles.navLinkIcon}>{item.icon}</div>
            ) : null}
            {item.name}
          </NavLink>
        </li>
      ));
    else return null;
  };

  const getNavItems = (item: {
    items: NavItem[];
    title?: string;
    divider: boolean;
    restricted?: string;
  }) => (
    <>
      {item.title && (
        <div
          className={styles.navHeader}
          onClick={() => setInfoOpen(!isInfoOpen)}
        >
          {item.title}
          <FilledKeyboardArrowDownIcon />
        </div>
      )}
      {getNavItemMarkup(item.items, !item.title || isInfoOpen)?.length ? (
        <>
          <ul className={styles.navMenu}>
            {getNavItemMarkup(item.items, !item.title || isInfoOpen)}
          </ul>
          {item.divider && <Divider className={styles.divider} />}
        </>
      ) : null}
    </>
  );

  const getAddWorkspaceDialog = () => {
    if (isAddingWorkspace) {
      return (
        <AddWorkspace
          open={isAddingWorkspace}
          onClose={() => setIsAddingWorkspace(false)}
          onSubmit={handleAddWorkspace}
        />
      );
    } else return null;
  };

  const getFilteredSideBarNavItems = (currentModule: Modules) => {
    const sidebarNavItems = sideBarNavItems()[currentModule].filter(
      (item: {
        items: NavItem[];
        title?: string;
        divider: boolean;
        restricted?: string;
      }) =>
        !item.restricted ||
        (item.restricted === 'EMPLOYEE' && currentUser.employee_detail?.id)
    );
    if (currentModule === 'contacts') {
      const TagsNavList = {
        items: tagNavList,
        title: t('label.tags'),
        divider: false,
      };
      return [...sidebarNavItems, TagsNavList];
    }
    return sidebarNavItems;
  };

  const filteredSideBarNavItems = currentModule
    ? getFilteredSideBarNavItems(currentModule)
    : [];

  return (
    <>
      <div className={clsx(styles.navigation, isCollapsed && styles.collapsed)}>
        {/* <SpovioLogoIcon className={styles.logo} /> */}
        <div className={styles.header}>
          <AppDropdown currentModule={currentModule} />
          <UserProfileDropdown
            onLogout={onLogout}
            handleSwitchWorkspace={handleSwitchWorkspace}
            toggleCreateWorkspace={toggleCreateWorkspace}
          />
        </div>
        {currentModule &&
          filteredSideBarNavItems.map((item, index) => {
            return <Fragment key={index}>{getNavItems(item)}</Fragment>;
          })}
        {account.getCurrent() && (
          <div className={styles.activeOrg}>
            <Avatar
              className={styles.userMenuItemImage}
              textWrapClassName={styles.userMenuItemTextWrap}
              size={AvatarSize.avatar22}
              name={account.getCurrent()?.name}
              displayName
              url={account.getCurrent()?.logo}
            />
          </div>
        )}
      </div>
      <div className={clsx(styles.burgerMenu, !isCollapsed && styles.expanded)}>
        <BurgerMenuIcon
          className={styles.burgerMenuIcon}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
      {getAddWorkspaceDialog()}
    </>
  );
};
