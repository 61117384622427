import React from 'react';
type SpinIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const PerformanceIcon = (props: SpinIconProps) => {
  return (
    <svg
      {...props}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-39"
          transform="translate(-9.000000, -7.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Group-38" transform="translate(8.000000, 5.000000)">
            <g
              id="Signal-Bars,-BarGraph.1"
              transform="translate(0.000000, 1.000000)"
            >
              <g id="Group-80" transform="translate(2.500000, 2.500000)">
                <line
                  x1="4.16666667"
                  y1="8.33333333"
                  x2="4.16666667"
                  y2="11.6666667"
                  id="Path"
                ></line>
                <line
                  x1="7.5"
                  y1="3.33333333"
                  x2="7.5"
                  y2="11.6666667"
                  id="Path"
                ></line>
                <line
                  x1="10.8333333"
                  y1="5.83333333"
                  x2="10.8333333"
                  y2="11.6666667"
                  id="Path"
                ></line>
                <path
                  d="M11.2533333,0 C13.3225,0 15,1.6775 15,3.74666667 L15,11.2541667 C15,13.3225 13.3225,15 11.2533333,15 L3.74666667,15 C1.6775,15 0,13.3225 0,11.2533333 L0,3.75 C0,1.67916667 1.67916667,0 3.75,0 L11.2533333,0 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
