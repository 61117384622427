import axios, { AxiosResponse } from 'axios';
import { Pagination, TrainingInfo, TrainingInfoPayload } from '../types';

export const getTrainingList = async (
  employeeID: number
): Promise<AxiosResponse<Pagination<TrainingInfo[]>>> => {
  return await axios.get(`/training/?employee_id=${employeeID}`);
};

export const addTrainingInfo = async (
  employeeID: number,
  data: TrainingInfoPayload,
): Promise<AxiosResponse<TrainingInfo>> => {
  const payload = data;
  return await axios.post(`/training/?employee_id=${employeeID}`, payload);
};

export const updateTrainingInfo = async (
  id: number,
  employeeID: number,
  data: TrainingInfoPayload,
): Promise<AxiosResponse<TrainingInfo>> => {
  const payload = data;
  return await axios.patch(
    `/training/${id}/?employee_id=${employeeID}`,
    payload
  );
};
export const getTrainingInfo = async (
  id: number,
  employeeID: number
): Promise<AxiosResponse<TrainingInfo>> => {

  return await axios.get(`/training/${id}/?employee_id=${employeeID}`);
};
export const deleteTrainingInfo = async (
  id: number,
  employeeID: number
): Promise<AxiosResponse<TrainingInfo>> => {
  return await axios.delete(`/training/${id}/?employee_id=${employeeID}`);
};
