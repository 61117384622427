import { t } from 'i18next';

export enum REIMBURSEMENT_TABS {
  EXPENSE,
  MILEAGE,
}

export const REIMBURSEMENT_TYPES = {
  [REIMBURSEMENT_TABS.EXPENSE]: 'EXPENSE',
  [REIMBURSEMENT_TABS.MILEAGE]: 'MILEAGE',
};

export const REIMBURSEMENT_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date').toUpperCase(),
    className: 'dateCol',
  },
  {
    id: 'name',
    label: t('label.name').toUpperCase(),
    className: 'nameCol',
  },
  {
    id: 'person',
    label: t('label.person').toUpperCase(),
    className: 'personCol',
  },
  {
    id: 'type',
    label: t('label.type').toUpperCase(),
    className: 'typeCol',
  },
  {
    id: 'notes',
    label: t('label.description').toUpperCase(),
    className: 'notesCol',
  },
  {
    id: 'amount',
    label: t('label.amount').toUpperCase(),
    className: 'amountCol',
  },
  {
    id: 'status',
    label: t('label.status').toUpperCase(),
    className: 'statusCol',
  },
];

export const REIMBURSEMENT_REQUEST_TABLE_HEADERS = () => [
  {
    id: 'date',
    label: t('label.date').toUpperCase(),
    className: 'dateCol',
  },
  {
    id: 'person',
    label: t('label.person').toUpperCase(),
    className: 'personCol',
  },
  {
    id: 'amount',
    label: t('label.amount').toUpperCase(),
    className: 'amountCol',
  },
  {
    id: 'status',
    label: t('label.status').toUpperCase(),
    className: 'statusCol',
  },
];
