import { verifyEmail } from '@spovio/shared';
import { Button, SpovioIcon, SpovioLogoBlackIcon, Text } from '@spovio/ui';
import { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styles from './confirm-email.module.scss';

interface StateProps {
  email: string;
}

/* eslint-disable-next-line */
export interface ConfirmEmailProps {}

export function ConfirmEmail(props: ConfirmEmailProps) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const state = location.state as StateProps;

  const handleResend = async () => {
    setIsButtonDisabled(true);
    const response = await verifyEmail(state.email);
    if (response.status === 200) {
      setTimeout(() => setIsButtonDisabled(false), 5000);
    }
  };

  useEffect(() => {
    if (!state?.email) history.push('/login');
  }, []);

  return (
    <div className={styles.root}>
      <SpovioLogoBlackIcon className={styles.title} />
      <div className={styles.innerContent}>
        <Text variant="h1" className={styles.heading}>
          Confirm your Email
        </Text>
        <Text variant="caption" className={styles.helperText}>
          We have sent a link to your email address. Please follow the link
          inside to continue.
        </Text>
        <Button
          className={styles.signIn}
          size={'l'}
          type="submit"
          fullWidth
          disabled={isButtonDisabled}
          onClick={handleResend}
        >
          Missing an email? Resend it.
        </Button>
        <div className={styles.option}>
          Already have an account?
          <NavLink to="/login" className={styles.linkText}>
            Log in
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmail;
