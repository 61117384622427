import React from 'react';

import clsx from 'clsx';

import styles from './table-list.module.scss';

export type TableListProps = React.HTMLAttributes<HTMLElement>;

export const TableList = (props: TableListProps) => {
  return (
    <div className={clsx(styles.root, props.className)}>{props.children}</div>
  );
};
