import React from 'react';
type FileCopyIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const FileCopyIcon = (props: FileCopyIconProps) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.082 1.5c1.284 0 2.334 1 2.413 2.265l.005.153v5.245c0 1.284-1 2.334-2.265 2.413l-.153.005-.129-.002v.55a2.372 2.372 0 01-2.209 2.366l-.162.005h-5.71A2.372 2.372 0 011.5 12.128v-5.71a2.372 2.372 0 012.372-2.372h.547v-.128c0-1.284 1.001-2.334 2.265-2.413l.153-.005h5.245zm-2.6 3.741h-5.51c-.705 0-1.277.572-1.277 1.277v5.51c0 .705.572 1.277 1.277 1.277h5.51c.705 0 1.277-.572 1.277-1.277v-5.51c0-.705-.572-1.277-1.277-1.277z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};
