// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".choose-member_root__VtBOa {\n  max-height: 542px;\n  width: 275px;\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-sizing: border-box;\n}\n\n.choose-member_searchBar__3HZDl {\n  width: 100%;\n}\n\n.choose-member_header__s8OpW {\n  padding: 0 10px;\n  border-bottom: 1px solid #edeef2;\n}\n\n.choose-member_body__2JT45 {\n  padding: 12px 6px;\n  max-height: 275px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  min-height: 150px;\n  box-sizing: border-box;\n}\n\n.choose-member_avatar__3S0U8 {\n  margin-right: 10px;\n  font-size: 9px;\n}\n\n.choose-member_item__8NAjs {\n  border-radius: 4px;\n  padding: 8px 12px 8px 12px;\n  width: 100%;\n  color: #333333;\n  box-sizing: border-box;\n  font-size: 14px;\n  letter-spacing: -0.22px;\n  transition: background-color 0.2s;\n  cursor: pointer;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n.choose-member_item__8NAjs:hover {\n  background-color: #f5f6f7;\n}\n\n.choose-member_itemIcon__1aHwk {\n  position: absolute;\n  width: 14px;\n  height: 14px;\n  right: 10px;\n  color: #818385;\n  transition: opacity 0.2s;\n}\n\n.choose-member_enlarge__25fWk {\n  width: 20px;\n  height: 20px;\n}\n\n.choose-member_noDataLabel__1kNZg {\n  color: #5a6370;\n  font-size: 14px;\n  font-weight: 400;\n  letter-spacing: -0.23px;\n  line-height: 35px;\n  text-align: center;\n}\n\n.choose-member_noData__1Lvtr {\n  padding-left: 12px;\n}\n\n.choose-member_memberName__1f3Or {\n  max-width: 180px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", ""]);
// Exports
exports.locals = {
	"root": "choose-member_root__VtBOa",
	"searchBar": "choose-member_searchBar__3HZDl",
	"header": "choose-member_header__s8OpW",
	"body": "choose-member_body__2JT45",
	"avatar": "choose-member_avatar__3S0U8",
	"item": "choose-member_item__8NAjs",
	"itemIcon": "choose-member_itemIcon__1aHwk",
	"enlarge": "choose-member_enlarge__25fWk",
	"noDataLabel": "choose-member_noDataLabel__1kNZg",
	"noData": "choose-member_noData__1Lvtr",
	"memberName": "choose-member_memberName__1f3Or"
};
module.exports = exports;
