import {
  acceptInvitation,
  appMsgs,
  COMPONENT_STATE,
  createUserFromInvite,
  getErrorMessages,
  setAuthenticationDetails,
} from '@spovio/shared';
import {
  Button,
  FormControl,
  FormText,
  Input,
  Spin,
  SpovioIcon,
  SpovioLogoBlackIcon,
  Text,
  useSnackbar,
} from '@spovio/ui';
import { ErrorMessage, Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles from './accept-invite.module.scss';

interface MatchParams {
  token: string;
}
interface FormValues {
  password: string;
  confirmPassword: string;
}

const { LOADED, LOADING } = COMPONENT_STATE;

/* eslint-disable-next-line */
export type AcceptInviteProps = RouteComponentProps<MatchParams>;

export function AcceptInvite({ match, history }: AcceptInviteProps) {
  const [serverErrorMsg, setServerErrorMsg] = useState<string>();
  const [componentState, setComponentState] = useState(LOADED);
  const { showSnackbar } = useSnackbar();
  const verifyToken = useCallback(async () => {
    const inviteToken = match.params.token;
    if (inviteToken) {
      try {
        const res = await acceptInvitation(inviteToken);
        if (res.data.msg && res.data.msg === 'User Not Found') {
          setComponentState(LOADED);
        } else {
          showSnackbar(true, {
            msg: 'Invitation accepted',
            severity: 'success',
          });
          history.push('/');
        }
      } catch (error) {
        showSnackbar(true, {
          msg: 'Token Expired',
          severity: 'error',
        });
        history.push('/login');
      }
    }
  }, [match.params.token, history]);

  const handleFocus = () => {
    setServerErrorMsg(undefined);
  };

  const handleSubmit = async (values: FormValues, _actions: any) => {
    try {
      const res = await createUserFromInvite({
        password: values.password,
        token: match.params.token,
      });
      setAuthenticationDetails({ ...res.data });
      showSnackbar(true, {
        msg: 'Invitation accepted',
        severity: 'success',
      });
      history.push('/');
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  return (
    <div className={styles.root}>
      <SpovioLogoBlackIcon className={styles.title} />
      <div className={styles.innerContent}>
        <Text variant="h1" className={styles.heading}>
          Setup your account
        </Text>
        <Text variant="caption" className={styles.helperText}>
          To setup new account, please enter your password.
        </Text>
        {componentState === LOADING ? (
          <Spin size="full" />
        ) : (
          <Formik
            initialValues={{
              confirmPassword: '',
              password: '',
            }}
            validate={(values) => {
              const errors: FormikErrors<FormValues> = {};
              if (values.password && !values.confirmPassword) {
                errors.confirmPassword = 'Please re-enter your password';
              } else if (!values.password) {
                errors.password = 'Please enter a password';
              } else if (values.confirmPassword !== values.password) {
                errors.confirmPassword =
                  'Password confirmation does not match password';
              }
              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl gutter="l" className={styles.formGroup}>
                  <Input
                    name="password"
                    placeholder="New password"
                    type={'password'}
                    size="l"
                    status={
                      errors.password && touched.password ? 'invalid' : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.password}
                  />
                  <ErrorMessage name="password">
                    {(errorMessage) => (
                      <FormText type="invalid">{errorMessage}</FormText>
                    )}
                  </ErrorMessage>
                  {serverErrorMsg && (
                    <FormText type="invalid">{serverErrorMsg}</FormText>
                  )}
                </FormControl>
                <FormControl gutter="xl">
                  <Input
                    name="confirmPassword"
                    placeholder="Confirm password"
                    size="l"
                    type="password"
                    status={
                      errors.password && touched.password ? 'invalid' : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.confirmPassword}
                  />

                  <ErrorMessage name="confirmPassword">
                    {(errorMessage) => (
                      <FormText type="invalid">{errorMessage}</FormText>
                    )}
                  </ErrorMessage>
                </FormControl>
                <Button
                  fullWidth
                  size="l"
                  type="submit"
                  className={styles.submitBtn}
                  disabled={isSubmitting || !isValid}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default AcceptInvite;
