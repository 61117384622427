import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const AccountSelect = (props: IconsProps) => {
  return (
    <svg
      {...props}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Profile-pop-up"
          transform="translate(-33.000000, -162.000000)"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <g id="Group-80" transform="translate(16.000000, 55.000000)">
            <g id="Group-29" transform="translate(8.000000, 100.000000)">
              <g id="Group-25" transform="translate(8.000000, 5.000000)">
                <g id="users-06" transform="translate(0.000000, 1.000000)">
                  <g
                    id="Account-Select"
                    transform="translate(2.000000, 2.000000)"
                  >
                    <path
                      d="M13.3333333,13.8106667 C11.9386667,15.1662222 10.0515556,16 7.97955556,16 C5.928,16 4.06133333,15.1831111 2.66666667,13.8524444"
                      id="Path"
                    ></path>
                    <polyline
                      id="Path-2"
                      points="5.72560465 8.25513149 7.52705336 10 11.5304419 6"
                    ></polyline>
                    <path
                      d="M0.866666667,11.5777778 C0.324444444,10.4986667 0,9.29066667 0,8 C0,3.57866667 3.57866667,0 8,0 C12.4213333,0 16,3.57866667 16,8 C16,9.28888889 15.6773333,10.4977778 15.1315556,11.576"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
