import { t } from 'i18next';
import React from 'react';
import { PieChart, Legend, Pie, Cell, ResponsiveContainer } from 'recharts';
import styles from './age-breakdown.module.scss';

/* eslint-disable-next-line */
interface AgeBreakdownProps {
  data: {
    name: string;
    value: number;
  }[];
  title: string;
}

export const AgeBreakdown = (props: AgeBreakdownProps) => {
  const COLORS = [
    '#8aba00',
    '#e8454d',
    '#f96422',
    '#7092eb',
    '#d05489',
    '#3bab38',
    '#ffcb15',
  ];

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div>
        {payload.map((entry: any, index: number) => (
          <div
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 3,
              textTransform: 'capitalize',
            }}
          >
            <div
              style={{
                width: 6,
                height: 16,
                background: entry.color,
                borderRadius: 6,
                marginRight: 8,
              }}
            ></div>
            {entry.value === 'Unspecified'
              ? t('label.unspecified')
              : entry.value === 'male'
              ? t('label.male')
              : entry.value === 'female'
              ? t('label.female')
              : entry.value}
            &nbsp;
            <span
              style={{
                color: '#808080',
                fontSize: 14,
              }}
            >
              {'(' + Math.round(entry.payload?.value) + '%)'}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.pie}>
      <div className={styles.title}>{props.title}</div>
      <PieChart width={730} height={250}>
        <Pie
          data={props.data}
          cx={100}
          cy={70}
          innerRadius={60}
          outerRadius={95}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          content={renderLegend}
          verticalAlign="top"
          height={36}
          wrapperStyle={{
            left: 250,
            top: 12,
          }}
        />
      </PieChart>
    </div>
  );
};
export default AgeBreakdown;
