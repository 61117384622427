import {
  createLocation,
  getErrorMessages,
  LocationItem,
  updateLocation,
} from '@spovio/shared';
import { Dialog, FormControl, Input, useSnackbar } from '@spovio/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './location-add.module.scss';

/* eslint-disable-next-line */
export interface LocationAddProps {
  open: boolean;
  isEdit?: boolean;
  locationItem?: LocationItem;
  onClose: () => void;
  onAdd: (updated?: boolean) => void;
}

export function LocationAdd({
  open,
  isEdit,
  locationItem,
  onClose,
  onAdd,
}: LocationAddProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const { showSnackbar } = useSnackbar();
  const [name, setName] = useState(
    isEdit && locationItem ? locationItem.name : ''
  );

  const patchLocation = async (name: string, id: number) => {
    try {
      await updateLocation(id, name);
      onAdd(true);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const postLocation = async () => {
    try {
      await createLocation(name);
      onAdd();
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const handleSubmit = () => {
    onClose();
    setIsModalVisible(false);
    if (isEdit && locationItem) {
      patchLocation(name, locationItem.id);
    } else {
      postLocation();
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    setName(e.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={
          isEdit ? t('dimensions.editLocation') : t('dimensions.addLocation')
        }
        action={isEdit ? t('label.save') : t('label.add')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
        isActionDisabled={!name}
      >
        <div className={styles.content}>
          <FormControl gutter="l" fullWidth={true}>
            <Input
              tabIndex={0} //this is to make the input focusable
              name="name"
              validationType={['required']}
              focusAuto
              labelClassName={styles.label}
              label={t('label.name')}
              value={name}
              required={true}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default LocationAdd;
