import {
  COMPONENT_STATE,
  deleteTrainingInfo,
  getErrorMessages,
  getTrainingList,
  TrainingInfo,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  CompensationIcon,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  NoDataContent,
  PlusIcon,
  TableMarkup,
  TableMarkupColumns,
  Text,
  useConfirmation,
  useCurrentUser,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PeopleTrainingForm from '../people-training-form/people-training-form';
import styles from './people-training.module.scss';

interface PeopleTrainingProps {
  employeeId: number;
}
const { LOADED, LOADING } = COMPONENT_STATE;
export const PeopleTraining = (props: PeopleTrainingProps) => {
  const { t } = useTranslation();
  const { showConfirmation } = useConfirmation();
  const { showSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const [trainings, setTrainings] = useState<TrainingInfo[]>();
  const [componentState, setComponentState] = useState(LOADING);
  const [trainingForm, setTrainingForm] = useState<{
    id?: number;
    open: boolean;
  }>({
    id: undefined,
    open: false,
  });

  const employeeID = currentUser.employee_detail.id;

  const init = useCallback(async () => {
    setComponentState(LOADING);
    try {
      const res = await getTrainingList(props.employeeId);

      setTrainings(res.data.results);
      setComponentState(LOADED);
    } catch (error) {
      //todo
    }
  }, [props.employeeId]);

  useEffect(() => {
    init();
  }, [init]);

  const onDeleteTraining = async (id: number) => {
    try {
      await deleteTrainingInfo(id, employeeID);
      showSnackbar(true, {
        msg: userMsgs().training.delete,
        severity: 'success',
      });
      const updatedtrainings = trainings?.filter((c) => c.id !== id);
      setTrainings(updatedtrainings);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const onCreatePatchTrainee = (trainee: TrainingInfo) => {
    const isExistingTrainee = trainings?.find(
      (_trainee) => _trainee.id === trainee.id
    );
    if (isExistingTrainee) {
      Object.assign(isExistingTrainee, trainee);
    } else {
      trainings && setTrainings([...trainings, trainee]);
    }
  };

  const onListAction = (dropId: string, training: TrainingInfo) => {
    if (dropId === 'edit') {
      setTrainingForm({ id: training.id, open: true });
    } else if (dropId === 'delete') {
      showConfirmation(true, {
        header: t('people.training.trainingDeleteConfirmation.head'),
        content: t('extension.thisCannotBeUndone'),
        onConfirmation: () => onDeleteTraining(training.id),
      });
    }
  };

  const dropdownItems = (): DropdownListItem[] => [
    {
      label: t('label.edit'),
      icon: <EditIcon />,
      id: 'edit',
    },
    {
      label: t('label.delete'),
      id: 'delete',
      icon: <DeleteIcon />,
    },
  ];
  const getColumns = (): TableMarkupColumns[] => {
    return [
      {
        title: t('label.date'),
        dataIndex: 'date',
        key: 'date',
        className: styles.dateCol,
      },
      {
        title: t('label.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('label.hours'),
        dataIndex: 'hours',
        key: 'hours',
        text_align: 'right',
        render: (v) => {
          return v + 'h';
        },
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        text_align: 'right',
        render: (v, data) => {
          return (
            <Dropdown
              buttonClassName={styles.actionContent}
              data={dropdownItems()}
              onItemClick={(drpId) => onListAction(drpId, data)}
            />
          );
        },
      },
    ];
  };

  const getDataSource = () => {
    return trainings?.map((item) => {
      return {
        key: item.id,
        date: moment(item.training_on).format('DD/MM/YYYY'),
        name: item.name,
        hours: item.hours,
        action: null,
        data: item,
      };
    });
  };

  const getAddTrainingDialog = () => {
    if (trainingForm.open) {
      return (
        <PeopleTrainingForm
          open={trainingForm.open}
          trainingId={trainingForm.id || undefined}
          employeeId={props.employeeId}
          onClose={() => setTrainingForm({ id: undefined, open: false })}
          onSubmit={onCreatePatchTrainee}
        />
      );
    } else return null;
  };

  return (
    <>
      <div className={styles.emergency}>
        <div className={styles.sectionHeader}>
          <Text variant="h4" className={styles.heading}>
            {t('label.trainingDetails')}
          </Text>
          <Button
            className={styles.addItemBtn}
            variant="text"
            onClick={() => setTrainingForm({ id: undefined, open: true })}
          >
            <PlusIcon className={styles.plusIcon} /> {t('label.addTraining')}
          </Button>
        </div>
        <TableMarkup
          columns={getColumns()}
          dataSource={getDataSource()}
          isLoading={componentState === LOADING}
          noData={
            <NoDataContent
              title={t('label.training')}
              desc={t('extension.noTrainingFound')}
              icon={<CompensationIcon className={styles.noDataIcon} />}
              containerClassName={styles.blankList}
            />
          }
        />
      </div>
      {getAddTrainingDialog()}
    </>
  );
};
export default PeopleTraining;
