// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".language-settings_root__3i2-v {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  padding-top: 100px;\n}\n\n.language-settings_innerContent__6GhZy {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 864px;\n  padding: 0 100px;\n  width: calc(100% - 16px);\n}", ""]);
// Exports
exports.locals = {
	"root": "language-settings_root__3i2-v",
	"innerContent": "language-settings_innerContent__6GhZy"
};
module.exports = exports;
