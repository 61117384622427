// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".time-add_content__2hvlX {\n  padding: 20px 25px 0 25px;\n}\n.time-add_content__2hvlX .time-add_row__2lJrM {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-auto-columns: minmax(50%, auto);\n  column-gap: 10px;\n}\n.time-add_content__2hvlX .time-add_label__TYydy {\n  color: #535659;\n  font-size: 14px;\n  letter-spacing: -0.1px;\n  line-height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"content": "time-add_content__2hvlX",
	"row": "time-add_row__2lJrM",
	"label": "time-add_label__TYydy"
};
module.exports = exports;
