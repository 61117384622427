import { debounce } from '@material-ui/core';
import {
  appRoute,
  BasicFilter,
  clone,
  COMPONENT_STATE,
  deleteTag,
  getBasicTagsList,
  getErrorMessages,
  getTags,
  Tag,
  TagListType,
  useModuleAccess,
  userMsgs,
} from '@spovio/shared';
import {
  Button,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  Spin,
  TagIcon,
  useConfirmation,
  useSnackbar,
  useTagNavList,
} from '@spovio/ui';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useHistory } from 'react-router-dom';
import ContactTagAddDialog from '../contact-tag-add-dialog/contact-tag-add-dialog';
import ContactTagDetails from '../contact-tag-details/contact-tag-details';
import ContactTagEditDialog from '../contact-tag-edit-dialog/contact-tag-edit-dialog';
import ContactTagTableList from '../contact-tag-table-list/contact-tag-table-list';
import styles from './contact-tag-list.module.scss';

/* eslint-disable-next-line */
export interface ContactTagListProps {}

const { LOADED, LOADING } = COMPONENT_STATE;
export const ContactTagList = (props: ContactTagListProps) => {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const { tagNavList, setTagNavList } = useTagNavList();
  const basicFilter: BasicFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };
  const [filter, setFilter] = useState<BasicFilter>(basicFilter);
  const [tags, setTags] = useState<TagListType>({} as TagListType);
  const { showSnackbar } = useSnackbar();
  const { showConfirmation } = useConfirmation();
  const { isAdmin } = useModuleAccess('contact_access');
  const history = useHistory();

  const requestTags = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getTags(_filter);
        setTags(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateSidebarTags = async () => {
    const res = await getBasicTagsList();
    if (res.status === 200) {
      const { data } = res;
      const tagNavs = data.map((tag) => ({
        path: `/contacts/tag/${tag.id}`,
        name: tag.name,
        icon: <TagIcon />,
      }));
      setTagNavList(tagNavs);
    }
  };

  const getTagCreateButton = () => {
    if (!isAdmin) return null;
    return (
      <Button
        size={'s'}
        component={NavLink}
        to={appRoute.getRoute(`/contacts/tags/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('tags.addTag')}
        </>
      </Button>
    );
  };

  const onFilter = useCallback(
    (filter: BasicFilter) => {
      setComponentState(LOADING);
      requestTags(filter);
    },
    [requestTags]
  );
  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
      </div>
    );
  };

  const deleteTagFromList = async (id: number) => {
    showSnackbar(false);
    try {
      const res = await deleteTag(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: userMsgs().tags.delete,
          severity: 'success',
        });
        requestTags();
        updateSidebarTags();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const deleteConfirmation = (tag: Tag) => {
    showConfirmation(true, {
      header: t('tags.tagDeleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => deleteTagFromList(tag.id),
    });
  };

  const getTagsTable = () => (
    <ContactTagTableList
      tags={tags.results}
      filter={filter}
      onFilter={onFilter}
      componentState={componentState}
      onListAction={(dropId, tag) => {
        if (dropId === 'view') {
          history.push({
            pathname: appRoute.getRoute(`/contacts/tags/${tag.id}/view`),
          });
        } else if (dropId === 'edit') {
          history.push(appRoute.getRoute(`/contacts/tags/${tag.id}/edit`));
        } else if (dropId === 'delete') {
          deleteConfirmation(tag);
        }
      }}
    />
  );

  const getPaginationFooter = () => {
    if (!tags) return null;
    const { has_next, has_prev, total_count, page_size, page, links } = tags;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };

  const getTagRoutes = () => (
    <Suspense fallback={<Spin size="full" />}>
      <Route
        exact
        path={appRoute.getPath(`/contacts/tags/add`)}
        render={() => (
          <ContactTagAddDialog
            onAddTag={() => {
              requestTags();
              updateSidebarTags();
            }}
          />
        )}
      />
      <Route
        exact
        path={appRoute.getPath(`/contacts/tags/:id/edit`)}
        render={(props) => (
          <ContactTagEditDialog
            {...props}
            onEdit={(data: Tag) => {
              const _tags = tags.results.find(
                (tag) => tag.id === Number(props.match.params.id)
              );
              _tags && Object.assign(_tags, data);
              setTags({ ...tags });
              updateSidebarTags();
            }}
          />
        )}
      />
    </Suspense>
  );

  useEffect(() => {
    requestTags();
  }, []);

  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.tags')}</h4>}
        rightContent={getTagCreateButton()}
        bottomContent={getFilters()}
      />
      {getTagsTable()}
      {getPaginationFooter()}
      {getTagRoutes()}
    </>
  );
};

export default ContactTagList;
