import { Folder, FolderPayload, validateForm } from '@spovio/shared';
import { Dialog, FormControl, Input } from '@spovio/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './people-add-edit-folder.module.scss';

/* eslint-disable-next-line */
export interface PeopleAddEditFolderProps {
  open: boolean;
  folder: Folder;
  onClose: () => void;
  onSubmit: (value: FolderPayload) => void;
}

export function PeopleAddEditFolder({
  open,
  folder,
  onClose,
  onSubmit,
}: PeopleAddEditFolderProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [values, setValues] = useState({} as FolderPayload);

  useEffect(() => {
    setIsModalVisible(open);
    if (folder && folder.id) {
      setValues({ ...folder, created_by: folder.created_by.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
    onSubmit(values);
  };

  const isValid = () => {
    return validateForm.isRequired(values.name);
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={
          folder && folder.id ? t('label.editFolder') : t('label.addFolder')
        }
        action={t('label.save')}
        open={isModalVisible}
        isActionDisabled={!isValid()}
        maxWidth="sm"
        onClose={() => handleClose()}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <FormControl gutter="xs" fullWidth={true}>
            <Input
              name="name"
              required
              validationType={['required']}
              labelClassName={styles.label}
              label={t('label.name')}
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </FormControl>
        </div>
      </Dialog>
    </div>
  );
}

export default PeopleAddEditFolder;
