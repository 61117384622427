import React from 'react';

type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const UploadIcon = (props: IconProps) => {
  return (
    <svg
      {...props}
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Upload</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Files" transform="translate(-1351.000000, -153.000000)">
          <g
            id="Group-15-Copy-2"
            transform="translate(1343.000000, 146.000000)"
          >
            <g id="Group-63" transform="translate(8.000000, 6.000000)">
              <g id="Upload" transform="translate(0.000000, 1.000000)">
                <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
                <g
                  id="Group-67"
                  transform="translate(1.131667, 2.500000)"
                  stroke="#4461CB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.2"
                >
                  <line
                    x1="5.86833333"
                    y1="8.16666667"
                    x2="5.86833333"
                    y2="4.08333333"
                    id="Path"
                  ></line>
                  <polyline
                    id="Path"
                    points="4.60425 5.25 5.86833333 3.98591667 7.13241667 5.25"
                  ></polyline>
                  <path
                    d="M8.20166667,8.16666667 L9.695,8.16666667 C10.8196667,8.16666667 11.7366667,7.24966667 11.7366667,6.125 C11.7366667,5.00033333 10.8196667,4.08333333 9.695,4.08333333 L9.44125,4.08333333 L9.44125,3.5 C9.44125,1.56916667 7.87208333,9.76996262e-15 5.94125,9.76996262e-15 C4.20466667,9.76996262e-15 2.765,1.2705 2.492,2.93008333 C1.10541667,3.00066667 4.62740957e-13,4.13758333 4.62740957e-13,5.54166667 C4.62740957e-13,6.99125 1.17541667,8.16666667 2.625,8.16666667 L3.535,8.16666667"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
