import {
  appRoute,
  COMPONENT_STATE,
  deleteReimbursement,
  getErrorMessages,
  getReimbursementList,
  Reimbursement,
  ReimbursementFilter,
  ReimbursementListType,
  requestReimbursement,
  useModuleAccess,
} from '@spovio/shared';
import {
  Button,
  Header,
  Pagination,
  PlusIcon,
  SearchBar,
  Spin,
  useConfirmation,
  useSnackbar,
} from '@spovio/ui';
import { clone, debounce } from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, useHistory } from 'react-router-dom';
import { ReimbursementAddDialog } from '../reimbursement-add-dialog/reimbursement-add-dialog';
import { ReimbursementEditDialog } from '../reimbursement-edit-dialog/reimbursement-edit-dialog';
import MileagesListTable from '../reimbursement-list-table/reimbursement-list-table';
import { ReimbursementViewDialog } from '../reimbursement-view-dialog/reimbursement-view-dialog';
import './reimbursement-list.module.scss';
import styles from './reimbursement-list.module.scss';

/* eslint-disable-next-line */
interface ReimbursementListProps {
  all?: boolean;
}
const { LOADED, LOADING } = COMPONENT_STATE;
export const ReimbursementList = (props: ReimbursementListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedReimbursementList, setSelectedReimbursementList] = useState(
    [] as number[]
  );
  const [componentState, setComponentState] = useState(LOADING);
  const [reimbursements, setReimbursements] = useState<ReimbursementListType>(
    {} as ReimbursementListType
  );
  const { isAdmin } = useModuleAccess('reimbursement_access');
  const { showSnackbar } = useSnackbar();
  const { showConfirmation } = useConfirmation();

  useEffect(() => {
    getMileages();
  }, []);

  const mileagesFilter: ReimbursementFilter = {
    page: '1',
    page_size: '25',
    search_text: '',
  };
  const [filter, setFilter] = useState<ReimbursementFilter>(mileagesFilter);

  const getMileages = useCallback(
    async (_filter = clone(filter)) => {
      setFilter(_filter);
      setComponentState(LOADING);
      try {
        const res = await getReimbursementList(_filter);
        setReimbursements(res.data);
        setComponentState(LOADED);
      } catch (error: any) {
        const msg = getErrorMessages(error);
        showSnackbar(true, { msg, severity: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = async () => {
    showSnackbar(false);
    try {
      const res = await requestReimbursement(selectedReimbursementList);
      if (res.status === 200) {
        showSnackbar(true, {
          msg: 'Reimbursement request sent successfully',
          severity: 'success',
        });
        setSelectedReimbursementList([]);
        getMileages();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getFilters = () => {
    return (
      <div className={styles.bottomContent}>
        <SearchBar
          onSearch={debouncedSearch}
          placeholder={t('label.search')}
          containerClassName={styles.searchBar}
        />
      </div>
    );
  };
  const getMileagesCreateButton = () => {
    return (
      <Button
        size={'s'}
        component={NavLink}
        to={appRoute.getRoute(`/reimbursements/list/add`)}
      >
        <>
          <PlusIcon className={styles.plusIcon} />
          {t('reimbursement.addReimbursement')}
        </>
      </Button>
    );
  };
  const deleteExpenseFromList = async (id: number) => {
    try {
      const res = await deleteReimbursement(id);
      if (res.status === 204) {
        showSnackbar(true, {
          msg: 'Reimbursement deleted successfully',
          severity: 'success',
        });
        getMileages();
      }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      showConfirmation(false);
    }
  };

  const onselect = (id: number) => {
    if (selectedReimbursementList.includes(id)) {
      setSelectedReimbursementList(
        selectedReimbursementList.filter((item) => item !== id)
      );
    } else {
      setSelectedReimbursementList([...selectedReimbursementList, id]);
    }
  };

  const getMileagesTable = () => {
    return (
      <>
        <MileagesListTable
          mileages={reimbursements.results}
          filter={filter}
          onFilter={onFilter}
          selectedReimbursementList={selectedReimbursementList}
          onselect={onselect}
          componentState={componentState}
          onListAction={(dropId, mileage) => {
            if (dropId === 'edit') {
              history.push(
                appRoute.getRoute(`/reimbursements/list/${mileage.id}/edit`)
              );
            } else if (dropId === 'delete') {
              deleteConfirmation(mileage);
            } else if (dropId === 'view') {
              history.push(
                appRoute.getRoute(`/reimbursements/list/${mileage.id}/view`)
              );
            }
          }}
        />
        {reimbursements.results?.length ? (
          <div className={styles.submit}>
            <Button
              size={'m'}
              onClick={handleSubmit}
              disabled={!selectedReimbursementList.length}
            >
              {t('extension.submitForApproval')}
            </Button>
          </div>
        ) : null}
      </>
    );
  };
  const deleteConfirmation = (expense: Reimbursement) => {
    showConfirmation(true, {
      header: t('reimbursement.deleteConfirmation.head'),
      content: t('extension.thisCannotBeUndone'),
      onConfirmation: () => deleteExpenseFromList(expense.id),
    });
  };
  const onEditMileages = (data: Reimbursement) => {
    const _mileages = reimbursements.results.find(
      (item) => item.id === data.id
    );
    _mileages && Object.assign(_mileages, data);
    setReimbursements({ ...reimbursements });
  };

  const getMileagesRoutes = () => {
    return (
      <Suspense fallback={<Spin size="full" />}>
        <Route
          exact
          path={appRoute.getPath(`/reimbursements/list/add`)}
          render={() => (
            <ReimbursementAddDialog
              onAddMileage={() => {
                getMileages();
              }}
            />
          )}
        />
        <Route
          exact
          path={appRoute.getPath(`/reimbursements/list/:id/edit`)}
          render={(props) => (
            <ReimbursementEditDialog {...props} onEdit={onEditMileages} />
          )}
        />
        <Route
          exact
          path={appRoute.getPath(`/reimbursements/list/:id/view`)}
          render={(props) => (
            <ReimbursementViewDialog
              {...props}
              onUpdate={onEditMileages}
              onDelete={deleteConfirmation}
            />
          )}
        />
      </Suspense>
    );
  };

  const onFilter = useCallback(
    (filter: ReimbursementFilter) => {
      setComponentState(LOADING);
      getMileages(filter);
    },
    [getMileages]
  );
  const debouncedSearch = useMemo(
    () =>
      debounce((searchText: string) => {
        onFilter({
          ...filter,
          search_text: searchText.trim(),
          page: '1',
        });
      }, 700),
    [filter, onFilter]
  );
  const getPaginationFooter = () => {
    if (!reimbursements) return null;
    const { has_next, has_prev, total_count, page_size, page, links } =
      reimbursements;
    if (!total_count) return null;
    return (
      <Pagination
        total={total_count}
        page={page}
        next={has_next ? page + 1 : undefined}
        pageSize={Number(filter.page_size)}
        prev={has_prev ? page - 1 : undefined}
        className={styles.pagination}
        onChange={(page) => onFilter({ ...filter, page: page })}
        onSelect={(value) => {
          onFilter({ ...filter, page_size: value });
        }}
      />
    );
  };
  return (
    <>
      <Header
        className={styles.header}
        leftContent={<h4>{t('label.reimbursements')}</h4>}
        rightContent={getMileagesCreateButton()}
        bottomContent={getFilters()}
      />
      {getMileagesTable()}
      {getPaginationFooter()}
      {getMileagesRoutes()}
    </>
  );
};
export default ReimbursementList;
