import { Popover } from '@material-ui/core';
import { getBasicUserList, getErrorMessages, User } from '@spovio/shared';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  AvatarSize,
  Button,
  CloseIcon,
  TeamMatesIcon,
  useSnackbar,
} from '../../';
import ChooseMember from '../choose-member/choose-member';
import styles from './select-people-popover.module.scss';

/* eslint-disable-next-line */
export interface SelectPeoplePopoverProps {
  onUserSelect: (user?: User) => void;
  selectedMember?: User;
}

type MouseBtnEvt = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export function SelectPeoplePopover({
  onUserSelect,
  selectedMember,
}: SelectPeoplePopoverProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [btnInfo, setBtnInfo] = useState<User>();
  const [peopleList, setPeopleList] = useState<User[]>();
  const { showSnackbar } = useSnackbar();
  const handleClick = (e: MouseBtnEvt) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onSelectUser = (userInfo?: User) => {
    onUserSelect(userInfo);
    setBtnInfo(userInfo);
    setAnchorEl(null);
  };

  const getUsers = useCallback(async () => {
    try {
      const res = await getBasicUserList();
      const filterList = res.data.filter(
        (user) => user.id !== selectedMember?.id
      );
      setPeopleList(filterList);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getButtonInfo = () => {
    const memberName = btnInfo ? btnInfo.name || btnInfo.email : '';
    return btnInfo ? (
      <>
        <Avatar
          className={styles.avatar}
          name={memberName}
          url={btnInfo.pic}
          size={AvatarSize.avatar22}
        />
        <span className={styles.userName}>{memberName}</span>
        <div
          className={styles.closeIcon}
          onClick={(e) => {
            e.stopPropagation();
            onSelectUser(undefined);
          }}
        >
          <CloseIcon />
        </div>
      </>
    ) : (
      <div className={styles.innerWrapBtn}>
        <TeamMatesIcon className={styles.teamMatesIcon} />
        {t('label.teammates')}
      </div>
    );
  };
  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      <Button
        aria-describedby={id}
        isActive={open}
        onClick={handleClick}
        color={'default'}
        size={'s'}
        className={clsx(styles.btn, open || btnInfo ? styles.active : null)}
      >
        {getButtonInfo()}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {peopleList && (
          <ChooseMember
            userList={peopleList}
            onUserSelect={onSelectUser}
            selectedMembers={selectedMember ? [selectedMember.id] : []}
          />
        )}
      </Popover>
    </div>
  );
}

export default SelectPeoplePopover;
