// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".select-people-popover_btn__1ki4c {\n  color: #707379;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.1px;\n  line-height: 28px;\n  background-color: transparent;\n}\n.select-people-popover_btn__1ki4c:hover {\n  background-color: #efeff18c;\n}\n.select-people-popover_btn__1ki4c.select-people-popover_active__N3Jr6 {\n  background-color: #efeff18c;\n}\n\n.select-people-popover_userName__3hYtW {\n  margin: 0 6px;\n  max-width: 200px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 28px;\n}\n\n.select-people-popover_closeIcon__1af19 {\n  display: flex;\n  align-self: center;\n  justify-content: center;\n}\n.select-people-popover_closeIcon__1af19 svg {\n  height: 10px;\n  width: 10px;\n}\n\n.select-people-popover_teamMatesIcon__3sTvL {\n  color: #707379;\n  width: 16px;\n  height: 16px;\n  margin-right: 6px;\n}\n\n.select-people-popover_innerWrapBtn__641Y9 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"btn": "select-people-popover_btn__1ki4c",
	"active": "select-people-popover_active__N3Jr6",
	"userName": "select-people-popover_userName__3hYtW",
	"closeIcon": "select-people-popover_closeIcon__1af19",
	"teamMatesIcon": "select-people-popover_teamMatesIcon__3sTvL",
	"innerWrapBtn": "select-people-popover_innerWrapBtn__641Y9"
};
module.exports = exports;
