import React, { useCallback, useState, useEffect } from 'react';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import clsx from 'clsx';
import styles from './ProjectAutoCompleteUser.module.scss';
import { toLowerCase, User, validateEmail } from '@spovio/shared';
import {
  Autocomplete,
  Avatar,
  AvatarProps,
  AvatarSize,
  TagItem,
} from '@spovio/ui';
import { useTranslation } from 'react-i18next';

interface ProjectAutoCompleteUserProps {
  className?: string;
  inputClassName?: string;
  onChange: (user: AutoCompleteUserItem[]) => void;
  userList: User[];
  defaultValue?: User[];
  selectedUsers: User[];
  noStyle?: boolean;
  showOptionalAdd?: boolean;
  loading?: boolean;
}

export interface AutoCompleteUserItem {
  name: string;
  data?: {
    id: number;
    fullName: string;
    email: string;
    type?: string;
    disabled?: boolean;
    avatarProps: AvatarProps;
  };
}

const ProjectAutoCompleteUser: React.FC<ProjectAutoCompleteUserProps> = ({
  className,
  inputClassName,
  onChange,
  userList,
  defaultValue,
  selectedUsers = [],
  noStyle,
  showOptionalAdd,
  loading,
}: ProjectAutoCompleteUserProps) => {
  const { t } = useTranslation();
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const isUserInSelection = useCallback(
    (user: User) => {
      return selectedUsers.some((_user) => _user.id === user.id);
    },
    [selectedUsers]
  );
  const getUserAutoCompleteList = useCallback(
    (users: User[]): AutoCompleteUserItem[] => {
      const autoCompleteList = users.map((user) => {
        const disabled = isUserInSelection(user);
        return {
          name: user.name,
          data: {
            id: user.id,
            fullName: user.name,
            email: user.email,
            disabled,
            avatarProps: {
              url: user.pic,
              size: AvatarSize.avatar22,
              name: user.name,
            },
          },
        };
      });
      return autoCompleteList;
    },
    [isUserInSelection]
  );

  const [userAutoCompleteList, setUserAutoCompleteList] = useState<
    AutoCompleteUserItem[]
  >(getUserAutoCompleteList(userList));

  useEffect(() => {
    setUserAutoCompleteList(getUserAutoCompleteList(userList));
  }, [selectedUsers, getUserAutoCompleteList, userList]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<unknown>, values: AutoCompleteUserItem[]) => {
      onChange(values);
    },
    [onChange]
  );
  const handleInputChange = useCallback(() => {
    setIsValidEmail(true);
  }, [setIsValidEmail]);

  const handleAddItemClick = (event: React.MouseEvent, name: string) => {
    const validEmail = !validateEmail(name);
    if (!validEmail) {
      event.stopPropagation();
      setIsValidEmail(false);
    }
  };

  const getRenderOption = (option: AutoCompleteUserItem) => {
    const { data, name } = option;
    const isOptionalAdd = data && data.type === 'add';
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <React.Fragment>
        {isOptionalAdd ? (
          <button
            className={clsx(styles.autoCompleteItem, styles.itemAdd, {
              [styles.error]: !isValidEmail,
            })}
            onClick={(event: React.MouseEvent) => {
              handleAddItemClick(event, name);
            }}
          >
            {isValidEmail ? (
              <div className={styles.optionalItemWrap}>
                <Avatar
                  className={styles.addIcon}
                  // icon={<AddIcon />}
                  color={'#ffffff'}
                />
                <span className={styles.optionalContent}>
                  {t('inviteViaEmail') + ':'}
                </span>
                {`"${name}"`}
              </div>
            ) : (
              t('messages.emailNotValid')
            )}
          </button>
        ) : (
          <div
            className={clsx(
              styles.autoCompleteItem,
              data?.disabled && styles.muted
            )}
          >
            <div className={styles.userWrap}>
              <Avatar className={styles.avatar} {...data?.avatarProps} />
              <span className={styles.userName}>{data?.fullName}</span>
              <span className={styles.email}>{data?.email}</span>
            </div>
            {data?.disabled && (
              <span className={styles.alreadyInProject}>
                Already in this project
              </span>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const filterOptions = (
    options: AutoCompleteUserItem[],
    params: FilterOptionsState<AutoCompleteUserItem>
  ) => {
    const filter = createFilterOptions({
      stringify: (option: AutoCompleteUserItem) =>
        `${option.name} ${option.data?.email}`,
    });
    const filtered = filter(options, params);
    const { inputValue } = params;
    const exactMatch = options.some(
      (option) => toLowerCase(option.data?.email) === toLowerCase(inputValue)
    );
    if (showOptionalAdd && inputValue && !exactMatch) {
      filtered.push({
        name: inputValue,
        data: { id: 0, type: 'add' },
      } as AutoCompleteUserItem);
    }
    return filtered as AutoCompleteUserItem[];
  };

  return (
    <Autocomplete
      className={className}
      inputClassName={inputClassName}
      options={userAutoCompleteList}
      placeholder={t('extension.addPeopleByNameOrEmailAddress')}
      noStyle={noStyle}
      freeSolo={showOptionalAdd}
      onInputChange={handleInputChange}
      multiple
      defaultValue={getUserAutoCompleteList(defaultValue || [])}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          const tagProps = getTagProps({
            index,
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { onDelete, key, ...restProps } = tagProps as any;
          return (
            <TagItem
              key={key}
              prefix={
                option.data.type === 'add' ? (
                  <span className={styles.mailIcon}>{/* <MailIcon /> */}</span>
                ) : (
                  <Avatar {...option.data.avatarProps} />
                )
              }
              // Adding explicit onDelete to get notified if we change method name later.
              onDelete={onDelete}
              containerClassName={styles.tagItem}
              label={
                option.data.type === 'add' ? option.name : option.data.fullName
              }
              {...restProps}
            />
          );
        });
      }}
      getOptionSelected={(options, value) =>
        value.data.email === options.data.email
      }
      filterOptions={filterOptions}
      renderOption={getRenderOption}
      onChange={handleChange}
      //   loading={loading}
    />
  );
};

export default ProjectAutoCompleteUser;
