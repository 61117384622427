import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const LockIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentcolor"
    >
      <path
        d="M9.47917 10.57143V9c0-1.39222 1.12861-2.52083 2.52083-2.52083 1.39222 0 2.52083 1.12861 2.52083 2.52083v1.57143h.09028c.76706 0 1.38889.62183 1.38889 1.38889v3.6508C16 16.37816 15.37817 17 14.61111 17H9.3889C8.62183 17 8 16.37817 8 15.61111v-3.6508c0-.76705.62183-1.38888 1.38889-1.38888h.09028zm1.04166 0h2.95834V9c0-.81692-.66225-1.47917-1.47917-1.47917-.81692 0-1.47917.66225-1.47917 1.47917v1.57143z"
        fillRule="evenodd"
      />
    </svg>
  );
};
