// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".birthdays_month_title__1WpCn {\n  margin: 15px 20px 5px 30px;\n  color: #333;\n}\n\n.birthdays_list__vQADm {\n  padding-left: 40px;\n  color: #333;\n}\n.birthdays_list__vQADm li {\n  text-decoration: none;\n  list-style: none;\n  margin: 8px 0;\n}\n.birthdays_list__vQADm .birthdays_date__P8NDT {\n  color: #222;\n  display: inline-block;\n  font-size: 18px;\n  margin-right: 10px;\n  min-width: 24px;\n  text-align: right;\n}\n.birthdays_list__vQADm .birthdays_age__1RXGe {\n  color: #808080;\n  display: inline-block;\n  font-size: 11px;\n  font-weight: 600;\n  margin-left: 2px;\n}\n\n.birthdays_title__1XvXa {\n  display: flex;\n  align-items: center;\n}\n.birthdays_title__1XvXa span {\n  color: #808080;\n  font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"month_title": "birthdays_month_title__1WpCn",
	"list": "birthdays_list__vQADm",
	"date": "birthdays_date__P8NDT",
	"age": "birthdays_age__1RXGe",
	"title": "birthdays_title__1XvXa"
};
module.exports = exports;
