// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-header_header__1S_m0 {\n  border-radius: 12px 12px 0 0;\n  padding: 19px 25px;\n  box-shadow: 0 1px 0 0 #eae9eb;\n  flex: auto 0 0;\n  z-index: 1;\n}\n.dialog-header_header__1S_m0 .dialog-header_title__2nlop {\n  text-align: left;\n  line-height: 28px;\n  color: #252526;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: -0.35px;\n}\n.dialog-header_header__1S_m0 .dialog-header_desc__15VqT {\n  color: #808080;\n  font-size: 13px;\n  letter-spacing: -0.1px;\n  line-height: 19px;\n  max-width: 372px;\n  padding: 4px 0;\n}\n.dialog-header_header__1S_m0 .dialog-header_closeButton__6JwQ7 {\n  position: absolute;\n  right: 20px;\n  top: 16px;\n  height: 28px;\n  width: 28px;\n}\n.dialog-header_header__1S_m0 .dialog-header_closeButton__6JwQ7 svg {\n  width: 12px;\n  height: 14px;\n  color: #6d6971;\n}\n\n.dialog-header_default__2V_7Y {\n  background-color: #fff;\n}\n\n.dialog-header_compact__3m3ip {\n  background-color: #f9f7fa;\n  padding-bottom: 10px;\n}\n.dialog-header_compact__3m3ip.dialog-header_hasDescription__zoL4M {\n  padding-bottom: 8px;\n}", ""]);
// Exports
exports.locals = {
	"header": "dialog-header_header__1S_m0",
	"title": "dialog-header_title__2nlop",
	"desc": "dialog-header_desc__15VqT",
	"closeButton": "dialog-header_closeButton__6JwQ7",
	"default": "dialog-header_default__2V_7Y",
	"compact": "dialog-header_compact__3m3ip",
	"hasDescription": "dialog-header_hasDescription__zoL4M"
};
module.exports = exports;
