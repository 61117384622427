import styles from './people-training-form.module.scss';

import {
  addTrainingInfo,
  COMPONENT_STATE,
  getErrorMessages,
  getTrainingInfo,
  TrainingInfo,
  TrainingInfoPayload,
  updateTrainingInfo,
  userMsgs,
  validateForm,
} from '@spovio/shared';
import {
  DatePickerPopover,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  LoadingSpinner,
  useSnackbar,
} from '@spovio/ui';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PeopleTrainingFormProps {
  open: boolean;
  trainingId?: number;
  employeeId: number;
  onClose: () => void;
  onSubmit: (value: TrainingInfo) => void;
}

const { LOADED, LOADING } = COMPONENT_STATE;

export function PeopleTrainingForm({
  open,
  employeeId,
  trainingId,
  onClose,
  onSubmit,
}: PeopleTrainingFormProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(open || false);
  const [componentState, setComponentState] = useState<COMPONENT_STATE>();
  const [values, setValues] = useState<TrainingInfoPayload>({
    name: '',
    hours: undefined,
    training_on: moment().format('DD/MM/YYYY'),
  });
  const { showSnackbar } = useSnackbar();

  const getTrainingDetails = useCallback(async () => {
    if (!trainingId) return;
    setComponentState(LOADING);
    try {
      const res = await getTrainingInfo(trainingId, employeeId);
      setValues(res.data);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      setComponentState(LOADED);
    }
  }, [employeeId, showSnackbar, trainingId]);

  useEffect(() => {
    setIsModalVisible(open);
    getTrainingDetails();
  }, [getTrainingDetails, open, trainingId]);

  const onCreateTraining = async () => {
    values.training_on = moment(values.training_on).format('YYYY-MM-DD');
    try {
      const res = await addTrainingInfo(employeeId, values);
      onSubmit(res.data);
      showSnackbar(true, { msg: userMsgs().training.add });
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      handleClose();
    }
  };

  const onPartialUpdateTraining = async () => {
    if (!trainingId) return;
    values.training_on = moment(values.training_on).format('YYYY-MM-DD');
    try {
      const res = await updateTrainingInfo(trainingId, employeeId, values);
      onSubmit(res.data);
      showSnackbar(true, { msg: userMsgs().training.edit });
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    } finally {
      handleClose();
    }
  };
  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  };

  const handleSubmit = () => {
    if (trainingId) {
      onPartialUpdateTraining();
    } else {
      onCreateTraining();
    }
  };

  const isValid = () => {
    return validateForm.isRequired(values?.name);
  };

  const getForm = () => {
    return (
      <div className={styles.content}>
        <FormControl gutter="l" fullWidth={true} className={styles.label}>
          <Input
            name="name"
            required
            validationType={['required']}
            labelClassName={styles.label}
            label={t('label.name')}
            placeholder={t('label.name')}
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </FormControl>
        <FormControl gutter="l" fullWidth={true}>
          <InputLabel
            className={styles.label}
            label={t('label.date')}
            required
          />
          <DatePickerPopover
            id={'date'}
            size={'m'}
            paperClassName={styles.paper}
            selected={values.training_on}
            onChange={(date) => {
              values.training_on = date as string;
              setValues({ ...values });
            }}
            placeholder={t('label.dobPlaceholder')}
          />
        </FormControl>
        <FormControl gutter="l" fullWidth={true}>
          <Input
            name="hours"
            type="number"
            validationType={['required']}
            labelClassName={styles.label}
            label={t('label.hours')}
            inputProps={{ min: 0 }}
            value={values.hours}
            required
            onChange={(e) =>
              setValues({
                ...values,
                hours: e.target.value as any,
              })
            }
          />
        </FormControl>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <Dialog
        title={trainingId ? t('label.editTraining') : t('label.addTraining')}
        action={t('label.save')}
        open={isModalVisible}
        maxWidth="sm"
        onClose={() => handleClose()}
        isActionDisabled={!isValid()}
        onSubmit={handleSubmit}
      >
        {componentState === LOADING ? <LoadingSpinner /> : getForm()}
      </Dialog>
    </div>
  );
}

export default PeopleTrainingForm;
