import {
  CheckApprovalType,
  COMPONENT_STATE,
  getTimeFromNumber,
  TimeListByDate,
  TimesByWeekItem,
  TimeWeekGroupType,
} from '@spovio/shared';
import {
  Button,
  DeleteIcon,
  Dropdown,
  DropdownListItem,
  EditIcon,
  LockIcon,
  NoDataContent,
  Skeleton,
  TimesIcon,
} from '@spovio/ui';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './time-day-view.module.scss';

/* eslint-disable-next-line */
export interface TimeDayViewProps {
  timesByWeek: TimesByWeekItem;
  selectedDate: string;
  onChangeSelectedDate: (date: string) => void;
  onDropdownClick: (id: string, item: TimeWeekGroupType) => void;
  currentTotalHours: number;
  selectedTimeList: TimeWeekGroupType[];
  onApproval: (type?: string) => void;
  approvalStatus?: CheckApprovalType;
  isApprovalBtn: boolean;
  onApprovalBtnAction: (value: boolean) => void;
  componentState?: COMPONENT_STATE;
  onAddTime: () => void;
  isAdmin?: boolean;
}

export function TimeDayView({
  timesByWeek,
  selectedDate,
  onChangeSelectedDate,
  onDropdownClick,
  currentTotalHours,
  selectedTimeList,
  onApproval,
  approvalStatus,
  isApprovalBtn,
  onApprovalBtnAction,
  componentState = COMPONENT_STATE.LOADING,
  onAddTime,
  isAdmin,
}: TimeDayViewProps) {
  const { t } = useTranslation();
  const getDayTimeViewHeaderItems = () => {
    const headerItems =
      timesByWeek.times_list &&
      timesByWeek.times_list.map((item: TimeListByDate, index) => {
        const date = moment(item.day).format('ddd').toLowerCase();
        return (
          <div
            className={clsx(
              styles.dayTimeViewHeaderItem,
              styles.dayTimeViewHeaderItemAction,
              item.day === selectedDate && styles.active
            )}
            onClick={() => onChangeSelectedDate(item.day)}
            key={index}
          >
            <div className={styles.dayTimeViewHeaderItemTitle}>{date}</div>
            <div className={styles.dayTimeViewHeaderItemTime}>
              {getTimeFromNumber(item.per_day_total_hours)}
            </div>
          </div>
        );
      });

    return (
      <>
        {headerItems}
        <div
          className={clsx(
            styles.dayTimeViewHeaderItem,
            styles.dayTimeViewTotalHeader
          )}
        >
          <span>{t('label.total')}:</span>
          {getTimeFromNumber(timesByWeek.total_hours)}
        </div>
      </>
    );
  };

  const getDayTimeViewHeaderPreloader = () => {
    const headerItems = Array(7)
      .fill(0)
      .map((item, index) => {
        return (
          <div
            className={clsx(
              styles.dayTimeViewHeaderItem,
              styles.dayTimeViewHeaderItemAction,
              item.day === selectedDate && styles.active
            )}
            onClick={() => onChangeSelectedDate(item.day)}
            key={index}
          >
            <div className={styles.dayTimeViewHeaderItemTitle}>
              <Skeleton variant="rect" width={35} height={15} />
            </div>
            <div className={styles.dayTimeViewHeaderItemTime}>
              <Skeleton variant="rect" width={70} height={20} />
            </div>
          </div>
        );
      });

    return (
      <>
        {headerItems}
        <div
          className={clsx(
            styles.dayTimeViewHeaderItem,
            styles.dayTimeViewTotalHeader
          )}
        >
          <span>{t('label.total')}:</span>
          <Skeleton variant="rect" width={35} height={20} />
        </div>
      </>
    );
  };

  const getTimeContentLists = (selectedTimeList: TimeWeekGroupType[]) => {
    const dropdownItems: DropdownListItem[] = [
      {
        label: t('label.edit'),
        icon: <EditIcon />,
        id: 'edit',
      },
      {
        label: t('label.delete'),
        id: 'delete',
        icon: <DeleteIcon />,
      },
    ];

    if (selectedTimeList.length <= 0) {
      return (
        <div className={styles.blankListWrap}>
          {!approvalStatus?.is_approved ? (
            <NoDataContent
              title={t('label.time')}
              desc={t('timesheet.noData.desc')}
              icon={<TimesIcon className={styles.timesIcon} />}
              containerClassName={styles.blankList}
              buttonTitle={t('timesheet.addTime')}
              onBtnAction={onAddTime}
            />
          ) : (
            <NoDataContent
              title={t('timesheet.thisTimesheetHasBeenApproved')}
              variant="compact"
            />
          )}
        </div>
      );
    }

    const listView =
      selectedTimeList &&
      selectedTimeList.map((time: TimeWeekGroupType, index) => {
        return (
          <div className={styles.timeContentWrap} key={index}>
            <div className={styles.timeContentDetailWrap}>
              <div className={styles.timeContentDetailProject}>
                {time.project_name}
              </div>
              <div className={styles.timeContentDetailComment}>
                {time.notes}
              </div>
            </div>
            <div className={styles.timeContentValueWrap}>
              <div className={styles.timeContentValue}>
                {getTimeFromNumber(time.hours)}
              </div>
              <div className={styles.timeContentActionWrap}>
                {!approvalStatus?.is_approved && !time.is_time_off ? (
                  <Dropdown
                    buttonClassName={styles.actionContent}
                    data={dropdownItems}
                    onItemClick={(id: string) => onDropdownClick(id, time)}
                  />
                ) : (
                  <LockIcon
                    className={clsx(styles.lockIcon, styles.actionContent)}
                  />
                )}
              </div>
            </div>
          </div>
        );
      });

    return listView;
  };

  const getTimeContentPreloader = () => {
    const listView = Array(3)
      .fill(0)
      .map((item, index) => {
        return (
          <div className={styles.timeContentWrap} key={index}>
            <div className={styles.timeContentDetailWrap}>
              <div className={styles.timeContentDetailProject}>
                <Skeleton variant="rect" width={70} height={20} />
              </div>
              <div className={styles.timeContentDetailComment}>
                <Skeleton variant="rect" width={150} height={15} />
              </div>
            </div>
            <div className={styles.timeContentValueWrap}>
              <div className={styles.timeContentValue}>
                <Skeleton variant="rect" width={50} height={20} />
              </div>
              <div className={styles.timeContentActionWrap}>
                <Skeleton variant="rect" width={20} height={20} />
              </div>
            </div>
          </div>
        );
      });

    return listView;
  };

  const getApprovalButton = () => {
    const submited = !(
      approvalStatus?.is_submitted && !approvalStatus?.is_approved
    );
    const approved = approvalStatus?.is_approved;
    if (approved && !isAdmin) {
      return null;
    }

    if (isApprovalBtn) {
      return (
        <div className={styles.approvalConfirmWrap}>
          {approved
            ? t('timesheet.thisWillUnlockEntireTimesheet')
            : submited
            ? t('timesheet.submitThisTimesheet')
            : t('timesheet.resubmitThisTimesheet')}
          <Button
            className={clsx(approved && styles.danger, styles.submitBtn)}
            size={'s'}
            onClick={() => onApproval(approved ? 'unlock' : '')}
          >
            {t('label.yes') + ', '}
            {approved
              ? t('label.unlock')
              : submited
              ? t('label.submit')
              : t('label.resubmit')}
            , {t('label.timesheet').toLowerCase()}
          </Button>
          <Button
            className={styles.cancelSubmitBtn}
            size={'s'}
            onClick={() => onApprovalBtnAction(false)}
          >
            {t('label.cancel')}
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          className={clsx(styles.approvalBtn, approved && styles.withdraw)}
          onClick={() => onApprovalBtnAction(true)}
        >
          {approved
            ? t('timesheet.withdrawApproval')
            : submited
            ? t('timesheet.submitWeekForApproval')
            : t('timesheet.resubmitWeekForApproval')}
        </Button>
      );
    }
  };

  const getApprovedWrap = () => {
    return <div className={styles.approvedWrap}>Approved</div>;
  };

  return (
    <div className={styles.dayListViewWrap}>
      {approvalStatus?.is_approved && getApprovedWrap()}
      <div className={styles.viewWrap}>
        <div className={styles.viewInnerWrap}>
          <div className={styles.dayListViewHeaderWrap}>
            <div className={styles.dayListViewHeaderInnerWrap}>
              <div className={styles.dayListViewHeader}>
                <div className={styles.dayListViewHeaderItemsWrap}>
                  {componentState === COMPONENT_STATE.LOADED
                    ? getDayTimeViewHeaderItems()
                    : getDayTimeViewHeaderPreloader()}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dayListViewContentWrap}>
            <div className={styles.dayListViewContentInnerWrap}>
              {componentState === COMPONENT_STATE.LOADING
                ? getTimeContentPreloader()
                : getTimeContentLists(selectedTimeList)}
              <div className={styles.dayListViewTotalWrap}>
                <div className={styles.dayListViewTotal}>
                  <span>{t('label.total')} :</span>
                  <div className={styles.dayListViewTotalValue}>
                    {componentState === COMPONENT_STATE.LOADED ? (
                      getTimeFromNumber(currentTotalHours)
                    ) : (
                      <Skeleton variant="rect" width={50} height={20} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.approvalWrap}>
        <div className={styles.approvalBtnWrap}>
          {componentState === COMPONENT_STATE.LOADED ? (
            getApprovalButton()
          ) : (
            <Button className={styles.approvalBtn}>
              <Skeleton variant="rect" width={150} height={20} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TimeDayView;
