// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-training_header__3HAkV .people-training_plusIcon__1UVWc {\n  margin-right: 6px;\n}\n\n.people-training_emergency__1M-Dr {\n  width: 100%;\n}\n@media (min-width: 1024px) {\n  .people-training_emergency__1M-Dr {\n    width: calc(100vw - 540px);\n  }\n}\n.people-training_emergency__1M-Dr .people-training_sectionHeader__1riTj {\n  display: flex;\n  justify-content: space-between;\n  padding: 30px 30px 15px 30px;\n}\n.people-training_emergency__1M-Dr .people-training_sectionHeader__1riTj .people-training_heading__2pViM {\n  margin: 0px;\n}\n.people-training_emergency__1M-Dr .people-training_sectionHeader__1riTj .people-training_addItemBtn__1UfpD {\n  height: auto;\n}\n.people-training_emergency__1M-Dr .people-training_sectionHeader__1riTj .people-training_addItemBtn__1UfpD .people-training_plusIcon__1UVWc {\n  margin-right: 8px;\n}\n\n.people-training_dateCol__3CbcZ {\n  width: 100px;\n}", ""]);
// Exports
exports.locals = {
	"header": "people-training_header__3HAkV",
	"plusIcon": "people-training_plusIcon__1UVWc",
	"emergency": "people-training_emergency__1M-Dr",
	"sectionHeader": "people-training_sectionHeader__1riTj",
	"heading": "people-training_heading__2pViM",
	"addItemBtn": "people-training_addItemBtn__1UfpD",
	"dateCol": "people-training_dateCol__3CbcZ"
};
module.exports = exports;
