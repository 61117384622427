// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-control_root__2gt_Q.form-control_xs__27QEo {\n  margin-bottom: 8px;\n}\n.form-control_root__2gt_Q.form-control_s__1Vj4v {\n  margin-bottom: 12px;\n}\n.form-control_root__2gt_Q.form-control_m__1N8hr {\n  margin-bottom: 16px;\n}\n.form-control_root__2gt_Q.form-control_l__Pak-H {\n  margin-bottom: 20px;\n}\n.form-control_root__2gt_Q.form-control_xl__2C6CC {\n  margin-bottom: 40px;\n}\n.form-control_root__2gt_Q.form-control_noStyle__16ILZ {\n  margin-bottom: 0;\n}\n.form-control_root__2gt_Q.form-control_fullWidth__6nIk7 {\n  width: 100%;\n}\n.form-control_root__2gt_Q.form-control_flex__UzSGT {\n  display: flex;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"root": "form-control_root__2gt_Q",
	"xs": "form-control_xs__27QEo",
	"s": "form-control_s__1Vj4v",
	"m": "form-control_m__1N8hr",
	"l": "form-control_l__Pak-H",
	"xl": "form-control_xl__2C6CC",
	"noStyle": "form-control_noStyle__16ILZ",
	"fullWidth": "form-control_fullWidth__6nIk7",
	"flex": "form-control_flex__UzSGT"
};
module.exports = exports;
