import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { appInfo } from '../constants';
import {
  User,
  UserList,
  UserPayload,
  UsersFilter,
  UserUpdatePayload,
} from '../types';

export const getBasicUserList = async (): Promise<AxiosResponse<User[]>> => {
  return await axios.get(`/settings/users/basic-list/`);
};

export const getUserList = async (
  filters?: UsersFilter
): Promise<AxiosResponse<UserList>> => {
  const queryParams = stringify({ ...filters }, { skipNull: true });
  return await axios.get(`/settings/users/?${queryParams}`);
};

export const inviteUser = async (
  user: UserPayload
): Promise<AxiosResponse<User>> => {
  return await axios.post(`/settings/invite-user/`, user);
};

export const changeRoles = async (
  userId: number,
  roles: Partial<UserPayload>
): Promise<AxiosResponse<User>> => {
  return await axios.patch(`/settings/users/${userId}/change-role/`, roles);
};

export const updateUser = async (
  userId: number,
  user: Partial<UserUpdatePayload>
): Promise<AxiosResponse<User>> => {
  return await axios.patch(`/settings/users/${userId}/`, user);
};

export const updateStatus = async (
  userId: number,
  archive: boolean
): Promise<AxiosResponse<User>> => {
  return await axios.get(
    `/settings/users/${userId}/update-status/?archived=${archive}`
  );
};

export const deleteUser = async (id: number): Promise<AxiosResponse<User>> => {
  return await axios.delete(`/settings/users/${id}`);
};

export const setDefaultAccount = async (
  id: number
): Promise<AxiosResponse<User>> => {
  return await axios.post(`/settings/users/set-default-account/`, {
    account: id,
  });
};

export const changePassword = async (payload: {
  old_password: string;
  new_password: string;
}): Promise<AxiosResponse> => {
  return await axios({
    baseURL: appInfo.baseURL,
    method: 'patch',
    url: '/auth/change-password/',
    data: payload,
  });
};
