import {
  appRoute,
  COMPONENT_STATE,
  currency,
  getDateFormat,
  getErrorMessages,
  getReimbursement,
  Reimbursement,
  REIMBURSEMENT_TYPES,
} from '@spovio/shared';
import {
  Avatar,
  AvatarSize,
  Button,
  DeleteIcon,
  Dialog,
  Divider,
  Dropdown,
  EditIcon,
  FormControl,
  Spin,
  StatusBadge,
  Text,
  useSnackbar,
} from '@spovio/ui';
import clsx from 'clsx';
import { lowerCase } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import styles from './reimbursement-view-dialog.module.scss';

interface MatchParams {
  id?: string;
}

type RouteProps = RouteComponentProps<MatchParams, StaticContext>;

export interface ReimbursementViewDialogProps extends RouteProps {
  onUpdate: (reimbursement: Reimbursement) => void;
  onDelete: (reimbursement: Reimbursement) => void;
  hideDropDown?: boolean;
}
const { LOADED, LOADING } = COMPONENT_STATE;
export function ReimbursementViewDialog(props: ReimbursementViewDialogProps) {
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(LOADING);
  const [values, setValues] = useState<Reimbursement>({} as Reimbursement);
  const { showSnackbar } = useSnackbar();
  const expense_id = Number(props.match.params.id);

  const handleClose = () => {
    props.history.goBack();
  };

  const handleSubmit = () => {
    return null;
  };

  useEffect(() => {
    getInitialLists();
  }, []);

  const getInitialLists = async () => {
    setComponentState(LOADING);
    try {
      const res = await getReimbursement(expense_id);
      setValues(res.data);
      setComponentState(LOADED);
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  if (componentState === LOADING) {
    return <Spin />;
  }

  const onApprove = async () => {
    try {
      // const res = await approveReimbursement(expense_id);
      // if (res.status === 200) {
      //   setValues((prev) => ({
      //     ...prev,
      //     status: res.data.status,
      //   }));
      //   props.onUpdate(res.data);
      // }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  const onReject = async () => {
    try {
      // const res = await rejectReimbursement(expense_id);
      // if (res.status === 200) {
      //   setValues((prev) => ({
      //     ...prev,
      //     status: res.data.status,
      //   }));
      //   props.onUpdate(res.data);
      // }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  const onUnlock = async () => {
    try {
      // const res = await unlockMileage(expense_id);
      // if (res.status === 200) {
      //   setValues((prev) => ({
      //     ...prev,
      //     status: res.data.status,
      //   }));
      //   props.onUpdate(res.data);
      // }
    } catch (error: any) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  const onDelete = async () => {
    values && props.onDelete(values);
    handleClose();
  };

  const getDialogTitle = () => {
    const formattedFileName = values.file
      ?.split('?')[0]
      .split('/')
      .splice(-1)[0];

    return (
      <div className={styles.dialogTitle}>
        <div className={clsx(styles.row, styles.mainHead)}>
          <div className={styles.title}>{values?.name}</div>
        </div>

        <div className={styles.subHeader}>
          <div className={styles.amount}>
            <span className={styles.key}>{t('label.amount')}:</span>
            <span className={styles.value}>
              {currency.getSymbol() + ' ' + values?.amount}
            </span>
          </div>
          <div className={styles.actions}>
            <div className={styles.actionBtn}>
              <StatusBadge
                label={
                  values.status === 'APPROVED'
                    ? t('label.approved')
                    : values.status === 'REJECTED'
                    ? t('label.rejected')
                    : t('label.pending').toLowerCase()
                }
                status={lowerCase(values.status)}
              />
            </div>
            {!['APPROVED'].includes(values.status) && !props.hideDropDown && (
              <Dropdown
                data={[
                  {
                    label: t('label.edit'),
                    id: 'edit',
                    icon: <EditIcon />,
                  },
                  {
                    label: t('label.delete'),
                    id: 'delete',
                    icon: <DeleteIcon />,
                  },
                ]}
                onItemClick={(drpId) => {
                  if (drpId === 'edit') {
                    props.history.replace(
                      appRoute.getRoute(
                        `/reimbursements/list/${expense_id}/edit/`
                      )
                    );
                  } else if (drpId === 'delete') {
                    onDelete();
                  } else if (drpId === 'unlock') {
                    onUnlock();
                  }
                }}
              />
            )}
          </div>
        </div>
        <Divider className={styles.headerDivider} />
        <FormControl gutter="xs" className={styles.row}>
          <Text variant="h6" className={styles.key}>
            {t('label.submittedBy')}:
          </Text>
          <Avatar
            name={values.user?.user.name}
            textClassName={styles.avatarText}
            displayName
            url={values.user?.user?.pic}
            size={AvatarSize.avatar22}
            className={styles.avatar}
            circle
          />
        </FormControl>
        <FormControl gutter="xs" className={styles.row}>
          <Text variant="h6" className={styles.key}>
            {t('label.date')}:
          </Text>
          <Text variant="h6" className={styles.value}>
            {getDateFormat(values.date, 'MMM DD, YYYY')}
          </Text>
        </FormControl>
        <FormControl gutter="xs" className={styles.row}>
          <Text variant="h6" className={styles.key}>
            {t('label.project')}:
          </Text>
          <Text variant="h6" className={styles.value}>
            {values.project?.name || '—'}
          </Text>
        </FormControl>
        {values.file && (
          <FormControl gutter="xs" className={styles.row}>
            <Text variant="h6" className={styles.key}>
              {t('label.file')}:
            </Text>
            <a
              href={values.file}
              className={clsx(styles.value, styles.fileName)}
            >
              {formattedFileName}
            </a>
          </FormControl>
        )}
        {values.reimbursement_type === REIMBURSEMENT_TYPES[1] && (
          <>
            <FormControl gutter="xs" className={styles.row}>
              <Text variant="h6" className={styles.key}>
                {t('label.miles')}:
              </Text>
              <Text variant="h6" className={styles.value}>
                {values.miles.toFixed(2)}
              </Text>
            </FormControl>
            <FormControl gutter="xs" className={styles.row}>
              <Text variant="h6" className={styles.key}>
                {t('label.mileageRate')}:
              </Text>
              <Text variant="h6" className={styles.value}>
                {values.rate.toFixed(2)}/Mile
              </Text>
            </FormControl>
          </>
        )}
        <FormControl gutter="xs" className={styles.row}>
          <Text variant="body1" className={styles.content}>
            {values.notes}
          </Text>
        </FormControl>
      </div>
    );
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      onClose={() => handleClose()}
      onSubmit={handleSubmit}
      showFooter={false}
      title={getDialogTitle()}
    >
      <div className={styles.footer}>
        <Text variant="h6" className={styles.heading}>
          {t('label.auditTrail')}
        </Text>
        <ul className={styles.auditTrial}>
          <li className={styles.listItem}>
            <Text variant="body1" className={styles.subHeading}>
              {t('label.createdOn')}
            </Text>
            <Text variant="body1" className={styles.value}>
              {moment(values.created_at).utc().format('MMM DD, YYYY hh:mm A z')}
            </Text>
          </li>
          <li className={styles.listItem}>
            <Text variant="body1" className={styles.subHeading}>
              {t('label.updatedOn')}
            </Text>
            <Text variant="body1" className={styles.value}>
              {moment(values.updated_at).utc().format('MMM DD, YYYY hh:mm A z')}
            </Text>
          </li>
        </ul>
      </div>
    </Dialog>
  );
}

export default ReimbursementViewDialog;
