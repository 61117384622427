// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input-placeholder_input__2g5gY {\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  vertical-align: middle;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  border: 1px solid #e0dee0;\n  border-radius: 6px;\n  padding: 0 12px;\n  width: 100%;\n  max-width: none;\n  box-shadow: none;\n  position: relative;\n  cursor: pointer;\n  color: #c6cccd;\n  padding-right: 26px;\n  margin: 0;\n  text-align: left;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  font-size: 14px;\n  color: #a6a6a6;\n}\n\n.input-placeholder_append__3W1zV {\n  position: absolute;\n  right: 8px;\n}\n\n.input-placeholder_active__3vpx5 {\n  border-color: #0075f3;\n  box-shadow: 0 0 0 1px #0075f3;\n}\n\n.input-placeholder_hasValue__3mh6t {\n  color: #252526;\n}\n\n.input-placeholder_hasTag__3Ya-D {\n  padding-left: 4px;\n}\n\n.input-placeholder_disabled__DyFCH {\n  pointer-events: none;\n  background-color: #f0f2f5;\n  opacity: 0.4;\n}\n\n.input-placeholder_xs__32JG9 {\n  min-height: 28px;\n  padding-left: 8px;\n  padding-right: 24px;\n}\n.input-placeholder_xs__32JG9 .input-placeholder_append__3W1zV {\n  top: 6px;\n}\n\n.input-placeholder_s__3Zad8 {\n  min-height: 28px;\n  padding-left: 8px;\n  padding-right: 24px;\n}\n.input-placeholder_s__3Zad8 .input-placeholder_append__3W1zV {\n  top: 8px;\n}\n\n.input-placeholder_m__b4qBH {\n  min-height: 36px;\n}\n.input-placeholder_m__b4qBH .input-placeholder_append__3W1zV {\n  top: 9px;\n}\n\n.input-placeholder_l__3bgNK {\n  min-height: 44px;\n}\n.input-placeholder_l__3bgNK .input-placeholder_append__3W1zV {\n  top: 14px;\n  right: 14px;\n}", ""]);
// Exports
exports.locals = {
	"input": "input-placeholder_input__2g5gY",
	"append": "input-placeholder_append__3W1zV",
	"active": "input-placeholder_active__3vpx5",
	"hasValue": "input-placeholder_hasValue__3mh6t",
	"hasTag": "input-placeholder_hasTag__3Ya-D",
	"disabled": "input-placeholder_disabled__DyFCH",
	"xs": "input-placeholder_xs__32JG9",
	"s": "input-placeholder_s__3Zad8",
	"m": "input-placeholder_m__b4qBH",
	"l": "input-placeholder_l__3bgNK"
};
module.exports = exports;
