import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const PendingIcon = (props: IconsProps) => {
  return (
    <svg
      viewBox="0 0 14 14"
      {...props}
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.917 11.667v-.584a4.083 4.083 0 0 1 8.167 0v.584"
        stroke="#FFAE42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7a2.333 2.333 0 1 0 0-4.667A2.333 2.333 0 0 0 7 7Z"
        stroke="#FFAE42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
