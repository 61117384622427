// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".account-settings_root__3EcUU {\n  align-items: center;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  padding-top: 100px;\n}\n.account-settings_root__3EcUU .account-settings_title__28TR0 {\n  position: absolute;\n  top: 25px;\n  left: 30px;\n  margin: 0;\n}\n\n.account-settings_innerContent__1VrT_ {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 864px;\n  padding: 0 100px;\n  width: calc(100% - 16px);\n}\n.account-settings_innerContent__1VrT_ .account-settings_heading__jLi5q {\n  font-size: 26px;\n  font-weight: 600;\n  letter-spacing: -0.13px;\n  line-height: 31px;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 3px;\n}\n.account-settings_innerContent__1VrT_ .account-settings_helperText__3jrSt {\n  color: #747578;\n  font-size: 14px;\n  letter-spacing: -0.16px;\n  line-height: 20px;\n  text-align: center;\n  margin-bottom: 56px;\n}\n.account-settings_innerContent__1VrT_ .account-settings_option__3Yf53 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 25px;\n  color: #6d6971;\n  font-size: 14px;\n  letter-spacing: -0.22px;\n  line-height: 17px;\n  text-align: center;\n}\n.account-settings_innerContent__1VrT_ .account-settings_option__3Yf53 .account-settings_linkText__2KVUr {\n  margin-left: 3px;\n}\n\n.account-settings_actionBtn__VObyR {\n  font-size: 14px;\n  height: 36px;\n  padding: 0 20px;\n  letter-spacing: -0.11px;\n  line-height: 17px;\n}", ""]);
// Exports
exports.locals = {
	"root": "account-settings_root__3EcUU",
	"title": "account-settings_title__28TR0",
	"innerContent": "account-settings_innerContent__1VrT_",
	"heading": "account-settings_heading__jLi5q",
	"helperText": "account-settings_helperText__3jrSt",
	"option": "account-settings_option__3Yf53",
	"linkText": "account-settings_linkText__2KVUr",
	"actionBtn": "account-settings_actionBtn__VObyR"
};
module.exports = exports;
