import React from 'react';

type CloseIconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const CloseIcon = (props: CloseIconProps) => {
  return (
    <svg
      {...props}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Close</title>
      <g
        id="Time"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Add-Time"
          transform="translate(-910.000000, -251.000000)"
          stroke="currentColor"
          strokeWidth="1.8"
        >
          <g id="Group-100" transform="translate(490.000000, 227.000000)">
            <g id="Group-11" transform="translate(412.000000, 16.000000)">
              <g id="Close-Model" transform="translate(9.403806, 9.403806)">
                <line
                  x1="9.19238816"
                  y1="9.19238816"
                  x2="-6.37850061e-17"
                  y2="3.97903932e-13"
                  id="Stroke-725"
                  transform="translate(4.596194, 4.596194) rotate(90.000000) translate(-4.596194, -4.596194) "
                ></line>
                <line
                  x1="9.19238816"
                  y1="9.19238816"
                  x2="-6.37850061e-17"
                  y2="3.97903932e-13"
                  id="Stroke-725-Copy"
                  transform="translate(4.596194, 4.596194) scale(-1, 1) rotate(90.000000) translate(-4.596194, -4.596194) "
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CloseIconSm = (props: CloseIconProps) => {
  return (
    <svg
      {...props}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 1l3 3 3-3M1 7l3-3 3 3" />
      </g>
    </svg>
  );
};
