import React from 'react';

import clsx from 'clsx';

import styles from './table-list-cell.module.scss';

export type TableListCellProps = React.HTMLAttributes<HTMLElement>

export const TableListCell = ({
  children,
  className,
  ...restProps
}: TableListCellProps) => {
  return (
    <div
      className={clsx(styles.root, className)}
      {...restProps}
    >
      {children}
    </div>
  );
};
