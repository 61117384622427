// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popper_root__2pshA {\n  position: relative;\n  border-radius: 6px;\n  outline: none;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.popper_paper__3R2l9 {\n  border-radius: 6px;\n  background-color: #ffffff;\n  box-shadow: 0 0 0 1px #eae9eb, 0 8px 18px -2px rgba(37, 37, 38, 0.1);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 1000;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}\n\n.popper_left__138U1 .popper_paper__3R2l9 {\n  left: 0;\n}\n\n.popper_right__1AwDg .popper_paper__3R2l9 {\n  right: 0;\n}\n\n.popper_isOpen__132r- {\n  transition: all 0.1s ease-in-out;\n}\n.popper_isOpen__132r- .popper_paper__3R2l9 {\n  opacity: 1;\n  visibility: visible;\n  height: auto;\n  transform: none;\n  animation: popper_fadeFromTop__pn5rE 321ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ease-in forwards;\n  transition: opacity 321ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 214ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  margin-top: 4px;\n}\n\n@keyframes popper_fadeFromTop__pn5rE {\n  0% {\n    opacity: 0;\n    transform: translateY(100%);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0px);\n  }\n}", ""]);
// Exports
exports.locals = {
	"root": "popper_root__2pshA",
	"paper": "popper_paper__3R2l9",
	"left": "popper_left__138U1",
	"right": "popper_right__1AwDg",
	"isOpen": "popper_isOpen__132r-",
	"fadeFromTop": "popper_fadeFromTop__pn5rE"
};
module.exports = exports;
