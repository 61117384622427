import React, { ReactElement, ReactNode } from 'react';

import { getColorFromString, getInitialsFromString } from '@spovio/shared';
import clsx from 'clsx';

import styles from './avatar.module.scss';

export enum AvatarSize {
  avatar18 = 'avatar18',
  avatar22 = 'avatar22',
  avatar24 = 'avatar24',
  avatar26 = 'avatar26',
  avatar28 = 'avatar28',
  avatar30 = 'avatar30',
  avatar32 = 'avatar32',
  avatar34 = 'avatar34',
  avatar36 = 'avatar36',
  avatar38 = 'avatar38',
  avatar48 = 'avatar48',
  avatar52 = 'avatar52',
  avatar64 = 'avatar64',
  avatar68 = 'avatar68',
  avatar72 = 'avatar72',
  avatar74 = 'avatar74',
  avatar86 = 'avatar86',
  avatar96 = 'avatar96',
  avatar100 = 'avatar100',
  avatar120 = 'avatar120',
}
export interface AvatarProps extends React.HTMLAttributes<HTMLElement> {
  size?: AvatarSize;
  url?: string;
  icon?: ReactElement;
  name?: string;
  email?: string;
  className?: string;
  textClassName?: string;
  textWrapClassName?: string;
  circle?: boolean;
  displayName?: boolean;
  color?: string;
  badge?: ReactNode;
  onClick?: () => void;
}

export const Avatar: React.FC<AvatarProps> = ({
  size = AvatarSize.avatar22,
  url,
  icon,
  name,
  email,
  className,
  textClassName,
  textWrapClassName,
  circle,
  displayName,
  color,
  badge,
  onClick,
}: AvatarProps) => {
  const defaultColor = name && getColorFromString(name);
  const avatarInitial = getInitialsFromString(name);
  const avatarClassName = clsx(styles.avatar, styles[size], className, {
    [styles.circle]: circle,
  });
  return (
    <>
      <div
        className={avatarClassName}
        style={{
          backgroundColor: url ? '#fff' : color || defaultColor,
        }}
        onClick={onClick}
      >
        {url ? (
          <img src={url} alt={name} />
        ) : icon ? (
          icon
        ) : (
          <span>{avatarInitial}</span>
        )}
      </div>
      <div className={textWrapClassName} onClick={onClick}>
        {displayName && (
          <div className="d-flex">
            <div className={clsx(styles.text, textClassName)}>{name}</div>
            {badge ? badge : null}
          </div>
        )}
        {email && (
          <div className={clsx(styles.subText, textClassName)}>{email}</div>
        )}
      </div>
    </>
  );
};
