// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".confirmation-modal_header__34ywt {\n  color: #252526;\n  font-size: 16px;\n  letter-spacing: -0.25px;\n  line-height: 24px;\n}\n\n.confirmation-modal_subText__1s4PE {\n  color: #808080;\n  font-size: 14px;\n  letter-spacing: -0.11px;\n  line-height: 23px;\n  margin-top: 6px;\n}\n\n.confirmation-modal_confirmationDialog__2oUVl {\n  width: 448px;\n}\n\n.confirmation-modal_dialog__3gqtJ {\n  padding: 32px;\n}\n\n.confirmation-modal_footerBlock__2k_b- {\n  margin-top: 25px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n.confirmation-modal_footerBlock__2k_b- .confirmation-modal_actionItem__1mheo {\n  margin-right: 10px;\n}\n.confirmation-modal_footerBlock__2k_b- .confirmation-modal_actionItem__1mheo:last-child {\n  margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"header": "confirmation-modal_header__34ywt",
	"subText": "confirmation-modal_subText__1s4PE",
	"confirmationDialog": "confirmation-modal_confirmationDialog__2oUVl",
	"dialog": "confirmation-modal_dialog__3gqtJ",
	"footerBlock": "confirmation-modal_footerBlock__2k_b-",
	"actionItem": "confirmation-modal_actionItem__1mheo"
};
module.exports = exports;
