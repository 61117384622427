import React from 'react';

type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;
export const FilledKeyboardArrowDownIcon = (
  props: IconsProps
) => {
  return (
    <svg
      {...props}
      width="7px"
      height="5px"
      viewBox="0 0 7 5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group-45-Copy-2"
          transform="translate(-61.000000, -15.000000)"
          fill="#818385"
        >
          <g id="Group-45-Copy" transform="translate(8.000000, 5.000000)">
            <path
              d="M54.765,9.08899976 C54.6105,8.97583855 54.4165,8.9705888 54.2575,9.07325052 C54.0985,9.17649555 54,9.37131949 54,9.58364259 L54,15.4166948 C54,15.6290179 54.0985,15.8238418 54.2575,15.9270868 C54.3335,15.9755012 54.4165,16 54.5,16 C54.592,16 54.684,15.9702514 54.765,15.9113376 L58.765,12.9948115 C58.911,12.8880667 59,12.701409 59,12.5001687 C59,12.2989284 58.911,12.1122707 58.765,12.0055259 L54.765,9.08899976 Z"
              id="Fill-124"
              transform="translate(56.500000, 12.500000) rotate(90.000000) translate(-56.500000, -12.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
