// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".people-add-job-info_content__1H90h {\n  padding: 20px 25px 0 25px;\n}\n.people-add-job-info_content__1H90h .people-add-job-info_inputSelector__1VNU- {\n  height: 22px;\n}\n.people-add-job-info_content__1H90h .people-add-job-info_currencySelector__M8VW9 {\n  border: 0;\n  width: 34px;\n  padding-left: 10px;\n}", ""]);
// Exports
exports.locals = {
	"content": "people-add-job-info_content__1H90h",
	"inputSelector": "people-add-job-info_inputSelector__1VNU-",
	"currencySelector": "people-add-job-info_currencySelector__M8VW9"
};
module.exports = exports;
