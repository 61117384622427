import React from 'react';
type IconProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TeamMatesIcon = (props: IconProps) => {
  return (
    <svg {...props} viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M1 0h14v14H1z" />
        <path
          d="M6.8 8.114c1.65 0 3 1.286 3 2.886a.6.6 0 0 1-1.2 0c0-.878-.722-1.61-1.652-1.68L6.8 9.314H4.4c-1 0-1.8.762-1.8 1.686a.6.6 0 0 1-1.2 0c0-1.543 1.255-2.794 2.824-2.88l.176-.006h2.4Zm5.4-.571c1.32 0 2.4 1.03 2.4 2.314a.6.6 0 0 1-1.192.097l-.008-.097c0-.568-.462-1.046-1.068-1.108l-.132-.006h-1.8a.6.6 0 0 1-.097-1.192l.097-.008h1.8ZM7.499 3.15a2.533 2.533 0 0 1 0 3.698 2.775 2.775 0 0 1-3.798 0 2.533 2.533 0 0 1 0-3.698 2.775 2.775 0 0 1 3.798 0Zm5.275.404a1.98 1.98 0 0 1 0 2.89 2.153 2.153 0 0 1-2.948 0 1.98 1.98 0 0 1 0-2.89 2.153 2.153 0 0 1 2.948 0ZM4.53 4.02a1.334 1.334 0 0 0 0 1.96c.588.56 1.554.56 2.142 0a1.33 1.33 0 0 0 .1-1.854l-.1-.106-.114-.098a1.578 1.578 0 0 0-2.028.098Zm6.124.404a.781.781 0 0 0 0 1.152.954.954 0 0 0 1.294 0 .779.779 0 0 0 .077-1.068l-.077-.084-.093-.077a.957.957 0 0 0-1.2.077Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
