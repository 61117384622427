import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const DepartmentIcon = (props: IconsProps) => {
  return (
    <svg viewBox="0 0 18 18" {...props} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#A2A5A8"
          strokeWidth="1.2"
        >
          <path d="M7.596 10.408a1.99 1.99 0 0 1 .422-3.134 1.965 1.965 0 0 1 1.964 0 1.99 1.99 0 0 1 .422 3.134M10.404 10.408a1.986 1.986 0 0 1-2.808 0" />
        </g>
        <path d="M14.66 3.344c2.975 2.976 3.111 7.708.422 10.852M3.34 3.344C.366 6.32.23 11.051 2.919 14.195M12.458 5.545c1.759 1.758 1.878 4.51.4 6.428" />
        <path
          d="M2.918 14.196C.23 11.051.366 6.32 3.341 3.344a8.003 8.003 0 0 1 11.318 0c2.976 2.976 3.112 7.708.423 10.852"
          stroke="#A2A5A8"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.141 11.973c-1.477-1.918-1.357-4.67.4-6.428a4.89 4.89 0 0 1 6.917 0c1.759 1.758 1.878 4.51.4 6.428M5.434 16.13c.458-1.545 1.872-2.68 3.566-2.68 1.694 0 3.108 1.135 3.566 2.68a7.966 7.966 0 0 1-7.132 0Z"
          stroke="#A2A5A8"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
