import React from 'react';
type IconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const TagIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.016 1a2.5 2.5 0 0 1 1.77.735l3.247 3.257a2 2 0 0 1-.013 2.836l-2.737 2.698a2 2 0 0 1-2.818-.01l-3.232-3.23A2.5 2.5 0 0 1 1.5 5.517V2.5A1.5 1.5 0 0 1 3 1h3.016Z"
        stroke="#A2A5A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.313 10.543 1.395-1.376 1.002-.987a2.5 2.5 0 0 0 .015-3.545l-.925-.928-1.35-1.353"
        stroke="#A2A5A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.62 4.787a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Z"
        fill="#A2A5A8"
      />
    </svg>
  );
};
