/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  groupBy,
  ProjectItem,
  TimesItem,
  TimeWeekGroupType,
} from '@spovio/shared';
import moment from 'moment';

export interface ProjectDetails {
  project_id: number;
  project_name: string;
  is_time_off: boolean;
}

export interface TableBodyData {
  week: Day[];
  project: ProjectDetails;
  total: number;
}

export const getTableBodyData = (flattenTimeData: any): TableBodyData[] => {
  const timeGroupedByTask = groupBy(flattenTimeData, 'project_id');
  const tableBodyData: TableBodyData[] = [];
  Object.keys(timeGroupedByTask).forEach((key: string) => {
    const timeByPro = timeGroupedByTask[key].items;
    const tableWeekData: any = {};
    tableWeekData.week = getTableDayData(timeByPro);
    const totalTime = timeByPro.reduce(
      (acc: any, item: any) => acc + item.hours,
      0
    );
    tableWeekData.project = {
      project_id: timeByPro[0].project_id,
      project_name: timeByPro[0].project_name || '',
      is_time_off: timeByPro[0].is_time_off,
    };
    tableWeekData.total = totalTime;
    tableBodyData.push(tableWeekData);
  });
  return tableBodyData;
};

export const getTableDayData = (times: any) => {
  const weekData: any = {
    Mo: [],
    Tu: [],
    We: [],
    Th: [],
    Fr: [],
    Sa: [],
    Su: [],
  };
  if (!times) return weekData;

  times.forEach((time: any) => {
    const weekday = moment(time.date).format('dd');
    weekData[weekday].push(time);
  });
  return weekData;
};

export const getTableFooterData = (data: any) => {
  const tableFooterData: any = {
    Mo: 0,
    Tu: 0,
    We: 0,
    Th: 0,
    Fr: 0,
    Sa: 0,
    Su: 0,
    total: 0,
  };
  if (!data) return tableFooterData;
  Object.keys(tableFooterData).forEach((key: any) => {
    data.forEach((item: any) => {
      const week = item.week;
      const projectInDay = week[key];
      if (projectInDay) {
        const dayTotalTime = projectInDay.reduce(
          (acc: any, item: any) => acc + item.hours,
          0
        );

        tableFooterData[key] += dayTotalTime;
      } else {
        tableFooterData.total += item.total;
      }
    });
  });
  return tableFooterData;
};

export const getTimeGroupItem = (time: TimesItem) => {
  const timeGroupItem: Partial<TimeWeekGroupType> = {
    project_id: time.project.id,
    project_name: time.project.name,
    date: time.date,
    hours: time.hours,
    id: time.id,
    status: time.status,
    notes: time.notes,
    created_at: time.created_at,
  };
  return timeGroupItem;
};
